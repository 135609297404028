import actionTypes from './GEFCRRRI01ActionType';

/**
 * 履修履歴詳細
 */
const initialState = {
    isFetching: false,
    data: {},
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 履修履歴詳細を取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getEducationHistoryDetail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EDUCATION_HISTORY_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_EDUCATION_HISTORY_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_EDUCATION_HISTORY_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_EDUCATION_HISTORY_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 進捗一覧のダウンロードReducer
 * @param {*} state
 * @param {*} action
 */
export const getEducationAction = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EDUCATION_HISTORY_ACTION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_EDUCATION_HISTORY_ACTION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_EDUCATION_HISTORY_ACTION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_EDUCATION_HISTORY_ACTION_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
