/**
 * Action Typesを定義
 */
const GISIPWPR01ActionType = {
    GET_TRAINING_PLAN_REQUEST: 'GET_TRAINING_PLAN_REQUEST',  // 実習計画登録初期取得定義
    GET_TRAINING_PLAN_SUCCESS: 'GET_TRAINING_PLAN_SUCCESS',  // 実習計画登録初期取得成功定義
    GET_TRAINING_PLAN_FAILURE: 'GET_TRAINING_PLAN_FAILURE',  // 実習計画登録初期取得失敗定義
    GET_TRAINING_PLAN_RESET: 'GET_TRAINING_PLAN_RESET',       // 実習計画登録初期取得リセット定義
    SAVE_TRAINING_PLAN_REQUEST: 'SAVE_TRAINING_PLAN_REQUEST',  // 実習計画登録定義
    SAVE_TRAINING_PLAN_SUCCESS: 'SAVE_TRAINING_PLAN_SUCCESS',  // 実習計画登録成功定義
    SAVE_TRAINING_PLAN_FAILURE: 'SAVE_TRAINING_PLAN_FAILURE',  // 実習計画登録失敗定義
    SAVE_TRAINING_PLAN_RESET: 'SAVE_TRAINING_PLAN_RESET'       // 実習計画登録リセット定義
};

export default GISIPWPR01ActionType;