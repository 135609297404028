import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getPrefecturesMasterList, getCountryMasterList, getMessage } from "../../Constants/CommonConstants";
import { createCase, createCaseReset } from "../MmGRSMMIMR01/GRSMMIMR01Action";
import { MessageMap } from "../MmGRSMMIMR01/GRSMMIMR01Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import Intern_Icon from "../../Images/icon_intern.svg";
import { getNumberText, isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * インターン案件募集要項登録（企業情報）
 */
class GRSMMIMR02 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            companyNameOpt: [],
            company_prefectures_name: [],
            countryOpt: [],
            isDisabled: false,
            caseOutline: this.props.caseOutline,
            caseDetail: this.props.caseDetail,
            isFetching: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { caseDetail } = this.state;

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        // 都道府県
        const prefecturesRet = Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            return v;
        });
        caseDetail.employee_regular = getNumberText(caseDetail.employee_regular);
        caseDetail.employee_part_time = getNumberText(caseDetail.employee_part_time);
        this.setState(prevState => ({
            company_id: caseDetail.company_id,
            company_name: caseDetail.company_name,
            company_name_kana: caseDetail.company_name_kana,
            company_prefectures_id: caseDetail.company_prefectures_id,
            company_prefectures_name: [...prevState.company_prefectures_name, ...prefecturesRet],
            company_address: caseDetail.company_address,
            start_year: caseDetail.start_year,
            president_name: caseDetail.president_name,
            president_name_kana: caseDetail.president_name_kana,
            president_tel_country_name: caseDetail.president_tel_country_name,
            president_tel_country_code: caseDetail.president_tel_country_code,
            president_tel_1: caseDetail.president_tel_1,
            president_tel_2: caseDetail.president_tel_2,
            president_tel_3: caseDetail.president_tel_3,
            fax_country_name: caseDetail.fax_country_name,
            fax_country_code: caseDetail.fax_country_code,
            fax_1: caseDetail.fax_1,
            fax_2: caseDetail.fax_2,
            fax_3: caseDetail.fax_3,
            employee_regular: caseDetail.employee_regular,
            employee_part_time: caseDetail.employee_part_time,
            facility_id: caseDetail.facility_id,
            facility_name: caseDetail.facility_name,
            facility_name_kana: caseDetail.facility_name_kana,
            facility_prefectures_id: caseDetail.facility_prefectures_id,
            facility_address_2: caseDetail.facility_address_2,
            home_page: caseDetail.home_page,
            position: caseDetail.position,
            pic_name: caseDetail.pic_name,
            pic_name_kana: caseDetail.pic_name_kana,
            pic_tel_country_name: caseDetail.pic_tel_country_name,
            pic_tel_country_code: caseDetail.pic_tel_country_code,
            pic_tel_1: caseDetail.pic_tel_1,
            pic_tel_2: caseDetail.pic_tel_2,
            pic_tel_3: caseDetail.pic_tel_3,
            mail_address: caseDetail.mail_address,
            countryOpt: [...prevState.countryOpt, ...countryRet],
            caseDetail: caseDetail
        }), () => {
            // 会社情報リストを設定する
            const { companyList } = this.props;

            if (companyList) {
                const companyNameArr = Object.entries(companyList).map(([k, v]) => {
                    return v;
                });

                // 会社情報
                this.setState(prevState => ({
                    companyNameOpt: [...prevState.companyNameOpt, ...companyNameArr]
                }), () => {
                    // 会社情報を繰り返し処理
                    this.state.companyNameOpt.forEach(item => {
                        if (item.company_id === this.state.company_id) {
                            // 企業名を設定する
                            this.setState({ company_name: item.company_name });
                        }
                        // 施設情報を繰り返し処理
                        item.facility_list.forEach(facility => {
                            if (item.company_id === this.state.company_id && facility.facility_id === this.state.facility_id) {
                                // 施設名を設定する
                                this.setState({ facility_name: facility.facility_name });
                            }
                        });
                    });
                    this.setState({ isFetching: false });
                });
            }

            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '2' && caseDetail.sys_date < caseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }

            countryRet.forEach(country => {
                // 代表者電話番号
                if (country.country_code === caseDetail.president_tel_country_code) {
                    this.setState({
                        president_tel_country_name: country.country_name,
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    });
                }
                if (country.country_code === caseDetail.fax_country_code) {
                    // FAX番号
                    this.setState({
                        fax_country_name: country.country_name,
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    });
                }
                if (country.country_code === caseDetail.pic_tel_country_code) {
                    // 担当者電話番号
                    this.setState({
                        pic_tel_country_name: country.country_name,
                        pic_tel_country_code: country.country_code,
                        pic_tel_country_number: country.country_number
                    });
                }
            });
        });
        window.scrollTo(0, 0);
    }

    /**
     * 国コードの変更
     * @param {*} item
     */
    handleCountryCodeChange = (item) => {
        this.state.countryOpt && this.state.countryOpt.forEach(country => {
            // 代表電話番号＿国・地域名
            if (country.country_code === item.president_tel_country_code) {
                this.setState({
                    president_tel_country_name: country.country_name,
                    president_tel_country_code: country.country_code,
                    president_tel_country_number: country.country_number
                }, () => {
                    this.handleSetValue();
                });
            }

            // FAX番号＿国・地域名
            if (country.country_code === item.fax_country_code) {
                this.setState({
                    fax_country_name: country.country_name,
                    fax_country_code: country.country_code,
                    fax_country_number: country.country_number
                }, () => {
                    this.handleSetValue();
                });
            }
        });
        // 担当者電話番号＿国・地域名
        this.setState({
            pic_tel_country_name: ""
        }, () => {
            this.handleSetValue();
        });
    }

    /**
     * 画面項目値を設定する
     */
    handleSetValue = () => {
        // 会社情報 Start
        this.editItemHandler("company_id", this.state.company_id);
        this.editItemHandler("company_name", this.state.company_name);
        this.editItemHandler("company_name_kana", this.state.company_name_kana);
        this.editItemHandler("company_prefectures_id", this.state.company_prefectures_id);
        this.editItemHandler("company_address", this.state.company_address);
        this.editItemHandler("start_year", this.state.start_year);
        this.editItemHandler("president_name", this.state.president_name);
        this.editItemHandler("president_name_kana", this.state.president_name_kana);
        this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
        this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
        this.editItemHandler("fax_country_name", this.state.fax_country_name);
        this.editItemHandler("fax_country_code", this.state.fax_country_code);
        this.editItemHandler("president_tel_1", this.state.president_tel_1);
        this.editItemHandler("president_tel_2", this.state.president_tel_2);
        this.editItemHandler("president_tel_3", this.state.president_tel_3);
        this.editItemHandler("fax_1", this.state.fax_1);
        this.editItemHandler("fax_2", this.state.fax_2);
        this.editItemHandler("fax_3", this.state.fax_3);
        this.editItemHandler("employee_regular", this.state.employee_regular);
        this.editItemHandler("employee_part_time", this.state.employee_part_time);
        // 会社情報 End
        // 施設情報 Start
        this.handleSetFacilityValue();
        // 施設情報 End

    }

    /**
     * 画面項目値(施設情報)を設定する
     */
    handleSetFacilityValue = () => {
        this.editItemHandler("facility_id", this.state.facility_id);
        this.editItemHandler("facility_name", this.state.facility_name);
        this.editItemHandler("facility_name_kana", this.state.facility_name_kana);
        this.editItemHandler("facility_prefectures_id", this.state.facility_prefectures_id);
        this.editItemHandler("facility_address_2", this.state.facility_address_2);
        this.editItemHandler("home_page", this.state.home_page);
        this.editItemHandler("position", this.state.position);
        this.editItemHandler("pic_name", this.state.pic_name);
        this.editItemHandler("pic_name_kana", this.state.pic_name_kana);
        this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
        this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
        this.editItemHandler("pic_tel_1", this.state.pic_tel_1);
        this.editItemHandler("pic_tel_2", this.state.pic_tel_2);
        this.editItemHandler("pic_tel_3", this.state.pic_tel_3);
        this.editItemHandler("mail_address", this.state.mail_address);
    }

    /**
     * 企業名の変更
     * @param {*} event
     */
    handleCompanyNameChange = (event) => {
        const { value } = event.target;
        this.setState({ company_name: value });

        let prevVal = this.state.company_name;
        let newVal = event.currentTarget.value;

        this.state.companyNameOpt.map(item => {

            // 初期値はブランクの場合
            if (value === "") {
                this.setState({
                    company_id: "",
                    company_name_kana: "",
                    company_prefectures_id: "",
                    company_address: "",
                    start_year: "",
                    president_name: "",
                    president_name_kana: "",
                    president_tel_1: "",
                    president_tel_2: "",
                    president_tel_3: "",
                    fax_1: "",
                    fax_2: "",
                    fax_3: "",
                    employee_regular: "",
                    employee_part_time: "",
                    president_tel_country_name: "",
                    president_tel_country_code: "",
                    president_tel_country_number: "",
                    fax_country_name: "",
                    fax_country_code: "",
                    fax_country_number: "",
                    facility_id: "",
                    facility_name: "",
                    facility_name_kana: "",
                    facility_prefectures_id: "",
                    facility_address_2: "",
                    home_page: "",
                    position: "",
                    pic_name: "",
                    pic_name_kana: "",
                    pic_tel_country_name: "",
                    pic_tel_country_code: "",
                    pic_tel_country_number: "",
                    pic_tel_1: "",
                    pic_tel_2: "",
                    pic_tel_3: "",
                    mail_address: ""
                }, () => {
                    this.handleSetValue();
                });
            }
            // 選択された企業名の場合
            if (newVal === item.company_name) {
                this.setState({
                    company_id: item.company_id,
                    company_name_kana: item.company_name_kana,
                    company_prefectures_id: item.company_prefectures_id,
                    company_address: item.company_address,
                    start_year: item.start_year,
                    president_name: item.president_name,
                    president_name_kana: item.president_name_kana,
                    president_tel_1: item.president_tel_1,
                    president_tel_2: item.president_tel_2,
                    president_tel_3: item.president_tel_3,
                    fax_1: item.fax_1,
                    fax_2: item.fax_2,
                    fax_3: item.fax_3,
                    employee_regular: getNumberText(item.employee_regular),
                    employee_part_time: getNumberText(item.employee_part_time)
                }, () => {
                    this.handleSetValue();
                    // 国コードの変更
                    this.handleCountryCodeChange(item);
                });
                // 会社名を変更した場合
            } else if (newVal !== prevVal) {
                // 施設情報をすべてクリア
                this.setState({
                    facility_id: "",
                    facility_name: "",
                    facility_name_kana: "",
                    facility_prefectures_id: "",
                    facility_address_2: "",
                    home_page: "",
                    position: "",
                    pic_name: "",
                    pic_name_kana: "",
                    pic_tel_country_code: "",
                    pic_tel_country_number: "",
                    pic_tel_1: "",
                    pic_tel_2: "",
                    pic_tel_3: "",
                    mail_address: ""
                }, () => {
                    this.handleSetFacilityValue();
                });
            }
        });
    };

    /**
     * 施設名の変更
     * @param {*} event
     */
    handleFacilityNameChange = (event) => {
        this.setState({
            facility_name: event.target.value
        }, () => {
            this.state.companyNameOpt.map(item => {
                // 施設を取得する
                const facilityArr = item.facility_list;

                facilityArr.map(facility => {
                    if (facility.facility_name === this.state.facility_name) {
                        this.setState({
                            facility_id: facility.facility_id,
                            facility_name: facility.facility_name,
                            facility_name_kana: facility.facility_name_kana,
                            facility_prefectures_id: facility.facility_prefectures_id,
                            facility_address_2: facility.facility_address_2,
                            home_page: facility.home_page,
                            position: facility.position,
                            pic_name: facility.pic_name,
                            pic_name_kana: facility.pic_name_kana,
                            pic_tel_1: facility.pic_tel_1,
                            pic_tel_2: facility.pic_tel_2,
                            pic_tel_3: facility.pic_tel_3,
                            mail_address: facility.mail_address
                        }, () => {
                            this.handleSetFacilityValue();
                            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                                // 担当者電話番号＿国・地域名を設定する
                                if (country.country_code === facility.pic_tel_country_code) {
                                    this.setState({
                                        pic_tel_country_name: country.country_name,
                                        pic_tel_country_code: country.country_code,
                                        pic_tel_country_number: country.country_number
                                    }, () => {
                                        this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
                                        this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
                                    });
                                }
                            });
                        });
                    } else if (this.state.facility_name === "") {
                        // 施設情報をすべてクリア
                        this.setState({
                            facility_id: "",
                            facility_name: "",
                            facility_name_kana: "",
                            facility_prefectures_id: "",
                            facility_address_2: "",
                            home_page: "",
                            position: "",
                            pic_name: "",
                            pic_name_kana: "",
                            pic_tel_country_name: "",
                            pic_tel_country_code: "",
                            pic_tel_country_number: "",
                            pic_tel_1: "",
                            pic_tel_2: "",
                            pic_tel_3: "",
                            mail_address: ""
                        }, () => {
                            this.handleSetFacilityValue();
                        });
                    }
                });
            });
        });
    };

    /**
     * 本社所在地の変更
     * @param {*} event
     */
    handleCompanyPrefecturesChange = (event) => {
        this.setState({
            company_prefectures_id: event.target.value
        }, () => {
            this.editItemHandler("company_prefectures_id", this.state.company_prefectures_id);
        });
    };

    /**
     * 施設所在地の変更
     * @param {*} event
     */
    handleFacilityPrefecturesChange = (event) => {
        this.setState({
            facility_prefectures_id: event.target.value
        }, () => {
            this.editItemHandler("facility_prefectures_id", this.state.facility_prefectures_id);
        });
    };

    /**
     * 代表者電話番号の変更
     * @param {*} event
     */
    handleTelCountryNameChange = (event) => {
        this.setState({
            president_tel_country_name: event.target.value
        }, () => {
            if (this.state.president_tel_country_name === '') {
                this.setState({
                    president_tel_country_number: '',
                    president_tel_country_code: ''
                }, () => {
                    this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                    this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                    this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.president_tel_country_name) {
                    this.setState({
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                        this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                        this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * FAX番号の変更
     * @param {*} event
     */
    handleFaxCountryNameChange = (event) => {
        this.setState({
            fax_country_name: event.target.value
        }, () => {
            if (this.state.fax_country_name === '') {
                this.setState({
                    fax_country_number: '',
                    fax_country_code: ''
                }, () => {
                    this.editItemHandler("fax_country_code", this.state.fax_country_code);
                    this.editItemHandler("fax_country_name", this.state.fax_country_name);
                    this.editItemHandler("fax_country_number", this.state.fax_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.fax_country_name) {
                    this.setState({
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("fax_country_code", this.state.fax_country_code);
                        this.editItemHandler("fax_country_name", this.state.fax_country_name);
                        this.editItemHandler("fax_country_number", this.state.fax_country_number);
                    });
                }
            });
        });
    };

    /**
     * 電話番号の変更
     * @param {*} event
     */
    handlePicTelCountryNameChange = (event) => {
        this.setState({
            pic_tel_country_name: event.target.value
        }, () => {
            if (this.state.pic_tel_country_name === '') {
                this.setState({
                    pic_tel_country_number: '',
                    pic_tel_country_code: ''
                }, () => {
                    this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
                    this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
                    this.editItemHandler("pic_tel_country_number", this.state.pic_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.pic_tel_country_name) {
                    this.setState({
                        pic_tel_country_code: country.country_code,
                        pic_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
                        this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
                        this.editItemHandler("pic_tel_country_number", this.state.pic_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            caseDetail: {
                ...prevState.caseDetail,
                [name]: value
            }
        }));
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createCaseReset();
        window.scrollTo(0, 0);
        this.setErrorCallback(this.saveReset);
        let caseDetailData = this.state.caseDetail;
        if (caseDetailData.case_id == '' || caseDetailData.case_id == null) {
            if (this.props.data && this.props.data.case_id != null) {
                caseDetailData.case_id = this.props.data.case_id;
            }
        }
        caseDetailData.temp_save = '1';
        this.setState({
            caseDetail: caseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.caseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createCase(reqData);
            }
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createCaseReset();
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    render() {
        const { caseDetail, isDisabled } = this.state;
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.caseDetail.version_no != this.props.data.version_no) {
                let caseDetailData = this.state.caseDetail;
                caseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    caseDetail: caseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                インターン管理
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>インターン登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">インターン登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR01", this.state.caseOutline, this.state.caseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>会社情報</span>
                                            </li>
                                            <li className="bl_stepbar_item  is_disabled">
                                                <span>条件・業務</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>待遇</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMIMR01", this.state.caseOutline, this.state.caseDetail)} tabIndex="35">戻る</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR03", this.state.caseOutline, this.state.caseDetail)} tabIndex="34">次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" /></span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>会社情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        企業名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select__max" name="company_name" value={this.state.company_name}
                                                                onChange={this.handleCompanyNameChange} disabled={this.props.createorupdate === "update" ? true : false} tabIndex="1">
                                                                <option value="" selected ></option>
                                                                {
                                                                    this.state.companyNameOpt.map(item => (
                                                                        <option key={item.company_id} value={item.company_name} selected={this.state.company_id == item.company_id}>
                                                                            {item.company_name}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        企業名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="company_name_kana" className="el_input el_input__max"
                                                                setValue={caseDetail.company_name_kana} maxlength="60" required="required" tabIndex="2" toZenKana="true" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        本社所在地
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_pref" name="company_prefectures_name"
                                                                value={this.state.company_prefectures_id} onChange={this.handleCompanyPrefecturesChange} tabIndex="3">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.company_prefectures_name && this.state.company_prefectures_name.map(item => (
                                                                        <option key={item.prefectures_id} value={item.prefectures_id} selected={this.state.company_prefectures_id == item.prefectures_id}>
                                                                            {item.prefectures_name}
                                                                        </option>

                                                                    ))
                                                                };
                                                            </select>
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="company_address" className="el_input el_input__max"
                                                                setValue={caseDetail.company_address} maxlength="60" required="required" tabIndex="4" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        設立日（年）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="start_year" className="el_input el_input__sm"
                                                                setValue={caseDetail.start_year} maxlength="4" required="required" tabIndex="5" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="president_name" className="el_input el_input__max"
                                                                setValue={caseDetail.president_name} maxlength="40" required="required" tabIndex="6" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="president_name_kana" className="el_input el_input__max"
                                                                setValue={caseDetail.president_name_kana} maxlength="40" required="required" tabIndex="7" toZenKana="true" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者電話番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="president_tel_country_name" value={this.state.president_tel_country_name}
                                                                onChange={this.handleTelCountryNameChange} tabIndex="8">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.president_tel_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="president_tel_country_number" className="el_input el_input__countryCode"
                                                            setValue={this.state.president_tel_country_number} maxlength="6" readOnly={true} />

                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="president_tel_1" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.president_tel_1} maxlength="4" required="required" tabIndex="9" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="president_tel_2" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.president_tel_2} maxlength="4" required="required" tabIndex="10" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="president_tel_3" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.president_tel_3} maxlength="4" required="required" tabIndex="11" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        FAX番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="fax_country_name" value={this.state.fax_country_name}
                                                                onChange={this.handleFaxCountryNameChange} tabIndex="12">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.fax_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="fax_country_number" className="el_input el_input__countryCode"
                                                            setValue={this.state.fax_country_number} maxlength="6" readOnly={true} />

                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="fax_1" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.fax_1} maxlength="4" required="required" tabIndex="13" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="fax_2" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.fax_2} maxlength="4" required="required" tabIndex="14" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="fax_3" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.fax_3} maxlength="4" required="required" tabIndex="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        従業員数
                                                    </th>
                                                    <td>
                                                        <div className="bl_formTxt">正社員</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="employee_regular" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.employee_regular} numberFormat="true" maxlength="6" required="required" tabIndex="16" />
                                                            <span className="el_form_txt">名</span>
                                                        </div>
                                                        <div className="bl_formTxt bl_formTxt_btm">パート/アルバイト</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="employee_part_time" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.employee_part_time} numberFormat="true" maxlength="6" required="required" tabIndex="17" />
                                                            <span className="el_form_txt">名</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>

                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>施設情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        施設名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select__max" name="facility_name" value={this.state.facility_name}
                                                                onChange={this.handleFacilityNameChange} tabIndex="18">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.companyNameOpt.map(item => {
                                                                        if (this.state.company_name == item.company_name) {
                                                                            return item.facility_list.map(facilityItem =>
                                                                                <option key={facilityItem.facility_id} value={facilityItem.facility_name} selected={this.state.facility_id == facilityItem.facility_id}>
                                                                                    {facilityItem.facility_name}
                                                                                </option>
                                                                            );
                                                                        }
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        施設名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="facility_name_kana" className="el_input el_input__max"
                                                                setValue={caseDetail.facility_name_kana} maxlength="60" required="required" tabIndex="19" toZenKana="true" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        施設所在地
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_pref" name="facility_prefectures_name" value={this.state.facility_prefectures_id}
                                                                onChange={this.handleFacilityPrefecturesChange} tabIndex="20" >
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.company_prefectures_name && this.state.company_prefectures_name.map(item => (
                                                                        <option key={item.prefectures_id} value={item.prefectures_id} selected={this.state.facility_prefectures_id == item.prefectures_id}>
                                                                            {item.prefectures_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="facility_address_2" className="el_input el_input__max"
                                                                setValue={caseDetail.facility_address_2} maxlength="60" required="required" tabIndex="21" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        ホームページ
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="home_page" className="el_input el_input__max"
                                                                setValue={caseDetail.home_page} maxlength="60" required="required" tabIndex="22" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        役職
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="position" className="el_input el_input__max"
                                                                setValue={caseDetail.position} maxlength="40" required="required" tabIndex="23" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        担当者氏名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="pic_name" className="el_input el_input__max"
                                                                setValue={caseDetail.pic_name} maxlength="40" required="required" tabIndex="24" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        担当者氏名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="pic_name_kana" className="el_input el_input__max"
                                                                setValue={caseDetail.pic_name_kana} maxlength="40" required="required" tabIndex="25" toZenKana="true" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        電話番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="pic_tel_country_name" value={this.state.pic_tel_country_name}
                                                                onChange={this.handlePicTelCountryNameChange} tabIndex="26">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.pic_tel_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="pic_tel_country_number" className="el_input el_input__countryCode"
                                                            setValue={this.state.pic_tel_country_number} maxlength="6" readOnly={true} />

                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="pic_tel_1" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.pic_tel_1} maxlength="4" required="required" tabIndex="27" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="pic_tel_2" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.pic_tel_2} maxlength="4" required="required" tabIndex="28" />
                                                        -
                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="pic_tel_3" className="el_input el_input__phoneNum"
                                                            setValue={caseDetail.pic_tel_3} maxlength="4" required="required" tabIndex="29" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        メールアドレス
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="mail_address" className="el_input el_input__max"
                                                                setValue={caseDetail.mail_address} maxlength="81" required="required" tabIndex="30" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex="32">途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR03", this.state.caseOutline, this.state.caseDetail)} tabIndex="33">次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

/**
 * 案件情報保存のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.createCase.isFetching,
        data: state.createCase.caseData,
        isError: state.createCase.isError,
        err_type: state.createCase.err_type,
        err_detail: state.createCase.err_detail,
        companyList: state.getCompanyList.data
    };
};

/**
 * 案件情報保存のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        createCase(condition) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCase(condition));
        },
        createCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMIMR02);

