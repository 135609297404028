
import { getWorkingtimeInquiry } from "./WtGTMWTWTI01/GTMWTWTI01Reducer";
import { saveWorkingtime } from "./WtGTMWTWTR01/GTMWTWTR01Reducer";
import { workingtimeApproval } from "./WtGTMWTWTA01/GTMWTWTA01Reducer";
import { workingHours } from "./WtGTMWTWTR02/GTMWTWTR02Reducer";

export const tmReducer = {
    getWorkingtimeInquiry,
    workingtimeApproval,
    saveWorkingtime,
    workingHours
};
