/**
 * Action Typesを定義
 */
const GCMOMCRU05ActionType = {
    GET_SUPERVISING_ORG_INFO_REQUEST: 'GET_SUPERVISING_ORG_INFO_REQUEST',  // 組織情報(プロフィール)監理団体取得定義
    GET_SUPERVISING_ORG_INFO_SUCCESS: 'GET_SUPERVISING_ORG_INFO_SUCCESS',  // 組織情報(プロフィール)監理団体取得成功定義
    GET_SUPERVISING_ORG_INFO_FAILURE: 'GET_SUPERVISING_ORG_INFO_FAILURE',  // 組織情報(プロフィール)監理団体取得失敗定義
    GET_SUPERVISING_ORG_INFO_RESET: 'GET_SUPERVISING_ORG_INFO_RESET',       // 組織情報(プロフィール)監理団体取得リセット定義
    UPDATE_SUPERVISING_ORG_INFO_REQUEST: 'UPDATE_SUPERVISING_ORG_INFO_REQUEST',  // 組織情報(プロフィール)監理団体更新定義
    UPDATE_SUPERVISING_ORG_INFO_SUCCESS: 'UPDATE_SUPERVISING_ORG_INFO_SUCCESS',  // 組織情報(プロフィール)監理団体更新成功定義
    UPDATE_SUPERVISING_ORG_INFO_FAILURE: 'UPDATE_SUPERVISING_ORG_INFO_FAILURE',  // 組織情報(プロフィール)監理団体更新失敗定義
    UPDATE_SUPERVISING_ORG_INFO_RESET: 'UPDATE_SUPERVISING_ORG_INFO_RESET'       // 組織情報(プロフィール)監理団体更新リセット定義
};

export default GCMOMCRU05ActionType;
