import React from 'react';
import SmTemplate from "./template";

function SmContainer() {
    return (
        <section className="App">
            <SmTemplate />
        </section>
    );
}

export default SmContainer;
