import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import Icon_Info from "../../Images/icon_info.svg";
import Icon_Todo from "../../Images/icon_todo.svg";
import { connect } from "react-redux";
import { getNoticeList, getNoticeDetail, getNoticeDetailReset, getNoticeListReset, updateTodoTable, UpdateTodoDetailReset } from "./GAFMCSNF01Action";
import { getCodeName } from "../../Constants/CommonConstants";
import { getChangeDateText } from "../../Components/Tools/StringTools";
import { withRouter } from "react-router";

/**
 * お知らせ機能
 */
class GAFMCSNF01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            condition: {},
            detailData: null,
            showInfoCount: 4,
            showTodoCount: 4,
            isError: false,
            isloading: false,
            noticeButtonLabel: "さらに表示",
            noticeButtonCss: "el_openBtn",
            todoButtonLabel: "さらに表示",
            todoButtonCss: "el_openBtn"
        };
    }

    /**
     * 画面初期化イベント
     */
    componentWillMount = () => {
        // システムエラーのコールバック設定
        this.setErrorCallback(this.closeMsg);
        this.handleToQuery();
    }

    /**
     * お知らせの詳細ボタンtoggleイベント
     */
    changeNoticeButton = () => {
        if (this.state.noticeButtonLabel == "さらに表示") {
            this.setState({
                noticeButtonLabel: "閉じる",
                noticeButtonCss: "el_closeBtn",
                showInfoCount: 50

            });
        } else {
            this.setState({
                noticeButtonLabel: "さらに表示",
                noticeButtonCss: "el_openBtn",
                showInfoCount: 4
            });
        }
    }

    /**
     * エラー画面閉じるイベント
     */
    closeMsg = () => {
        this.props.getNoticeDetailReset();
        this.props.getNoticeListReset();
        this.props.updateTodoDetailReset();
    }

    /**
     * Todoの詳細ボタンtoggleイベント
     */
    changeTodoButton = () => {
        if (this.state.todoButtonLabel == "さらに表示") {
            this.setState({
                todoButtonLabel: "閉じる",
                todoButtonCss: "el_closeBtn",
                showTodoCount: 50
            });

        } else {
            this.setState({
                todoButtonLabel: "さらに表示",
                todoButtonCss: "el_openBtn",
                showTodoCount: 4
            });
        }
    }

    /**
     * お知らせ詳細クリック
     */
    noticeClick = (noticeId) => {
        this.props.getNoticeDetail(noticeId);
    }

    /**
     * TODOクリック
     */
    todoClick = (gamenId, caseId, todoId, todoLink) => {
        this.setState({
            gamenId: gamenId,
            caseId: caseId,
            todoLink: todoLink
        });
        let updateCondition = {};
        updateCondition.todo_id = todoId;
        updateCondition.read_unread_flg = '1';
        this.props.updateTodoTable(updateCondition);
    }

    /**
     * 詳細画面切り替え
     */
    handleToDisplay = (changeTo) => {
        let detailData = this.props.detailData;
        this.props.changeDisp(changeTo, detailData);
        this.props.getNoticeDetailReset();
    }

    /**
     * 案件一覧画面切り替え
     */
    handleToCaseSearch = async (caseId, gamenId) => {
        await this.props.history.push({
            pathname: '/rs/home',
            dispId: 'GRSMMMLI01',
            caseId: caseId,
            gamenId: gamenId,
            toggleClass: [
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item js_gnavBtn is_active",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item"
            ],
            menuIndex: 0
        });
        await this.props.updateTodoDetailReset();
    }

    /**
     * 実習結果一覧画面切り替え
     */
    handleToTrainingSearch = async (caseId, companyId, candidateId, gamenId) => {
        await this.props.history.push({
            pathname: '/is/home',
            dispId: 'GISAMIRS01',
            caseId: caseId,
            companyId: companyId,
            candidateId: candidateId,
            gamenId: gamenId,
            toggleClass: [
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item js_gnavBtn is_active",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item",
                "bl_gnav_item"
            ],
            menuIndex: 0
        });
        await this.props.updateTodoDetailReset();
    }

    /**
     * データ検索
     */
    handleToQuery = () => {

        this.setState({
        }, () => {
            this.props.getNoticeList();
        });
    }

    render() {
        return (
            <article id="showNoticeArticle">
                <div className="ly_mainCont">
                    {
                        (() => {
                            if (this.props.detailData != null) {
                                this.handleToDisplay("detail");
                            }
                        })()
                    }
                    {
                        (() => {
                            if (this.props.errType === '200' && this.state.todoLink === 'GRSMMMLI01') {
                                this.handleToCaseSearch(this.state.caseId, this.state.gamenId);
                            }
                            if (this.props.errType === '200' && this.state.todoLink === 'GISAMIRS01') {
                                let param = this.state.caseId.split(",");
                                let caseId = param[0]
                                let companyId = param[1]
                                let candidateId = param[2]
                                this.handleToTrainingSearch(caseId, companyId, candidateId, this.state.gamenId);
                            }
                        })()
                    }
                    <div className="un_topMainCont">
                        <section className="bl_info">
                            <div className="bl_info_header">
                                <h1 className="bl_info_ttl">
                                    <span><img src={Icon_Info} alt="お知らせアイコン" /></span>
                                    <span>お知らせ</span>
                                </h1>
                            </div>

                            <div className="bl_info_main">
                                <ul className="bl_vertPosts">
                                    {
                                        (
                                            () => {
                                                if (this.props.data.infoList != null && this.props.data.infoList.length > 0) {
                                                    return (
                                                        this.props.data.infoList.slice(0, this.state.showInfoCount).map(
                                                            (item) => (
                                                                <li className="bl_vertPosts_item" key={item.info_seq}>
                                                                    <span className="bl_vertPosts_date">{getChangeDateText(item.info_date)}</span>
                                                                    <p className="bl_vertPosts_ttl">
                                                                        <a href="#!" className="bl_vertPosts_link" onClick={() => this.noticeClick(item.info_seq)}>{item.info_title}</a>
                                                                    </p>
                                                                </li>
                                                            )
                                                        )
                                                    );
                                                }
                                            }
                                        )()
                                    }
                                </ul>
                                {
                                    (() => {
                                        if (this.props.data.infoList != null && this.props.data.infoList.length > 4) {
                                            return (
                                                <div className="bl_horizBtnUnit" >
                                                    <button className={this.state.noticeButtonCss} onClick={() => this.changeNoticeButton()}>{this.state.noticeButtonLabel}</button>
                                                </div>
                                            );
                                        }
                                    })()
                                }
                            </div>
                        </section>
                        {/* TODO リスト Start */}
                        <section className="bl_info bl_todo">
                            <div className="bl_info_header">
                                <h1 className="bl_info_ttl">
                                    <span><img src={Icon_Todo} alt="TODOアイコン" /></span>
                                    <span>TODO リスト</span>
                                </h1>
                            </div>
                            <div className="bl_info_main">
                                <ul className="bl_vertPosts">
                                    {
                                        (
                                            () => {
                                                if (this.props.data.todoList != null && this.props.data.todoList.length > 0) {
                                                    return (
                                                        this.props.data.todoList.slice(0, this.state.showTodoCount).map(
                                                            (item) => (
                                                                <li className="bl_vertPosts_item" key={item.todo_id}>
                                                                    {
                                                                        (() => {
                                                                            if (item.read_unread_flg == '0') {
                                                                                return (
                                                                                    <p className="el_vertPosts_mark el_vertPosts_mark__unread">{getCodeName('RSC0017', item.read_unread_flg)}</p>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <p className="el_vertPosts_mark el_vertPosts_mark__read">{getCodeName('RSC0017', item.read_unread_flg)}</p>
                                                                                );
                                                                            }
                                                                        })()
                                                                    }
                                                                    <p className="bl_vertPosts_date">{getChangeDateText(item.create_datetime)}</p>
                                                                    <p className="bl_vertPosts_ttl bl_vertPosts_todo">
                                                                        <a href="#!" className="bl_vertPosts_link" onClick={() => this.todoClick(item.act_screen_id, item.todo_parameter, item.todo_id, item.todo_link)}>{item.todo_comment}</a>
                                                                    </p>
                                                                </li>
                                                            )
                                                        )
                                                    );
                                                }
                                            }
                                        )()
                                    }
                                </ul>
                                {
                                    (() => {
                                        if (this.props.data.todoList != null && this.props.data.todoList.length > 4) {
                                            return (
                                                <div className="bl_horizBtnUnit" >
                                                    <button className={this.state.todoButtonCss} onClick={() => this.changeTodoButton()}>{this.state.todoButtonLabel}</button>
                                                </div>
                                            );
                                        }
                                    })()
                                }
                            </div>
                        </section>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    <footer>
                        <div className="bl_footer">
                            <p className="el_osl"><a href="#!" onClick={() => this.getMenu().showOssDialog()}>オープンソースライセンス</a></p>
                            <p className="el_copyrights">© Hitachi Systems, Ltd. 2020. All Rights Reserved.</p>
                        </div>
                    </footer>
                </div>
            </article >
        );
    }
}

/**
 * Home画面一覧データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.getNoticeList.isFetching || state.getNoticeDetail.isFetching || state.updateTodoTable.isFetching,
        data: state.getNoticeList.data,
        isError: state.getNoticeList.isError || state.getNoticeDetail.isError || state.updateTodoTable.isError,
        detailData: state.getNoticeDetail.detailData,
        errType: state.updateTodoTable.errType
    };
};

/**
 * Home画面一覧データ取得のActionバインディングする
 * @param {*} dispatch
 */
function mapDispatchToProps(dispatch) {
    return {
        getNoticeList(condition) {
            dispatch(getNoticeList(condition));
        }, getNoticeDetail(noticeId) {
            dispatch(getNoticeDetail(noticeId));
        }, getNoticeDetailReset() {
            dispatch(getNoticeDetailReset());
        }, getNoticeListReset() {
            dispatch(getNoticeListReset());
        }, updateTodoTable(condition) {
            dispatch(updateTodoTable(condition));
        }, updateTodoDetailReset() {
            dispatch(UpdateTodoDetailReset());
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GAFMCSNF01));
