import actionTypes from './GCMOMORU01ActionType';

/**
 * ユーザ管理
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * ユーザ管理取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getUserManagementList = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_USER_MANAGEMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_USER_MANAGEMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_USER_MANAGEMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_USER_MANAGEMENT_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * ユーザ管理を更新するReducer
 * @param {*} state
 * @param {*} action
 */
export const updateUserManagement = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_MANAGEMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * ユーザ管理を削除するReducer
 * @param {*} state
 * @param {*} action
 */
export const deleteUserManagement = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.DELETE_USER_MANAGEMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.DELETE_USER_MANAGEMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.DELETE_USER_MANAGEMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.DELETE_USER_MANAGEMENT_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
