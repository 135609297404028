import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import { connect } from "react-redux";
import { getMessage, getIndustryMasterList, getCodeList, getCodeName } from "../../Constants/CommonConstants";
import { GetWorkingPlanCaseList, GetWorkingPlanCaseListReset } from "./GISIPWPM01Action";
import { GetWorkingPlanInfoList, GetWorkingPlanInfoListReset } from "../IpGISIPWPL01/GISIPWPL01Action";
import { MessageMap } from "./GISIPWPM01Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 作業計画案件一覧
 */
class GISIPWPM01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
            caseType: [],
            sort_case_view: '0',
            inited: false
        };
        this.setCaseType = this.setCaseType.bind(this);
    }

    /**
     * 初期化イベント
     */
    componentDidMount = async () => {
        this.handleToQuery();
        await this.props.getCaseInfoReset();
        this.setState({ inited: true });
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        this.state.caseType[index] = event.target.checked;
        this.setState({
            caseType: this.state.caseType
        });
    };

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    }

    /**
     * 検索処理
     */
    handleToQuery = () => {
        window.scrollTo(0, 0);
        // 在留資格
        let casetype = "";
        this.state.caseType.map((key, value) => {
            if (key) {
                casetype = casetype + value + ",";
            }
        });
        if (casetype.length > 0) {
            casetype = casetype.substr(0, casetype.length - 1);
        }
        this.setState({
            search_status_of_residence: casetype,
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getCaseList(this.state);
        });
    }

    /**
     * 案件選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        window.scrollTo(0, 0);
        // パラメータの設定
        let searchPara = {};
        searchPara['case_id'] = event;
        this.props.getCaseInfo(searchPara);
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDisp(changeTo);
    }

    /**
     * 並べ替え
     * @param {*} event 並べ替え
     */
    setSortStates = (event) => {
        this.setState({
            sort_case_view: event.target.value
        }, () => {
            this.handleToQuery();
        });
    }

    render() {

        // 実習計画画面へ遷移する
        if (this.props.caseInfo_err_type == '200' && this.state.inited) {
            this.props.changeDisp('GISIPWPL01');
        }

        // 並べ替え順定義する
        let sortOptions = Object.entries(getCodeList('RSC0045')).map(
            ([key, value]) => (
                <option key={value.code} value={value.code}>
                    {value.code_name}
                </option>
            )
        );

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_linkUnit">
                        </div>
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                案件一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>
                                    案件一覧
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.caseInfo_err_type} err_detail={this.props.caseInfo_err_detail} messageMap={MessageMap} />

                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>
                                                        <TextInput maxlength="60" tabIndex="1" type="text" setValue={this.state.search_company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__max" name="search_company_name" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>施設名</th>
                                                    <td>
                                                        <TextInput maxlength="60" tabIndex="2" type="text" setValue={this.state.search_facility_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__max" name="search_facility_name" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input tabIndex="4" className="el_checkbox" type="checkbox" name="search_gno" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} />
                                                                <span className="el_checkbox_txt">技能実習</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input tabIndex="5" className="el_checkbox" type="checkbox" name="search_tok" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} />
                                                                <span className="el_checkbox_txt">特定技能</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input tabIndex="6" className="el_checkbox" type="checkbox" name="search_int" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} />
                                                                <span className="el_checkbox_txt">インターン</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>
                                                        <TextInput maxlength="11" tabIndex="7" type="text" setValue={this.state.search_case_id} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__mid" name="search_case_id" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>
                                                        <TextInput maxlength="40" tabIndex="8" type="text" setValue={this.state.search_case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__max" name="search_case_title" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    <td>
                                                        <TextInput maxlength="10" tabIndex="9" type="date" setValue={this.state.search_accept_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" className="el_input el_input__datepicker" name="search_accept_period_start" /> 〜
                                                        <TextInput maxlength="10" tabIndex="10" type="date" setValue={this.state.search_accept_period_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" className="el_input el_input__datepicker" name="search_accept_period_end" /> まで
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button tabIndex="11" type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToQuery()}>検索</button>
                                        </div>
                                    </div>
                                    <section className="bl_sect">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv3Heading"><span>募集案件一覧</span></h2>
                                        </div>
                                        <div className="bl_sortBoxUnit">
                                            <dl className="bl_sortBox bl_sortBox__r">
                                                <dt className="bl_sortBox_ttl">並べ替え</dt>
                                                <dd>
                                                    <select tabIndex="12" className="el_select el_select__auto" name="sort_case_view" required value={this.state.sort_case_view} onChange={this.setSortStates}>
                                                        {sortOptions}
                                                    </select>
                                                </dd>
                                            </dl>
                                        </div>

                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>案件名</th><th>案件ID</th><th>在留資格</th><th>企業名</th><th>施設名</th><th>受入・就業期間</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data)) {
                                                            return (
                                                                this.props.data.map((item, index) => (
                                                                    <tr key={item.case_id}>
                                                                        <td><a href="#" className="el_link" onClick={() => { this.handleRowSelection(item.case_id); }}>{item.case_title}</a></td>
                                                                        <td className="hp_tar">{item.case_id}</td>
                                                                        <td>{getCodeName('RSC0004', item.status_of_residence)}</td>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{item.facility_name}</td>
                                                                        <td>{isBlank(item.accept_period_start) && isBlank(item.accept_period_end) ? '' : item.accept_period_start.replace(/\-/g, "/") + "\n" + "〜" + "\n" + item.accept_period_end.replace(/\-/g, "/")}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.getWorkingPlanCaseList.data,
        overflowflg: state.getWorkingPlanCaseList.overflowflg,
        err_type: state.getWorkingPlanCaseList.err_type,
        err_detail: state.getWorkingPlanCaseList.err_detail,
        caseInfo_data: state.getWorkingPlanInfoList.data,
        caseInfo_err_type: state.getWorkingPlanInfoList.err_type,
        caseInfo_err_detail: state.getWorkingPlanInfoList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getCaseList(condition) {
            // 作業計画案件一覧取得
            dispatch(GetWorkingPlanCaseList(condition));
        },
        getCaseInfo(condition) {
            // 実習計画案件取得
            dispatch(GetWorkingPlanInfoList(condition));
        },
        getCaseInfoReset() {
            // 実習計画案件リセットする
            dispatch(GetWorkingPlanInfoListReset());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISIPWPM01);

