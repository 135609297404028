import React from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { getIndustryMasterList, getCognitoUserInfo, getCodeList, getCodeName, getMessage } from "../../Constants/CommonConstants";
import { getStatus } from "../../Constants/RsConstants";
import TextInput from "../../Components/Atoms/TextInput";
import { getCaseList, getCaseDetail, getCaseDetailCopy, deleteCase, getRecruitmentDetail, getRecruitmentDetailCopy, getSpecificDetail, getSpecificDetailCopy } from "./GRSMMMLI01Action";
import { CreateCaseResetAllData } from "../MmGRSMMIMR01/GRSMMIMR01Action";
import { CreateRecruitmentCaseResetAllData } from "../MmGRSMMRMR01/GRSMMRMR01Action";
import { CreateRecruitmentCaseSpecialSkillResetAllData } from "../MmGRSMMRMR31/GRSMMRMR31Action";
import { MessageMap } from "./GRSMMMLI01Message";
import "../../Css/Common.css";
import "../../Css/GRSMMMLI01.css";
import Intern_Icon from "../../Images/icon_intern.svg";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getEntryList, EntryListReset, GetCaseCandidateListReset } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import { getNumberText } from "../../Components/Tools/StringTools";
import { getInterviewHistoryReset } from "../MsGRSMSMSA01/GRSMSMSA01Action";

/**
 * 案件一覧画面
 */
class GRSMMMLI01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            search_company_name: "",
            search_facility_name: "",
            search_candidate_status: "",
            caseType: [],
            search_case_id: this.props.caseId,
            search_case_title: "",
            pubPeriodStart: "",
            pubPeriodEnd: "",
            acceptPeriodStart: "",
            acceptPeriodEnd: "",
            displayStatus: 6,
            sortStates: '0',
            condition: {},
            selectedCaseId: "",
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
            createBtnToggleclass: "bl_acoBtnWrapper js_acoBtn",
            showShikitorokuMenu: "hideShikitorokuMenu",
            modifyordetail: -1,
            gamenId: this.props.gamenId,
            actionCopyFlag: null,
            actionValue: null
        };
        this.setEntryStatus = this.setEntryStatus.bind(this);
        this.setCaseType = this.setCaseType.bind(this);
        this.setModifyOrDetail = this.setModifyOrDetail.bind(this);
    }

    componentDidMount = () => {
        this.handleToQuery();
        this.props.getInterviewHistoryReset();
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        this.state.caseType[index] = event.target.checked;
        this.setState({
            caseType: this.state.caseType
        });
    };

    /**
     * エントリー条件を設定する
     * @param {*} entry エントリステータス
     */
    setEntryStatus(entry) {
        this.setState({
            search_candidate_status: entry.target.value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        this.props.createCaseReset();
        return this.props.changeDisp(changeTo);
    }

    /**
     * 求人案件画面切替処理
     * @param {*} dispId
     * @param {*} type  1:技能実習,2:特定技能
     */
    switchRecruitmentDisplay = (dispId, type) => {
        this.props.createCaseReset();
        return this.props.switchRecruitmentDisplay(dispId, type);
    }

    /**
     * 変更対象の案件詳細を設定する
     * @param {*} value 変更対象の案件詳細
     */
    setModifyOrDetail = (value) => {
        this.setState({
            modifyordetail: value
        });
    }


    /**
     * 検索処理
     */
    handleToQuery = () => {
        window.scrollTo(0, 0);
        // 検索結果を初期化
        this.getCaseListReset();
        // パラメータの設定
        let searchPara = {};
        // 企業名
        searchPara['company_name'] = this.state.search_company_name;
        // 施設名
        searchPara['facility_name'] = this.state.search_facility_name;
        // 在留資格
        let casetype = "";
        this.state.caseType.map((key, value) => {
            if (key) {
                casetype = casetype + value + ",";
            }
        });
        if (casetype.length > 0) {
            casetype = casetype.substr(0, casetype.length - 1);
        }
        // 在留資格
        searchPara['status_of_residence'] = casetype;
        // 案件ID
        searchPara['case_id'] = this.state.search_case_id;
        // 案件名
        searchPara['case_title'] = this.state.search_case_title;
        // 募集開始日
        searchPara['pub_start'] = this.state.pubPeriodStart;
        // 募集終了日
        searchPara['pub_end'] = this.state.pubPeriodEnd;
        // 受入・就業開始日
        searchPara['accept_start'] = this.state.acceptPeriodStart;
        // 受入・就業終了日
        searchPara['accept_end'] = this.state.acceptPeriodEnd;
        // 案件ステータス
        searchPara['case_status'] = this.state.search_candidate_status;
        // 並べ替え順
        searchPara['order_by'] = this.state.sortStates;
        this.setState({
            condition: searchPara,
            selectedCaseId: "",
            err_msg: []
        }, () => {
            // 検索処理を行う
            // システムエラーのコールバック設定
            this.setErrorCallback(this.getCaseListReset);
            this.props.getCaseList(this.state.condition);
        });
    }

    /**
     * 並べ替え
     * @param {*} event 並べ替え
     */
    setSortStates = (event) => {
        this.setState({
            sortStates: event.target.value
        }, () => {
            this.handleToQuery();
        });
    }

    /**
     * 検索一覧リセットする
     */
    getCaseListReset = () => {
        this.props.getCaseListReset();
        // 一覧画面の場合にエントリー情報初期化を行う
        this.props.entryListReset();
        this.props.getCaseCandidateListReset();
    }

    /**
     * 案件詳細データリセットする
     */
    getCaseDetailReset = () => {
        this.props.getCaseDetailReset();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    }

    /**
     * 案件選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        this.setState({
            selectedCaseId: this.props.data[event.target.value].case_id,
            selectedstatusOfResidence: this.props.data[event.target.value].status_of_residence
        });
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 修正・案件コピー
     * @param {*} copy 修正/コピー先
     * @param {*} value 0:修正・コピー/1:詳細内容
     */
    handleToModify = (copy, value) => {
        window.scrollTo(0, 0);
        if (this.state.selectedCaseId == "") {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            this.setState({
                actionCopyFlag: copy,
                actionValue: value
            }, () => {
                if (value == 1) {
                    this.handleToAction();
                } else {
                    let showError = false;
                    let showEndError = false;
                    let message = '';
                    if (!copy) {
                        showError = this.checkCaseEditStatus();
                        if (showError) {
                            // (募集開始)修正不可のメッセージ
                            message = getMessage('RS-0027');
                        }
                        showEndError = this.checkCaseEditDate();
                        if (showEndError) {
                            // (募集終了)修正不可のメッセージ
                            message = getMessage('RS-0038');
                        }
                    }
                    if (showError || showEndError) {
                        // 修正不可のメッセージボックスを表示する
                        this.showModalDialog(3, message, this.handleToCancelAction);
                    } else {
                        // 編集画面へ移動する
                        this.handleToAction();
                    }
                }
            });

        }
    }

    /**
     * 案件の編集・取消かどうかのチェック
     */
    checkCaseEditStatus = () => {
        let showError = false;
        this.props.data.map((item) => {
            if (item.case_id == this.state.selectedCaseId && item.case_status === '2') {
                // 案件一覧情報．募集開始日≦当日の場合
                // 案件一覧情報．募集終了日≧当日の場合
                // 修正不可のメッセージボックスを表示する。
                // 現在時刻
                let currentDate = item.sys_date.replace(/\-/g, "");
                // 募集開始日のフォマード
                let pub_start = item.pub_period_start.replace(/\-/g, "");
                let pub_end = item.pub_period_end.replace(/\-/g, "");
                if (pub_start <= currentDate && pub_end >= currentDate) {
                    showError = true;
                }
            }
        });
        return showError;
    }

    /**
     * 案件の編集・取消かどうかのチェック
     */
    checkCaseEditDate = () => {
        let showError = false;
        this.props.data.map((item) => {
            if (item.case_id == this.state.selectedCaseId && item.case_status === '2') {
                // 案件一覧情報．募集開始日<当日の場合
                // 修正不可のメッセージボックスを表示する。
                // 現在時刻
                let currentDate = item.sys_date.replace(/\-/g, "");
                // 募集終了日のフォマード
                let pub_end = item.pub_period_end.replace(/\-/g, "");
                if (pub_end < currentDate) {
                    showError = true;
                }
            }
        });
        return showError;
    }

    /**
     * 修正・案件コピーの処理を実行する
     */
    handleToAction = async () => {
        let copy = this.state.actionCopyFlag;
        let value = this.state.actionValue;
        this.handleToCancelAction();
        // 条件設定
        this.setModifyOrDetail(value);
        let condition = {};
        condition.case_id = this.state.selectedCaseId;
        // 画面状態リセット
        this.props.createCaseReset();
        // システムエラーのコールバック設定
        this.setErrorCallback(this.getCaseDetailReset);
        if (copy) {
            // インターンの場合
            if (this.state.selectedstatusOfResidence == '2') {
                // コピーの場合
                this.props.getCaseDetailCopy(condition);
            } else if (this.state.selectedstatusOfResidence == '1') {
                // コピーの場合
                this.props.getSpecificDetailCopy(condition);
            } else {
                // コピーの場合
                this.props.getRecruitmentDetailCopy(condition);
            }

        } else {
            if (this.state.selectedstatusOfResidence == '2') {
                // インターン　修正の場合
                await this.props.getCaseDetail(condition);
            } else if (this.state.selectedstatusOfResidence == '1') {
                // 特定技能　修正の場合
                await this.props.getSpecificDetail(condition);
            } else {
                // インターン、特定技能以外　修正の場合
                await this.props.getRecruitmentDetail(condition);
            }
            if (value === 1) {
                // ログインユーザ情報
                let userInfo = getCognitoUserInfo();
                if (!['INT', 'GNO', 'TOK'].includes(userInfo['org_type'])) {
                    let entrycondition = {};
                    entrycondition.case_id = this.state.selectedCaseId;
                    entrycondition.call_type = "1";
                    await this.props.getEntryList(entrycondition);
                }
            }
        }
    }

    /**
     * 修正・案件コピーのキャンセル処理
     */
    handleToCancelAction = () => {
        this.setState({
            actionCopyFlag: null,
            actionValue: null,
        });
    }

    /**
     * 案件新規登録メニュー表示・非表示
     */
    showShikitorokuMenu = () => {
        if (this.state.createBtnToggleclass == 'bl_acoBtnWrapper js_acoBtn el_acoBtn__open') {
            this.setState({
                createBtnToggleclass: 'bl_acoBtnWrapper js_acoBtn',
                showShikitorokuMenu: 'hideShikitorokuMenu'
            });
        } else {
            this.setState({
                createBtnToggleclass: 'bl_acoBtnWrapper js_acoBtn el_acoBtn__open',
                showShikitorokuMenu: ''
            });
        }
    }

    /**
     * 案件削除
     */
    deleteCase = () => {
        window.scrollTo(0, 0);
        this.props.deleteCase({ 'case_id': this.state.selectedCaseId });
    }

    /**
     * 案件削除確認メッセージ
     */
    showDeleteCaseConfirm = () => {
        window.scrollTo(0, 0);
        if (this.state.selectedCaseId == "") {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            let showError = this.checkCaseEditStatus();
            let showError2 = this.checkCaseEditDate();
            if (showError) {
                // (募集開始)取消不可のメッセージボックスを表示する
                this.showModalDialog(3, getMessage('RS-0029'), this.deleteCaseConfirmCancel);
            } else if (showError2) {
                // (募集終了)取消不可のメッセージボックスを表示する
                this.showModalDialog(3, getMessage('RS-0039'), this.deleteCaseConfirmCancel);
            } else {
                // 取消確認のメッセージボックスを表示する
                this.showModalDialog(1, getMessage('RS-0030'), this.deleteCase, this.deleteCaseConfirmCancel);
            }
        }
    }

    /**
     * 案件削除確認メッセージを閉じる
     */
    deleteCaseConfirmCancel = () => {

    }

    render() {
        if (this.props.isDelete && this.props.err_type === '200') {
            // 削除成功の場合、再検索する
            this.handleToQuery();
        }

        if (this.state.search_case_id != null && this.state.gamenId != null) {
            // ホーム画面から遷移する場合、検索を行う
            this.setState({
                gamenId: null
            }, () => {
                this.handleToQuery();
            });

        }

        // ログインユーザ情報
        let userInfo = getCognitoUserInfo();

        if (this.props.datadetail && this.props.detail_err_type === '200') {
            // 案件編集画面へ移動する
            if (this.state.modifyordetail === 0) {
                if (this.state.selectedstatusOfResidence == '2') {
                    // インターンの場合
                    this.props.changeDispWithData("GRSMMIMR01", this.props.data, this.props.datadetail);
                } else if (this.state.selectedstatusOfResidence == '1') {
                    // 特定技能の場合
                    this.props.changeDispWithData("GRSMMRMR31", this.props.data, this.props.datadetail);
                } else {
                    // インターン、特定技能以外の場合
                    this.props.changeDispWithData("GRSMMRMR01", this.props.data, this.props.datadetail);
                }
            } else {
                if (this.state.selectedstatusOfResidence == '2') {
                    if (!['INT', 'GNO', 'TOK'].includes(userInfo['org_type'])) {
                        if (this.props.entryList && this.props.err_entry_type === '200') {
                            // 案件詳細画面へ移動する
                            this.props.changeDispWithData("interndetail", this.props.data, this.props.datadetail);
                        }
                    } else {
                        // 案件詳細画面へ移動する
                        this.props.changeDispWithData("interndetail", this.props.data, this.props.datadetail);
                    }
                } else if (this.state.selectedstatusOfResidence == '1') {
                    if (!['INT', 'GNO', 'TOK'].includes(userInfo['org_type'])) {
                        if (this.props.entryList && this.props.err_entry_type === '200') {
                            // 案件詳細画面へ移動する
                            this.props.changeDispWithData("GRSMMRMD02", this.props.data, this.props.datadetail);
                        }
                    } else {
                        // 案件詳細画面へ移動する
                        this.props.changeDispWithData("GRSMMRMD02", this.props.data, this.props.datadetail);
                    }
                } else {
                    if (!['INT', 'GNO', 'TOK'].includes(userInfo['org_type'])) {
                        if (this.props.entryList && this.props.err_entry_type === '200') {
                            // 案件詳細画面へ移動する
                            this.props.changeDispWithData("GRSMMRMD01", this.props.data, this.props.datadetail);
                        }
                    } else {
                        // 案件詳細画面へ移動する
                        this.props.changeDispWithData("GRSMMRMD01", this.props.data, this.props.datadetail);
                    }

                }
            }
        }

        if (this.props.datadetailcopy && this.props.detail_copy_err_type === '200') {
            // コピーの時に、保険加入と周辺サービスを初期化
            this.props.datadetailcopy.insurance_flg = null
            this.props.datadetailcopy.immigration_service_flg = null
            this.props.datadetailcopy.move_service_flg = null
            this.props.datadetailcopy.education_service_before_flg = null
            this.props.datadetailcopy.education_service_after_flg = null

            // 案件編集画面(コピー)へ移動する
            if (this.state.selectedstatusOfResidence == '2') {
                // インターンの場合
                this.props.changeDispWithData("GRSMMIMR01", this.props.data, this.props.datadetailcopy);
            } else if (this.state.selectedstatusOfResidence == '1') {
                // 特定技能の場合
                this.props.changeDispWithData("GRSMMRMR31", this.props.data, this.props.datadetailcopy);
            } else {
                // インターン、特定技能以外の場合
                this.props.changeDispWithData("GRSMMRMR01", this.props.data, this.props.datadetailcopy);
            }
        }

        // 並べ替え順定義する
        let sortOptions = Object.entries(getCodeList('RSC0013')).map(
            ([key, value]) => (
                <option key={value.code} value={value.code}>
                    {value.code_name}
                </option>
            )
        );

        // エントリーステータス定義する
        let entryStatusStates = getCodeList('RSC0001');
        let entryOptions = Object.entries(entryStatusStates).map(
            ([key, value]) => (
                <option key={value.code} value={value.code} selected={this.state.search_candidate_status == value.code}>
                    {value.code_name}
                </option>
            )
        );

        // 新規登録
        let shinkiTorokuButtonShow = false;
        // 修正
        let caseEditButtonShow = false;
        // 削除
        let caseCancelButtonShow = false;
        // コピー作成
        let caseEditCopyButtonShow = false;
        if (['COP', 'KAN', 'TOR'].includes(userInfo['org_type'])) {
            // ログインユーザの組織タイプ＝"COP"　または
            // ログインユーザの組織タイプ＝"KAN"　または
            // ログインユーザの組織タイプ＝"TOR"　の場合
            // 　ボタンを表示する。
            // 上記以外のログインユーザの組織タイプの場合
            // 　ボタンを非表示にする。
            shinkiTorokuButtonShow = true;
            caseEditButtonShow = true;
            caseCancelButtonShow = true;
            caseEditCopyButtonShow = true;
        }
        // エントリープルダウン
        // 組織タイプ＝'2'(インターン)または'1'(技能実習)の場合、プルダウンを表示しない
        let entryPulldownShow = false;
        if (!['INT', 'GNO', 'TOK'].includes(userInfo['org_type'])) {
            entryPulldownShow = true;
        }

        // 件数が100件を超える場合
        if (this.props.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                案件一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li>
                                    案件一覧
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.detail_err_type} err_detail={this.props.detail_err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.detail_copy_err_type} err_detail={this.props.detail_copy_err_detail} messageMap={MessageMap} />
                                {
                                    (
                                        () => {
                                            return (
                                                this.state.err_msg.length > 0 ? <div id='errorarea'>
                                                    <ul className='errorArea'>
                                                        {
                                                            Object.entries(this.state.err_msg).map(
                                                                ([key]) => (
                                                                    <li key={key}>{this.state.err_msg[key]}</li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </div> : null
                                            );
                                        }
                                    )()
                                }
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                    </div>
                                    {/* <!-- /.bl_serchBtn --> */}
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>
                                                        <TextInput type="text" className="el_input el_input__max" name="search_company_name" maxlength="60" setValue={this.state.search_company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>施設名</th>
                                                    <td>
                                                        <TextInput type="text" className="el_input el_input__max" name="search_facility_name" maxlength="60" setValue={this.state.search_facility_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="search_gno" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} tabIndex="4" />
                                                                <span className="el_checkbox_txt">技能実習</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="search_tok" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} tabIndex="5" />
                                                                <span className="el_checkbox_txt">特定技能</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="search_int" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} tabIndex="6" />
                                                                <span className="el_checkbox_txt">インターン</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>
                                                        <TextInput className="el_input el_input__mid" name="search_case_id" type="text" maxlength="11" setValue={this.state.search_case_id} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>
                                                        <TextInput className="el_input el_input__max" name="search_case_title" type="text" maxlength="60" setValue={this.state.search_case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>募集期間</th>
                                                    <td>
                                                        <TextInput placeholder="年/月/日" className="el_input el_input__datepicker" name="pubPeriodStart" type="date" setValue={this.state.pubPeriodStart} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" /> 〜
                                                        <TextInput placeholder="年/月/日" className="el_input el_input__datepicker" name="pubPeriodEnd" type="date" setValue={this.state.pubPeriodEnd} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="10" /> まで
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業希望期間</th>
                                                    <td>
                                                        <TextInput placeholder="年/月/日" className="el_input el_input__datepicker" name="acceptPeriodStart" type="date" setValue={this.state.acceptPeriodStart} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="11" /> 〜
                                                        <TextInput placeholder="年/月/日" className="el_input el_input__datepicker" name="acceptPeriodEnd" type="date" setValue={this.state.acceptPeriodEnd} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="12" /> まで
                                                    </td>
                                                </tr>
                                                {entryPulldownShow ?
                                                    <tr>
                                                        <th>エントリー</th>
                                                        <td>
                                                            <select className="el_select" name="search_candidate_status" required value={this.state.search_candidate_status} onChange={this.setEntryStatus} tabIndex="13">
                                                                <option value="" selected></option>
                                                                {entryOptions}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                    : null}
                                            </tbody>
                                        </table>

                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToQuery()} tabIndex="14">検索</button>
                                        </div>
                                    </div>
                                    <section className="bl_sect">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv3Heading"><span>募集案件一覧</span></h2>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__multi">
                                            {shinkiTorokuButtonShow ? <div className={this.state.createBtnToggleclass}>
                                                <button type="button" name="" onClick={() => this.showShikitorokuMenu()} tabIndex="15">新規登録</button>
                                                <ul className={this.state.showShikitorokuMenu}>
                                                    {['TOR'].includes(userInfo['org_type']) ? null : <li><a href="#!" onClick={() => this.handleToDisplay("GRSMMIMR01")} >インターン</a></li>}
                                                    {['TOR'].includes(userInfo['org_type']) ? null : <li><a href="#!" onClick={() => this.switchRecruitmentDisplay("GRSMMRMR01", "0")} >技能実習</a></li>}
                                                    {['KAN'].includes(userInfo['org_type']) ? null : <li><a href="#!" onClick={() => this.switchRecruitmentDisplay("GRSMMRMR31", "1")} >特定技能</a></li>}
                                                </ul>
                                            </div>
                                                : null}
                                            {caseEditButtonShow ? <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToModify(false, 0)} tabIndex="16">修正</button> : null}
                                            {caseCancelButtonShow ? <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.showDeleteCaseConfirm()} tabIndex="17">削除</button> : null}
                                            {caseEditCopyButtonShow ? <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToModify(true, 0)} tabIndex="18">コピー作成</button> : null}
                                            <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToModify(false, 1)} tabIndex="19">詳細表示</button>
                                        </div>
                                        <div className="bl_sortBoxUnit">
                                            <dl className="bl_sortBox bl_sortBox__r">
                                                <dt className="bl_sortBox_ttl">並べ替え</dt>
                                                <dd>
                                                    <select className="el_select el_select__auto" name="term" required value={this.state.sortStates} onChange={this.setSortStates} tabIndex="20">
                                                        {sortOptions}
                                                    </select>
                                                </dd>
                                            </dl>
                                        </div>

                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr><th>選択</th><th>案件ID</th><th>ステータス</th><th>在留資格</th><th>企業名</th><th>施設名</th><th>案件名</th><th>募集期間</th><th>受入・就業希望期間</th><th>募集職種</th><th>募集人数</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (this.props.data !== undefined && this.props.data.length > 0) {
                                                            return (
                                                                this.props.data.map((item, index) => (
                                                                    (this.state.displayStatus === item.status && item.entered_flg !== 0) || this.state.displayStatus === 6 ||
                                                                        (this.state.displayStatus === 5 && item.entered_flg === 0) ?
                                                                        <tr key={item.case_id}>
                                                                            <td><input type="radio" name="row_select" value={index}
                                                                                onChange={this.handleRowSelection} tabIndex={21 + index} /></td>
                                                                            <td>{item.case_id}</td>
                                                                            <td>{getStatus(item)}</td>
                                                                            <td>{getCodeName('RSC0004', item.status_of_residence)}</td>
                                                                            <td>{item.company_name}</td>
                                                                            <td>{item.facility_name}</td>
                                                                            <td>{item.case_title}</td>
                                                                            <td className="tdreturn">{item.pub_period_start.replace(/\-/g, "/") + "\n" + "〜" + "\n" + item.pub_period_end.replace(/\-/g, "/")}</td>
                                                                            <td className="tdreturn">{item.accept_period_start.replace(/\-/g, "/") + "\n" + "〜" + "\n" + item.accept_period_end.replace(/\-/g, "/")}</td>
                                                                            <td>{item.occupation}</td>
                                                                            <td>{getNumberText(item.applicant)}</td>
                                                                        </tr>
                                                                        : null
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </article >
        );
    }
}

/**
 * 案件一覧画面一覧データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        data: state.getCaseList.overViewData,
        overflowflg: state.getCaseList.overflowflg,
        isDelete: state.getCaseList.isDelete,
        err_type: state.getCaseList.err_type,
        err_detail: state.getCaseList.err_detail,
        datadetail: state.getCaseDetail.overViewData,
        detail_err_type: state.getCaseDetail.err_type,
        detail_err_detail: state.getCaseDetail.err_detail,
        datadetailcopy: state.getCaseDetailCopy.overViewData,
        detail_copy_err_type: state.getCaseDetailCopy.err_type,
        detail_copy_err_detail: state.getCaseDetailCopy.err_detail,
        entryList: state.getEntryList.overViewData,
        err_entry_type: state.getEntryList.err_type,
        err_entry_detail: state.getEntryList.err_detail
    };
};

/**
 * 案件一覧画面一覧データ取得のActionバインディングする
 * @param {*} dispatch
 */
function mapDispatchToProps(dispatch) {
    return {
        getCaseList(condition) {
            // 案件一覧情報取得
            dispatch(getCaseList(condition));
        }, getCaseDetail(condition) {
            // 案件詳細情報取得
            dispatch(getCaseDetail(condition));
        }, getCaseDetailCopy(condition) {
            // 案件コピー情報取得
            dispatch(getCaseDetailCopy(condition));
        }, createCaseReset() {
            // 画面状態リセット
            dispatch(CreateCaseResetAllData());
            dispatch(CreateRecruitmentCaseResetAllData());
            dispatch(CreateRecruitmentCaseSpecialSkillResetAllData());
        }, deleteCase(condition) {
            // 案件削除
            dispatch(deleteCase(condition));
        }, getEntryList(condition) {
            // エントリリスト取得
            dispatch(getEntryList(condition));
        }, entryListReset() {
            dispatch(EntryListReset());
        }, getRecruitmentDetail(condition) {
            // 求人案件詳細情報取得
            dispatch(getRecruitmentDetail(condition));
        }, getRecruitmentDetailCopy(condition) {
            // 求人案件コピー情報取得
            dispatch(getRecruitmentDetailCopy(condition));
        }, getSpecificDetail(condition) {
            // 求人案件詳細情報取得
            dispatch(getSpecificDetail(condition));
        }, getSpecificDetailCopy(condition) {
            // 求人案件コピー情報取得
            dispatch(getSpecificDetailCopy(condition));
        }, getInterviewHistoryReset() {
            // WEB面接リセット
            dispatch(getInterviewHistoryReset());
        }, getCaseCandidateListReset() {
            // 応募者一覧情報stateリセット
            dispatch(GetCaseCandidateListReset());
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMMLI01);

