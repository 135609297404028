/**
 * Action Typesを定義
 */
const GRSMMIMD02ActionTypes = {
    CASE_ENTRY_LIST_ACTION_REQUEST: 'CASE_ENTRY_LIST_ACTION_REQUEST',
    CASE_ENTRY_LIST_ACTION_SUCCESS: 'CASE_ENTRY_LIST_ACTION_SUCCESS',
    CASE_ENTRY_LIST_ACTION_FAILURE: 'CASE_ENTRY_LIST_ACTION_FAILURE',
    CASE_ENTRY_LIST_ACTION_RESET: 'CASE_ENTRY_LIST_ACTION_RESET',
    SELECTED_CANDIDATE_LIST_ACTION_REQUEST: 'SELECTED_CANDIDATE_LIST_ACTION_REQUEST',
    CASE_ENTRY_LIST_UPDATE_ACTION_REQUEST: 'CASE_ENTRY_LIST_UPDATE_ACTION_REQUEST',
    CASE_ENTRY_LIST_UPDATE_ACTION_SUCCESS: 'CASE_ENTRY_LIST_UPDATE_ACTION_SUCCESS',
    CASE_ENTRY_LIST_UPDATE_ACTION_FAILURE: 'CASE_ENTRY_LIST_UPDATE_ACTION_FAILURE',
    CASE_ENTRY_LIST_UPDATE_ACTION_RESET: 'CASE_ENTRY_LIST_UPDATE_ACTION_RESET',
};

export default GRSMMIMD02ActionTypes;