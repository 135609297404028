import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import { MessageMap } from "./GTMWTWTR01Message";
import { saveWorkingtime, saveWorkingTimeReset } from "../WtGTMWTWTR01/GTMWTWTR01Action";
import { getWorkingtimeInquiry, workingtimeInquiryReset } from "../WtGTMWTWTI01/GTMWTWTI01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 作業時間登録
 */
class GTMWTWTR01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.props.saveWorkingTimeReset();
        this.state = {
            work_start_time: '',
            work_end_time: '',
            day_flg: '',
            rest_minute: '',
            rest_flg: '',
            late_early_flg: '0',
            isDisabled: false,
            isNewDisabled: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { detailData } = this.props;
        this.setState({
            work_start_time: detailData.work_start_time,
            work_end_time: detailData.work_end_time,
            rest_minute: detailData.rest_minute,
            day_flg: isBlank(detailData.day_flg) ? '0' : detailData.day_flg,
            rest_flg: isBlank(detailData.rest_flg) ? '0' : detailData.rest_flg,
            version_no: detailData.version_no,
            late_early_flg: isBlank(detailData.late_early_flg) ? '0' : detailData.late_early_flg
        });

        this.setState({
            isDisabled: !isBlank(detailData.work_approval_status) && detailData.work_approval_status === '1' ? true : false,
            rest_minute: ((!isBlank(detailData.work_approval_status) && detailData.work_approval_status === '1') || detailData.rest_flg != '0') && detailData.rest_minute == 0 ? null : detailData.rest_minute,
            isNewDisabled: detailData.rest_flg == '1' || detailData.rest_flg == '2' ? true : false
        });

        window.scrollTo(0, 0);
    }

    /**
     * チェックボックスのon/offを変更する
     */
    handleCheckboxChange = (event) => {
        const { checked, name } = event.target;

        let chk = checked ? '1' : '0';
        this.setState({
            [name]: chk
        }, () => {
            this.editItemHandler(name, chk);
        });
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * ドロップダウンリスト変更
     */
    workrestHandleChange = (event) => {
        this.setState({
            isNewDisabled: false
        });

        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });

        this.setState({
            isNewDisabled: value == '1' || value == '2' ? true : false,
            work_start_time: value == '1' || value == '2' ? '' : this.state.work_start_time,
            work_end_time: value == '1' || value == '2' ? '' : this.state.work_end_time,
            day_flg: value == '1' || value == '2' ? '0' : this.state.day_flg,
            rest_minute: value == '1' || value == '2' ? '' : this.state.rest_minute,
            late_early_flg: value == '1' || value == '2' ? '0' : this.state.late_early_flg
        });
    };

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.saveWorkingTimeReset();
        this.props.workingtimeInquiryReset();
    }

    /**
    * 登録
    */
    saveWorkingTime = () => {
        window.scrollTo(0, 0);
        this.props.saveWorkingTimeReset();
        const { detailData } = this.props;
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        let params = Object.assign({}, this.state);
        params['work_report_date'] = detailData.work_report_date;
        params['user_id'] = detailData.user_id;
        params['case_id'] = detailData.case_id;
        params['version_no'] = this.state.version_no;

        this.props.saveWorkingtime(params);
    }

    /**
     * 作業時間照会画面へ切替
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToInquiry = () => {
        const { detailData } = this.props;
        this.saveReset();

        this.props.switchDisplayInquiry("GTMWTWTI01", detailData);
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { isDisabled, isNewDisabled, version_no } = this.state;
        // 休暇区分
        const vacation = getCodeValueLabelList('RSC0023');
        // 遅刻／早退
        const late_early = getCodeValueLabelList('RSC0024');

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.version_no) && version_no !== this.props.data.version_no) {
                this.editItemHandler('version_no', this.props.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.props.saveWorkingTimeReset();
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">作業時間登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                {this.props.beforeDispId === 'GTMWTWTI01' ?
                                    <Fragment>
                                        <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GAFMCMCFWT01'); }, () => { })}>ユーザ一覧</a></li>
                                        <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToInquiry(); }, () => { })}>作業時間照会</a></li>
                                    </Fragment>
                                    : null
                                }
                                {this.props.beforeDispId === 'GTMWTWTR02' ?
                                    <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GTMWTWTR02'); }, () => { })}>簡易作業時間登録</a></li>
                                    : null
                                }
                                <li>作業時間登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <div className="sticky_table_wrapper" disabled={true}>
                                            <table className="sticky_table mcTable_align sticky_table_workTime">
                                                <thead>
                                                    <tr>
                                                        <th>日付</th>
                                                        <th>開始時間</th>
                                                        <th>終了時間</th>
                                                        <th>休憩時間</th>
                                                        <th>休暇区分</th>
                                                        <th>遅刻／早退</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="hp_tar">{this.props.detailData.year_month}</td>
                                                        <td className="hp_tac">
                                                            <TextInput type="time" name="work_start_time" className={isDisabled || isNewDisabled ? "el_input el_input__time is_disabled " : "el_input el_input__time"} setValue={this.state.work_start_time} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={isDisabled || isNewDisabled} tabIndex="1" />
                                                        </td>
                                                        <td className="hp_tac">
                                                            <TextInput type="time" name="work_end_time" className={isDisabled || isNewDisabled ? "el_input el_input__time is_disabled " : "el_input el_input__time"} setValue={this.state.work_end_time} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={isDisabled || isNewDisabled} tabIndex="2" />
                                                            <div className="bl_formUnit">
                                                                <label className="el_label">
                                                                    <input className="el_checkbox" type="checkbox" name="day_flg" checked={this.state.day_flg === '1'} onChange={this.handleCheckboxChange} disabled={isDisabled || isNewDisabled} tabIndex="3" />
                                                                    <span className="el_checkbox_txt">翌日</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                        <td className="hp_tac hp_noWrap">
                                                            <TextInput type="text" name="rest_minute" className={isDisabled || isNewDisabled ? "el_input el_input__countryCode is_disabled " : "el_input el_input__countryCode"} setValue={this.state.rest_minute} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={isDisabled || isNewDisabled} tabIndex="4" />分
                                                        </td>
                                                        <td className="hp_tac">
                                                            <select className={isDisabled ? "el_select el_select__w80per is_disabled" : "el_select el_select__w80per"} name="rest_flg" onChange={this.workrestHandleChange} disabled={isDisabled} tabIndex="5">
                                                                {checkArray(vacation) && vacation.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.rest_flg === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                        <td className="hp_tac">
                                                            <select className={isDisabled || isNewDisabled ? "el_select is_disabled el_select__w80per" : "el_select el_select__w80per"} name="late_early_flg" onChange={this.handleChange} disabled={isDisabled || isNewDisabled} tabIndex="6">
                                                                {checkArray(late_early) && late_early.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.late_early_flg === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveWorkingTime()} tabIndex="7">登録</button>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToInquiry(); }, () => { })} tabIndex="8">作業時間照会へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

/**
 * データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.saveWorkingtime.isFetching,
        data: state.saveWorkingtime.data,
        isError: state.saveWorkingtime.isError,
        err_type: state.saveWorkingtime.err_type,
        err_detail: state.saveWorkingtime.err_detail,
        isInquiryFetching: state.getWorkingtimeInquiry.isFetching,
        inquiryList: state.getWorkingtimeInquiry.data,
        isInquiryError: state.getWorkingtimeInquiry.isError,
        err_inquiry_type: state.getWorkingtimeInquiry.err_type,
        err_inquiry_detail: state.getWorkingtimeInquiry.err_detail,
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        // 作業時間照会取得
        getWorkingtimeInquiryList: (args) => dispatch(getWorkingtimeInquiry(args)),
        saveWorkingtime: (args) => dispatch(saveWorkingtime(args)),
        saveWorkingTimeReset: () => dispatch(saveWorkingTimeReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GTMWTWTR01);

