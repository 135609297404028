import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GIPPMPLI01 from "./PmGIPPMPLI01/GIPPMPLI01";
import GIPPMPMF01 from "./PmGIPPMPMF01/GIPPMPMF01";
import { getProgressManagementInfo } from "../Ip/PmGIPPMPMF01/GIPPMPMF01Action";

/**
 * 入出国手続き支援機能テンプレート
 */
class IpTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToView = (dispId, detailData) => {
        const beforeDispId = this.state.display;
        // 進捗管理
        if (dispId == 'GIPPMPMF01') {
            this.props.getProgressManagementInfo({
                case_id: detailData.case_id,
                status_of_residence: detailData.status_of_residence
            });
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId
            });
        }
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        this.setState({
            display: dispId,
            detailData: detailData
        }, () => {

        });
    }

    render() {
        let displayParts;
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
        if (this.state.display === "GIPPMPLI01") {
            displayParts = <GIPPMPLI01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} />;
        } else if (this.state.display === "GIPPMPMF01") {
            if (this.props.data.data && this.props.data.err_type === '200') {
                // 進捗管理
                displayParts = <GIPPMPMF01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} progressData={this.props.data.data} />;
            }
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // 進捗管理初期取得
        data: state.getProgressManagementInfo
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        getProgressManagementInfo: (arg) => dispatch(getProgressManagementInfo(arg))
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IpTemplate)
);

