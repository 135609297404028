/**
 * Action Typesを定義
 */
const GCMOMOIR02ActionType = {
    UPDATE_ORGANIZATION_MANAGEMENT_REQUEST: "UPDATE_ORGANIZATION_MANAGEMENT_REQUEST", // 組織管理更新定義
    UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS: "UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS", // 組織管理更新成功定義
    UPDATE_ORGANIZATION_MANAGEMENT_FAILURE: "UPDATE_ORGANIZATION_MANAGEMENT_FAILURE", // 組織管理更新失敗定義
    UPDATE_ORGANIZATION_MANAGEMENT_RESET:   "UPDATE_ORGANIZATION_MANAGEMENT_RESET",   // 組織管理更新リセット定義
};

export default GCMOMOIR02ActionType;
