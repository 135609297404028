import React from 'react';
import RsTemplate from "./template";

function Home() {
    return (
        <section className="App">
            <RsTemplate />
        </section>
    );
}

export default Home;
