import actionTypes from './GAFMCMCF01ActionType';

// レスポンスState定義
const menuSate = {
    showLoading: false,
    showError: false,
    callBack: null
};

/**
 * メニューステータス情報
 * @param {*} state
 * @param {*} action
 */
export const menu = (state = menuSate, action) => {

    switch (action.type) {
        case actionTypes.MENU_LOADING_SHOW:
            return Object.assign({}, state, {
                showLoading: true
            });
        case actionTypes.MENU_LOADING_CLOSE:
            return Object.assign({}, state, {
                showLoading: false
            });
        case actionTypes.MENU_ERROR_SHOW:
            return Object.assign({}, state, {
                showError: true
            });
        case actionTypes.MENU_ERROR_CLOSE:
            return Object.assign({}, state, {
                showError: false
            });
        case actionTypes.MENU_ERROR_CLOSE_CALLBACK:
            return Object.assign({}, state, {
                showError: false,
                callBack: action.callBack
            });
        default:
            return state;
    }

};
