import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GISIPWPR01ActionType';

// 実習計画登録初期取得API
const GET_TRAINING_PLAN_INFO_POST = BASE_URL + "/api/is_ip_get_working_plan_info";

// 実習計画登録API
const SAVE_TRAINING_PLAN_INFO_POST = BASE_URL + "/api/is_ip_update_working_plan_info";

// ********************Actionの作成 Start ********************

/**
 * 実習計画登録初期取得
 */
export const GetTrainingPlanRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_REQUEST
    };
};

/**
 * 実習計画登録初期取得成功
 */
export const GetTrainingPlanSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_SUCCESS,
        data
    };
};

/**
 * 実習計画登録初期取得失敗
 */
export const GetTrainingPlanFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_FAILURE,
        err
    };
};

/**
 * 実習計画登録初期取得リセット
 */
export const GetTrainingPlanReset = () => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_RESET
    };
};

/**
 * 登録リクエスト
 */
export const SaveTrainingPlanRequest = () => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const SaveTrainingPlanSuccess = (data) => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const SaveTrainingPlanFailure = (err) => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const SaveTrainingPlanReset = () => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 作業計画登録初期取得Actionの発行
 */
export const getTrainingPlanInfo = (data) => {
    return async (dispatch) => {
        // Storeへ作業計画登録初期取得リクエストActionをdispatchする
        dispatch(GetTrainingPlanRequest());
        ecoPostWithAuthHeader(GET_TRAINING_PLAN_INFO_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ作業計画登録初期取得成功Actionをdispatchする
                dispatch(GetTrainingPlanSuccess(res.data))
            ).catch(err =>
                // Storeへ作業計画登録初期取得失敗Actionをdispatchする
                dispatch(GetTrainingPlanFailure(err))
            );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const saveTrainingPlanInfo = (data) => {
    return async (dispatch) => {
        dispatch(SaveTrainingPlanRequest());
        ecoPostWithAuthHeader(SAVE_TRAINING_PLAN_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(SaveTrainingPlanSuccess(res.data))
        ).catch(
            err => dispatch(SaveTrainingPlanFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const saveTrainingPlanReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveTrainingPlanReset());
    };
};