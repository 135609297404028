import actionType from "./GISTTPTL01ActionType";

// レスポンスState定義
const infoState = {
    isFetching: false,
    data: [],
    overflowflg: "",
    isError: false,
    isDelete: false,
    err_type: "",
    err_detail: []
};

/**
 * 実習計画テンプレート一覧データ取得
 * @param {*} state
 * @param {*} action
 */
export const getTrainingPlanTemplateList = (state = infoState, action) => {
    switch (action.type) {
        case actionType.GET_TRAINING_PLAN_TEMPLATE_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                overflowflg: "",
                isDelete: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.GET_TRAINING_PLAN_TEMPLATE_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data.list,
                overflowflg: action.data.data.overflowflg,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.GET_TRAINING_PLAN_TEMPLATE_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                overflowflg: "",
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.GET_TRAINING_PLAN_TEMPLATE_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                overflowflg: "",
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DELETE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isDelete: false,
                err_type: "",
                err_detail: []
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DELETE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                isDelete: true,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DELETE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                isDelete: false,
                err_type: action.err,
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 実習計画テンプレート詳細データを取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getTrainingPlanTemplateDetail = (state = infoState, action) => {
    switch (action.type) {
        case actionType.TRAINING_PLAN_TEMPLATE_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.TRAINING_PLAN_TEMPLATE_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
