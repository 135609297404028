import actionTypes from './GESEQQRI01ActionType';

/**
 * アンケート初期取得登録（実習生用）
 */
const initialState = {
    isFetching: false,
    data: {},
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * アンケート初期取得登録（実習生用）を取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getTrainingEnqueteAnswerInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 進捗一覧のダウンロードReducer
 * @param {*} state
 * @param {*} action
 */
export const updateTrainingEnqueteAnswerInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
