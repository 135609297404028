import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCRU01ActionType';

// 組織情報(プロフィール)・企業情報・企業施設情報取得API
const GET_COMPANY_AND_FACILITY_INFO_POST = BASE_URL + "/api/cm_om_get_company_and_facility_info";

// 組織情報(プロフィール)・企業情報・企業施設情報更新API
const UPDATE_COMPANY_AND_FACILITY_INFO_POST = BASE_URL + "/api/cm_om_update_company_and_facility_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetCompanyAndFacilityInfoRequest = () => {
    return {
        type: actionTypes.GET_COMPANY_AND_FACILITY_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetCompanyAndFacilityInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_AND_FACILITY_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetCompanyAndFacilityInfoFailure = (err) => {
    return {
        type: actionTypes.GET_COMPANY_AND_FACILITY_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetCompanyAndFacilityInfoReset = () => {
    return {
        type: actionTypes.GET_COMPANY_AND_FACILITY_INFO_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateCompanyAndFacilityInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateCompanyAndFacilityInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateCompanyAndFacilityInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateCompanyAndFacilityInfoReset = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getCompanyAndFacilityInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetCompanyAndFacilityInfoRequest());
        ecoPostWithAuthHeader(GET_COMPANY_AND_FACILITY_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetCompanyAndFacilityInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetCompanyAndFacilityInfoFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateCompanyAndFacilityInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateCompanyAndFacilityInfoRequest());
        ecoPostWithAuthHeader(UPDATE_COMPANY_AND_FACILITY_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateCompanyAndFacilityInfoSuccess(res.data))
        ).catch(
            err => dispatch(UpdateCompanyAndFacilityInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getCompanyAndFacilityInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetCompanyAndFacilityInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateCompanyAndFacilityInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateCompanyAndFacilityInfoReset());
    };
};