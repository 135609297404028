import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

/**
 * @param {*} props
 */
export default function DoubleLineChart(props) {
    const { data, lineonekey, linetwokey } = props;
    let isLineCharShow = false;
    data.map(item => {
        if (!isLineCharShow) {
            if (item[lineonekey] || item[linetwokey]) {
                isLineCharShow = true;
            }
        }
    });

    const renderColorfulLegendText = (value: string, entry: any) => {
        let { color } = entry;
        color = '#000000'
        return <span style={{ color }}>{value}</span>;
    };
    return (
        <div style={{ width: '100%', height: 450 }}>
            <div style={{
                width: '100%',
                textAlign: 'center',
                'font-size': '2.5rem',
                width: '100 %',
                'font-weight': 'normal',
                'line-height': 50
            }}>{props.title}</div>
            {
                isLineCharShow ?
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            width="100%"
                            height={200}
                            data={props.data}
                            margin={{
                                top: 30,
                                right: 30,
                                left: 5,
                                bottom: 10,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" vertical={props.vertical || false} />
                            <XAxis height={60} tickMargin={10} dataKey={props.xkey} />
                            <YAxis domain={[0, 5]} ticks={[0, 1, 2, 3, 4, 5]} label={{ value: props.ylabel, angle: -90, position: 'insideLeft' }} />
                            <Legend formatter={renderColorfulLegendText} />
                            <Tooltip />
                            <Line connectNulls type="linear" dataKey={props.lineonekey} stroke="#f59511" fill="#f59511" />
                            <Line connectNulls type="linear" dataKey={props.linetwokey} stroke="#8884d8" fill="#8884d8" />
                        </LineChart>
                    </ResponsiveContainer>
                    :
                    <div style={{
                        textAlign: 'center',
                        'font-size': '5.0rem',
                        'font-weight': 'normal',
                        border: '1px solid #bfbfbf',
                        'background-color': 'white',
                        position: 'relative',
                        height: 400,
                        'line-height': 400,
                        width: '100%',
                        'z-index': 9999,
                    }}>
                        <div style={{ 'line-height': 55 }}>
                            <br />
                            <br />
                            <br />
                            評価設定履歴が無いため、
                            <br />
                            評価グラフは表示できません
                        </div>
                    </div>
            }
        </div >
    );
}
