/**
 * Action Typesを定義
 */
const GUIULUIP02ActionType = {
    GET_USEFUL_INFO_DETAIL_REQUEST: 'GET_USEFUL_INFO_DETAIL_REQUEST',  // お役立ち情報取得定義
    GET_USEFUL_INFO_DETAIL_SUCCESS: 'GET_USEFUL_INFO_DETAIL_SUCCESS',  // お役立ち情報取得成功定義
    GET_USEFUL_INFO_DETAIL_FAILURE: 'GET_USEFUL_INFO_DETAIL_FAILURE',  // お役立ち情報取得失敗定義
    GET_USEFUL_INFO_DETAIL_RESET: 'GET_USEFUL_INFO_DETAIL_RESET',      // お役立ち情報取得リセット定義
};

export default GUIULUIP02ActionType;