import React from 'react';
import CmTemplate from "./template";

function CmContainer() {
    return (
        <section className="App">
            <CmTemplate />
        </section>
    );
}

export default CmContainer;
