import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCodeValueLabelList, getCountryMasterList, getMessage } from "../../Constants/CommonConstants";
import { imageResize } from "../../Components/Tools/ImageTools";
import { updateUniversityInfo, updateUniversityInfoReset } from "./GCMOMCRU04Action";
import { MessageMap } from "./GCMOMCRU04Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import { getNumberText } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 組織情報(プロフィール)大学
 */
class GCMOMCRU04 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            countryOpt: [],
            universityInfo: this.props.detailData
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { universityInfo } = this.state;

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });
        universityInfo.people = getNumberText(universityInfo.people);
        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
            university_name: universityInfo.university_name,
            university_logo: universityInfo.university_logo,
            university_location: universityInfo.university_location,
            university_start_year: universityInfo.university_start_year,
            teacher_name: universityInfo.teacher_name,
            teacher_mail: universityInfo.teacher_mail,
            teacher_tel_country_name: universityInfo.teacher_tel_country_name,
            teacher_tel_country_code: universityInfo.teacher_tel_country_code,
            teacher_tel1: universityInfo.teacher_tel1,
            teacher_tel2: universityInfo.teacher_tel2,
            teacher_tel3: universityInfo.teacher_tel3,
            english_level: universityInfo.english_level,
            japanese_level: universityInfo.japanese_level,
            jpteacher: universityInfo.jpteacher,
            people: universityInfo.people,
            study_period: universityInfo.study_period,
            section: universityInfo.section
        }), () => {
            countryRet.forEach(country => {
                // 担当者電話番号
                if (country.country_code === this.state.teacher_tel_country_code) {
                    this.setState({
                        teacher_tel_country_name: country.country_name,
                        teacher_tel_country_code: country.country_code,
                        teacher_tel_country_number: country.country_number
                    });
                }
            });
        });
        window.scrollTo(0, 0);
    }

    /**
     * 画像を変更
     * @param {*} event
     */
    handleImageUpload = (event) => {
        imageResize(event.target, (data) => {
            this.setState({
                university_logo: data
            }, () => {
                this.editItemHandler('university_logo', this.state.university_logo);
            });
        });
    }

    /**
     * 画像を削除
     */
    handleImageDelete = () => {
        this.setState({
            university_logo: null
        }, () => {
            this.editItemHandler('university_logo', this.state.university_logo);
        });
    };

    /**
     * 代表者電話番号の変更
     * @param {*} event
     */
    handleTelCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.teacher_tel_country_name === '') {
                this.setState({
                    teacher_tel_country_number: '',
                    teacher_tel_country_code: ''
                }, () => {
                    this.editItemHandler("teacher_tel_country_code", this.state.teacher_tel_country_code);
                    this.editItemHandler("teacher_tel_country_name", this.state.teacher_tel_country_name);
                    this.editItemHandler("teacher_tel_country_number", this.state.teacher_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.teacher_tel_country_name) {
                    this.setState({
                        teacher_tel_country_code: country.country_code,
                        teacher_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("teacher_tel_country_code", this.state.teacher_tel_country_code);
                        this.editItemHandler("teacher_tel_country_name", this.state.teacher_tel_country_name);
                        this.editItemHandler("teacher_tel_country_number", this.state.teacher_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.universityInfoReset();
    }

    /**
    * 登録
    */
    saveUniversityInfo = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        let reqData = JSON.parse(JSON.stringify(this.state.universityInfo));
        this.props.createUniversityInfo(reqData);
    }

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            universityInfo: {
                ...prevState.universityInfo,
                [name]: value
            }
        }));
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    render() {
        const { universityInfo } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && universityInfo.version_no != this.props.data.version_no) {
                this.editItemHandler('version_no', this.props.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => { });
            }
        }

        // 日本語レベル
        const japaneseLevel = getCodeValueLabelList('RSC0006');

        // 英語レベル
        const englishLevel = getCodeValueLabelList('RSC0020');

        // 設置区分
        const sectionOpt = getCodeValueLabelList('RSC0021');

        // 修業期間
        const trainingPeriod = getCodeValueLabelList('RSC0022');

        // 日本語教育専門の先生の有無
        const japaneseTeacher = getCodeValueLabelList('RSC0011');

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織情報(プロフィール)</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">登録プロフィール情報</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        大学名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="university_name" className="el_input el_input__max" maxlength="60" required="required" setValue={universityInfo.university_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ロゴ</th>
                                                    <td>
                                                        <div className="bl_upload_box__applicant">
                                                            <figure>
                                                                <img src={this.state.university_logo == null ? 'data:,' : this.state.university_logo} alt="" />
                                                            </figure>
                                                            <div className="bl_btns">
                                                                <label htmlFor="changeImg" onClick={event => event.target.value = null} className="el_smBlueBtn" tabIndex="2">
                                                                    画像を選択<br />Select image
                                                                    <input type="file" id="changeImg" className="el_sendFile" name="university_image" accept="image/*"
                                                                        onChange={this.handleImageUpload} />
                                                                </label>
                                                                <label htmlFor="deleteImg" className="el_smBlueBtn" tabIndex="3">
                                                                    画像を削除<br />Delete image
                                                                    <input type="button" id="deleteImg" className="el_sendFile" onClick={this.handleImageDelete} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        所在地
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="university_location" className="el_input el_input__max" maxlength="60" required="required" setValue={universityInfo.university_location} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        設立日(年)
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="university_start_year" className="el_input el_input__sm" maxlength="4" required="required" setValue={universityInfo.university_start_year} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                        <span className="el_form_txt">年</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        担当先生名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="teacher_name" className="el_input el_input__max" maxlength="40" required="required" setValue={universityInfo.teacher_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        担当先生メールアドレス
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="teacher_mail" className="el_input el_input__max" maxlength="81" required="required" setValue={universityInfo.teacher_mail} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        担当者電話番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="teacher_tel_country_name" onChange={this.handleTelCountryNameChange} tabIndex="8">
                                                                <option value="" selected ></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.teacher_tel_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="teacher_tel_country_number" className="el_input el_input__countryCode" setValue={this.state.teacher_tel_country_number} maxlength="6" readOnly={true} />
                                                        <TextInput type="text" name="teacher_tel1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={universityInfo.teacher_tel1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="teacher_tel2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={universityInfo.teacher_tel2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="10" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="teacher_tel3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={universityInfo.teacher_tel3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="11" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>英語レベル</th>
                                                    <td>
                                                        <select className="el_select el_select_country" name="english_level" onChange={this.handleChange} tabIndex="12">
                                                            <option value="" selected ></option>
                                                            {
                                                                englishLevel && englishLevel.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.english_level === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>日本語レベル</th>
                                                    <td>
                                                        <select className="el_select el_select_country" name="japanese_level" onChange={this.handleChange} tabIndex="13">
                                                            <option value="" selected ></option>
                                                            {
                                                                japaneseLevel && japaneseLevel.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.japanese_level === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>日本語教育専門の先生の有無</th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb10">
                                                            <input type="radio" name="jpteacher" value={japaneseTeacher[1].value} checked={this.state.jpteacher === japaneseTeacher[1].value} onChange={this.handleChange} tabIndex="14" />
                                                            <span className="hp_mr20">{japaneseTeacher[1].label}</span>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="jpteacher" value={japaneseTeacher[0].value} checked={this.state.jpteacher === japaneseTeacher[0].value} onChange={this.handleChange} tabIndex="15" />
                                                            <span className="hp_mr20">{japaneseTeacher[0].label}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>学生の人数</th>
                                                    <td>
                                                        <TextInput type="text" name="people" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="6" setValue={universityInfo.people} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="16" numberFormat="true" />
                                                        <span className="el_form_txt">名</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>修業期間</th>
                                                    <td>
                                                        <select className="el_select el_select_country" name="study_period" onChange={this.handleChange} tabIndex="17">
                                                            <option value="" selected ></option>
                                                            {
                                                                trainingPeriod && trainingPeriod.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.study_period == item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        設置区分
                                                    </th>
                                                    <td>
                                                        <select className="el_select el_select_country" name="section" onChange={this.handleChange} tabIndex="18">
                                                            <option value="" selected ></option>
                                                            {
                                                                sectionOpt && sectionOpt.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.section == item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_btn" onClick={() => this.saveUniversityInfo()} tabIndex="19">登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.updateUniversityInfo.isFetching,
        data: state.updateUniversityInfo.data,
        isError: state.updateUniversityInfo.isError,
        err_type: state.updateUniversityInfo.err_type,
        err_detail: state.updateUniversityInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createUniversityInfo(arg) {
            // 組織情報(プロフィール)大学更新
            dispatch(updateUniversityInfo(arg));
        },
        universityInfoReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(updateUniversityInfoReset());
        }
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMCRU04);

