/**
 * Action Typesを定義
 */
const GESSFCQA01ActionType = {
    GET_COP_USAGE_RESULTS_DETAIL_REQUEST: 'GET_COP_USAGE_RESULTS_DETAIL_REQUEST',  // 企業アンケート照会取得定義
    GET_COP_USAGE_RESULTS_DETAIL_SUCCESS: 'GET_COP_USAGE_RESULTS_DETAIL_SUCCESS',  // 企業アンケート照会取得成功定義
    GET_COP_USAGE_RESULTS_DETAIL_FAILURE: 'GET_COP_USAGE_RESULTS_DETAIL_FAILURE',  // 企業アンケート照会取得失敗定義
    GET_COP_USAGE_RESULTS_DETAIL_RESET: 'GET_COP_USAGE_RESULTS_DETAIL_RESET',      // 企業アンケート照会取得リセット定義
};

export default GESSFCQA01ActionType;