import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getPrefecturesMasterList, getMessage } from "../../Constants/CommonConstants";
import { imageResize } from "../../Components/Tools/ImageTools";
import { MessageMap } from "./GCMOMCRU01Message";
import {
    getCompanyAndFacilityInfo,
    getCompanyAndFacilityInfoReset,
    updateCompanyAndFacilityInfo,
    updateCompanyAndFacilityInfoReset
}
    from "./GCMOMCRU01Action";
import { isBlank, getNumberText } from "../../Components/Tools/StringTools";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 組織情報(プロフィール)企業
 */
class GCMOMCRU01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            company_prefectures_name: [],
            countryOpt: [],
            corporationInfo: this.props.detailData
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { corporationInfo } = this.state;

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });
        // 都道府県
        const prefecturesRet = Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            return v;
        });

        corporationInfo.employee_regular = getNumberText(corporationInfo.employee_regular);
        corporationInfo.employee_part_time = getNumberText(corporationInfo.employee_part_time);
        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
            company_name: corporationInfo.company_name,
            company_name_kana: corporationInfo.company_name_kana,
            logo: corporationInfo.logo,
            company_prefectures_id: corporationInfo.company_prefectures_id,
            company_prefectures_name: [...prevState.company_prefectures_name, ...prefecturesRet],
            company_address: corporationInfo.company_address,
            start_year: corporationInfo.start_year,
            president_name: corporationInfo.president_name,
            president_name_kana: corporationInfo.president_name_kana,
            president_tel_country_name: corporationInfo.president_tel_country_name,
            president_tel_country_code: corporationInfo.president_tel_country_code,
            president_tel_1: corporationInfo.president_tel_1,
            president_tel_2: corporationInfo.president_tel_2,
            president_tel_3: corporationInfo.president_tel_3,
            fax_country_name: corporationInfo.fax_country_name,
            fax_country_code: corporationInfo.fax_country_code,
            fax_1: corporationInfo.fax_1,
            fax_2: corporationInfo.fax_2,
            fax_3: corporationInfo.fax_3,
            employee_regular: corporationInfo.employee_regular,
            employee_part_time: corporationInfo.employee_part_time
        }), () => {
            // 代表者電話番号と国名
            countryRet.forEach(country => {
                // 代表者電話番号
                if (country.country_code === corporationInfo.president_tel_country_code) {
                    this.setState({
                        president_tel_country_name: country.country_name,
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    });
                }
                if (country.country_code === corporationInfo.fax_country_code) {
                    // FAX番号
                    this.setState({
                        fax_country_name: country.country_name,
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    });
                }
            });
        });
        window.scrollTo(0, 0);
    }

    /**
     * 画像を変更
     * @param {*} event
     */
    handleImageUpload = (event) => {
        imageResize(event.target, (data) => {
            this.setState({
                logo: data
            }, () => {
                this.editItemHandler('logo', this.state.logo);
            });
        });
    }

    /**
     * 画像を削除
     */
    handleImageDelete = () => {
        this.setState({
            logo: null
        }, () => {
            this.editItemHandler('logo', this.state.logo);
        });
    };

    /**
     * 本社所在地の変更
     * @param {*} event
     */
    handleCompanyPrefecturesChange = (event) => {
        this.setState({
            company_prefectures_id: event.target.value
        }, () => {
            this.editItemHandler("company_prefectures_id", this.state.company_prefectures_id);
        });
    };

    /**
     * 代表者電話番号の変更
     * @param {*} event
     */
    handleTelCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (isBlank(this.state.president_tel_country_name)) {
                this.setState({
                    president_tel_country_number: '',
                    president_tel_country_code: ''
                }, () => {
                    this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                    this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                    this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.president_tel_country_name) {
                    this.setState({
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                        this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                        this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * FAX番号の変更
     * @param {*} event
     */
    handleFaxCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (isBlank(this.state.fax_country_name)) {
                this.setState({
                    fax_country_number: '',
                    fax_country_code: ''
                }, () => {
                    this.editItemHandler("fax_country_code", this.state.fax_country_code);
                    this.editItemHandler("fax_country_name", this.state.fax_country_name);
                    this.editItemHandler("fax_country_number", this.state.fax_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === this.state.fax_country_name) {
                    this.setState({
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("fax_country_code", this.state.fax_country_code);
                        this.editItemHandler("fax_country_name", this.state.fax_country_name);
                        this.editItemHandler("fax_country_number", this.state.fax_country_number);
                    });
                }
            });
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.companyAndFacilityInfoReset();
    }

    /**
     * 登録
     */
    saveCorporationInfo = () => {
        window.scrollTo(0, 0);
        const { corporationInfo } = this.state;
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let newData = Object.assign({}, corporationInfo);
        newData['update_type'] = '1';
        newData['company_version_no'] = corporationInfo.version_no;
        this.setState({
            corporationInfo: newData
        }, () => {
            this.props.createCompanyAndFacilityInfo(this.state.corporationInfo);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            corporationInfo: {
                ...prevState.corporationInfo,
                [name]: value
            }
        }));
    }

    /**
     * 画面切替処理
     */
    redirectToFacility = () => {
        window.scrollTo(0, 0);
        this.props.companyAndFacilityInfoReset();
        // 企業施設画面に移動
        this.props.redirectToFacility("GCMOMCRU02");
    }

    render() {
        const { corporationInfo } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.company_version_no) && corporationInfo.version_no !== this.props.data.company_version_no) {
                this.editItemHandler('version_no', this.props.data.company_version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => { });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織情報(プロフィール)</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ul className="bl_tabs_list" role="tablist">
                                    <li><a className="bl_tabs_link is_current" href="#tab1" tabIndex="1">組織情報</a></li>
                                    <li><a className="bl_tabs_link" href="#tab2" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.redirectToFacility(); }, () => { })} tabIndex="2">施設情報</a></li>
                                </ul>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">登録プロフィール情報</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <span className="el_badge el_error">※法人登記をしていない場合は施設名を入力してください。</span>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />企業名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="company_name" className="el_input el_input__max" maxlength="60" required="required" setValue={corporationInfo.company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />企業名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="company_name_kana" className="el_input el_input__max" maxlength="60" required="required" setValue={corporationInfo.company_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} toZenKana="true" tabIndex="4" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>企業ロゴ</th>
                                                    <td>
                                                        <div className="bl_upload_box__applicant">
                                                            <figure>
                                                                <img src={this.state.logo == null ? 'data:,' : this.state.logo} alt="" />
                                                            </figure>
                                                            <div className="bl_btns">
                                                                <label htmlFor="changeImg" onClick={event => event.target.value = null} className="el_smBlueBtn" tabIndex="5">画像を選択<br />Select image
                                                                    <input type="file" id="changeImg" className="el_sendFile" accept="image/*" onChange={this.handleImageUpload} />
                                                                </label>
                                                                <label htmlFor="deleteImg" className="el_smBlueBtn" tabIndex="6">画像を削除<br />Delete image
                                                                    <input type="button" id="deleteImg" className="el_sendFile" onClick={this.handleImageDelete} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />本社所在地
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_pref" name="company_prefectures_name" value={this.state.company_prefectures_id} onChange={this.handleCompanyPrefecturesChange} tabIndex="7">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.company_prefectures_name && this.state.company_prefectures_name.map(item => (
                                                                        <option key={item.prefectures_id} value={item.prefectures_id} selected={this.state.company_prefectures_id == item.prefectures_id}>
                                                                            {item.prefectures_name}
                                                                        </option>

                                                                    ))
                                                                }
                                                            </select>
                                                            <TextInput type="text" name="company_address" className="el_input el_input__max" maxlength="60" required="required" setValue={corporationInfo.company_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />設立日（年）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="start_year" className="el_input el_input__sm" maxlength="4" required="required" setValue={corporationInfo.start_year} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />代表者名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="president_name" className="el_input el_input__max" maxlength="40" required="required" setValue={corporationInfo.president_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="10" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />代表者名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="president_name_kana" className="el_input el_input__max" maxlength="40" required="required" setValue={corporationInfo.president_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} toZenKana="true" tabIndex="11" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />代表者電話番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="president_tel_country_name" value={this.state.president_tel_country_name} onChange={this.handleTelCountryNameChange} tabIndex="12">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.president_tel_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="president_tel_country_number" className="el_input el_input__countryCode" setValue={this.state.president_tel_country_number} maxlength="6" readOnly={true} />
                                                        <TextInput type="text" name="president_tel_1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.president_tel_1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="13" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="president_tel_2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.president_tel_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="14" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="president_tel_3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.president_tel_3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="15" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>FAX番号</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="fax_country_name" value={this.state.fax_country_name} onChange={this.handleFaxCountryNameChange} tabIndex="16">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.fax_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="fax_country_number" className="el_input el_input__countryCode" setValue={this.state.fax_country_number} maxlength="6" readOnly={true} />
                                                        <TextInput type="text" name="fax_1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.fax_1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="18" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="fax_2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.fax_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="19" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="fax_3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={corporationInfo.fax_3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="20" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />従業員数
                                                    </th>
                                                    <td>
                                                        <div className="bl_formTxt">正社員</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="employee_regular" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="6" required="required" setValue={corporationInfo.employee_regular} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="21" />&nbsp;
                                                            <span className="el_form_txt">名</span>
                                                        </div>
                                                        <div className="bl_formTxt bl_formTxt_btm">パート/アルバイト</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="employee_part_time" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="6" required="required" setValue={corporationInfo.employee_part_time} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="22" />&nbsp;
                                                            <span className="el_form_txt">名</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_btn" onClick={() => this.saveCorporationInfo()} tabIndex="23">登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.updateCompanyAndFacilityInfo.isFetching,
        data: state.updateCompanyAndFacilityInfo.data,
        isError: state.updateCompanyAndFacilityInfo.isError,
        err_type: state.updateCompanyAndFacilityInfo.err_type,
        err_detail: state.updateCompanyAndFacilityInfo.err_detail,

        isFetching: state.getCompanyAndFacilityInfo.isFetching,
        facilityData: state.getCompanyAndFacilityInfo.data,
        isfacilityError: state.getCompanyAndFacilityInfo.isError,
        facilityErr_type: state.getCompanyAndFacilityInfo.err_type,
        facilityErr_detail: state.getCompanyAndFacilityInfo.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getCompanyAndFacilityInfo() {
            // 組織情報(プロフィール)施設情報取得
            dispatch(getCompanyAndFacilityInfo({
                'get_type': '2'
            }));
        },
        getCompanyAndFacilityInfoReset() {
            dispatch(getCompanyAndFacilityInfoReset());
        },
        createCompanyAndFacilityInfo(arg) {
            // 組織情報(プロフィール)送り出し機関取得
            dispatch(updateCompanyAndFacilityInfo(arg));
        },
        companyAndFacilityInfoReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(updateCompanyAndFacilityInfoReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMCRU01);

