import React, { Fragment } from "react";

import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getChangeDateText, getDateTextYM } from "../../Components/Tools/StringTools.js";
import { GetCandidateDetailReset } from "../MmGRSMMAMF01/GRSMMAMF01Action";
import { checkArray } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";
import YouTube from "react-youtube";

/**
 * 応募者詳細画面
 */
class GRSMMAMF02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseDetail: this.props.caseDetail,
            candidateList: this.props.candidateList
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        const { candidateDetail } = this.props;
        this.setState({
            status_of_residence: candidateDetail.status_of_residence,       // 在留資格
            status_of_residence_name: candidateDetail.status_of_residence_name,       // 在留資格名
            candidate_id: candidateDetail.candidate_id,                                           // 応募者ID
            candidate_photo: candidateDetail.candidate_photo,                                  // 応募者写真
            university_name: candidateDetail.university_name,                                  // 大学
            candidate_student_no: candidateDetail.candidate_student_no,                   // 学籍番号
            candidate_name: candidateDetail.candidate_name,                                     // 応募者氏名（ローマ字）
            candidate_name_kana: candidateDetail.candidate_name_kana,                      // 応募者氏名（カナ）
            candidate_nickname: candidateDetail.candidate_nickname,                         // 応募者氏名（ニックネーム）
            country_name: candidateDetail.country_name,                                           // 国籍
            candidate_birthday: candidateDetail.candidate_birthday ? getChangeDateText(candidateDetail.candidate_birthday) : "",                         // 生年月日
            candidate_sex_name: candidateDetail.candidate_sex_name,                         // 性別
            candidate_japanese_level_name: candidateDetail.candidate_japanese_level_name, // 日本語レベル
            candidate_language: candidateDetail.candidate_language,                         // 得意言語
            candidate_department: candidateDetail.candidate_department,                   // 学部・専攻
            candidate_acquired_credits: candidateDetail.candidate_acquired_credits, // 取得済単位数
            candidate_intern_schedule_credits: candidateDetail.candidate_intern_schedule_credits, // インターンでの取得予定単位数
            candidate_required_credits: candidateDetail.candidate_required_credits, // 卒業までに必要な単位数
            candidate_appeal: candidateDetail.candidate_appeal,                               // 自己アピール
            license: candidateDetail.license,                                 // 保有資格 
            video_url_display: candidateDetail.video_url ? this.youtubeParser(candidateDetail.video_url) : "",                   // 動画URL
            video_url_trans: candidateDetail.video_url,                   // 動画URL
            work_history: candidateDetail.work_history,                                                          // 職歴
            work_history_list: candidateDetail.work_history_list,                                                          // 職歴リスト
            working_practice_history: candidateDetail.working_practice_history ? candidateDetail.working_practice_history : [],       // 就労/実習履歴
            education_history: candidateDetail.education_history ? candidateDetail.education_history : []                             // 教育履歴
        });
    }

    // youtubeのリンクから、IDを取得
    youtubeParser(url) {
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length == 11) ? match[1] : false;
    }

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            if (changeTo === 'GRSMMMLI01') {
                this.props.getCaseDetailReset();
                this.props.getEntryListReset();
            }
            this.props.resetCandidateDetailState();
        }
        return this.props.switchDisplaySelectedCandidate(changeTo, this.state.caseDetail, this.state.candidateList);
    }

    openVideo = (event) => {
        event.target.stopVideo();
        document.getElementById(this.state.video_url_display).click();
    }

    render() {
        const { working_practice_history, education_history, work_history_list } = this.state;
        const { displaySource } = this.props;

        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                応募者情報
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                {displaySource === 'GRSMMAMF01' ? <Fragment>
                                    <li>
                                        <a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>案件詳細</a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={() => this.handleToDisplay("GRSMMAMF01", true)}>応募者一覧</a>
                                    </li>
                                </Fragment> : null}
                                {displaySource === 'GRSMMIMD02' ? <Fragment>
                                    <li>
                                        <a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a>
                                    </li>
                                    <li>
                                        <a href="#" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>案件詳細</a>
                                    </li>
                                </Fragment> : null}
                                <li>
                                    応募者詳細
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">応募者詳細</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>{this.state.status_of_residence_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>応募者ID</th>
                                                    <td>{this.state.candidate_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>応募者写真</th>
                                                    <td>
                                                        <figure className="un_applicantPhoto">
                                                            <img src={this.state.candidate_photo} alt="" />
                                                        </figure>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>自己紹介動画</th>
                                                    {(() => {
                                                        if (this.state.video_url_trans) {
                                                            return (
                                                                <td>
                                                                    <div>
                                                                        <figure>
                                                                            <YouTube videoId={this.state.video_url_display} className={"un_applicantVideo"} onPlay={this.openVideo} tabIndex="1" />
                                                                            <u><a style={{ display: "none" }} id={this.state.video_url_display} href={this.state.video_url_trans} target="_blank"></a></u>
                                                                        </figure>
                                                                    </div>
                                                                </td>
                                                            );
                                                        }
                                                    })()}
                                                </tr>
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>大学</th>
                                                                <td>{this.state.university_name}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>学籍番号</th>
                                                                <td>{this.state.candidate_student_no}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                <tr>
                                                    <th>応募者氏名<br />（ローマ字）</th>
                                                    <td>{this.state.candidate_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>応募者氏名<br />（フリガナ）</th>
                                                    <td>{this.state.candidate_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>応募者氏名<br />（ニックネーム）</th>
                                                    <td>{this.state.candidate_nickname}</td>
                                                </tr>
                                                <tr>
                                                    <th>国籍</th>
                                                    <td>{this.state.country_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>生年月日</th>
                                                    <td>{this.state.candidate_birthday}</td>
                                                </tr>
                                                <tr>
                                                    <th>性別</th>
                                                    <td>{this.state.candidate_sex_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>日本語レベル</th>
                                                    <td>{this.state.candidate_japanese_level_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>得意言語</th>
                                                    <td>{this.state.candidate_language}</td>
                                                </tr>
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>学部・専攻</th>
                                                                <td>{this.state.candidate_department}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>取得済単位数</th>
                                                                <td>{this.state.candidate_acquired_credits}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>インターンでの取得予定単位数</th>
                                                                <td>{this.state.candidate_intern_schedule_credits}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                {(() => {
                                                    if (this.state.status_of_residence === "2") {
                                                        return (
                                                            <tr>
                                                                <th>卒業までに必要な単位数</th>
                                                                <td>{this.state.candidate_required_credits}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                <tr>
                                                    <th>自己アピール</th>
                                                    <td>{this.state.candidate_appeal ? this.state.candidate_appeal.split('\n').map((str, index) => (
                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                    )) : null}</td>
                                                </tr>
                                                <tr>
                                                    <th>保有資格</th>
                                                    <td>{this.state.license ? this.state.license.split('\n').map((str, index) => (
                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                    )) : null}</td>
                                                </tr>
                                                <tr>
                                                    <th>日本での職歴</th>
                                                    <td>
                                                        {
                                                            checkArray(work_history_list) && work_history_list.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {item.work_history_ym_from}
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history_ym_from ? '－' : null}
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history_ym_to}&nbsp;
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">就労/実習履歴</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                {
                                                    working_practice_history && working_practice_history.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.status_of_residence_name}<br />
                                                                    期間：{getDateTextYM(item.accept_period_start)}〜{getDateTextYM(item.accept_period_end)}<br />
                                                                    業種：{item.industry_name_j}<br />
                                                                    職種：{item.occupation}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">教育履歴</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                {
                                                    education_history && education_history.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.education_name}<br />
                                                                    完了年月：{getDateTextYM(item.education_end)}<br />
                                                                    ステータス：{item.education_status}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </section>
                                    <button type="button" className="el_blueBtn" style={{ display: displaySource === 'GRSMMAMF01' ? 'block' : 'none' }} onClick={() => this.handleToDisplay("GRSMMAMF01", true)} tabIndex="2">応募者一覧へ</button>
                                    <button type="button" className="el_blueBtn" style={{ display: displaySource === 'GRSMMIMD02' ? 'block' : 'none' }} onClick={() => this.handleToDisplay("GRSMMIMD02", true)} tabIndex="3">案件詳細へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </article>
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        resetCandidateDetailState: () => dispatch(GetCandidateDetailReset()),
    };
};

export default connect(null, mapDispatchToProps)(GRSMMAMF02);

