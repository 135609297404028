import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import moment from "moment";
import { getMessage, getCodeName } from "../../Constants/CommonConstants";
import { isBlank, checkArray, timeConvert } from "../../Components/Tools/StringTools";
import { getWorkingtimeInquiry, workingtimeInquiryReset } from "../WtGTMWTWTI01/GTMWTWTI01Action";
import { workingtimeApproval, workingtimeApprovalReset } from "./GTMWTWTA01Action";
import { MessageMap } from "./GTMWTWTA01Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 作業時間承認
 */
class GTMWTWTA01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('YYYY/MM/DD'),
            approval_chk: '0',
            remand_chk: '0',
            selectedData: [],
            err_msg: [],
            version_no: '',
            inited: false,
            employment_name_kana: this.props.detailData.employment_name_kana,
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    /**
     * 作業時間取得
     * @param  {...any} args
     */
    getWorkingtimeInquiry = (...args) => {
        this.props.workingtimeInquiryReset();
        let params = {
            user_id: this.props.detailData.user_id,
            case_id: this.props.detailData.case_id,
            target_month: args[0],
            get_type: args[1]
        };
        this.props.getWorkingtimeInquiryList(params);
    }

    /**
     * 前月
     */
    lastMonth = () => {
        window.scrollTo(0, 0);
        this.editItemHandler('selectedData', []);
        this.props.workingtimeApprovalReset();
        this.setState(prevState => ({
            date: moment(moment(prevState.date).subtract(1, 'months').format('YYYY/MM/DD')).format('YYYY/MM/DD'),
            err_msg: []
        }), () => {
            this.getWorkingtimeInquiry(this.state.date, '2');
        });
    }

    /**
     * 次月
     */
    nextMonth = () => {
        window.scrollTo(0, 0);
        this.editItemHandler('selectedData', []);
        this.props.workingtimeApprovalReset();
        this.setState(prevState => ({
            date: moment(moment(prevState.date).add(1, 'months').format('YYYY/MM/DD')).format('YYYY/MM/DD'),
            err_msg: []
        }), () => {
            this.getWorkingtimeInquiry(this.state.date, '2');
        });
    }

    /**
     * チェックボックスのon/offを変更する
     */
    handleCheckboxChange = (event, index) => {
        const { name, value, checked } = event.target;
        let chk = checked ? '1' : '0';

        this.setState({
            [name]: chk,
            err_msg: []
        }, () => {
            // this.editItemHandler(name + index, chk);
            const clone = { ...this.props.data[value] };
            if (checked) {
                // 選択した承認する場合
                if (name === 'approval_chk') {
                    clone.approval_chk = this.state.approval_chk;
                    clone.remand_chk = '0';
                } else {
                    clone.approval_chk = '0';
                    clone.remand_chk = this.state.remand_chk;
                }

                if (!this.state.selectedData.includes(this.state.selectedData.find(el => el.year_month === clone.year_month))) {
                    this.setState(prevState => ({ selectedData: [...prevState.selectedData, clone] }));
                }
            } else {
                this.setState(prevState => ({
                    selectedData: prevState.selectedData.filter(el => el.year_month !== clone.year_month)
                }));
            }
        });
    }

    /**
     * 行チェックしているかどうかの設定
     * @param {*} year_month
     * @param {*} isApproval
     */
    getItemChecked = (year_month, isApproval) => {
        let ret = false;
        const el = this.state.selectedData.find(el => el.year_month === year_month)
        if (el) {
            if (isApproval && el.approval_chk == '1') {
                ret = true;
            } else if (!isApproval && el.remand_chk == '1') {
                ret = true;
            }
        }
        return ret;
    }

    /**
     * renderメソッドの直前に実行されます。
     * @param {*} nextProps
     * @param {*} prevState
     */
    static getDerivedStateFromProps(nextProps, prevState) {

        if (!nextProps.isApprovalError && nextProps.approval_err_type === '200') {
            return {
                data: nextProps.data
            };
        }
        // Return null if the state hasn't changed
        return null;
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.workingtimeApprovalReset();
    }

    /**
     * 承認／差戻
     */
    approvalAndRemand = () => {
        window.scrollTo(0, 0);
        this.props.workingtimeApprovalReset();

        const { selectedData } = this.state;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // データが選択されない場合、エラーになる
        if (!checkArray(selectedData)) {
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            const newArray = selectedData.map(el => {
                return {
                    approval_chk: el.approval_chk,
                    chao_chk: el.remand_chk,
                    case_id: el.case_id,
                    user_id: el.user_id,
                    work_report_date: el.work_report_date,
                    work_start_time: el.work_start_time,
                    work_end_time: el.work_end_time,
                    rest_start_time: el.rest_start_time,
                    rest_end_time: el.rest_end_time,
                    day_flg: el.day_flg,
                    rest_minute: el.rest_minute,
                    rest_flg: el.rest_flg,
                    late_early_flg: el.late_early_flg,
                    work_minute: el.work_minute,
                    work_approval_status: el.work_approval_status,
                    version_no: el.version_no
                };
            });

            let condition = {};
            condition.working_admission_data = newArray;
            this.props.workingtimeApproval(condition);

            this.getWorkingtimeInquiry(this.state.date, '2');
        }
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.saveReset();
        }
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { list } = this.props.approvalData;
        const { selectedData } = this.state;

        if (!this.props.isApprovalFetching) {
            // 最新バージョン設定する
            if (checkArray(list) && checkArray(selectedData)) {
                list.map(data => {
                    selectedData.map(item => {
                        if (!isBlank(data.version_no) && item.version_no != data.version_no && item.work_report_date == data.work_report_date) {
                            this.editItemHandler('version_no', data.version_no);
                            this.editItemHandler('selectedData', []);
                            this.showModalDialog(0, getMessage('RS-0051'), () => {
                                this.props.workingtimeApprovalReset();
                                this.getWorkingtimeInquiry(this.state.date, '2');
                            });
                        }
                    });

                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">作業時間承認</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GAFMCMCFAA01", true)}>ユーザ一覧</a></li>
                                <li>作業時間承認</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.approval_err_type} err_detail={this.props.approval_err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">{moment(this.state.date).format('YYYY年MM月')}</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.lastMonth()} tabIndex="1">前月</button>
                                        <button type="button" className={moment(this.state.date).format('YYYY年MM月') === moment().format('YYYY年MM月') ? "el_btn is_disabled" : "el_nextBtn"} onClick={() => this.nextMonth()} tabIndex="2">次月</button>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>氏名：{this.state.employment_name_kana}</span></h3>
                                        </div>
                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                            <button type="button" className="el_btn" onClick={() => this.approvalAndRemand()} tabIndex="3">承認／差戻</button>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>承認</th>
                                                        <th>差戻</th>
                                                        <th>日付</th>
                                                        <th>開始時間</th>
                                                        <th>終了時間</th>
                                                        <th>休憩時間</th>
                                                        <th>勤務時間</th>
                                                        <th>休暇区分</th>
                                                        <th>承認者</th>
                                                        <th>承認日</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data)) {
                                                            return (
                                                                this.props.data.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <label className="el_label el_label__center">
                                                                                <input className="el_checkbox" type="checkbox" name="approval_chk" value={index} checked={this.getItemChecked(item.year_month, true)} onChange={(e) => { this.handleCheckboxChange(e, index); }} disabled={item.activity_flg === '0' || item.work_approval_status === '1' ? true : false} />
                                                                                <span className="el_checkbox_txt el_checkbox_txt__solo"></span>
                                                                            </label>
                                                                        </td>
                                                                        <td>
                                                                            <label className="el_label el_label__center">
                                                                                <input className="el_checkbox" type="checkbox" name="remand_chk" value={index} checked={this.getItemChecked(item.year_month, false)} onChange={(e) => { this.handleCheckboxChange(e, index); }} disabled={item.activity_flg === '0' || item.work_approval_status !== '1' ? true : false} />
                                                                                <span className="el_checkbox_txt el_checkbox_txt__solo"></span>
                                                                            </label>
                                                                        </td>
                                                                        <td className="hp_tar"> {item.year_month}</td>
                                                                        <td className="hp_tar">{item.work_start_time}</td>
                                                                        <td className="hp_tar">{item.work_end_time}</td>
                                                                        <td className="hp_tar">{isBlank(String(item.rest_minute)) || (item.rest_minute == 0 && item.rest_flg != '0') ? null : (item.rest_minute + '分')}</td>
                                                                        <td className="hp_tar">{isBlank(String(item.work_minute)) || (item.work_minute == 0 && item.rest_flg != '0') ? null : timeConvert(item.work_minute)}</td>
                                                                        <td>{getCodeName('RSC0023', item.rest_flg)}</td>
                                                                        <td>{item.user_name}</td>
                                                                        <td className="hp_tar">{item.work_approval_datetime}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.handleToDisplay("GAFMCMCFAA01", true)} tabIndex="4">ユーザ一覧へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getWorkingtimeInquiry.isFetching,
        data: state.getWorkingtimeInquiry.data,
        isError: state.getWorkingtimeInquiry.isError,
        err_type: state.getWorkingtimeInquiry.err_type,
        err_detail: state.getWorkingtimeInquiry.err_detail,
        isApprovalFetching: state.workingtimeApproval.isFetching,
        approvalData: state.workingtimeApproval.data,
        isApprovalError: state.workingtimeApproval.isError,
        approval_err_type: state.workingtimeApproval.err_type,
        approval_err_detail: state.workingtimeApproval.err_detail

    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getWorkingtimeInquiryList: (args) => dispatch(getWorkingtimeInquiry(args)),
        workingtimeApproval: (args) => dispatch(workingtimeApproval(args)),
        workingtimeInquiryReset: () => dispatch(workingtimeInquiryReset()),
        workingtimeApprovalReset: () => dispatch(workingtimeApprovalReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GTMWTWTA01);

