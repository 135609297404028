/**
 * Action Typesを定義
 */
const GESSFCES01ActionType = {
    GET_EVALUATION_RESULTS_DETAIL_REQUEST: 'GET_EVALUATION_RESULTS_DETAIL_REQUEST',  // 評価詳細取得定義
    GET_EVALUATION_RESULTS_DETAIL_SUCCESS: 'GET_EVALUATION_RESULTS_DETAIL_SUCCESS',  // 評価詳細取得成功定義
    GET_EVALUATION_RESULTS_DETAIL_FAILURE: 'GET_EVALUATION_RESULTS_DETAIL_FAILURE',  // 評価詳細取得失敗定義
    GET_EVALUATION_RESULTS_DETAIL_RESET: 'GET_EVALUATION_RESULTS_DETAIL_RESET',       // 評価詳細取得リセット定義
    UPDATE_EVALUATION_RESULTS_DETAIL_REQUEST: 'UPDATE_EVALUATION_RESULTS_DETAIL_REQUEST',  // 評価詳細更新定義
    UPDATE_EVALUATION_RESULTS_DETAIL_SUCCESS: 'UPDATE_EVALUATION_RESULTS_DETAIL_SUCCESS',  // 評価詳細更新成功定義
    UPDATE_EVALUATION_RESULTS_DETAIL_FAILURE: 'UPDATE_EVALUATION_RESULTS_DETAIL_FAILURE',  // 評価詳細更新失敗定義
    UPDATE_EVALUATION_RESULTS_DETAIL_RESET: 'UPDATE_EVALUATION_RESULTS_DETAIL_RESET'       // 評価詳細更新リセット定義
};

export default GESSFCES01ActionType;