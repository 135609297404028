import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { MessageMap } from "../MmGRSMMRMR01/GRSMMRMR01Message";
import { createRecruitmentCase, createRecruitmentCaseReset } from "../MmGRSMMRMR01/GRSMMRMR01Action";
import { getCodeValueLabelList, getPrefecturesMasterList, getMessage } from "../../Constants/CommonConstants";
import { calculatePayment } from "../../Constants/RsConstants";
import { getNumber, isBlank, checkArray } from "../../Components/Tools/StringTools";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Minus_Icon from "../../Images/btn_minus.svg";
import Plus_Icon from "../../Images/btn_plus.svg";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import { getNumberText } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 求人案件登録（待遇）
 */
class GRSMMRMR04 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            recruitmentCaseInfo: this.props.recruitmentCaseInfo,
            recruitmentCaseDetail: this.props.recruitmentCaseDetail
        };
    }

    /**
     * をセットする
     */
    componentDidMount = () => {
        const { recruitmentCaseDetail } = this.state;

        // 支給額画面初期化時に、フォーマット調整
        recruitmentCaseDetail.payment_amount = getNumberText(recruitmentCaseDetail.payment_amount);
        // 時給画面初期化時に、フォーマット調整
        recruitmentCaseDetail.payment_hourly = getNumberText(recruitmentCaseDetail.payment_hourly);
        // 寮費画面初期化時に、フォーマット調整
        recruitmentCaseDetail.deduction_dormitory = getNumberText(recruitmentCaseDetail.deduction_dormitory);
        // 食費画面初期化時に、フォーマット調整
        recruitmentCaseDetail.deduction_food = getNumberText(recruitmentCaseDetail.deduction_food);
        // 名目
        if (recruitmentCaseDetail.deduction) {
            recruitmentCaseDetail.deduction.forEach(item => {
                item.fee = getNumberText(item.fee);
            });
        }

        this.setState({
            payment_amount: recruitmentCaseDetail.payment_amount,
            payment_hourly: recruitmentCaseDetail.payment_hourly,
            deduction: checkArray(recruitmentCaseDetail.deduction) ? recruitmentCaseDetail.deduction : [],
            deduction_dormitory: recruitmentCaseDetail.deduction_dormitory,
            deduction_utility: recruitmentCaseDetail.deduction_utility,
            deduction_food: recruitmentCaseDetail.deduction_food,
            paid_holiday: recruitmentCaseDetail.paid_holiday,
            others_holiday: recruitmentCaseDetail.others_holiday,
            living_place: recruitmentCaseDetail.living_place,
            wifi: recruitmentCaseDetail.wifi,
            transportation: recruitmentCaseDetail.transportation,
            insurance_deposit: recruitmentCaseDetail.insurance_deposit,
            insurance_pension: recruitmentCaseDetail.insurance_pension,
            insurance_employment: recruitmentCaseDetail.insurance_employment,
            insurance_accident: recruitmentCaseDetail.insurance_accident,
            insurance_severance: recruitmentCaseDetail.insurance_severance,
            insurance_others: recruitmentCaseDetail.insurance_others,
            insurance_others_free: recruitmentCaseDetail.insurance_others_free,
            recruitmentCaseDetail: recruitmentCaseDetail
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '2' && recruitmentCaseDetail.sys_date < recruitmentCaseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });

        let minimum_wage = 0, prefectures_name = "";
        // 都道府県
        Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            if (v.prefectures_id == recruitmentCaseDetail.facility_prefectures_id) {
                minimum_wage = v.minimum_wage;
                prefectures_name = v.prefectures_name;
            }
        });

        this.setState({
            minimum_wage: minimum_wage,   // 最低賃金
            prefectures_name: prefectures_name  // 都道府県
        });
        window.scrollTo(0, 0);
    }

    /**
     * 寮有無の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleLivingPlaceChange = (event) => {
        const { value } = event.target;
        this.setState({
            living_place: value
        }, () => {
            this.editItemHandler('living_place', value);
            // 寮無し
            if (this.state.living_place === '0') {
                this.setState({ 'wifi': '0' }, () => {
                    this.editItemHandler('wifi', this.state.wifi);
                });
            }
        });
    }

    /**
     * WiFi有無の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleWiFiChange = (event) => {
        let chk = event.target.checked ? '1' : '0';
        this.setState({
            wifi: chk
        }, () => {
            this.editItemHandler('wifi', chk);
        });
    }

    /**
     * 水道光熱費の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleWaterLightChange = (event) => {
        const { value } = event.target;
        this.setState({
            deduction_utility: value
        }, () => {
            this.editItemHandler('deduction_utility', value);
        });
    }

    /**
     * 手取り支援額の計算
     */
    calclateProceeds = () => {
        const { recruitmentCaseDetail } = this.state;

        // 支給額
        const payment_amount = recruitmentCaseDetail.payment_amount;
        // 寮費
        const livingAmount = recruitmentCaseDetail.deduction_dormitory;
        // 食費
        const mealAmount = recruitmentCaseDetail.deduction_food;

        // 名目＿金額
        const amountList = !checkArray(recruitmentCaseDetail.deduction) ? [] : recruitmentCaseDetail.deduction.map(item => {
            return item.fee;
        });

        // 控除額
        const deductionAmount = [livingAmount, mealAmount, ...amountList];

        // 手取り金額計算
        const proceeds = calculatePayment(payment_amount, deductionAmount);

        this.setState({
            support_amount: getNumber(proceeds)      // 手取り支援額
        });
    }

    /**
     * 控除額を追加する
     */
    addDeduction = () => {
        this.setState(prevState => ({
            deduction: [...prevState.deduction, { "item_id": "", "item_name": "", "fee": "" }]
        }));
    }

    /**
     * 控除額を削除する
     * @param {*} index
     */
    deleteDeduction = (index) => {
        let array = [...this.state.deduction];
        array.splice(index, 1);
        this.setState({
            deduction: array
        });
    }

    /**
     * チェックボックスのon/offを変更する
     */
    handleChange = (event) => {
        const { checked, name } = event.target;
        let chk = checked ? '1' : '0';
        this.setState({
            [name]: chk
        }, () => {
            this.editItemHandler(name, chk);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            recruitmentCaseDetail: {
                ...prevState.recruitmentCaseDetail,
                [name]: value
            }
        }));
    }

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            deduction: this.state.deduction.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler('deduction', this.state.deduction);
        });
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createRecruitmentCaseReset();
    }

    /**
    * 途中保存
    */
    temporalySave = () => {
        this.props.createRecruitmentCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
        if (isBlank(recruitmentCaseDetailData.case_id)) {
            if (this.props.data && this.props.data.case_id != null) {
                recruitmentCaseDetailData.case_id = this.props.data.case_id;
            }
        }
        recruitmentCaseDetailData.temp_save = '1';
        this.setState({
            recruitmentCaseDetail: recruitmentCaseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.recruitmentCaseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createRecruitmentCase(reqData);
            }
        });
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    render() {
        const { recruitmentCaseDetail, recruitmentCaseInfo, isDisabled } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.recruitmentCaseDetail.version_no != this.props.data.version_no) {
                let recruitmentCaseDetailData = recruitmentCaseDetail;
                recruitmentCaseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    recruitmentCaseDetail: recruitmentCaseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }

        // 寮情報
        const dormitoryInfo = getCodeValueLabelList('RSC0011');

        // 水道光熱費
        const waterLightInfo = getCodeValueLabelList('RSC0012');

        const deductionCount = this.state.deduction && this.state.deduction.map((value, index) => {
            return 7 + index;
        });

        const tabindex = checkArray(deductionCount) ? [...deductionCount].pop() : 7;

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">求人案件管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>求人案件登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">求人案件登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_type_upload} err_detail={this.props.err_detail_upload} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR01", recruitmentCaseInfo, recruitmentCaseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR02", recruitmentCaseInfo, recruitmentCaseDetail)}>会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR03", recruitmentCaseInfo, recruitmentCaseDetail)}>条件・勤務形態</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>待遇</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMRMR03", recruitmentCaseInfo, recruitmentCaseDetail)} tabIndex={tabindex + 20}>戻る</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMRMR05", recruitmentCaseInfo, recruitmentCaseDetail)} tabIndex={tabindex + 19}>次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" /></span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>賃金</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />支給額
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="payment_amount" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" required="required" setValue={recruitmentCaseDetail.payment_amount} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />時給
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="payment_hourly" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" required="required" setValue={recruitmentCaseDetail.payment_hourly} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                            <span className="el_form_txt">円/時間</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        ※<span className="el_bold">{this.state.prefectures_name}</span>の最低賃金
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="minimum_wage" className="el_input el_input__num el_input__red text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" setValue={getNumberText(this.state.minimum_wage)} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={true} />
                                                            <span className="el_form_txt">円/時間</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>控除額</th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb5">
                                                            <span className="hp_mr10">名目　寮費</span>
                                                            <TextInput type="text" name="deduction_dormitory" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" setValue={recruitmentCaseDetail.deduction_dormitory} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                        <div className="bl_formUnit bl_formUnit_utilityCosts hp_mb20">
                                                            <input type="radio" name="deduction_utility" value={waterLightInfo[0].value} checked={this.state.deduction_utility === waterLightInfo[0].value} onChange={this.handleWaterLightChange} tabIndex="4" />
                                                            <span className="hp_mr20">{waterLightInfo[0].label}</span>
                                                            <input type="radio" name="deduction_utility" value={waterLightInfo[1].value} checked={this.state.deduction_utility === waterLightInfo[1].value} onChange={this.handleWaterLightChange} tabIndex="5" />
                                                            <span>{waterLightInfo[1].label}</span>
                                                        </div>
                                                        <div className="bl_formUnit hp_mb5">
                                                            <span className="hp_mr10">名目　食費</span>
                                                            <TextInput type="text" name="deduction_food" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" setValue={recruitmentCaseDetail.deduction_food} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                        {
                                                            this.state.deduction && this.state.deduction.map((item, index) => {
                                                                return (
                                                                    <div className="bl_formUnit hp_mb5" key={index}>
                                                                        <span>名目　</span>
                                                                        <TextInput type="text" name="item_name" className="el_input el_input__sm hp_mr5" maxlength="20" setValue={item.item_name} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={7 + index} />
                                                                        <TextInput type="text" name="fee" className="el_input el_input__sm text_right_align" inputmode="decimal" numberFormat="true" maxlength="10" setValue={item.fee} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={7 + index} />
                                                                        <span className="el_form_txt">円/月</span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r">
                                                            <button type="button" value="-" className="el_funcBtn" onClick={() => this.deleteDeduction(this.state.deduction.length - 1)} tabIndex={tabindex + 1}>
                                                                <img src={Minus_Icon} alt="マイナス" />削除
                                                            </button>
                                                            <button type="button" value="+" className="el_funcBtn" onClick={() => this.addDeduction()} tabIndex={tabindex + 2}>
                                                                <img src={Plus_Icon} alt="プラス" />追加
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit hp_mt50 hp_mb40">
                                            <button type="button" name="" className="el_submitBtn el_submitBtn__calc" onClick={() => this.calclateProceeds()} tabIndex={tabindex + 3}>
                                                手取り金額の計算<br />
                                                <small>（税金を考慮した概算）</small>
                                            </button>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>手取り金額</th>
                                                    <td>
                                                        <div className="bl_formTxt">1月あたり</div>
                                                        <div className="bl_formUnit">
                                                            <span className="hp_mr10">約</span>
                                                            <TextInput type="text" name="support_amount" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="10" setValue={this.state.support_amount} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={true} />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>その他待遇</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        有給休暇
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="paid_holiday" className="el_input el_input__max" maxlength="102" setValue={recruitmentCaseDetail.paid_holiday} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 4} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        その他休暇
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="others_holiday" className="el_input el_input__max" maxlength="102" setValue={recruitmentCaseDetail.others_holiday} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 5} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        寮の有無
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="living_place-radio" value={dormitoryInfo[1].value} checked={this.state.living_place === dormitoryInfo[1].value} onChange={this.handleLivingPlaceChange} tabIndex={tabindex + 6} />
                                                            <span className="hp_mr20">{dormitoryInfo[1].label}</span>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label hp_ml20 hp_mb5">
                                                                <input className="el_checkbox" type="checkbox" name="wifi" checked={this.state.wifi === '1'} onChange={this.handleWiFiChange} disabled={this.state.living_place === '0'} tabIndex={tabindex + 7} />
                                                                <span className="el_checkbox_txt">Wi-Fi有り</span>
                                                            </label>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="living_place-radio" value={dormitoryInfo[0].value} checked={this.state.living_place === dormitoryInfo[0].value} onChange={this.handleLivingPlaceChange} tabIndex={tabindex + 8} />
                                                            <span className="hp_mr20">{dormitoryInfo[0].label}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />交通手段
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="transportation" className="el_input el_input__max hp_mb1Rem" maxlength="102" setValue={recruitmentCaseDetail.transportation} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 9} />
                                                            <p>例：寮から徒歩５分<br />　　最寄駅「XXX」から徒歩10分</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>社会保険等</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_deposit" checked={this.state.insurance_deposit === '1'} onChange={this.handleChange} tabIndex={tabindex + 10} />
                                                                <span className="el_checkbox_txt">健康保険</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_pension" checked={this.state.insurance_pension === '1'} onChange={this.handleChange} tabIndex={tabindex + 11} />
                                                                <span className="el_checkbox_txt">厚生年金</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_employment" checked={this.state.insurance_employment === '1'} onChange={this.handleChange} tabIndex={tabindex + 12} />
                                                                <span className="el_checkbox_txt">雇用保険</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_accident" checked={this.state.insurance_accident === '1'} onChange={this.handleChange} tabIndex={tabindex + 13} />
                                                                <span className="el_checkbox_txt">労災保険</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_severance" checked={this.state.insurance_severance === '1'} onChange={this.handleChange} tabIndex={tabindex + 14} />
                                                                <span className="el_checkbox_txt">退職金制度</span>
                                                            </label>
                                                        </div>
                                                        <div className="bl_formFlex">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_others" checked={this.state.insurance_others === '1'} onChange={this.handleChange} tabIndex={tabindex + 15} />
                                                                <span className="el_checkbox_txt">その他</span>
                                                            </label>
                                                            <TextInput type="text" name="insurance_others_free" className="el_input el_input__lg" maxlength="20" setValue={recruitmentCaseDetail.insurance_others_free} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 16} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note">
                                            <p>加入させる保険にチェックを入れてください。</p>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex={tabindex + 17}>途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMRMR05", recruitmentCaseInfo, recruitmentCaseDetail)} tabIndex={tabindex + 18}>次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createRecruitmentCase.isFetching,
        data: state.createRecruitmentCase.recruitmentCaseData,
        isError: state.createRecruitmentCase.isError,
        err_type: state.createRecruitmentCase.err_type,
        err_detail: state.createRecruitmentCase.err_detail,
        err_type_upload: state.uploadInfo.err_type,
        err_detail_upload: state.uploadInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createRecruitmentCase(arg) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCase(arg));
        },
        createRecruitmentCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMRMR04);

