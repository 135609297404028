import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMIMR01ActionTypes';

// 案件情報登録・更新API
const CREATE_CASE_POST = BASE_URL + "/api/rs_mm_update_case_info";
// 企業情報取得API
const GET_COMPANY_LIST_POST = BASE_URL + "/api/rs_mm_get_company_list";

// ********************Actionの作成 Start ********************

/**
 * 登録リクエスト
 */
export const CreateCaseRequest = () => {
    return {
        type: actionTypes.CREATE_CASE_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const CreateCaseSuccess = (data) => {
    return {
        type: actionTypes.CREATE_CASE_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const CreateCaseFailure = (err) => {
    return {
        type: actionTypes.CREATE_CASE_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const CreateCaseReset = () => {
    return {
        type: actionTypes.CREATE_CASE_RESET
    };
};

/**
 * 登録リセット(データも合わせリセットする)
 */
export const CreateCaseResetAllData = () => {
    return {
        type: actionTypes.CREATE_CASE_RESET_ALL_DATA
    };
};

/**
 * 企業情報リスト取得
 */
export const GetCompanyListRequest = () => {
    return {
        type: actionTypes.GET_COMPANY_LIST_REQUEST
    };
};

/**
 * 企業情報取得成功
 * @param {*} data
 */
export const GetCompanyListSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_LIST_SUCCESS,
        data
    };
};

/**
 * 企業情報取得失敗
 * @param {*} err
 */
export const GetCompanyListFailure = (err) => {
    return {
        type: actionTypes.GET_COMPANY_LIST_FAILURE,
        err
    };
};

/**
 * 企業情報リストリセット
 */
export const GetCompanyListReset = () => {
    return {
        type: actionTypes.GET_COMPANY_LIST_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const createCase = (data) => {

    return async (dispatch) => {
        dispatch(CreateCaseRequest());
        ecoPostWithAuthHeader(CREATE_CASE_POST, JSON.stringify(data)).then(
            res => dispatch(CreateCaseSuccess(res.data))
        ).catch(
            err => dispatch(CreateCaseFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const createCaseReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(CreateCaseReset());
    };
};

/**
 * 企業情報リスト取得Actionの発行
 * @param {*} query
 */
export const getCompanyList = (query) => {

    return async (dispatch) => {
        // Storeへ企業情報リクエストActionをdispatchする
        dispatch(GetCompanyListRequest());
        ecoPostWithAuthHeader(GET_COMPANY_LIST_POST, {})
            .then(res =>
                // Storeへ企業情報取得成功Actionをdispatchする
                dispatch(GetCompanyListSuccess(res.data))
            ).catch(err =>
                // Storeへ企業情報取得失敗Actionをdispatchする
                dispatch(GetCompanyListFailure(err))
            );
    };
};
