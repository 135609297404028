import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCUM01ActionType';

// 実習／労働者一覧取得API
const GET_TRAINNING_WORKER_POST = BASE_URL + "/api/cm_om_get_trainning_worker_list";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetTrainningWorkerListRequest = () => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetTrainningWorkerListSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetTrainningWorkerListFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetTrainningWorkerListReset = () => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getTrainningWorkerList = (data) => {
    return async (dispatch) => {
        dispatch(GetTrainningWorkerListRequest());
        ecoPostWithAuthHeader(GET_TRAINNING_WORKER_POST, JSON.stringify(data)).then(
            res => dispatch(GetTrainningWorkerListSuccess(res.data))
        ).catch(
            err => dispatch(GetTrainningWorkerListFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getTrainningWorkerListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainningWorkerListReset());
    };
};
