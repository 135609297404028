/**
 * Action Typesを定義
 */
const GRSMMIMR01ActionTypes = {
    CREATE_CASE_REQUEST: 'CREATE_CASE_REQUEST',
    CREATE_CASE_SUCCESS: 'CREATE_CASE_SUCCESS',
    CREATE_CASE_FAILURE: 'CREATE_CASE_FAILURE',
    CREATE_CASE_RESET: 'CREATE_CASE_RESET',
    CREATE_CASE_RESET_ALL_DATA: 'CREATE_CASE_RESET_ALL_DATA',
    GET_COMPANY_LIST_REQUEST: 'GET_COMPANY_LIST_REQUEST',
    GET_COMPANY_LIST_SUCCESS: 'GET_COMPANY_LIST_SUCCESS',
    GET_COMPANY_LIST_FAILURE: 'GET_COMPANY_LIST_FAILURE',
    GET_COMPANY_LIST_RESET: 'GET_COMPANY_LIST_RESET'
};

export default GRSMMIMR01ActionTypes;
