/**
 * Action Typesを定義
 */
const GISDMRLS01ActionType = {
    DAILY_REPORT_LIST_REQUEST: 'DAILY_REPORT_LIST_REQUEST',         // 日報日次一覧取得定義
    DAILY_REPORT_LIST_SUCCESS: 'DAILY_REPORT_LIST_SUCCESS',         // 日報日次一覧取得成功定義
    DAILY_REPORT_LIST_FAILURE: 'DAILY_REPORT_LIST_FAILURE',         // 日報日次一覧取得失敗定義
    DAILY_REPORT_LIST_RESET: 'DAILY_REPORT_LIST_RESET',             // 日報日次一覧取得リセット定義
    DAILY_REPORT_LIST_LASTDATE: 'DAILY_REPORT_LIST_LASTDATE',             // 日報日次一覧の最新対象月設定定義
    DAILY_REPORT_LIST_LASTDATE_RESET: 'DAILY_REPORT_LIST_LASTDATE_RESET',             // 日報日次一覧の最新対象月リセット定義
};

export default GISDMRLS01ActionType;
