import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GESSFQUS01ActionType";

// 送り出し機関・大学アンケート照会取得API
const GET_OKR_UNI_USAGE_RESULTS_DETAIL_POST = BASE_URL + "/api/es_sf_get_okr_uni_usage_results_detail";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetOkrUniUsageResultsDetailRequest = () => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_DETAIL_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetOkrUniUsageResultsDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_DETAIL_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetOkrUniUsageResultsDetailFailure = (err) => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_DETAIL_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetOkrUniUsageResultsDetailReset = () => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getOkrUniUsageResultsDetail = (data) => {
    return async (dispatch) => {
        dispatch(GetOkrUniUsageResultsDetailRequest());
        ecoPostWithAuthHeader(GET_OKR_UNI_USAGE_RESULTS_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(GetOkrUniUsageResultsDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetOkrUniUsageResultsDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getOkrUniUsageResultsDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetOkrUniUsageResultsDetailReset());
    };
};
