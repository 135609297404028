import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GESEQQRE01ActionType';

// アンケート初期取得登録（企業用）API
const GET_COMPANY_ENQUETE_ANSWER_INFO_POST = BASE_URL + "/api/es_eq_get_company_enquete_answer_info";

// アンケート登録（企業用）API
const UPDATE_COMPANY_ENQUETE_ANSWER_INFO_POST = BASE_URL + "/api/es_eq_update_company_enquete_answer_info";

// ********************Actionの作成 Start ********************
/**
 * アンケート初期取得登録（企業用）照会
 */
export const GetCompanyEnqueteAnswerInfoRequest = () => {
    return {
        type: actionTypes.GET_COMPANY_ENQUETE_ANSWER_INFO_REQUEST
    };
};

/**
 * アンケート初期取得登録（企業用）照会成功
 */
export const GetCompanyEnqueteAnswerInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS,
        data
    };
};

/**
 * アンケート初期取得登録（企業用）照会失敗
 */
export const GetCompanyEnqueteAnswerInfoFailure = (err) => {
    return {
        type: actionTypes.GET_COMPANY_ENQUETE_ANSWER_INFO_FAILURE,
        err
    };
};

/**
 * アンケート初期取得登録（企業用）照会リセット
 */
export const GetCompanyEnqueteAnswerInfoReset = () => {
    return {
        type: actionTypes.GET_COMPANY_ENQUETE_ANSWER_INFO_RESET
    };
};

/**
 * アンケート登録（企業用）リクエスト
 */
export const UpdateCompanyEnqueteAnswerInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_ENQUETE_ANSWER_INFO_REQUEST
    };
};

/**
 * アンケート登録（企業用）成功
 * @param {*} data
 */
export const UpdateCompanyEnqueteAnswerInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS,
        data
    };
};

/**
 * アンケート登録（企業用）失敗
 * @param {*} err
 */
export const UpdateCompanyEnqueteAnswerInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_COMPANY_ENQUETE_ANSWER_INFO_FAILURE,
        err
    };
};

/**
 * アンケート登録（企業用）リセット
 * @param {*} err
 */
export const UpdateCompanyEnqueteAnswerInfoReset = (err) => {
    return {
        type: actionTypes.UPDATE_COMPANY_ENQUETE_ANSWER_INFO_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * アンケート初期取得登録（企業用）照会Actionの発行
 */
export const getCompanyEnqueteAnswerInfo = (data) => {
    return async (dispatch) => {
        // Storeへアンケート初期取得登録（企業用）照会リクエストActionをdispatchする
        dispatch(GetCompanyEnqueteAnswerInfoRequest());
        ecoPostWithAuthHeader(GET_COMPANY_ENQUETE_ANSWER_INFO_POST, JSON.stringify(data))
            .then(res =>
                // Storeへアンケート初期取得登録（企業用）照会成功Actionをdispatchする
                dispatch(GetCompanyEnqueteAnswerInfoSuccess(res.data))
            ).catch(err =>
                // Storeへアンケート初期取得登録（企業用）照会失敗Actionをdispatchする
                dispatch(GetCompanyEnqueteAnswerInfoFailure(err))
            );
    };
};

/**
 * アンケート登録（企業用）Actionの発行
 */
export const updateCompanyEnqueteAnswerInfo = (arg) => {
    return async (dispatch) => {
        // Storeへアンケート初期取得登録（企業用）アンケート登録（企業用）リクエストActionをdispatchする
        dispatch(UpdateCompanyEnqueteAnswerInfoRequest());
        ecoPostWithAuthHeader(UPDATE_COMPANY_ENQUETE_ANSWER_INFO_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへアンケート初期取得登録（企業用）アンケート登録（企業用）成功Actionをdispatchする
                dispatch(UpdateCompanyEnqueteAnswerInfoSuccess(res.data));
            }).catch(err =>
                // Storeへアンケート初期取得登録（企業用）アンケート登録（企業用）失敗Actionをdispatchする
                dispatch(UpdateCompanyEnqueteAnswerInfoFailure(err))
            );
    };
};
