import React from "react";
import { ecoPost } from "../../Components/Tools/HttpTools";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import TextInput from "../../Components/Atoms/TextInput";
import ModalMessage from "../../Components/Atoms/ModalMessage";
import { MenuUserLogout } from "../McGAFMCMCF01/GAFMCMCF01Action";
import { setCognitoSessionStorage, getMessage, getCognitoPool, getCognitoInfo, BASE_URL, setMessageMaster, setCodeMaster, setAccessControlMaster, setCognitoInfo, setCountryMaster, setIndustryMaster, setPrefecturesMaster, createNewCognitoPool, setCognitoPool, setCognitoUser, getCognitoUser, setCognitoUserInfo, getCognitoUserInfo } from "../../Constants/CommonConstants";
import "../../Css/Common.css";
import {
    CognitoUser,
    AuthenticationDetails,
} from "amazon-cognito-identity-js";
import { testData } from "../../test/TestWork";
import Service_Icon from "../../Images/service Logo.svg";
import { MessageMap } from "./GAFLFLAF01Message";
import Load from "../../Components/Atoms/Load";
import { Fragment } from "react";

const LOG_IN_POST = BASE_URL + "/api/af_lf_gaflflaf01_login";

/**
 * ログイン認証機能
 */
class GAFLFLAF01 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            psw: "",
            showing: false,
            buttonEnable: true,
            loginButtonEnable: true,
            otpw: "",
            errorShowing: false,
            errorText: "",
            challengeId: "",
            errType: "",
            resData: "",
            session: "",
            isError: false,
            isloading: false,
            isExpired: false,
            expiredMessage: '',
            responsePwdExpiredFlag: false,
            responseInfoAgreeFlg: '0',
            infoAgreeFlgNotAgree: false
        };

        sessionStorage.clear();

        const _this = this;

        // 認証成功時のコールバック定義
        this.authCallBack = {
            onSuccess: function (result) {
                // 2段階目認証成功
                for (var attr in localStorage) {
                    if (!attr.includes('device_key:')) {
                        localStorage.removeItem(attr);
                    }
                }
                _this.setState({ isloading: false });
                const userPool = getCognitoPool();
                const userData = {
                    Username: _this.state.challengeId,
                    Pool: userPool
                };
                const cognitoUser = new CognitoUser(userData);
                cognitoUser.getCognitoUserSession(result);
                setCognitoSessionStorage(_this.state.challengeId, result);
                setCognitoUser(userPool.getCurrentUser());
                let currentUser = getCognitoUser();
                currentUser.getSession(function (err, session) {
                    // デバイスキーを記録する
                    if (session && session.accessToken.payload.device_key) {
                        localStorage.setItem('device_key:' + _this.state.challengeId, session.accessToken.payload.device_key);
                    }
                    // ユーザ属性設定する
                    currentUser.getUserAttributes(function (err, attrresult) {
                        let userInfo = {};
                        userInfo['user_name'] = currentUser.username;
                        if (attrresult == null) {
                            return;
                        }
                        for (var i = 0; i < attrresult.length; i++) {
                            if (attrresult[i].getName() == "email") {
                                userInfo['email'] = attrresult[i].getValue();
                            }
                            else if (attrresult[i].getName() == "custom:organization_id") {
                                userInfo['org_id'] = attrresult[i].getValue();
                            }
                            else if (attrresult[i].getName() == "custom:organization_type") {
                                userInfo['org_type'] = attrresult[i].getValue();
                            }
                            else if (attrresult[i].getName() == "custom:country_code") {
                                userInfo['country_code'] = attrresult[i].getValue();
                            }
                            else if (attrresult[i].getName() == "custom:candidate_id") {
                                userInfo['candidate_id'] = attrresult[i].getValue();
                            }
                        }
                        setCognitoUserInfo(userInfo);
                        // パスワード有効期限切れかどうか
                        if (_this.state.responsePwdExpiredFlag) {
                            _this.setState({ isExpired: true, expiredMessage: getMessage('AF-0013') });
                        } else if (_this.state.responseInfoAgreeFlg != '1') {
                            // 個人情報取り扱い同意取得画面に遷移する
                            _this.setState({ infoAgreeFlgNotAgree: true });
                        } else {
                            _this.props.history.push('/af/home');
                        }

                    });
                });
            },
            onFailure: function (err) {
                // 2段階目認証失敗
                // console.log('認証キーを認証失敗');
                // console.log(err);
                if (_this.state.otpw == "") {
                    localStorage.removeItem('device_key:' + _this.state.challengeId);
                }
                _this.setState({ isloading: false });
                _this.setState({ buttonEnable: true });
                _this.setState({ errorShowing: true });
                _this.setState({ loginButtonEnable: false });
                _this.setState({ otpw: "" });
                _this.setState({
                    errorText: getMessage('AF-0002')
                });
            }
        };
    }

    /**
     * 初期化完了イベント
     */
    componentDidMount = () => {
        this.props.initStore();
        window.addEventListener('keydown', this.onPressEvent);
    }

    /**
     * ログインUnloadイベント
     */
    componentWillUnmount = () => {
        // エンターキー禁止解除
        window.removeEventListener('keydown', this.onPressEvent);
    }

    /**
     * エンターキー対策
     * @param {*} e
     */
    onPressEvent = (e) => {
        // ローディング画面表示する際にエンターキー無効にする
        if (this.state.isloading && (e.keyCode == 13 || e.key === 'Enter')) {
            e.preventDefault();
        }
    }

    /**
     * PINコード認証
     */
    pinCodeAuth = () => {
        const userPool = createNewCognitoPool();
        setCognitoPool(userPool);
        const userData = {
            Username: this.state.challengeId,
            Pool: getCognitoPool()
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.Session = this.state.session;
        cognitoUser.sendCustomChallengeAnswer(this.state.otpw, this.authCallBack);
    }

    /**
     * デバイス認証
     */
    deviceAuth = (challengeId, session) => {
        const userPool = createNewCognitoPool();
        setCognitoPool(userPool);
        const userData = {
            Username: challengeId,
            Pool: getCognitoPool()
        };
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.Session = session;
        cognitoUser.sendCustomChallengeAnswer(localStorage.getItem('device_key:' + this.state.id), this.authCallBack);
    }

    closeMsg = () => {
        this.setState({ isError: false });
    }

    /**
     * パスワード変更画面を表示する
     */
    showChangePassword = () => {
        this.setState({ isExpired: false });
        this.redirectToNextPage('GAFLFCPF01', this.state.id);
    }

    /**
    * 個人情報取り扱い同意取得を表示する
    */
    showInfoAgree = () => {
        this.setState({ infoAgreeFlgNotAgree: false }, () => this.redirectToNextPage('GAFLFLAF02', this.state.id));

    }

    /**
     * PINコード入力画面表示
     */
    handleToActivate = () => {
        this.setState({ errorShowing: false });
        this.setState({ showing: true });
        this.setState({ buttonEnable: false });
        this.setState({ loginButtonEnable: true });
    }

    /**
     * 画面項目編集ハンドラー
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({ [name]: value });
    }

    /**
     * 1段階目ログイン処理
     */
    signInCognito = () => {
        let loginPara = {};
        loginPara['user'] = this.state.id;
        loginPara['password'] = this.state.psw;
        loginPara['device_key'] = localStorage.getItem('device_key:' + this.state.id);
        this.setState({ isloading: true });
        // 認証Lambda関数を呼び出し
        ecoPost(LOG_IN_POST, JSON.stringify(loginPara), { headers: {} }).then(
            response => {
                // メッセージマスタデータ設定
                setMessageMaster(response.data.data.message_master);
                if (response.data.err_type == "200") {
                    // 認証成功の場合
                    // コードマスタデータ設定
                    setCodeMaster(response.data.data.code_master);
                    // アクセスコントロールマスタデータ設定
                    setAccessControlMaster(response.data.data.access_control_master);
                    // Cognito情報設定
                    setCognitoInfo({
                        'ClientId': response.data.data.cognito.client_id,
                        'UserPoolId': response.data.data.cognito.pool_id
                    });
                    this.setState({ session: response.data.data.cognito.session });
                    this.setState({ challengeId: this.state.id });
                    // 国マスタデータ設定
                    setCountryMaster(response.data.data.country_master);
                    // 業種マスタデータ設定
                    setIndustryMaster(response.data.data.industry_master);
                    // 都道府県マスタデータ設定
                    setPrefecturesMaster(response.data.data.prefectures_master);
                    // パスワード有効期限切れフラグ
                    this.setState({ responsePwdExpiredFlag: response.data.data.is_expired });
                    // 個人情報同意フラグ
                    this.setState({ responseInfoAgreeFlg: response.data.data.info_agree_flg });
                    if (response.data.data.cognito.device_auth) {
                        // デバイス認証でログインする
                        this.deviceAuth(this.state.id, response.data.data.cognito.session);
                    } else {
                        this.setState({ isloading: false });
                        // PINコード入力画面表示
                        this.handleToActivate();
                    }
                }
                else if (response.data.err_type == "300") {
                    this.setState({ isloading: false });
                    this.setState({ errorShowing: true });
                    this.setState({ errorText: getMessage(response.data.err_detail[0]["err_code"]) });
                }
                else if (response.data.err_type == "310") {
                    this.setState({ isloading: false });
                    this.setState({ errorShowing: true });
                    let errorMessage = "";
                    for (let i = 0; i < response.data.err_detail.length; i++) {
                        if (i == 0) {
                            errorMessage = getMessage(response.data.err_detail[i]["err_code"]).replace(/%1/g, MessageMap[response.data.err_detail[i]["err_item"]]);
                        } else {
                            errorMessage = errorMessage + "\n" + getMessage(response.data.err_detail[i]["err_code"]).replace(/%1/g, MessageMap[response.data.err_detail[i]["err_item"]]);
                        }
                        this.setState({ errorText: errorMessage });
                    }
                } else {
                    this.setState({ isloading: false });
                }
            }).catch(error => {
                this.setState({ isError: true });
                this.setState({ isloading: false });
            });
    };

    /**
     * 画面切替処理
     */
    redirectToNextPage = (dispId, data) => {
        window.scrollTo(0, 0);
        // パスワードリマインド画面に移動
        this.props.redirectToNextPage(dispId, data);
    }

    render() {
        if (this.state.infoAgreeFlgNotAgree) {
            // 個人情報取り扱い同意取得を表示する
            this.showInfoAgree();
        }
        return (
            <body id="login_A2-2">
                <div className="ly_cont">
                    <main className="ly_cont_main ly_cont_login">
                        <div className="ly_mainCont">
                            <section className="bl_sect">
                                <div className="ly_mainCont_inner">
                                    <div className="bl_sect_inner">
                                        {this.state.isloading ? <Load setLabel="Loading" /> : null}
                                        {this.state.isError ? <ModalMessage type={9} okClick={this.closeMsg} /> : null}
                                        {this.state.isExpired ? <ModalMessage type={0} okClick={this.showChangePassword} content={this.state.expiredMessage} /> : null}
                                        <section className="bl_sect_frame">
                                            <p className="el_headerLogo">
                                                <img src={Service_Icon} alt="service Logo" />
                                            </p>
                                            {this.state.errorShowing
                                                ? <div id="errorarea">
                                                    <ul className='errorArea'>
                                                        {
                                                            Object.entries(this.state.errorText.split("\n")).map(
                                                                ([key, value]) => (
                                                                    <li className="errorText">{value}</li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                                : null
                                            }
                                            <div className="bl_formUnit">
                                                <TextInput type="text" className="el_input el_input__max" placeholder="ログインID" name="id" setValue={this.state.id} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex='1' />
                                            </div>
                                            <div className="bl_formUnit">
                                                <TextInput type="password" className="el_input el_input__max" placeholder="パスワード" name="psw" setValue={this.state.psw} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex='2' />
                                            </div>
                                            <div className="bl_horizBtnUnit">
                                                <button type="button" className={this.state.buttonEnable ? "el_blueBtn el_shadowBtn" : "el_blueBtn is_disabled"} onClick={() => this.signInCognito()} tabIndex='3'>ログイン</button>
                                            </div>
                                            <p className="el_aside hp_tac">
                                                <a href="#!" className="el_link" onClick={() => { this.redirectToNextPage("GAFLFPRF01"); }} tabIndex='6'>パスワードをお忘れの場合</a>
                                            </p>
                                            {this.state.showing
                                                ? <div className="authObject">
                                                    <p className="el_aside hp_tac">※認証キーの有効期限は３分です。</p>
                                                    <div className="bl_formUnit">
                                                        <TextInput type="text" className="el_input el_input__max" name="otpw" setValue={this.state.otpw} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex='4' />
                                                    </div>
                                                    <div className="bl_horizBtnUnit">
                                                        <button type="button" className={this.state.loginButtonEnable ? "el_blueBtn el_shadowBtn" : "el_blueBtn is_disabled"} onClick={() => this.pinCodeAuth()} tabIndex='5'>認証キー送信</button>
                                                    </div>
                                                </div>
                                                : null
                                            }
                                        </section>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </main>
                </div>
            </body>
        );
    }
}
/**
 * Actionバインディングする
 * @param {*} dispatch
 */
function mapDispatchToProps(dispatch) {
    return {
        initStore() {
            dispatch(MenuUserLogout());
        }
    };
}
export default withRouter(connect(null, mapDispatchToProps)(GAFLFLAF01));
