import actionTypes from './GISAMIAR01ActionType';

/**
 * 実習結果評価
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 実習結果評価を取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getTrainingResultsEvaluation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAINING_RESULTS_EVALUATION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_TRAINING_RESULTS_EVALUATION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_TRAINING_RESULTS_EVALUATION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_TRAINING_RESULTS_EVALUATION_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 実習結果評価を登録するReducer
 * @param {*} state
 * @param {*} action
 */
export const saveTrainingResultsEvaluation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRAINING_RESULTS_EVALUATION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.TRAINING_RESULTS_EVALUATION_SUCCESS:
            console.log("SUCCESS",action.data.data)
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.TRAINING_RESULTS_EVALUATION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.TRAINING_RESULTS_EVALUATION_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
