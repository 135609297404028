import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GESSFCMA01ActionType';

//企業利用実績一覧取得API
const GET_COP_USAGE_RESULTS_LIST_POST = BASE_URL + "/api/es_sf_get_cop_usage_results_list";

// ********************Actionの作成 Start ********************
/**
 *企業利用実績一覧取得
 */
export const GetCopUsageResultsListRequest = () => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_LIST_REQUEST
    };
};

/**
 *企業利用実績一覧取得成功
 */
export const GetCopUsageResultsListSuccess = (data) => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_LIST_SUCCESS,
        data
    };
};

/**
 *企業利用実績一覧取得失敗
 */
export const GetCopUsageResultsListFailure = (err) => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_LIST_FAILURE,
        err
    };
};

/**
 *企業利用実績一覧取得リセット
 */
export const GetCopUsageResultsListReset = () => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_LIST_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 *企業利用実績一覧取得Actionの発行
 */
export const getCopUsageResultsList = (data) => {
    return async (dispatch) => {
        // Storeへ企業利用実績一覧取得リクエストActionをdispatchする
        dispatch(GetCopUsageResultsListRequest());
        ecoPostWithAuthHeader(GET_COP_USAGE_RESULTS_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ企業利用実績一覧取得成功Actionをdispatchする
                dispatch(GetCopUsageResultsListSuccess(res.data))
            ).catch(err =>
                // Storeへ企業利用実績一覧取得失敗Actionをdispatchする
                dispatch(GetCopUsageResultsListFailure(err))
            );
    };
};