/**
 * Action Typesを定義
 */
const GCMOMCRU01ActionType = {
    GET_COMPANY_AND_FACILITY_INFO_REQUEST: 'GET_COMPANY_AND_FACILITY_INFO_REQUEST',  // 組織情報(プロフィール)企業情報・企業施設情報取得定義
    GET_COMPANY_AND_FACILITY_INFO_SUCCESS: 'GET_COMPANY_AND_FACILITY_INFO_SUCCESS',  // 組織情報(プロフィール)企業情報・企業施設情報取得成功定義
    GET_COMPANY_AND_FACILITY_INFO_FAILURE: 'GET_COMPANY_AND_FACILITY_INFO_FAILURE',  // 組織情報(プロフィール)企業情報・企業施設情報取得失敗定義
    GET_COMPANY_AND_FACILITY_INFO_RESET: 'GET_COMPANY_AND_FACILITY_INFO_RESET',       // 組織情報(プロフィール)企業情報・企業施設情報取得リセット定義
    UPDATE_COMPANY_AND_FACILITY_INFO_REQUEST: 'UPDATE_COMPANY_AND_FACILITY_INFO_REQUEST',  // 組織情報(プロフィール)企業情報・企業施設情報更新定義
    UPDATE_COMPANY_AND_FACILITY_INFO_SUCCESS: 'UPDATE_COMPANY_AND_FACILITY_INFO_SUCCESS',  // 組織情報(プロフィール)企業情報・企業施設情報更新成功定義
    UPDATE_COMPANY_AND_FACILITY_INFO_FAILURE: 'UPDATE_COMPANY_AND_FACILITY_INFO_FAILURE',  // 組織情報(プロフィール)企業情報・企業施設情報更新失敗定義
    UPDATE_COMPANY_AND_FACILITY_INFO_RESET: 'UPDATE_COMPANY_AND_FACILITY_INFO_RESET'       // 組織情報(プロフィール)企業情報・企業施設情報更新リセット定義
};

export default GCMOMCRU01ActionType;