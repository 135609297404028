/**
 * Action Typesを定義
 */
const GRSMMRMR31ActionType = {
    CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_REQUEST: 'CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_REQUEST',
    CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_SUCCESS: 'CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_SUCCESS',
    CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_FAILURE: 'CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_FAILURE',
    CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET: 'CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET',
    CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET_ALL_DATA: 'CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET_ALL_DATA'
};

export default GRSMMRMR31ActionType;