/**
 * Action Typesを定義
 */
const GCMOMORU01ActionType = {
    GET_USER_MANAGEMENT_REQUEST: 'GET_USER_MANAGEMENT_REQUEST',        // ユーザ管理取得定義
    GET_USER_MANAGEMENT_SUCCESS: 'GET_USER_MANAGEMENT_SUCCESS',        // ユーザ管理取得成功定義
    GET_USER_MANAGEMENT_FAILURE: 'GET_USER_MANAGEMENT_FAILURE',        // ユーザ管理取得失敗定義
    GET_USER_MANAGEMENT_RESET:   'GET_USER_MANAGEMENT_RESET',          // ユーザ管理取得リセット定義
    UPDATE_USER_MANAGEMENT_REQUEST: 'UPDATE_USER_MANAGEMENT_REQUEST',  // ユーザ管理更新定義
    UPDATE_USER_MANAGEMENT_SUCCESS: 'UPDATE_USER_MANAGEMENT_SUCCESS',  // ユーザ管理更新成功定義
    UPDATE_USER_MANAGEMENT_FAILURE: 'UPDATE_USER_MANAGEMENT_FAILURE',  // ユーザ管理更新失敗定義
    UPDATE_USER_MANAGEMENT_RESET:   'UPDATE_USER_MANAGEMENT_RESET',    // ユーザ管理更新リセット定義
    DELETE_USER_MANAGEMENT_REQUEST: 'DELETE_USER_MANAGEMENT_REQUEST',  // ユーザ管理削除定義
    DELETE_USER_MANAGEMENT_SUCCESS: 'DELETE_USER_MANAGEMENT_SUCCESS',  // ユーザ管理削除成功定義
    DELETE_USER_MANAGEMENT_FAILURE: 'DELETE_USER_MANAGEMENT_FAILURE',  // ユーザ管理削除失敗定義
    DELETE_USER_MANAGEMENT_RESET:   'DELETE_USER_MANAGEMENT_RESET'     // ユーザ管理削除リセット定義
};

export default GCMOMORU01ActionType;