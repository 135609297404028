/**
 * Action Typesを定義
 */
const GISDMIRR01ActionType = {
    GET_REPORT_CONTENT_REQUEST: 'GET_REPORT_CONTENT_REQUEST',  // レポート内容登録初期取得定義
    GET_REPORT_CONTENT_SUCCESS: 'GET_REPORT_CONTENT_SUCCESS',  // レポート内容登録初期取得成功定義
    GET_REPORT_CONTENT_FAILURE: 'GET_REPORT_CONTENT_FAILURE',  // レポート内容登録初期取得失敗定義
    GET_REPORT_CONTENT_RESET: 'GET_REPORT_CONTENT_RESET',       // レポート内容登録初期取得リセット定義
    SAVE_REPORT_CONTENT_REQUEST: 'SAVE_REPORT_CONTENT_REQUEST',  // レポート内容登録定義
    SAVE_REPORT_CONTENT_SUCCESS: 'SAVE_REPORT_CONTENT_SUCCESS',  // レポート内容登録成功定義
    SAVE_REPORT_CONTENT_FAILURE: 'SAVE_REPORT_CONTENT_FAILURE',  // レポート内容登録失敗定義
    SAVE_REPORT_CONTENT_RESET: 'SAVE_REPORT_CONTENT_RESET'       // レポート内容登録リセット定義
};

export default GISDMIRR01ActionType;