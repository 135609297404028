import React from 'react';
import UiTemplate from "./template";

function UiContainer() {
    return (
        <section className="App">
            <UiTemplate />
        </section>
    );
}

export default UiContainer;
