// メッセージMap
export const MessageMap = {
    "company_id": "企業",
    "company_name": "企業名",
    "company_name_kana": "企業名（フリガナ）",
    "company_prefectures_id": "本社所在地＿都道府県",
    "company_address": "本社所在地",
    "start_year": "設立日（年）",
    "president_name": "代表者名",
    "president_name_kana": "代表者名（フリガナ）",
    "president_tel_country_name": "代表者電話番号",
    "president_tel_country_code": "代表電話番号＿国・地域名",
    "president_tel_1": "代表電話番号＿市外局番",
    "president_tel_2": "代表電話番号＿市内局番",
    "president_tel_3": "代表電話番号加入者番号",
    "fax_country_country_name": "FAX番号",
    "fax_country_code": "FAX番号＿国・地域名",
    "fax_1": "FAX番号＿市外局番",
    "fax_2": "FAX番号＿市内局番",
    "fax_3": "FAX番号＿加入者番号",
    "employee_regular": "正社員",
    "employee_part_time": "パート/アルバイト",
    "facility_name": "施設名",
    "facility_name_kana": "施設名（フリガナ）",
    "facility_prefectures_id": "施設所在地＿都道府県",
    "facility_address_2": "施設所在地２",
    "home_page": "ホームページ",
    "position": "役職",
    "pic_name": "担当者氏名",
    "pic_name_kana": "担当者氏名（フリガナ）",
    "pic_tel_country_name": "電話番号",
    "pic_tel_country_code": "担当者電話番号＿国・地域名",
    "pic_tel_1": "担当者電話番号＿市外局番",
    "pic_tel_2": "担当者電話番号＿市内局番",
    "pic_tel_3": "担当者電話番号＿加入者番号",
    "mail_address": "メールアドレス"
};
