import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import "../../Css/Common.css";
import Intern_Icon from "../../Images/icon_intern.svg";

/**
 * インターンの概要
 */
class GRSMMISF01 extends BaseUIComponent {
    constructor(props) {
        super(props);
    }

    // 前画面へ戻る
    handleToDisplay = (changeTo) => {
        if (changeTo === "GRSMMMLI01") {
            return this.props.changeDisp(changeTo);
        } else {
            return this.props.skipToNext(changeTo, this.props.caseOutline, this.props.caseDetail);
        }
    }

    render() {
        return (
            <main className="">
                <div className="bl_mainHeader">
                    <div className="bl_mainHeader_linkUnit">
                        <a href="#" className="bl_mainHeader_link">
                            インターンの概要
          <img src={Intern_Icon} alt="" />
                        </a>
                    </div>
                    <div className="bl_mainHeader_inner">
                        <h1 className="el_lv1Heading">
                            インターンの概要
          </h1>
                    </div>
                </div>
                <div className="ly_mainCont">
                    <nav className="bl_breadcrumb">
                        <ol className="bl_breadcrumb_list">
                            <li>
                                <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                            </li>
                            <li>
                                インターンの概要
            </li>
                        </ol>
                    </nav>
                    <section className="bl_sect">
                        <div className="ly_mainCont_inner">
                            <div className="bl_sect_header">
                                <h2 className="el_lv2Heading">インターンとは？</h2>
                            </div>
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">
                                    <div className="bl_sect_list">
                                        <ul className="el_list_blue">
                                            <li>学生が在学中に企業に研修に行き、会社の様子や仕事の内容、人間関係などを実践的に学ぶことができる制度です。就職や転職のミスマッチをなくすために生まれた制度で、労使ともにメリットの有る形での就職を目指しています。</li>
                                            <li>海外の大学と日本が直接産学協定を結んで行うインターンシップ制度は、 研修実績（3ヶ月、6ヶ月、1年）に応じて大学から卒業単位を取得できます。</li>
                                        </ul>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>企業が海外インターン生を採用するメリット</span>
                                        </h3>
                                    </div>
                                    <div className="bl_sect_list">
                                        <ul className="el_list_blue">
                                            <li>特定技能や日本に在留する外国籍の採用よりも安価に外国人材活用を経験でき、正式な採用に向けたノウハウを貯めることができます。</li>
                                            <li>広告や案内、サービス、マニュアルなど、お客様向けや従業員向けにどのようなものを用意したらよいかといった意見を、顧客の視点からも従業員の視点からも得られるチャンスになります。</li>
                                            <li>インターンは3ヶ月～1年と期限が決まっているため、外国人材の活用が効果的な仕事を見極める試験の場としても活用できます。</li>
                                            <li>学生に気にってもらえると 卒業後にそのまま就職してもらえる可能性もありますし、学内でいい口コミを流してもらえると、その後の人材の交流が活発になるとともに、集客の起点になるチャンスもあります。</li>
                                        </ul>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>学生がインターンに参加するメリット</span>
                                        </h3>
                                    </div>
                                    <div className="bl_sect_list">
                                        <ul className="el_list_blue">
                                            <li>自国では学べない、生きた語学学習、生活習慣、ビジネスマナーを身につけることができます。</li>
                                            <li>特に日本の接客レベルは世界でも高いため、日本でのビジネスマナーの学習は学生にとって非常に魅力的です。</li>
                                            <li>治安がよく安定した国で働きたいという考えから、学生のうちから日本で人や職場との繋がりを作れることを ポジティブにとらえている学生やご家族が多いです。</li>
                                        </ul>
                                        <p>各種手続きの代行を承っております。気軽にお申し込みください</p>
                                    </div>
                                </div>
                            </section>
                            <div className="bl_sect_header">
                                <h2 className="el_lv2Heading">募集の流れ</h2>
                            </div>
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>インターン募集の流れ</span>
                                        </h3>
                                    </div>
                                    <div className="bl_sect_list">
                                        <ol className="el_numlist">
                                            <li>大学と事業者との連携協定を締結する</li>
                                            <li>学生に対して、事前説明会を開催する<span className="el_note">※学生はほとんど日本の企業の事情を知らないため、事前告知が必要</span></li>
                                            <li>大学内での募集を開始</li>
                                            <li>学内での事前選考<span className="el_note">単位取得数や日本語検定2級以上など</span></li>
                                            <li>事業者による面接を実施し候補者を選定<span className="el_note">直接現地に赴くか、テレビ電話などを利用する。</span></li>
                                            <li>学内での本選抜<span className="el_note">学内稟議で在学証明書の交付を決定する。送り出せる人数が限られている場合は選抜を行う。</span><span className="el_comment">※ここまでで３～４ヶ月</span></li>
                                            <li>ビザ申請・入国採用決定<span className="el_note">入国不採用になる場合がある。その時は２に戻る。</span></li>
                                            <li>事前研修、浮き入れ準備、搭乗手続きなど<span className="el_comment">※７．８で約２～３ヶ月 ここまで累計５～７ヶ月</span></li>
                                            <li>就業体験（3ヶ月、6ヶ月、1年）</li>
                                            <li>終了報告（学生がまとめて発表します）</li>
                                        </ol>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>台湾の場合</span>
                                        </h3>
                                    </div>
                                    <p>8～9月に募集を開始し、2～6月に就業体験を行うパターンと4～4月に募集を開始し、9～1月に就業体験を行うパターンが一般的です</p>
                                </div>
                            </section>
                            <div className="bl_sect_header">
                                <h2 className="el_lv2Heading">費用</h2>
                            </div>
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>外国籍インターン受入にかかる費用</span>
                                        </h3>
                                    </div>
                                    <div className="bl_sect_list">
                                        <ul className="el_list_blue">
                                            <li>人材受入の初期費用：約40万</li>
                                            <li>行政書士費用（１人あたり5万円程度）</li>
                                            <li>渡航費用（１人あたり往復20万円程度　地域によって異なる）</li>
                                            <li>紹介料金（１人あたり10万円程度）</li>
                                            <li>大学への支援金（インターン終了時に３～６万円程度）</li>
                                        </ul>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h3 className="el_lv3Heading">
                                            <span>インターン中の月々の費用（月々約8～9万円）</span>
                                        </h3>
                                    </div>
                                    <div className="bl_sect_list">
                                        <ul className="el_list_blue">
                                            <li>生活支援金<br />
          最低賃金×就業時間 相当の手取りになる金額が一般的です。寮や食事を提供する場合は税法上、寮費・食費の徴収を推奨します（少額でよい）。<br />
          支援額から控除を差し引いた額が月88000円または年間103万円を超えると 所得税と住民税が課税されて返って学生の手取りが減る場合があります。</li>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                            <div className="bl_sect_header">
                                <h2 className="el_lv2Heading">ノウハウ</h2>
                            </div>
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">

                                    <ul className="el_list_blue">
                                        <li>インターンは就業体験です。<br /><span className="el_bold">雇用保険、労災保険、厚生年金には加入する必要はありません。</span></li>
                                        <li>3ヶ月以上滞在する場合、雇用主が負担する必要はありませんが<span className="el_bold">学生には健康保険と国民年金に加入</span>してもらいましょう。<br />
          学生で収入が無いため申請すれば支払い免除になります。</li>
                                        <li>インターンの実態調査のため、就業体験の期間に大学教員が訪問します。 その際の宿泊費や食費をサービスすると大学教授に気に入られ選ばれやすい傾向があります。</li>
                                        <li>職業体験の最後に学生がインターンを体験して学んだことをまとめて発表する必要があります。<br />
          職員をあつめて発表の場を設けましょう（発表と質疑合わせて20～30分程度）</li>
                                        <li>職場と寮が離れている場合は、その旨を明記しましょう。女学生の場合は特に通勤経路の治安を気にするため、トラブルになる場合があります。</li>
                                    </ul>
                                </div>
                            </section>
                            <button type="button" className="el_backBtn el_backBtn_right" onClick={() => this.handleToDisplay(this.props.beforeDispId)}>戻る</button>
                        </div>
                    </section>
                </div>
            </main>
        );
    }
}

export default GRSMMISF01;

