import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getTrainingReportList, GetTrainingReportListReset } from "./GISDMIRI01Action";
import { GetDailyReportListReset } from "../DmGISDMRLS01/GISDMRLS01Action";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { getCodeName } from "../../Constants/CommonConstants";
import "../../Css/Common.css";

/**
 * 実習レポート照会
 */
class GISDMIRI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            reportDate: this.props.data,
            training_report_list: this.props.data.training_report_list,
            employment_name_kana: this.props.data.employment_name_kana,
            internship_report_date: this.props.data.internship_report_date,
            internship_comment: this.props.data.internship_comment,
            guidance_personnel_comment: this.props.data.guidance_personnel_comment
        };
    }


    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo, isReset) => {
        window.scrollTo(0, 0);
        if (isReset) {
            this.props.getTrainingReportListReset();
            if (changeTo == "GAFMCMCFDI01") {
                this.props.getDailyReportListReset();
            }
        }
        return this.props.changeDisp(changeTo);
    }


    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {
        return (
            <Fragment>
                <div className="ly_cont">
                    <div class="bl_mainHeader">
                        <div class="bl_mainHeader_inner">
                            <h1 class="el_lv1Heading">
                                実習レポート照会
                            </h1>
                        </div>
                    </div>
                    <div class="ly_mainCont">
                        <nav class="bl_breadcrumb">
                            <ol class="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay('GAFMCMCFDI01', true)}>ユーザ一覧</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay('GISDMRLS01_GAFMCMCFDI01', true)}>日報日次一覧</a></li>
                                <li>実習レポート照会</li>
                            </ol>
                        </nav>
                        <section class="bl_sect">
                            <div class="ly_mainCont_inner">
                                <div class="bl_sect_inner">
                                    <div class="bl_sect_header">
                                        <h2 class="el_lv2Heading">{this.state.internship_report_date}　{this.state.employment_name_kana}</h2>
                                    </div>
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <div class='sticky_table_wrapper sticky_table_wrapperNoScroll'>
                                            <table class='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th></th><th>開始時間</th><th>終了時間</th><th>実習カテゴリ</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.state.training_report_list)) {
                                                            return (
                                                                this.state.training_report_list.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td class="hp_tac hp_bg__gl" rowspan="2">No.{index + 1}</td>
                                                                            <td class="hp_tar">{item.start_time}</td>
                                                                            <td class="hp_tar">{item.end_time}</td>
                                                                            <td>{item.internship_type_name}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                                {isBlank(item.internship_contents) ? null : item.internship_contents.split('\n').map((str, index) => (
                                                                                    <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                                ))}
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                    {(() => {
                                                        if (!checkArray(this.state.training_report_list)) {
                                                            return (
                                                                [1, 2, 3].map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td class="hp_tac hp_bg__gl" rowspan="2">No.{item}</td>
                                                                            <td class="hp_tar"></td>
                                                                            <td class="hp_tar"></td>
                                                                            <td></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colspan="3">
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        今日学んだこと・感じたこと
                                                    </th>
                                                    <td>
                                                        {isBlank(this.state.internship_comment) ? null : this.state.internship_comment.split('\n').map((str, index) => (
                                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                        ))}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        指導担当者コメント
                                                </th>
                                                    <td>
                                                        {isBlank(this.state.guidance_personnel_comment) ? null : this.state.guidance_personnel_comment.split('\n').map((str, index) => (
                                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                        ))}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div class="bl_horizBtnUnit__move">
                                        <button tabIndex="1" type="button" class="el_blueBtn" onClick={() => this.handleToDisplay('GISDMRLS01_GAFMCMCFDI01', true)}>日報日次ー覧へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.getTrainingReportList.data,
        isError: state.getTrainingReportList.isError,
        err_type: state.getTrainingReportList.err_type,
        err_detail: state.getTrainingReportList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getTrainingReportListReset() {
            // 日報日次一覧Reset
            dispatch(GetTrainingReportListReset());
        },
        getTrainingReportInfo(condition) {
            // 案件一覧情報取得
            dispatch(getTrainingReportList(condition));
        },
        getDailyReportListReset: () => dispatch(GetDailyReportListReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISDMIRI01);

