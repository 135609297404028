import React, { useState, useEffect } from "react";
import { getNumber } from "../Tools/StringTools";

/**
 * テキストコンポーネント
 * @param {*} props
 */
export default function TextInput(props) {

    // 現在の値と更新用関数を入手、初期値 setValue をセット
    const [txt, setTxt] = useState(props.setValue);

    /**
     * 値が変更された処理
     * @param {*} e
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTxt(value);
        if (props.onHandleChange !== undefined) {
            props.onHandleChange(name, value);
        }
    };

    // レンダリング後に実行されるので、確実に取得できる
    useEffect(() => {
        setTxt(props.setValue);
    });

    /**
     * 半角カタカナ→全角カタカナ変換
     */
    function hankana2Zenkana(str) {
        var strMap = {
            'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
            'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
            'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
            'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
            'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
            'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
            'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
            'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
            'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
            'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
            'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
            'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
            'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
            'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
            'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
            'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
            'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
            'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
            'ｰ': 'ー', ' ': '　'
        };

        var reg = new RegExp('(' + Object.keys(strMap).join('|') + ')', 'g');
        var newStr = str
            .replace(reg, function (match) {
                return strMap[match];
            });

        return newStr;
    }

    /**
     * カーソルがテキストから外す処理
     * @param {*} e
     */
    const handleBlur = (e) => {
        const { name, value } = e.target;
        let newValue = value;
        if (props.toZenKana != null && (props.toZenKana == 'true' || props.toZenKana == true)) {
            newValue = hankana2Zenkana(value);
        }
        // 数値フォーマット
        if (props.numberFormat != null && (props.numberFormat == 'true' || props.numberFormat == true)) {
            newValue = getNumber(value);
        }
        setTxt(newValue);
        if (props.onHandleChange !== undefined) {
            props.onHandleChange(name, newValue);
        }
    };

    return (
        <input
            id={props.id}
            name={props.name}
            className={props.className}
            type={props.type}
            value={txt}
            onChange={handleChange}
            required={props.required}
            min={props.min}
            minLength={props.minlength}
            maxLength={props.maxlength}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            tabIndex={props.tabIndex}
            toZenKana={props.toZenKana}
            numberFormat={props.numberFormat}
            onBlur={handleBlur}
            inputmode={props.inputmode == 'decimal' ? 'decimal' : 'text'}
            disabled={props.disabled} />
    );
}
