import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getWorkingHours, getWorkingHoursReset, updateWorkingHours, updateWorkingHoursReset } from "../WtGTMWTWTR02/GTMWTWTR02Action";
import { isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 簡易作業時間登録
 */
class GTMWTWTR02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
            init_flg: 0,
            detailData: null
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.pageInitial();
    }

    /**
     * 初期化処理
     */
    pageInitial = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.getWorkingHoursReset());

        this.setState({
            get_type: '3',
            err_msg: []
        }, () => {
            // 初期化処理を行う
            this.props.getWorkingHours(this.state);
        });
    }

    /**
     * 共通処理を実行する
     */
    handleAction = (handle_type) => {
        window.scrollTo(0, 0);

        if ((!isBlank(this.props.workingHoursData.work_start_time) && handle_type == '1')
            || (!isBlank(this.props.workingHoursData.work_end_time) && handle_type == '2')
            || (!isBlank(this.props.workingHoursData.rest_start_time) && handle_type == '3')
            || (!isBlank(this.props.workingHoursData.rest_end_time) && handle_type == '4')) {
            let message = getMessage('RS-0044');
            let params = Object.assign({}, this.state);
            params['btn_type'] = handle_type;
            // 確認ダイアログメッセージボックスを表示する
            this.showModalDialog(1, message, () => {
                this.editItemHandler('init_flg', 1);
                this.props.updateWorkingHours(params);
            }, () => { });
        } else {
            let params = Object.assign({}, this.state);
            params['btn_type'] = handle_type;
            this.props.updateWorkingHours(params);
        }
    }

    /**
     * 出社
     */
    getToWork = () => {
        this.handleAction('1');
    }

    /**
     * 退社
     */
    getOffWork = () => {
        this.handleAction('2');
    }

    /**
     * 休憩開始
     */
    breaktimeStart = () => {
        this.handleAction('3');
    }

    /**
     * 休憩終了
     */
    breaktimeEnd = () => {
        this.handleAction('4');
    }

    /**
     * 作業時間修正
     */
    modifyWorkTime = () => {
        this.props.changeDisp("GTMWTWTR01", this.props.workingHoursData);
    }

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    render() {
        const { init_flg, version_no } = this.state;
        if (this.props.workingHoursData && this.props.workingHoursData.user_id != null) {
            if (init_flg === 0) {
                this.editItemHandler('user_id', this.props.workingHoursData.user_id);
                this.editItemHandler('case_id', this.props.workingHoursData.case_id);
                this.editItemHandler('work_report_date', this.props.workingHoursData.work_report_date);
                // 最新バージョン設定する
                this.editItemHandler('version_no', this.props.workingHoursData.version_no);
                this.editItemHandler('init_flg', 1);
            } else if (version_no !== this.props.workingHoursData.version_no) {
                // 最新バージョン設定する
                this.editItemHandler('version_no', this.props.workingHoursData.version_no);
                // 成功メッセージを表示
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.props.getWorkingHoursReset();
                    this.props.updateWorkingHoursReset();
                    this.props.getWorkingHours(this.state);
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">簡易作業時間登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>

                                <li>簡易作業時間登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                {
                                    (this.props.workingHoursData.has_adoptiony === "1") ?
                                        <ErrorMessage err_type={300} err_detail={[{ err_code: 'RS-0071' }]} messageMap={{}} />
                                        :
                                        null
                                }
                                {
                                    (this.props.workingHoursData.work_approval_status === "1") ?
                                        <ErrorMessage err_type={300} err_detail={[{ err_code: 'RS-0050' }]} messageMap={{}} />
                                        :
                                        null
                                }
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <ErrorMessage err_type={this.props.working_hours_err_type} err_detail={this.props.working_hours_err_detail} messageMap={{}} />
                                <div className="bl_sect_inner">
                                    {
                                        (this.props.workingHoursData.work_approval_status === "1") ?
                                            <section className="bl_sect_frame">
                                                <div className="bl_timeResistBtns">
                                                    <button type="button" className="un_workStart el_blueBtn is_disabled" tabIndex="1" >出社</button>
                                                    <button type="button" className="un_workEnd el_blueBtn is_disabled" tabIndex="2" >退社</button>
                                                </div>
                                                <div className="bl_timeResistBtns">
                                                    <button type="button" className="un_restStart el_blueBtn is_disabled" tabIndex="3" >休憩開始</button>
                                                    <button type="button" className="un_restEnd el_blueBtn is_disabled" tabIndex="4" >休憩終了</button>
                                                </div>
                                            </section>
                                            :
                                            <section className="bl_sect_frame">
                                                <div className="bl_timeResistBtns">
                                                    <button type="button" className={this.props.workingHoursData.has_adoptiony === "1" ? "un_workStart el_blueBtn is_disabled" : "un_workStart"} onClick={() => this.getToWork()} tabIndex="1">出社</button>
                                                    <button type="button" className={this.props.workingHoursData.has_adoptiony === "1" ? "un_workEnd el_blueBtn is_disabled" : "un_workEnd"} onClick={() => this.getOffWork()} tabIndex="2">退社</button>
                                                </div>
                                                <div className="bl_timeResistBtns">
                                                    <button type="button" className={this.props.workingHoursData.has_adoptiony === "1" ? "un_restStart el_blueBtn is_disabled" : "un_restStart"} onClick={() => this.breaktimeStart()} tabIndex="3">休憩開始</button>
                                                    <button type="button" className={this.props.workingHoursData.has_adoptiony === "1" ? "un_restEnd el_blueBtn is_disabled" : "un_restEnd"} onClick={() => this.breaktimeEnd()} tabIndex="4">休憩終了</button>
                                                </div>
                                            </section>
                                    }
                                    <button type="button" className={this.props.workingHoursData.has_adoptiony === "1" ? "el_backBtn el_blueBtn is_disabled" : "el_backBtn"} onClick={() => this.modifyWorkTime()} tabIndex="5">作業時間修正</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        // 作業時間管理
        workingHoursIsFetching: state.workingHours.isFetching,
        workingHoursData: state.workingHours.data,
        workingHoursIsError: state.workingHours.isError,
        working_hours_err_type: state.workingHours.err_type,
        working_hours_err_detail: state.workingHours.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getWorkingHours: (args) => dispatch(getWorkingHours(args)),
        getWorkingHoursReset: () => dispatch(getWorkingHoursReset()),
        updateWorkingHours: (args) => dispatch(updateWorkingHours(args)),
        updateWorkingHoursReset: () => dispatch(updateWorkingHoursReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GTMWTWTR02);

