import React from "react";
import { sessionTimeoutCheck, getMenuInstance } from "../Constants/CommonConstants";

/**
 * ログイン画面以外の画面基底コンポーネント
 */
class BaseUIComponent extends React.Component {

    constructor(props) {
        super(props);
        // セッションタイムアウトチェック
        sessionTimeoutCheck();
        let menuInstance = getMenuInstance();
        if (menuInstance) {
            // ダイアログのコールバック初期化する
            menuInstance.setErrCallback(null);
            menuInstance.showModalDialog(null, null, null, null);
        }
    }

    /**
     * メニュー取得
     */
    getMenu = () => {
        return getMenuInstance();
    }

    /**
     * システムエラーメッセージボタンのコールバック設定
     */
    setErrorCallback = (callback) => {
        let menuInstance = getMenuInstance();
        if (menuInstance) {
            // システムエラーのコールバック設定
            menuInstance.setErrCallback(callback);
        }
    }

    /**
     * モーダルダイアログを表示する
     * @param {*} type  ダイアログ種類（詳細はModalMessage部品）
     * @param {*} message   ダイアログメッセージ
     * @param {*} okCallback    ダイアログOKボータンのコールバック処理
     * @param {*} cancelCallback    ダイアログキャンセルボータンのコールバック処理
     */
    showModalDialog = (type, message, okCallback, cancelCallback) => {
        let menuInstance = getMenuInstance();
        if (menuInstance) {
            menuInstance.showModalDialog(type, message, okCallback, cancelCallback);
        }
    }

}

export default BaseUIComponent;
