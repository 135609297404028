/**
 * Action Typesを定義
 */
const GSMBIBIO01ActionType = {
    GET_REQUEST_INFO_LIST_REQUEST: 'GET_REQUEST_INFO_LIST_REQUEST',  // 請求情報一覧照会取得定義
    GET_REQUEST_INFO_LIST_SUCCESS: 'GET_REQUEST_INFO_LIST_SUCCESS',  // 請求情報一覧照会取得成功定義
    GET_REQUEST_INFO_LIST_FAILURE: 'GET_REQUEST_INFO_LIST_FAILURE',  // 請求情報一覧照会取得失敗定義
    GET_REQUEST_INFO_LIST_RESET: 'GET_REQUEST_INFO_LIST_RESET',       // 請求情報一覧照会取得リセット定義
    CREAT_REQUEST_INFO_FILE_REQUEST: 'CREAT_REQUEST_INFO_FILE_REQUEST',  // 請求情報ファイル作成定義
    CREAT_REQUEST_INFO_FILE_SUCCESS: 'CREAT_REQUEST_INFO_FILE_SUCCESS',  // 請求情報ファイル作成成功定義
    CREAT_REQUEST_INFO_FILE_FAILURE: 'CREAT_REQUEST_INFO_FILE_FAILURE',  // 請求情報ファイル作成失敗定義
    CREAT_REQUEST_INFO_FILE_RESET: 'CREAT_REQUEST_INFO_FILE_RESET',      // 請求情報ファイル作成リセット定義
    DOWNLOAD_REQUEST_INFO_FILE_REQUEST: 'DOWNLOAD_REQUEST_INFO_FILE_REQUEST',  // 請求情報ファイルダウンロード定義
    DOWNLOAD_REQUEST_INFO_FILE_SUCCESS: 'DOWNLOAD_REQUEST_INFO_FILE_SUCCESS',  // 請求情報ファイルダウンロード成功定義
    DOWNLOAD_REQUEST_INFO_FILE_FAILURE: 'DOWNLOAD_REQUEST_INFO_FILE_FAILURE',  // 請求情報ファイルダウンロード失敗定義
    DOWNLOAD_REQUEST_INFO_FILE_RESET: 'DOWNLOAD_REQUEST_INFO_FILE_RESET',       // 請求情報ファイルダウンロードリセット定義
};

export default GSMBIBIO01ActionType;