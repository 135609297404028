// メッセージMap
export const MessageMap = {
    "plan_template_name": "テンプレート名",
    "work_type": "業務種別",
    "work_name": "業務名",
    "work_contents": "業務内容",
    "hours_1": "３月",
    "hours_2": "２月",
    "hours_3": "１月",
    "hours_4": "４月",
    "hours_5": "５月",
    "hours_6": "６月",
    "hours_7": "７月",
    "hours_8": "８月",
    "hours_9": "９月",
    "hours_10": "１０月",
    "hours_11": "１１月",
    "hours_12": "１２月",
    "work_plan_template_data": "実習計画テンプレート"
};
