import React from "react";
import { withRouter } from "react-router";
import GAFMCMCF01 from "./McGAFMCMCF01/GAFMCMCF01";
import GAFMCSNF01 from "./McGAFMCSNF01/GAFMCSNF01";
import GAFMCSNF02 from "./McGAFMCSNF02/GAFMCSNF02";

/**
 * 認証機能テンプレート
 */
class AfTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: "show_top",
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        if (this.props.location.dispId != null) {
            this.setState({
                display: this.props.location.dispId,
            });
        }
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        if (detailData != null) {
            this.setState({
                detailData: detailData
            });
        }
        this.setState({
            display: dispId
        });
    }


    render() {
        let displayParts;
        if (this.state.display === "show_top") {
            // Home画面
            displayParts = <GAFMCSNF01 changeDisp={this.switchDisplay} />;
        } else if (this.state.display === "detail") {
            // お知らせ詳細画面
            displayParts = <GAFMCSNF02 noticeDetail={this.state.detailData} changeDisp={this.switchDisplay} />;
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}


export default withRouter(AfTemplate);

