import React from 'react';
import LoginTemplate from "./loginTemplate";

function AfContainer() {
    return (
        <LoginTemplate />
    );
}

export default AfContainer;
