import actionTypes from './GAFMCSNF01ActionType';

// レスポンスState定義
const infoState = {
    isFetching: false,
    data: [],
    isError: false,
    detailData: null,
    errType: ""
};

/**
 * Home画面一覧データ取得
 * @param {*} state
 * @param {*} action
 */
export const getNoticeList = (state = infoState, action) => {
    switch (action.type) {
        case actionTypes.GET_NOTICE_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false
            });
        case actionTypes.GET_NOTICE_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false
            });
        case actionTypes.GET_NOTICE_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: true
            });
        case actionTypes.GET_NOTICE_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false
            });
        default:
            return state;
    }
};

/**
 * Home画面お知らせ詳細データ取得
 * @param {*} state
 * @param {*} action
 */
export const getNoticeDetail = (state = infoState, action) => {
    switch (action.type) {
        case actionTypes.GET_NOTICE_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                detailData: null,
                isError: false
            });
        case actionTypes.GET_NOTICE_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: action.data.data,
                isError: false
            });
        case actionTypes.GET_NOTICE_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: null,
                isError: true
            });
        case actionTypes.GET_NOTICE_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                detailData: null,
                isError: false
            });

        default:
            return state;
    }
};

/**
 * TODO更新
 * @param {*} state
 * @param {*} action
 */
export const updateTodoTable = (state = infoState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_TODO_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                errType: "",
                isError: false,
            });
        case actionTypes.UPDATE_TODO_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                errType: action.data.err_type,
                isError: false
            });
        case actionTypes.UPDATE_TODO_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                errType: "",
                isError: true
            });
        case actionTypes.UPDATE_TODO_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                errType: "",
                isError: false
            });

        default:
            return state;
    }
};

