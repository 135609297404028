import React from 'react';
import IsTemplate from "./template";

function Home() {
    return (
        <section className="App">
            <IsTemplate />
        </section>
    );
}

export default Home;
