import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import { connect } from "react-redux";
import { getCognitoUserInfo, getCodeName } from "../../Constants/CommonConstants";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { GetWorkingPlanInfoListReset, GetWorkingPlanInfoList } from "./GISIPWPL01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 実習計画一覧
 */
class GISIPWPL01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        if (this.props.beforeDispId != 'GISIPWPM01') {
            // 一覧以外から再取得する
            this.handleToQuery();
        }
    }

    /**
     * 検索処理
     */
    handleToQuery = () => {
        this.props.getCaseInfoReset();
        window.scrollTo(0, 0);
        this.props.getCaseInfo({ case_id: this.props.data.case_info.case_id });
    }

    /**
     * 案件選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {

    }

    handleToModify = (copy, value) => {
        window.scrollTo(0, 0);
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo, isReset) => {
        window.scrollTo(0, 0);
        if (isReset) {
            this.props.getCaseInfoReset();
        }
        return this.props.changeDisp(changeTo);
    }

    /**
     * 画面切替表示
     * @param {*} caseInfo
     * @param {*} workingPlanInfo
     * @returns
     */
    redirectToView = (caseInfo, workingPlanInfo) => {
        window.scrollTo(0, 0);
        const merged = { ...caseInfo, ...workingPlanInfo };
        merged['createorupdate'] = workingPlanInfo === undefined ? 'create' : 'update';

        // 実習計画登録へ遷移する
        return this.props.redirectToView("GISIPWPR01", merged);
    }

    render() {

        if (this.props.data == null || this.props.data.case_info == null) {
            // 取得中の場合、何も表示しない
            return null;
        }

        // 抽出一覧データをグループする
        let planList = [];
        if (this.props.data != null && checkArray(this.props.data.working_plan_list)) {
            this.props.data.working_plan_list.forEach(plan => {
                let item = {
                    plan_id: plan.plan_id,
                    plan_name: plan.plan_name,
                    list: [],
                    nameList: []
                };
                planList.forEach(planGroup => {
                    if (planGroup.plan_id == item.plan_id) {
                        item = planGroup;
                    }
                });
                if (item.list.length == 0) {
                    planList.push(item);
                }
                item.list.push(plan);
                item.nameList.push(plan.employment_name_kana);
            });
        }

        // ログインユーザ情報
        let userInfo = getCognitoUserInfo();
        let isDisabled = true;
        if (['COP', 'KAN', 'TOR'].includes(userInfo['org_type'])) {
            // ログインユーザの組織タイプ＝"COP"　または
            // ログインユーザの組織タイプ＝"KAN"　または
            // ログインユーザの組織タイプ＝"TOR"　の場合
            // 現在システム日付　＜　受入・就業期間開始日　の場合活性
            if (this.props.data != null && this.props.data.sys_date < this.props.data.case_info.accept_period_start) {
                isDisabled = false
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                実習計画一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>
                                    <a href="#" onClick={() => this.handleToDisplay('GISIPWPM01', true)}>実習計画案件一覧</a>
                                </li>
                                <li>実習計画一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">

                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                <div className="bl_sect_inner">

                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <table className="bl_horizTable02">
                                            <tbody>

                                                <tr>
                                                    <th>案件名</th>
                                                    <td>{this.props.data.case_info.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>{this.props.data.case_info.case_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{this.props.data.case_info.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>施設名</th>
                                                    <td>{this.props.data.case_info.facility_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>{getCodeName('RSC0004', this.props.data.case_info.status_of_residence)}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    <td>
                                                        {isBlank(this.props.data.case_info.accept_period_start) && isBlank(this.props.data.case_info.accept_period_end) ? '' : this.props.data.case_info.accept_period_start + "\n" + "〜" + "\n" + this.props.data.case_info.accept_period_end}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>

                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading"><span>実習計画編集／登録</span></h2>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                            <button tabIndex="1" type="button" disabled={isDisabled} className={isDisabled ? "el_blueBtn is_disabled" : "el_blueBtn"} onClick={() => this.redirectToView(this.props.data.case_info)}>新規登録</button>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr><th>実習計画名</th><th>実習者名</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(planList)) {
                                                            return (
                                                                planList.map((item, index) => (
                                                                    <Fragment key={item.plan_id}>
                                                                        <tr>
                                                                            <td><a href="#" className="el_link" onClick={() => this.redirectToView(this.props.data.case_info, item.list[0])}>{item.plan_name}</a></td>
                                                                            <td>{item.nameList.join('、')}</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>

                                    <div className="bl_horizBtnUnit__move">
                                        <button tabIndex="2" type="button" className="el_blueBtn" onClick={() => this.handleToDisplay('GISIPWPM01', true)}>案件一覧へ</button>
                                    </div>

                                </div>
                            </div>

                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.getWorkingPlanInfoList.data,
        err_type: state.getWorkingPlanInfoList.err_type,
        err_detail: state.getWorkingPlanInfoList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getCaseInfoReset() {
            // 実習計画案件リセットする
            dispatch(GetWorkingPlanInfoListReset());
        },
        getCaseInfo(condition) {
            // 実習計画案件取得
            dispatch(GetWorkingPlanInfoList(condition));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISIPWPL01);

