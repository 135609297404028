/**
 * Action Typesを定義
 */
const GAFLFLAF02ActionType = {
    GET_PERSONAL_INFO_REQUEST: "GET_PERSONAL_INFO_REQUEST", // 個人情報取り扱い同意取得定義
    GET_PERSONAL_INFO_SUCCESS: "GET_PERSONAL_INFO_SUCCESS", // 個人情報取り扱い同意取得成功定義
    GET_PERSONAL_INFO_FAILURE: "GET_PERSONAL_INFO_FAILURE", // 個人情報取り扱い同意取得失敗定義
    GET_PERSONAL_INFO_RESET: "GET_PERSONAL_INFO_RESET",   // 個人情報取り扱い同意取得リセット定義
    UPDATE_PERSONAL_INFO_REQUEST: "UPDATE_PERSONAL_INFO_REQUEST", // 個人情報取り扱い同意定義
    UPDATE_PERSONAL_INFO_SUCCESS: "UPDATE_PERSONAL_INFO_SUCCESS", // 個人情報取り扱い同意成功定義
    UPDATE_PERSONAL_INFO_FAILURE: "UPDATE_PERSONAL_INFO_FAILURE", // 個人情報取り扱い同意失敗定義
    UPDATE_PERSONAL_INFO_RESET: "UPDATE_PERSONAL_INFO_RESET",   // 個人情報取り扱い同意リセット定義
};

export default GAFLFLAF02ActionType;
