// メッセージMap
export const MessageMap = {
    "status_of_residence": "在留資格",
    "candidate_photo": "応募者写真",
    "candidate_student_no": "学籍番号",
    "candidate_name": "応募者氏名(ローマ字)",
    "candidate_name_kana": "応募者氏名(フリガナ)",
    "candidate_nickname": "応募者氏名(ニックネーム)",
    "born_country_code": "国籍",
    "candidate_birthday": "生年月日",
    "candidate_sex": "性別",
    "candidate_japanese_level": "日本語レベル",
    "candidate_language": "得意言語",
    "candidate_department": "学部・専攻",
    "candidate_acquired_credits": "取得済単位数",
    "candidate_intern_schedule_credits": "インターンでの取得予定単位数",
    "candidate_required_credits": "卒業までに必要な単位数",
    "candidate_appeal": "自己アピール",
    "license": "保有資格",
    "work_history": "職歴",
    "video_url": "自己紹介動画URL"
};
