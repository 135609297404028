/**
 * Action Typesを定義
 */
const GISTTPTL01ActionType = {
    GET_TRAINING_PLAN_TEMPLATE_INFO_REQUEST: 'GET_TRAINING_PLAN_TEMPLATE_INFO_REQUEST',         // 実習計画テンプレート初期取得定義
    GET_TRAINING_PLAN_TEMPLATE_INFO_SUCCESS: 'GET_TRAINING_PLAN_TEMPLATE_INFO_SUCCESS',         // 実習計画テンプレート初期取得成功定義
    GET_TRAINING_PLAN_TEMPLATE_INFO_FAILURE: 'GET_TRAINING_PLAN_TEMPLATE_INFO_FAILURE',         // 実習計画テンプレート初期取得失敗定義
    GET_TRAINING_PLAN_TEMPLATE_INFO_RESET: 'GET_TRAINING_PLAN_TEMPLATE_INFO_RESET',             // 実習計画テンプレート初期取得リセット定義
    GET_TRAINING_PLAN_TEMPLATE_LIST_REQUEST: 'GET_TRAINING_PLAN_TEMPLATE_LIST_REQUEST',         // 実習計画テンプレート一覧検索定義
    GET_TRAINING_PLAN_TEMPLATE_LIST_SUCCESS: 'GET_TRAINING_PLAN_TEMPLATE_LIST_SUCCESS',         // 実習計画テンプレート一覧検索成功定義
    GET_TRAINING_PLAN_TEMPLATE_LIST_FAILURE: 'GET_TRAINING_PLAN_TEMPLATE_LIST_FAILURE',         // 実習計画テンプレート一覧検索失敗定義
    GET_TRAINING_PLAN_TEMPLATE_LIST_RESET: 'GET_TRAINING_PLAN_TEMPLATE_LIST_RESET',             // 実習計画テンプレート一覧検索リセット定義
    TRAINING_PLAN_TEMPLATE_DETAIL_REQUEST: 'TRAINING_PLAN_TEMPLATE_DETAIL_REQUEST',             // 実習計画テンプレート詳細取得定義
    TRAINING_PLAN_TEMPLATE_DETAIL_SUCCESS: 'TRAINING_PLAN_TEMPLATE_DETAIL_SUCCESS',             // 実習計画テンプレート詳細取得成功定義
    TRAINING_PLAN_TEMPLATE_DETAIL_FAILURE: 'TRAINING_PLAN_TEMPLATE_DETAIL_FAILURE',             // 実習計画テンプレート詳細取得失敗定義
    TRAINING_PLAN_TEMPLATE_DETAIL_RESET: 'TRAINING_PLAN_TEMPLATE_DETAIL_RESET',                 // 実習計画テンプレート詳細取得リセット定義
    TRAINING_PLAN_TEMPLATE_DETAIL_COPY_REQUEST: 'TRAINING_PLAN_TEMPLATE_DETAIL_COPY_REQUEST',   // 実習計画テンプレートコピー作成定義
    TRAINING_PLAN_TEMPLATE_DETAIL_COPY_SUCCESS: 'TRAINING_PLAN_TEMPLATE_DETAIL_COPY_SUCCESS',   // 実習計画テンプレートコピー作成成功定義
    TRAINING_PLAN_TEMPLATE_DETAIL_COPY_FAILURE: 'TRAINING_PLAN_TEMPLATE_DETAIL_COPY_FAILURE',   // 実習計画テンプレートコピー作成失敗定義
    TRAINING_PLAN_TEMPLATE_DETAIL_COPY_RESET: 'TRAINING_PLAN_TEMPLATE_DETAIL_COPY_RESET',       // 実習計画テンプレートコピー作成リセット定義
    TRAINING_PLAN_TEMPLATE_DELETE_REQUEST: 'TRAINING_PLAN_TEMPLATE_DELETE_REQUEST',             // 実習計画テンプレート削除定義
    TRAINING_PLAN_TEMPLATE_DELETE_SUCCESS: 'TRAINING_PLAN_TEMPLATE_DELETE_SUCCESS',             // 実習計画テンプレート削除成功定義
    TRAINING_PLAN_TEMPLATE_DELETE_FAILURE: 'TRAINING_PLAN_TEMPLATE_DELETE_FAILURE',             // 実習計画テンプレート削除失敗定義
};

export default GISTTPTL01ActionType;
