/**
 * Action Typesを定義
 */
const GISAMIAR01ActionType = {
    GET_TRAINING_RESULTS_EVALUATION_REQUEST: 'GET_TRAINING_RESULTS_EVALUATION_REQUEST',  // 実習結果評価取得定義
    GET_TRAINING_RESULTS_EVALUATION_SUCCESS: 'GET_TRAINING_RESULTS_EVALUATION_SUCCESS',  // 実習結果評価取得成功定義
    GET_TRAINING_RESULTS_EVALUATION_FAILURE: 'GET_TRAINING_RESULTS_EVALUATION_FAILURE',  // 実習結果評価取得失敗定義
    GET_TRAINING_RESULTS_EVALUATION_RESET: 'GET_TRAINING_RESULTS_EVALUATION_RESET',      // 実習結果評価取得リセット定義
    TRAINING_RESULTS_EVALUATION_REQUEST: 'TRAINING_RESULTS_EVALUATION_REQUEST',  // 実習結果評価登録定義
    TRAINING_RESULTS_EVALUATION_SUCCESS: 'TRAINING_RESULTS_EVALUATION_SUCCESS',  // 実習結果評価登録成功定義
    TRAINING_RESULTS_EVALUATION_FAILURE: 'TRAINING_RESULTS_EVALUATION_FAILURE',  // 実習結果評価登録失敗定義
    TRAINING_RESULTS_EVALUATION_RESET: 'TRAINING_RESULTS_EVALUATION_RESET'       // 実習結果評価登録リセット定義
};

export default GISAMIAR01ActionType;