/**
 * Action Typesを定義
 */
const GRSMMIMD01ActionTypes = {
    CASE_DETAIL_REQUEST: 'CASE_DETAIL_REQUEST',
    CASE_DETAIL_SUCCESS: 'CASE_DETAIL_SUCCESS',
    CASE_DETAIL_FAILURE: 'CASE_DETAIL_FAILURE',
    CASE_DETAIL_RESET: 'CASE_DETAIL_RESET',
    ENTRY_LIST_REQUEST: 'ENTRY_LIST_REQUEST',
    ENTRY_LIST_SUCCESS: 'ENTRY_LIST_SUCCESS',
    ENTRY_LIST_FAILURE: 'ENTRY_LIST_FAILURE',
    ENTRY_LIST_RESET: 'ENTRY_LIST_RESET',
    GET_CASE_CANDIDATE_LIST_REQUEST: 'GET_CASE_CANDIDATE_LIST_REQUEST',
    GET_CASE_CANDIDATE_LIST_SUCCESS: 'GET_CASE_CANDIDATE_LIST_SUCCESS',
    GET_CASE_CANDIDATE_LIST_FAILURE: 'GET_CASE_CANDIDATE_LIST_FAILURE',
    GET_CASE_CANDIDATE_LIST_RESET: 'GET_CASE_CANDIDATE_LIST_RESET',
};

export default GRSMMIMD01ActionTypes;