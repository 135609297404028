

import { getTrainingResultsList, trainingResultsDownloadFile } from "./AmGISAMIRS01/GISAMIRS01Reducer";
import { getTrainingPlanTemplateList, getTrainingPlanTemplateDetail } from "./TtGISTTPTL01/GISTTPTL01Reducer";
import { saveTrainingPlanTemplateInfo } from "./TtGISTTPTR01/GISTTPTR01Reducer";
import { saveTrainingPlanInfo } from "./IpGISIPWPR01/GISIPWPR01Reducer";
import { getReportContentInfo, saveReportContentInfo } from "./DmGISDMIRR01/GISDMIRR01Reducer";
import { getTrainingResultsEvaluation, saveTrainingResultsEvaluation } from "./AmGISAMIAR01/GISAMIAR01Reducer";
import { getTrainingPlanInfo } from "./IpGISIPWPR01/GISIPWPR01Reducer";
import { getWorkingPlanCaseList } from "./IpGISIPWPM01/GISIPWPM01Reducer";
import { getWorkingPlanInfoList } from "./IpGISIPWPL01/GISIPWPL01Reducer";
import { trainingResults } from "./AmGISAMIAR02/GISAMIAR02Reducer";
import { getDailyReportList } from "./DmGISDMRLS01/GISDMRLS01Reducer";
import { getTrainingReportList } from "./DmGISDMIRI01/GISDMIRI01Reducer";

export const isReducer = {
    getTrainingResultsList,
    trainingResultsDownloadFile,
    getTrainingResultsEvaluation,
    saveTrainingResultsEvaluation,
    getTrainingPlanTemplateList,
    getTrainingPlanTemplateDetail,
    getTrainingPlanInfo,
    getReportContentInfo,
    saveTrainingPlanTemplateInfo,
    saveTrainingPlanInfo,
    saveReportContentInfo,
    trainingResults,
    getWorkingPlanCaseList,
    getWorkingPlanInfoList,
    getDailyReportList,
    getTrainingReportList
};
