import React, { Fragment } from "react";
import { getMessage } from "../../Constants/CommonConstants";

/**
 * エラーメッセージコンポーネント
 * @param {*} props
 */
export default function ErrorMessage(props) {
    return (
        <Fragment>
            {
                (
                    () => {
                        if (props.err_type != '200' && props.err_type != '' && props.err_type != null) {
                            let errors = props.err_detail;
                            let otherErrors = {};
                            if (props.err_detail.length > 5) {
                                errors = props.err_detail.slice(0, 4);
                                otherErrors['other'] = 'その他' + (props.err_detail.length - 4) + '件';
                            }
                            if (props.err_type == '310') {
                                return (
                                    <div id='errorarea'>
                                        <ul className='errorArea'>
                                            {(() => {
                                                return (
                                                    Object.entries(errors).map(
                                                        ([key, value]) => {
                                                            if (value.err_item) {
                                                                let erritems = value.err_item.split(",");
                                                                if (erritems.length > 1) {
                                                                    let message = getMessage(value.err_code);
                                                                    erritems.map((item, key) => {
                                                                        if (message) {
                                                                            key = key + 1;
                                                                            message = message.replace("%" + key, (item in props.messageMap ? props.messageMap[item] : item));
                                                                        } else {
                                                                            return (<li key={key} className="errorText">{""}</li>);
                                                                        }
                                                                    });
                                                                    return <li className="errorText">{message}</li>;
                                                                } else {
                                                                    return (
                                                                        <li key={key} className="errorText">{getMessage(value.err_code) ? getMessage(value.err_code).replace(/%1/g, (value.err_item in props.messageMap ? props.messageMap[value.err_item] : value.err_item)) : ""}</li>
                                                                    );
                                                                }
                                                            } else {
                                                                return (
                                                                    <li key={key} className="errorText">{getMessage(value.err_code)}</li>
                                                                );
                                                            }
                                                        }
                                                    )
                                                );
                                            })()}
                                            {
                                                Object.entries(otherErrors).map(
                                                    ([key, value]) => (
                                                        <li className="el_noMark">{value}</li>
                                                    )
                                                )
                                            }
                                        </ul>
                                    </div>

                                );
                            } else if (props.err_type == '300') {
                                return (
                                    <div id='errorarea'>
                                        <ul className='errorArea'>
                                            {
                                                Object.entries(errors).map(
                                                    ([key, value]) => (
                                                        <li key={key} className="errorText">{getMessage(value.err_code)}</li>
                                                    )
                                                )
                                            }
                                        </ul>
                                    </div>
                                );
                            }
                        }
                    }
                )()
            }
        </Fragment>
    );
}
