import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GTMWTWTR02ActionType";

// API
const GET_WORKING_HOURS_POST = BASE_URL + "/api/tm_wt_get_working_time_info";
const UPDATE_WORKING_HOURS_POST = BASE_URL + "/api/tm_wt_update_working_time_simplicity_info";

// ********************Actionの作成 Start ********************

/**
 * 作業時間管理取得リクエスト
 */
export const GetWorkingHoursRequest = () => {
    return {
        type: actionTypes.GET_WORKING_HOURS_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const GetWorkingHoursSuccess = (data) => {
    return {
        type: actionTypes.GET_WORKING_HOURS_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const GetWorkingHoursFailure = (err) => {
    return {
        type: actionTypes.GET_WORKING_HOURS_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const GetWorkingHoursReset = () => {
    return {
        type: actionTypes.GET_WORKING_HOURS_RESET,
    };
};

/**
 * 作業時間管理更新リクエスト
 */
export const UpdateWorkingHoursRequest = () => {
    return {
        type: actionTypes.UPDATE_WORKING_HOURS_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateWorkingHoursSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_WORKING_HOURS_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateWorkingHoursFailure = (err) => {
    return {
        type: actionTypes.UPDATE_WORKING_HOURS_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const UpdateWorkingHoursReset = () => {
    return {
        type: actionTypes.UPDATE_WORKING_HOURS_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 作業時間管理取得処理
 * @param {*} data
 */
export const getWorkingHours = (data) => {
    return async (dispatch) => {
        dispatch(GetWorkingHoursRequest());
        ecoPostWithAuthHeader(
            GET_WORKING_HOURS_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(GetWorkingHoursSuccess(res.data))
            )
            .catch((err) => dispatch(GetWorkingHoursFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const getWorkingHoursReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetWorkingHoursReset());
    };
};

/**
 * 作業時間管理更新処理
 * @param {*} data
 */
export const updateWorkingHours = (data) => {
    return async (dispatch) => {
        dispatch(UpdateWorkingHoursRequest());
        ecoPostWithAuthHeader(
            UPDATE_WORKING_HOURS_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(UpdateWorkingHoursSuccess(res.data))
            )
            .catch((err) => dispatch(UpdateWorkingHoursFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const updateWorkingHoursReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateWorkingHoursReset());
    };
};
