import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GESSFCQA01ActionType";

// 企業アンケート照会取得API
const GET_COP_USAGE_RESULTS_DETAIL_POST = BASE_URL + "/api/es_sf_get_cop_usage_results_detail";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetCopUsageResultsDetailRequest = () => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_DETAIL_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetCopUsageResultsDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_DETAIL_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetCopUsageResultsDetailFailure = (err) => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_DETAIL_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetCopUsageResultsDetailReset = () => {
    return {
        type: actionTypes.GET_COP_USAGE_RESULTS_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getCopUsageResultsDetail = (data) => {
    return async (dispatch) => {
        dispatch(GetCopUsageResultsDetailRequest());
        ecoPostWithAuthHeader(GET_COP_USAGE_RESULTS_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(GetCopUsageResultsDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetCopUsageResultsDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getCopUsageResultsDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetCopUsageResultsDetailReset());
    };
};
