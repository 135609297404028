import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { createRecruitmentCase, createRecruitmentCaseReset } from "../MmGRSMMRMR01/GRSMMRMR01Action";
import { MessageMap } from "../MmGRSMMRMR01/GRSMMRMR01Message";
import { isBlank } from "../../Components/Tools/StringTools";
import { getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 求人案件登録（サポートサービス）
 */
class GRSMMRMR07 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            recruitmentCaseInfo: this.props.recruitmentCaseInfo,
            recruitmentCaseDetail: this.props.recruitmentCaseDetail
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { recruitmentCaseDetail } = this.state;
        this.setState({
            insurance_flg: recruitmentCaseDetail.insurance_flg,
            immigration_service_flg: recruitmentCaseDetail.immigration_service_flg,
            move_service_flg: recruitmentCaseDetail.move_service_flg,
            education_service_before_flg: recruitmentCaseDetail.education_service_before_flg,
            education_service_after_flg: recruitmentCaseDetail.education_service_after_flg
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '2' && recruitmentCaseDetail.sys_date < recruitmentCaseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });
        window.scrollTo(0, 0);
    }

    /**
     * 保険加入の処理
     * @param {*} event
     */
    handleInsuranceChanged = (event) => {
        const { name, checked } = event.target;
        let chk = checked ? '1' : '0';
        this.setState({
            [name]: chk
        }, () => {
            this.editItemHandler(name, chk);
        });
    }

    /**
     * サービスの処理
     * @param {*} event
     */
    handleChanged = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            recruitmentCaseDetail: {
                ...prevState.recruitmentCaseDetail,
                [name]: value
            }
        }));
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createRecruitmentCaseReset();
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createRecruitmentCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
        if (isBlank(recruitmentCaseDetailData.case_id)) {
            if (this.props.data && this.props.data.case_id != null) {
                recruitmentCaseDetailData.case_id = this.props.data.case_id;
            }
        }
        recruitmentCaseDetailData.temp_save = '1';
        this.setState({
            recruitmentCaseDetail: recruitmentCaseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.recruitmentCaseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createRecruitmentCase(reqData);
            }
        });
    }

    /**
     * 登録
     */
    saveDetailIntern = () => {
        this.props.createRecruitmentCaseReset();
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let dic = this.state.recruitmentCaseDetail;
        if (isBlank(dic['case_id'])) {
            if (this.props.data && this.props.data.case_id != null) {
                dic['case_id'] = this.props.data.case_id;
            }
        }
        dic['temp_save'] = '2';
        this.setState({
            recruitmentCaseDetail: dic
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.recruitmentCaseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createRecruitmentCase(reqData);
            }
        });
    }


    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    render() {
        const { recruitmentCaseDetail, recruitmentCaseInfo, isDisabled } = this.state;
        if (this.props.err_type === '200' && this.state.recruitmentCaseDetail['temp_save'] !== '1') {
            if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                const deleteList = this.state.imageFileNameList;
                this.setState({
                    imageFileNameList: null
                });
                this.props.deleteImageFiles(deleteList);
            }
            this.skipToNext("GRSMMRMR08", recruitmentCaseInfo, recruitmentCaseDetail);
        }
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && recruitmentCaseDetail.version_no != this.props.data.version_no) {
                let recruitmentCaseDetailData = recruitmentCaseDetail;
                recruitmentCaseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    recruitmentCaseDetail: recruitmentCaseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">求人案件管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>求人案件登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">求人案件登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_type_upload} err_detail={this.props.err_detail_upload} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR01", recruitmentCaseInfo, recruitmentCaseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR02", recruitmentCaseInfo, recruitmentCaseDetail)}>会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR03", recruitmentCaseInfo, recruitmentCaseDetail)}>条件・勤務形態</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR04", recruitmentCaseInfo, recruitmentCaseDetail)}>待遇</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR05", recruitmentCaseInfo, recruitmentCaseDetail)}>施設紹介</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR06", recruitmentCaseInfo, recruitmentCaseDetail)}>周辺情報</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMRMR06", recruitmentCaseInfo, recruitmentCaseDetail)} tabIndex="8">戻る</button>
                                        <button type="button" className="el_btn" onClick={() => this.saveDetailIntern()} tabIndex="7">登録する</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="el_badge el_badge__register hp_mr5">登録する</span>
                                            ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。
                                        </p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>保険加入と周辺サービス選択</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />保険加入
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="insurance_flg" checked={this.state.insurance_flg === '1'} onChange={this.handleInsuranceChanged} tabIndex="1" />
                                                                <span className="el_checkbox_txt">加入する</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />入出国手続きサービス
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="immigration_service_flg" value={1} checked={this.state.immigration_service_flg === '1'}
                                                                onChange={this.handleChanged} tabIndex="2" />
                                                            <span className="hp_mr20">申し込む</span>
                                                        </div>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="immigration_service_flg" value={0} checked={this.state.immigration_service_flg === '0'}
                                                                onChange={this.handleChanged} tabIndex="3" />
                                                            <span className="hp_mr20">申し込まない</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />渡航移動パック販売サービス
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="move_service_flg" value={1} checked={this.state.move_service_flg === '1'}
                                                                onChange={this.handleChanged} tabIndex="4" />
                                                            <span className="hp_mr20">申し込む</span>
                                                        </div>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="move_service_flg" value={0} checked={this.state.move_service_flg === '0'}
                                                                onChange={this.handleChanged} tabIndex="5" />
                                                            <span className="hp_mr20">申し込まない</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />教育支援サービス<br />（入国前）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="education_service_before_flg" value={1} checked={this.state.education_service_before_flg === '1'}
                                                                onChange={this.handleChanged} tabIndex="6" />
                                                            <span className="hp_mr20">申し込む</span>
                                                        </div>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="education_service_before_flg" value={0} checked={this.state.education_service_before_flg === '0'}
                                                                onChange={this.handleChanged} tabIndex="7" />
                                                            <span className="hp_mr20">申し込まない</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />教育支援サービス<br />（入国後研修）
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="education_service_after_flg" value={1} checked={this.state.education_service_after_flg === '1'}
                                                                onChange={this.handleChanged} tabIndex="8" />
                                                            <span className="hp_mr20">申し込む</span>
                                                        </div>
                                                        <div className="bl_formUnit hp_mb15">
                                                            <input type="radio" name="education_service_after_flg" value={0} checked={this.state.education_service_after_flg === '0'}
                                                                onChange={this.handleChanged} tabIndex="9" />
                                                            <span className="hp_mr20">申し込まない</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex="5">途中保存</button>
                                        <button type="button" className="el_btn" onClick={() => this.saveDetailIntern()} tabIndex="6">登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createRecruitmentCase.isFetching,
        data: state.createRecruitmentCase.recruitmentCaseData,
        isError: state.createRecruitmentCase.isError,
        err_type: state.createRecruitmentCase.err_type,
        err_detail: state.createRecruitmentCase.err_detail,
        err_type_upload: state.uploadInfo.err_type,
        err_detail_upload: state.uploadInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createRecruitmentCase(arg) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCase(arg));
        },
        createRecruitmentCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMRMR07);

