import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GIPPMPMF01ActionType';

// 進捗管理初期取得API
const GET_PROGRESS_MANAGEMENT_INFO_POST = BASE_URL + "/api/ip_pm_get_progress_management_info";

// 進捗管理登録API
const SAVE_PROGRESS_MANAGEMENT_INFO_POST = BASE_URL + "/api/ip_pm_update_progress_management_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetProgressManagementRequest = () => {
    return {
        type: actionTypes.GET_PROGRESS_MANAGEMENT_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetProgressManagementSuccess = (data) => {
    return {
        type: actionTypes.GET_PROGRESS_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetProgressManagementFailure = (err) => {
    return {
        type: actionTypes.GET_PROGRESS_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetProgressManagementReset = () => {
    return {
        type: actionTypes.GET_PROGRESS_MANAGEMENT_RESET
    };
};

/**
 * 登録リクエスト
 */
export const SaveProgressManagementRequest = () => {
    return {
        type: actionTypes.SAVE_PROGRESS_MANAGEMENT_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const SaveProgressManagementSuccess = (data) => {
    return {
        type: actionTypes.SAVE_PROGRESS_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const SaveProgressManagementFailure = (err) => {
    return {
        type: actionTypes.SAVE_PROGRESS_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const SaveProgressManagementReset = () => {
    return {
        type: actionTypes.SAVE_PROGRESS_MANAGEMENT_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getProgressManagementInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetProgressManagementRequest());
        ecoPostWithAuthHeader(GET_PROGRESS_MANAGEMENT_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetProgressManagementSuccess(res.data))
        ).catch(
            err => dispatch(GetProgressManagementFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const saveProgressManagementInfo = (data) => {
    return async (dispatch) => {
        dispatch(SaveProgressManagementRequest());
        ecoPostWithAuthHeader(SAVE_PROGRESS_MANAGEMENT_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(SaveProgressManagementSuccess(res.data))
        ).catch(
            err => dispatch(SaveProgressManagementFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getProgressManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetProgressManagementReset());
    };
};

/**
 * 画面リセットAction
 */
export const saveProgressManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveProgressManagementReset());
    };
};