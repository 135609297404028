import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { getChangeDateText } from "../../Components/Tools/StringTools";

/**
 * オープンソースライセンス
 */
class GAFMCSNF03 extends BaseUIComponent {

    constructor(props) {
        super(props);
    }

    render() {
        // オープンソースライセンス画面に表示する内容 定義
        const ossList = [
            { name: 'boto3', license: 'Apache-2.0', url: 'https://github.com/boto/boto3' },
            { name: 'openpyxl', license: 'MIT/Expat', url: 'https://openpyxl.readthedocs.io/en/stable/' },
            { name: 'sqlalchemy', license: 'MIT', url: 'https://www.sqlalchemy.org/download.html#license' },
            { name: 'requests ', license: 'Apache-2.0', url: 'https://pypi.org/project/requests/' },
            { name: '@testing-library/jest-dom', license: 'MIT', url: 'https://github.com/testing-library/jest-dom' },
            { name: '@testing-library/react', license: 'MIT', url: 'https://github.com/testing-library/react-testing-library' },
            { name: '@testing-library/user-event', license: 'MIT', url: 'https://github.com/testing-library/user-event' },
            { name: '@thumbtack/thumbprint-react', license: 'Apache-2.0', url: 'https://www.npmjs.com/package/@thumbtack/thumbprint-react' },
            { name: 'amazon-cognito-identity-js', license: 'Apache-2.0', url: 'https://github.com/amazon-archives/amazon-cognito-identity-js' },
            { name: 'amazon-cognito-identity-js-node', license: 'Apache-2.0', url: 'https://github.com/kevinresol/amazon-cognito-identity-js-node' },
            { name: 'aws-sdk', license: 'Apache-2.0', url: 'https://aws.amazon.com/jp/sdk-for-javascript/' },
            { name: 'axios', license: 'MIT', url: 'https://github.com/axios/axios' },
            { name: 'env-cmd', license: 'MIT', url: 'https://www.npmjs.com/package/env-cmd' },
            { name: 'file-saver', license: 'MIT', url: 'https://www.npmjs.com/package/file-saver' },
            { name: 'jsonwebtoken', license: 'MIT', url: 'https://jwt.io/' },
            { name: 'jwk-to-pem', license: 'Apache-2.0', url: 'https://www.npmjs.com/package/jwk-to-pem' },
            { name: 'moment', license: 'MIT', url: 'https://momentjs.com/' },
            { name: 'npm-run-all', license: 'MIT', url: 'https://github.com/mysticatea/npm-run-all' },
            { name: 'react', license: 'MIT', url: 'https://ja.reactjs.org/' },
            { name: 'react-app-polyfill', license: 'MIT', url: 'https://www.npmjs.com/package/react-app-polyfill' },
            { name: 'react-dom', license: 'MIT', url: 'https://ja.reactjs.org/docs/react-dom.html' },
            { name: 'react-dropdown', license: 'MIT', url: 'https://www.npmjs.com/package/react-dropdown' },
            { name: 'react-redux', license: 'MIT', url: 'https://react-redux.js.org/' },
            { name: 'react-router-dom', license: 'MIT', url: 'https://reactrouter.com/web/guides/quick-start' },
            { name: 'react-scripts', license: 'MIT', url: 'https://www.npmjs.com/package/react-scripts' },
            { name: 'react-select', license: 'MIT', url: 'https://react-select.com/home' },
            { name: 'redux', license: 'MIT', url: 'https://redux.js.org/' },
            { name: 'redux-logger', license: 'MIT', url: 'https://github.com/LogRocket/redux-logger' },
            { name: 'redux-thunk', license: 'MIT', url: 'https://github.com/reduxjs/redux-thunk' },
            { name: 'Recharts', license: 'MIT', url: 'https://recharts.org/en-US/' },
            { name: 'react-youtube', license: 'MIT', url: 'https://github.com/tjallingt/react-youtube' }
        ];

        return (
            <div id="bl_modalID" class="bl_modal">
                <div class="bl_modal-wrap">
                    <div class="bl_modal-bg"></div>
                    <div class="bl_modal-box bl_modal-box_osl">
                        <div class="bl_modal_inner">
                            <div class="bl_modalTop">
                                <p>ソフトウェア情報</p>
                            </div>
                            <div class="bl_modalBtm">
                                <div class="bl_modalTxt">
                                    <p>本システムは、以下のソフトウェアを使用しています。</p>
                                </div>
                                <div class='sticky_table_wrapper'>
                                    <table class='sticky_table mcTable_align'>
                                        <thead>
                                            <tr><th>ソフトウェア名称</th><th>ライセンス</th></tr>
                                        </thead>
                                        <tbody>
                                            {(() => {
                                                return (ossList.map((item, index) => (
                                                    <tr>
                                                        <td>{item.name}</td>
                                                        <td><a href={item.url} target="_blank" class="el_link">{item.license}</a></td>
                                                    </tr>
                                                )))
                                            })()}
                                        </tbody>
                                    </table>
                                </div>
                                <div class="bl_horizBtnUnit">
                                    <button type="button" class="el_blueBtn" onClick={() => this.props.onClose()}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default GAFMCSNF03;
