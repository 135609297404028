import React from 'react';
import IcTemplate from "./template";

function IcContainer() {
    return (
        <section className="App">
            <IcTemplate />
        </section>
    );
}

export default IcContainer;