import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GAFMCSNF01ActionType';

/**
 * Home画面一覧データ取得
 */
export const GetNoticeListRequest = () => {
    return {
        type: actionTypes.GET_NOTICE_LIST_REQUEST
    };
};

/**
 * Home画面一覧データ取得成功
 */
export const GetNoticeListSuccess = (data) => {
    return {
        type: actionTypes.GET_NOTICE_LIST_SUCCESS,
        data
    };
};

/**
 * Home画面一覧データ取得失敗
 */
export const GetNoticeListFailure = (err) => {
    return {
        type: actionTypes.GET_NOTICE_LIST_FAILURE,
        err
    };
};

/**
 * Home画面一覧データ取得RESET
 */
export const GetNoticeListReset = (err) => {
    return {
        type: actionTypes.GET_NOTICE_LIST_RESET,
        err
    };
};

/**
 * Home画面お知らせ詳細データ取得
 */
export const GetNoticeDetailRequest = () => {
    return {
        type: actionTypes.GET_NOTICE_DETAIL_REQUEST
    };
};

/**
 * Home画面お知らせ詳細データ取得成功
 */
export const GetNoticeDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_NOTICE_DETAIL_SUCCESS,
        data
    };
};

/**
 * Home画面お知らせ詳細データ取得失敗
 */
export const GetNoticeDetailFailure = (err) => {
    return {
        type: actionTypes.GET_NOTICE_DETAIL_FAILURE,
        err
    };
};

/**
 * Home画面お知らせ詳細データRESET
 */
export const GetNoticeDetailReset = () => {
    return {
        type: actionTypes.GET_NOTICE_DETAIL_RESET
    };
};

/**
 * Home画面TODOデータ更新
 */
export const UpdateTodoDetailRequest = () => {
    return {
        type: actionTypes.UPDATE_TODO_DETAIL_REQUEST
    };
};

/**
 * Home画面TODOデータ更新成功
 */
export const UpdateTodoDetailSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_TODO_DETAIL_SUCCESS,
        data
    };
};

/**
 * Home画面TODOデータ更新失敗
 */
export const UpdateTodoDetailFailure = (err) => {
    return {
        type: actionTypes.UPDATE_TODO_DETAIL_FAILURE,
        err
    };
};

/**
 * Home画面TODOデータRESET
 */
export const UpdateTodoDetailReset = () => {
    return {
        type: actionTypes.UPDATE_TODO_DETAIL_RESET
    };
};

// お知らせ一覧取得APIURL定義
const GET_NOTICE_LIST_POST = BASE_URL + "/api/af_mc_get_notice_list";
// お知らせ詳細取得APIURL定義
const GET_NOTICE_INFO_POST = BASE_URL + "/api/af_mc_get_notice_info";
// TODOテーブル更新APIURL定義
const UPDATE_TODO_INFO_POST = BASE_URL + "/api/af_mc_update_todo_info";

/**
 * Home画面TODOリストクリックによってTODOテーブル更新Action
 */
export const updateTodoTable = (query) => {
    return async (dispatch) => {
        dispatch(UpdateTodoDetailRequest());
        ecoPostWithAuthHeader(UPDATE_TODO_INFO_POST, JSON.stringify(query)).then(
            res => dispatch(UpdateTodoDetailSuccess(res.data))
        ).catch(
            err => dispatch(UpdateTodoDetailFailure(err))
        );
    };
};

/**
 * Home画面一覧データ取得Action
 */
export const getNoticeList = (query) => {
    return async (dispatch) => {
        dispatch(GetNoticeListRequest());
        ecoPostWithAuthHeader(GET_NOTICE_LIST_POST, JSON.stringify(query)).then(
            res => dispatch(GetNoticeListSuccess(res.data))
        ).catch(
            err => dispatch(GetNoticeListFailure(err))
        );
    };
};

/**
 * Home画面一覧データRESETAction
 */
export const getNoticeListReset = (query) => {
    return async (dispatch) => {
        dispatch(GetNoticeListReset());
    };
};

/**
 * Home画面お知らせ詳細データ取得Action
 */
export const getNoticeDetail = (noticeId) => {
    return async (dispatch) => {
        let query = { info_seq: noticeId + '' };
        dispatch(GetNoticeDetailRequest());
        ecoPostWithAuthHeader(GET_NOTICE_INFO_POST, JSON.stringify(query)).then(
            res => dispatch(GetNoticeDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetNoticeDetailFailure(err))
        );
    };
};

/**
 * Home画面お知らせ詳細データクリアAction
 */
export const getNoticeDetailReset = () => {
    return async (dispatch) => {
        dispatch(GetNoticeDetailReset());
    };
};
