/**
 * Action Typesを定義
 */
const GTMWTWTR01ActionType = {
    SAVE_WORKINGTIME_REQUEST: 'SAVE_WORKINGTIME_REQUEST',
    SAVE_WORKINGTIME_SUCCESS: 'SAVE_WORKINGTIME_SUCCESS',
    SAVE_WORKINGTIME_FAILURE: 'SAVE_WORKINGTIME_FAILURE',
    SAVE_WORKINGTIME_RESET: 'SAVE_WORKINGTIME_RESET'
};

export default GTMWTWTR01ActionType;