/**
 * Action Typesを定義
 */
const GRSMMMLI01ActionType = {
    GET_CASE_LIST_REQUEST: 'GET_CASE_LIST_REQUEST',         // 案件一覧検索定義
    GET_CASE_LIST_SUCCESS: 'GET_CASE_LIST_SUCCESS',         // 案件一覧検索成功定義
    GET_CASE_LIST_FAILURE: 'GET_CASE_LIST_FAILURE',         // 案件一覧検索失敗定義
    GET_CASE_LIST_RESET: 'GET_CASE_LIST_RESET',             // 案件一覧検索リセット定義
    CASE_DETAIL_REQUEST: 'CASE_DETAIL_REQUEST',             // 案件詳細取得定義
    CASE_DETAIL_SUCCESS: 'CASE_DETAIL_SUCCESS',             // 案件詳細取得成功定義
    CASE_DETAIL_FAILURE: 'CASE_DETAIL_FAILURE',             // 案件詳細取得失敗定義
    CASE_DETAIL_RESET: 'CASE_DETAIL_RESET',                 // 案件詳細取得リセット定義
    CASE_DETAIL_COPY_REQUEST: 'CASE_DETAIL_COPY_REQUEST',   // 案件コピー作成定義
    CASE_DETAIL_COPY_SUCCESS: 'CASE_DETAIL_COPY_SUCCESS',   // 案件コピー作成成功定義
    CASE_DETAIL_COPY_FAILURE: 'CASE_DETAIL_COPY_FAILURE',   // 案件コピー作成失敗定義
    CASE_DETAIL_COPY_RESET: 'CASE_DETAIL_COPY_RESET',       // 案件コピー作成リセット定義
    CASE_DELETE_REQUEST: 'CASE_DELETE_REQUEST',             // 案件削除定義
    CASE_DELETE_SUCCESS: 'CASE_DELETE_SUCCESS',             // 案件削除成功定義
    CASE_DELETE_FAILURE: 'CASE_DELETE_FAILURE',             // 案件削除失敗定義
    RECRUITMENT_DETAIL_REQUEST: 'RECRUITMENT_DETAIL_REQUEST',             // 求人案件詳細取得定義
    RECRUITMENT_DETAIL_SUCCESS: 'RECRUITMENT_DETAIL_SUCCESS',             // 求人案件詳細取得成功定義
    RECRUITMENT_DETAIL_FAILURE: 'RECRUITMENT_DETAIL_FAILURE',             // 求人案件詳細取得失敗定義
    RECRUITMENT_DETAIL_RESET: 'RECRUITMENT_DETAIL_RESET',                 // 求人案件詳細取得リセット定義
    RECRUITMENT_DETAIL_COPY_REQUEST: 'RECRUITMENT_DETAIL_COPY_REQUEST',   // 求人案件コピー作成定義
    RECRUITMENT_DETAIL_COPY_SUCCESS: 'RECRUITMENT_DETAIL_COPY_SUCCESS',   // 求人案件コピー作成成功定義
    RECRUITMENT_DETAIL_COPY_FAILURE: 'RECRUITMENT_DETAIL_COPY_FAILURE',   // 求人案件コピー作成失敗定義
    RECRUITMENT_DETAIL_COPY_RESET: 'RECRUITMENT_DETAIL_COPY_RESET',       // 求人案件コピー作成リセット定義
    SPECIFIC_DETAIL_REQUEST: 'SPECIFIC_DETAIL_REQUEST',             // 特定技能求人案件詳細取得定義
    SPECIFIC_DETAIL_SUCCESS: 'SPECIFIC_DETAIL_SUCCESS',             // 特定技能求人案件詳細取得成功定義
    SPECIFIC_DETAIL_FAILURE: 'SPECIFIC_DETAIL_FAILURE',             // 特定技能求人案件詳細取得失敗定義
    SPECIFIC_DETAIL_RESET: 'SPECIFIC_DETAIL_RESET',                 // 特定技能求人案件詳細取得リセット定義
    SPECIFIC_DETAIL_COPY_REQUEST: 'SPECIFIC_DETAIL_COPY_REQUEST',   // 特定技能求人案件コピー作成定義
    SPECIFIC_DETAIL_COPY_SUCCESS: 'SPECIFIC_DETAIL_COPY_SUCCESS',   // 特定技能求人案件コピー作成成功定義
    SPECIFIC_DETAIL_COPY_FAILURE: 'SPECIFIC_DETAIL_COPY_FAILURE',   // 特定技能求人案件コピー作成失敗定義
    SPECIFIC_DETAIL_COPY_RESET: 'SPECIFIC_DETAIL_COPY_RESET',       // 特定技能求人案件コピー作成リセット定義
};

export default GRSMMMLI01ActionType;
