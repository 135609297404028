import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GCMOMCUM01 from "../Cm/OmGCMOMCUM01/GCMOMCUM01";
import GEFCRRRI01 from "./CrGEFCRRRI01/GEFCRRRI01";

/**
 * 教育履歴テンプレート
 */
class IpTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToView = (dispId, detailData) => {
        const beforeDispId = this.state.display;
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        this.setState({
            display: dispId,
            detailData: detailData
        }, () => {

        });
    }

    render() {
        let displayParts;
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
        if (this.state.display === "GAFMCMCFCR01") {
            // ユーザ一覧(履修履歴詳細)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFCR01" key={this.state.beforeDispId} />;
        } else if (this.state.display === "GEFCRRRI01") {
            displayParts = <GEFCRRRI01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} />;
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {

    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {

    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IpTemplate)
);

