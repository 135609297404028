import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage, getCodeValueLabelList, getCodeName } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { isBlank, getDateTextYM, getChangeDateText } from "../../Components/Tools/StringTools";
import TextInput from "../../Components/Atoms/TextInput";
import ScoreStar from "../../Components/Atoms/ScoreStar";
import "../../Css/Common.css";
import { getEvaluationTargetsList, GetEvaluationTargetsListReset } from "./GESSFELI01Action";
import { MessageMap } from "./GESSFELI01Message";

/**
 * 評価対象一覧
 */
class GESSFELI01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            err_msg: [],
            evaluationTargetsList: [],
            getEvaluationTargetsListInfo: {
                search_organization_type: '',
                search_organization_name: '',
                search_accept_period_start: '',
                search_accept_period_end: '',
                search_score_date_start: '',
                search_score_date_end: '',
                search_score: '',
                order_by: 'organization_name_asc',
            },
            scoreDisabled: false,
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.handleSearch();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    };

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        const { getEvaluationTargetsListInfo } = this.state
        getEvaluationTargetsListInfo[name] = value;
        this.setState({
            getEvaluationTargetsListInfo: getEvaluationTargetsListInfo
        });
    };

    /**
     * 並べ替え変更
     */
    handleOrderByChange = (event) => {
        const { name, value } = event.target;
        const { getEvaluationTargetsListInfo } = this.state
        getEvaluationTargetsListInfo[name] = value;
        this.setState({
            getEvaluationTargetsListInfo: getEvaluationTargetsListInfo
        }, () => {
            this.handleSearch();
        });
    };

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        const { getEvaluationTargetsListInfo } = this.state
        getEvaluationTargetsListInfo[name] = value;
        this.setState({
            getEvaluationTargetsListInfo: getEvaluationTargetsListInfo
        });
    }

    /**
     * 未評価の処理
     * @param {*} event
     */
    handleScoreCheckChanged = (event) => {
        const { name, checked } = event.target;
        let value = checked ? '1' : '0';
        const { getEvaluationTargetsListInfo, scoreDisabled } = this.state
        getEvaluationTargetsListInfo[name] = value;
        if (checked) {
            getEvaluationTargetsListInfo.search_score_date_start = ' ';
            getEvaluationTargetsListInfo.search_score_date_end = ' ';
        }
        this.setState({
            getEvaluationTargetsListInfo: getEvaluationTargetsListInfo,
            scoreDisabled: checked
        });
    }

    /**
     * 検索処理
     */
    handleSearch = () => {
        window.scrollTo(0, 0);
        this.searchReset();

        this.setState({ isSearch: true, err_msg: [] }, () => {
            this.setErrorCallback(this.searchReset);
            const { getEvaluationTargetsListInfo } = this.state
            let reqData = JSON.parse(JSON.stringify(getEvaluationTargetsListInfo));
            this.props.getEvaluationTargetsList(reqData);
        });
    };

    /**
     * 検索一覧リセットする
     */
    searchReset = () => {
        this.props.getEvaluationTargetsListReset();
    };

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        let err_msg = [];
        let evaluationTargetsList = [];

        if (detailData.overflowflg === '1' && this.state.err_msg.length == 0) {
            err_msg = [getMessage('RS-0017').replace(/%1/g, '100')];
        }
        if (detailData && detailData.list && detailData.list.length > 0) {
            evaluationTargetsList = detailData.list;
        }
        this.setState({
            err_msg: err_msg,
            evaluationTargetsList: evaluationTargetsList
        });
    };


    render() {
        if (this.state.isSearch && this.props.errType === '200') {
            this.setState({ isSearch: false }, () => {
                this.setViewStatus(this.props.data);
            });
        }

        const { getEvaluationTargetsListInfo, evaluationTargetsList, scoreDisabled } = this.state
        const organizationType = getCodeValueLabelList('RSC0030').filter(v => v.value == 'COP' || v.value == 'UNI' || v.value == 'OKR');
        const orderType = [
            { label: '組織名（昇順）', value: 'organization_name_asc' },
            { label: '組織名（降順）', value: 'organization_name_desc' },
            { label: '直近評価実施日（昇順）', value: 'score_date_asc' },
            { label: '直近評価実施日（降順）', value: 'score_date_desc' },
        ];
        const scoreCssDisabled = scoreDisabled ? ' is_disabled' : '';

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">評価対象一覧</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => { this.getMenu().gotoHome() }}>ホーム</a>
                                </li>
                                <li>評価対象一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.errType} err_detail={this.props.errDetail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>組織種別</th>
                                                    <td>
                                                        <select className="el_select" name="search_organization_type" value={getEvaluationTargetsListInfo.search_organization_type} onChange={this.handleChange} tabIndex="1">
                                                            <option value="" selected></option>
                                                            {
                                                                organizationType && organizationType.map(item => (
                                                                    <option key={item.value} value={item.value} selected={getEvaluationTargetsListInfo.search_organization_type === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>組織名</th>
                                                    <td>
                                                        <TextInput type="text" name="search_organization_name" maxlength="60" value={getEvaluationTargetsListInfo.search_organization_name} className="el_input el_input__max" onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件終了月</th>
                                                    <td>
                                                        <TextInput className="el_input el_input__datepicker" name="search_accept_period_start" type="month" value={getEvaluationTargetsListInfo.search_accept_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                        <span className="hp_ml10 hp_mr10">〜</span>
                                                        <TextInput className="el_input el_input__datepicker" name="search_accept_period_end" type="month" value={getEvaluationTargetsListInfo.search_accept_period_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>直近評価実施日</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="search_score" checked={getEvaluationTargetsListInfo.search_score === '1'} onChange={this.handleScoreCheckChanged} tabIndex="5" /><span className="el_checkbox_txt">未評価</span>
                                                            </label>
                                                        </div>
                                                        <TextInput placeholder="年/月/日" className={"el_input el_input__datepicker".concat(scoreCssDisabled)} name="search_score_date_start" type="date" setValue={getEvaluationTargetsListInfo.search_score_date_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} disabled={this.state.scoreDisabled} tabIndex="6" />
                                                        <span className="hp_ml10 hp_mr10">〜</span>
                                                        <TextInput placeholder="年/月/日" className={"el_input el_input__datepicker".concat(scoreCssDisabled)} name="search_score_date_end" type="date" setValue={getEvaluationTargetsListInfo.search_score_date_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} disabled={this.state.scoreDisabled} tabIndex="7" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.handleSearch()} tabIndex="8">検索</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>評価組織一覧​</span></h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="bl_sortBoxUnit">
                                            <dl className="bl_sortBox bl_sortBox__r">
                                                <dt className="bl_sortBox_ttl">並べ替え</dt>
                                                <dd>
                                                    <select className="el_select el_select__auto" name="order_by" value={getEvaluationTargetsListInfo.order_by} onChange={this.handleOrderByChange} tabIndex="9">
                                                        {
                                                            orderType.map(item => (
                                                                <option key={item.value} value={item.value} selected={getEvaluationTargetsListInfo.order_by === item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </dd>
                                            </dl>
                                        </div>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>組織種別</th><th>組織名</th><th>案件終了月</th><th>直近評価実施日</th><th>直近評価結果</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (evaluationTargetsList !== undefined && evaluationTargetsList.length > 0) {
                                                            return (
                                                                evaluationTargetsList.map((item, index) => (
                                                                    <tr>
                                                                        <td>{getCodeName('RSC0030', item.org_type)}</td>
                                                                        <td>
                                                                            <a href="#" className="el_link" onClick={() => this.props.changeDisp('GESSFCES01', item)} tabIndex="11">{item.organization_name}</a>
                                                                        </td>
                                                                        <td className="hp_tar">{getDateTextYM(item.max_accept_end)}</td>
                                                                        <td className="hp_tar">{!isBlank(item.score_date) ? getChangeDateText(item.score_date) : ''}</td>
                                                                        <td class="hp_tac">
                                                                            {item.score
                                                                                ? <ScoreStar score={item.score} />
                                                                                : "未評価"
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getEvaluationTargetsList.isFetching,
        data: state.getEvaluationTargetsList.data,
        error: state.getEvaluationTargetsList.isError,
        errType: state.getEvaluationTargetsList.err_type,
        errDetail: state.getEvaluationTargetsList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getEvaluationTargetsList(arg) {
            // 評価対象一覧取得
            dispatch(getEvaluationTargetsList(arg));
        },
        getEvaluationTargetsListReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(GetEvaluationTargetsListReset());
        }
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GESSFELI01);
