import actionTypes from './GCMOMCRU01ActionType';

/**
 * 組織情報(プロフィール)企業情報・企業施設情報
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 組織情報(プロフィール)企業情報・企業施設情報取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getCompanyAndFacilityInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_AND_FACILITY_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_COMPANY_AND_FACILITY_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_COMPANY_AND_FACILITY_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_COMPANY_AND_FACILITY_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 組織情報(プロフィール)企業情報・企業施設情報を更新するReducer
 * @param {*} state
 * @param {*} action
 */
export const updateCompanyAndFacilityInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.UPDATE_COMPANY_AND_FACILITY_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
