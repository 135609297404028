import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCodeName } from "../../Constants/CommonConstants";
import { getNumberText } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";
import { MessageMap } from "./GESSFCQA01Message";

class GESSFCQA01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    render() {

        const { company_case_data_info, candidte_count, int_worker_enquet_data_dict, int_judger_enquet_data_dict, accident_data_dict, intern_plan_diff_list_dict, organization_id, organization_type } = this.props.detailData;

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                アンケート照会
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => { this.getMenu().gotoHome() }}>ホーム</a></li>
                                <li><a href="#!" onClick={() => { this.props.changeDisp("GESSFELI01") }}>評価対象一覧</a></li>
                                <li><a href="#!" onClick={() => { this.props.changeDisp("GESSFCES01", { organization_id: organization_id, organization_type: organization_type }) }}>評価詳細</a></li>
                                <li><a href="#!" onClick={() => { this.props.changeDisp("GESSFCMA01", { organization_id: organization_id, organization_type: organization_type }) }}>利用実績一覧</a></li>
                                <li>アンケート照会</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.errType} err_detail={this.props.errDetail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <p><img src={company_case_data_info && company_case_data_info.logo} alt="" /></p>
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                <dt>企業名：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.company_name ? company_case_data_info.company_name : <br />}</dd>
                                                <dt>在留資格：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.status_of_residence ? getCodeName('RSC0004', company_case_data_info.status_of_residence) : <br />}</dd>
                                                <dt>案件名：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.case_title ? company_case_data_info.case_title : <br />}</dd>
                                                <dt>受入・就業期間：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.accept_period_start ? company_case_data_info.accept_period_start.replace(/\-/g, "/") : ""}〜{company_case_data_info && company_case_data_info.accept_period_end ? company_case_data_info.accept_period_end.replace(/\-/g, "/") : ""}</dd>
                                                <dt>職種：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.occupation ? company_case_data_info.occupation : <br />}</dd>
                                                <dt>採用：</dt>
                                                <dd>{candidte_count}名</dd>
                                                {company_case_data_info && company_case_data_info.status_of_residence == '2'
                                                    ? <dt>労働時間：</dt>
                                                    : null
                                                }
                                                {company_case_data_info && company_case_data_info.status_of_residence == '2'
                                                    ? <dd>{company_case_data_info && company_case_data_info.working_hour}時間{company_case_data_info && company_case_data_info.working_minute}分/日</dd>
                                                    : null
                                                }
                                                <dt>{company_case_data_info && company_case_data_info.status_of_residence == '2' ? '支援額' : '支給額'}：</dt>
                                                <dd>{company_case_data_info && company_case_data_info.salary}円/月</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading"><span>実習生アンケート集計</span></h2>
                                        </div>
                                        {
                                            (int_worker_enquet_data_dict !== null && int_worker_enquet_data_dict !== undefined && int_worker_enquet_data_dict.length > 0) ?
                                                <Fragment>
                                                    <p className="el_note_sm hp_tar hp_mb20">５：満足　４：やや満足　３：どちらとも言えない　２：やや不満　１：不満</p>
                                                    <div className='sticky_table_wrapper'>
                                                        <table className='sticky_table mcTable_align queViewTable_width'>
                                                            <thead>
                                                                <tr>
                                                                    <th>アンケート回答者</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_01}</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_02}</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_03}</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_04}</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_05}</th>
                                                                    <th>{int_worker_enquet_data_dict[0].enquete_item_06}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(() => {
                                                                    return (
                                                                        int_worker_enquet_data_dict.map((item, index) => (
                                                                            <tr>
                                                                                <td>{item.employment_name_kana}</td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_01}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_02}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_03}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_04}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_05}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_06}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    );
                                                                })()}
                                                                <tr>
                                                                    <th style={{ background: '#0098ce', color: '#fff', 'vertical-align': 'middle' }}>平均値</th>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_01}
                                                                    </td>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_02}
                                                                    </td>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_03}
                                                                    </td>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_04}
                                                                    </td>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_05}
                                                                    </td>
                                                                    <td className="hp_noWrap hp_tar">
                                                                        {this.props.detailData.average_score_06}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Fragment>
                                                : null
                                        }
                                    </section>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">企業アンケート集計</h2>
                                        </div>
                                        {
                                            (int_judger_enquet_data_dict !== null && int_judger_enquet_data_dict !== undefined && int_judger_enquet_data_dict.length > 0) ?
                                                <Fragment>
                                                    <p className="el_note_sm hp_tar hp_mb20">５：満足　４：やや満足　３：どちらとも言えない　２：やや不満　１：不満</p>
                                                    <div className='sticky_table_wrapper'>
                                                        <table className='sticky_table mcTable_align queViewTable_width'>
                                                            <thead>
                                                                <tr>
                                                                    <th>評価実習生名</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_01}</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_02}</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_03}</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_04}</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_05}</th>
                                                                    <th>{int_judger_enquet_data_dict[0].enquete_item_06}</th>
                                                                    <th>アクシデント</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {(() => {
                                                                    return (
                                                                        int_judger_enquet_data_dict.map((item, index) => (
                                                                            <tr>
                                                                                <td>{item.employment_name_kana}</td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_01}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_02}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_03}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_04}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_05}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.score_06}
                                                                                </td>
                                                                                <td className="hp_noWrap hp_tar">
                                                                                    {item.have_accident_flg === '1' ? '有り' : '無し'}
                                                                                </td>
                                                                            </tr>
                                                                        ))
                                                                    );
                                                                })()}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </Fragment>
                                                : null
                                        }
                                    </section>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">アクシデント</h2>
                                        </div>
                                        {(() => {
                                            if (accident_data_dict !== null && accident_data_dict !== undefined && accident_data_dict.length > 0) {
                                                return (
                                                    accident_data_dict.map((item, index) => (
                                                        <table className="bl_horizTable02 hp_mb10">
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        評価実習生名：{item.employment_name_kana}<br />
                                                                        発生事象：{item.enquete_answer_contents}<br />
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    ))
                                                );
                                            }
                                        })()}
                                    </section>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">実習計画との差異情報</h2>
                                        </div>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>計画名</th><th>実習カテゴリ</th><th>予定時間</th><th>実績時間</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (intern_plan_diff_list_dict !== null && intern_plan_diff_list_dict !== undefined && intern_plan_diff_list_dict.length > 0) {
                                                            return (
                                                                intern_plan_diff_list_dict.map((item, index) => (
                                                                    <tr>
                                                                        <td>{item.plan_name}</td>
                                                                        <td>{item.work_name}</td>
                                                                        <td className="hp_tar">
                                                                            {getNumberText(item.total_time)}時間
                                                                        </td>
                                                                        <td className="hp_tar">
                                                                            {(() => {
                                                                                const realTime = item.real_time.split(':');
                                                                                return (
                                                                                    getNumberText(realTime[0]) + '時間' + realTime[1] + '分'
                                                                                );
                                                                            })()}
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => { this.props.changeDisp("GESSFCMA01", { organization_id: organization_id, organization_type: organization_type }) }}>利用実績一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div >
                </div >
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getCopUsageResultsDetail.isFetching,
        data: state.getCopUsageResultsDetail.data,
        isError: state.getCopUsageResultsDetail.isError,
        errType: state.getCopUsageResultsDetail.err_type,
        errDetail: state.getCopUsageResultsDetail.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {

    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GESSFCQA01);