import React from "react";
import { connect } from "react-redux";
import { getCountryMasterList, getCodeList, getCodeValueLabelList, getMessage, getCognitoUserInfo } from "../../Constants/CommonConstants";
import { MessageMap } from "./GRSMMERF01Message";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { imageResize } from "../../Components/Tools/ImageTools";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import { SaveCandidate, CandidateReset } from "./GRSMMERF01Action";
import { GetCandidateDetailReset } from "../MmGRSMMAMF01/GRSMMAMF01Action";
import Minus_Icon from "../../Images/btn_minus.svg";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";

/**
 * 応募者登録画面
 */
class GRSMMERF01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
            detailData: props.detailData,
            work_history_list: []
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            candidate_id: this.state.detailData.candidate_id,                           // 応募者ID
            status_of_residence: this.state.detailData.status_of_residence,      // 在留資格
            candidate_photo: this.state.detailData.candidate_photo,                  // 応募者写真
            university_name: this.state.detailData.university_name,                  // 大学
            candidate_student_no: this.state.detailData.candidate_student_no,   // 学籍番号
            candidate_name: this.state.detailData.candidate_name,                     // 応募者氏名（ローマ字）
            candidate_name_kana: this.state.detailData.candidate_name_kana,      // 応募者氏名（カナ）
            candidate_nickname: this.state.detailData.candidate_nickname,         // 応募者氏名（ニックネーム）
            born_country_code: this.state.detailData.born_country_code,            // 国籍
            candidate_birthday: this.state.detailData.candidate_birthday,         // 生年月日
            candidate_sex: this.state.detailData.candidate_sex,                        // 性別
            candidate_japanese_level: this.state.detailData.candidate_japanese_level, // 日本語レベル
            candidate_language: this.state.detailData.candidate_language,         // 得意言語
            candidate_department: this.state.detailData.candidate_department,   // 学部・専攻
            candidate_acquired_credits: this.state.detailData.candidate_acquired_credits, // 取得済単位数
            candidate_intern_schedule_credits: this.state.detailData.candidate_intern_schedule_credits, // インターンでの取得予定単位数
            candidate_required_credits: this.state.detailData.candidate_required_credits, // 卒業までに必要な単位数
            candidate_appeal: this.state.detailData.candidate_appeal,                     // 自己アピール
            license: this.state.detailData.license,                                                // 保有資格
            work_history: this.state.detailData.work_history,   // 職歴
            work_history_list: this.state.detailData.work_history_list,   // 職歴リスト
            candidate_mail_address: this.state.detailData.candidate_mail_address,   // メールアドレス
            update_datetime: this.state.detailData.update_datetime,                         // 更新日時
            version_no: this.state.detailData.version_no,
            video_url: this.state.detailData.video_url,
            user_version_no: this.state.detailData.user_data_list[0].version_no
        });
    }

    /**
     * 在留資格変更
     * @param {*} event
     */
    handleResidenceChange = (event) => {
        this.setState({
            status_of_residence: event.target.value
        }, () => {
            this.editItemHandler("status_of_residence", this.state.status_of_residence);
        });
    };

    /**
     * 国籍変更
     * @param {*} event
     */
    handleCountriesChange = (event) => {
        this.setState({
            born_country_code: event.target.value
        }, () => {
            this.editItemHandler("born_country_code", this.state.born_country_code);
        });
    };

    /**
     * 性別変更
     * @param {*} event
     */
    handleGenderChange = (event) => {
        this.setState({
            candidate_sex: event.target.value
        }, () => {
            this.editItemHandler("candidate_sex", this.state.candidate_sex);
        });
    };

    /**
     * 日本語レベル変更
     * @param {*} event
     */
    handleJapaneseLevelChange = (event) => {
        this.setState({
            candidate_japanese_level: event.target.value
        }, () => {
            this.editItemHandler("candidate_japanese_level", this.state.candidate_japanese_level);
        });
    };

    /**
     * 画像を変更
     */
    handleImageUpload = (event) => {
        imageResize(event.target, (data) => {
            this.setState({ candidate_photo: data });
        });
    }

    /**
     * 画像を削除
     */
    handleImageDelete = () => {
        this.setState({ candidate_photo: null });
    };

    /**
     * 応募者情報登録
     */
    candidateInfoSave = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.getCandidateReset);
        this.props.saveCandidateInfo(this.state);
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        this.props.getCandidateReset();
        this.props.getCandidateDetailReset();
        return this.props.changeDisp(changeTo);
    }

    /**
     * 職歴を追加する
     */
    addWorkHistory = () => {
        let plan = { "work_history_ym_from": "", "work_history_ym_to": "", "work_history": "" };
        this.state.work_history_list.push(plan);
        this.setState({
            work_history_list: this.state.work_history_list
        });
    }

    /**
     * 職歴を削除する
     * @param {*} index
     */
    deleteWorkHistory = (index) => {
        let array = [...this.state.work_history_list];
        array.splice(index, 1);
        this.setState({
            work_history_list: array
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            work_history_list: this.state.work_history_list.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler('work_history_list', this.state.work_history_list);
        });
    }

    render() {
        const { work_history_list } = this.state;
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.version_no) && !isBlank(this.props.data.user_version_no) && this.state.version_no !== this.props.data.version_no && this.state.user_version_no !== this.props.data.user_version_no) {
                this.setState({
                    version_no: this.props.data.version_no,
                    user_version_no: this.props.data.user_version_no
                });
                this.showModalDialog(0, getMessage('RS-0036'), () => { });
            }
        }

        // 表示、非表示項目
        const showOrHide = (this.state.status_of_residence === '0' || this.state.status_of_residence === '1') ? 'none' : 'table-row';

        // 在留資格
        const statusOfResidencebef = getCodeValueLabelList('RSC0004');
        const statusOfResidence = [];
        // ログインユーザの組織タイプ="UNI"または"INT"の場合、「インターン」
        if (getCognitoUserInfo()["org_type"] === 'UNI' || getCognitoUserInfo()["org_type"] === 'INT') {
            statusOfResidencebef.forEach(function (item) {
                if (item['value'] == '2') {
                    statusOfResidence.push(item);
                }
            })
        }
        // ログインユーザの組織タイプ="OKR"の場合、「技能実習」と「特定技能」
        else if (getCognitoUserInfo()["org_type"] === 'OKR') {
            statusOfResidencebef.forEach(function (item) {
                if (item['value'] == '0' || item['value'] == '1') {
                    statusOfResidence.push(item);
                }
            })
        }
        // ログインユーザの組織タイプ="GNO"の場合、「技能実習」
        else if (getCognitoUserInfo()["org_type"] === 'GNO') {
            statusOfResidencebef.forEach(function (item) {
                if (item['value'] == '0') {
                    statusOfResidence.push(item);
                }
            })
        }
        // ログインユーザの組織タイプ="TOK"の場合、「特定技能」
        else if (getCognitoUserInfo()["org_type"] === 'TOK') {
            statusOfResidencebef.forEach(function (item) {
                if (item['value'] == '1') {
                    statusOfResidence.push(item);
                }
            })
        }
        else {
            statusOfResidencebef.forEach(function (item) {
                statusOfResidence.push(item);
            })
        }

        // 性別
        const filtered = getCodeList('RSC0007')
            .filter(function (element, index, array) {
                return (element.code !== 'N');
            });

        const genderRet = Object.entries(filtered).map(([k, v]) => {
            return {
                value: v.code,
                label: v.code_name
            };
        });

        // 日本語レベル
        const japaneseLevel = getCodeValueLabelList('RSC0006');

        // 国・地域名
        const countries = Object.entries(getCountryMasterList()).map(([key, val]) => {
            if (val.country_code !== "JPN") {
                return (
                    <option key={val.country_code} value={val.country_code} selected={this.state.born_country_code == val.country_code}>
                        {val.country_name}
                    </option>
                );
            }
        });

        let tabindex = 20;

        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                応募者情報
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                {this.props.beforeDispId === 'CmTemplate' ?
                                    <li>
                                        <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMAMF01", true) }, () => { })}>応募者一覧</a>
                                    </li>
                                    : null
                                }
                                <li>
                                    応募者情報登録
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">応募者情報登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、「登録」ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。
                                            <br />Enter the following items and save inputs with clicking the "Register" button.<br />Items with <span className="el_badge el_badge__required hp_mr5">必須</span> are required inputs.</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        在留資格<br />
                                                        Status
                                                    </th>
                                                    <td>
                                                        <select className="el_select" name="status_of_residence" value={this.state.status_of_residence}
                                                            onChange={this.handleResidenceChange} tabIndex="1">
                                                            {
                                                                statusOfResidence && statusOfResidence.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.status_of_residence == item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            };
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>応募者ID<br />
                                                        Applicant ID</th>
                                                    <td>
                                                        <span>{this.state.candidate_id}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        応募者写真<br />
                                                        Applicant photo
                                                    </th>
                                                    <td>
                                                        <div className="bl_upload_box__applicant">
                                                            <figure>
                                                                <img src={this.state.candidate_photo == null ? 'data:,' : this.state.candidate_photo} alt="" tabIndex="2" />
                                                            </figure>
                                                            <div className="bl_btns">
                                                                <label htmlFor="changeImg" onClick={event => event.target.value = null} className="el_smBlueBtn" tabIndex="3" >
                                                                    写真を選択<br />Select photo
                                                                    <input type="file" id="changeImg" className="el_sendFile" name="workplace_image_change" accept="image/*"
                                                                        onChange={this.handleImageUpload} />
                                                                </label>
                                                                <label htmlFor="deleteImg" className="el_smBlueBtn" tabIndex="4">
                                                                    写真を削除<br />Delete photo
                                                                    <input type="button" id="deleteImg" className="el_sendFile" onClick={this.handleImageDelete} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>自己紹介動画URL<br />
                                                        Video URL for self-introduction</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="video_url" className="el_input el_input__max" maxlength="2000" required="required"
                                                                setValue={this.state.video_url} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>大学<br />
                                                        University</th>
                                                    <td>
                                                        <span>{this.state.university_name}</span>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        学籍番号<br />
                                                        Student No.
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_student_no" className="el_input el_input__max" maxlength="10" required="required"
                                                                setValue={this.state.candidate_student_no} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        応募者氏名（ローマ字）<br />
                                                        Name(Roman Alphabet)
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_name" className="el_input el_input__max" maxlength="20" required="required"
                                                                setValue={this.state.candidate_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        応募者氏名（フリガナ）<br />
                                                        Name(Phonetic Notation)
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_name_kana" className="el_input el_input__max" maxlength="20" required="required"
                                                                setValue={this.state.candidate_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" toZenKana="true" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        応募者氏名（ニックネーム）<br />
                                                        Name(nickname)
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_nickname" className="el_input el_input__max" maxlength="20" required="required"
                                                                setValue={this.state.candidate_nickname} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        国籍<br />
                                                        Nationality
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select" name="born_country_code" value={this.state.born_country_code}
                                                                onChange={this.handleCountriesChange} tabIndex="10">
                                                                <option value="" selected></option>
                                                                {countries};
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        生年月日<br />
                                                        Date of birth
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="date" name="candidate_birthday" className="el_input el_input__datepicker" required="required"
                                                                setValue={this.state.candidate_birthday} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex="11" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        性別<br />
                                                        Sex
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select" name="candidate_sex" value={this.state.candidate_sex}
                                                                onChange={this.handleGenderChange} tabIndex="12">
                                                                <option value="" selected></option>
                                                                {
                                                                    genderRet && genderRet.map(item => (
                                                                        <option key={item.value} value={item.value} selected={this.state.candidate_sex == item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        日本語レベル<br />
                                                        Japanese level
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select" name="candidate_japanese_level" value={this.state.candidate_japanese_level}
                                                                onChange={this.handleJapaneseLevelChange} tabIndex="13">
                                                                <option value="" selected></option>
                                                                {
                                                                    japaneseLevel && japaneseLevel.map(item => (
                                                                        <option key={item.value} value={item.value} selected={this.state.candidate_japanese_level == item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>得意言語<br />
                                                        Preferred language</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_language" className="el_input el_input__max" maxlength="20" required="required"
                                                                setValue={this.state.candidate_language} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="14" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        学部・専攻<br />
                                                        Faculty/major
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_department" className="el_input el_input__max" maxlength="40" required="required"
                                                                setValue={this.state.candidate_department} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="15" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        取得済単位数<br />
                                                        Unit gained
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_acquired_credits" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="3" required="required"
                                                                setValue={this.state.candidate_acquired_credits} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="16" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        インターンでの取得予定単位数<br />
                                                        Obtained credits by internship
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_intern_schedule_credits" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="3" required="required"
                                                                setValue={this.state.candidate_intern_schedule_credits} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="17" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        卒業までに必要な単位数<br />
                                                        Graduation credits
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="candidate_required_credits" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="3" required="required"
                                                                setValue={this.state.candidate_required_credits} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="18" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        自己アピール<br />
                                                        Self appeal
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <Textarea name="candidate_appeal" className="el_textarea" rows="2" maxlength="60" required="required"
                                                                setValue={this.state.candidate_appeal} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="19" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>保有資格<br />
                                                        Possessed status</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <Textarea name="license" className="el_textarea" rows="2" maxlength="60"
                                                                setValue={this.state.license} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="20" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>職歴<br />
                                                        Work history</th>
                                                    <td>
                                                        （入力例）2020年01月－2020年12月&nbsp;&nbsp;〇〇ホテル&nbsp;&nbsp;フロント受付
                                                        <br />
                                                        <br />
                                                        {
                                                            checkArray(work_history_list) ? (work_history_list.map((item, index) => {
                                                                return (
                                                                    <div className="bl_formUnit hp_mb5" key={index}>
                                                                        <TextInput maxlength="10" type="month" placeholder="年/月" name="work_history_ym_from" className="el_input el_input__datepicker divpadding" setValue={item.work_history_ym_from} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={tabindex + 1} />－
                                                                        <TextInput maxlength="10" type="month" placeholder="年/月" name="work_history_ym_to" className="el_input el_input__datepicker divpadding" setValue={item.work_history_ym_to} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={tabindex + 1} />&nbsp;
                                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} name="work_history" className="el_input el_input__65 text_left_align" setValue={item.work_history} tabIndex={tabindex + 1} />
                                                                    </div>
                                                                );
                                                            })) : null
                                                        }
                                                        <div class="bl_horizBtnUnit bl_horizBtnUnit__l">
                                                            <button type="button" value="-" class="el_funcBtn" onClick={() => this.deleteWorkHistory(this.state.work_history_list.length - 1)} tabIndex={tabindex + 2} disabled={checkArray(work_history_list) ? (work_history_list.length > 0 ? false : true) : true}>
                                                                <img style={{ opacity: checkArray(work_history_list) ? (work_history_list.length > 0 ? 1.0 : 0.5) : 0.5 }} src={Minus_Icon} alt="マイナス" />
                                                                <span style={{ opacity: checkArray(work_history_list) ? (work_history_list.length > 0 ? 1.0 : 0.5) : 0.5 }}>削除</span>
                                                            </button>
                                                            <button type="button" value="+" class="el_funcBtn" onClick={() => this.addWorkHistory()} tabIndex={tabindex + 3}>
                                                                <img src={Plus_Icon} alt="プラス" />
                                                                追加
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>メールアドレス<br />
                                                        Mail address</th>
                                                    <td>
                                                        <span>{this.state.candidate_mail_address}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>更新日時<br />
                                                        Update date</th>
                                                    <td>
                                                        <span>{this.state.update_datetime}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        {this.props.beforeDispId === "CmTemplate" ? <button type="button" className="el_backBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMAMF01", true); }, () => { })} tabIndex={tabindex + 4}>応募者一覧へ</button> :
                                            <button type="button" className="el_blueBtn is_disabled" tabIndex={tabindex + 6}>応募者一覧へ</button>}
                                        <button type="button" className="el_btn" onClick={this.candidateInfoSave} tabIndex={tabindex + 5}>登録／Register</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </article >
        );
    }
}

/**
 * データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.saveCandidate.isFetching,
        data: state.saveCandidate.candidateData,
        isError: state.saveCandidate.isError,
        err_type: state.saveCandidate.err_type,
        err_detail: state.saveCandidate.err_detail
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        saveCandidateInfo: (arg) => dispatch(SaveCandidate(arg)),
        getCandidateReset: () => dispatch(CandidateReset()),
        getCandidateDetailReset: () => dispatch(GetCandidateDetailReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMERF01);

