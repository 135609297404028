import actionTypes from './GIPPMPLI01ActionType';

/**
 * 保険申込履歴
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 進捗一覧照会を検索するReducer
 * @param {*} state
 * @param {*} action
 */
export const getProgressList = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PROGRESS_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_PROGRESS_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_PROGRESS_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_PROGRESS_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 進捗一覧のダウンロードReducer
 * @param {*} state
 * @param {*} action
 */
export const progressDownloadFile = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.PROGRESS_DOWNLOAD_FILE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.PROGRESS_DOWNLOAD_FILE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                isError: false,
                err_type: action.data.err_type == null ? "200" : action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.PROGRESS_DOWNLOAD_FILE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.PROGRESS_DOWNLOAD_FILE_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
