import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCRU10ActionType';

// 組織情報(プロフィール)支援機関取得API
const GET_SUPPORT_ORG_INFO_POST = BASE_URL + "/api/cm_om_get_sup_organization_info";
// 組織情報(プロフィール)支援機関更新API
const UPDATE_SUPPORT_ORG_INFO_POST = BASE_URL + "/api/cm_om_update_sup_organization_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetSupportOrgInfoRequest = () => {
    return {
        type: actionTypes.GET_SUPPORT_ORG_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetSupportOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_SUPPORT_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetSupportOrgInfoFailure = (err) => {
    return {
        type: actionTypes.GET_SUPPORT_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetSupportOrgInfoReset = () => {
    return {
        type: actionTypes.GET_SUPPORT_ORG_INFO_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateSupportOrgInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_SUPPORT_ORG_INFO_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateSupportOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_SUPPORT_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateSupportOrgInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_SUPPORT_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateSupportOrgInfoReset = () => {
    return {
        type: actionTypes.UPDATE_SUPPORT_ORG_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getSupportOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetSupportOrgInfoRequest());
        ecoPostWithAuthHeader(GET_SUPPORT_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetSupportOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetSupportOrgInfoFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateSupportOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateSupportOrgInfoRequest());
        ecoPostWithAuthHeader(UPDATE_SUPPORT_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateSupportOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(UpdateSupportOrgInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getSupportOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetSupportOrgInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateSupportOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateSupportOrgInfoReset());
    };
};