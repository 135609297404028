import React, { Fragment } from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCognitoUserInfo, getMessage, getCodeName } from "../../Constants/CommonConstants";
import { MessageMap } from "./GESEQQRI01Message";
import { getNumberText, checkArray, isBlank } from "../../Components/Tools/StringTools";
import { updateTrainingEnqueteAnswerInfo, UpdateTrainingEnqueteAnswerInfoReset } from "./GESEQQRI01Action";
import "../../Css/Common.css";

/**
 * アンケート登録（実習生用）
 */
class GESEQQRI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            answerList: [],
            profileInfo: {},
            sysDateFlg: false,
            messageMap: {}
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const profileInfo = this.props.trainingEnqueteAnswerInfo.data.list;
        let answerList = [];
        let isCreate = false;
        if (isBlank(profileInfo.version_no)) {
            isCreate = true;
        }
        // アンケートリスト設定
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('1') : profileInfo.enquete_item_01,
            score: isCreate ? '' : profileInfo.score_01,
            item_no: '1'
        });
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('2') : profileInfo.enquete_item_02,
            score: isCreate ? '' : profileInfo.score_02,
            item_no: '2'
        });
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('3') : profileInfo.enquete_item_03,
            score: isCreate ? '' : profileInfo.score_03,
            item_no: '3'
        });
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('4') : profileInfo.enquete_item_04,
            score: isCreate ? '' : profileInfo.score_04,
            item_no: '4'
        });
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('5') : profileInfo.enquete_item_05,
            score: isCreate ? '' : profileInfo.score_05,
            item_no: '5'
        });
        answerList.push({
            enquete_item: isCreate ? this.getMasterName('6') : profileInfo.enquete_item_06,
            score: isCreate ? '' : profileInfo.score_06,
            item_no: '6'
        });
        // メッセージマスタ名設定
        const messageMap = Object.assign({}, MessageMap);
        messageMap['enquete_item_01'] = this.getMasterName('1');
        messageMap['enquete_item_02'] = this.getMasterName('2');
        messageMap['enquete_item_03'] = this.getMasterName('3');
        messageMap['enquete_item_04'] = this.getMasterName('4');
        messageMap['enquete_item_05'] = this.getMasterName('5');
        messageMap['enquete_item_06'] = this.getMasterName('6');
        this.setState({
            profileInfo: profileInfo,
            answerList: answerList,
            version_no: profileInfo.version_no,
            messageMap: messageMap,
            sysDateFlg: this.props.trainingEnqueteAnswerInfo.data.sys_date_flg,
        });

    }

    /**
     * アンケートマスタデータ
     * @param {*} intemNo 
     */
    getMasterName = (intemNo) => {
        const enquetMaster = this.props.trainingEnqueteAnswerInfo.data.enquet_master_list;
        return enquetMaster.filter(v => v.enquete_item_id == intemNo)[0].enquete_item_name;

    }

    /**
     * ラジオボタン変更
     * @param {*} event 
     */
    handleChange = (event) => {
        const { name, value, title } = event.target;
        const { answerList } = this.state;
        const itemNo = name.split('_')[1];
        answerList.forEach(el => {
            if (el.item_no == itemNo) {
                el.score = value;
            }
        });
        this.setState({
            answerList: answerList
        });
    };

    /**
     * 登録
     */
    saveInfo = () => {
        window.scrollTo(0, 0);
        this.props.updateTrainingEnqueteAnswerInfo({
            case_id: this.state.profileInfo.case_id,
            version_no: this.state.version_no,
            enquete_answer_data: this.state.answerList
        });
    }

    render() {

        const { answerList, sysDateFlg, profileInfo } = this.state;

        let isDisabled = true;
        // 現在システム日付　≧　受入・就業期間終了日　－　1ヶ月
        if (sysDateFlg) {
            if (isBlank(this.state.version_no)) {
                // 採用者情報．在留資格　が　INT:インターン生　の場合
                if (getCognitoUserInfo()["org_type"] == 'INT') {
                    // 実習結果報告書が作成済み（採用者情報．実習結果登録ステータス　＝　2:評価登録済）
                    if (profileInfo.training_results_regist_status == '2') {
                        isDisabled = false;
                    }
                } else {
                    isDisabled = false;
                }
            }
        }

        if (!this.props.updateTrainingEnqueteAnswerInfoResult.isFetching) {
            // 最新バージョン設定する
            if (this.props.updateTrainingEnqueteAnswerInfoResult.data
                && this.props.updateTrainingEnqueteAnswerInfoResult.err_type === '200'
                && this.state.version_no !== this.props.updateTrainingEnqueteAnswerInfoResult.data.version_no) {
                this.setState({ version_no: this.props.updateTrainingEnqueteAnswerInfoResult.data.version_no }, () => {
                    this.showModalDialog(0, getMessage('RS-0036'), () => {
                        this.props.updateTrainingEnqueteAnswerInfoReset();
                    });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                アンケート登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GAFMCMCFEQ01_GESEQQRI01"); }, () => { })}>ユーザ一覧</a></li>
                                <li>実習・就労アンケート</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.updateTrainingEnqueteAnswerInfoResult.err_type} err_detail={this.props.updateTrainingEnqueteAnswerInfoResult.err_detail} messageMap={this.state.messageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">実習・就労アンケート</h2>
                                </div>

                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>実習・就労情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{this.state.profileInfo.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>{this.state.profileInfo.case_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>{this.state.profileInfo.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    {
                                                        isBlank(this.state.profileInfo.accept_start) && isBlank(this.state.profileInfo.accept_end) ? <td></td> : <td>{this.state.profileInfo.accept_start}〜{this.state.profileInfo.accept_end}</td>
                                                    }
                                                </tr>
                                                <tr>
                                                    <th>
                                                        所属
                                                    </th>
                                                    <td>
                                                        {this.state.profileInfo.organization_name}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>

                                <div className="bl_sect_inner">

                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>アンケート登録</span></h3>
                                        </div>
                                        <p className="el_note_sm hp_tar hp_mb20">満足度：★★★★★（1～5）</p>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr><th>
                                                        <span className="el_badge el_badge__required hp_mr10">必須</span>
                                                    </th><th>５</th><th>４</th><th>３</th><th>２</th><th>１</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        return (
                                                            checkArray(answerList) && answerList.map((el, index) => {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="el_vTitle">
                                                                                {el.enquete_item}
                                                                            </td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={5} title={el.enquete_item} checked={el.score == '5'} onChange={this.handleChange} tabIndex={index + 1} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={4} title={el.enquete_item} checked={el.score == '4'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={3} title={el.enquete_item} checked={el.score == '3'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={2} title={el.enquete_item} checked={el.score == '2'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={1} title={el.enquete_item} checked={el.score == '1'} onChange={this.handleChange} /></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                );
                                                            })
                                                        );
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>

                                    <div className="bl_horizBtnUnit">
                                        <button tabIndex='7' type="button" className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveInfo()}>登録する</button>
                                    </div>

                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button tabIndex="8" type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GAFMCMCFEQ01_GESEQQRI01"); }, () => { })}>ユーザ一覧へ</button>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        trainingEnqueteAnswerInfo: state.getTrainingEnqueteAnswerInfo,
        updateTrainingEnqueteAnswerInfoResult: state.updateTrainingEnqueteAnswerInfo
    };
};


const mapDispatchToProps = dispatch => {
    return {
        updateTrainingEnqueteAnswerInfo: (arg) => dispatch(updateTrainingEnqueteAnswerInfo(arg)),
        updateTrainingEnqueteAnswerInfoReset: () => dispatch(UpdateTrainingEnqueteAnswerInfoReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GESEQQRI01);
