import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getMessage } from "../../Constants/CommonConstants";
import { checkArray } from "../../Components/Tools/StringTools";
import { getTrainningWorkerList, getTrainningWorkerListReset } from "./GCMOMCUM01Action";
import { getTrainningWorkerDetail } from "../OmGCMOMCUM02/GCMOMCUM02Action";
import { GetDailyReportList, GetDailyReportListReset, GetDailyReportListLastDate } from "../../Is/DmGISDMRLS01/GISDMRLS01Action";
import { getEducationHistoryDetail, GetEducationHistoryDetailReset } from "../../Ef/CrGEFCRRRI01/GEFCRRRI01Action";
import { getTrainingEnqueteAnswerInfo, GetTrainingEnqueteAnswerInfoReset } from "../../Es/EqGESEQQRI01/GESEQQRI01Action";
import { getCompanyEnqueteAnswerInfo, GetCompanyEnqueteAnswerInfoReset } from "../../Es/EqGESEQQRE01/GESEQQRE01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import moment from "moment";
import "../../Css/Common.css";

/**
 * ユーザ一覧
 */
class GCMOMCUM01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            search_candidate_name: "",
            search_organization_name: "",
            caseType: [],
            search_country_code: "",
            search_company_name: "",
            search_case_id: "",
            selectedOrganizationId: "",
            inited: false,
            countryOpt: [],
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = async () => {
        // 初期化する
        await this.props.trainningWorkerListReset();
        await this.props.getDailyReportListReset();
        await this.props.getEducationHistoryDetailReset();
        await this.props.getTrainingEnqueteAnswerInfoReset();
        await this.props.getCompanyEnqueteAnswerInfoReset();
        this.setState({ inited: true });

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet]
        }), () => {
            this.handlingSearch();
        });
        window.scrollTo(0, 0);
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        this.state.caseType[index] = event.target.checked;
        this.setState({
            caseType: this.state.caseType
        });
    };

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 検索処理
     */
    handlingSearch = () => {
        window.scrollTo(0, 0);

        let casetype = "";
        this.state.caseType.map((key, value) => {
            if (key) {
                casetype = casetype + value + ",";
            }
        });
        if (casetype.length > 0) {
            casetype = casetype.substr(0, casetype.length - 1);
        }

        // パラメータの設定
        let searchParameter = {
            search_candidate_name: this.state.search_candidate_name,
            search_organization_name: this.state.search_organization_name,
            search_status_of_residence: casetype,
            search_country_code: this.state.search_country_code,
            search_company_name: this.state.search_company_name,
            search_case_id: this.state.search_case_id
        };

        this.setState({
            condition: searchParameter,
            selectedOrganizationId: "",
            err_msg: []
        }, () => {
            // 検索処理を行う
            // システムエラーのコールバック設定
            this.setErrorCallback(this.props.trainningWorkerListReset());
            this.props.getTrainningWorkerList(this.state.condition);
        });
    }

    /**
     * 実習／労働者詳細取得
     * @param {*} event 選択された対象
     */
    handleToDisplayWithWorkerData = (candidate_id, company_id, case_id, status_of_residence, user_id, accept_end, employment_name_kana) => {
        window.scrollTo(0, 0);
        if (this.props.beforeDispId == "GAFMCMCFDM01" || this.props.beforeDispId == "GAFMCMCFDI01") {
            // 日報管理,日報照会の場合
            let args = {
                case_id: case_id,
                user_id: user_id,
                accept_end: accept_end,
                employment_name_kana: employment_name_kana

            };
            this.props.getDailyReportList(args, this.props.beforeDispId);
            return;
        }
        if (this.props.beforeDispId == "GAFMCMCFWT01") {
            // 作業時間管理の場合
            let args = {
                user_id: user_id,
                case_id: case_id,
                work_report_date: moment().format('YYYY/MM/DD'),
                employment_name_kana: employment_name_kana
            };
            this.props.switchDisplayInquiry('GTMWTWTI01', args);
            return;
        }
        if (this.props.beforeDispId == "GAFMCMCFAA01") {
            // 勤怠承認
            let args = {
                user_id: user_id,
                case_id: case_id,
                work_report_date: moment().format('YYYY/MM/DD'),
                employment_name_kana: employment_name_kana
            };
            this.props.switchDisplayInquiry('GTMWTWTA01', args);
            return;
        }
        if (this.props.beforeDispId == "GAFMCMCFCR01") {
            // 履修履歴詳細の場合
            let args = {
                user_id: user_id,
                case_id: case_id
            };
            this.props.getEducationHistoryDetail(args);
            return;
        }
        if (this.props.beforeDispId == "GAFMCMCFEQ01_GESEQQRI01") {
            // アンケート登録（実習生用）の場合
            let args = {
                case_id: case_id
            };
            this.props.getTrainingEnqueteAnswerInfo(args);
            return;
        }
        if (this.props.beforeDispId == "GAFMCMCFEQ01_GESEQQRE01") {
            // アンケート登録（企業用）の場合
            let args = {
                case_id: case_id,
                user_id: user_id
            };
            this.props.getCompanyEnqueteAnswerInfo(args);
            return;
        }
        // 実習／労働者詳細取得
        this.props.getTrainningWorkerinfo(candidate_id, company_id, case_id, status_of_residence);
    };

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    render() {
        // 件数が100件を超える場合
        if (this.props.data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({ err_msg: [getMessage('RS-0017').replace(/%1/g, '100')] });
        }

        // 実習／労働者詳細取得が成功になる場合
        if (this.props.detailData && this.props.err_typeDetail === "200" && this.state.inited) {
            this.props.skipToNext("GCMOMCUM02", this.props.detailData, "GCMOMCUM01");
        }

        // 日報日次一覧
        if (this.props.dailyReportList && this.props.dailyReportList.data && this.props.dailyReportList.err_type === "200" && this.state.inited) {
            this.props.getDailyReportListLastDate(this.props.dailyReportList.data.target_date);
            if (this.props.beforeDispId == "GAFMCMCFDM01") {
                this.props.changeDisp('GISDMRLS01_GAFMCMCFDM01');
            } else {
                this.props.changeDisp('GISDMRLS01_GAFMCMCFDI01');
            }
        }

        // 履修履歴詳細
        if (this.props.educationHistory && this.props.educationHistory.err_type === "200" && this.state.inited) {
            this.props.changeDisp("GEFCRRRI01");
        }

        // アンケート登録（実習生用）
        if (this.props.trainingEnqueteAnswerInfo && this.props.trainingEnqueteAnswerInfo.err_type === "200" && this.state.inited) {
            this.props.changeDisp('GESEQQRI01');
        }

        // アンケート登録（企業用）
        if (this.props.companyEnqueteAnswerInfo && this.props.companyEnqueteAnswerInfo.err_type === "200" && this.state.inited) {
            this.props.changeDisp('GESEQQRE01');
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">ユーザ一覧</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>ユーザ一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.dailyReportList.err_type} err_detail={this.props.dailyReportList.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.educationHistory.err_type} err_detail={this.props.educationHistory.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.trainingEnqueteAnswerInfo.err_type} err_detail={this.props.trainingEnqueteAnswerInfo.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.companyEnqueteAnswerInfo.err_type} err_detail={this.props.companyEnqueteAnswerInfo.err_detail} messageMap={{}} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={this.toggleClassAction} tabIndex="1">検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>氏名</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="search_candidate_name" className="el_input el_input__max" maxlength="20" setValue={this.state.search_candidate_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>所属組織名</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="search_organization_name" className="el_input el_input__max" maxlength="40" setValue={this.state.search_organization_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} disabled={this.state.disabled} tabIndex="4" />
                                                                <span className="el_checkbox_txt">技能実習</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} disabled={this.state.disabled} tabIndex="5" />
                                                                <span className="el_checkbox_txt">特定技能</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} disabled={this.state.disabled} tabIndex="6" />
                                                                <span className="el_checkbox_txt">インターン</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>国籍</th>
                                                    <td>
                                                        <select className="el_select" name="search_country_code" value={this.state.country_code} onChange={this.handleChange} tabIndex="7">
                                                            <option value="" selected></option>
                                                            {
                                                                this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                    <option key={item.country_number} value={item.country_code} selected={this.state.country_code === item.country_code}>
                                                                        {item.country_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>
                                                        <TextInput type="text" name="search_company_name" className="el_input el_input__max" maxlength="60" setValue={this.state.search_company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>
                                                        <TextInput type="text" name="search_case_id" className="el_input el_input__lg" maxlength="11" setValue={this.state.search_case_id} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSearch()} tabIndex="10">検索</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>ユーザ一覧</span></h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>氏名</th>
                                                        <th>所属組織名</th>
                                                        <th>在留資格</th>
                                                        <th>国籍</th>
                                                        <th>性別</th>
                                                        <th>企業名</th>
                                                        <th>案件ID</th>
                                                        <th>受入・就業期間</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data.list)) {
                                                            return (
                                                                this.props.data.list.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <a href="#" className="el_link" onClick={() => this.handleToDisplayWithWorkerData(item.candidate_id, item.company_id, item.case_id, item.status_of_residence, item.user_id, item.accept_end, item.employment_name_kana)} tabIndex="11">{item.employment_name_kana}</a>
                                                                        </td>
                                                                        <td>{item.organization_name}</td>
                                                                        <td>{item.status_of_residence_name}</td>
                                                                        <td>{item.employment_born_country_name}</td>
                                                                        <td>{item.employment_sex_name}</td>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{item.case_id}</td>
                                                                        <td>{item.accept_start.replace(/\-/g, "/")}〜{item.accept_end.replace(/\-/g, "/")}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getTrainningWorkerList.isFetching,
        data: state.getTrainningWorkerList.data,
        isError: state.getTrainningWorkerList.isError,
        err_type: state.getTrainningWorkerList.err_type,
        err_detail: state.getTrainningWorkerList.err_detail,
        isDetailFetching: state.getTrainningWorkerDetail.isFetching,
        detailData: state.getTrainningWorkerDetail.data,
        isDetailError: state.getTrainningWorkerDetail.isError,
        err_typeDetail: state.getTrainningWorkerDetail.err_type,
        err_detailDetail: state.getTrainningWorkerDetail.err_detail,
        dailyReportList: state.getDailyReportList,
        educationHistory: state.getEducationHistoryDetail,
        trainingEnqueteAnswerInfo: state.getTrainingEnqueteAnswerInfo,
        companyEnqueteAnswerInfo: state.getCompanyEnqueteAnswerInfo,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getTrainningWorkerList(args) {
            // 実習／労働者一覧
            dispatch(getTrainningWorkerList(args));
        },
        trainningWorkerListReset() {
            // 実習／労働者一覧
            dispatch(getTrainningWorkerListReset());
        },
        getTrainningWorkerinfo(candidate_id, company_id, case_id, status_of_residence) {
            // 送出し機関詳細取得
            dispatch(getTrainningWorkerDetail({
                candidate_id: candidate_id, company_id: company_id, case_id: case_id, status_of_residence: status_of_residence
            }));
        },
        getDailyReportListReset() {
            // 日報日次一覧Reset
            dispatch(GetDailyReportListReset());
        },
        getDailyReportList(args) {
            // 日報日次一覧
            dispatch(GetDailyReportList(args));
        },
        getDailyReportListLastDate(data) {
            // 日報日次一覧(最新対象月)
            dispatch(GetDailyReportListLastDate(data));
        },
        getEducationHistoryDetail(args) {
            // 履修履歴詳細照
            dispatch(getEducationHistoryDetail(args));
        },
        getEducationHistoryDetailReset() {
            // 履修履歴詳細照リセット
            dispatch(GetEducationHistoryDetailReset());
        },
        getTrainingEnqueteAnswerInfo(args) {
            // アンケート登録（実習生用）
            dispatch(getTrainingEnqueteAnswerInfo(args));
        },
        getTrainingEnqueteAnswerInfoReset() {
            // アンケート登録（実習生用）リセット
            dispatch(GetTrainingEnqueteAnswerInfoReset());
        },
        getCompanyEnqueteAnswerInfo(args) {
            // アンケート登録（企業用）
            dispatch(getCompanyEnqueteAnswerInfo(args));
        },
        getCompanyEnqueteAnswerInfoReset() {
            // アンケート登録（企業用）リセット
            dispatch(GetCompanyEnqueteAnswerInfoReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMCUM01);

