import React from "react";
import { getMessage } from "../../Constants/CommonConstants";
import icon_erro_rd03 from "../../Images/icon_erro_rd03.svg";
import icon_erro_og01 from "../../Images/icon_erro_og01.svg";
import icon_info02 from "../../Images/icon_info02.svg";

/**
 * モーダル風メッセージポーネント
 * @param {*} props
 */
export default function ModalMessage(props) {
    return (
        <div id="bl_modalID" className="bl_modal">
            <div className="bl_modal-wrap">
                <div className="bl_modal-bg"></div>
                <div className="bl_modal-box">
                    <div className="bl_modal_inner">
                        <div className="bl_modalTop">
                            {/* システムエラー */}
                            {props.type == 9 ? <img src={icon_erro_rd03} alt="" /> : null}
                            {/* メッセージ */}
                            {props.type == 0 ? <img src={icon_info02} alt="" /> : null}
                            {/* 警告 */}
                            {props.type == 1 ? <img src={icon_erro_og01} alt="" /> : null}
                            {/* 業務エラー */}
                            {props.type == 3 ? <img src={icon_erro_rd03} alt="" /> : null}
                            {/* エラーコード表示 */}
                            {props.code ? <p>ERROR : {props.code}</p> : null}
                        </div>
                        <div className="bl_modalBtm">
                            <div className="bl_modalTxt">
                                {props.content ? props.content : null}
                                {props.type == 9 ? <p>{getMessage('AF-0004')}</p> : null}
                            </div>
                            <div className="bl_horizBtnUnit">
                                <button type="button" className="el_blueBtn" onClick={() => props.okClick()}>OK</button>
                                {props.cancelClick ? <button type="button" className="el_blueBtn" onClick={() => props.cancelClick()}>キャンセル</button> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
