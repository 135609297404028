import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import GAFMCSNF03 from "../McGAFMCSNF03/GAFMCSNF03";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import moment from "moment";

import { getCognitoPool, hasShowRole, setSessionTimeoutCheck, getCognitoUserInfo, getCognitoUser, setMenuInstance, getMenuInstance } from "../../Constants/CommonConstants";
import { showError, showLoading, MenuUserLogout, errCloseCallback, MenuClearData } from "./GAFMCMCF01Action";

import Logout_Icon from "../../Images/icon_logout.svg";
import Help_Icon from "../../Images/icon_help.svg";
import Education_Icon from "../../Images/icon_education.svg";
import Attendance_Icon from "../../Images/icon_attendance.svg";
import Attendance_Icon2 from "../../Images/icon_attendance2.svg";
import Workshop_Icon from "../../Images/icon_workshop.svg";
import Insurance_Icon from "../../Images/icon_insurance.svg";
import Formalities_Icon from "../../Images/icon_formalities.svg";
import Matching_Icon from "../../Images/icon_matching.svg";
import Evaluation_Icon from "../../Images/icon_evaluation.svg";
import Organization_Icon from "../../Images/icon_organization.svg";
import Home_Icon from "../../Images/icon_home.svg";
import MenuClose_Icon from "../../Images/icon_menu_close.svg";
import MenuOpen_Icon from "../../Images/btn_menu.svg";
import Service_Icon from "../../Images/service_logo.png";
import pageUp_Icon from "../../Images/pageup.svg";
import pageDown_Icon from "../../Images/pagedwn.svg";
import ModalMessage from "../../Components/Atoms/ModalMessage";
import Load from "../../Components/Atoms/Load";
import { DeleteOrganizationManagementRequest } from "../../Cm/OmGCMOMOIR01/GCMOMOIR01Action";

/**
 * メニュー制御機能
 */
class GAFMCMCF01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        let init_toggle_class = [
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item",
            "bl_gnav_item"
        ];
        if (this.props.toggleClass !== undefined && this.props.toggleClass !== null) {
            init_toggle_class = this.props.toggleClass;
        }
        this.state = {
            is_menu_open: true,
            menu_class: "ly_cont_side is_active_sp",
            current_time: moment().format("YYYY/MM/DD HH:mm:ss"),
            user_name: getCognitoUserInfo()['user_name'],
            toggle_class: init_toggle_class,
            select_class: [
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link",
                "bl_gnavChildNav_link"],
            select_class_index: null,
            contents_class: "ly_cont_main",
            disp: "",
            beforeDispId: this.props.beforeDispId,
            errCallback: null,
            okCallback: null,
            cancelCallback: null,
            showDialog: false,
            dialogMessage: null,
            dialogType: null,
            orgType: getCognitoUserInfo()["org_type"],
            showOss: false,
            pdf: ""
        };
        setMenuInstance(this);
    }

    /**
     * メニュー初期化完了イベント
     */
    componentDidMount = () => {

        // セッションタイムメソッドバインディングする
        setSessionTimeoutCheck(this.sessionTimeoutCheck);
        if (this.props.toggleClass !== undefined && this.props.toggleClass !== null) {
            this.setState({
                toggle_class: this.props.toggleClass
            });
        }
        // メニュー選択状態設定
        this.setMenuSelected(this.props.menuIndex);
        // スマートフォン対応
        if (this.isSmartPhone()) {
            this.setState({
                is_menu_open: false,
                menu_class: "ly_cont_side is_active_pc",
                contents_class: "ly_cont_main is_wide"
            });
        }
        // 戻るボタン禁止
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
        // F5キー禁止
        window.addEventListener('keydown', this.onPressF5Event);

        if (document.getElementById("showNoticeArticle")) {
            document.body.style.background = "#f2f2f2";
        } else {
            document.body.style.background = "#fff";
        }

        if (this.state.orgType) {
            import("../../Pdfs/Manual_" + this.state.orgType + ".pdf").then(pdf => {
                this.setState({
                    pdf: pdf.default
                });
            });
        }
    }

    /**
     * メニューUnloadイベント
     */
    componentWillUnmount = () => {
        // セッションタイムメソッド解除する
        setSessionTimeoutCheck(null);
        // 戻るボタン禁止解除
        window.removeEventListener('popstate', this.onBackButtonEvent);
        // F5キー禁止解除
        window.removeEventListener('keydown', this.onPressF5Event);
    }

    /**
     * renderメソッドの直前に実行されます
     * @param {*} nextProps
     * @param {*} prevState
     */
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.displayParts && nextProps.displayParts.type && nextProps.displayParts.type.displayName) {
            if (nextProps.displayParts.type.displayName == window.currentShowDisplayName) {
                return {};
            }
            window.currentShowDisplayName = nextProps.displayParts.type.displayName;
        } else {
            window.currentShowDisplayName = null;
        }
        return {
            current_time: moment().format("YYYY/MM/DD HH:mm:ss")
        };
    }

    /**
     * セッションタイムチェック処理
     */
    sessionTimeoutCheck = () => {
        let userInfo = getCognitoUserInfo();
        if (userInfo['exp'] != null) {
            var date = new Date();
            var nowDate = date.getTime();
            var unixtime = Math.floor(nowDate / 1000);
            if (userInfo['exp'] < unixtime) {
                if (getCognitoUser() != null) {
                    getCognitoUser().signOut();
                }
                sessionStorage.clear();
                this.props.history.push('/login');
                return;
            }
        }
    }

    /**
     * F5キー対策
     * @param {*} e
     */
    onPressF5Event = (e) => {
        if ((e.which || e.keyCode) == 116) {
            e.preventDefault();
        }
        // ローディング画面表示する際にエンターキー無効にする
        if (this.props.isShowLoading && (e.keyCode == 13 || e.key === 'Enter')) {
            e.preventDefault();
        }
    }

    /**
     * Backボタン対策
     * @param {*} e
     */
    onBackButtonEvent = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }

    /**
     * スマートフォン判定
     */
    isSmartPhone = () => {
        if (navigator.userAgent.match(/iPhone|Android.+Mobile/)) {
            return true;
        } else {
            return false;
        }
    }

    /**
     * メイン画面切替表示
     * @param {*} changeTo
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDisp(changeTo);
    }

    /**
     * メニュー開く・閉じる制御
     */
    menuAction = () => {
        if (this.state.is_menu_open) {
            this.setState({
                is_menu_open: false,
                menu_class: "ly_cont_side is_active_pc",
                contents_class: "ly_cont_main is_wide"
            });
        } else {
            this.setState({
                is_menu_open: true,
                menu_class: "ly_cont_side is_active_sp",
                contents_class: "ly_cont_main"
            });
        }
    }

    /**
     * メニュー項目toggle制御
     * @param {*} index
     */
    toggleClassAction = (index) => {
        const changeState = this.state.toggle_class.slice();

        changeState[index] = changeState[index].indexOf("is_active") == -1 ? "bl_gnav_item js_gnavBtn is_active" : "bl_gnav_item";

        this.setState({
            toggle_class: changeState
        });
    }

    /**
     * メニュー項目select制御
     * @param {*} index
     */
    selectClassAction = (index) => {
        const changeState = this.state.select_class.slice();

        for (let i = 0; i < changeState.length; i++) {
            changeState[i] = "bl_gnavChildNav_link";
        }

        changeState[index] = "bl_gnavChildNav_link is_current";

        this.setState({
            select_class: changeState
        });
    }

    /**
     * 画面の最上部へ
     */
    scrollTop = () => {
        window.scroll({
            left: 0,
            top: 0,
            behavior: "smooth",
        });
    }

    /**
     * 画面の最下部へ
     */
    scrollBtm = () => {
        let element = document.documentElement;
        let bottom = element.scrollHeight - element.clientHeight;
        window.scroll({
            left: 0,
            top: bottom,
            behavior: "smooth",
        });
    }

    /**
     * ログアウト処理
     */
    logoutAction = () => {
        const currentUser = getCognitoPool().getCurrentUser();
        if (currentUser != null) {
            currentUser.signOut();
            sessionStorage.clear();
        }
        this.props.logout();
        this.props.history.replace("/login");
    }

    /**
     * メニュー選択状態設定
     */
    setMenuSelected = (menuIndex) => {
        if (menuIndex !== undefined && menuIndex !== null) {
            this.selectClassAction(menuIndex);
        }
        this.setState({ select_class_index: menuIndex });
    }

    /**
     * ホーム画面へ移動する
     */
    gotoHome = async () => {
        if (this.state.select_class_index != null) {
            await this.props.clearData();
            this.setMenuSelected(null);
        }
        this.props.history.push({
            pathname: '/af/home'
        });
    }

    /**
     * 募集支援メニュー制御
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoRs = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.push({
            pathname: '/rs/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index
        });
    }

    /**
     *ユーザ/組織管理機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoCm = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        let candidateInit = false;
        if (dispId == 'showProfile') {
            candidateInit = true;
        }
        this.props.history.replace({
            pathname: '/cm/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
            candidateInit: candidateInit
        });
    }

    /**
     * 作業時間管理機能機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoTm = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/tm/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }

    /**
     * 教育履歴機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoEf = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/ef/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }

    /**
     * 実習支援機能機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoIs = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/is/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }

    /**
     * 入出国手続き支援機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoIp = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/ip/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }

    /**
     * 保険契約管理機能機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoIc = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/ic/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }

    /**
     * 評価機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoEs = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/es/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }
    /**
     * ヘルプ
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoUi = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/ui/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }
    /**
     * システム管理機能
     * @param {*} dispId 画面名
     * @param {*} index  選択メニューのスタイルIndex
     */
    gotoSm = async (dispId, index) => {
        if (this.state.select_class_index != index) {
            await this.props.clearData();
        }
        this.setMenuSelected(index);
        this.props.history.replace({
            pathname: '/sm/home',
            dispId: dispId,
            toggleClass: this.state.toggle_class,
            menuIndex: index,
        });
    }
    /**
     * エラー画面クローズ処理
     */
    onCloseError = () => {
        // ログイン画面に遷移
        this.logoutAction();

        // コールバック設定された場合にコールバックを実施する
        // if (this.state.errCallback) {
        //     this.state.errCallback();
        //     this.setErrCallback(null);
        // }
        // this.props.showError(false);
    }

    /**
     * OKボタンを押す
     */
    onDialogOk = () => {

        // コールバック設定された場合にコールバックを実施する
        if (this.state.okCallback) {
            this.state.okCallback();
        }

        this.showModalDialog(null, null, null, null);
    }

    /**
     * Cancelボタンを押す
     */
    onDialogCancel = () => {

        // コールバック設定された場合にコールバックを実施する
        if (this.state.cancelCallback) {
            this.state.cancelCallback();

        }

        this.showModalDialog(null, null, null, null);
    }

    /**
     *「画面」に遷移すること
     */
    pageTransition = () => {
        if (this.state.orgType === 'COP') { // 組織情報(プロフィール)企業
            this.gotoCm("GCMOMCRU01", 12);
        } else if (this.state.orgType === 'OKR') { // 組織情報(プロフィール)送り出し機関
            this.gotoCm("GCMOMCRU03", 12);
        } else if (this.state.orgType === 'UNI') { // 組織情報(プロフィール)大学
            this.gotoCm("GCMOMCRU04", 12);
        } else if (this.state.orgType === 'KAN') { // 組織情報(プロフィール)監理団体
            this.gotoCm("GCMOMCRU05", 12);
        } else if (this.state.orgType === 'INT') { // 組織情報(プロフィール)インターン
            this.gotoCm("GCMOMCRU08", 12);
        } else if (this.state.orgType === 'GNO' || this.state.orgType === 'TOK') { // 組織情報(プロフィール)技能実習・特定技能
            this.gotoCm("GCMOMCRU09", 12);
        } else if (this.state.orgType === 'TOR') { // 組織情報(プロフィール)登録支援機関
            this.gotoCm("GCMOMCRU10", 12);
        }
    }

    /**
     * エラー画面のコールバック設定
     * @param {*} callback
     */
    setErrCallback = (callback) => {
        this.setState({ errCallback: callback });
    }

    /**
     * オープンライセンスダイアログ表示
     */
    showOssDialog = () => {
        this.setState({ showOss: true });
    }

    /**
     * モーダルダイアログを表示する
     */
    showModalDialog = (type, message, okCallback, cancelCallback) => {
        this.setState({
            showDialog: type != null ? true : false,
            dialogType: type,
            dialogMessage: message,
            okCallback: okCallback,
            cancelCallback: cancelCallback
        });
    }

    render() {
        let okCallback = this.state.okCallback == null ? null : this.onDialogOk;
        let cancelCallback = this.state.cancelCallback == null ? null : this.onDialogCancel;

        return (
            <div id="html">
                {this.props.isShowError ? <ModalMessage type={9} okClick={() => this.onCloseError()} /> : null}
                {this.state.showDialog ? <ModalMessage type={this.state.dialogType} content={this.state.dialogMessage} okClick={okCallback} cancelClick={cancelCallback} /> : null}
                {this.props.isShowLoading ? <Load /> : null}
                <div id="body">
                    <header className="ly_header">
                        <p className="el_headerLogo">
                            <img src={Service_Icon} alt="service Logo" className="el_headerLogo_img" />
                        </p>
                        <ul className="el_headerUserInfo">
                            <li>{this.state.current_time}</li>
                            <li>{this.state.user_name}</li>
                        </ul>
                    </header>
                    <div className="bl_sideOpenBtn_wrapper">
                        <button className="bl_sideOpenBtn js_sideOpenBtn" onClick={this.menuAction}>
                            <img src={MenuOpen_Icon} alt="メニューを開く" />
                        </button>
                    </div>
                    <div className="ly_cont">
                        <nav className={this.state.menu_class} id="glonav">
                            <div className="ly_header none-md">
                                <p className="el_headerLogo">
                                    <img src={Service_Icon} alt="service Logo" />
                                </p>
                            </div>
                            <div className="bl_sideCloseBtn_wrapper">
                                <button className="bl_sideCloseBtn js_sideCloseBtn" onClick={this.menuAction}>
                                    <img src={MenuClose_Icon} alt="メニューを閉じる" />
                                </button>
                            </div>
                            <ul className="bl_gnav">
                                <li className="bl_gnav_item">
                                    <a href="#!" className="bl_gnav_link" onClick={this.gotoHome}>
                                        <span className="bl_gnav_icon"><img src={Home_Icon} alt="" /></span>
                                        <span>ホーム画面</span>
                                    </a>
                                </li>
                                {hasShowRole("GAFMCMCFOM01") || hasShowRole("GAFMCMCFUM03") || hasShowRole("GAFMCMCFOM02") || hasShowRole("GAFMCMCFUM01") || hasShowRole("GAFMCMCFUM02") || hasShowRole("GAFMCMCFUM04") || hasShowRole("GAFMCMCFUM05")
                                    ? <li className={this.state.toggle_class[0]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(0)}>
                                            <span className="bl_gnav_icon"><img src={Organization_Icon} alt="" /></span>
                                            <span>ユーザ組織管理</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFOM01")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[10]} onClick={() => { this.gotoCm("GCMOMOIR01", 10); }}>組織管理</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM03")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[11]} onClick={() => { this.gotoCm("GCMOMORU01", 11); }}>ユーザ管理(登録/変更)</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFOM02")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[12]} onClick={() => { this.pageTransition(); }}>組織情報(プロフィール)</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM01")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[13]} onClick={() => { this.gotoCm("GRSMMAMF01", 13); }}>応募者一覧</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM02")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[14]} onClick={() => { this.gotoCm("showProfile", 14); }}>プロフィール登録</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM04")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[15]} onClick={() => { this.gotoCm("GCMOMCUM01", 15); }}>実習/労働者一覧</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM06")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[17]} onClick={() => { this.gotoCm("GCMOMORU03", 17); }}>組織変更</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUM05")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[16]} onClick={() => { this.gotoCm("GAFLFCPF01", 16); }}>パスワードの変更</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFMM01")
                                    ? <li className={this.state.toggle_class[2]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(2)}>
                                            <span className="bl_gnav_icon"><img src={Matching_Icon} alt="" /></span>
                                            <span>募集管理<small>(マッチング)</small></span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFMM01")
                                                ? <li className="">
                                                    <a href="#!" className={this.state.select_class[0]} onClick={() => { this.gotoRs("GRSMMMLI01", 0); }}>案件管理</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFIA01") || hasShowRole("GAFMCMCFRM01")
                                    ? <li className={this.state.toggle_class[4]}>
                                        <span className="bl_gnav_btn js_gnavBtn is_active" onClick={() => this.toggleClassAction(4)}>
                                            <span className="bl_gnav_icon"><img src={Insurance_Icon} alt="" /></span>
                                            <span>保険契約管理</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFIA01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[1]} onClick={() => this.gotoIc("GICIAIAS01", 1)}>保険申込一覧</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFRM01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[2]} onClick={() => this.gotoIc("GICRMIAI01", 2)}>保険申込履歴</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFSF01") || hasShowRole("GAFMCMCFEQ01") || hasShowRole("GAFMCMCFEQ02")
                                    ? <li className={this.state.toggle_class[1]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(1)}>
                                            <span className="bl_gnav_icon"><img src={Evaluation_Icon} alt="" /></span>
                                            <span>評価設定</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFEQ01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    {this.state.orgType == 'COP' ? <a href="#!" className={this.state.select_class[41]} onClick={() => this.gotoEs("GAFMCMCFEQ01_GESEQQRE01", 41)}>評価アンケート</a> :
                                                        <a href="#!" className={this.state.select_class[41]} onClick={() => this.gotoEs("GAFMCMCFEQ01_GESEQQRI01", 41)}>評価アンケート</a>}
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFEQ02")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[42]} onClick={() => this.gotoEs("GESSFELI01", 42)}>評価設定</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFPS01") || hasShowRole("GAFMCMCFPP01")
                                    ? <li className={this.state.toggle_class[3]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(3)}>
                                            <span className="bl_gnav_icon"><img src={Formalities_Icon} alt="" /></span>
                                            <span>入出国手続き支援</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFPS01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="" className={this.state.select_class[30]}>入出国手続き支援</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFPP01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#" className={this.state.select_class[31]} onClick={() => this.gotoIp("GIPPMPLI01", 31)}>入出国手続き進捗</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFDM01") || hasShowRole("GAFMCMCFDI01") || hasShowRole("GAFMCMCFIP01") || hasShowRole("GAFMCMCFAM01") || hasShowRole("GAFMCMCFTT01")
                                    ? < li className={this.state.toggle_class[5]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(5)}>
                                            <span className="bl_gnav_icon"><img src={Workshop_Icon} alt="" /></span>
                                            <span>実習支援</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFDM01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[17]} onClick={() => this.gotoIs("GAFMCMCFDM01", 17)}>日報管理</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFDI01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[18]} onClick={() => this.gotoIs("GAFMCMCFDI01", 18)}>日報照会</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFIP01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[19]} onClick={() => this.gotoIs("GISIPWPM01", 19)}>実習計画</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFTT01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[20]} onClick={() => this.gotoIs("GISTTPTL01", 20)}>実習計画テンプレート作成</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFAM01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[21]} onClick={() => this.gotoIs("GISAMIRS01", 21)}>実習完了管理</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFWT01") || hasShowRole("GAFMCMCFWT02")
                                    ? < li className={this.state.toggle_class[6]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(6)}>
                                            <span className="bl_gnav_icon"><img src={Attendance_Icon} alt="" /></span>
                                            <span>時間管理</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFWT01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[22]} onClick={() => this.gotoTm("GAFMCMCFWT01", 22)}>作業時間管理</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFWT02")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[23]} onClick={() => this.gotoTm("GTMWTWTR02", 23)}>簡易作業時間登録</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFAA01")
                                    ? <li className={this.state.toggle_class[8]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(8)}>
                                            <span className="bl_gnav_icon"><img src={Attendance_Icon2} alt="" /></span>
                                            <span>勤怠管理</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFAA01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[24]} onClick={() => { this.gotoTm("GAFMCMCFAA01", 24); }}>勤怠承認</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFCR01")
                                    ? <li className={this.state.toggle_class[9]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(9)}>
                                            <span className="bl_gnav_icon"><img src={Education_Icon} alt="" /></span>
                                            <span>教育</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFCR01")
                                                ? <li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[35]} onClick={() => { this.gotoEf("GAFMCMCFCR01", 35); }}>履修履歴管理</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFUL01") || hasShowRole("GAFMCMCFUL02") || hasShowRole("GAFMCMCFUL03")
                                    ? <li className={this.state.toggle_class[10]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(10)}>
                                            <span className="bl_gnav_icon"><img src={Help_Icon} alt="" /></span>
                                            <span>ヘルプ</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFUL01")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href={this.state.pdf} target="_blank" className={this.state.select_class[26]}>操作マニュアル</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUL02")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[27]} onClick={() => { this.gotoUi("GUIULUIP01", 27); }}>お役立ち情報登録</a>
                                                </li>
                                                : null
                                            }
                                            {hasShowRole("GAFMCMCFUL03")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[28]} onClick={() => { this.gotoUi("GUIULUIP02", 28); }}>お役立ち情報</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                                {hasShowRole("GAFMCMCFBI01")
                                    ? <li className={this.state.toggle_class[11]}>
                                        <span className="bl_gnav_btn js_gnavBtn" onClick={() => this.toggleClassAction(11)}>
                                            <span className="bl_gnav_icon"><img src={Matching_Icon} alt="" /></span>
                                            <span>システム管理</span>
                                        </span>
                                        <ul className="bl_gnavChildNav">
                                            {hasShowRole("GAFMCMCFBI01")
                                                ? < li className="bl_gnavChildNav_item">
                                                    <a href="#!" className={this.state.select_class[29]} onClick={() => { this.gotoSm("GSMBIBIO01", 29); }}>請求情報出力</a>
                                                </li>
                                                : null
                                            }
                                        </ul>
                                    </li>
                                    : null
                                }
                            </ul>
                            <ul className="bl_memberNav">
                                <li className="bl_memberNav_item">
                                    <a href="#!" className="bl_memberNav_link" onClick={() => this.logoutAction()}>
                                        <span className="bl_memberNav_icon"><img src={Logout_Icon} alt="" /></span>
                                        <span>ログアウト</span>
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <main className={this.state.contents_class}>
                            {this.props.displayParts}
                        </main>
                    </div>
                    <div id="btn_pud">
                        <div className="btn_pud-in">
                            <button className="scroll-top" id="pup"><img src={pageUp_Icon} alt="" onClick={this.scrollTop} /></button>
                        </div>
                        <div className="btn_pud-in">
                            <button className="scroll-top" id="pdwn"><img src={pageDown_Icon} alt="" onClick={this.scrollBtm} /></button>
                        </div>
                    </div>
                </div>
                {this.state.showOss ? <GAFMCSNF03 onClose={() => this.setState({ showOss: false })} /> : null}
            </div >
        );
    }
}

/**
 * メニューのStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isShowLoading: state.menu.showLoading,
        isShowError: state.menu.showError,
        callBack: state.menu.callBack
    };
};

/**
 * メニューのActionバインディングする
 * @param {*} dispatch
 */
function mapDispatchToProps(dispatch) {
    return {
        showError(show) {
            dispatch(showError(show));
        },
        showLoading(show) {
            dispatch(showLoading(show));
        },
        logout() {
            dispatch(MenuUserLogout());
        },
        errCloseCallback(callback) {
            dispatch(errCloseCallback(callback));
        },
        clearData() {
            dispatch(MenuClearData());
        }
    };
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GAFMCMCF01));

