import React, { Fragment } from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getMessage } from "../../Constants/CommonConstants";
import { MessageMap } from "./GICRMIAI01Message";
import { getNumberText, checkArray } from "../../Components/Tools/StringTools";
import {
    searchInsuranceApplicationDetail,
    searchInsuranceApplicationDetailReset,
    insuranceDownloadFile,
    insuranceDownloadFileReset
} from "./GICRMIAI01Action";
import "../../Css/Common.css";

/**
 * 保険申込履歴画面
 */
class GICRMIAI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            apply_date_start: "",
            apply_date_end: "",
            file_name: "",
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.searchHandle();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * 保険申込履歴検索
     */
    searchHandle = async () => {
        window.scrollTo(0, 0);
        await this.props.insuranceApplicationDetailReset();
        // メッセージ初期化
        this.setState({
            err_msg: []
        }, () => {
            // システムエラーのコールバック設定
            this.setErrorCallback(this.props.insuranceApplicationDetailReset);
            this.props.insuranceApplicationDetailInfo(this.state);
        });
    }

    /**
     * ダウンロードファイル
     */
    HandleDownloadFile = (type, item) => event => {
        event.preventDefault();
        event.stopPropagation();

        // ファイル名
        const fileName = type === 1 ? item.report_filename : item.df_filename;
        this.setState({ 'file_name': fileName });

        let downloadParam = {};
        // ボタンタイプ
        downloadParam['button_type'] = type;
        // ファイル名
        downloadParam['file_name'] = fileName;
        // ログインユーザの業種ID
        downloadParam['industry_id'] = item.industry_id;
        // 保険の種類
        downloadParam['insurance_kind'] = item.insurance_kind;
        // 申込年月
        downloadParam['apply_date'] = item.apply_date;

        this.props.downloadFile(downloadParam);
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    inputChangeHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    render() {

        // ファイルダウンロード処理
        if (this.props.download_data && this.props.download_err_type === '200') {
            const blob = new Blob([this.props.download_data], {
                type: this.props.download_data.type
            });

            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, this.state.file_name);
            } else {
                saveAs(blob, this.state.file_name);
            }

            this.props.downloadFileReset();
        }

        // 件数が100件を超える場合
        if (this.props.insurance_data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">保険申込履歴</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>保険申込履歴</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.download_err_type} err_detail={this.props.download_err_detail} messageMap={MessageMap} />
                                {
                                    (
                                        () => {
                                            return (
                                                this.state.err_msg.length
                                                    ?
                                                    <div id='errorarea'>
                                                        <ul className='errorArea'>
                                                            {
                                                                Object.entries(this.state.err_msg).map(
                                                                    ([key]) => (
                                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                                    )
                                                                )
                                                            }
                                                        </ul>
                                                    </div>
                                                    : null
                                            );
                                        }
                                    )()
                                }
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={this.toggleClassAction}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>申込年月</th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb1Rem">
                                                            <TextInput type="month" name="apply_date_start" className="el_input el_input__datepicker"
                                                                setValue={this.state.apply_date_start} onHandleChange={(k, v) => this.inputChangeHandler(k, v)} placeholder="年/月" />
                                                            <span className="hp_ml10 hp_mr10">〜</span>
                                                            <TextInput type="month" name="apply_date_end" className="el_input el_input__datepicker"
                                                                setValue={this.state.apply_date_end} onHandleChange={(k, v) => this.inputChangeHandler(k, v)} placeholder="年/月" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={this.searchHandle}>検索</button>
                                        </div>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv3Heading"><span>保険申込履歴一覧</span></h2>
                                    </div>
                                    <div className="bl_sect_inner">
                                        <section className="bl_sect bl_sect_applicantList">
                                            <div className="sticky_table_wrapper">
                                                <table className="sticky_table mcTable_align">
                                                    <thead>
                                                        <tr>
                                                            <th>申込年月</th>
                                                            <th>保険の種類</th>
                                                            <th>加入者数</th>
                                                            <th>共済掛金額</th>
                                                            <th>報告書ダウンロード</th>
                                                            <th>対象者一覧ダウンロード</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            if (checkArray(this.props.insurance_data.list)) {
                                                                return (
                                                                    this.props.insurance_data.list.map((item, index) => (
                                                                        <tr key={index}>
                                                                            <td className="hp_tac">{item.apply_date}</td>
                                                                            <td>{item.insurance_name}</td>
                                                                            <td className="hp_tar">{getNumberText(item.subscriber)}名</td>
                                                                            <td className="hp_tar">{getNumberText(item.mutual_aid_premium)}円</td>
                                                                            <td className="hp_tac">
                                                                                <button type="button" className="el_smBlueBtn el_shadowBtn" onClick={this.HandleDownloadFile(1, item)}>報告書</button>
                                                                            </td>
                                                                            <td className="hp_tac">
                                                                                <button type="button" className="el_smBlueBtn el_shadowBtn" onClick={this.HandleDownloadFile(2, item)}>対象者一覧</button>
                                                                            </td>
                                                                        </tr>
                                                                    ))
                                                                );
                                                            }
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.searchInsuranceApplicationDetail.isFetching,
        insurance_data: state.searchInsuranceApplicationDetail.data,    // 検索
        isError: state.searchInsuranceApplicationDetail.isError,
        err_type: state.searchInsuranceApplicationDetail.err_type,
        err_detail: state.searchInsuranceApplicationDetail.err_detail,
        download_data: state.insuranceDownloadFile.data,                // ウンロードファイル
        download_err_type: state.insuranceDownloadFile.err_type,
        download_err_detail: state.insuranceDownloadFile.err_detail
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        insuranceApplicationDetailInfo: (arg) => dispatch(searchInsuranceApplicationDetail(arg)),
        insuranceApplicationDetailReset: () => dispatch(searchInsuranceApplicationDetailReset()),
        downloadFile: (arg) => dispatch(insuranceDownloadFile(arg)),
        downloadFileReset: () => dispatch(insuranceDownloadFileReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GICRMIAI01);
