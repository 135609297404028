/**
 * Action Typesを定義
 */
const GESSFQUS01ActionType = {
    GET_OKR_UNI_USAGE_RESULTS_DETAIL_REQUEST: 'GET_OKR_UNI_USAGE_RESULTS_DETAIL_REQUEST',  // 送り出し機関・大学アンケート照会取得定義
    GET_OKR_UNI_USAGE_RESULTS_DETAIL_SUCCESS: 'GET_OKR_UNI_USAGE_RESULTS_DETAIL_SUCCESS',  // 送り出し機関・大学アンケート照会取得成功定義
    GET_OKR_UNI_USAGE_RESULTS_DETAIL_FAILURE: 'GET_OKR_UNI_USAGE_RESULTS_DETAIL_FAILURE',  // 送り出し機関・大学アンケート照会取得失敗定義
    GET_OKR_UNI_USAGE_RESULTS_DETAIL_RESET: 'GET_OKR_UNI_USAGE_RESULTS_DETAIL_RESET',      // 送り出し機関・大学アンケート照会取得リセット定義
};

export default GESSFQUS01ActionType;