import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import Intern_Icon from "../../Images/icon_intern.svg";
import "../../Css/Common.css";

/**
 * インターン案件募集要項登録（完了）
 */
class GRSMMIMR08 extends BaseUIComponent {

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.props.getCaseDetailReset();
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    render() {
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">インターン管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a></li>
                                <li>インターン登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">インターン登録</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">条件・業務</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">待遇</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">施設紹介</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">周辺情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a className="bl_stepbar_link">サポート<br />サービス</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>登録が完了しました</span></h3>
                                        </div>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_blueBtn" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧へ</button>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

export default GRSMMIMR08;

