import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GESSFCES01ActionType";

// 評価詳細取得API
const GET_EVALUATION_RESULTS_DETAIL_POST = BASE_URL + "/api/es_sf_get_evaluation_results_detail";
// 評価詳細更新API
const UPDATE_EVALUATION_RESULTS_DETAIL_POST = BASE_URL + "/api/es_sf_update_evaluation_results_detail";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetEvaluationResultsDetailRequest = () => {
    return {
        type: actionTypes.GET_EVALUATION_RESULTS_DETAIL_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetEvaluationResultsDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_EVALUATION_RESULTS_DETAIL_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetEvaluationResultsDetailFailure = (err) => {
    return {
        type: actionTypes.GET_EVALUATION_RESULTS_DETAIL_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetEvaluationResultsDetailReset = () => {
    return {
        type: actionTypes.GET_EVALUATION_RESULTS_DETAIL_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateEvaluationResultsDetailRequest = () => {
    return {
        type: actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateEvaluationResultsDetailSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateEvaluationResultsDetailFailure = (err) => {
    return {
        type: actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateEvaluationResultsDetailReset = () => {
    return {
        type: actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getEvaluationResultsDetail = (data) => {
    return async (dispatch) => {
        dispatch(GetEvaluationResultsDetailRequest());
        ecoPostWithAuthHeader(GET_EVALUATION_RESULTS_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(GetEvaluationResultsDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetEvaluationResultsDetailFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateEvaluationResultsDetail = (data) => {
    return async (dispatch) => {
        dispatch(UpdateEvaluationResultsDetailRequest());
        ecoPostWithAuthHeader(UPDATE_EVALUATION_RESULTS_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateEvaluationResultsDetailSuccess(res.data))
        ).catch(
            err => dispatch(UpdateEvaluationResultsDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getEvaluationResultsDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetEvaluationResultsDetailReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateEvaluationResultsDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateEvaluationResultsDetailReset());
    };
};
