import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { MessageMap } from "./GRSMMIMR01Message";
import { createCase, createCaseReset } from "../MmGRSMMIMR01/GRSMMIMR01Action";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import Intern_Icon from "../../Images/icon_intern.svg";
import { getMessage } from "../../Constants/CommonConstants";
import { getNumberText, isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * インターン案件募集要項登録（募集手続）
 */
class GRSMMIMR01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            case_title: "",
            applicant: "",
            accept_period_start: "",
            accept_period_end: "",
            pub_period_start: "",
            pub_period_end: "",
            isDisabled: false,
            caseOutline: this.props.caseOutline,
            caseDetail: this.props.caseDetail
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { caseDetail } = this.state;
        caseDetail.applicant = getNumberText(caseDetail.applicant);
        this.setState({
            case_title: caseDetail.case_title,
            applicant: caseDetail.applicant,
            accept_period_start: caseDetail.accept_period_start,
            accept_period_end: caseDetail.accept_period_end,
            pub_period_start: caseDetail.pub_period_start,
            pub_period_end: caseDetail.pub_period_end,
            caseDetail: caseDetail
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '2' && caseDetail.sys_date < caseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });
        window.scrollTo(0, 0);
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createCaseReset();
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let caseDetailData = this.state.caseDetail;
        if (caseDetailData.case_id == '' || caseDetailData.case_id == null) {
            if (this.props.data && this.props.data.case_id != null) {
                caseDetailData.case_id = this.props.data.case_id;
            }
        }
        caseDetailData.temp_save = '1';
        this.setState({
            caseDetail: caseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.caseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createCase(reqData);
            }
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            caseDetail: {
                ...prevState.caseDetail,
                [name]: value
            }
        }));
    }

    render() {
        const { caseDetail, isDisabled } = this.state;
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.caseDetail.version_no != this.props.data.version_no) {
                let caseDetailData = this.state.caseDetail;
                caseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    caseDetail: caseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                インターン管理
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#1" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>インターン登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">インターン登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item is_current">
                                                <span>募集手続</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>会社情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>条件・業務</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>待遇</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })} tabIndex="10">案件一覧へ</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR02", this.state.caseOutline, this.state.caseDetail)} tabIndex="9">次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" /></span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>募集手続</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        インターン名
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <span className="el_form_txt">（記入例）○○ホテル　2021年春　インターン</span>
                                                            <TextInput type="text" name="case_title" className="el_input el_input__max" maxlength="40" required="required"
                                                                setValue={caseDetail.case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        人数
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="applicant" className="el_input el_input__sm text_right_align" inputmode="decimal" maxlength="4" required="required"
                                                                setValue={caseDetail.applicant} numberFormat="true" onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                            <span className="el_form_txt">名</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        受入希望開始
                                                    </th>
                                                    <td>
                                                        <TextInput type="date" name="accept_period_start" className="el_input el_input__datepicker" maxlength="10" required="required"
                                                            setValue={caseDetail.accept_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" placeholder="年/月/日" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        受入希望終了
                                                    </th>
                                                    <td>
                                                        <TextInput type="date" name="accept_period_end" className="el_input el_input__datepicker" maxlength="10" required="required"
                                                            setValue={caseDetail.accept_period_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" placeholder="年/月/日" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        募集開始
                                                    </th>
                                                    <td>
                                                        <TextInput type="date" name="pub_period_start" className="el_input el_input__datepicker" maxlength="10" required="required"
                                                            setValue={caseDetail.pub_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" placeholder="年/月/日" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        募集終了
                                                    </th>
                                                    <td>
                                                        <TextInput type="date" name="pub_period_end" className="el_input el_input__datepicker" maxlength="10" required="required"
                                                            setValue={caseDetail.pub_period_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" placeholder="年/月/日" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} disabled={this.state.isDisabled} tabIndex="7">途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR02", this.state.caseOutline, this.state.caseDetail)} tabIndex="8">次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createCase.isFetching,
        data: state.createCase.caseData,
        isError: state.createCase.isError,
        err_type: state.createCase.err_type,
        err_detail: state.createCase.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createCase(condition) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCase(condition));
        },
        createCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMIMR01);

