/**
 * Action Typesを定義
 */
const GCMOMORU03ActionType = {
    GET_CHANGE_ORG_INFO_REQUEST: 'GET_CHANGE_ORG_INFO_REQUEST',  // 組織変更取得定義
    GET_CHANGE_ORG_INFO_SUCCESS: 'GET_CHANGE_ORG_INFO_SUCCESS',  // 組織変更取得成功定義
    GET_CHANGE_ORG_INFO_FAILURE: 'GET_CHANGE_ORG_INFO_FAILURE',  // 組織変更取得失敗定義
    GET_CHANGE_ORG_INFO_RESET: 'GET_CHANGE_ORG_INFO_RESET',       // 組織変更取得リセット定義
    UPDATE_CHANGE_ORG_INFO_REQUEST: 'UPDATE_CHANGE_ORG_INFO_REQUEST',  // 組織変更更新定義
    UPDATE_CHANGE_ORG_INFO_SUCCESS: 'UPDATE_CHANGE_ORG_INFO_SUCCESS',  // 組織変更更新成功定義
    UPDATE_CHANGE_ORG_INFO_FAILURE: 'UPDATE_CHANGE_ORG_INFO_FAILURE',  // 組織変更更新失敗定義
    UPDATE_CHANGE_ORG_INFO_RESET: 'UPDATE_CHANGE_ORG_INFO_RESET'       // 組織変更更新リセット定義
};

export default GCMOMORU03ActionType;