import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMRMR01ActionType';

// 求人案件登録・更新API
const CREATE_RECRUITMENT_CASE_POST = BASE_URL + "/api/rs_mm_update_recruitment_case_info";

// ********************Actionの作成 Start ********************

/**
 * 登録リクエスト
 */
export const CreateRecruitmentCaseRequest = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const CreateRecruitmentCaseSuccess = (data) => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const CreateRecruitmentCaseFailure = (err) => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const CreateRecruitmentCaseReset = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_RESET
    };
};

/**
 * 登録リセット(データも合わせリセットする)
 */
export const CreateRecruitmentCaseResetAllData = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_RESET_ALL_DATA
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const createRecruitmentCase = (data) => {

    return async (dispatch) => {
        dispatch(CreateRecruitmentCaseRequest());
        ecoPostWithAuthHeader(CREATE_RECRUITMENT_CASE_POST, JSON.stringify(data)).then(
            res => dispatch(CreateRecruitmentCaseSuccess(res.data))
        ).catch(
            err => dispatch(CreateRecruitmentCaseFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const createRecruitmentCaseReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(CreateRecruitmentCaseReset());
    };
};