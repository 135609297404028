import React from 'react';
import EsTemplate from "./template";

function EsContainer() {
    return (
        <section className="App">
            <EsTemplate />
        </section>
    );
}

export default EsContainer;
