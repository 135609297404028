import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import { saveOrganizationManagement, saveOrganizationManagementReset } from "./GCMOMOIR02Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { MessageMap } from "./GCMOMORU02Message";
import TextInput from "../../Components/Atoms/TextInput";
import Minus_Icon from "../../Images/btn_minus.svg";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";


/**
 * 組織情報登録・訂正
 */
class GCMOMOIR02 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            countryOpt: [],
            companyMembershipArray: [],
            universityMembershipArray: [],
            industry_id: "",
            membership_id: "",
            membership_name: "",
            version_no: isBlank(this.props.detailData.organization_id) ? '' : this.props.detailData.version_no
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { detailData } = this.props;

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
            country_code: detailData.country_code,
            country_name: detailData.country_name,
            organization_type: detailData.organization_type,
            organization_id: detailData.organization_id,
            organization_name: detailData.organization_name
        }), () => {
            // 処理区分 = "新規" の場合
            if (isBlank(detailData.organization_id)) {
                if (this.state.organization_type === 'COP') {
                    this.setState({
                        companyMembershipArray: [{ "membership_id": "", "membership_name": "" }],
                        membership_name_list: detailData.cop_membership
                    });
                } else if (this.state.organization_type === 'UNI') {
                    this.setState({
                        universityMembershipArray: [],
                        membership_name_list: detailData.uni_membership
                    });
                }
            } else {
                if (this.state.organization_type === 'COP') {
                    this.setState({
                        companyMembershipArray: detailData.membership_name_list,
                        membership_name_list: detailData.cop_membership
                    });
                } else if (this.state.organization_type === 'UNI') {
                    this.setState({
                        universityMembershipArray: detailData.membership_name_list,
                        membership_name_list: detailData.uni_membership
                    });
                }
            }
            this.setState({
                industry_id: detailData.industry_id
            });
        });
        window.scrollTo(0, 0);
    }

    /**
     * 国変更
     */
    handleCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.country_name === '') {
                this.setState({
                    country_code: ''
                }, () => {
                    this.editItemHandler("country_code", this.state.country_code);
                    this.editItemHandler("country_name", this.state.country_name);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                if (country.country_name === this.state.country_name) {
                    this.setState({
                        country_code: country.country_code,
                    }, () => {
                        this.editItemHandler("country_code", this.state.country_code);
                        this.editItemHandler("country_name", this.state.country_name);
                    });
                }
            });
        });
    };

    /**
     * 組織タイプ変更
     */
    handleOrganizationTypeChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            // 組織タイプ = "企業" の場合
            if (value === 'COP') {
                this.setState({
                    companyMembershipArray: [{ "membership_id": "", "membership_name": "" }],
                    membership_name_list: this.props.detailData.cop_membership
                }, () => {
                    this.editItemHandler("companyMembershipArray", this.state.companyMembershipArray);
                    this.editItemHandler("membership_name_list", this.state.membership_name_list);
                });
            } else if (value === 'UNI') {
                // 組織タイプ = "大学" の場合
                this.setState({
                    universityMembershipArray: [{ "membership_id": "", "membership_name": "" }],
                    membership_name_list: this.props.detailData.uni_membership
                }, () => {
                    this.editItemHandler("universityMembershipArray", this.state.universityMembershipArray);
                    this.editItemHandler("membership_name_list", this.state.membership_name_list);
                });
            }
            this.editItemHandler(name, value);
        });
    };

    /**
     * 所属団体変更
     */
    handleChange = event => {
        const { name, value } = event.target;
        const { membership_name_list } = this.state;

        this.setState({ [name]: value });

        if (isBlank(value)) {
            this.setState({
                membership_id: ""
            }, () => {
                this.editItemHandler("membership_id", this.state.membership_id);
            });
        } else {
            membership_name_list.map((item) => {
                if (value === item.membership_name) {
                    this.setState({
                        membership_id: item.membership_id
                    }, () => {
                        this.editItemHandler("membership_id", this.state.membership_id);
                    });
                }
            });
        }

    };

    /**
     * 所属団体変更
     */
    handleMembershipChange = index => (event) => {
        const { name, value } = event.target;
        const { organization_type, membership_name_list } = this.state;

        checkArray(membership_name_list) && membership_name_list.map((item) => {
            if (value === item.membership_name) {
                this.setState({
                    membership_id: item.membership_id
                }, () => {
                    if (organization_type === 'UNI') {
                        this.setState({
                            universityMembershipArray: this.state.universityMembershipArray.map((data, i) =>
                                i === index ? { ...data, [name]: value, "membership_id": this.state.membership_id } : data
                            )
                        }, () => {
                            this.editItemHandler("membership_id", this.state.membership_id);
                            this.editItemHandler('universityMembershipArray', this.state.universityMembershipArray);
                        });
                    } else {
                        this.setState({
                            companyMembershipArray: this.state.companyMembershipArray.map((data, i) =>
                                i === index ? { ...data, [name]: value, "membership_id": this.state.membership_id } : data
                            )
                        }, () => {
                            this.editItemHandler("membership_id", this.state.membership_id);
                            this.editItemHandler('companyMembershipArray', this.state.companyMembershipArray);
                        });
                    }

                });
            } else if (isBlank(value)) {
                this.setState({
                    membership_id: value
                }, () => {
                    if (organization_type === 'UNI') {
                        this.setState({
                            universityMembershipArray: this.state.universityMembershipArray.map((data, i) =>
                                i === index ? { ...data, [name]: value, "membership_id": this.state.membership_id } : data
                            )
                        }, () => {
                            this.editItemHandler("membership_id", this.state.membership_id);
                            this.editItemHandler('universityMembershipArray', this.state.universityMembershipArray);
                        });
                    } else {
                        this.setState({
                            companyMembershipArray: this.state.companyMembershipArray.map((data, i) =>
                                i === index ? { ...data, [name]: value, "membership_id": this.state.membership_id } : data
                            )
                        }, () => {
                            this.editItemHandler("membership_id", this.state.membership_id);
                            this.editItemHandler('companyMembershipArray', this.state.companyMembershipArray);
                        });
                    }
                });
            }
        });
    };

    /**
     * 所属団体を追加する
     */
    addMembership = () => {
        if (this.state.organization_type === 'UNI') {
            this.setState(prevState => ({
                universityMembershipArray: [...prevState.universityMembershipArray, { "membership_id": "", "membership_name": "" }]
            }));
        } else if (this.state.organization_type === 'COP') {
            this.setState(prevState => ({
                companyMembershipArray: [...prevState.companyMembershipArray, { "membership_id": "", "membership_name": "" }]
            }));
        }
    }

    /**
     * 所属団体を削除する
     * @param {*} index
     */
    deleteMembership = () => {
        if (this.state.organization_type === 'UNI') {
            let array = [...this.state.universityMembershipArray];
            array.splice(this.state.universityMembershipArray.length - 1, 1);

            this.setState({
                universityMembershipArray: array
            });
        } else if (this.state.organization_type === 'COP') {
            let array = [...this.state.companyMembershipArray];
            array.splice(this.state.companyMembershipArray.length - 1, 1);

            this.setState({
                companyMembershipArray: array
            });
        }

    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.saveOrganizationManagementReset();
    }

    /**
     * 登録
     */
    saveOrganizationInfo = () => {
        window.scrollTo(0, 0);
        const { organization_id, organization_type, universityMembershipArray, companyMembershipArray, industry_id } = this.state;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 処理区分 1:新規　2：更新
        const processDivision = isBlank(organization_id) ? '1' : '2';
        let newVal = [];
        // 組織タイプ = "大学" の場合
        if (organization_type === 'UNI') {
            if (checkArray(universityMembershipArray)) {
                newVal = universityMembershipArray.map(data => {
                    if (isBlank(data.membership_id)) {
                        return { membership_id: '' };
                    } else {
                        return { membership_id: data.membership_id };
                    }
                });
            } else {
                newVal = [{ membership_id: "" }];
            }
        } else if (organization_type === 'COP') { // 組織タイプ = "企業" の場合
            if (checkArray(companyMembershipArray)) {
                newVal = companyMembershipArray.map(data => {
                    if (isBlank(data.membership_id)) {
                        return { membership_id: '' };
                    } else {
                        return { membership_id: data.membership_id };
                    }
                });
            } else {
                newVal = [{ membership_id: "" }];
            }
        }

        this.setState({
            process_division: processDivision,
            membership: newVal,
            industry_id: industry_id
        }, () => {
            this.props.saveOrganizationManagement(this.state);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.saveOrganizationManagementReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * 削除ボタン活性処理
     */
    delBtnDisabled = () => {
        if (this.state.organization_type === 'UNI' && this.state.universityMembershipArray.length > 1) {
            return false
        } else if (this.state.organization_type === 'COP' && this.state.companyMembershipArray.length > 1) {
            return false
        }
        return true
    };

    render() {
        const { detailData } = this.props;
        const { organization_id, organization_type, membership_name_list, companyMembershipArray, universityMembershipArray } = this.state;

        // 組織タイプ
        const organizationType = getCodeValueLabelList('RSC0030').filter(v => v.value != 'COD');

        // 活性、非活性項目
        const cssDisabled = isBlank(organization_id) ? '' : ' is_disabled';
        const isDisabled = isBlank(organization_id) ? false : true;

        // 表示、非表示項目
        const showOrHide = isBlank(organization_id) ? 'table-row' : 'none';
        const buttonShow = organization_type === 'UNI' || organization_type === 'COP' ? true : false;

        let tabindex = 3;
        if (organization_type === 'UNI') {
            checkArray(universityMembershipArray) && universityMembershipArray.forEach((v, i) => {
                tabindex = 2 + i + 1;
            });
        } else if (organization_type === 'COP') {
            checkArray(companyMembershipArray) && companyMembershipArray.forEach((v, i) => {
                tabindex = 2 + i + 1;
            });
        }

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.version_no) && this.state.version_no !== this.props.data.version_no) {
                this.editItemHandler('version_no', this.props.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.setState({
                        organization_id: this.props.data.organization_id
                    }, () => {
                        this.editItemHandler('organization_id', this.state.organization_id);
                    });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織情報登録・訂正</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GCMOMOIR01", true); }, () => { })}>組織管理</a></li>
                                <li>組織情報登録・訂正​</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">組織情報​</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />組織タイプ</th>
                                                    <td>
                                                        <select className={"el_select".concat(cssDisabled)} name="organization_type" value={this.state.organization_type} onChange={this.handleOrganizationTypeChange} disabled={isDisabled} tabIndex="1">
                                                            {
                                                                checkArray(organizationType) && organizationType.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.organization_type === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />国</th>
                                                    <td>
                                                        <select className="el_select" name="country_name" value={this.state.country_name} onChange={this.handleCountryNameChange} tabIndex="2">
                                                            <option value="" selected></option>
                                                            {
                                                                checkArray(this.state.countryOpt) && this.state.countryOpt.map(item => (
                                                                    <option key={item.country_code} value={item.country_name} selected={this.state.country_name == item.country_name}>
                                                                        {item.country_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: (['UNI', 'COP'].includes(this.state.organization_type)) ? 'table-row' : 'none' }}>
                                                    <th>所属団体</th>
                                                    <td>
                                                        {(() => {
                                                            if (isBlank(detailData.organization_id)) {
                                                                if (organization_type === 'UNI') {
                                                                    return (
                                                                        checkArray(universityMembershipArray) && universityMembershipArray.map((el, index) => {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <select className="el_select" name="membership_name" value={el.membership_name} onChange={this.handleMembershipChange(index)} tabIndex={tabindex}>
                                                                                        <option value="" selected></option>
                                                                                        {
                                                                                            checkArray(membership_name_list) && membership_name_list.map((item) => (
                                                                                                <option key={item.membership_id} value={item.membership_name} selected={this.state.membership_id === item.membership_id}>
                                                                                                    {item.membership_name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    <br />
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    );
                                                                } else if (organization_type === 'COP') {
                                                                    return (
                                                                        checkArray(companyMembershipArray) && companyMembershipArray.map((el, index) => {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <select className="el_select" name="membership_name" value={el.membership_name} onChange={this.handleMembershipChange(index)} tabIndex={tabindex}>
                                                                                        <option value="" selected></option>
                                                                                        {
                                                                                            checkArray(membership_name_list) && membership_name_list.map((item) => (
                                                                                                <option key={item.membership_id} value={item.membership_name} selected={this.state.membership_id === item.membership_id}>
                                                                                                    {item.membership_name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    <br />
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <select className="el_select" name="membership_name" value={this.state.membership_name} onChange={this.handleChange} tabIndex={tabindex}>
                                                                            <option value="" selected></option>
                                                                            {
                                                                                checkArray(membership_name_list) && membership_name_list.map((item) => (
                                                                                    <option key={item.membership_id} value={item.membership_name} selected={this.state.membership_id === item.membership_id}>
                                                                                        {item.membership_name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    );
                                                                }
                                                            } else {
                                                                if (organization_type === 'COP') {
                                                                    return (
                                                                        checkArray(companyMembershipArray) && companyMembershipArray.map((el, index) => {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <select className="el_select" name="membership_name" value={el.membership_name} onChange={this.handleMembershipChange(index)} tabIndex={tabindex}>
                                                                                        <option value="" selected></option>
                                                                                        {
                                                                                            checkArray(membership_name_list) && membership_name_list.map((item) => (
                                                                                                <option key={item.membership_id} value={item.membership_name} selected={this.state.membership_id === item.membership_id}>
                                                                                                    {item.membership_name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    <br />
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    );
                                                                } else {
                                                                    return (
                                                                        checkArray(universityMembershipArray) && universityMembershipArray.map((el, index) => {
                                                                            return (
                                                                                <Fragment key={index}>
                                                                                    <select className="el_select" name="membership_name" value={el.membership_name} onChange={this.handleMembershipChange(index)} tabIndex={tabindex}>
                                                                                        <option value="" selected></option>
                                                                                        {
                                                                                            checkArray(membership_name_list) && membership_name_list.map((item) => (
                                                                                                <option key={item.membership_id} value={item.membership_name} selected={this.state.membership_id === item.membership_id}>
                                                                                                    {item.membership_name}
                                                                                                </option>
                                                                                            ))
                                                                                        }
                                                                                    </select>
                                                                                    <br />
                                                                                </Fragment>
                                                                            );
                                                                        })
                                                                    );
                                                                }
                                                            }
                                                        })()}
                                                        {(() => {
                                                            if (organization_type === 'UNI') {
                                                                return (
                                                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__l">
                                                                        <button type="button" value="-" className="el_funcBtn" onClick={() => this.deleteMembership()} style={{ display: buttonShow ? '' : 'none' }} disabled={this.delBtnDisabled()} tabIndex={tabindex + 1}>
                                                                            <img style={{ opacity: universityMembershipArray.length <= 1 ? 0.5 : 1.0 }} src={Minus_Icon} alt="マイナス" />削除
                                                                        </button>
                                                                        <button type="button" value="+" className="el_funcBtn" onClick={() => this.addMembership()} style={{ display: buttonShow ? '' : 'none' }} tabIndex={tabindex + 2}>
                                                                            <img src={Plus_Icon} alt="プラス" />追加
                                                                        </button>
                                                                    </div>
                                                                );
                                                            }
                                                            if (organization_type === 'COP') {
                                                                return (
                                                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__l">
                                                                        <button type="button" value="-" className="el_funcBtn" onClick={() => this.deleteMembership()} style={{ display: buttonShow ? '' : 'none' }} disabled={this.delBtnDisabled()} tabIndex={tabindex + 1}>
                                                                            <img style={{ opacity: companyMembershipArray.length <= 1 ? 0.5 : 1.0 }} src={Minus_Icon} alt="マイナス" />削除
                                                                        </button>
                                                                        <button type="button" value="+" className="el_funcBtn" onClick={() => this.addMembership()} style={{ display: buttonShow ? '' : 'none' }} tabIndex={tabindex + 2}>
                                                                            <img src={Plus_Icon} alt="プラス" />追加
                                                                        </button>
                                                                    </div>
                                                                );
                                                            }
                                                        })()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />組織名</th>
                                                    <td>
                                                        <TextInput type="text" name="organization_name" className={"el_input el_input__max".concat(cssDisabled)} maxlength="60" setValue={this.state.organization_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} readOnly={isDisabled} tabIndex={tabindex + 3} />
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />ユーザ名</th>
                                                    <td>
                                                        <TextInput type="text" name="user_name" className="el_input el_input__max" maxlength="60" setValue={this.state.user_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 4} />
                                                    </td>
                                                </tr>
                                                <tr style={{ display: showOrHide }}>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />メールアドレス</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="mail_address" className="el_input el_input__max" maxlength="81" setValue={this.state.mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 5} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_backBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GCMOMOIR01", true); }, () => { })} tabIndex={tabindex + 6} >組織管理へ​</button>
                                        <button type="button" className="el_btn" onClick={() => this.saveOrganizationInfo()} tabIndex={tabindex + 7} >登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.saveOrganizationManagement.isFetching,
        data: state.saveOrganizationManagement.data,
        isError: state.saveOrganizationManagement.isError,
        err_type: state.saveOrganizationManagement.err_type,
        err_detail: state.saveOrganizationManagement.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveOrganizationManagement(args) {
            // 組織情報​更新
            dispatch(saveOrganizationManagement(args));
        },
        saveOrganizationManagementReset() {
            dispatch(saveOrganizationManagementReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMOIR02);

