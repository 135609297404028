import actionTypes from './GAFMCMCF01ActionType';

/**
 * ローディング画面表示
 */
export const MenuLoadingShow = () => {
    return {
        type: actionTypes.MENU_LOADING_SHOW
    };
};

/**
 * ローディング画面非表示
 */
export const MenuLoadingClose = () => {
    return {
        type: actionTypes.MENU_LOADING_CLOSE
    };
};

/**
 * エラー画面表示
 */
export const MenuErrorShow = () => {
    return {
        type: actionTypes.MENU_ERROR_SHOW
    };
};

/**
 * エラー画面非表示
 */
export const MenuErrorClose = () => {
    return {
        type: actionTypes.MENU_ERROR_CLOSE
    };
};

/**
 * エラー画面閉じるのコールバック設定
 */
export const MenuErrorShowCallback = (callBack) => {
    return {
        type: actionTypes.MENU_ERROR_CLOSE_CALLBACK,
        callBack: callBack
    };
};

/**
 * ユーザログアウト
 */
export const MenuUserLogout = () => {
    return {
        type: actionTypes.MENU_USER_LOGOUT
    };
};

/**
 * ユーザデータクリア
 */
export const MenuClearData = () => {
    return {
        type: actionTypes.MENU_CLEAR_DATA
    };
};

/**
 * ローディング画面表示制御Action
 */
export const showLoading = (show) => {
    return async (dispatch) => {
        if (show) {
            dispatch(MenuLoadingShow());
        } else {
            dispatch(MenuLoadingClose());
        }
    };
};

/**
 * エラー画面表示制御Action
 */
export const showError = (show) => {
    return async (dispatch) => {
        if (show) {
            dispatch(MenuErrorShow());
        } else {
            dispatch(MenuErrorClose());
        }
    };
};

/**
 * エラー画面閉じるのコールバック設定Action
 */
export const errCloseCallback = (callback) => {
    return async (dispatch) => {
        dispatch(MenuErrorShowCallback(callback));
    };
};
