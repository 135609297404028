
// 実習計画テンプレート登録情報
export let TRAINING_PLAN_TEMPLATE_DATA = {
    "plan_template_id": "",
    "plan_template_name": "",
    "plan_detail_number": "",
    "work_type": "",
    "work_name": "",
    "work_contents": "",
    "hours_1": "",
    "hours_2": "",
    "hours_3": "",
    "hours_4": "",
    "hours_5": "",
    "hours_6": "",
    "hours_7": "",
    "hours_8": "",
    "hours_9": "",
    "hours_10": "",
    "hours_11": "",
    "hours_12": "",
    "version_no": ""
};


