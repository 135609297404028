import React from 'react';
import IpTemplate from "./template";

function IpContainer() {
    return (
        <section className="App">
            <IpTemplate />
        </section>
    );
}

export default IpContainer;
