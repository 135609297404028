import React, { Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { getPersonalInfo, updatePersonalInfo } from "./GAFLFLAF02Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Textarea from "../../Components/Atoms/Textarea";
import Load from "../../Components/Atoms/Load";
import ModalMessage from "../../Components/Atoms/ModalMessage";
import { getMessage } from "../../Constants/CommonConstants";
import Service_Icon_PNG from "../../Images/service_logo.png";
import "../../Css/Common.css";
import "../../Css/GAFLFLAF02.css";
import { isBlank } from "../../Components/Tools/StringTools";

/**
 * 個人情報取り扱い同意取得
 */
class GAFLFLAF02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isloading: false,
            err_msg: [],
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.props.getPersonalInfo({});
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替処理
     */
    redirectToNextPage = () => {
        // ログイン画面に移動
        this.props.redirectToNextPage("GAFLFLAF01");
    }
    render() {

        if (this.props.personalInfoResult.err_type == '200') {
            // 同意の場合、ホーム画面へ遷移する
            this.props.history.push('/af/home');
        }

        return (
            <Fragment>
                <header className="ly_header">
                    <p className="el_headerLogo">
                        <img src={Service_Icon_PNG} alt="service Logo" />
                    </p>
                </header>
                {this.props.personalInfoResult.isError ? <ModalMessage type={9} okClick={this.redirectToNextPage} /> : null}
                {this.props.personalInfo.isError ? <ModalMessage type={9} okClick={this.redirectToNextPage} /> : null}
                {this.props.personalInfoResult.isFetching ? <Load /> : null}
                {this.props.personalInfo.isFetching ? <Load /> : <div className="ly_mainCont">
                    <section className="bl_sect">
                        <div className="ly_mainCont_inner">
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">
                                    <div className="bl_sect_header bl_sect_header__info">
                                        <h2 className="el_lv3Heading">
                                            <span>{this.props.personalInfo.data.p_title}</span>
                                        </h2>
                                    </div>
                                    <div className="bl_infoBody">
                                        {isBlank(this.props.personalInfo.data.p_description) ? null : this.props.personalInfo.data.p_description.split('\n').map((str, index) => (
                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <br></br>
                                <div className="bl_formUnit">
                                    <Textarea className={"el_textarea_GAFLFLAF02"} disabled={true} rows="20" cols="200" name="p_text" setValue={this.props.personalInfo.data.p_text} >
                                    </Textarea>
                                </div>
                            </section>
                            <section className="bl_sect">
                                <div className="bl_horizBtnUnit">
                                    <button tabIndex="1" type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => { window.scrollTo(0, 0); this.props.updatePersonalInfo({}) }}>{this.props.personalInfo.data.p_agree_btn}</button>
                                    <button tabIndex="2" type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.redirectToNextPage()}>{this.props.personalInfo.data.p_disagree_btn}</button>
                                </div>
                            </section>
                        </div>
                    </section>
                </div>}

            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        personalInfo: state.getPersonalInfo,
        personalInfoResult: state.updatePersonalInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getPersonalInfo(args) {
            // 個人情報取り扱い同意取得
            dispatch(getPersonalInfo(args));
        }, updatePersonalInfo(args) {
            // 個人情報取り扱い同意
            dispatch(updatePersonalInfo(args));
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GAFLFLAF02));

