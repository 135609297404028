import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GICIAIAS01ActionType';

// 保険申込一覧検索API
const INSURANCE_APPLICATION_LIST_POST = BASE_URL + "/api/ic_ia_get_insurance_target_list";
// 保険申込更新API
const INSURANCE_APPLICATION_UPDATE_POST = BASE_URL + "/api/ic_ia_update_insurance_target_info";

// ********************Actionの作成 Start ********************

/**
 * 検索リクエスト
 */
export const searchInsuranceApplicationListRequest = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_LIST_REQUEST
    };
};

/**
 * 検索成功
 * @param {*} data
 */
export const searchInsuranceApplicationListSuccess = (data) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_LIST_SUCCESS,
        data
    };
};

/**
 * 検索失敗
 * @param {*} err
 */
export const searchInsuranceApplicationListFailure = (err) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_LIST_FAILURE,
        err
    };
};

/**
 * 検索リセット
 */
export const searchInsuranceApplicationListReset = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_LIST_RESET
    };
};

/**
 * リストデータ更新
 */
export const searchInsuranceApplicationListUpdate = (data) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_LIST_UPDATE,
        data
    };
};

/**
 * 更新リクエスト
 */
export const updateInsuranceApplicationListRequest = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_UPDATE_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const updateInsuranceApplicationListSuccess = (data) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_UPDATE_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const updateInsuranceApplicationListFailure = (err) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_UPDATE_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const updateInsuranceApplicationListReset = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_UPDATE_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 検索処理Actionの発行
 * @param {*} data
 */
export const searchInsuranceApplicationList = (data) => {
    return async (dispatch) => {
        // Storeへ保険申込一覧検索リクエストActionをdispatchする
        dispatch(searchInsuranceApplicationListRequest());
        ecoPostWithAuthHeader(INSURANCE_APPLICATION_LIST_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ保険申込一覧検索成功Actionをdispatchする
                dispatch(searchInsuranceApplicationListSuccess(res.data));
            }).catch(err =>
                // Storeへ保険申込一覧検索失敗Actionをdispatchする
                dispatch(searchInsuranceApplicationListFailure(err))
            );
    };
};

/**
 * 更新処理Actionの発行
 * @param {*} data
 */
export const updateInsuranceApplicationList = (data) => {
    return async (dispatch) => {
        // Storeへ保険申込一覧更新リクエストActionをdispatchする
        dispatch(updateInsuranceApplicationListRequest());
        ecoPostWithAuthHeader(INSURANCE_APPLICATION_UPDATE_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ保険申込一覧更新成功Actionをdispatchする
                dispatch(updateInsuranceApplicationListSuccess(res.data));
            }).catch(err =>
                // Storeへ保険申込一覧更新失敗Actionをdispatchする
                dispatch(updateInsuranceApplicationListFailure(err))
            );
    };
};

/**
 * 画面リセットActionの発行
 */
export const insuranceApplicationListReset = () => {
    return async (dispatch) => {
        // Storeへ検索リセットActionをdispatchする
        dispatch(searchInsuranceApplicationListReset());
    };
};

/**
 * 画面リセットActionの発行
 */
export const insuranceApplicationUpdateReset = () => {
    return async (dispatch) => {
        // Storeへ検索リセットActionをdispatchする
        dispatch(updateInsuranceApplicationListReset());
    };
};

