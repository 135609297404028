/**
 * Action Typesを定義
 */
const GIPPMPMF01ActionType = {
    GET_PROGRESS_MANAGEMENT_REQUEST: 'GET_PROGRESS_MANAGEMENT_REQUEST',  // 進捗管理初期取得定義
    GET_PROGRESS_MANAGEMENT_SUCCESS: 'GET_PROGRESS_MANAGEMENT_SUCCESS',  // 進捗管理初期取得成功定義
    GET_PROGRESS_MANAGEMENT_FAILURE: 'GET_PROGRESS_MANAGEMENT_FAILURE',  // 進捗管理初期取得失敗定義
    GET_PROGRESS_MANAGEMENT_RESET: 'GET_PROGRESS_MANAGEMENT_RESET',       // 進捗管理初期取得リセット定義
    SAVE_PROGRESS_MANAGEMENT_REQUEST: 'SAVE_PROGRESS_MANAGEMENT_REQUEST',  // 進捗管理登録定義
    SAVE_PROGRESS_MANAGEMENT_SUCCESS: 'SAVE_PROGRESS_MANAGEMENT_SUCCESS',  // 進捗管理登録成功定義
    SAVE_PROGRESS_MANAGEMENT_FAILURE: 'SAVE_PROGRESS_MANAGEMENT_FAILURE',  // 進捗管理登録失敗定義
    SAVE_PROGRESS_MANAGEMENT_RESET: 'SAVE_PROGRESS_MANAGEMENT_RESET'       // 進捗管理登録リセット定義
};

export default GIPPMPMF01ActionType;