/**
 * Action Typesを定義
 */
const GCMOMOIR01ActionType = {
    GET_ORGANIZATION_MANAGEMENT_REQUEST: 'GET_ORGANIZATION_MANAGEMENT_REQUEST',        // 組織管理取得定義
    GET_ORGANIZATION_MANAGEMENT_SUCCESS: 'GET_ORGANIZATION_MANAGEMENT_SUCCESS',        // 組織管理取得成功定義
    GET_ORGANIZATION_MANAGEMENT_FAILURE: 'GET_ORGANIZATION_MANAGEMENT_FAILURE',        // 組織管理取得失敗定義
    GET_ORGANIZATION_MANAGEMENT_RESET:   'GET_ORGANIZATION_MANAGEMENT_RESET',          // 組織管理取得リセット定義
    UPDATE_ORGANIZATION_MANAGEMENT_REQUEST: 'UPDATE_ORGANIZATION_MANAGEMENT_REQUEST',  // 組織管理更新定義
    UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS: 'UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS',  // 組織管理更新成功定義
    UPDATE_ORGANIZATION_MANAGEMENT_FAILURE: 'UPDATE_ORGANIZATION_MANAGEMENT_FAILURE',  // 組織管理更新失敗定義
    UPDATE_ORGANIZATION_MANAGEMENT_RESET:   'UPDATE_ORGANIZATION_MANAGEMENT_RESET',    // 組織管理更新リセット定義
    DELETE_ORGANIZATION_MANAGEMENT_REQUEST: 'DELETE_ORGANIZATION_MANAGEMENT_REQUEST',  // 組織管理削除定義
    DELETE_ORGANIZATION_MANAGEMENT_SUCCESS: 'DELETE_ORGANIZATION_MANAGEMENT_SUCCESS',  // 組織管理削除成功定義
    DELETE_ORGANIZATION_MANAGEMENT_FAILURE: 'DELETE_ORGANIZATION_MANAGEMENT_FAILURE',  // 組織管理削除失敗定義
    DELETE_ORGANIZATION_MANAGEMENT_RESET:   'DELETE_ORGANIZATION_MANAGEMENT_RESET'     // 組織管理削除リセット定義
};

export default GCMOMOIR01ActionType;