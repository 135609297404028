
// 組織管理情報
export let ORGANIZATION_MANAGEMENT_DATA = {
    "country_code": null,
    "country_name": null,
    "organization_type": "COP",
    "organization_id": null,
    "organization_name": null,
    "organization_type_name": null,
    "user_name": null,
    "mail_address": null
};


// ユーザ管理情報
export let USER_MANAGEMENT_DATA = {
    "user_name": null,
    "user_kind": "",
    "status_of_residence": null,
    "organization_id": null,
    "organization_name": null,
    "user_mail_address": null
};

