import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMORU01ActionType';

// ユーザ管理一覧取得API
const GET_USER_MANAGEMENT_POST = BASE_URL + "/api/cm_om_get_system_user_tbl_info";
// ユーザ管理更新API
const GET_USER_MANAGEMENT_DETAIL_POST = BASE_URL + "/api/cm_om_get_system_user_tbl_detail_info";
// ユーザ管理削除API
const DELETE_USER_MANAGEMENT_POST = BASE_URL + "/api/cm_om_delete_system_user_tbl_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetUserManagementListRequest = () => {
    return {
        type: actionTypes.GET_USER_MANAGEMENT_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetUserManagementListSuccess = (data) => {
    return {
        type: actionTypes.GET_USER_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetUserManagementListFailure = (err) => {
    return {
        type: actionTypes.GET_USER_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetUserManagementListReset = () => {
    return {
        type: actionTypes.GET_USER_MANAGEMENT_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateUserManagementRequest = () => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateUserManagementSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateUserManagementFailure = (err) => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateUserManagementReset = () => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_RESET
    };
};

/**
 * 削除リクエスト
 */
export const DeleteUserManagementRequest = () => {
    return {
        type: actionTypes.DELETE_USER_MANAGEMENT_REQUEST
    };
};

/**
 * 削除成功
 * @param {*} data
 */
export const DeleteUserManagementSuccess = (data) => {
    return {
        type: actionTypes.DELETE_USER_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 削除失敗
 * @param {*} err
 */
export const DeleteUserManagementFailure = (err) => {
    return {
        type: actionTypes.DELETE_USER_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 削除リセット
 */
export const DeleteUserManagementReset = () => {
    return {
        type: actionTypes.DELETE_USER_MANAGEMENT_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getUserManagementList = (data) => {
    return async (dispatch) => {
        dispatch(GetUserManagementListRequest());
        ecoPostWithAuthHeader(GET_USER_MANAGEMENT_POST, JSON.stringify(data)).then(
            res => dispatch(GetUserManagementListSuccess(res.data))
        ).catch(
            err => dispatch(GetUserManagementListFailure(err))
        );
    };
};

/**
 * 修正処理
 * @param {*} data
 */
export const updateUserManagement = (data) => {
    return async (dispatch) => {
        dispatch(UpdateUserManagementRequest());
        ecoPostWithAuthHeader(GET_USER_MANAGEMENT_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateUserManagementSuccess(res.data))
        ).catch(
            err => dispatch(UpdateUserManagementFailure(err))
        );
    };
};

/**
 * 削除処理
 * @param {*} data
 */
export const deleteUserManagement = (data) => {
    return async (dispatch) => {
        dispatch(DeleteUserManagementRequest());
        ecoPostWithAuthHeader(DELETE_USER_MANAGEMENT_POST, JSON.stringify(data)).then(
            res => dispatch(DeleteUserManagementSuccess(res.data))
        ).catch(
            err => dispatch(DeleteUserManagementFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getUserManagementListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetUserManagementListReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateUserManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateUserManagementReset());
    };
};

/**
 * 画面リセットAction
 */
export const deleteUserManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(DeleteUserManagementReset());
    };
};