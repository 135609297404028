import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { UPDATE_MAP, CREATE_OUTLINE_TEMPLATE, CREATE_DETAIL_TEMPLATE, CREATE_RECRUITMENT_DETAIL_TEMPLATE } from "../Constants/RsConstants";
import { getCognitoUserInfo } from "../Constants/CommonConstants";
import { fileUpload, fileDelete, UploadRequest, UploadSuccess, UploadFailure, UploadReset } from "../Components/Tools/UploadTools";
import { isBlank } from "../Components/Tools/StringTools";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GRSMMISF01 from "./MmGRSMMISF01/GRSMMISF01";
import InternDetail from "./MmGRSMMIMD01/GRSMMIMD01";
import GRSMMMLI01 from "./MmGRSMMMLI01/GRSMMMLI01";
import GRSMMIMR01 from "./MmGRSMMIMR01/GRSMMIMR01";
import GRSMMIMR02 from "./MmGRSMMIMR02/GRSMMIMR02";
import GRSMMIMR03 from "./MmGRSMMIMR03/GRSMMIMR03";
import GRSMMIMR04 from "./MmGRSMMIMR04/GRSMMIMR04";
import GRSMMIMR05 from "./MmGRSMMIMR05/GRSMMIMR05";
import GRSMMIMR06 from "./MmGRSMMIMR06/GRSMMIMR06";
import GRSMMIMR07 from "./MmGRSMMIMR07/GRSMMIMR07";
import GRSMMIMR08 from "./MmGRSMMIMR08/GRSMMIMR08";
import GRSMMAMF01 from "./MmGRSMMAMF01/GRSMMAMF01";
import GRSMMERF01 from "./MmGRSMMERF01/GRSMMERF01";
import GRSMMAMF02 from "./MmGRSMMAMF02/GRSMMAMF02";
import GRSMMIMD02 from "./MmGRSMMIMD02/GRSMMIMD02";
import GRSMMRMR01 from "./MmGRSMMRMR01/GRSMMRMR01";
import GRSMMRMR02 from "./MmGRSMMRMR02/GRSMMRMR02";
import GRSMMRMR03 from "./MmGRSMMRMR03/GRSMMRMR03";
import GRSMMRMR04 from "./MmGRSMMRMR04/GRSMMRMR04";
import GRSMMRMR05 from "./MmGRSMMRMR05/GRSMMRMR05";
import GRSMMRMR06 from "./MmGRSMMRMR06/GRSMMRMR06";
import GRSMMRMR07 from "./MmGRSMMRMR07/GRSMMRMR07";
import GRSMMRMR08 from "./MmGRSMMRMR08/GRSMMRMR08";
import GRSMMRMR31 from "./MmGRSMMRMR31/GRSMMRMR31";
import GRSMMRMR32 from "./MmGRSMMRMR32/GRSMMRMR32";
import GRSMMRMR33 from "./MmGRSMMRMR33/GRSMMRMR33";
import GRSMMRMR34 from "./MmGRSMMRMR34/GRSMMRMR34";
import GRSMMRMR35 from "./MmGRSMMRMR35/GRSMMRMR35";
import GRSMMRMR36 from "./MmGRSMMRMR36/GRSMMRMR36";
import GRSMMRMR37 from "./MmGRSMMRMR37/GRSMMRMR37";
import GRSMMRMR38 from "./MmGRSMMRMR38/GRSMMRMR38";
import GRSMMRMD01 from "./MmGRSMMRMD01/GRSMMRMD01";
import GRSMMRMD02 from "./MmGRSMMRMD02/GRSMMRMD02";
import GRSMSMSA01 from "./MsGRSMSMSA01/GRSMSMSA01";
import GCMOMCRU06 from "../Cm/OmGCMOMCRU06/GCMOMCRU06";
import GCMOMCRU07 from "../Cm/OmGCMOMCRU07/GCMOMCRU07";
import { GetCaseListReset, CaseDetailReset, CaseDetailCopyReset, RecruitmentDetailReset, RecruitmentDetailCopyReset } from "./MmGRSMMMLI01/GRSMMMLI01Action";
import { GetCandidateListReset, GetSelectedCaseCandidateListReset } from "./MmGRSMMAMF01/GRSMMAMF01Action";
import { EntryListReset, GetCaseCandidateListReset } from "./MmGRSMMIMD01/GRSMMIMD01Action";
import { getSendingOrganizationInfo, getSendingOrganizationInfoReset } from "../Cm/OmGCMOMCRU03/GCMOMCRU03Action";
import { getUniversityInfo, getUniversityInfoReset } from "../Cm/OmGCMOMCRU04/GCMOMCRU04Action";
import { getCompanyList } from "./MmGRSMMIMR01/GRSMMIMR01Action";
import { getInterviewHistoryReset } from "./MsGRSMSMSA01/GRSMSMSA01Action";

/**
 * 募集支援機能テンプレート
 */
class RsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            internId: null,
            caseOutline: {},
            caseDetail: {},
            recruitmentCaseInfo: {},
            recruitmentCaseDetail: {},
            createorupdate: "",
        };
        this.caseId = this.props.location.caseId;
        this.gamenId = this.props.location.gamenId;
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        if (["COP", "KAN", "TOR"].includes(getCognitoUserInfo()["org_type"])) {
            // ログインユーザの組織タイプ＝"COP"　または
            // ログインユーザの組織タイプ＝"KAN"　または
            // ログインユーザの組織タイプ＝"TOR"　の場合
            this.props.getCompanyList();
        }
    };

    /**
     * 画面切替処理
     * @param {*} dispId
     * @param {*} isReset
     */
    switchDisplay = (dispId, isReset) => {
        if (!isReset) {
        }
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            caseOutline: CREATE_OUTLINE_TEMPLATE,
            caseDetail: CREATE_DETAIL_TEMPLATE,
            createorupdate: UPDATE_MAP[0],
        });
    };

    /**
     * 求人案件画面切替処理
     * @param {*} dispId
     * @param {*} type  0:技能実習,1:特定技能
     */
    switchRecruitmentDisplay = (dispId, type) => {
        const beforeDispId = this.state.display;
        const detail = Object.assign({}, CREATE_RECRUITMENT_DETAIL_TEMPLATE);
        detail.status_of_residence = type;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            recruitmentCaseInfo: CREATE_OUTLINE_TEMPLATE,
            recruitmentCaseDetail: detail,
            createorupdate: UPDATE_MAP[0],
        });
    };

    /**
     * 状態リセット
     */
    getCaseListErrorDisable = () => {
        this.props.resetGetListState();
    };

    /**
     * 状態リセット
     */
    getCaseDetailErrorDisable = () => {
        this.props.resetGetDetailState();
        this.props.resetGetDetailCopyState();
    };

    /**
     * 状態リセット
     */
    getEntryErrorDisable = () => {
        this.props.resetEntryListState();
    };

    /**
     * 状態リセット
     */
    getSelectedCaseCandidateListErrorDisable = () => {
        this.props.resetSelectedCaseCandidateListState();
    };

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} outline
     * @param {*} detail
     */
    switchDisplayWithParameter = (dispId, detail, source) => {
        this.setState({
            display: dispId,
            candidateDetail: detail,
            displaySource: source
        });
    };

    /**
     * 状態リセット
     */
    getCaseCandidateErrorDisable = () => {
        this.props.resetCaseCandidateListState();
    };

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} outline
     * @param {*} detail
     */
    switchDisplayWithData = (dispId, outline, detail) => {
        const beforeDispId = this.state.display;
        if (dispId == "GRSMMRMR01") {
            // 求人案件の場合(技能実習)
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                recruitmentCaseInfo: outline,
                recruitmentCaseDetail: detail,
                createorupdate: detail.case_id && detail.case_status && detail.case_status != "" ? UPDATE_MAP[1] : UPDATE_MAP[0]
            });
        } else if (dispId == "GRSMMRMR31") {
            // 求人案件の場合(特定技能)
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                recruitmentCaseInfo: outline,
                recruitmentCaseDetail: detail,
                createorupdate: detail.case_id && detail.case_status && detail.case_status != "" ? UPDATE_MAP[1] : UPDATE_MAP[0]
            });
        } else {
            // インターンの場合
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                caseOutline: outline,
                caseDetail: detail,
                createorupdate: detail.case_id && detail.case_status && detail.case_status != "" ? UPDATE_MAP[1] : UPDATE_MAP[0]
            });
        }
    };

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} outline
     * @param {*} detail
     */
    switchRecruitmentDisplayWithData = (dispId, outline, detail) => {
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            recruitmentCaseInfo: outline,
            recruitmentCaseDetail: detail,
            createorupdate: detail.case_id && detail.case_status && detail.case_status != "" ? UPDATE_MAP[1] : UPDATE_MAP[0]
        });
    };

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detail
     * @param {*} candidate
     * @param {*} entry
     */
    switchDisplayEntry = (dispId, caseDetail, candidateList, entryList, selectedCandidateList) => {
        this.setState({
            display: dispId,
            caseDetail: caseDetail,
            candidateList: candidateList,
            entryList: entryList,
            selectedCandidateList: selectedCandidateList,
            beforeDispId: "GRSMMIMD02"
        });
    };

    /**
     * 応募者詳細から画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detail
     * @param {*} candidate
     * @param {*} entry
     */
    switchDisplaySelectedCandidate = (dispId, caseDetail, candidateList, selectedCandidateList) => {
        window.scrollTo(0, 0);
        this.setState({
            display: dispId,
            caseDetail: caseDetail,
            candidateList: candidateList,
            selectedCandidateList: selectedCandidateList,
            beforeDispId: "GRSMMAMF02"
        });
    };

    /**
     * 大学から画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detail
     */
    switchDisplaySelectedUni = (dispId, universtyData) => {
        window.scrollTo(0, 0);
        this.setState({
            display: dispId,
            universtyData: universtyData,
            beforeDispId: "GRSMMIMD02"
        });
    };

    /**
     * 送り出し機関から画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detail
     */
    switchDisplaySelectedOrg = (dispId, sendingOrganizationData) => {
        window.scrollTo(0, 0);
        this.setState({
            display: dispId,
            sendingOrganizationData: sendingOrganizationData,
            beforeDispId: "GRSMMIMD02"
        });
    };

    /**
     * 施設/周辺写真リストアップロード
     * @param {*} caseImageList 施設/周辺写真リスト
     */
    uploadImageFiles = async (caseImageList) => {
        await this.props.uploadReset();
        await this.props.uploadRequest();
        if (caseImageList) {
            await Promise.all(
                caseImageList.map(async (file) => {
                    if (!isBlank(file.image_content)) {
                        file.image_content = await fileUpload(
                            file.image_content,
                            (value) => this.props.uploadSuccess(value),
                            (value) => this.props.uploadFailure(value)
                        );
                    }
                })
            );
            let errs = [];
            if (caseImageList.length > 0) {
                errs = caseImageList.filter((item) => {
                    return item.image_content === "error";
                });
            }
            if (errs.length > 0) return false;
        }
        await this.props.uploadReset();
        return true;
    };

    /**
     * 施設/周辺写真リスト一時ファイル削除
     * @param {*} caseImageList 施設/周辺写真一時ファイルリスト
     */
    deleteImageFiles = async (caseImageList) => {
        await this.props.uploadReset();
        await this.props.uploadRequest();
        if (caseImageList) {
            const result = await fileDelete(
                caseImageList,
                (value) => this.props.uploadSuccess(value),
                (value) => this.props.uploadFailure(value)
            );
            if (!result) return false;
        }
        await this.props.uploadReset();
        return true;
    };

    render() {
        let displayParts;
        if (this.state.display === "interndetail") {
            displayParts = (
                <InternDetail
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    getCaseCandidateListReset={this.getCaseCandidateErrorDisable}
                    getEntryListReset={this.getEntryErrorDisable}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    switchDisplayEntry={this.switchDisplayEntry}
                />
            );
        } else if (this.state.display === "GRSMMMLI01") {
            displayParts = (
                <GRSMMMLI01
                    switchRecruitmentDisplay={this.switchRecruitmentDisplay}
                    gamenId={this.gamenId}
                    caseId={this.caseId}
                    changeDisp={this.switchDisplay}
                    getCaseListReset={this.getCaseListErrorDisable}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    changeDispWithData={this.switchDisplayWithData}
                />
            );
            this.caseId = null;
            this.gamenId = null;
        } else if (this.state.display === "GRSMMIMR01") {
            displayParts = (
                <GRSMMIMR01
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    createorupdate={this.state.createorupdate}
                    caseDetail={this.state.caseDetail}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR02") {
            displayParts = (
                <GRSMMIMR02
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR03") {
            displayParts = (
                <GRSMMIMR03
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR04") {
            displayParts = (
                <GRSMMIMR04
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR05") {
            displayParts = (
                <GRSMMIMR05
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR06") {
            displayParts = (
                <GRSMMIMR06
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR07") {
            displayParts = (
                <GRSMMIMR07
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMIMR08") {
            displayParts = (
                <GRSMMIMR08 changeDisp={this.switchDisplay} getCaseDetailReset={this.getCaseDetailErrorDisable} />
            );
        } else if (this.state.display === "GRSMMISF01") {
            displayParts = (
                <GRSMMISF01
                    changeDisp={this.switchDisplay}
                    beforeDispId={this.state.beforeDispId}
                    skipToNext={this.switchDisplayWithData}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    createorupdate={this.state.createorupdate}
                />
            );
        } else if (this.state.display === "GRSMMAMF01") {
            displayParts = (
                <GRSMMAMF01
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    skipToNext={this.switchDisplayWithParameter}
                    getEntryListReset={this.getEntryErrorDisable}
                    beforeDispId={this.state.beforeDispId}
                    caseDetail={this.state.caseDetail}
                    candidateList={this.state.candidateList}
                    entryList={this.state.entryList}
                    switchDisplaySelectedCandidate={this.switchDisplaySelectedCandidate}
                    statusOfResidence={this.state.statusOfResidence}
                    selectedCandidateList={this.state.selectedCandidateList}
                    beforeDispId={this.state.beforeDispId}
                />
            );
        } else if (this.state.display === "GRSMMAMF02") {
            displayParts = (
                <GRSMMAMF02
                    changeDisp={this.switchDisplay}
                    displaySource={this.state.displaySource}
                    beforeDispId={this.state.beforeDispId}
                    candidateDetail={this.state.candidateDetail}
                    caseDetail={this.state.caseDetail}
                    candidateList={this.state.candidateList}
                    getEntryListReset={this.getEntryErrorDisable}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    switchDisplaySelectedCandidate={this.switchDisplaySelectedCandidate}
                />
            );
        } else if (this.state.display === "GRSMMERF01") {
            displayParts = (
                <GRSMMERF01 changeDisp={this.switchDisplay} beforeDispId={this.state.beforeDispId} />
            );
        } else if (this.state.display === "GRSMMIMD02") {
            displayParts = (
                <GRSMMIMD02
                    changeDisp={this.switchDisplay}
                    skipToNext={this.switchDisplayWithParameter}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    getEntryListReset={this.getEntryErrorDisable}
                    caseDetail={this.state.caseDetail}
                    candidateList={this.state.candidateList}
                    selectedCandidateList={this.state.selectedCandidateList}
                    beforeDispId={this.state.beforeDispId}
                    changeDispWithData={this.switchDisplayWithData}
                    switchDisplayEntry={this.switchDisplayEntry}
                    getSelectedCaseCandidateListReset={this.getSelectedCaseCandidateListErrorDisable}
                />
            );
        } else if (this.state.display === "GRSMMRMR01") {
            displayParts = (
                <GRSMMRMR01
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR02") {
            displayParts = (
                <GRSMMRMR02
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR03") {
            displayParts = (
                <GRSMMRMR03
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR04") {
            displayParts = (
                <GRSMMRMR04
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR05") {
            displayParts = (
                <GRSMMRMR05
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR06") {
            displayParts = (
                <GRSMMRMR06
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR07") {
            displayParts = (
                <GRSMMRMR07
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR08") {
            displayParts = (
                <GRSMMRMR08 changeDisp={this.switchRecruitmentDisplay} getCaseDetailReset={this.getCaseDetailErrorDisable} />
            );
        } else if (this.state.display === "GRSMMRMR31") {
            displayParts = (
                <GRSMMRMR31
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR32") {
            displayParts = (
                <GRSMMRMR32
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR33") {
            displayParts = (
                <GRSMMRMR33
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR34") {
            displayParts = (
                <GRSMMRMR34
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR35") {
            displayParts = (
                <GRSMMRMR35
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR36") {
            displayParts = (
                <GRSMMRMR36
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR37") {
            displayParts = (
                <GRSMMRMR37
                    changeDisp={this.switchRecruitmentDisplay}
                    skipToNext={this.switchRecruitmentDisplayWithData}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    recruitmentCaseInfo={this.state.caseOutline}
                    recruitmentCaseDetail={this.state.recruitmentCaseDetail}
                    createorupdate={this.state.createorupdate}
                    uploadImageFiles={this.uploadImageFiles}
                    deleteImageFiles={this.deleteImageFiles}
                />
            );
        } else if (this.state.display === "GRSMMRMR38") {
            displayParts = (
                <GRSMMRMR38 changeDisp={this.switchRecruitmentDisplay} getCaseDetailReset={this.getCaseDetailErrorDisable} />
            );
        } else if (this.state.display === "GRSMMRMD01") {
            displayParts = (
                <GRSMMRMD01
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    getCaseCandidateListReset={this.getCaseCandidateErrorDisable}
                    getEntryListReset={this.getEntryErrorDisable}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    switchDisplayEntry={this.switchDisplayEntry}
                />
            );
        } else if (this.state.display === "GRSMMRMD02") {
            displayParts = (
                <GRSMMRMD02
                    changeDisp={this.switchDisplay}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    getCaseCandidateListReset={this.getCaseCandidateErrorDisable}
                    getEntryListReset={this.getEntryErrorDisable}
                    caseOutline={this.state.caseOutline}
                    caseDetail={this.state.caseDetail}
                    switchDisplayEntry={this.switchDisplayEntry}
                />
            );
        } else if (this.state.display === "GCMOMCRU06") {
            displayParts = (
                <GCMOMCRU06
                    changeDisp={this.switchDisplay}
                    displaySource={this.state.displaySource}
                    beforeDispId={this.state.beforeDispId}
                    sendingOrganizationData={this.state.candidateDetail}
                    getEntryListReset={this.getEntryErrorDisable}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    switchDisplaySelectedOrg={this.switchDisplaySelectedOrg}
                />
            );
        } else if (this.state.display === "GCMOMCRU07") {
            displayParts = (
                <GCMOMCRU07
                    changeDisp={this.switchDisplay}
                    displaySource={this.state.displaySource}
                    beforeDispId={this.state.beforeDispId}
                    universityData={this.state.candidateDetail}
                    getEntryListReset={this.getEntryErrorDisable}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    switchDisplaySelectedUni={this.switchDisplaySelectedUni}
                />
            );
        } else if (this.state.display === "GRSMSMSA01") {
            displayParts = (
                <GRSMSMSA01
                    changeDisp={this.switchDisplay}
                    displaySource={this.state.displaySource}
                    beforeDispId={this.state.beforeDispId}
                    interviewData={this.state.candidateDetail}
                    caseDetail={this.state.caseDetail}
                    getCaseDetailReset={this.getCaseDetailErrorDisable}
                    getEntryListReset={this.getEntryErrorDisable}
                    switchDisplayEntry={this.switchDisplayEntry}
                />
            );
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01
                    changeDisp={this.switchDisplay}
                    displayParts={displayParts}
                    toggleClass={this.props.location.toggleClass}
                    menuIndex={this.props.location.menuIndex}
                />
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

function mapDispatchToProps(dispatch) {
    return {
        resetGetListState() {
            dispatch(GetCaseListReset());
        },
        resetGetDetailState() {
            dispatch(CaseDetailReset());
            dispatch(RecruitmentDetailReset());
        },
        resetGetDetailCopyState() {
            dispatch(CaseDetailCopyReset());
            dispatch(RecruitmentDetailCopyReset());
        },
        resetEntryListState() {
            dispatch(EntryListReset());
        },
        resetCaseCandidateListState() {
            dispatch(GetCaseCandidateListReset());
        },
        resetSelectedCaseCandidateListState() {
            dispatch(GetSelectedCaseCandidateListReset());
        },
        getCompanyList() {
            dispatch(getCompanyList());
        },
        uploadRequest() {
            dispatch(UploadRequest());
        },
        uploadSuccess(value) {
            dispatch(UploadSuccess(value));
        },
        uploadFailure(value) {
            dispatch(UploadFailure(value));
        },
        uploadReset() {
            dispatch(UploadReset());
        },
        getSendingOrganizationInfo() {
            // 組織情報(プロフィール)送り出し機関取得
            dispatch(getSendingOrganizationInfo());
        },
        getSendingOrganizationInfoReset() {
            dispatch(getSendingOrganizationInfoReset());
        },
        getUniversityInfo() {
            // 組織情報(プロフィール)送り出し機関取得
            dispatch(getUniversityInfo());
        },
        getUniversityInfoReset() {
            dispatch(getUniversityInfoReset());
        }
    };
}

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RsTemplate)
);
