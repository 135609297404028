const cognito = require('amazon-cognito-identity-js');

// Region
export let REGION = 'ap-northeast-1';

/**
 * IDトークン取得処理
 */
export const getIdToken = () => {
    const usernameKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.LastAuthUser';
    const username = sessionStorage.getItem(usernameKey);
    const idTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + username + '.idToken';
    const idToken = sessionStorage.getItem(idTokenKey);
    return idToken;
};

/**
 * Cognitoのtoken情報をSessionStorageへ登録する
 * @param {*} challengeId
 * @param {*} cognitoResult
 */
export const setCognitoSessionStorage = (challengeId, cognitoResult) => {
    const usernameKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.LastAuthUser';
    sessionStorage.setItem(usernameKey, challengeId);
    const idTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + challengeId + '.idToken';
    sessionStorage.setItem(idTokenKey, cognitoResult.idToken.jwtToken);
    const accessTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + challengeId + '.accessToken';
    sessionStorage.setItem(accessTokenKey, cognitoResult.accessToken.jwtToken);
    const refreshTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + challengeId + '.refreshToken';
    sessionStorage.setItem(refreshTokenKey, cognitoResult.refreshToken.token);
};

export const setRefreshCognitoSessionStorage = (challengeId, cognitoResult) => {
    const idTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + challengeId + '.idToken';
    sessionStorage.setItem(idTokenKey, cognitoResult.IdToken);
    const accessTokenKey = 'CognitoIdentityServiceProvider.' + getCognitoInfo('ClientId') + '.' + challengeId + '.accessToken';
    sessionStorage.setItem(accessTokenKey, cognitoResult.AccessToken);
};

/**
 * CognitoURL情報取得
 */
export const getCognitoIss = () => {
    return "https://cognito-idp." + REGION + ".amazonaws.com/" + getCognitoInfo('UserPoolId');
};

/**
 * CognitoEndpoint取得
 */
export const getCognitoEndpoint = () => {
    return getCognitoIss() + "/.well-known/jwks.json";
};

// バージョン定義
export const Version = "Version 0.0.1";

// APIのURL先頭部
export let BASE_URL = window.location.origin;

/**
 * APIのURL先頭部設定
 * @param {*} url
 */
export const setBaseUrl = (url) => {
    BASE_URL = url;
};

// メッセージMap
export let MESSAGE_MASTER = {};

/**
 * メッセージマスタデータ設定
 * @param {*} data
 */
export const setMessageMaster = (data) => {
    MESSAGE_MASTER = data;
};

/**
 * メッセージ取得
 * @param {*} code
 */
export const getMessage = (code) => {
    if (code in MESSAGE_MASTER) {
        return '（' + code + '）' + MESSAGE_MASTER[code];
    } else {
        return code;
    }
};

// コードMap
export let CODE_MASTER = {};
// コード配列
export let CODE_MASTER_LIST = {};

/**
 * コードマスタデータ設定
 * @param {*} data
 */
export const setCodeMaster = (data) => {
    let codeMap = {};
    let codeList = {};
    data.forEach(value => {
        // コードMap
        if (value.code_type in codeMap) {
            // ignore
        } else {
            codeMap[value.code_type] = {};
        }
        codeMap[value.code_type][value.code] = value.code_name;
        // コード配列
        if (value.code_type in codeList) {
            // ignore
        } else {
            codeList[value.code_type] = [];
        }
        codeList[value.code_type].push(value);
    });
    CODE_MASTER = codeMap;
    CODE_MASTER_LIST = codeList;
};


/**
 * コード定義取得
 * @param {*} codeType
 */
export const getCode = (codeType) => {
    if (codeType in CODE_MASTER) {
        return CODE_MASTER[codeType];
    } else {
        return {};
    }
};

/**
 * コード定義配列取得
 * @param {*} codeType
 */
export const getCodeList = (codeType) => {
    if (codeType in CODE_MASTER_LIST) {
        return CODE_MASTER_LIST[codeType];
    } else {
        return [];
    }
};

/**
 * コードValue&Label取得
 * @param {*} codeType
 */
export const getCodeValueLabelList = (codeType) => {
    return Object.entries(getCodeList(codeType)).map(
        ([key, value]) => (
            {
                value: value.code,
                label: value.code_name
            }
        )
    );
};

// // コードOptions定義取得
// export const getCodeOptions = (codeType) => {
//   if (codeType in CODE_MASTER) {
//     return Object.entries(CODE_MASTER[codeType]).map(
//       ([key, value]) => (
//         <option key={key} value={key}>
//           {value}
//         </option>
//       )
//     );
//   } else {
//     return '';
//   }
// };

/**
 * コード名取得
 * @param {*} codeType
 * @param {*} code
 */
export const getCodeName = (codeType, code) => {
    if (codeType in CODE_MASTER && code in CODE_MASTER[codeType]) {
        return CODE_MASTER[codeType][code];
    } else {
        return code;
    }
};

// アクセスコントロールMap
export let ACCESS_CONTROL_MASTER = {};

/**
 * アクセスコントロールマスタデータ設定
 * @param {*} data
 */
export const setAccessControlMaster = (data) => {
    ACCESS_CONTROL_MASTER = data;
};

/**
 * アクセスコントロール存在チェック
 * @param {*} moduleId
 */
export const hasShowRole = (moduleId) => {
    for (var i = 0; i < ACCESS_CONTROL_MASTER.length; i++) {
        if (moduleId == ACCESS_CONTROL_MASTER[i]) {
            return true;
        }
    }
    return false;
};

// Cognito定義情報
export let COGNITO_INFO = {};

/**
 * Cognito定義情報設定
 * @param {*} data
 */
export const setCognitoInfo = (data) => {
    COGNITO_INFO = data;
};

/**
 * Cognito定義情報取得
 * @param {*} name
 */
export const getCognitoInfo = (name) => {
    return COGNITO_INFO[name];
};

// CognitoPool
export let COGNITO_POOL = null;

/**
 * CognitoPool取得
 */
export const getCognitoPool = () => {
    return COGNITO_POOL;
};

/**
 * CognitoPool設定
 * @param {*} cognitooPool
 */
export const setCognitoPool = (cognitooPool) => {
    COGNITO_POOL = cognitooPool;
};

/**
 * CognitoPool新規作成
 */
export const createNewCognitoPool = () => {
    if (getCognitoInfo('UserPoolId') && getCognitoInfo('ClientId')) {
        return new cognito.CognitoUserPool({
            UserPoolId: getCognitoInfo('UserPoolId'),
            ClientId: getCognitoInfo('ClientId'),
            Storage: sessionStorage
        });
    } else {
        return null;
    }

};

// Cognitoユーザ
export let COGNITO_USER = null;

/**
 * Cognitoユーザ取得
 */
export const getCognitoUser = () => {
    return COGNITO_USER;
};

/**
 * Cognitoユーザ設定
 * @param {*} cognitoUser
 */
export const setCognitoUser = (cognitoUser) => {
    COGNITO_USER = cognitoUser;
};

//  ログインユーザ情報
export let COGNITO_USER_INFO = {};

/**
 * ログインユーザ情報設定
 * @param {*} data
 */
export const setCognitoUserInfo = (data) => {
    COGNITO_USER_INFO = data;
};

/**
 * ログインユーザ情報取得
 */
export const getCognitoUserInfo = () => {
    return COGNITO_USER_INFO;
};


// 業種マスタ
export let INDUSTRY_MASTER = {};
// 業種マスタ配列
export let INDUSTRY_MASTER_LIST = [];

/**
 * 業種マスタデータ設定
 * @param {*} data
 */
export const setIndustryMaster = (data) => {
    let industryMap = {};
    let industryList = [];
    data.forEach(value => {
        // 業種マスタMap
        industryMap[value.industry_id] = value;
        // 業種マスタ配列
        industryList.push(value);
    });
    INDUSTRY_MASTER = industryMap;
    INDUSTRY_MASTER_LIST = industryList;
};

/**
 * 業種マスタ配列取得
 */
export const getIndustryMasterList = () => {
    return INDUSTRY_MASTER_LIST;
};

/**
 * 業種表示名取得
 */
export const getIndustryName = (data) => {
    if (data in INDUSTRY_MASTER) {
        return INDUSTRY_MASTER[data]['industry_name_j'];
    } else {
        return data;
    }
};

// 国マスタ
export let COUNTRY_MASTER = {};
// 国マスタ配列
export let COUNTRY_MASTER_LIST = [];

/**
 * 国マスタデータ設定
 * @param {*} data
 */
export const setCountryMaster = (data) => {
    let countryMap = {};
    let countryList = [];
    data.forEach(value => {
        // 国マスタMap
        countryMap[value.country_code] = value;
        // 国マスタ配列
        countryList.push(value);
    });
    COUNTRY_MASTER = countryMap;
    COUNTRY_MASTER_LIST = countryList;
};

/**
 * 国マスタ配列取得
 */
export const getCountryMasterList = () => {
    return COUNTRY_MASTER_LIST;
};

// 都道府県マスタ
export let PREFECTURES_MASTER = {};
// 都道府県マスタ配列
export let PREFECTURES_MASTER_LIST = [];

/**
 * 都道府県マスタデータ設定
 * @param {*} data
 */
export const setPrefecturesMaster = (data) => {
    let prefecturesMap = {};
    let prefecturesList = [];
    data.forEach(value => {
        // 都道府県マスタMap
        prefecturesMap[value.prefectures_id] = value;
        // 都道府県マスタ配列
        prefecturesList.push(value);
    });
    PREFECTURES_MASTER = prefecturesMap;
    PREFECTURES_MASTER_LIST = prefecturesList;
};

/**
 * 都道府県マスタデータ取得
 */
export const getPrefecturesMaster = () => {
    return PREFECTURES_MASTER;
};

/**
 * 都道府県マスタ配列取得
 */
export const getPrefecturesMasterList = () => {
    return PREFECTURES_MASTER_LIST;
};

// メニュー画面のtokenタイムアウトチェック定義
export let SESSION_TIMEOUT_CHECK = null;

/**
 * メニュー画面のtokenタイムアウトチェック処理設定
 * @param {*} data
 */
export const setSessionTimeoutCheck = (data) => {
    SESSION_TIMEOUT_CHECK = data;
};

/**
 * tokenタイムアウトチェック
 */
export const sessionTimeoutCheck = () => {
    if (SESSION_TIMEOUT_CHECK != null) {
        SESSION_TIMEOUT_CHECK();
    }
};

/**
 * メニューインスタンス定義
 */
export let MENU_INSTANCE = null;

/**
 * メニューインスタンス設定
 * @param {*} data
 */
export const setMenuInstance = (data) => {
    MENU_INSTANCE = data;
};

/**
 * メニューインスタンス取得
 */
export const getMenuInstance = () => {
    return MENU_INSTANCE;
};
