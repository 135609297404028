import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GISTTPTR01ActionType';

// 実習計画テンプレート登録API
const SAVE_TRAINING_PLAN_TEMPLATE_INFO_POST = BASE_URL + "/api/is_tt_update_working_plan_template_info";

// ********************Actionの作成 Start ********************

/**
 * 登録リクエスト
 */
export const SaveTrainingPlanTemplateRequest = () => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_TEMPLATE_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const SaveTrainingPlanTemplateSuccess = (data) => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_TEMPLATE_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const SaveTrainingPlanTemplateFailure = (err) => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_TEMPLATE_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const SaveTrainingPlanTemplateReset = () => {
    return {
        type: actionTypes.SAVE_TRAINING_PLAN_TEMPLATE_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const saveTrainingPlanTemplateInfo = (data) => {
    return async (dispatch) => {
        dispatch(SaveTrainingPlanTemplateRequest());
        ecoPostWithAuthHeader(SAVE_TRAINING_PLAN_TEMPLATE_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(SaveTrainingPlanTemplateSuccess(res.data))
        ).catch(
            err => dispatch(SaveTrainingPlanTemplateFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const saveTrainingPlanTemplateReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveTrainingPlanTemplateReset());
    };
};