import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GISAMIAR02ActionType";

// API
const GET_TRAINING_RESULTS_POST = BASE_URL + "/api/is_am_get_training_results_detail";
const UPDATE_TRAINING_RESULTS_POST = BASE_URL + "/api/is_am_update_training_results_info";

// ********************Actionの作成 Start ********************

/**
 * 実習結果取得リクエスト
 */
export const GetTrainingResultsRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const GetTrainingResultsSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const GetTrainingResultsFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const GetTrainingResultsReset = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_RESET,
    };
};

/**
 * 実習結果更新リクエスト
 */
export const UpdateTrainingResultsRequest = () => {
    return {
        type: actionTypes.UPDATE_TRAINING_RESULTS_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateTrainingResultsSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_TRAINING_RESULTS_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateTrainingResultsFailure = (err) => {
    return {
        type: actionTypes.UPDATE_TRAINING_RESULTS_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const UpdateTrainingResultsReset = () => {
    return {
        type: actionTypes.UPDATE_TRAINING_RESULTS_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 実習結果取得処理
 * @param {*} data
 */
export const getTrainingResults = (data) => {
    return async (dispatch) => {
        dispatch(GetTrainingResultsRequest());
        ecoPostWithAuthHeader(
            GET_TRAINING_RESULTS_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(GetTrainingResultsSuccess(res.data))
            )
            .catch((err) => dispatch(GetTrainingResultsFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const getTrainingResultsReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainingResultsReset());
    };
};

/**
 * 実習結果更新処理
 * @param {*} data
 */
export const updateTrainingResults = (data) => {
    return async (dispatch) => {
        dispatch(UpdateTrainingResultsRequest());
        ecoPostWithAuthHeader(
            UPDATE_TRAINING_RESULTS_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(UpdateTrainingResultsSuccess(res.data))
            )
            .catch((err) => dispatch(UpdateTrainingResultsFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const updateTrainingResultsReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateTrainingResultsReset());
    };
};
