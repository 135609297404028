import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMIMD01ActionTypes';

// エントリリスト取得API
const ENTRY_LIST_POST = BASE_URL + "/api/rs_mm_get_case_entry_list";

// ********************Actionの作成 Start ********************

/**
 * エントリリスト
 */
export const EntryListRequest = () => {
    return {
        type: actionTypes.ENTRY_LIST_REQUEST
    };
};

/**
 * エントリリスト取得成功
 * @param {*} data
 */
export const EntryListSuccess = (data) => {
    return {
        type: actionTypes.ENTRY_LIST_SUCCESS,
        data
    };
};

/**
 * エントリリスト取得失敗
 * @param {*} err
 */
export const EntryListFailure = (err) => {
    return {
        type: actionTypes.ENTRY_LIST_FAILURE,
        err
    };
};

/**
 * エントリリスト取得リセット
 */
export const EntryListReset = () => {
    return {
        type: actionTypes.ENTRY_LIST_RESET
    };
};

/**
 * 案件応募者リスト
 */
export const GetCaseCandidateListRequest = () => {
    return {
        type: actionTypes.GET_CASE_CANDIDATE_LIST_REQUEST
    };
};

/**
 * 案件応募者リスト取得成功
 * @param {*} data
 */
export const GetCaseCandidateListSuccess = (data) => {
    return {
        type: actionTypes.GET_CASE_CANDIDATE_LIST_SUCCESS,
        data
    };
};

/**
 * 案件応募者リスト取得失敗
 * @param {*} err
 */
export const GetCaseCandidateListFailure = (err) => {
    return {
        type: actionTypes.GET_CASE_CANDIDATE_LIST_FAILURE,
        err
    };
};

/**
 * 案件応募者リスト取得リセット
 */
export const GetCaseCandidateListReset = () => {
    return {
        type: actionTypes.GET_CASE_CANDIDATE_LIST_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * エントリ詳細情報取得Actionの発行
 * @param {*} data
 */
export const getEntryList = (data) => {

    return async (dispatch) => {
        // Storeへエントリ詳細登録リクエストActionをdispatchする
        dispatch(EntryListRequest());
        ecoPostWithAuthHeader(ENTRY_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへエントリ詳細登録取得成功Actionをdispatchする
                dispatch(EntryListSuccess(res.data))
            ).catch(err =>
                // Storeへエントリ詳細登録取得失敗Actionをdispatchする
                dispatch(EntryListFailure(err))
            );
    };
};

/**
 * 案件応募者リスト取得Actionの発行
 * @param {*} data
 */
export const getCaseCandidateList = (data) => {

    return async (dispatch) => {
        // Storeへ案件応募者リスト取得リクエストActionをdispatchする
        dispatch(GetCaseCandidateListRequest());
        ecoPostWithAuthHeader(ENTRY_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件応募者リスト取得成功Actionをdispatchする
                dispatch(GetCaseCandidateListSuccess(res.data))
            ).catch(err =>
                // Storeへ案件応募者リスト取得失敗Actionをdispatchする
                dispatch(GetCaseCandidateListFailure(err))
            );
    };
};
