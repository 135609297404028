import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import {
    getOrganizationManagementList,
    getOrganizationManagementListReset,
    updateOrganizationManagement,
    updateOrganizationManagementReset,
    deleteOrganizationManagement,
    deleteOrganizationManagementReset
} from "./GCMOMOIR01Action";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 組織管理
 */
class GCMOMOIR01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            country_code: "",
            organization_type: "",
            membership_name: "",
            organization_name: "",
            status: "1",
            mail_address: "",
            selectedOrganizationId: "",
            createOrUpdate: "",
            countryOpt: [],
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
        }), () => {
            this.handlingSearch();
        });
        window.scrollTo(0, 0);
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 検索処理
     */
    handlingSearch = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset());

        this.setState({
            country_code: this.state.country_code,
            organization_type: this.state.organization_type,
            membership_name: this.state.membership_name,
            organization_name: this.state.organization_name,
            status: this.state.status,
            mail_address: this.state.mail_address,
            selectedOrganizationId: "",
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getOrganizationManagementList(this.state);
        });
    }

    /**
     * 組織選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        const { value } = event.target;

        this.setState({
            err_msg: [],
            selectedOrganizationId: this.props.data.list[value].organization_id,
            selectedOrganizationData: this.props.data.list[value]
        });
    }

    /**
    * 新規登録
    */
    handlingSignUp = () => {
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 条件設定
        let parameterData = {
            organization_id: '',
            organization_type: 'COP'
        };

        this.props.updateOrganizationManagement(parameterData);
    }

    /**
     * 組織修正
     */
    handlingModify = () => {
        window.scrollTo(0, 0);

        if (isBlank(this.state.selectedOrganizationId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            // 編集画面へ移動する
            this.handleModifyOKAction();
        }
    }

    /**
     * 組織修正の処理を実行する
     */
    handleModifyOKAction = () => {
        const { selectedOrganizationId, selectedOrganizationData } = this.state;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 条件設定
        let parameterData = {
            organization_id: selectedOrganizationId,
            organization_type: selectedOrganizationData.organization_type
        };

        this.props.updateOrganizationManagement(parameterData);
    }

    /**
     * 組織削除処理
     */
    handlingDelete = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        if (isBlank(this.state.selectedOrganizationId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            let message = getMessage('RS-0040');
            // 確認ダイアログメッセージボックスを表示する
            this.showModalDialog(1, message, this.handlingDeleteOKAction, this.handlingCancelAction);
        }
    }

    /**
     * 組織削除のを実行する
     */
    handlingDeleteOKAction = () => {
        const { selectedOrganizationId, selectedOrganizationData } = this.state;

        // 条件設定
        let parameterData = {
            organization_id: selectedOrganizationId,
            organization_type: selectedOrganizationData.organization_type
        };

        this.props.deleteOrganizationManagement(parameterData);

    }

    /**
     * キャンセル処理
     */
    handlingCancelAction = () => { }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.organizationManagementListReset();
        this.props.updateOrganizationManagementReset();
        this.props.deleteOrganizationManagementReset();
    }

    render() {

        // 組織タイプ
        const organizationType = getCodeValueLabelList('RSC0030').filter(v => v.value != 'COD');

        // ステータス
        const statusOpt = getCodeValueLabelList('RSC0027');

        // 削除成功の場合、再検索する
        if (this.props.deleteErr_type === '200') {
            this.props.deleteOrganizationManagementReset();
            this.handlingSearch();
        }

        // 組織情報登録・訂正画面に移動
        if (this.props.updateData && this.props.updateErr_type === '200') {
            this.props.changeDispWithData("GCMOMOIR02", this.props.updateData);
        }

        // 件数が100件を超える場合
        if (this.props.data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        } else if (this.props.data.overflowflg === '' && this.state.err_msg.length > 0) {
            let { err_msg } = this.state;
            err_msg.map((msg, index) => {
                if (msg.indexOf('RS-0017') > 0) {
                    err_msg.splice(index, 1);
                    this.setState({
                        err_msg: err_msg
                    });
                }
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>組織管理</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.updateErr_type} err_detail={this.props.updateErr_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.deleteErr_type} err_detail={this.props.deleteErr_detail} messageMap={{}} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={this.toggleClassAction} tabIndex="1">検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>国</th>
                                                    <td>
                                                        <select className="el_select" name="country_code" value={this.state.country_code} onChange={this.handleChange} tabIndex="2">
                                                            <option value="" selected></option>
                                                            {
                                                                this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                    <option key={item.country_number} value={item.country_code} selected={this.state.country_code === item.country_code}>
                                                                        {item.country_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>組織タイプ</th>
                                                    <td>
                                                        <select className="el_select" name="organization_type" value={this.state.organization_type} onChange={this.handleChange} tabIndex="3">
                                                            <option value="" selected></option>
                                                            {
                                                                organizationType && organizationType.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.organization_type === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>所属団体</th>
                                                    <td>
                                                        <TextInput type="text" name="membership_name" className="el_input el_input__max" maxlength="60" setValue={this.state.membership_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>組織名</th>
                                                    <td>
                                                        <TextInput type="text" name="organization_name" className="el_input el_input__max" maxlength="60" setValue={this.state.organization_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ステータス</th>
                                                    <td>
                                                        <select className="el_select" name="status" value={this.state.status} onChange={this.handleChange} tabIndex="6">
                                                            <option value="" ></option>
                                                            {
                                                                statusOpt && statusOpt.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.status === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>メールアドレス</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="mail_address" className="el_input el_input__max" setValue={this.state.mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSearch()} tabIndex="8">検索</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>組織一覧</span></h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__multi">
                                            <button type="button" name="signUp" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSignUp()} tabIndex="9">新規登録</button>
                                            <button type="button" name="modify" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingModify()} tabIndex="10">修正​</button>
                                            <button type="button" name="delete" className="el_btn el_shadowBtn" onClick={() => this.handlingDelete()} tabIndex="11">削除</button>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>選択</th>
                                                        <th>国</th>
                                                        <th>組織タイプ</th>
                                                        <th>所属団体</th>
                                                        <th>組織名</th>
                                                        <th>ステータス</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data.list)) {
                                                            return (
                                                                this.props.data.list.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="hp_tac">
                                                                            <input type="radio" name="row_select" value={index} onChange={this.handleRowSelection} tabIndex={12 + index} />
                                                                        </td>
                                                                        <td>{item.country_name}</td>
                                                                        <td>{item.organization_type_name}</td>
                                                                        <td>
                                                                            {item.membership_name.split('¥r¥n').map((item, key) => {
                                                                                return (<Fragment key={key}>{item}<br /></Fragment>);
                                                                            })}
                                                                        </td>
                                                                        <td>{item.organization_name}</td>
                                                                        <td>{item.status_name}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getOrganizationManagementList.isFetching,
        data: state.getOrganizationManagementList.data,
        isError: state.getOrganizationManagementList.isError,
        err_type: state.getOrganizationManagementList.err_type,
        err_detail: state.getOrganizationManagementList.err_detail,
        isUpdateFetching: state.updateOrganizationManagement.isFetching,
        updateData: state.updateOrganizationManagement.data,
        isUpdateError: state.updateOrganizationManagement.isError,
        updateErr_type: state.updateOrganizationManagement.err_type,
        updateErr_detail: state.updateOrganizationManagement.err_detail,
        isDeleteFetching: state.deleteOrganizationManagement.isFetching,
        deleteData: state.deleteOrganizationManagement.data,
        isDeleteError: state.deleteOrganizationManagement.isError,
        deleteErr_type: state.deleteOrganizationManagement.err_type,
        deleteErr_detail: state.deleteOrganizationManagement.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getOrganizationManagementList(args) {
            // 組織管理情報取得
            dispatch(getOrganizationManagementList(args));
        },
        organizationManagementListReset() {
            // 組織管理情報取得Reset
            dispatch(getOrganizationManagementListReset());
        },
        updateOrganizationManagement(args) {
            // 組織管理情報更新(初期データ取得)
            dispatch(updateOrganizationManagement(args));
        },
        updateOrganizationManagementReset() {
            // 組織管理情報更新Reset
            dispatch(updateOrganizationManagementReset());
        },
        deleteOrganizationManagement(args) {
            // 組織管理情報削除
            dispatch(deleteOrganizationManagement(args));
        },
        deleteOrganizationManagementReset() {
            // 組織管理情報削除Reset
            dispatch(deleteOrganizationManagementReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMOIR01);

