import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GESSFELI01ActionType';

// 評価対象一覧取得API
const GET_EVALUATION_TARGETS_LIST_POST = BASE_URL + "/api/es_sf_get_evaluation_targets_list";

// ********************Actionの作成 Start ********************
/**
 * 評価対象一覧取得
 */
export const GetEvaluationTargetsListRequest = () => {
    return {
        type: actionTypes.GET_EVALUATION_TARGETS_LIST_REQUEST
    };
};

/**
 * 評価対象一覧取得成功
 */
export const GetEvaluationTargetsListSuccess = (data) => {
    return {
        type: actionTypes.GET_EVALUATION_TARGETS_LIST_SUCCESS,
        data
    };
};

/**
 * 評価対象一覧取得失敗
 */
export const GetEvaluationTargetsListFailure = (err) => {
    return {
        type: actionTypes.GET_EVALUATION_TARGETS_LIST_FAILURE,
        err
    };
};

/**
 * 評価対象一覧取得リセット
 */
export const GetEvaluationTargetsListReset = () => {
    return {
        type: actionTypes.GET_EVALUATION_TARGETS_LIST_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 評価対象一覧取得Actionの発行
 */
export const getEvaluationTargetsList = (data) => {
    return async (dispatch) => {
        // Storeへ評価対象一覧取得リクエストActionをdispatchする
        dispatch(GetEvaluationTargetsListRequest());
        ecoPostWithAuthHeader(GET_EVALUATION_TARGETS_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ評価対象一覧取得成功Actionをdispatchする
                dispatch(GetEvaluationTargetsListSuccess(res.data))
            ).catch(err =>
                // Storeへ評価対象一覧取得失敗Actionをdispatchする
                dispatch(GetEvaluationTargetsListFailure(err))
            );
    };
};