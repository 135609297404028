import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage, getCodeValueLabelList, getCognitoUserInfo } from "../../Constants/CommonConstants";
import { checkArray } from "../../Components/Tools/StringTools";
import { saveTrainingPlanTemplateInfo, saveTrainingPlanTemplateReset, } from "./GISTTPTR01Action";
import { trainingPlanTemplateDetailReset } from "../TtGISTTPTL01/GISTTPTL01Action";
import { MessageMap } from "./GISTTPTR01Message";
import { TRAINING_PLAN_TEMPLATE_DATA } from "../../Constants/IsConstants";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Textarea from "../../Components/Atoms/Textarea";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";

/**
 * 実習計画テンプレート登録
 */
class GISTTPTR01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            err_msg: [],
            templateInfoList: []
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { detailData, createorupdate } = this.props;

        // 新規登録場合
        if (createorupdate === 'create') {
            let registration = new Array(5).fill().map((item, index) => ({ ...TRAINING_PLAN_TEMPLATE_DATA, plan_detail_number: index + 1 }));
            this.setState({
                templateInfoList: registration,
                plan_template_name: "",
                isDisabled: false,
                createorupdate: createorupdate
            });
        } else if (createorupdate === 'update') { // コピー作成　または　修正の場合
            let result = detailData.map(item => ({
                plan_template_id: this.props.action && this.props.action.copyFlg ? '' : item.plan_template_id,
                version_no: this.props.action && this.props.action.copyFlg ? '' : item.version_no,
                plan_template_name: item.plan_template_name,
                plan_detail_number: item.plan_detail_number,
                work_type: item.work_type,
                work_name: item.work_name,
                work_contents: item.work_contents,
                hours_1: item.hours_1,
                hours_2: item.hours_2,
                hours_3: item.hours_3,
                hours_4: item.hours_4,
                hours_5: item.hours_5,
                hours_6: item.hours_6,
                hours_7: item.hours_7,
                hours_8: item.hours_8,
                hours_9: item.hours_9,
                hours_10: item.hours_10,
                hours_11: item.hours_11,
                hours_12: item.hours_12
            }));
            this.setState({
                templateInfoList: result,
                plan_template_name: result[0].plan_template_name,
                isDisabled: false,
                createorupdate: createorupdate
            });
        } else { // 詳細表示の場合
            this.setState({ isDisabled: true });
        }
    }

    /**
     * 表示モード取得
     */
    getReadMode = () => {
        // ログインユーザ．組織タイプ　＝　KAN:監理団体、COP:企業、TOR:登録支援機関、COD:コーディネーター以外の場合参照モード
        return this.props.action && this.props.action.modeFlg == '1' || !(['KAN', 'COP', 'TOR', 'COD'].includes(getCognitoUserInfo()['org_type']));
    }

    /**
     * 業務種別変更
     * @param {*} index
     */
    handleChange = index => event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandlerByObject(index, name, value);
        });
    };

    /**
     * 画面リストにデータを追加する。
     */
    addTemplate = () => {
        const { templateInfoList } = this.state;
        if (templateInfoList.length > 9) {
            // 表示中のメッセージリセットする
            this.props.saveTrainingPlanTemplateReset();
            this.setState({
                err_msg: [getMessage('RS-0056').replace(/%1/g, '10')]
            });
            window.scrollTo(0, 0);
        } else {
            const clone = { ...TRAINING_PLAN_TEMPLATE_DATA };
            clone.plan_detail_number = templateInfoList.length + 1;
            this.setState(prevState => ({
                templateInfoList: [...prevState.templateInfoList, clone]
            }));
        }

    }

    /**
     * 登録
     */
    saveTemplateInfo = () => {
        window.scrollTo(0, 0);
        this.setState({ err_msg: [] }, () => {
            const { templateInfoList, createorupdate } = this.state;

            // システムエラーのコールバック設定
            this.setErrorCallback(this.props.saveTrainingPlanTemplateReset());

            let workPlanTemplateData = templateInfoList.map((item, index) => ({
                plan_detail_number: index + 1,
                work_type: item.work_type,
                work_name: item.work_name,
                work_contents: item.work_contents,
                month_time: {
                    hours_1: item.hours_1,
                    hours_2: item.hours_2,
                    hours_3: item.hours_3,
                    hours_4: item.hours_4,
                    hours_5: item.hours_5,
                    hours_6: item.hours_6,
                    hours_7: item.hours_7,
                    hours_8: item.hours_8,
                    hours_9: item.hours_9,
                    hours_10: item.hours_10,
                    hours_11: item.hours_11,
                    hours_12: item.hours_12
                }
            }));

            let params = {};
            params['plan_template_id'] = createorupdate === 'create' ? '' : templateInfoList[0].plan_template_id;
            params['version_no'] = createorupdate === 'create' ? '' : templateInfoList[0].version_no;
            params['plan_template_name'] = this.state.plan_template_name;
            params['work_plan_template_data'] = workPlanTemplateData;

            this.props.saveTrainingPlanTemplateInfo(params);
        });
    }

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            templateInfoList: this.state.templateInfoList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        this.props.saveTrainingPlanTemplateReset();
        this.props.trainingPlanTemplateDetailReset();
        return this.props.changeDisp(changeTo);
    }

    render() {

        // 業務種別
        const workTypeOpt = getCodeValueLabelList('RSC0033');

        if (!this.props.data.isFetching) {
            if (this.props.data.data && this.props.data.err_type === '200') {
                let items = this.state.templateInfoList.slice(0, this.state.templateInfoList.length);
                items.forEach((item) => {
                    item.plan_template_id = this.props.data.data.plan_template_id;
                    item.version_no = this.props.data.data.version_no;
                }
                );
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    // 新規の場合のID設定
                    this.setState({
                        templateInfoList: items,
                        createorupdate: 'update'
                    });
                    this.props.saveTrainingPlanTemplateReset();
                });
            }
        }

        // 参照モード設定
        const isDisabled = this.getReadMode();

        let listSize = 0;
        if (checkArray(this.state.templateInfoList)) {
            listSize = this.state.templateInfoList.length;
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">実習計画テンプレート登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISTTPTL01'); }, () => { })}>実習計画テンプレート一覧</a></li>
                                <li>実習計画テンプレート登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.data.err_type} err_detail={this.props.data.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">実習計画テンプレート</h2>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable02 hp_mb20">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required hp_mr10">必須</span>
                                                        <br />
                                                        テンプレート名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" readOnly={isDisabled} className={isDisabled ? "el_input el_input__max is_disabled" : "el_input el_input__max"} name="plan_template_name" maxlength="40" required="required" setValue={this.state.plan_template_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="sticky_table_wrapper sticky_table_wrapperNoScroll">
                                            <table className="sticky_table mcTable_align el_fixedTable">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan="3">業務種別</th>
                                                        <th colSpan="9">業務名／業務内容</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.state.templateInfoList)) {
                                                            return (
                                                                this.state.templateInfoList.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="hp_tac hp_bg__gl" rowSpan="5">No.{item.plan_detail_number}</td>
                                                                            <td colSpan="3" rowSpan="2" className="hp_tac">
                                                                                <select disabled={isDisabled} readOnly={isDisabled} className={isDisabled ? "el_select el_select__w80per is_disabled" : "el_select el_select__w80per"} name="work_type" value={item.work_type} onChange={this.handleChange(index)} tabIndex={2 + index * 15}>
                                                                                    <option value="" selected></option>
                                                                                    {
                                                                                        checkArray(workTypeOpt) && workTypeOpt.map(item => (
                                                                                            <option key={item.value} value={item.value} selected={item.work_type === item.value}>
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </td>
                                                                            <td colSpan="9" className="hp_tac">
                                                                                <div className="bl_formUnit">
                                                                                    <TextInput type="text" readOnly={isDisabled} className={isDisabled ? "el_input el_input__max is_disabled" : "el_input el_input__max"} name="work_name" maxlength="40" required="required" setValue={item.work_name} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="業務名をここに記入してください" tabIndex={3 + index * 15} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="9" className="hp_tac">
                                                                                <div className="bl_formUnit">
                                                                                    <Textarea disabled={isDisabled} className={isDisabled ? "el_textarea is_disabled" : "el_textarea"} name="work_contents" rows="2" cols="15" maxlength="200" setValue={item.work_contents} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="業務内容をここに記入してください" tabIndex={4 + index * 15} />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan="12">月・時間</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>４月</th>
                                                                            <th>５月</th>
                                                                            <th>６月</th>
                                                                            <th>７月</th>
                                                                            <th>８月</th>
                                                                            <th>９月</th>
                                                                            <th>１０月</th>
                                                                            <th>１１月</th>
                                                                            <th>１２月</th>
                                                                            <th>１月</th>
                                                                            <th>２月</th>
                                                                            <th>３月</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_4" maxlength="3" setValue={item.hours_4} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={5 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_5" maxlength="3" setValue={item.hours_5} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={6 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_6" maxlength="3" setValue={item.hours_6} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={7 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_7" maxlength="3" setValue={item.hours_7} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={8 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_8" maxlength="3" setValue={item.hours_8} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={9 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_9" maxlength="3" setValue={item.hours_9} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={10 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_10" maxlength="3" setValue={item.hours_10} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={11 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_11" maxlength="3" setValue={item.hours_11} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={12 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_12" maxlength="3" setValue={item.hours_12} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={13 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_1" maxlength="3" setValue={item.hours_1} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={14 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_2" maxlength="3" setValue={item.hours_2} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={15 + index * 15} />&nbsp;h</td>
                                                                            <td><TextInput readOnly={isDisabled} type="text" className={isDisabled ? "el_input el_fontSize__sm is_disabled" : "el_input el_fontSize__sm"} name="hours_3" maxlength="3" setValue={item.hours_3} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex={16 + index * 15} />&nbsp;h</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r">
                                            <button type="button" disabled={isDisabled} readOnly={isDisabled} value="+" className={isDisabled ? "el_funcBtn is_disabled" : "el_funcBtn"} onClick={() => this.addTemplate()} tabIndex={17 + listSize * 15}><img src={Plus_Icon} alt="プラス" />追加</button>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit">
                                    <button type="button" disabled={isDisabled} readOnly={isDisabled} className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveTemplateInfo()} tabIndex={18 + listSize * 15}>登録する</button>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISTTPTL01'); }, () => { })} tabIndex={19 + listSize * 15}>テンプレート一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.saveTrainingPlanTemplateInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveTrainingPlanTemplateInfo: (arg) => dispatch(saveTrainingPlanTemplateInfo(arg)),
        saveTrainingPlanTemplateReset: () => dispatch(saveTrainingPlanTemplateReset()),
        trainingPlanTemplateDetailReset: () => dispatch(trainingPlanTemplateDetailReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISTTPTR01);

