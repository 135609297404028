
export const imageResize = (target, callBack) => {
    const { files } = target;
    if (files.length) {
        // FileReaderを作成
        let reader = new FileReader();

        // 読み込み完了時のイベント
        reader.onload = (e) => {
            let img = new Image();
            img.onload = () => {
                let width = img.width;
                let height = img.height;
                if (width > 300 || height > 300) {
                    if (width > height) {
                        height = Math.round(height * 300 / width);
                        width = 300;
                    } else {
                        width = Math.round(width * 300 / height);
                        height = 300;
                    }
                    let canvas = document.createElement("canvas");
                    // サイズを設定する
                    canvas.width = width;
                    canvas.height = height;
                    // 二次元グラフィックスのコンテキストを取得
                    let ctx = canvas.getContext("2d");
                    ctx.drawImage(img, 0, 0, width, height);
                    ctx.canvas.toBlob((blob) => {
                        const imageFile = new File([blob], "thumb_" + reader.name, {
                            type: reader.type,
                            lastModified: Date.now()
                        });
                        let file_reader = new FileReader();
                        file_reader.readAsDataURL(imageFile);
                        file_reader.onload = (small) => {
                            callBack(small.target.result);
                        };
                    }, reader.type, 1);
                } else {
                    callBack(e.target.result);
                }
            };
            img.src = e.target.result;
        };
        // 読み込みを実行
        reader.readAsDataURL(files[0]);
    }
};

