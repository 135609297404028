import actionTypes from './GICIAIAS01ActionType';

/**
 * 保険申込一覧
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 保険申込一覧を検索するReducer
 * @param {*} state
 * @param {*} action
 */
export const searchInsuranceApplicationList = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSURANCE_APPLICATION_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.INSURANCE_APPLICATION_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_LIST_UPDATE:
            return Object.assign({}, state, {
                data: action.data
            });
        default:
            return state;
    }
};

/**
 * 保険申込一覧を更新するReducer
 * @param {*} state
 * @param {*} action
 */
export const updateInsuranceApplicationList = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSURANCE_APPLICATION_UPDATE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_UPDATE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.INSURANCE_APPLICATION_UPDATE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_UPDATE_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
