import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import GRSMMAMF01 from "../Rs/MmGRSMMAMF01/GRSMMAMF01";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GAFLFCPF01 from "../Af/LfGAFLFCPF01/GAFLFCPF01";
import GRSMMERF01 from "../Rs/MmGRSMMERF01/GRSMMERF01";
import GRSMMAMF02 from "../Rs/MmGRSMMAMF02/GRSMMAMF02";
import GCMOMCUM01 from "./OmGCMOMCUM01/GCMOMCUM01";
import GCMOMCUM02 from "./OmGCMOMCUM02/GCMOMCUM02";
import GCMOMOIR01 from "./OmGCMOMOIR01/GCMOMOIR01";
import GCMOMOIR02 from "./OmGCMOMOIR02/GCMOMOIR02";
import GCMOMCRU01 from "./OmGCMOMCRU01/GCMOMCRU01";
import GCMOMCRU02 from "./OmGCMOMCRU02/GCMOMCRU02";
import GCMOMCRU03 from "./OmGCMOMCRU03/GCMOMCRU03";
import GCMOMCRU04 from "./OmGCMOMCRU04/GCMOMCRU04";
import GCMOMCRU05 from "./OmGCMOMCRU05/GCMOMCRU05";
import GCMOMCRU08 from "./OmGCMOMCRU08/GCMOMCRU08";
import GCMOMCRU09 from "./OmGCMOMCRU09/GCMOMCRU09";
import GCMOMORU01 from "./OmGCMOMORU01/GCMOMORU01";
import GCMOMORU02 from "./OmGCMOMORU02/GCMOMORU02";
import GCMOMCRU10 from "./OmGCMOMCRU10/GCMOMCRU10";
import GCMOMORU03 from "./OmGCMOMORU03/GCMOMORU03";
import { USER_MANAGEMENT_DATA, ORGANIZATION_MANAGEMENT_DATA } from "../Constants/CmConstants";
import { getCandidateDetail, getCandidateDetailReset } from "../Rs/MmGRSMMAMF01/GRSMMAMF01Action";
import { getCompanyAndFacilityInfo, getCompanyAndFacilityInfoReset } from "./OmGCMOMCRU01/GCMOMCRU01Action";
import { getOrganizationManagementListReset, updateOrganizationManagementReset } from "./OmGCMOMOIR01/GCMOMOIR01Action";
import { getUserManagementListReset, updateUserManagementReset } from "./OmGCMOMORU01/GCMOMORU01Action";
import { getSendingOrganizationInfo, getSendingOrganizationInfoReset } from "./OmGCMOMCRU03/GCMOMCRU03Action";
import { getUniversityInfo, getUniversityInfoReset } from "./OmGCMOMCRU04/GCMOMCRU04Action";
import { getSupervisingOrgInfo, getSupervisingOrgInfoReset } from "./OmGCMOMCRU05/GCMOMCRU05Action";
import { getEmployFacilityInfo, getEmployFacilityInfoReset } from "./OmGCMOMCRU08/GCMOMCRU08Action";
import { getTrainningWorkerList, getTrainningWorkerListReset } from "./OmGCMOMCUM01/GCMOMCUM01Action";
import { getTrainningWorkerDetail, getTrainningWorkerDetailReset } from "./OmGCMOMCUM02/GCMOMCUM02Action";
import { getSupportOrgInfo, getSupportOrgInfoReset } from "./OmGCMOMCRU10/GCMOMCRU10Action";
import { getChangeOrgInfo, getChangeOrgInfoReset } from "./OmGCMOMORU03/GCMOMORU03Action";
import { getCognitoUserInfo } from "../Constants/CommonConstants";
import { isBlank } from "../Components/Tools/StringTools";

/**
 * ユーザ/組織管理機能テンプレート
 */
class CmTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: null,
            beforeDispId: null,
            candidateInit: null,
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            candidateInit: this.props.location.candidateInit,
            detailData: null
        }, () => {
            if (this.state.display === "showProfile" && this.state.candidateInit) {
                this.setState({ candidateInit: false }, () => {
                    this.props.getCandidateDetail(getCognitoUserInfo()['candidate_id']);
                });
            } else if (this.state.display === "GCMOMCRU01") {
                this.props.getCompanyAndFacilityInfo("1");
            } else if (this.state.display === "GCMOMCRU02") {
                this.props.getCompanyAndFacilityInfo("2");
            } else if (this.state.display === "GCMOMCRU03") {
                this.props.getSendingOrganizationInfo();
            } else if (this.state.display === "GCMOMCRU04") {
                this.props.getUniversityInfo();
            } else if (this.state.display === "GCMOMCRU08") {
                this.props.getUniversityInfo();
                this.props.getEmployFacilityInfo();
            } else if (this.state.display === "GCMOMCRU09") {
                this.props.getSendingOrganizationInfo();
                this.props.getEmployFacilityInfo();
            } else if (this.state.display === "GCMOMCRU05") {
                this.props.getSupervisingOrgInfo();
            } else if (this.state.display === "GCMOMCUM02") {
                this.props.getTrainningWorkerDetail();
            } else if (this.state.display === "GCMOMCRU10") {
                this.props.getSupportOrgInfo();
            }
        });
    }

    /**
     * 画面初期化処理を呼び出す
     */
    callInitialization = () => {
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            detailData: detailData
        }, () => {
            if (this.state.display === "showProfile") {
                this.props.getCandidateDetailReset();
            } else if (this.state.display === "GCMOMCRU03") {
                this.props.getSendingOrganizationInfoReset();
            } else if (this.state.display === "GCMOMCRU04") {
                this.props.getUniversityInfoReset();
            } else if (this.state.display === "GCMOMOIR01") {
                this.props.getOrganizationManagementListReset();
            } else if (this.state.display === "GCMOMORU01") {
                this.props.getUserManagementListReset();
            }
            this.props.getSupervisingOrgInfoReset();
            this.props.getCompanyAndFacilityInfoReset();
            this.props.getEmployFacilityInfoReset();
            this.props.getSupportOrgInfoReset();
            this.props.getChangeOrgInfoReset();
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplayWithData = (dispId, detailData) => {
        const beforeDispId = this.state.display;

        let organizationData = { ...ORGANIZATION_MANAGEMENT_DATA, ...detailData };
        let userData = { ...USER_MANAGEMENT_DATA, ...detailData };

        // 組織管理の場合
        if (beforeDispId === 'GCMOMOIR01') {
            this.setState({
                detailData: isBlank(detailData.organization_id) ? organizationData : detailData
            });
        } else if (beforeDispId === 'GCMOMORU01') { // ユーザ管理の場合
            this.setState({
                detailData: isBlank(detailData.user_id) ? userData : detailData
            });
        }
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId
        }, () => {
            this.props.getOrganizationManagementListReset();
            this.props.updateOrganizationManagementReset();
            this.props.getUserManagementListReset();
            this.props.updateUserManagementReset();
        });
    }

    /**
    * 画面切替処理（データ連携あり）
    * @param {*} dispId
    * @param {*} outline
    * @param {*} detail
    */
    switchDisplayUser = (dispId, detail) => {
        this.setState({
            display: dispId,
            detailData: detail
        }, () => {
            this.props.updateUserManagementReset();
        });
    }

    /**
    * 画面切替処理（データ連携あり）
    * @param {*} dispId
    * @param {*} outline
    * @param {*} detail
    */
    switchDisplayWithParameter = (dispId, detail, source) => {
        this.setState({
            display: dispId,
            detailData: detail,
            displaySource: source
        }, () => {
            this.props.getCandidateDetailReset();
            this.props.getTrainningWorkerDetailReset();
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToFacility = (dispId) => {
        this.props.getCompanyAndFacilityInfo("2");
        this.setState({
            display: dispId,
            beforeDispId: "GCMOMCRU01"
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToCompany = (dispId) => {
        this.props.getCompanyAndFacilityInfo("1");
        this.setState({
            display: dispId,
            beforeDispId: "GCMOMCRU02"
        });
    }

    /**
     * 応募者検索から応募者編集へ遷移する
     * @param {*} candidateId
     */
    showEditCandidate = (candidateId) => {
        this.setState({ candidateInit: false, display: "showEditProfile" }, () => {
            this.props.getCandidateDetail(candidateId);
        });
    }

    render() {
        let displayParts;
        if (this.state.display === "GRSMMAMF01") {
            // 応募者検索画面
            displayParts = <GRSMMAMF01 skipToNext={this.switchDisplayWithParameter} toEditProfile={this.showEditCandidate} beforeDispId="CmTemplate" />;
            this.callInitialization();
        } else if (this.state.display === "GRSMMAMF02") {
            // 応募者詳細画面
            displayParts = <GRSMMAMF02 changeDisp={this.switchDisplay} displaySource="GRSMMAMF01" beforeDispId="GRSMMAMF01" candidateDetail={this.state.detailData} caseDetail={null} candidateList={null} switchDisplaySelectedCandidate={this.switchDisplay} />;
        } else if (this.state.display === "GCMOMOIR01") {
            // 組織管理
            displayParts = <GCMOMOIR01 detailData={this.state.detailData} changeDispWithData={this.switchDisplayWithData} />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMOIR02") {
            // 組織情報登録・訂正
            displayParts = <GCMOMOIR02 changeDisp={this.switchDisplay} detailData={this.state.detailData} />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMORU01") {
            // ユーザ管理
            displayParts = <GCMOMORU01 changeDispWithData={this.switchDisplayWithData} />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMORU02") {
            // ユーザ情報登録・訂正
            displayParts = <GCMOMORU02 changeDisp={this.switchDisplay} switchDisplayUser={this.switchDisplayUser} detailData={this.state.detailData} />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU01") {
            // 組織情報(プロフィール)企業画面
            if (this.props.companyAndFacilityData && this.props.companyAndFacilityErrtype === '200') {
                displayParts = <GCMOMCRU01 changeDisp={this.switchDisplay} detailData={this.props.companyAndFacilityData} facilityData={null} redirectToFacility={this.redirectToFacility} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU02") {
            // 組織情報(プロフィール)施設画面
            if (this.props.companyAndFacilityData && this.props.companyAndFacilityErrtype === '200') {
                displayParts = <GCMOMCRU02 changeDisp={this.switchDisplay} detailData={this.props.companyAndFacilityData} facilityData={this.props.companyAndFacilityData} redirectToCompany={this.redirectToCompany} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU03") {
            // 組織情報(プロフィール)送り出し機関画面
            if (this.props.sendingOrganizationData && this.props.sendingOrganizationErrtype === '200') {
                displayParts = <GCMOMCRU03 changeDisp={this.switchDisplay} detailData={this.props.sendingOrganizationData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU04") {
            // 組織情報(プロフィール)大学画面
            if (this.props.universityData && this.props.universityErrtype === '200') {
                displayParts = <GCMOMCRU04 changeDisp={this.switchDisplay} detailData={this.props.universityData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU05") {
            // 組織情報(プロフィール)監理団体画面
            if (this.props.supervisingOrgData && this.props.supervisingOrgErrtype === '200') {
                displayParts = <GCMOMCRU05 changeDisp={this.switchDisplay} detailData={this.props.supervisingOrgData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU08") {
            // 組織情報(プロフィール)インターン生
            if (this.props.universityData && this.props.employFacilityData && this.props.universityErrtype === '200' && this.props.employFacilityErrtype === '200') {
                displayParts = <GCMOMCRU08 changeDisp={this.switchDisplay} uniDetailData={this.props.universityData} facDetailData={this.props.employFacilityData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU09") {
            // 組織情報(プロフィール)技能実習・特定技能向け
            if (this.props.sendingOrganizationData && this.props.employFacilityData && this.props.sendingOrganizationErrtype === '200' && this.props.employFacilityErrtype === '200') {
                displayParts = <GCMOMCRU09 changeDisp={this.switchDisplay} sendOrgDetailData={this.props.sendingOrganizationData} facDetailData={this.props.employFacilityData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "showProfile" || this.state.display === "showEditProfile") {
            // 応募者の自分プロフィール編集
            if (this.props.data && this.props.err_type === '200') {
                if (this.state.display === "showProfile") {
                    displayParts = <GRSMMERF01 changeDisp={this.switchDisplay} beforeDispId="GRSMMERF01" detailData={this.props.data} />;
                } else if (this.state.display === "showEditProfile") {
                    displayParts = <GRSMMERF01 changeDisp={this.switchDisplay} beforeDispId="CmTemplate" detailData={this.props.data} />;
                }
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMCUM01") {
            // 実習／労働者一覧
            displayParts = <GCMOMCUM01 skipToNext={this.switchDisplayWithParameter} beforeDispId="CmTemplate" />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMCUM02") {
            // 実習／労働者詳細
            displayParts = <GCMOMCUM02 changeDisp={this.switchDisplayWithParameter} beforeDispId="GCMOMCUM01" detailData={this.state.detailData} />;
            this.callInitialization();
        } else if (this.state.display === "GAFLFCPF01") {
            // パスワード変更画面
            displayParts = <GAFLFCPF01 changeDisp={this.switchDisplay} />;
            this.callInitialization();
        } else if (this.state.display === "GCMOMCRU10") {
            // 組織情報(プロフィール)支援機関画面
            if (this.props.supportOrgData && this.props.supportOrgErrtype === '200') {
                displayParts = <GCMOMCRU10 changeDisp={this.switchDisplay} detailData={this.props.supportOrgData} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GCMOMORU03") {
            // 組織変更画面
            displayParts = <GCMOMORU03 changeDisp={this.switchDisplay} />;
            this.callInitialization();
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

/**
 * データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.getCandidateDetail.isFetching,
        data: state.getCandidateDetail.data,
        isError: state.getCandidateDetail.isError,
        err_type: state.getCandidateDetail.err_type,
        err_detail: state.getCandidateDetail.err_detail,
        // 組織情報(プロフィール)大学
        universityData: state.getUniversityInfo.data,
        isUniversityError: state.getUniversityInfo.isError,
        universityErrtype: state.getUniversityInfo.err_type,
        universityErrDetail: state.getUniversityInfo.err_detail,
        // 組織情報(プロフィール)監理団体
        supervisingOrgData: state.getSupervisingOrgInfo.data,
        isSupervisingOrgError: state.getSupervisingOrgInfo.isError,
        supervisingOrgErrtype: state.getSupervisingOrgInfo.err_type,
        supervisingOrgErrDetail: state.getSupervisingOrgInfo.err_detail,
        // 組織情報(プロフィール)送り出し機関
        sendingOrganizationData: state.getSendingOrganizationInfo.data,
        isSendingOrganizationError: state.getSendingOrganizationInfo.isError,
        sendingOrganizationErrtype: state.getSendingOrganizationInfo.err_type,
        sendingOrganizationErrDetail: state.getSendingOrganizationInfo.err_detail,
        // 組織情報(プロフィール)企業情報・企業施設情報
        isCompanyAndFacilityFetching: state.getCompanyAndFacilityInfo.isFetching,
        companyAndFacilityData: state.getCompanyAndFacilityInfo.data,
        isCompanyAndFacilityError: state.getCompanyAndFacilityInfo.isError,
        companyAndFacilityErrtype: state.getCompanyAndFacilityInfo.err_type,
        companyAndFacilityErrDetail: state.getCompanyAndFacilityInfo.err_detail,
        // 組織情報(プロフィール)インターン向け
        employFacilityData: state.getEmployFacilityInfo.data,
        isEmployFacilityError: state.getEmployFacilityInfo.isError,
        employFacilityErrtype: state.getEmployFacilityInfo.err_type,
        employFacilityErrDetail: state.getEmployFacilityInfo.err_detail,
        // 実習／労働者一覧
        trainningWorkerData: state.getTrainningWorkerList.data,
        isTrainningWorkerError: state.getTrainningWorkerList.isError,
        trainningWorkerErrtype: state.getTrainningWorkerList.err_type,
        trainningWorkerErrDetail: state.getTrainningWorkerList.err_detail,
        // 組織情報(プロフィール)支援機関
        supportOrgData: state.getSupportOrgInfo.data,
        isSupportOrgError: state.getSupportOrgInfo.isError,
        supportOrgErrtype: state.getSupportOrgInfo.err_type,
        supportOrgErrDetail: state.getSupportOrgInfo.err_detail,
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
function mapDispatchToProps(dispatch) {
    return {
        getCandidateDetail(condition) {
            // 応募者詳細取得
            dispatch(getCandidateDetail(condition));
        },
        getCandidateDetailReset() {
            dispatch(getCandidateDetailReset());
        },
        getUniversityInfo() {
            // 組織情報(プロフィール)大学取得
            dispatch(getUniversityInfo({
                'Organization_id': null
            }));
        },
        getUniversityInfoReset() {
            dispatch(getUniversityInfoReset());
        },
        getSupervisingOrgInfo() {
            // 組織情報(プロフィール)監理団体取得
            dispatch(getSupervisingOrgInfo());
        },
        getSupervisingOrgInfoReset() {
            dispatch(getSupervisingOrgInfoReset());
        },
        getSendingOrganizationInfo() {
            // 組織情報(プロフィール)送り出し機関取得
            dispatch(getSendingOrganizationInfo({
                'Organization_id': null
            }));
        },
        getSendingOrganizationInfoReset() {
            dispatch(getSendingOrganizationInfoReset());
        },
        getCompanyAndFacilityInfo(type) {
            // 組織情報(プロフィール)企業情報取得
            dispatch(getCompanyAndFacilityInfo({
                'get_type': type
            }));
        },
        getCompanyAndFacilityInfoReset() {
            dispatch(getCompanyAndFacilityInfoReset());
        },
        getEmployFacilityInfo() {
            // 組織情報(プロフィール)インターン向け
            dispatch(getEmployFacilityInfo());
        },
        getEmployFacilityInfoReset() {
            dispatch(getEmployFacilityInfoReset());
        },
        // 組織管理情報リセット
        getOrganizationManagementListReset() {
            dispatch(getOrganizationManagementListReset());
        },
        // 組織管理詳細情報リセット
        updateOrganizationManagementReset() {
            dispatch(updateOrganizationManagementReset());
        },
        // ユーザ管理情報リセット
        getUserManagementListReset() {
            dispatch(getUserManagementListReset());
        },
        // ユーザ管理詳細情報リセット
        updateUserManagementReset() {
            dispatch(updateUserManagementReset());
        },
        getTrainningWorkerList() {
            // 実習／労働者一覧
            dispatch(getTrainningWorkerList());
        },
        getTrainningWorkerListReset() {
            dispatch(getTrainningWorkerListReset());
        },
        getTrainningWorkerDetail() {
            // 実習／労働者詳細
            dispatch(getTrainningWorkerDetail());
        },
        getTrainningWorkerDetailReset() {
            dispatch(getTrainningWorkerDetailReset());
        },
        getSupportOrgInfo() {
            // 組織情報(プロフィール)支援機関取得
            dispatch(getSupportOrgInfo());
        },
        getSupportOrgInfoReset() {
            dispatch(getSupportOrgInfoReset());
        },
        getChangeOrgInfo() {
            // 組織変更取得
            dispatch(getChangeOrgInfo());
        },
        getChangeOrgInfoReset() {
            dispatch(getChangeOrgInfoReset());
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CmTemplate));
