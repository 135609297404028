import React from 'react';
import TmTemplate from "./template";

function TmContainer() {
    return (
        <section className="App">
            <TmTemplate />
        </section>
    );
}

export default TmContainer;