import React from "react";
import { ecoPost } from "../../Components/Tools/HttpTools";
import { withRouter } from "react-router";
import TextInput from "../../Components/Atoms/TextInput";
import ModalMessage from "../../Components/Atoms/ModalMessage";
import { MessageMap } from "./GAFLFPRF01Message";
import Service_Icon from "../../Images/service_logo.png";
import { BASE_URL, getMessage, setMessageMaster } from "../../Constants/CommonConstants";
import "../../Css/Common.css";
import Load from "../../Components/Atoms/Load";

// API
const REMIND_PASSWORD_POST = BASE_URL + "/api/af_lf_remind_password";

/**
 * パスワードリマインド画面
 */
class GAFLFPRF01 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isSuccess: false,
            isloading: false,
            errorShowing: false,
            errorText: ""
        };
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 送信
     */
    sendEmail = () => {
        let remindPasswordPara = {};
        remindPasswordPara['register_mail_address'] = this.state.register_mail_address;
        this.setState({ isloading: true });
        this.setState({ errorShowing: false });
        // 認証Lambda関数を呼び出し
        ecoPost(REMIND_PASSWORD_POST, JSON.stringify(remindPasswordPara), { headers: {} }).then(
            response => {
                if (response.data.data.message_master != null) {
                    // メッセージマスタデータ設定
                    setMessageMaster(response.data.data.message_master);
                }
                if (response.data.err_type == "200") {
                    this.setState({ isSuccess: true });
                    this.setState({ errorShowing: false });
                }
                else if (response.data.err_type == "300") {
                    this.setState({ errorShowing: true });
                    this.setState({ errorText: getMessage(response.data.err_detail[0]["err_code"]) });
                }
                else if (response.data.err_type == "310") {
                    this.setState({ errorShowing: true });
                    let errorMessage = "";
                    for (let i = 0; i < response.data.err_detail.length; i++) {
                        if (i == 0) {
                            errorMessage = getMessage(response.data.err_detail[i]["err_code"]).replace(/%1/g, MessageMap[response.data.err_detail[i]["err_item"]]);
                        } else {
                            errorMessage = errorMessage + "\n" + getMessage(response.data.err_detail[i]["err_code"]).replace(/%1/g, MessageMap[response.data.err_detail[i]["err_item"]]);
                        }
                        this.setState({ errorText: errorMessage });
                    }
                }
                this.setState({ isloading: false });
            }).catch(error => {
                this.setState({ isError: true });
                this.setState({ isloading: false });
            });
    };

    /**
     * 画面切替処理
     */
    redirectToNextPage = () => {
        // ログイン画面に移動
        this.props.redirectToNextPage("GAFLFLAF01");
    }

    /**
     * メニュー初期化完了イベント
     */
    componentDidMount = () => {
        // 戻るボタン禁止
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', this.onBackButtonEvent);
    }

    /**
     * メニューUnloadイベント
     */
    componentWillUnmount = () => {
        // 戻るボタン禁止解除
        window.removeEventListener('popstate', this.onBackButtonEvent);
    }

    /**
     * Backボタン対策
     * @param {*} e
     */
    onBackButtonEvent = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
    }

    closeMsg = () => {
        this.setState({ isError: false });
        this.setState({ isSuccess: false });
    }

    render() {
        return (
            <body>
                <header class="ly_header">
                    <p class="el_headerLogo">
                        <img src={Service_Icon} alt="service Logo" />
                    </p>
                </header>
                <div class="ly_cont">
                    <main class="ly_cont_main ly_cont_login">
                        <div class="ly_mainCont">
                            {this.state.isloading ? <Load setLabel="Loading" /> : null}
                            {this.state.isError ? <ModalMessage type={9} okClick={this.closeMsg} /> : null}
                            {this.state.isSuccess ? <ModalMessage type={0} content={getMessage("AF-0012")} okClick={this.redirectToNextPage} /> : null}
                            <section class="bl_sect">
                                <div class="ly_mainCont_inner">
                                    {this.state.errorShowing
                                        ? <div id="errorarea">
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.errorText.split("\n")).map(
                                                        ([key, value]) => (
                                                            <li className="errorText">{value}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div>
                                        : null
                                    }
                                    <div class="bl_sect_inner">
                                        <section class="bl_sect_frame">
                                            <p class="el_aside">メールアドレスを入力して「送信」ボタンを押してください。<br />送信されたメールに新規パスワードが記入されていますので、そちらでログインしてからパスワードの再設定をお願いします。</p>
                                            <div class="bl_formUnit">
                                                <TextInput type="text" placeholder="登録メールアドレス" name="register_mail_address" className="el_input el_input__max" setValue={this.state.register_mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                            </div>
                                            <div class="bl_horizBtnUnit">
                                                <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.sendEmail()} tabIndex="2">送信</button>
                                            </div>
                                        </section>
                                    </div>
                                    <button type="button" class="el_blueBtn" onClick={() => this.redirectToNextPage()} tabIndex="3">ログイン画面へ</button>
                                </div>
                            </section>
                        </div>
                    </main>
                </div>
            </body>
        );
    }
}

/**
 * Actionバインディングする
 * @param {*} dispatch
 */
export default withRouter((GAFLFPRF01));
