import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GISDMIRI01ActionType';

// 実習レポート照会API
const GET_TRAINING_REPORT_INFO_POST = BASE_URL + "/api/is_dm_get_training_report_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetTrainingReportListRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_REPORT_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetTrainingReportListSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_REPORT_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetTrainingReportListFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_REPORT_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetTrainingReportListReset = () => {
    return {
        type: actionTypes.GET_TRAINING_REPORT_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getTrainingReportList = (data) => {
    return async (dispatch) => {
        dispatch(GetTrainingReportListRequest());
        ecoPostWithAuthHeader(GET_TRAINING_REPORT_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetTrainingReportListSuccess(res.data))
        ).catch(
            err => dispatch(GetTrainingReportListFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getTrainingReportListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainingReportListReset());
    };
};
