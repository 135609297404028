import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GCMOMORU02ActionType";

// ユーザ管理更新API
const UPDATE_USER_MANAGEMENT_POST = BASE_URL + "/api/cm_om_update_system_user_tbl_info";

// ********************Actionの作成 Start ********************

/**
 * 更新リクエスト
 */
export const SaveUserManagementRequest = () => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const SaveUserManagementSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const SaveUserManagementFailure = (err) => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const SaveUserManagementReset = () => {
    return {
        type: actionTypes.UPDATE_USER_MANAGEMENT_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 修正処理
 * @param {*} data
 */
export const saveUserManagement = (data) => {
    return async (dispatch) => {
        dispatch(SaveUserManagementRequest());
        ecoPostWithAuthHeader(UPDATE_USER_MANAGEMENT_POST, JSON.stringify(data))
            .then((res) => dispatch(SaveUserManagementSuccess(res.data)))
            .catch((err) => dispatch(SaveUserManagementFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const saveUserManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveUserManagementReset());
    };
};
