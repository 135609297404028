import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css"
import { MessageMap } from "./GCMOMORU03Message";
import { getChangeOrgInfo, getChangeOrgInfoReset, updateChangeOrgInfo, updateChangeOrgInfoReset } from "./GCMOMORU03Action";

const isBlank = (value) => {
    if (value == null) {
        return true;
    } else if (value == undefined) {
        return true;
    } else if (value === '') {
        return true;
    } else if (value.length == 0) {
        return true;
    }
    return false;
};

/**
 * 組織変更
 */
class GCMOMORU03 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isUpdate: false,
            residenceOpt: [],
            changeOrgInfo: {
                mail_address: '',
                user_id: '',
                user_name: '',
                country_name: '',
                user_mail_address: '',
                university_name: '',
                candidate_id: '',
                sending_organization_name: '',
                status_of_residence: '',
                user_version_no: '',
                candidate_version_no: ''
            },
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    /**
    * 検索
    */
    searchChangeOrgInfo = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.reset);

        let reqData = JSON.parse(JSON.stringify({ 'mail_address': this.state.changeOrgInfo.mail_address }));
        this.props.searchChangeOrgInfo(reqData);
        this.setState({ isSearch: true });
    };

    /**
     * ローディング、エラー画面リセット
     */
    reset = () => {
        this.props.searchChangeOrgInfoReset();
        this.props.changeOrgInfoReset();
    };

    /**
    * 登録
    */
    handlingUpdateOKAction = () => {
        const { changeOrgInfo } = this.state;
        let reqData = JSON.parse(JSON.stringify({
            'user_id': changeOrgInfo.user_id,
            'candidate_id': changeOrgInfo.candidate_id,
            'user_version_no': changeOrgInfo.user_version_no,
            'candidate_version_no': changeOrgInfo.candidate_version_no,
        }));
        if (changeOrgInfo.status_of_residence == 'GNO') {
            reqData['status_of_residence'] = '0';
        } else if (changeOrgInfo.status_of_residence == 'TOK') {
            reqData['status_of_residence'] = '1';
        }
        this.props.createChangeOrgInfo(reqData);
        this.setState({ isUpdate: true });
    };

    /**
     * 登録処理
     */
    handlingUpdate = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.reset);

        let message = getMessage('RS-0084');
        // 確認ダイアログメッセージボックスを表示する
        this.showModalDialog(1, message, this.handlingUpdateOKAction, this.handlingCancelAction);
    };

    /**
     * 登録のキャンセル処理
     */
    handlingCancelAction = () => { }

    /**
     * 在留資格の変更
     * @param {*} event
     */
    handleStatusOfResidenceChange = (event) => {
        const { changeOrgInfo } = this.state;
        changeOrgInfo.status_of_residence = event.target.value;
        this.setState({
            changeOrgInfo: changeOrgInfo
        });
    };

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            changeOrgInfo: {
                ...prevState.changeOrgInfo,
                [name]: value
            }
        }));
    };

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        if (isBlank(detailData)) {
            return
        }
        this.props.searchChangeOrgInfoReset();
        this.props.changeOrgInfoReset();
        const { changeOrgInfo } = this.state;
        if (detailData.user_info && detailData.user_info.length > 0) {
            const user_info = detailData.user_info[0];
            changeOrgInfo.user_name = user_info.user_name;
            changeOrgInfo.country_name = user_info.country_name;
            changeOrgInfo.user_mail_address = user_info.user_mail_address;
            changeOrgInfo.university_name = user_info.university_name;
            changeOrgInfo.user_id = user_info.user_id;
            changeOrgInfo.candidate_id = user_info.candidate_id;
            changeOrgInfo.user_version_no = user_info.user_version_no;
            changeOrgInfo.candidate_version_no = user_info.candidate_version_no;

            if (detailData.self_org_name && detailData.self_org_name.length > 0) {
                const self_org_name = detailData.self_org_name[0];
                changeOrgInfo.sending_organization_name = self_org_name.sending_organization_name;
            }

            if (detailData.status_of_residence_list && detailData.status_of_residence_list.length > 0) {
                changeOrgInfo.status_of_residence = detailData.status_of_residence_list[0].code;
                this.setState({ residenceOpt: detailData.status_of_residence_list });
            }
            this.setState({ changeOrgInfo: changeOrgInfo });
        } else {
            changeOrgInfo.user_name = '';
            changeOrgInfo.country_name = '';
            changeOrgInfo.user_mail_address = '';
            changeOrgInfo.university_name = '';
            changeOrgInfo.user_id = '';
            changeOrgInfo.candidate_id = '';
            changeOrgInfo.user_version_no = '';
            changeOrgInfo.candidate_version_no = '';
            changeOrgInfo.sending_organization_name = '';
            changeOrgInfo.status_of_residence = '';
            this.setState({ changeOrgInfo: changeOrgInfo, residenceOpt: [] });
        }
    };

    /**
     * 完了メッセージ表示
     */
    showCompeleteMessage = () => {
        this.setState({
            isSearch: false,
            isUpdate: false,
            residenceOpt: [],
            changeOrgInfo: {
                mail_address: '',
                user_id: '',
                user_name: '',
                country_name: '',
                user_mail_address: '',
                university_name: '',
                candidate_id: '',
                sending_organization_name: '',
                status_of_residence: '',
                user_version_no: '',
                candidate_version_no: ''
            }
        });
        this.showModalDialog(0, getMessage('RS-0036'), () => { });
    };

    render() {
        const { changeOrgInfo } = this.state;
        if (this.props.getChangeOrgErrType == '200' && this.state.isSearch) {
            this.setState({ isSearch: false }, () => {
                this.setViewStatus(this.props.getChangeOrgData);
            });
        }
        if (this.props.updateChangeOrgErrType == '200' && this.state.isUpdate) {
            this.setState({ isUpdate: false }, () => {
                this.showCompeleteMessage();
            });
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織変更</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    組織変更
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.getChangeOrgErrType} err_detail={this.props.getChangeOrgErrDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.updateChangeOrgErrType} err_detail={this.props.updateChangeOrgErrDetail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>検索条件</span></h2>
                                </div>
                                <div className="bl_sect_frame bl_sect_frame_narrow">
                                    <table className="bl_horizTable">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span className="el_badge el_badge__required">必須</span><br />
                                                    メールアドレス
                                                </th>
                                                <td>
                                                    <div className="bl_formUnit">
                                                        <TextInput type="text" name="mail_address" className="el_input el_input__max" maxlength="81" required="required" setValue={changeOrgInfo.mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                        ※完全一致で検索します。
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.searchChangeOrgInfo()} tabIndex="2">検索</button>
                                    </div>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>ユーザ情報</span></h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="ly_mainCont_inner">
                                            <div className="bl_sect_inner">
                                                <section className="bl_sect_frame">
                                                    <table className="bl_horizTable">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    ユーザ名
                                                                </th>
                                                                <td>
                                                                    {changeOrgInfo.user_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    国籍
                                                                </th>
                                                                <td>
                                                                    {changeOrgInfo.country_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    メールアドレス
                                                                </th>
                                                                <td>
                                                                    {changeOrgInfo.user_mail_address}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    (変更前)所属組織
                                                                </th>
                                                                <td>
                                                                    {changeOrgInfo.university_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    (変更後)所属組織
                                                                </th>
                                                                <td>
                                                                    {changeOrgInfo.sending_organization_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <span className="el_badge el_badge__required">必須</span><br />
                                                                    (変更後)在留資格</th>
                                                                <td>
                                                                    <select className="el_select el_select__auto" name="status_of_residence" value={this.state.status_of_residence} onChange={this.handleStatusOfResidenceChange} tabIndex="3">
                                                                        {
                                                                            this.state.residenceOpt && this.state.residenceOpt.map(item => (
                                                                                <option key={item.code} value={item.code} selected={this.state.status_of_residence == item.code}>
                                                                                    {item.code_name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </section>
                                                <div className="bl_horizBtnUnit">
                                                    <button type="button" className="el_btn" onClick={() => this.handlingUpdate()} tabIndex="4">登録する</button>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        //  組織変更取得
        getChangeOrgIsFetching: state.getChangeOrgInfo.isFetching,
        getChangeOrgData: state.getChangeOrgInfo.data,
        getChangeOrgIsError: state.getChangeOrgInfo.isError,
        getChangeOrgErrType: state.getChangeOrgInfo.err_type,
        getChangeOrgErrDetail: state.getChangeOrgInfo.err_detail,
        //  組織変更更新
        updateChangeOrgIsFetching: state.updateChangeOrgInfo.isFetching,
        updateChangeOrgData: state.updateChangeOrgInfo.data,
        updateChangeOrgIsError: state.updateChangeOrgInfo.isError,
        updateChangeOrgErrType: state.updateChangeOrgInfo.err_type,
        updateChangeOrgErrDetail: state.updateChangeOrgInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        searchChangeOrgInfo(arg) {
            // 組織変更取得
            dispatch(getChangeOrgInfo(arg));
        },
        createChangeOrgInfo(arg) {
            // 組織変更更新
            dispatch(updateChangeOrgInfo(arg));
        },
        searchChangeOrgInfoReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(getChangeOrgInfoReset());
        },
        changeOrgInfoReset() {
            dispatch(updateChangeOrgInfoReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMORU03)