import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { MessageMap } from "../MmGRSMMIMR01/GRSMMIMR01Message";
import { createCase, createCaseReset } from "../MmGRSMMIMR01/GRSMMIMR01Action";
import { getCodeValueLabelList, getPrefecturesMasterList, getMessage } from "../../Constants/CommonConstants";
import { calculatePayment, calculateSalary } from "../../Constants/RsConstants";
import { getNumber, isBlank, getNumberText } from "../../Components/Tools/StringTools";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Minus_Icon from "../../Images/btn_minus.svg";
import Plus_Icon from "../../Images/btn_plus.svg";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import Intern_Icon from "../../Images/icon_intern.svg";
import "../../Css/Common.css";

/**
 * インターン案件募集要項登録（待遇）
 */
class GRSMMIMR04 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isDisabled: false,
            caseOutline: this.props.caseOutline,
            caseDetail: this.props.caseDetail
        };
    }

    /**
     * をセットする
     */
    componentDidMount = () => {
        const { caseDetail } = this.state;
        caseDetail.salary = getNumberText(caseDetail.salary);
        caseDetail.living_amount = getNumberText(caseDetail.living_amount);
        caseDetail.meal_amount = getNumberText(caseDetail.meal_amount);
        if (caseDetail.deduction) {
            caseDetail.deduction.forEach(item => {
                item.fee = getNumberText(item.fee);
            });
        }

        this.setState({
            working_day: caseDetail.working_day,
            working_hour: caseDetail.working_hour,
            working_minute: caseDetail.working_minute,
            salary: caseDetail.salary,
            living_place: caseDetail.living_place,
            wifi: caseDetail.wifi,
            water_light: caseDetail.water_light,
            living_amount: caseDetail.living_amount,
            meal_amount: caseDetail.meal_amount,
            transportation: caseDetail.transportation,
            hotels_service: caseDetail.hotels_service,
            meal_service: caseDetail.meal_service,
            deduction: caseDetail.deduction ? caseDetail.deduction : [],
            caseDetail: caseDetail
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '2' && caseDetail.sys_date < caseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });

        let minimum_wage = 0, prefectures_name = "";
        // 都道府県
        Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            if (v.prefectures_id == caseDetail.facility_prefectures_id) {
                minimum_wage = v.minimum_wage;
                prefectures_name = v.prefectures_name;
            }
        });

        this.setState({
            minimum_wage: minimum_wage,   // 最低賃金
            prefectures_name: prefectures_name  // 都道府県
        });

        window.scrollTo(0, 0);
    }

    /**
     * 寮有無の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleLivingPlaceChange = (event) => {
        const { value } = event.target;
        this.setState({
            living_place: value
        }, () => {
            this.editItemHandler('living_place', value);
            // 寮無し
            if (this.state.living_place === '0') {
                this.setState({ 'wifi': '0' }, () => {
                    this.editItemHandler('wifi', this.state.wifi);
                });
            }
        });
    }

    /**
     * WiFi有無の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleWiFiChange = (event) => {
        let chk = event.target.checked ? '1' : '0';
        this.setState({
            wifi: chk
        }, () => {
            this.editItemHandler('wifi', chk);
        });
    }

    /**
     * 水道光熱費の処理 (1: あり, 0: 無し)
     * @param {*} event
     */
    handleWaterLightChange = (event) => {
        const { value } = event.target;
        this.setState({
            water_light: value
        }, () => {
            this.editItemHandler('water_light', value);
        });
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * 手取り支援額の計算
     */
    calclateProceeds = () => {
        // 支援額
        const salary = this.state.caseDetail.salary;
        // 寮費
        const livingAmount = this.state.caseDetail.living_amount;
        // 食費
        const mealAmount = this.state.caseDetail.meal_amount;

        // 名目＿金額
        const amountList = this.state.caseDetail.deduction == null ? [] : this.state.caseDetail.deduction.map(item => {
            return item.fee;
        });

        // 差引額
        const deductionAmount = [livingAmount, mealAmount, ...amountList];

        // 手取り金額計算
        const proceeds = calculatePayment(salary, deductionAmount);

        //1日の働く時間
        const minutes = isBlank(this.state.caseDetail.working_minute) ? 0 : this.state.caseDetail.working_minute / 60;
        const workingTime = parseInt(isBlank(this.state.caseDetail.working_hour) ? 0 : this.state.caseDetail.working_hour) + minutes;

        const wage = calculateSalary(proceeds, this.state.caseDetail.working_day, workingTime);
        this.setState({
            support_amount: getNumber(proceeds),      // 手取り支援額
            hourly_wage_conversion: getNumber(wage)   // 時給換算
        });
    }

    /**
     * 差引額を追加する
     */
    addDeduction = () => {
        let plan = { "item_id": "", "item_name": "", "fee": 0 };
        this.state.deduction.push(plan);
        this.setState({
            deduction: this.state.deduction
        });
    }

    /**
     * 差引額を削除する
     * @param {*} index
     */
    deleteDeduction = (index) => {
        this.state.deduction.splice(index, 1);
        this.setState({
            deduction: this.state.deduction
        });
    }

    /**
     * 宿泊費をサービスする
     * @param {*} event
     */
    getHotelService = (event) => {
        let chk = event.target.checked ? '1' : '0';
        this.setState({
            hotels_service: chk
        }, () => {
            this.editItemHandler('hotels_service', chk);
        });
    }

    /**
     * 食費をサービスする
     * @param {*} event
     */
    getMealService = (event) => {
        let chk = event.target.checked ? '1' : '0';
        this.setState({
            meal_service: chk
        }, () => {
            this.editItemHandler('meal_service', chk);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            caseDetail: {
                ...prevState.caseDetail,
                [name]: value
            }
        }));
    }

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            deduction: this.state.deduction.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler('deduction', this.state.deduction);
        });
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let caseDetailData = this.state.caseDetail;
        if (caseDetailData.case_id == '' || caseDetailData.case_id == null) {
            if (this.props.data && this.props.data.case_id != null) {
                caseDetailData.case_id = this.props.data.case_id;
            }
        }
        caseDetailData.temp_save = '1';
        this.setState({
            caseDetail: caseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.caseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createCase(reqData);
            }
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createCaseReset();
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    render() {
        const { caseDetail, isDisabled } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.caseDetail.version_no != this.props.data.version_no) {
                let caseDetailData = this.state.caseDetail;
                caseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    caseDetail: caseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }

        let tabindex = 12;
        this.state.deduction && this.state.deduction.forEach((v, i) => {
            tabindex = 12 + i + 1;
        });

        // 寮情報
        const dormitoryInfo = getCodeValueLabelList('RSC0011');

        // 水道光熱費
        const waterLightInfo = getCodeValueLabelList('RSC0012');

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                インターン管理
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>インターン登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">インターン登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR01", this.state.caseOutline, this.state.caseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR02", this.state.caseOutline, this.state.caseDetail)}>会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR03", this.state.caseOutline, this.state.caseDetail)}>条件・業務</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>待遇</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMIMR03", this.state.caseOutline, this.state.caseDetail)} tabIndex={tabindex + 10}>戻る</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR05", this.state.caseOutline, this.state.caseDetail)} tabIndex={tabindex + 9}>次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" /></span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>就業体験</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        就業日数
                                                    </th>
                                                    <td>
                                                        <div className="bl_formTxt">1月あたり</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="working_day" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.working_day} maxlength="2" required="required" tabIndex="1" />
                                                            <span className="el_form_txt">日</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        実労働時間
                                                    </th>
                                                    <td>
                                                        <div className="bl_formTxt">1日あたり</div>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="working_hour" className="el_input el_input__numSm text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.working_hour} maxlength="2" required="required" tabIndex="2" />
                                                            <span className="el_form_txt hp_mr20">時間</span>
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="working_minute" className="el_input el_input__numSm hp_tar text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.working_minute} maxlength="2" required="required" tabIndex="3" />
                                                            <span className="el_form_txt hp_mr20">分</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note">
                                            <p>インターン生の労働時間が⾧いと、労基署に実質労働者としてみなされ課税される場合があります。そのため、インターン生の労働従事時間は正社員の実労働時間（残業時間含む）の平均値の4分の3に収まる範囲にすることが推奨されます。</p>
                                        </div>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>生活支援</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        寮の有無
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="living_place-radio" value={dormitoryInfo[1].value}
                                                                checked={this.state.living_place === dormitoryInfo[1].value} onChange={this.handleLivingPlaceChange} tabIndex="4" />
                                                            <span className="hp_mr20">{dormitoryInfo[1].label}</span>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label hp_ml20 hp_mb5">
                                                                <input className="el_checkbox" type="checkbox" name="wifi" checked={this.state.wifi === '1'}
                                                                    onChange={this.handleWiFiChange} disabled={this.state.living_place === '0'} tabIndex="5" />
                                                                <span className="el_checkbox_txt">Wi-Fi有り</span>
                                                            </label>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="living_place-radio" value={dormitoryInfo[0].value}
                                                                checked={this.state.living_place === dormitoryInfo[0].value} onChange={this.handleLivingPlaceChange} tabIndex="6" />
                                                            <span className="hp_mr20">{dormitoryInfo[0].label}</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        支援額
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="salary" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                setValue={caseDetail.salary} numberFormat="true" maxlength="10" required="required" tabIndex="7" />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>差引額</th>
                                                    <td>
                                                        <div>
                                                            <div className="bl_formUnit hp_mb5">
                                                                <span className="hp_mr10">名目　寮費</span>
                                                                <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="living_amount" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                    setValue={caseDetail.living_amount} numberFormat="true" maxlength="10" tabIndex="8" />
                                                                <span className="el_form_txt">円/月</span>
                                                            </div>
                                                            <div className="bl_formUnit bl_formUnit_utilityCosts hp_mb20">
                                                                <input type="radio" name="water_light" value={waterLightInfo[0].value} checked={this.state.water_light === waterLightInfo[0].value}
                                                                    onChange={this.handleWaterLightChange} tabIndex="9" />
                                                                <span className="hp_mr20">{waterLightInfo[0].label}</span>
                                                                <input type="radio" name="water_light" value={waterLightInfo[1].value} checked={this.state.water_light === waterLightInfo[1].value}
                                                                    onChange={this.handleWaterLightChange} tabIndex="10" />
                                                                <span>{waterLightInfo[1].label}</span>
                                                            </div>
                                                            <div className="bl_formUnit hp_mb5">
                                                                <span className="hp_mr10">名目　食費</span>
                                                                <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="meal_amount" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                    setValue={caseDetail.meal_amount} numberFormat="true" maxlength="10" tabIndex="11" />
                                                                <span className="el_form_txt">円/月</span>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.state.deduction ? (this.state.deduction.map((item, index) => {
                                                                return (
                                                                    <div className="bl_formUnit hp_mb5" key={index}>
                                                                        <span>名目　</span>
                                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} name="item_name" className="el_input el_input__sm hp_mr5"
                                                                            setValue={item.item_name} maxlength="20" tabIndex={tabindex} />
                                                                        <TextInput type="text" onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} name="fee" className="el_input el_input__sm text_right_align" inputmode="decimal"
                                                                            setValue={item.fee} numberFormat="true" maxlength="10" tabIndex={tabindex} />
                                                                        <span className="el_form_txt">円/月</span>
                                                                    </div>
                                                                );
                                                            })) : null
                                                        }
                                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r">
                                                            <button type="button" value="-" className="el_funcBtn" tabIndex={tabindex + 1} onClick={() => this.deleteDeduction(this.state.deduction.length - 1)}>
                                                                <img src={Minus_Icon} alt="マイナス" />削除
                                                            </button>
                                                            <button type="button" value="+" className="el_funcBtn" tabIndex={tabindex + 2} onClick={() => this.addDeduction()} >
                                                                <img src={Plus_Icon} alt="プラス" />追加
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note">
                                            <p>インターン生の賃金や手当には法的な規制はありません。地域の最低賃金が目安とされています。<br />一方で、大学も学生も手取りの多さで職場を選ぶ傾向があります。</p>
                                        </div>
                                        <div className="bl_horizBtnUnit hp_mt50 hp_mb40">
                                            <button type="button" name="" className="el_submitBtn el_submitBtn__calc" onClick={() => this.calclateProceeds()} tabIndex={tabindex + 3}>
                                                手取り支援額の計算<br />
                                                <small>（税金を考慮した概算）</small>
                                            </button>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>手取り支援額</th>
                                                    <td>
                                                        <div className="bl_formTxt">1月あたり</div>
                                                        <div className="bl_formUnit">
                                                            <span className="hp_mr10">約</span>
                                                            <TextInput type="text" name="support_amount" className="el_input el_input__sm text_right_align" inputmode="decimal" setValue={this.state.support_amount}
                                                                onHandleChange={(k, v) => this.editItemHandler(k, v)} maxlength="10" readOnly={true} />
                                                            <span className="el_form_txt">円/月</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>時給換算</th>
                                                    <td>
                                                        <div className="bl_formTxt">時間あたり</div>
                                                        <div className="bl_formUnit">
                                                            <span className="hp_mr10">約</span>
                                                            <TextInput type="text" name="hourly_wage_conversion" className="el_input el_input__sm text_right_align" inputmode="decimal" setValue={this.state.hourly_wage_conversion}
                                                                onHandleChange={(k, v) => this.editItemHandler(k, v)} maxlength="10" readOnly={true} />
                                                            <span className="el_form_txt">円相当額</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        ※<span className="el_bold">{this.state.prefectures_name}</span>の最低賃金
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="minimum_wage" className="el_input el_input__num el_input__red"
                                                                setValue={getNumberText(this.state.minimum_wage)} numberFormat="true" maxlength="10" readOnly={true} />
                                                            <span className="el_form_txt">円</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note hp_mb20">
                                            <p>支援額から差引額を差し引いた学生の手取り支援額が、年間103万または月88000円以上で所得税と住民税が課税されて額が減少することがあります。</p>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>交通手段</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" onHandleChange={(k, v) => this.editItemHandler(k, v)} name="transportation" className="el_input el_input__max"
                                                                setValue={caseDetail.transportation} maxlength="102" tabIndex={tabindex + 4} />
                                                            <br></br>
                                                            　例：寮から徒歩5分
                                                            <br></br>
                                                            　　　最寄駅「ＸＸＸ」から徒歩10分
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>監督教授受入条件</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th rowSpan="2">担当教員</th>
                                                    <td>
                                                        <label className="el_label">
                                                            <input className="el_checkbox" type="checkbox" name="hotels_service"
                                                                checked={this.state.hotels_service === '1'} onChange={this.getHotelService} tabIndex={tabindex + 5} />
                                                            <span className="el_checkbox_txt">宿泊費をサービスする</span>
                                                        </label>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <label className="el_label">
                                                            <input className="el_checkbox" type="checkbox" name="meal_service"
                                                                checked={this.state.meal_service === '1'} onChange={this.getMealService} tabIndex={tabindex + 6} />
                                                            <span className="el_checkbox_txt">食費をサービスする</span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note">
                                            <p>実習期間中に大学の担当教員がインターンの実態を視察に来ます。<br />その際の宿泊費や食費をサービスにすると喜ばれます。通常期間中に１回程度。</p>
                                        </div>
                                    </section>

                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex={tabindex + 7}>途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR05", this.state.caseOutline, this.state.caseDetail)} tabIndex={tabindex + 8}>次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createCase.isFetching,
        data: state.createCase.caseData,
        isError: state.createCase.isError,
        err_type: state.createCase.err_type,
        err_detail: state.createCase.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createCase(condition) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCase(condition));
        },
        createCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMIMR04);

