import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage, getCodeValueLabelList } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { isBlank, getChangeDateText } from "../../Components/Tools/StringTools";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";
import { getUsefulInfoDetail, GetUsefulInfoDetailReset } from "../UlGUIULUIP02/GUIULUIP02Action";
import { updateUsefulInfoDetail, UpdateUsefulInfoDetailReset } from "./GUIULUIP01Action";
import { MessageMap } from "./GUIULUIP01Message";

class GUIULUIP01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            usefulInfoList: [],
            organizationType: getCodeValueLabelList('RSC0031'),
            isGet: false,
            isUpdate: false,
            getInfo: {
                organization_type: '',
            },
            updateInfo: {
                industry_id: '',
                organization_type: '',
                useful_info_list: [],
            },
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);

        const { getInfo, updateInfo, organizationType } = this.state;
        getInfo.organization_type = organizationType[0].value;
        updateInfo.organization_type = organizationType[0].value;
        this.setState({
            getInfo: getInfo,
            updateInfo: updateInfo
        }, () => {
            this.handlingGet();
        });
    }

    /**
    * 取得
    */
    handlingGet = () => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isGet: true }, () => {
            this.setErrorCallback(this.reset);
            const { getInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify(getInfo));
            this.props.getUsefulInfoDetail(reqData);
        });
    }

    /**
    * 登録
    */
    handlingUpdate = () => {
        window.scrollTo(0, 0);
        this.reset();

        const { updateInfo } = this.state;
        const { useful_info_list } = updateInfo;
        updateInfo.useful_info_list = useful_info_list.filter(v => v.useful_title != '' || v.useful_url != '' || v.useful_comment != '');
        this.setState({
            isUpdate: true,
            updateInfo: updateInfo
        }, () => {
            this.setErrorCallback(this.reset);
            let reqData = JSON.parse(JSON.stringify(updateInfo));
            this.props.updateUsefulInfoDetail(reqData);
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    reset = () => {
        this.props.getUsefulInfoDetailReset();
        this.props.updateUsefulInfoDetailReset();
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { value } = event.target;
        this.showModalDialog(1, getMessage('RS-0080'), () => { this.handleOrgTypeChange(value); }, () => { });
    };

    /**
    * 組織タイプ変更
    * @param {*} value
    */
    handleOrgTypeChange = (type) => {
        const { getInfo, updateInfo } = this.state;
        getInfo.organization_type = type;
        updateInfo.organization_type = type;
        this.setState({
            getInfo: getInfo,
            updateInfo: updateInfo
        }, () => {
            this.handlingGet();
        });
    };

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value, index) => {
        const { usefulInfoList, updateInfo } = this.state;
        const { useful_info_list } = updateInfo;
        const showItem = usefulInfoList[index];
        const updateItem = useful_info_list[index];

        showItem[name] = value;
        updateItem[name] = value;
        usefulInfoList[index] = showItem;
        useful_info_list[index] = updateItem;
        updateInfo.useful_info_list = useful_info_list;
        this.setState({
            usefulInfoList: usefulInfoList,
            updateInfo: updateInfo
        });
    }

    /**
    * 削除
    */
    handlingDelete = (index) => {
        const { usefulInfoList, updateInfo } = this.state;
        const { useful_info_list } = updateInfo;

        useful_info_list.splice(index, 1);
        usefulInfoList.splice(index, 1);
        updateInfo.useful_info_list = useful_info_list;
        this.setState({
            usefulInfoList: usefulInfoList,
            updateInfo: updateInfo
        });
    }

    /**
    * 追加
    */
    handlingAdd = () => {
        const { usefulInfoList, organizationType, updateInfo } = this.state;
        const { useful_info_list } = updateInfo;

        const codeName = organizationType.find(item => item.value === updateInfo.organization_type).label;
        useful_info_list.push({
            useful_title: '',
            useful_url: '',
            useful_comment: ''
        });
        usefulInfoList.push({
            organization_type: updateInfo.organization_type,
            code_name: codeName,
            useful_title: '',
            useful_url: '',
            useful_comment: ''
        });
        this.setState({
            usefulInfoList: usefulInfoList,
            updateInfo: updateInfo
        });
    }

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        const { useful_info_list_dict, industry_id } = detailData;
        const { updateInfo } = this.state;

        updateInfo.industry_id = industry_id;
        const usefulInfoListDict = useful_info_list_dict && useful_info_list_dict.length > 0 ? useful_info_list_dict : [];
        updateInfo.useful_info_list = usefulInfoListDict.map((item, index) => ({
            useful_title: item.useful_title,
            useful_url: item.useful_url,
            useful_comment: item.useful_comment
        }));
        this.setState({
            usefulInfoList: usefulInfoListDict,
            updateInfo: updateInfo
        }, () => {
            this.reset();
        });
    };

    render() {
        const { organizationType, getInfo, usefulInfoList } = this.state;

        if (this.state.isGet && this.props.getErrType == '200') {
            this.setState({ isGet: false }, () => {
                this.setViewStatus(this.props.getData);
            });
        }

        if (this.state.isUpdate && this.props.updateErrType == '200') {
            this.setState({ isUpdate: false }, () => {
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.handlingGet();
                });
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                お役立ち情報登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li>お役立ち情報登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.getErrType} err_detail={this.props.getErrDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.updateErrType} err_detail={this.props.updateErrDetail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">お役立ち情報登録</h2>
                                    </div>
                                    <table className="bl_horizTable">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    開示する組織タイプ
                                                </th>
                                                <td width="80%" style={{ padding: '0px 5px' }}>
                                                    <select className="el_select el_select__auto" name="organization_type" value={getInfo.organization_type} onChange={this.handleChange} tabIndex="1">
                                                        {
                                                            organizationType && organizationType.map(item => (
                                                                <option key={item.value} value={item.value} selected={getInfo.organization_type === item.value}>
                                                                    {item.label}
                                                                </option>
                                                            ))
                                                        }
                                                    </select>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <section className="bl_sect_frame">
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr>
                                                        <th colspan="1" width="10%">削除</th>
                                                        <th colspan="1" width="10%">組織タイプ</th>
                                                        <th colspan="1" width="25%"><span className="el_badge el_badge__required">必須</span>&nbsp;タイトル</th>
                                                        <th colspan="1" width="30%"><span className="el_badge el_badge__required">必須</span>&nbsp;URL</th>
                                                        <th colspan="1" width="25%"><span className="el_badge el_badge__required">必須</span>&nbsp;コメント</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (usefulInfoList !== null && usefulInfoList !== undefined && usefulInfoList.length > 0) {
                                                            return (
                                                                usefulInfoList.map((item, index) => (
                                                                    <tr>
                                                                        <td className="hp_tac hp_bg__gl">
                                                                            <button type="button" className="el_smBlueBtn" onClick={() => this.handlingDelete(index)} tabIndex="2">削除</button>
                                                                        </td>
                                                                        <td className="hp_tac hp_bg__gl">
                                                                            {item.code_name}
                                                                        </td>
                                                                        <td colspan="1" className="hp_tac">
                                                                            <div className="bl_formUnit">
                                                                                <TextInput type="text" className="el_input el_input__max" maxlength="200" name="useful_title" setValue={item.useful_title} onHandleChange={(k, v) => this.editItemHandler(k, v, index)} tabIndex="3" />
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="1" className="hp_tac">
                                                                            <div className="bl_formUnit">
                                                                                <TextInput type="text" className="el_input el_input__max" maxlength="2000" name="useful_url" setValue={item.useful_url} onHandleChange={(k, v) => this.editItemHandler(k, v, index)} tabIndex="4" />
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="1" className="hp_tac">
                                                                            <div className="bl_formUnit">
                                                                                <Textarea className={"el_textarea"} maxlength="1000" rows="2" cols="80" name="useful_comment" setValue={item.useful_comment} onHandleChange={(k, v) => this.editItemHandler(k, v, index)} tabIndex="5" />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__l">
                                            <button type="button" value="+" className="el_funcBtn" onClick={() => this.handlingAdd()} tabIndex="6">
                                                <img src={Plus_Icon} alt="プラス" />
                                                追加
                                            </button>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit">
                                    <button type="button" className="el_btn" onClick={() => this.handlingUpdate()} tabIndex="7">登録する</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        //  お役立ち情報取得
        isGetFetching: state.getUsefulInfoDetail.isFetching,
        getData: state.getUsefulInfoDetail.data,
        isGetError: state.getUsefulInfoDetail.isError,
        getErrType: state.getUsefulInfoDetail.err_type,
        getErrDetail: state.getUsefulInfoDetail.err_detail,
        //  お役立ち情報更新
        isUpdateFetching: state.updateUsefulInfoDetail.isFetching,
        updateData: state.updateUsefulInfoDetail.data,
        isUpdateError: state.updateUsefulInfoDetail.isError,
        updateErrType: state.updateUsefulInfoDetail.err_type,
        updateErrDetail: state.updateUsefulInfoDetail.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getUsefulInfoDetail(arg) {
            // お役立ち情報取得
            dispatch(getUsefulInfoDetail(arg));
        },
        getUsefulInfoDetailReset() {
            dispatch(GetUsefulInfoDetailReset());
        },
        updateUsefulInfoDetail(arg) {
            // お役立ち情報更新
            dispatch(updateUsefulInfoDetail(arg));
        },
        updateUsefulInfoDetailReset() {
            dispatch(UpdateUsefulInfoDetailReset());
        }
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GUIULUIP01);