import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCRU05ActionType';

// 組織情報(プロフィール)監理団体取得API
const GET_SUPERVISING_ORG_INFO_POST = BASE_URL + "/api/cm_om_get_supervising_org_info";
// 組織情報(プロフィール)監理団体更新API
const UPDATE_SUPERVISING_ORG_INFO_POST = BASE_URL + "/api/cm_om_update_supervising_org_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetSupervisingOrgInfoRequest = () => {
    return {
        type: actionTypes.GET_SUPERVISING_ORG_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetSupervisingOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_SUPERVISING_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetSupervisingOrgInfoFailure = (err) => {
    return {
        type: actionTypes.GET_SUPERVISING_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetSupervisingOrgInfoReset = () => {
    return {
        type: actionTypes.GET_SUPERVISING_ORG_INFO_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateSupervisingOrgInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_SUPERVISING_ORG_INFO_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateSupervisingOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_SUPERVISING_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateSupervisingOrgInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_SUPERVISING_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateSupervisingOrgInfoReset = () => {
    return {
        type: actionTypes.UPDATE_SUPERVISING_ORG_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getSupervisingOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetSupervisingOrgInfoRequest());
        ecoPostWithAuthHeader(GET_SUPERVISING_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetSupervisingOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetSupervisingOrgInfoFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateSupervisingOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateSupervisingOrgInfoRequest());
        ecoPostWithAuthHeader(UPDATE_SUPERVISING_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateSupervisingOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(UpdateSupervisingOrgInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getSupervisingOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetSupervisingOrgInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateSupervisingOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateSupervisingOrgInfoReset());
    };
};