import actionTypes from "./GCMOMORU02ActionType";

/**
 * 組織管理
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: [],
};

/**
 * ユーザ管理を更新するReducer
 * @param {*} state
 * @param {*} action
 */
export const saveUserManagement = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_USER_MANAGEMENT_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: [],
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail,
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: [],
            });
        case actionTypes.UPDATE_USER_MANAGEMENT_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: [],
            });
        default:
            return state;
    }
};
