/**
 * Action Typesを定義
 */
const GISDMIRI01ActionType = {
    GET_TRAINING_REPORT_INFO_REQUEST: 'GET_TRAINING_REPORT_INFO_REQUEST',  // 実習レポート照会定義
    GET_TRAINING_REPORT_INFO_SUCCESS: 'GET_TRAINING_REPORT_INFO_SUCCESS',  // 実習レポート照会成功定義
    GET_TRAINING_REPORT_INFO_FAILURE: 'GET_TRAINING_REPORT_INFO_FAILURE',  // 実習レポート照会失敗定義
    GET_TRAINING_REPORT_INFO_RESET: 'GET_TRAINING_REPORT_INFO_RESET',       // 実習レポート照会リセット定義
};

export default GISDMIRI01ActionType;