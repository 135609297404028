import actionType from "./GISDMRLS01ActionType";

// レスポンスState定義
const infoState = {
    isFetching: false,
    isError: false,
    beforeDispId: '',
    data: [],
    err_type: "",
    err_detail: [],
    lastDate: ''// 最新の対象月、これ以降はボタン非活性
};

/**
 * 日報日次一覧データ取得
 * @param {*} state
 * @param {*} action
 */
export const getDailyReportList = (state = infoState, action) => {
    switch (action.type) {
        case actionType.DAILY_REPORT_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                beforeDispId: '',
                err_detail: []
            });
        case actionType.DAILY_REPORT_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                beforeDispId: action.beforeDispId,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.DAILY_REPORT_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.DAILY_REPORT_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false,
                err_type: "",
                beforeDispId: '',
                err_detail: []
            });
        case actionType.DAILY_REPORT_LIST_LASTDATE:
            return Object.assign({}, state, {
                lastDate: action.data
            });
        case actionType.DAILY_REPORT_LIST_LASTDATE_RESET:
            return Object.assign({}, state, {
                lastDate: ''
            });
        default:
            return state;
    }
};
