import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCognitoUserInfo, getMessage } from "../../Constants/CommonConstants";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { saveReportContentInfo, saveReportContentReset, getReportContentInfo } from "./GISDMIRR01Action";
import { MessageMap } from "./GISDMIRR01Message";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Textarea from "../../Components/Atoms/Textarea";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";

/**
 * レポート内容登録
 */
class GISDMIRR01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            internship_report_date: "",
            start_time: "",
            end_time: "",
            trainingCategory: [],
            reportContentList: [],
            err_msg: [],
            isDisabled: false,
            isDisabledFlg: false,
            isRegistFlg: false,
            refreshInited: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.setViewStatus(this.props.detailData);
    }

    /**
     * 画面処理ステータス設定
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        const { internship_type_dict, internship_report_list_dict, case_id, user_id, internship_report_date } = detailData;

        if (JSON.stringify(internship_report_list_dict) === '{}') {
            const clone = {
                start_time: "",
                end_time: "",
                internship_type_id: "",
                internship_contents: ""
            };
            let registration = new Array(3).fill().map((item, index) => ({ ...clone, report_detail_number: index + 1 }));
            this.setState({
                internship_comment: "",
                guidance_personnel_comment: "",
                reportContentList: registration,
                case_id: case_id,
                user_id: user_id,
                internship_report_date: internship_report_date,
                isDisabledFlg: false,
                isRegistFlg: false
            });
        } else {
            let result = internship_report_list_dict.map(item => ({
                report_detail_number: item.report_detail_number,
                start_time: item.start_time,
                end_time: item.end_time,
                internship_contents: item.internship_contents,
                internship_type_id: item.internship_type_id,
                case_id: item.case_id,
                user_id: item.user_id,
                internship_report_date: item.internship_report_date,
                internship_comment: item.internship_comment,
                approval_flg: item.approval_flg,
                guidance_personnel_comment: item.guidance_personnel_comment,
                version_no: item.version_no
            }));
            this.setState({
                internship_comment: internship_report_list_dict[0].internship_comment,
                guidance_personnel_comment: internship_report_list_dict[0].guidance_personnel_comment,
                reportContentList: result,
                case_id: case_id,
                user_id: user_id,
                version_no: internship_report_list_dict[0].version_no,
                internship_report_date: internship_report_date,
                isDisabledFlg: internship_report_list_dict[0].approval_flg === '1' ? true : false,
                isRegistFlg: internship_report_list_dict[0].registration_flg === '1' ? true : false
            });
        }

        // インターン生、技能実習、特定技能の場合
        if (['INT', 'GNO', 'TOK'].includes(getCognitoUserInfo()["org_type"])) {
            // 活性
            this.setState({ isDisabled: false });
        } else if (getCognitoUserInfo()["org_type"] === 'COP') {
            // 非活性
            this.setState({ isDisabled: true });
        }

        // 実習カテゴリ
        this.setState({ trainingCategory: internship_type_dict });
    }

    /**
     * 実習カテゴリ変更
     * @param {*} event
     */
    handleChange = index => event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandlerByObject(index, name, value);
        });
    };

    /**
     * 画面リストにデータを追加する。
     */
    addReportContent = () => {
        const { reportContentList } = this.state;
        const clone = {
            "report_detail_number": 1,
            "start_time": "",
            "end_time": "",
            "internship_type_id": "",
            "internship_contents": ""
        };

        if (reportContentList.length > 9) {
            // 表示中のメッセージをリセットする
            this.props.saveReportContentReset();
            this.setState({
                err_msg: [getMessage('RS-0056').replace(/%1/g, '10')]
            });
            window.scrollTo(0, 0);
        } else {
            clone.report_detail_number = reportContentList.length + 1;
            this.setState(prevState => ({
                reportContentList: [...prevState.reportContentList, clone]
            }));
        }
    }

    /**
     * 登録
     */
    saveReportContentInfo = () => {
        window.scrollTo(0, 0);
        const { reportContentList } = this.state;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.saveReportContentReset());

        let reportContentData = reportContentList.map((item, index) => ({
            start_time: item.start_time,
            end_time: item.end_time,
            report_detail_number: item.report_detail_number,
            internship_type_id: item.internship_type_id,
            internship_contents: item.internship_contents
        }));

        this.setState({
            err_msg: []
        }, () => {
            let params = {};
            params['user_id'] = this.state.user_id;
            params['case_id'] = this.state.case_id
            params['internship_report_date'] = this.state.internship_report_date
            params['internship_comment'] = this.state.internship_comment;
            params['guidance_personnel_comment'] = this.state.guidance_personnel_comment;
            params['internship_report_data'] = reportContentData;
            params['version_no'] = this.state.version_no;

            this.props.saveReportContentInfo(params);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            reportContentList: this.state.reportContentList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        this.props.saveReportContentReset();
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { trainingCategory, isDisabled, isDisabledFlg, isRegistFlg } = this.state;
        const { case_id, user_id, internship_report_date } = this.props.detailData;

        if (!isBlank(this.props.reportContentInfo) && this.props.reportContentInfo.err_type == '200' && this.state.refreshInited) {
            // レポート登録データ再設定する
            this.setState({ refreshInited: false }, () => {
                this.setViewStatus(this.props.reportContentInfo.data);
            });
        }

        if (!this.props.data.isFetching) {
            // 最新バージョン設定する
            if (this.props.data.data && this.props.data.err_type === '200' && !isBlank(this.props.data.data.version_no) && this.state.version_no !== this.props.data.data.version_no) {
                this.editItemHandler('version_no', this.props.data.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), async () => {
                    await this.props.saveReportContentReset();
                    // 日報管理レポート登録再取得
                    await this.props.getReportContentInfo(user_id, case_id, internship_report_date);
                    this.setState({ refreshInited: true });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">レポート内容登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GAFMCMCFDM01'); }, () => { })}>ユーザ一覧</a></li>
                                <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISDMRLS01_GAFMCMCFDM01'); }, () => { })}>日報日次一覧</a></li>
                                <li>レポート内容登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.data.err_type} err_detail={this.props.data.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">{this.state.internship_report_date}</h2>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="sticky_table_wrapper sticky_table_wrapperNoScroll">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>開始時間</th>
                                                        <th>終了時間</th>
                                                        <th>実習カテゴリ</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.state.reportContentList)) {
                                                            return (
                                                                this.state.reportContentList.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="hp_tac hp_bg__gl" rowSpan="2">No.{item.report_detail_number}</td>
                                                                            <td className="hp_tac">
                                                                                <TextInput type="time" name="start_time" className={isDisabled || isDisabledFlg ? "el_input is_disabled" : "el_input el_input__time"} maxlength="5"
                                                                                    setValue={item.start_time} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="--:--" disabled={isDisabled || isDisabledFlg} tabIndex="1" />
                                                                            </td>
                                                                            <td className="hp_tac">
                                                                                <TextInput type="time" name="end_time" className={isDisabled || isDisabledFlg ? "el_input is_disabled" : "el_input el_input__time"} maxlength="5"
                                                                                    setValue={item.end_time} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="--:--" disabled={isDisabled || isDisabledFlg} tabIndex="2" />
                                                                            </td>
                                                                            <td className="hp_tac">
                                                                                <select className={isDisabled || isDisabledFlg ? "el_select is_disabled" : "el_select el_select__w80per"} name="internship_type_id" value={item.internship_type_id} onChange={this.handleChange(index)} disabled={isDisabled || isDisabledFlg} tabIndex="3">
                                                                                    <option value="" selected></option>
                                                                                    {
                                                                                        checkArray(trainingCategory) && trainingCategory.map(category => (
                                                                                            <option key={category.plan_detail_number} value={category.plan_detail_number} selected={String(category.plan_detail_number) === item.internship_type_id}>
                                                                                                {category.work_name}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="hp_tac" colSpan="3">
                                                                                <div className="bl_formUnit">
                                                                                    <Textarea className={isDisabled || isDisabledFlg ? "el_textarea is_disabled" : "el_textarea"} name="internship_contents" rows="2" cols="15" setValue={item.internship_contents} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="実習内容をここに記載してください" disabled={isDisabled || isDisabledFlg} tabIndex="4" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r">
                                            <button type="button" value="+" className="el_funcBtn" onClick={() => this.addReportContent()} disabled={isDisabled || isDisabledFlg}><img style={{ opacity: isDisabled || isDisabledFlg ? 0.5 : 1.0 }} src={Plus_Icon} alt="プラス" tabIndex="5" />追加</button>
                                        </div>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>今日学んだこと・感じたこと</th>
                                                    <td>
                                                        <Textarea className={isDisabled || isDisabledFlg ? "el_textarea is_disabled" : "el_textarea"} name="internship_comment" rows="2" cols="15" setValue={this.state.internship_comment} onHandleChange={(k, v) => this.editItemHandler(k, v)} disabled={isDisabled || isDisabledFlg} tabIndex="7" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>指導担当者コメント</th>
                                                    <td>
                                                        <Textarea className={!isDisabled || (isDisabled && !isRegistFlg) || isDisabledFlg ? "el_textarea is_disabled" : "el_textarea"} name="guidance_personnel_comment" rows="2" cols="15" setValue={this.state.guidance_personnel_comment} onHandleChange={(k, v) => this.editItemHandler(k, v)} disabled={!isDisabled || isDisabledFlg} tabIndex="8" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_sect_note">
                                            <p>※指導担当者コメントは必ずしも毎日ご記入いただく必要はありません</p>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className={(!isDisabled && isDisabledFlg) || (isDisabled && !isRegistFlg) || isDisabledFlg ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveReportContentInfo()} tabIndex="9">登録</button>
                                    </div>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISDMRLS01_GAFMCMCFDM01'); }, () => { })} tabIndex="10">日報日次一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.saveReportContentInfo,
        reportContentInfo: state.getReportContentInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveReportContentInfo: (arg) => dispatch(saveReportContentInfo(arg)),
        saveReportContentReset: () => dispatch(saveReportContentReset()),
        getReportContentInfo(user_id, case_id, internship_report_date) {
            // レポート内容登録初期取得
            dispatch(getReportContentInfo({
                case_id: case_id, user_id: user_id, internship_report_date: internship_report_date
            }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISDMIRR01);

