// メッセージMap
export const MessageMap = {
    sending_organization_name: "送り出し機関名",
    sending_organization_location: "所在地",
    pic_name: "担当者名",
    pic_mail_address: "担当者名メールアドレス",
    pic_tel_country_code: "担当者電話番号＿国・地域名",
    pic_tel1: "担当者電話番号＿市外局番",
    pic_tel2: "担当者電話番号＿市内局番",
    pic_tel3: "担当者電話番号加入者番号",
};
