import actionTypes from "./GESSFCES01ActionType";

/**
 * 評価詳細
 */

const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 評価詳細を取得するReducer
 * @param {*} state
 * @param {*} action
 */

export const getEvaluationResultsDetail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVALUATION_RESULTS_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_EVALUATION_RESULTS_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_EVALUATION_RESULTS_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_EVALUATION_RESULTS_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 評価詳細を更新するReducer
 * @param {*} state
 * @param {*} action
 */

export const updateEvaluationResultsDetail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.UPDATE_EVALUATION_RESULTS_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};