import actionTypes from './GRSMMIMD01ActionTypes';

/**
 * エントリリスト情報
 */
const initialEntryListState = {
    isEntryFetching: false,
    overViewData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 案件応募者情報
 */
const initialCaseCandidateState = {
    isEntryFetching: false,
    overViewData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * エントリリストを取得するReducer
 * @param {*} state 
 * @param {*} action 
 */
export const getEntryList = (state = initialEntryListState, action) => {
    switch (action.type) {
        case actionTypes.ENTRY_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.ENTRY_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data,
                isError: action.data.isError,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.ENTRY_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.ENTRY_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 案件応募者リストを取得するReducer
 * @param {*} state 
 * @param {*} action 
 */
export const getCaseCandidateList = (state = initialCaseCandidateState, action) => {
    switch (action.type) {
        case actionTypes.GET_CASE_CANDIDATE_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_CASE_CANDIDATE_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data,
                isError: action.data.isError,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_CASE_CANDIDATE_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_CASE_CANDIDATE_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
