import { getCodeName } from "../Constants/CommonConstants";

// 更新フラグMap
export const UPDATE_MAP = {
    0: "create",
    1: "update"
};

// 案件一覧テンプレート
export let CREATE_OUTLINE_TEMPLATE = {
    "case_id": null,
    "company_id": null,
    "company_name": null,
    "facility_name": null,
    "industry_id": null,
    "status_of_residence": null,
    "accept_period_start": null,
    "accept_period_end": null,
    "pub_period_start": null,
    "pub_period_end": null,
    "case_status": null,
    "case_title": null,
    "applicant": null,
    "country": null,
    "delete_flg": null,
    "create_datetime": null,
    "create_user": null,
    "update_datetime": null,
    "update_user": null
};

// 案件詳細テンプレート
export let CREATE_DETAIL_TEMPLATE = {
    "case_id": null,
    "supervising_org_id": null,
    "company_id": null,
    "status_of_residence": null,
    "accept_period_start": null,
    "accept_period_end": null,
    "pub_period_start": null,
    "pub_period_end": null,
    "case_status": null,
    "case_title": null,
    "occupation": null,
    "applicant": null,
    "country": null,
    "company_name": null,
    "company_name_kana": null,
    "company_prefectures_id": null,
    "company_address": null,
    "start_year": null,
    "president_name": null,
    "president_name_kana": null,
    "president_tel_country_code": null,
    "president_tel_1": null,
    "president_tel_2": null,
    "president_tel_3": null,
    "fax_country_code": null,
    "fax_1": null,
    "fax_2": null,
    "fax_3": null,
    "employee_regular": null,
    "employee_part_time": null,
    "member_type": null,
    "skt_score_id": null,
    "facility_id": null,
    "facility_name": null,
    "facility_name_kana": null,
    "facility_prefectures_id": null,
    "facility_address_2": null,
    "home_page": null,
    "position": null,
    "pic_name": null,
    "pic_name_kana": null,
    "pic_tel_country_code": null,
    "pic_tel_1": null,
    "pic_tel_2": null,
    "pic_tel_3": null,
    "mail_address": null,
    "candidate_sex": "N",
    "country_select_flg": null,
    "content": null,
    "skt_comment": null,
    "working_day": null,
    "working_hour": null,
    "working_minute": null,
    "living_place": null,
    "wifi": null,
    "salary": null,
    "living_amount": null,
    "meal_amount": null,
    "transportation": null,
    "hotels_service": null,
    "meal_service": null,
    "water_light": '1',
    "case_image": [{
        "image_id": '',
        "image_type": '',
        "image_content": '',
        "image_comment": ''
    }],
    "facility_video_url_list": [],
    "environment_video_url_list": [],
    "insurance_flg": null,
    "immigration_service_flg": null,
    "move_service_flg": null,
    "education_service_before_flg": null,
    "education_service_after_flg": null
};

// 求人案件詳細テンプレート
export let CREATE_RECRUITMENT_DETAIL_TEMPLATE = {
    "case_id": null,
    "supervising_org_id": null,
    "company_id": null,
    "status_of_residence": null,
    "case_status": null,
    "case_title": null,
    "applicant": null,
    "employment_status": '0',
    "accept_period_start": null,
    "accept_period_end": null,
    "employment_renewal": '0',
    "pub_period_start": null,
    "pub_period_end": null,
    "company_name": null,
    "company_name_kana": null,
    "company_prefectures_id": null,
    "company_address": null,
    "start_year": null,
    "president_name": null,
    "president_name_kana": null,
    "president_tel_country_code": null,
    "president_tel_1": null,
    "president_tel_2": null,
    "president_tel_3": null,
    "fax_country_code": null,
    "fax_1": null,
    "fax_2": null,
    "fax_3": null,
    "employee_regular": null,
    "employee_part_time": null,
    "member_type": null,
    "skt_score_id": null,
    "facility_id": null,
    "facility_name": null,
    "facility_name_kana": null,
    "facility_prefectures_id": null,
    "facility_address_2": null,
    "home_page": null,
    "position": null,
    "pic_name": null,
    "pic_name_kana": null,
    "pic_tel_country_code": null,
    "pic_tel_1": null,
    "pic_tel_2": null,
    "pic_tel_3": null,
    "mail_address": null,
    "candidate_sex": "N",
    "country_select_flg": null,
    "country": null,
    "occupation": null,
    "content": null,
    "job_working" : [{
        "work_start": "",
        "work_end": "",
        "work_break": "",
        "work_hours": ""
    }],
    "work_shift": null,
    "work_day_fixed": null,
    "work_day_fixed_free": null,
    "work_others": null,
    "work_others_free": null,
    "holiday": null,
    "holiday_number": null,
    "payment_amount": null,
    "payment_hourly": null,
    "living_amount": null,
    "meal_amount": null,
    "water_light": '1',
    "paid_holiday": null,
    "others_holiday": null,
    "living_place": null,
    "wifi": null,
    "transportation": null,
    "insurance_deposit": null,
    "insurance_pension": null,
    "insurance_employment": null,
    "insurance_accident": null,
    "insurance_severance": null,
    "insurance_others_free": null,
    "case_image": [{
        "image_id": '',
        "image_type": '',
        "image_content": '',
        "image_comment": ''
    }],
    "facility_video_url_list": [],
    "environment_video_url_list": [],
    "insurance_flg": null,
    "immigration_service_flg": null,
    "move_service_flg": null,
    "education_service_before_flg": null,
    "education_service_after_flg": null
};

/**
 * 手取り金額計算
 * @param {*} salary        支給額（int）
 * @param {*} amountList    控除額を配列で入れてください
 */
export const calculatePayment = (salary, amountList) => {
    const alist = amountList.filter(v => v);
    let amounts = 0;
    if (alist.length > 0) {
        amounts = alist.reduce((amount, x) => Number((amount + '').replace(/,/g, '')) + Number((x + '').replace(/,/g, '')), 0);
    }
    let ret = parseInt((Number((salary + '').replace(/,/g, '')) - amounts) * 0.9);
    if (isNaN(ret)) {
        return 0;
    } else {
        return ret;
    }
};

/**
 * 時給額計算
 * @param {*} totalSalary   手取り金額（int）
 * @param {*} days          就業日数（int）
 * @param {*} time          実稼働時間（float）
 */
export const calculateSalary = (totalSalary, days, time) => {
    let ret = parseInt(Number((totalSalary + '').replace(/,/g, '')) / (Number((days + '').replace(/,/g, '')) * Number((time + '').replace(/,/g, ''))));
    if (isNaN(ret)) {
        return 0;
    } else {
        return ret;
    }
};


/**
 * 案件ステータスを取得する
 * @param {*} item 案件対象
 */
export const getStatus = (item) => {
    // 現在時刻
    let currentDate = item.sys_date.replace(/\-/g, "");
    // 募集開始日のフォマード
    let pub_start = item.pub_period_start.replace(/\-/g, "");
    // 募集終了日のフォマード
    let pub_end = item.pub_period_end.replace(/\-/g, "");
    // 案件ステータスの設定
    if (item.case_status === "9") {
        // 案件一覧情報テーブル．ステータス＝9：作成中 の場合
        return getCodeName('RSC0003', "9");
    } else if (item.case_status === "2" && currentDate < pub_start) {
        // 案件一覧情報テーブル．ステータス＝ 2：作成済　かつ
        //当日日付 ＜ 案件一覧情報テーブル．募集開始日 の場合
        return getCodeName('RSC0003', "2");
    } else if (item.case_status === "2" && (currentDate >= pub_start && currentDate <= pub_end)) {
        // 案件一覧情報テーブル．ステータス＝ 2：作成済 かつ
        //（当日日付 ≧ 案件一覧情報テーブル．募集開始日 かつ
        // 当日日付 ≦ 案件一覧情報テーブル．募集終了日）　の場合
        return getCodeName('RSC0003', "0");
    } else if (item.case_status === "2" && (currentDate > pub_end)) {
        // 案件一覧情報テーブル．ステータス＝ 2　かつ
        // 当日日付 ＞ 案件一覧情報テーブル．募集終了日　の場合
        return getCodeName('RSC0003', "1");
    }
};
