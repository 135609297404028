import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GCMOMOIR02ActionType";

// 組織管理更新API
const UPDATE_ORGANIZATION_MANAGEMENT_POST = BASE_URL + "/api/cm_om_update_system_org_tbl_info";

// ********************Actionの作成 Start ********************

/**
 * 更新リクエスト
 */
export const SaveOrganizationManagementRequest = () => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const SaveOrganizationManagementSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const SaveOrganizationManagementFailure = (err) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const SaveOrganizationManagementReset = () => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 修正処理
 * @param {*} data
 */
export const saveOrganizationManagement = (data) => {
    return async (dispatch) => {
        dispatch(SaveOrganizationManagementRequest());
        ecoPostWithAuthHeader(
            UPDATE_ORGANIZATION_MANAGEMENT_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(SaveOrganizationManagementSuccess(res.data))
            )
            .catch((err) => dispatch(SaveOrganizationManagementFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const saveOrganizationManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveOrganizationManagementReset());
    };
};
