/**
 * Action Typesを定義
 */
const GCMOMCRU10ActionType = {
    GET_SUPPORT_ORG_INFO_REQUEST: 'GET_SUPPORT_ORG_INFO_REQUEST',  // 組織情報(プロフィール)支援機関取得定義
    GET_SUPPORT_ORG_INFO_SUCCESS: 'GET_SUPPORT_ORG_INFO_SUCCESS',  // 組織情報(プロフィール)支援機関取得成功定義
    GET_SUPPORT_ORG_INFO_FAILURE: 'GET_SUPPORT_ORG_INFO_FAILURE',  // 組織情報(プロフィール)支援機関取得失敗定義
    GET_SUPPORT_ORG_INFO_RESET: 'GET_SUPPORT_ORG_INFO_RESET',       // 組織情報(プロフィール)支援機関取得リセット定義
    UPDATE_SUPPORT_ORG_INFO_REQUEST: 'UPDATE_SUPPORT_ORG_INFO_REQUEST',  // 組織情報(プロフィール)支援機関更新定義
    UPDATE_SUPPORT_ORG_INFO_SUCCESS: 'UPDATE_SUPPORT_ORG_INFO_SUCCESS',  // 組織情報(プロフィール)支援機関更新成功定義
    UPDATE_SUPPORT_ORG_INFO_FAILURE: 'UPDATE_SUPPORT_ORG_INFO_FAILURE',  // 組織情報(プロフィール)支援機関更新失敗定義
    UPDATE_SUPPORT_ORG_INFO_RESET: 'UPDATE_SUPPORT_ORG_INFO_RESET'       // 組織情報(プロフィール)支援機関更新リセット定義
};

export default GCMOMCRU10ActionType;