import actionTypes from './GCMOMCUM02ActionType';

/**
 * 実習／労働者詳細
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 実習／労働者詳細取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getTrainningWorkerDetail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TRAINNING_WORKER_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_TRAINNING_WORKER_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_TRAINNING_WORKER_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_TRAINNING_WORKER_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
