import actionTypes from './GRSMMRMR31ActionType';

/**
 * 求人案件詳細
 */
const caseInitialState = {
    isFetching: false,
    recruitmentCaseData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 求人案件を登録するReducer
 * @param {*} state
 * @param {*} action
 */
export const createRecruitmentCaseSpecialSkill = (state = caseInitialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                recruitmentCaseData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                recruitmentCaseData: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                recruitmentCaseData: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET_ALL_DATA:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                recruitmentCaseData: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
