import actionTypes from './GRSMSMSA01ActionType';

/**
 * web面接日履歴取得
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * web面接日履歴取得Reducer
 * @param {*} state
 * @param {*} action
 */
export const getInterviewHistory = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INTERVIEW_HISTORY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_INTERVIEW_HISTORY_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_INTERVIEW_HISTORY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_INTERVIEW_HISTORY_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * web面接日登録Reducer
 * @param {*} state
 * @param {*} action
 */
export const createInterviewHistory = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREAT_INTERVIEW_HISTORY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                caseData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREAT_INTERVIEW_HISTORY_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                caseData: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.CREAT_INTERVIEW_HISTORY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                caseData: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.CREAT_INTERVIEW_HISTORY_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREATE_INTERVIEW_HISTORY_RESET_ALL_DATA:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                caseData: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
