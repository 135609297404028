/**
 * Action Typesを定義
 */
const GESSFCMA01ActionType = {
    GET_COP_USAGE_RESULTS_LIST_REQUEST: 'GET_COP_USAGE_RESULTS_LIST_REQUEST',  // 企業利用実績一覧取得定義
    GET_COP_USAGE_RESULTS_LIST_SUCCESS: 'GET_COP_USAGE_RESULTS_LIST_SUCCESS',  // 企業利用実績一覧取得成功定義
    GET_COP_USAGE_RESULTS_LIST_FAILURE: 'GET_COP_USAGE_RESULTS_LIST_FAILURE',  // 企業利用実績一覧取得失敗定義
    GET_COP_USAGE_RESULTS_LIST_RESET: 'GET_COP_USAGE_RESULTS_LIST_RESET',       // 企業利用実績一覧取得リセット定義
};

export default GESSFCMA01ActionType;