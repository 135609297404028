import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GICRMIAI01ActionType';

// 保険申込履歴検索API
const INSURANCE_APPLICATION_DETAILS_POST = BASE_URL + "/api/ic_rm_get_insurance_history_list";

// 保険申込履歴ファイルダウンロードAPI
const INSURANCE_DOWNLOAD_FILE_POST = BASE_URL + "/api/ic_rm_download_insurance_file";

// ********************Actionの作成 Start ********************

/**
 * 検索リクエスト
 */
export const searchInsuranceApplicationDetailRequest = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_DETAILS_REQUEST
    };
};

/**
 * 検索成功
 * @param {*} data
 */
export const searchInsuranceApplicationDetailSuccess = (data) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_DETAILS_SUCCESS,
        data
    };
};

/**
 * 検索失敗
 * @param {*} err
 */
export const searchInsuranceApplicationDetailFailure = (err) => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_DETAILS_FAILURE,
        err
    };
};

/**
 * 検索リセット
 */
export const searchInsuranceApplicationDetailReset = () => {
    return {
        type: actionTypes.INSURANCE_APPLICATION_DETAILS_RESET
    };
};

/**
 * ダウンロードリクエスト
 */
export const insuranceDownloadFileRequest = () => {
    return {
        type: actionTypes.INSURANCE_DOWNLOAD_FILE_REQUEST
    };
};

/**
 * ダウンロード成功
 * @param {*} data
 */
export const insuranceDownloadFileSuccess = (data) => {
    return {
        type: actionTypes.INSURANCE_DOWNLOAD_FILE_SUCCESS,
        data
    };
};

/**
 * ダウンロード失敗
 * @param {*} err
 */
export const insuranceDownloadFileFailure = (err) => {
    return {
        type: actionTypes.INSURANCE_DOWNLOAD_FILE_FAILURE,
        err
    };
};

/**
 * ダウンロードリセット
 * @param {*} err
 */
export const insuranceDownloadFileReset = (err) => {
    return {
        type: actionTypes.INSURANCE_DOWNLOAD_FILE_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * 検索処理Actionの発行
 * @param {*} data
 */
export const searchInsuranceApplicationDetail = (data) => {
    return async (dispatch) => {
        // Storeへ保険申込履歴検索リクエストActionをdispatchする
        dispatch(searchInsuranceApplicationDetailRequest());
        ecoPostWithAuthHeader(INSURANCE_APPLICATION_DETAILS_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ保険申込履歴検索成功Actionをdispatchする
                dispatch(searchInsuranceApplicationDetailSuccess(res.data));
            }).catch(err =>
                // Storeへ保険申込履歴検索失敗Actionをdispatchする
                dispatch(searchInsuranceApplicationDetailFailure(err))
            );
    };
};

/**
 * 画面リセットActionの発行
 */
export const insuranceApplicationDetailReset = () => {
    return async (dispatch) => {
        // Storeへ検索リセットActionをdispatchする
        dispatch(searchInsuranceApplicationDetailReset());
    };
};

/**
 * ダウンロードActionの発行
 */
export const insuranceDownloadFile = (arg) => {
    return async (dispatch) => {
        // Storeへ保険申込履歴ダウンロードリクエストActionをdispatchする
        dispatch(insuranceDownloadFileRequest());
        ecoPostDownloadExcelFile(INSURANCE_DOWNLOAD_FILE_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへ保険申込履歴ダウンロード成功Actionをdispatchする
                dispatch(insuranceDownloadFileSuccess(res.data));
            }).catch(err =>
                // Storeへ保険申込履歴ダウンロード失敗Actionをdispatchする
                dispatch(insuranceDownloadFileFailure(err))
            );
    };
};

