import React, { Fragment } from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { changePassword, changePasswordReset } from "./GAFLFCPF01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { MessageMap } from "./GAFLFCPF01Message";
import TextInput from "../../Components/Atoms/TextInput";
import Load from "../../Components/Atoms/Load";
import ModalMessage from "../../Components/Atoms/ModalMessage";
import { getMessage } from "../../Constants/CommonConstants";
import Service_Icon_PNG from "../../Images/service_logo.png";
import "../../Css/Common.css";

/**
 * パスワード変更画面
 */
class GAFLFCPF01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isError: false,
            isloading: false,
            err_msg: [],
            user_id: this.props.userId,
            current_password: '',
            new_password: '',
            new_password_reenter: '',
            user_id: ''
        };
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    passwordReset = () => {
        this.props.changePasswordReset();
    }

    /**
     * 変更する
     */
    changePassword = () => {
        window.scrollTo(0, 0);
        this.props.changePassword(this.state);
    }

    /**
     * 変更完了OK
     */
    changePasswordOk = () => {
        window.scrollTo(0, 0);
        this.passwordReset();
        if (this.props.beforeTemplate == 'LoginTemplate') {
            this.props.redirectToNextPage('GAFLFLAF01');
        }
    }

    /**
     * 完了メッセージ表示
     */
    showCompeleteMessage = () => {
        if (this.props.beforeTemplate == 'LoginTemplate') {
            return <ModalMessage type={0} okClick={this.changePasswordOk} content={getMessage('AF-0014')} />;
        } else {
            this.showModalDialog(0, getMessage('AF-0008'), this.changePasswordOk);
            return null;
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.beforeTemplate == 'LoginTemplate' ? <header class="ly_header">
                    <p class="el_headerLogo">
                        <img src={Service_Icon_PNG} alt="service Logo" />
                    </p>
                </header> : null}
                {this.props.beforeTemplate == 'LoginTemplate' && this.props.isError ? <ModalMessage type={9} okClick={() => this.passwordReset()} /> : null}
                {this.props.beforeTemplate == 'LoginTemplate' && this.props.isFetching ? <Load /> : null}
                {this.props.err_type == '200' ? this.showCompeleteMessage() : null}
                <div className="bl_mainHeader">
                    <div className="bl_mainHeader_inner">
                        <h1 className="el_lv1Heading">
                            パスワード変更
                        </h1>
                    </div>
                </div>
                <div className="ly_mainCont">
                    {this.props.beforeTemplate == 'LoginTemplate' ? <br></br> : <nav className="bl_breadcrumb">
                        <ol className="bl_breadcrumb_list">
                            <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                            <li>パスワード変更​​​</li>
                        </ol>
                    </nav>}

                    <section className="bl_sect">
                        <div className="ly_mainCont_inner">
                            <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                            {(() => {
                                return (
                                    this.state.err_msg.length > 0 ? <div id='errorarea'>
                                        <ul className='errorArea'>
                                            {
                                                Object.entries(this.state.err_msg).map(
                                                    ([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    )
                                                )
                                            }
                                        </ul>
                                    </div> : null
                                );
                            }
                            )()}
                            <div className="bl_sect_header">
                                <h2 className="el_lv2Heading">認証情報
                                </h2>
                            </div>
                            <div className="bl_sect_inner">
                                <section className="bl_sect_frame">
                                    <table className="bl_horizTable">
                                        <tbody>
                                            <tr>
                                                <th>
                                                    <span className="el_badge el_badge__required">必須</span><br />
                                                現在のパスワード
                                            </th>
                                                <td>
                                                    <div className="bl_formUnit">
                                                        <TextInput type="password" name="current_password" className="el_input el_input__max" maxlength="20" setValue={this.state.current_password} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <span className="el_badge el_badge__required">必須</span><br />
                                                新しいパスワード
                                            </th>
                                                <td>
                                                    <div className="bl_formUnit">
                                                        <TextInput type="password" name="new_password" className="el_input el_input__max" maxlength="20" setValue={this.state.new_password} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <span className="el_badge el_badge__required">必須</span><br />
                                                新しいパスワード<br />(再入力)
                                            </th>
                                                <td>
                                                    <div className="bl_formUnit">
                                                        <TextInput type="password" name="new_password_reenter" className="el_input el_input__max" maxlength="20" setValue={this.state.new_password_reenter} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                                <div className="bl_horizBtnUnit">
                                    <button type="button" className="el_btn" onClick={() => this.changePassword()} tabIndex="4" >変更する</button>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.changePassword.isFetching,
        data: state.changePassword.data,
        isError: state.changePassword.isError,
        err_type: state.changePassword.err_type,
        err_detail: state.changePassword.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        changePassword(args) {
            // パスワード更新
            dispatch(changePassword(args));
        },
        changePasswordReset() {
            dispatch(changePasswordReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GAFLFCPF01);

