import React, { Fragment } from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCognitoUserInfo } from "../../Constants/CommonConstants";
import "../../Css/Common.css";
import { getUsefulInfoDetail, GetUsefulInfoDetailReset } from "./GUIULUIP02Action";
import { MessageMap } from "./GUIULUIP02Message";

class GUIULUIP02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            orgType: getCognitoUserInfo()["org_type"],
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);

        this.props.getUsefulInfoDetail({ organization_type: '' });
    }

    render() {

        const { useful_info_list_dict } = this.props.data;
        const { orgType } = this.state;

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                お役立ち情報
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => { this.getMenu().gotoHome() }}>ホーム</a></li>
                                <li>お役立ち情報</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.errType} err_detail={this.props.errDetail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">お役立ち情報</h2>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className='bl_horizTable'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr>
                                                        {orgType === 'COD' ? <th colspan="1" width="20%">組織タイプ</th> : null}
                                                        <th colspan="1" width="40%">リンク</th>
                                                        <th colspan="1" width="40%">コメント</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (useful_info_list_dict !== null && useful_info_list_dict !== undefined && useful_info_list_dict.length > 0) {
                                                            return (
                                                                useful_info_list_dict.map((item, index) => (
                                                                    <tr>
                                                                        {orgType === 'COD' ? <td className="hp_tac">{item.code_name}</td> : null}
                                                                        <td colspan="1" className="hp_tac">
                                                                            <div className="bl_formUnit">
                                                                                <u><a href={item.useful_url} target="_blank">{item.useful_title}</a></u>
                                                                            </div>
                                                                        </td>
                                                                        <td colspan="1" className="hp_tac">
                                                                            <div className="bl_formUnit" style={{ "text-align": "left" }}>
                                                                                {item.useful_comment ? item.useful_comment.split('\n').map((str, index) => (
                                                                                    <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                                )) : null}
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        // お役立ち情報
        isFetching: state.getUsefulInfoDetail.isFetching,
        data: state.getUsefulInfoDetail.data,
        isError: state.getUsefulInfoDetail.isError,
        errType: state.getUsefulInfoDetail.err_type,
        errDetail: state.getUsefulInfoDetail.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getUsefulInfoDetail(arg) {
            // お役立ち情報取得
            dispatch(getUsefulInfoDetail(arg));
        },
        getUsefulInfoDetailReset() {
            dispatch(GetUsefulInfoDetailReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GUIULUIP02);