import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GCMOMCRU03ActionType";

// 組織情報(プロフィール)送り出し機関取得API
const GET_SENDING_ORGANIZATION_INFO_POST =
    BASE_URL + "/api/cm_om_get_okr_organization_info";
// 組織情報(プロフィール)送り出し機関更新API
const UPDATE_SENDING_ORGANIZATION_INFO_POST =
    BASE_URL + "/api/cm_om_update_okr_organization_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetSendingOrganizationInfoRequest = () => {
    return {
        type: actionTypes.GET_SENDING_ORGANIZATION_INFO_REQUEST,
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetSendingOrganizationInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_SENDING_ORGANIZATION_INFO_SUCCESS,
        data,
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetSendingOrganizationInfoFailure = (err) => {
    return {
        type: actionTypes.GET_SENDING_ORGANIZATION_INFO_FAILURE,
        err,
    };
};

/**
 * 取得リセット
 */
export const GetSendingOrganizationInfoReset = () => {
    return {
        type: actionTypes.GET_SENDING_ORGANIZATION_INFO_RESET,
    };
};

/**
 * 更新リクエスト
 */
export const UpdateSendingOrganizationInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_SENDING_ORGANIZATION_INFO_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateSendingOrganizationInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_SENDING_ORGANIZATION_INFO_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateSendingOrganizationInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_SENDING_ORGANIZATION_INFO_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const UpdateSendingOrganizationInfoReset = () => {
    return {
        type: actionTypes.UPDATE_SENDING_ORGANIZATION_INFO_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getSendingOrganizationInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetSendingOrganizationInfoRequest());
        ecoPostWithAuthHeader(
            GET_SENDING_ORGANIZATION_INFO_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(GetSendingOrganizationInfoSuccess(res.data))
            )
            .catch((err) => dispatch(GetSendingOrganizationInfoFailure(err)));
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateSendingOrganizationInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateSendingOrganizationInfoRequest());
        ecoPostWithAuthHeader(
            UPDATE_SENDING_ORGANIZATION_INFO_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(UpdateSendingOrganizationInfoSuccess(res.data))
            )
            .catch((err) =>
                dispatch(UpdateSendingOrganizationInfoFailure(err))
            );
    };
};

/**
 * 画面リセットAction
 */
export const getSendingOrganizationInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetSendingOrganizationInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateSendingOrganizationInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateSendingOrganizationInfoReset());
    };
};
