import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCognitoUserInfo, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { saveProgressManagementInfo, saveProgressManagementReset } from "./GIPPMPMF01Action";
import { MessageMap } from "./GIPPMPMF01Message";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import moment from "moment";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";
import "../../Css/GIPPMPMF01.css";

/**
 * 進捗管理
 */
class GIPPMPMF01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            progressManagementList: [],
            err_msg: [],
            isDisabled: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { list } = this.props.progressData;

        for (var i = 0; i < list.length; i++) {
            // 活性&&新規作成の場合
            if (list[i].education_service_before_flg === '1' && list[i].progress_case_id === '') {
                // 0:未受講を設定
                list[i].pre_training_status = '0'
            }
        }
        window.scrollTo(0, 0);

        this.setState({
            progressManagementList: list,
            isDisabled: ['UNI', 'OKR'].includes(getCognitoUserInfo()["org_type"]) ? true : false
        }, () => {
        });
    }

    handleChange = index => event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandlerByObject(index, name, value);
        });
    };

    /**
     * 登録
     */
    saveProgressManagementInfo = () => {
        window.scrollTo(0, 0);
        const { progressManagementList } = this.state;
        const { case_list } = this.props.progressData;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.saveProgressManagementReset());

        let progressManagementData = progressManagementList.map((item, index) => ({
            case_id: case_list.case_id,
            user_id: item.user_id,
            candidate_id: item.candidate_id,
            company_id: item.company_id,
            organization_id: item.organization_id,
            pre_training_status: item.pre_training_status,
            visa_issuance_status: item.visa_issuance_status,
            ticket_arrange_status: item.ticket_arrange_status,
            pick_up_arrange_status: item.pick_up_arrange_status,
            education_date: item.education_date,
            version_no: item.version_no
        }));

        let param = {};
        param['porgress_management_info_data'] = progressManagementData;

        this.props.saveProgressManagementInfo(param);
    }

    /**
     * ビザ発行手続き/航空チケット手配/ピックアップ手配のドロップダウンリスト
     * @param  {...any} args
     * @returns
     */
    dropdownList = (...args) => {
        const { case_list, sys_date } = this.props.progressData;
        const { isDisabled } = this.state;

        // 申込無し
        if (args[2] !== '1') {
            return <>－</>;
        } else { // 申込あり
            if (isBlank(args[1])) {
                // ドロップダウンリスト表示且つNULLの場合、デフォルトの0:未手配／未手続
                this.editItemHandlerByObject(args[4], args[0], '0');
            }
            if (isBlank(args[3])) {  // 登録の場合
                return (
                    <select className={isDisabled ? "el_select col150 is_disabled" : "el_select col150"} name={args[0]} value={args[1]} onChange={this.handleChange(args[4])} disabled={isDisabled}>
                        {
                            checkArray(args[5]) && args[5].map(el => (
                                <option key={el.value} value={el.value} selected={args[1] === '0'}>
                                    {el.label}
                                </option>
                            ))
                        }
                    </select>
                );
            } else if (['UNI', 'OKR'].includes(getCognitoUserInfo()["org_type"]) || sys_date > case_list.accept_period_end) { // 照会の場合
                return (
                    <select className="el_select col150 is_disabled" name={args[0]} value={args[1]} onChange={this.handleChange(args[4])} disabled={true}>
                        {
                            checkArray(args[5]) && args[5].map(el => (
                                <option key={el.value} value={el.value} selected={args[1] === el.value}>
                                    {el.label}
                                </option>
                            ))
                        }
                    </select>
                );
            } else { // 訂正の場合
                return (
                    <select className={isDisabled ? "el_select col150 is_disabled" : "el_select col150"} name={args[0]} value={args[1]} onChange={this.handleChange(args[4])} disabled={isDisabled}>
                        {
                            checkArray(args[5]) && args[5].map(el => (
                                <option key={el.value} value={el.value} selected={args[1] === el.value}>
                                    {el.label}
                                </option>
                            ))
                        }
                    </select>
                );
            }
        }
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            progressManagementList: this.state.progressManagementList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        });
    }

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.saveReset();
        }
        this.props.saveProgressManagementReset();
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { case_list } = this.props.progressData;
        const { isDisabled } = this.state;

        // 受講
        const attendanceOpt = getCodeValueLabelList('RSC0042');
        // 手続き
        const procedureOpt = getCodeValueLabelList('RSC0043');
        // 手配
        const arrangeOpt = getCodeValueLabelList('RSC0044');

        if (!this.props.data.isFetching) {
            if (this.props.data.data && this.props.data.err_type === '200') {
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    let progressManagementList = this.state.progressManagementList;
                    if (checkArray(this.props.data.data.return_data)) {
                        this.props.data.data.return_data.forEach((progress) =>
                            progressManagementList.forEach((item) => {
                                if (progress.user_id == item.user_id) {
                                    item.version_no = progress.version_no;
                                }
                            }
                            )
                        );
                    }
                    this.setState({ progressManagementList: progressManagementList }, () => {
                        this.props.saveProgressManagementReset();
                        this.props.redirectToView("GIPPMPMF01", this.props.progressData.list[0]);
                    });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">進捗管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GIPPMPLI01'); }, () => { })}>入国手続き進捗一覧</a></li>
                                <li>進捗管理</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.data.err_type} err_detail={this.props.data.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>{case_list.case_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>{case_list.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{case_list.company_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    <td>{case_list.accept_period_start}〜{case_list.accept_period_end}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">進捗登録</h2>
                                    </div>
                                    <section className="bl_sect">
                                        <div className="bl_sect_frame bl_sect_frame_custom_padding">
                                            <p className="el_note_sm hp_tar hp_mb20">※「－」はオプション申込み無し</p>
                                            <div className="sticky_table_wrapper">
                                                <table className="sticky_table sticky_table__equal mcTable_align">
                                                    <thead>
                                                        <tr>
                                                            <th>氏名</th>
                                                            <th>入国前研修</th>
                                                            <th>ビザ発行手続き</th>
                                                            <th>航空チケット手配</th>
                                                            <th>ピックアップ手配</th>
                                                            <th>入国日</th>
                                                            <th>更新日時</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            if (checkArray(this.state.progressManagementList)) {
                                                                return (
                                                                    this.state.progressManagementList.map((item, index) => (
                                                                        <Fragment key={index}>
                                                                            <tr>
                                                                                <td>{item.employment_name_kana}</td>
                                                                                <td className="hp_tac">
                                                                                    {(() => {
                                                                                        if (item.education_service_before_flg !== '1') { // 申込無し
                                                                                            return <>－</>;
                                                                                        } else { // 申込あり
                                                                                            if (!item.progress_case_id) {  // 登録の場合
                                                                                                return <>{'未受講'}</>;
                                                                                            } else if (['UNI', 'OKR'].includes(getCognitoUserInfo()["org_type"]) || moment().format('YYYY年MM月DD日') > case_list.accept_period_end) { // 照会の場合
                                                                                                return <>{item.pre_training_status === '0' ? '未受講' : '受講済'}</>;
                                                                                            } else { // 訂正の場合
                                                                                                return <>{item.pre_training_status === '0' ? '未受講' : '受講済'}</>;
                                                                                            }
                                                                                        }
                                                                                    })()}
                                                                                </td>
                                                                                <td className="hp_tac" tabIndex="1">
                                                                                    {this.dropdownList('visa_issuance_status', item.visa_issuance_status, item.immigration_service_flg, item.progress_case_id, index, procedureOpt)}
                                                                                </td>
                                                                                <td className="hp_tac" tabIndex="2">
                                                                                    {this.dropdownList('ticket_arrange_status', item.ticket_arrange_status, item.move_service_flg, item.progress_case_id, index, arrangeOpt)}
                                                                                </td>
                                                                                <td className="hp_tac" tabIndex="3">
                                                                                    {this.dropdownList('pick_up_arrange_status', item.pick_up_arrange_status, item.move_service_flg, item.progress_case_id, index, arrangeOpt)}
                                                                                </td>
                                                                                <td className="hp_tac">
                                                                                    <TextInput type="date" name="education_date" className={isDisabled ? "el_input is_disabled col180" : "el_input el_input__datepicker col180"} setValue={moment(item.education_date).format('YYYY-MM-DD')}
                                                                                        onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="年/月/日" disabled={isDisabled} />
                                                                                </td>
                                                                                <td>{item.update_datetime}</td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                );
                                                            }
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveProgressManagementInfo()} tabIndex="4">登録する</button>
                                    </div>
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GIPPMPLI01'); }, () => { })} tabIndex="5">入国手続き<br />進捗一覧へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.saveProgressManagementInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveProgressManagementInfo: (args) => dispatch(saveProgressManagementInfo(args)),
        saveProgressManagementReset: () => dispatch(saveProgressManagementReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GIPPMPMF01);

