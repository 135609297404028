import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import "../../Css/Common.css";
import { connect } from "react-redux";
import { getCognitoUserInfo } from "../../Constants/CommonConstants";
import { getStatus } from "../../Constants/RsConstants";
import { getEntryList, EntryListReset, getCaseCandidateList, GetCaseCandidateListReset } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import { updateCaseEntryList, CaseEntryListActionReset, updateSelectedCandidateList, updateCaseEntryListByCandidate, CaseEntryListUpdateActionReset } from "../MmGRSMMIMD02/GRSMMIMD02Action";
import Textarea from "../../Components/Atoms/Textarea";
import { CaseDetailReset } from "../MmGRSMMMLI01/GRSMMMLI01Action";
import { MessageMap } from "./GRSMMIMD02Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCaseCandidateDetail, getCandidateDetailReset } from "../MmGRSMMAMF01/GRSMMAMF01Action";
import { getSendingOrganizationInfo, getSendingOrganizationInfoReset } from "../../Cm/OmGCMOMCRU03/GCMOMCRU03Action";
import { getUniversityInfo, getUniversityInfoReset } from "../../Cm/OmGCMOMCRU04/GCMOMCRU04Action";
import { getInterviewHistory, getInterviewHistoryReset } from "../MsGRSMSMSA01/GRSMSMSA01Action";
import { isBlank, getDateText } from "../../Components/Tools/StringTools";

/**
 * インターン案件詳細（募集要項情報）
 */
class GRSMMIMD02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseDetail: this.props.caseDetail,
            candidateList: this.props.candidateList.get_case_candidate_dict,
            entryList: this.props.entryList,
            entryInfo: false,
            actionFlg: null,
            orgId: getCognitoUserInfo()["org_id"],
            orgType: getCognitoUserInfo()["org_type"],
            case_id: "",
            organizationid_id: ""
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        // 応募者一覧から戻って、選択された応募者リストがある場合
        if (this.props.selectedCandidateList) {
            // 応募者リストを再表示
            this.props.updateSelectedCandidateList(this.props.selectedCandidateList);
        }
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.getReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    getReset = () => {
        // 案件詳細情報ステータスリセット
        this.props.CaseDetailReset();
        // エントリリスト情報ステータスリセット
        this.props.EntryListReset();
        // 案件エントリリスト情報ステータスリセット
        this.props.CaseEntryListActionReset();
        // 案件応募者情報ステータスリセット
        this.props.GetCaseCandidateListReset();
        // 案件エントリ更新情報ステータスリセット
        this.props.CaseEntryListUpdateActionReset();
        // 応募者詳細ステータスリセット
        this.props.getCandidateDetailReset();
        // 送出し機関詳細ステータスリセット
        this.props.getSendingOrganizationInfoReset();
        // 大学詳細ステータスリセット
        this.props.getUniversityInfoReset();
        // 面接履歴ステータスリセット
        this.props.getInterviewHistoryReset();
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    switchDisplayEntry = (changeTo, caseDetail, candidateList, entryList, selectedCandidateList, isReset) => {
        // ステータスリセット
        if (isReset) {
            this.getReset();
        }
        // 応募者検索一覧画面に遷移
        return this.props.switchDisplayEntry(changeTo, caseDetail, candidateList, entryList, selectedCandidateList);
    }

    /**
     * 応募者編集
     */
    handleToQueryCandidateList = () => {
        window.scrollTo(0, 0);
        // パラメータの設定
        let searchPara = {};
        // 応募者ＩＤ
        searchPara.candidate_id = "";

        this.setState({
            condition: searchPara,
            err_msg: []
        }, () => {
            // 応募者編集を行う
            this.switchDisplayEntry("GRSMMAMF01", this.state.caseDetail, this.state.candidateList, this.state.entryList, this.props.selectedCandidateProps, false);
        });
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    changeDispWithData = (isReset) => {
        if (isReset) {
            this.getReset();
        }

        this.setState({
            entryInfo: true
        });

        let entrycondition = {};
        entrycondition.case_id = this.state.caseDetail.case_id;
        entrycondition.call_type = "1";
        this.props.getEntryList(entrycondition);
    }

    /**
    * エントリ更新処理
    * @param {*} item　更新対象
    */
    entryUpdate = async (actionFlg, index, object, isCandidate) => {

        window.scrollTo(0, 0);

        // アクション種類を記録する
        this.setState({ actionFlg: actionFlg });

        // 更新条件
        let condition = {};
        // 案件ＩＤ
        condition.case_id = this.state.caseDetail.case_id;

        // エントリーリスト操作する場合
        if (this.props.entryList != null && this.props.entryList.length > 0 && isCandidate != true) {
            condition.entry_version_no = index == null ? this.props.entryList[0].entry_version_no : this.props.entryList[index].entry_version_no;
        }

        // 応募者リスト操作する場合
        if (object != null && isCandidate == true) {
            condition.candidate_version_no = object.candidate_version_no;
            condition.candidate_profile_version_no = object.candidate_profile_version_no;
        }

        // 更新区分
        condition.action_flg = actionFlg;
        if (actionFlg === "ReceptionButton" || actionFlg === "SendButton") {
            condition.entry_text = "";
            // 「受付」または「見送り」ボタン押下
            // エントリテキストを作成
            this.state.entryList.map((item, _index) =>
                _index == index ? condition.entry_text = item.entry_comment ? item.entry_comment : null : ""
            );
            // 組織ＩＤ
            condition.organization_id = object.organization_id;
            // ＤＢ更新
            await this.props.updateCaseEntryList(condition);
        } else if (actionFlg === "AdoptionButton" || actionFlg === "UnadoptionButton" || actionFlg === "ConfirmButton" || actionFlg === "DeclineButton") {
            // 「採用」、「不採用」、「確認」または「辞退」ボタン押下
            if (actionFlg === "AdoptionButton" || actionFlg === "UnadoptionButton") {
                // 「採用」、「不採用」
                this.state.candidateList.map((item, _index) =>
                    _index == index ? condition.action_cop_text = item.candidate_comment ? item.candidate_comment : null : ""
                );
            } else {
                // 「確認」、「辞退」
                this.state.candidateList.map((item, _index) =>
                    _index == index ? condition.action_uni_text = item.candidate_comment ? item.candidate_comment : null : ""
                );
            }
            // 応募者氏名（ローマ字）
            condition.candidate_name = object.candidate_name;
            // 応募者ＩＤ
            condition.candidate_id = object.candidate_id;
            // 組織ＩＤ
            condition.organization_id = object.organization_id;
            // 受入・就業開始日
            condition.accept_period_start = this.state.caseDetail.accept_period_start;
            // 受入・就業終了日
            condition.accept_period_end = this.state.caseDetail.accept_period_end;

            // ＤＢ更新
            await this.props.updateCaseEntryList(condition);
        } else if (actionFlg === "ApplyButton") {
            // 「応募する」ボタン押下
            const value = Object.entries(this.props.selectedCandidateProps).map(([k, v]) => {
                return v.candidate_id;
            });
            // 応募者ＩＤ
            condition.candidate_id = value;
            // ＤＢ更新
            await this.props.updateCaseEntryListByCandidate(condition);
        } else if (actionFlg === "EntryButton" || actionFlg === "EntryQuitButton") {
            // TODO:修正必要
            // 組織ＩＤ
            condition.organization_id = getCognitoUserInfo()["org_id"];
            // ＤＢ更新
            await this.props.updateCaseEntryList(condition);
        }
    }

    /**
     * エントリーリスト、応募者リスト画面リフレッシュ処理
     */
    refreshList = () => {
        const actionFlg = this.state.actionFlg;
        if (actionFlg != null) {
            this.setState({ actionFlg: null }, () => {
                // 更新後、再度取得の処理
                if (actionFlg === "ReceptionButton" || actionFlg === "SendButton" || actionFlg === "EntryButton" || actionFlg === "EntryQuitButton" || actionFlg === "ApplyButton") {
                    // 「受付」、「見送り」、「エントリー」または「エントリー取り下げ」または「応募する」ボタン押下
                    // データ取得条件
                    let entrycondition = {};
                    // 案件ＩＤ
                    entrycondition.case_id = this.state.caseDetail.case_id;
                    // データ取得区分（エントリ取得）
                    entrycondition.call_type = "1";
                    // エントリリスト取得
                    this.props.getEntryList(entrycondition);
                } else if (actionFlg === "AdoptionButton" || actionFlg === "UnadoptionButton" || actionFlg === "ConfirmButton" || actionFlg === "DeclineButton") {
                    // 「採用」、「不採用」、「確認」または「辞退」ボタン押下
                    // データ取得条件
                    let candidatecondition = {};
                    // 案件ＩＤ
                    candidatecondition.case_id = this.state.caseDetail.case_id;
                    // データ取得区分（応募者リスト取得）
                    candidatecondition.call_type = "2";
                    // 応募者リスト取得
                    this.props.getCaseCandidateList(candidatecondition);
                }
            });
        }
    }

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByCandidate = (index, name, value) => {
        this.setState({
            candidateList: this.state.candidateList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler("candidateList", this.state.candidateList);
        });
    }

    /**
   * 画面部品編集イベント
   * @param {*} index
   * @param {*} name
   * @param {*} value
   */
    editItemHandlerByEntry = (index, name, value) => {
        this.setState({
            entryList: this.state.entryList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler("entryList", this.state.entryList);
        });
    }

    /**
     * 応募者を削除
     * @param {*} event 選択された対象
     */
    deleteCandidate = (event) => {
        // 画面に追加される応募者リストを取得
        let tempprops = this.props.selectedCandidateProps;
        // 応募者リストを繰り返す
        tempprops.map((item, index) => {
            // 削除しようとしている応募者があった場合
            if (item.candidate_id === event.target.value) {
                // 画面の応募者リストから削除
                tempprops.splice(index, 1);
                // 画面再表示
                this.props.updateSelectedCandidateList(tempprops);
                // 画面リフレッシュ
                this.forceUpdate();
            }
        });
    }

    /**
     * 応募者詳細情報を取得
     * @param {*} event 選択された対象
     */
    handleToDisplayWithData = (candidateId) => {
        window.scrollTo(0, 0);
        // 応募者詳細取得
        this.props.getCandidateDetail(candidateId, this.state.caseDetail.case_id);
    }

    /**
     * 組織情報(プロフィール)送り出し機関取得
     * @param {*} event 選択された対象
     */
    handleToDisplayWithOrgData = (sendingOrganizaitonId) => {
        window.scrollTo(0, 0);
        // 組織情報(プロフィール)送り出し機関取得
        this.props.getSendingOrganizaitonDetail(sendingOrganizaitonId);
    }

    /**
     * 組織情報(プロフィール)大学取得
     * @param {*} event 選択された対象
     */
    handleToDisplayWithUniData = (universityId) => {
        window.scrollTo(0, 0);
        // 組織情報(プロフィール)大学取得
        this.props.getUniversityDetail(universityId);
    }

    /**
     * web面接日登録
     */
    handleToDisplayWithInterviewData = (caseId, organizationId, organizationType) => {
        window.scrollTo(0, 0);
        // web面接日登録
        this.setState({
            case_id: caseId,
            organization_id: organizationId,
            organization_type: organizationType
        }, () => {
            this.props.getInterviewHistory(caseId, organizationId, organizationType);
        });
    }

    /**
     * web面接日登録（特定技能）
     */
    handleToDisplayWithInterviewDataTok = (caseId, organizationId, organizationType, candidateId) => {
        window.scrollTo(0, 0);
        // web面接日登録
        this.setState({
            case_id: caseId,
            organization_id: organizationId,
            organization_type: organizationType,
            candidate_id: candidateId
        }, () => {
            this.props.getInterviewHistoryTok(caseId, organizationId, organizationType, candidateId);
        });
    }

    /**
     * エラーがある時に上へ移動する
     */
    scrollToTopWithErrors = () => {
        const errs = ['300', '310'];
        if (errs.includes(this.props.err_type)
            || errs.includes(this.props.err_update_type)
            || errs.includes(this.props.err_candidate_type)
            || errs.includes(this.props.err_update_candidate_type)) {
            if (this.state.errScrolled == false) {
                this.setState({
                    errScrolled: true
                }, () => {
                    window.scrollTo(0, 0);
                });
            }
        } else {
            if (this.state.errScrolled == true) {
                this.setState({
                    errScrolled: false
                });
            }
        }
    }

    render() {

        // エラーがある時に上へ移動する
        this.scrollToTopWithErrors();

        // 案件詳細情報
        const { caseDetail } = this.state;
        // 組織ＩＤ、組織タイプ
        const { orgId, orgType } = this.state;

        // エントリ更新が成功になる場合
        if (this.props.updatedEntryList && this.props.err_update_type === '200') {
            // エントリ更新情報stateリセット
            this.props.CaseEntryListActionReset();
            // リストデータをリフレッシュする
            this.refreshList();
        }

        // 応募更新が成功になる場合
        if (this.props.updatedCandidateEntryList && this.props.err_update_candidate_type === '200') {
            // 応募者一覧情報stateリセット
            this.props.CaseEntryListUpdateActionReset();
            // 画面クリア
            this.props.updateSelectedCandidateList([]);
            // 応募者一覧再取得
            let candidatecondition = {};
            candidatecondition.case_id = this.state.caseDetail.case_id;
            candidatecondition.call_type = "2";
            this.props.getCaseCandidateList(candidatecondition);
            // リストデータをリフレッシュする
            this.refreshList();
        }

        // 組織情報(プロフィール)送り出し機関
        if (this.props.orgDetailData && this.props.orgErr_typeDetail === '200') {
            this.props.skipToNext("GCMOMCRU06", this.props.orgDetailData, "GRSMMIMD02");
        }

        // 組織情報(プロフィール)大学
        if (this.props.uniDetailData && this.props.uniErr_typeDetail === '200') {
            this.props.skipToNext("GCMOMCRU07", this.props.uniDetailData, "GRSMMIMD02");
        }

        // web面接日登録
        if (this.props.interviewDetailData && this.props.interviewErr_typeDetail === '200') {
            let obj = {
                case_id: this.state.case_id,
                organization_id: this.state.organization_id,
                organization_type: this.state.organization_type
            };

            let newObj = { ...this.props.interviewDetailData, ...obj };
            this.props.skipToNext("GRSMSMSA01", newObj, "GRSMMIMD02");
        }

        // 応募詳細取得が成功になる場合
        if (this.props.detailData && this.props.err_typeDetail === '200') {
            this.props.skipToNext("GRSMMAMF02", this.props.detailData, "GRSMMIMD02");
        }

        // エントリリスト取得が成功になる場合
        if ((orgType === 'TOK' || this.props.entryList && this.props.err_type === '200') && this.state.entryInfo === true) {
            // 案件情報へ移動する
            if (caseDetail.status_of_residence === "1") {
                this.props.changeDispWithData("GRSMMRMD02", {}, this.state.caseDetail);
            } else {
                this.props.changeDispWithData("interndetail", {}, this.state.caseDetail);
            }
        }

        // インターン案件詳細（募集要項情報）
        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                案件詳細
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a>
                                </li>
                                <li>
                                    案件詳細
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_update_type} err_detail={this.props.err_update_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_candidate_type} err_detail={this.props.err_candidate_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_update_candidate_type} err_detail={this.props.err_update_candidate_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">エントリー情報</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <ul className="bl_tabs_list" role="tablist">
                                        <li><a className="bl_tabs_link" href="#tab1" onClick={() => this.changeDispWithData(true)}>案件情報</a></li>
                                        <li><a className="bl_tabs_link is_current" href="#tab2">エントリ情報</a></li>
                                    </ul>
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <img src={caseDetail.logo} alt="" />
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                <dt>案件ステータス：</dt>
                                                <dd>{getStatus(caseDetail)}</dd>
                                                <dt>案件ID：</dt>
                                                <dd>{caseDetail.case_id}</dd>
                                                <dt>案件名：</dt>
                                                <dd>{caseDetail.case_title}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    {(() => {
                                        if (caseDetail.status_of_residence !== '1') {
                                            if (orgType === "OKR" || orgType === "UNI") {
                                                // 送出し機関の場合
                                                return (
                                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                                        <div className="bl_sect_header">
                                                            <h3 className="el_lv3Heading"><span>エントリー情報</span></h3>
                                                        </div>
                                                        {(() => {
                                                            // 現在時刻
                                                            let currentDate = caseDetail.sys_date ? caseDetail.sys_date.replace(/\-/g, "") : "";
                                                            // 募集終了日のフォマード
                                                            let pub_end = caseDetail.pub_period_end.replace(/\-/g, "");
                                                            if (pub_end >= currentDate) {
                                                                // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                if (this.props.entryList && this.props.entryList.length) {
                                                                    if (this.props.entryList[0].entry_status === "0") {
                                                                        //　案件エントリ情報.エントリステータス=0
                                                                        return (
                                                                            <div className="bl_horizBtnUnit__move hp_mb20">
                                                                                <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.entryUpdate("EntryButton")}>エントリーする</button>
                                                                            </div>
                                                                        );
                                                                    } else if (this.props.entryList[0].entry_status === "1") {
                                                                        // 案件エントリー情報.エントリステータス=1 の場合
                                                                        return (
                                                                            <div className="bl_horizBtnUnit__move hp_mb20">
                                                                                <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.entryUpdate("EntryQuitButton")}>取り下げする</button>
                                                                            </div>
                                                                        );
                                                                    }
                                                                } else {
                                                                    //　案件エントリ情報.エントリ情報が存在していない場合
                                                                    return (
                                                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                                                            <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.entryUpdate("EntryButton")}>エントリーする</button>
                                                                        </div>
                                                                    );
                                                                }
                                                            }
                                                        })()}

                                                        {this.props.entryList && this.props.entryList.length > 0 ? <div className="un_entryBox">
                                                            {(() => {
                                                                if (this.props.entryList && this.props.entryList.length) {
                                                                    return (
                                                                        this.props.entryList.map((entry) => {
                                                                            if (entry.case_entry_history_table && entry.case_entry_history_table.length) {
                                                                                return (
                                                                                    entry.case_entry_history_table.sort((a, b) => (a.entry_history_id - b.entry_history_id)
                                                                                    ).map((message, index) => (
                                                                                        <li key={index}>
                                                                                            <date>{message.entry_date_time}</date>
                                                                                            <p>{message.entry_message}&nbsp;{isBlank(message.entry_comment) ? '' : 'コメント「' + message.entry_comment + '」'}</p>
                                                                                        </li>
                                                                                    ))
                                                                                );
                                                                            }
                                                                        })
                                                                    );
                                                                }
                                                            })()}
                                                        </div> : null}
                                                    </section>
                                                );
                                            }
                                            if (orgType === "COD") {
                                                // SKTの場合
                                                return (
                                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                                        <div className="bl_sect_header">
                                                            <h3 className="el_lv3Heading"><span>エントリー情報</span></h3>
                                                        </div>
                                                        <div className='sticky_table_wrapper'>
                                                            <table className='sticky_table'>
                                                                <thead>
                                                                    <tr><th>エントリー組織</th><th>エントリー日</th><th>メッセージ</th><th>応募依頼</th></tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(() => {
                                                                        // 案件エントリリストが存在している場合
                                                                        if (this.props.entryList && this.props.entryList.length) {
                                                                            return (
                                                                                // 案件エントリリストを繰り返す
                                                                                this.props.entryList.sort((a, b) => (a.organization_id - b.organization_id)
                                                                                ).map((item, index) => (
                                                                                    <Fragment key={index}>
                                                                                        <tr>
                                                                                            <td>{item.org_type === "OKR" ?
                                                                                                <a href="#" className="el_link" onClick={() => this.handleToDisplayWithOrgData(item.organization_id)}>
                                                                                                    {item.organization_name}
                                                                                                </a>
                                                                                                : item.org_type === "UNI" ?
                                                                                                    <a href="#" className="el_link" onClick={() => this.handleToDisplayWithUniData(item.organization_id)}>
                                                                                                        {item.organization_name}
                                                                                                    </a>
                                                                                                    : item.organization_name}
                                                                                                {item.entry_status === "3" ?
                                                                                                    <Fragment>
                                                                                                        <br />
                                                                                                        <button type="button" className="el_smBlueBtn el_shadowBtn hp_mt10" onClick={() => this.handleToDisplayWithInterviewData(item.case_id, item.organization_id, item.org_type)}>Web面接日登録</button>
                                                                                                    </Fragment>
                                                                                                    : null}
                                                                                            </td>
                                                                                            <td>{item.create_datetime.replace(/\-/g, "/")}</td>
                                                                                            <td>
                                                                                                {(() => {
                                                                                                    // エントリー履歴を表示
                                                                                                    if (item.case_entry_history_table && item.case_entry_history_table.length) {
                                                                                                        return (
                                                                                                            item.case_entry_history_table.sort((a, b) => (a.entry_history_id - b.entry_history_id)
                                                                                                            ).map((message, key) => (
                                                                                                                <Fragment key={key}><p className="bl_tableTxt__l">{message.entry_date_time}&nbsp;{message.entry_message}&nbsp;{isBlank(message.entry_comment) ? '' : 'コメント「' + message.entry_comment + '」'}<br /></p></Fragment>
                                                                                                            ))
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            </td>
                                                                                            <td>
                                                                                                {(() => {
                                                                                                    //　案件エントリ情報.エントリステータス=1 の場合
                                                                                                    if (item.entry_status === "1") {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <Textarea type="text" maxlength={""} cols="4" rows="2" className="el_textarea hp_mb10" name="entry_comment" setValue={item.entry_comment} onHandleChange={(k, v) => this.editItemHandlerByEntry(index, k, v)} />
                                                                                                                <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("ReceptionButton", index, item)}>受付</button>
                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("SendButton", index, item)}>見送り</button>
                                                                                                                </div>
                                                                                                            </>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=0 の場合
                                                                                                    if (item.entry_status === "0") {
                                                                                                        return (
                                                                                                            <>エントリ取り下げ</>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=3 の場合
                                                                                                    if (item.entry_status === "3") {
                                                                                                        return (
                                                                                                            <>エントリ受付済</>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=9 の場合
                                                                                                    if (item.entry_status === "9") {
                                                                                                        return (
                                                                                                            <>エントリ見送り</>
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            );
                                                                        }
                                                                    })()}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                );
                                            }
                                            if (orgType !== "UNI" && orgType !== "OKR" && orgType !== "COD") {
                                                // 大学、送出し機関、SKT以外の場合
                                                return (
                                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                                        <div className="bl_sect_header">
                                                            <h3 className="el_lv3Heading"><span>エントリー情報</span></h3>
                                                        </div>
                                                        <div className='sticky_table_wrapper'>
                                                            <table className='sticky_table'>
                                                                <thead>
                                                                    <tr><th>エントリー組織</th><th>エントリー日</th><th>メッセージ</th><th>応募依頼</th></tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(() => {
                                                                        // 案件エントリリストが存在している場合
                                                                        if (this.props.entryList && this.props.entryList.length) {
                                                                            return (
                                                                                // 案件エントリリストを繰り返す
                                                                                this.props.entryList.sort((a, b) => (a.organization_id - b.organization_id)
                                                                                ).map((item, index) => (
                                                                                    <Fragment key={index}>
                                                                                        <tr>
                                                                                            <td>{item.org_type === "OKR" ?
                                                                                                <a href="#" className="el_link" onClick={() => this.handleToDisplayWithOrgData(item.organization_id)}>
                                                                                                    {item.organization_name}
                                                                                                </a>
                                                                                                : item.org_type === "UNI" ?
                                                                                                    <a href="#" className="el_link" onClick={() => this.handleToDisplayWithUniData(item.organization_id)}>
                                                                                                        {item.organization_name}
                                                                                                    </a>
                                                                                                    : item.organization_name}
                                                                                            </td>
                                                                                            <td>{item.create_datetime.replace(/\-/g, "/")}</td>
                                                                                            <td>
                                                                                                {(() => {
                                                                                                    if (item.case_entry_history_table && item.case_entry_history_table.length) {
                                                                                                        return (
                                                                                                            item.case_entry_history_table.sort((a, b) => (a.entry_history_id - b.entry_history_id)
                                                                                                            ).map((message, key) => (
                                                                                                                <Fragment key={key}><p className="bl_tableTxt__l">{message.entry_date_time}&nbsp;{message.entry_message}&nbsp;{isBlank(message.entry_comment) ? '' : 'コメント「' + message.entry_comment + '」'}<br /></p></Fragment>
                                                                                                            ))
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            </td>
                                                                                            <td>
                                                                                                {(() => {
                                                                                                    //　案件エントリ情報.エントリステータス=0 の場合
                                                                                                    if (item.entry_status === "0") {
                                                                                                        return (
                                                                                                            <>エントリ取り下げ</>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=1 の場合
                                                                                                    if (item.entry_status === "1") {
                                                                                                        return (
                                                                                                            <>エントリ依頼中</>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=3 の場合
                                                                                                    if (item.entry_status === "3") {
                                                                                                        return (
                                                                                                            <>エントリ受付済</>
                                                                                                        );
                                                                                                    }
                                                                                                    //　案件エントリ情報.エントリステータス=9 の場合
                                                                                                    if (item.entry_status === "9") {
                                                                                                        return (
                                                                                                            <>エントリ見送り</>
                                                                                                        );
                                                                                                    }
                                                                                                })()}
                                                                                            </td>
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            );
                                                                        }
                                                                    })()}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                );
                                            }
                                        }
                                    })()}
                                </div>
                            </div>
                            {(() => {
                                let currentDate = caseDetail.sys_date ? caseDetail.sys_date.replace(/\-/g, "") : "";
                                let pub_start = caseDetail.pub_period_start.replace(/\-/g, "");
                                if (orgType === "OKR" || orgType === "UNI" || orgType === "COP" || orgType === "COD" || orgType === "KAN" || orgType === "TOR" || orgType === "TOK") {
                                    // 送り出し機関、大学、企業、SKT、監理団体、登録支援機関の場合
                                    // エントリーステータスが3 の場合
                                    if (this.props.entryList && this.props.entryList.length && this.props.entryList[0].entry_status === "3" || caseDetail.status_of_residence === '1') {
                                        return (
                                            <div className="ly_mainCont_inner">
                                                <div className="bl_sect_header">
                                                    <h2 className="el_lv2Heading">応募者一覧</h2>
                                                </div>
                                                <div className="bl_sect_inner">
                                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                                        <div className="bl_sect_header">
                                                            <h3 className="el_lv3Heading"><span>応募者一覧</span></h3>
                                                        </div>
                                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                                            {(() => {
                                                                if (caseDetail.status_of_residence !== '1') {
                                                                    // 現在時刻
                                                                    let currentDate = caseDetail.sys_date ? caseDetail.sys_date.replace(/\-/g, "") : "";
                                                                    // 募集終了日のフォマード
                                                                    let pub_end = caseDetail.pub_period_end.replace(/\-/g, "");
                                                                    if (pub_end >= currentDate) {
                                                                        if (orgType === "OKR" || orgType === "UNI") {
                                                                            // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                            // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                            return (
                                                                                <button type="button" className="el_blueBtn el_shadowBtn hp_mr15" onClick={() => this.handleToQueryCandidateList()}>応募者編集</button>
                                                                            );
                                                                        }
                                                                    }
                                                                }
                                                            })()}
                                                            {(() => {
                                                                // 現在時刻
                                                                let currentDate = caseDetail.sys_date ? caseDetail.sys_date.replace(/\-/g, "") : "";
                                                                // 募集終了日のフォマード
                                                                let pub_end = caseDetail.pub_period_end.replace(/\-/g, "");
                                                                if (pub_end >= currentDate) {
                                                                    if (orgType === "OKR" || orgType === "UNI" || orgType === "TOK" && caseDetail.status_of_residence === '1') {
                                                                        // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                        // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                        if (caseDetail.status_of_residence === '1') {
                                                                            if (orgType === "TOK") {
                                                                                return (
                                                                                    <button type="button" className={"el_btn el_shadowBtn".concat(this.props.candidateList.fundraising_flg === "1" ? " is_disabled" : "")} disabled={this.props.candidateList.fundraising_flg === "1"} onClick={() => this.entryUpdate("ApplyButton", null, null)}>応募する</button>
                                                                                );
                                                                            }
                                                                        } else {
                                                                            if (this.props.entryList && this.props.entryList.length && this.props.entryList[0].entry_status === "3" && this.props.selectedCandidateProps && this.props.selectedCandidateProps.length > 0) {
                                                                                //　案件エントリ情報.エントリステータス=3
                                                                                return (
                                                                                    <button type="button" className="el_btn el_shadowBtn" onClick={() => this.entryUpdate("ApplyButton", null, null)}>応募する</button>
                                                                                );
                                                                            } else if (this.props.entryList && this.props.entryList.length && this.props.entryList[0].entry_status === "3" && this.props.selectedCandidateProps && this.props.selectedCandidateProps.length === 0) {
                                                                                //　案件エントリー.エントリーステータス=3 の場合
                                                                                return (
                                                                                    <button type="button" className="el_btn el_shadowBtn is_disabled">応募する</button>
                                                                                );
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                            })()}
                                                        </div>
                                                        <div className='sticky_table_wrapper'>
                                                            <table className='sticky_table'>
                                                                <thead>
                                                                    <tr>
                                                                        {orgType === "OKR" || orgType === "UNI" || caseDetail.status_of_residence === '1' ? null : orgType === "COP" ? <th>エントリー組織</th> : <th>エントリー組織</th>}
                                                                        {
                                                                            caseDetail.status_of_residence === '1' && pub_start > currentDate ?
                                                                                null
                                                                                : <Fragment>
                                                                                    <th>氏名（ローマ字）</th><th>国籍</th><th>生年月日</th><th>性別</th><th>日本語レベル</th><th>ステータス</th><th>連絡メッセージ</th>
                                                                                    {(() => {
                                                                                        if (orgType === "OKR" || orgType === "UNI" || orgType === "TOK" && caseDetail.status_of_residence === '1') {
                                                                                            // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                                            // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                                            // 「本人意向確認」を表示
                                                                                            return (
                                                                                                <Fragment>
                                                                                                    <th>削除</th>
                                                                                                    <th>本人意向確認</th>
                                                                                                </Fragment>);
                                                                                        } else if (orgType === "COP") {
                                                                                            // ログインユーザの組織タイプ＝'COP'企業　の場合
                                                                                            // 「選考結果」を表示
                                                                                            return (<th>選考結果</th>);
                                                                                        }
                                                                                    })()}
                                                                                </Fragment>
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(() => {
                                                                        // 画面に追加される応募者情報が存在している場合
                                                                        if (this.props.selectedCandidateProps && this.props.selectedCandidateProps.length) {
                                                                            return (
                                                                                // 新しく追加される応募者リストを繰り返す
                                                                                this.props.selectedCandidateProps.map((item, index) => (
                                                                                    <Fragment key={index}>
                                                                                        <tr>
                                                                                            {orgType !== "OKR" && orgType !== "UNI" && caseDetail.status_of_residence !== '1' ? <td>{item.organization_name}</td> : null}
                                                                                            {
                                                                                                caseDetail.status_of_residence === '1' && pub_start > currentDate ?
                                                                                                    null
                                                                                                    : <Fragment>
                                                                                                        <td>{item.candidate_name}</td>
                                                                                                        <td>{item.country_name}</td>
                                                                                                        <td>{item.candidate_birthday ? item.candidate_birthday.replace(/\-/g, "/") : ""}</td>
                                                                                                        <td>{item.candidate_sex_name}</td>
                                                                                                        <td>{item.candidate_japanese_level_name}</td>
                                                                                                        <td>{item.candidate_status_name}</td>
                                                                                                        <td></td>
                                                                                                        <td>
                                                                                                            <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                                                <button type="button" className="el_smBlueBtn el_shadowBtn" value={item.candidate_id} onClick={this.deleteCandidate}>削除</button>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td></td>
                                                                                                    </Fragment>
                                                                                            }
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            );
                                                                        }
                                                                    })()}
                                                                    {(() => {
                                                                        // 応募者リストが存在している場合
                                                                        if (this.props.candidateList.get_case_candidate_dict && this.props.candidateList.get_case_candidate_dict.length) {
                                                                            return (
                                                                                // 応募者リストを繰り返す
                                                                                this.props.candidateList.get_case_candidate_dict.map((item, index) => (
                                                                                    <Fragment key={index}>
                                                                                        <tr>
                                                                                            {orgType !== "OKR" && orgType !== "UNI" && caseDetail.status_of_residence !== '1' ? <td>{item.organization_name}</td> : null}
                                                                                            {
                                                                                                caseDetail.status_of_residence === '1' && pub_start > currentDate ?
                                                                                                    null
                                                                                                    : <Fragment>
                                                                                                        <td>
                                                                                                            <a href="#" className="el_link" onClick={() => this.handleToDisplayWithData(item.candidate_id)}>
                                                                                                                {item.candidate_name}
                                                                                                            </a>
                                                                                                            {
                                                                                                                caseDetail.status_of_residence === '1' && orgType === "COD" ?
                                                                                                                    <Fragment>
                                                                                                                        <br />
                                                                                                                        <button type="button" className="el_smBlueBtn el_shadowBtn hp_mt10" onClick={() => this.handleToDisplayWithInterviewDataTok(item.case_id, item.organization_id, item.org_type, item.candidate_id)}>Web面接日登録</button>
                                                                                                                    </Fragment>
                                                                                                                    : null
                                                                                                            }
                                                                                                        </td>
                                                                                                        <td>{item.country_name}</td>
                                                                                                        <td>{item.candidate_birthday ? item.candidate_birthday.replace(/\-/g, "/") : ""}</td>
                                                                                                        <td>{item.candidate_sex_name}</td>
                                                                                                        <td>{item.candidate_japanese_level_name}</td>
                                                                                                        <td>{item.candidate_status_name}</td>
                                                                                                        <td>
                                                                                                            {(() => {
                                                                                                                // 連絡メッセージ・・・応募者メッセージ履歴.アクションメッセージ
                                                                                                                if (item.candidate_message_history_table && item.candidate_message_history_table.length) {
                                                                                                                    return (
                                                                                                                        item.candidate_message_history_table.sort((a, b) => (a.history_id - b.history_id)
                                                                                                                        ).map((message, key) => (
                                                                                                                            <Fragment key={key}><p className="bl_tableTxt__l">{message.action_message}<br />{message.action_comment}</p></Fragment>
                                                                                                                        ))
                                                                                                                    );
                                                                                                                }
                                                                                                            })()}
                                                                                                        </td>
                                                                                                        {(() => {
                                                                                                            // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                                                            // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                                                            if (orgType === "OKR" || orgType === "UNI" || orgType === "TOK" && caseDetail.status_of_residence === '1') {
                                                                                                                return (
                                                                                                                    <td>
                                                                                                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                                                            <button type="button" className="el_smBlueBtn el_shadowBtn is_disabled">削除</button>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                );
                                                                                                            }
                                                                                                        })()}
                                                                                                        {(() => {
                                                                                                            // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                                                                            // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                                                                            if ((orgType === "OKR" || orgType === "UNI" || orgType === "TOK" && caseDetail.status_of_residence === '1')) {
                                                                                                                // 応募者ステータスが4（内定・確定待）の場合
                                                                                                                if (item.candidate_status === "4") {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <td>
                                                                                                                                <Textarea type="text" maxlength={""} cols="4" rows="2" className="el_textarea hp_mb10" name="candidate_comment" setValue={item.candidate_comment} onHandleChange={(k, v) => this.editItemHandlerByCandidate(index, k, v)}></Textarea>
                                                                                                                                <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("ConfirmButton", index, item, true)}>確認</button>
                                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("DeclineButton", index, item, true)}>辞退</button>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    // 応募者ステータスが4（内定・確定待）以外の場合
                                                                                                                    return (<td></td>);
                                                                                                                }
                                                                                                            } else if (orgType === "COP") {
                                                                                                                // ログインユーザの組織タイプ＝'COP'企業　の場合
                                                                                                                // 応募者ステータスが3（面接待ち）の場合
                                                                                                                if (item.candidate_status === "1") {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <td>
                                                                                                                                <Textarea type="text" maxlength={""} cols="4" rows="2" className="el_textarea hp_mb10" name="candidate_comment" setValue={item.candidate_comment} onHandleChange={(k, v) => this.editItemHandlerByCandidate(index, k, v)}></Textarea>
                                                                                                                                <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("AdoptionButton", index, item, true)}>採用</button>
                                                                                                                                    <button type="button" className="el_smBtn el_shadowBtn" onClick={() => this.entryUpdate("UnadoptionButton", index, item, true)}>不採用</button>
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                        </>
                                                                                                                    );
                                                                                                                } else {
                                                                                                                    return (<td></td>);
                                                                                                                }
                                                                                                            }
                                                                                                        })()}
                                                                                                    </Fragment>
                                                                                            }
                                                                                        </tr>
                                                                                    </Fragment>
                                                                                ))
                                                                            );
                                                                        }
                                                                    })()}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                                        <div class="bl_sect_header">
                                                            <h3 class="el_lv3Heading"><span>Web面接情報</span></h3>
                                                        </div>
                                                        <div class='sticky_table_wrapper'>
                                                            <table class='sticky_table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Web面接タイトル</th><th>Web面接日時</th>{orgType === "TOK" ? '' : <th>Web面接メンバー</th>}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {(() => {
                                                                        if (this.props.candidateList.web_interview_data_dict && this.props.candidateList.web_interview_data_dict.length) {
                                                                            return (
                                                                                this.props.candidateList.web_interview_data_dict.map((item, index) => (
                                                                                    <tr>
                                                                                        <td>{item.interview_title}</td>
                                                                                        <td>{getDateText(item.interview_date)} {item.interview_start_time}～ {item.code_name}</td>
                                                                                        {orgType === "TOK" ? '' : <td>{item.user_name}</td>}
                                                                                    </tr>
                                                                                ))
                                                                            );
                                                                        }
                                                                    })()}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })()}
                            <div className="ly_mainCont_inner">
                                <button type="button" className="el_backBtn" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧へ</button>
                            </div>
                        </section>
                    </div>
                </main>
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.getEntryList.isFetching,
        entryList: state.getEntryList.overViewData,
        isError: state.getEntryList.isError,
        err_type: state.getEntryList.err_type,
        err_detail: state.getEntryList.err_detail,
        isUpdateFetching: state.updateCaseEntryList.isFetching,
        updatedEntryList: state.updateCaseEntryList.overViewData,
        isUpdateError: state.updateCaseEntryList.isError,
        err_update_type: state.updateCaseEntryList.err_type,
        err_update_detail: state.updateCaseEntryList.err_detail,
        isCandidateFetching: state.getCaseCandidateList.isFetching,
        candidateList: state.getCaseCandidateList.overViewData,
        isCandidateError: state.getCaseCandidateList.isError,
        err_candidate_type: state.getCaseCandidateList.err_type,
        err_candidate_detail: state.getCaseCandidateList.err_detail,
        selectedCandidateProps: state.updateSelectedCandidateList.selectedCandidateProps,
        isUpdateCandidateFetching: state.updateCaseEntryListByCandidate.isFetching,
        updatedCandidateEntryList: state.updateCaseEntryListByCandidate.overViewData,
        isUpdateCandidateError: state.updateCaseEntryListByCandidate.isError,
        err_update_candidate_type: state.updateCaseEntryListByCandidate.err_type,
        err_update_candidate_detail: state.updateCaseEntryListByCandidate.err_detail,
        isDetailFetching: state.getCandidateDetail.isFetching,
        detailData: state.getCandidateDetail.data,
        isDetailError: state.getCandidateDetail.isError,
        err_typeDetail: state.getCandidateDetail.err_type,
        err_detailDetail: state.getCandidateDetail.err_detail,
        orgDetailData: state.getSendingOrganizationInfo.data,
        orgIsDetailError: state.getSendingOrganizationInfo.isError,
        orgErr_typeDetail: state.getSendingOrganizationInfo.err_type,
        orgErr_detailDetail: state.getSendingOrganizationInfo.err_detail,
        uniDetailData: state.getUniversityInfo.data,
        uniIsDetailError: state.getUniversityInfo.isError,
        uniErr_typeDetail: state.getUniversityInfo.err_type,
        uniErr_detailDetail: state.getUniversityInfo.err_detail,
        interviewDetailData: state.getInterviewHistory.data,
        interviewIsDetailError: state.getInterviewHistory.isError,
        interviewErr_typeDetail: state.getInterviewHistory.err_type,
        interviewErr_detailDetail: state.getInterviewHistory.err_detail
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getEntryList(condition) {
            // エントリリスト取得
            dispatch(getEntryList(condition));
        }, EntryListReset() {
            // エントリリストstateリセット
            dispatch(EntryListReset());
        }, updateCaseEntryList(condition) {
            // エントリリスト更新
            dispatch(updateCaseEntryList(condition));
        }, CaseEntryListActionReset() {
            // エントリリストstateリセット
            dispatch(CaseEntryListActionReset());
        }, getCaseCandidateList(condition) {
            // 応募者一覧情報取得
            dispatch(getCaseCandidateList(condition));
        }, GetCaseCandidateListReset() {
            // 応募者一覧情報stateリセット
            dispatch(GetCaseCandidateListReset());
        }, updateSelectedCandidateList(condition) {
            // 応募者一覧情報state更新
            dispatch(updateSelectedCandidateList(condition));
        }, updateCaseEntryListByCandidate(condition) {
            // 応募者一覧情報更新
            dispatch(updateCaseEntryListByCandidate(condition));
        }, CaseEntryListUpdateActionReset() {
            // 応募者一覧情報stateリセット
            dispatch(CaseEntryListUpdateActionReset());
        }, CaseDetailReset() {
            // 案件詳細情報stateリセット
            dispatch(CaseDetailReset());
        }, getCandidateDetail(candidateId, caseId) {
            // 応募者詳細取得
            dispatch(getCaseCandidateDetail(candidateId, caseId));
        }, getCandidateDetailReset() {
            // 応募者詳細取得ステータスリセット
            dispatch(getCandidateDetailReset);
        }, getSendingOrganizaitonDetail(sendingOrganizaitonId) {
            // 送出し機関詳細取得
            dispatch(getSendingOrganizationInfo(
                { 'organization_id': sendingOrganizaitonId }));
        }, getSendingOrganizationInfoReset() {
            // 送出し機関詳細取得ステータスリセット
            dispatch(getSendingOrganizationInfoReset);
        }, getUniversityDetail(universityId) {
            // 大学詳細取得
            dispatch(getUniversityInfo(
                { 'organization_id': universityId }));
        }, getUniversityInfoReset() {
            // 大学詳細取得ステータスリセット
            dispatch(getUniversityInfoReset);
        }, getInterviewHistory(caseId, organizationId, organizationType) {
            // web面接日詳細取得
            dispatch(getInterviewHistory({
                'case_id': caseId,
                'organization_id': organizationId,
                'organization_type': organizationType,
            }));
        }, getInterviewHistoryTok(caseId, organizationId, organizationType, candidateId) {
            // web面接日詳細取得（特定技能）
            dispatch(getInterviewHistory({
                'case_id': caseId,
                'organization_id': organizationId,
                'organization_type': organizationType,
                'candidate_id': candidateId,
            }));
        }, getInterviewHistoryReset() {
            // web面接日詳細取得ステータスリセット
            dispatch(getInterviewHistoryReset);
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMIMD02);
