import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCUM02ActionType';

// 実習／労働者詳細
const GET_TRAINNING_WORKER_DETAIL_POST = BASE_URL + "/api/cm_om_get_trainning_worker_detail";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetTrainningWorkerDetailRequest = () => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_DETAIL_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetTrainningWorkerDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_DETAIL_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetTrainningWorkerDetailFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_DETAIL_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetTrainningWorkerDetailReset = () => {
    return {
        type: actionTypes.GET_TRAINNING_WORKER_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getTrainningWorkerDetail = (data) => {
    return async (dispatch) => {
        dispatch(GetTrainningWorkerDetailRequest());
        ecoPostWithAuthHeader(GET_TRAINNING_WORKER_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(GetTrainningWorkerDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetTrainningWorkerDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getTrainningWorkerDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainningWorkerDetailReset());
    };
};