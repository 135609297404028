import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getPrefecturesMasterList, getMessage } from "../../Constants/CommonConstants";
import { MessageMap } from "../OmGCMOMCRU01/GCMOMCRU01Message";
import {
    getCompanyAndFacilityInfo,
    getCompanyAndFacilityInfoReset,
    updateCompanyAndFacilityInfo,
    updateCompanyAndFacilityInfoReset
}
    from "../OmGCMOMCRU01/GCMOMCRU01Action.js";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 組織情報(プロフィール)企業施設情報
 */
class GCMOMCRU02 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            company_prefectures_name: [],
            countryOpt: [],
            selectedFacilityId: "",
            processDivision: "",
            isFacilityDelete: false,
            areaShow: false,
            err_msg: [],
            facilityInfo: {
                company_id: "",
                facility_id: "",
                facility_name: "",
                facility_name_kana: "",
                facility_prefectures_id: "",
                facility_prefectures_name: "",
                prefectures_name: "",
                facility_address_2: "",
                home_page: "",
                position: "",
                pic_name: "",
                pic_name_kana: "",
                pic_tel_country_code: "",
                pic_tel_country_name: "",
                pic_tel_1: "",
                pic_tel_2: "",
                pic_tel_3: "",
                mail_address: ""
            },
            facilityInfoList: this.props.facilityData
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        // 都道府県
        const prefecturesRet = Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
            company_prefectures_name: [...prevState.company_prefectures_name, ...prefecturesRet],
        }));
        window.scrollTo(0, 0);
    }

    /**
     * 初期データ
     */
    initialData = () => {
        return {
            company_id: "",
            facility_id: "",
            facility_name: "",
            facility_name_kana: "",
            facility_prefectures_id: "",
            facility_prefectures_name: "",
            prefectures_name: "",
            facility_address_2: "",
            home_page: "",
            position: "",
            pic_name: "",
            pic_name_kana: "",
            pic_tel_country_code: "",
            pic_tel_country_name: "",
            pic_tel_1: "",
            pic_tel_2: "",
            pic_tel_3: "",
            version_no: 0,
            mail_address: ""
        };
    }

    /**
     * 施設所在地の変更
     * @param {*} event
     */
    handleFacilityPrefecturesChange = (event) => {
        this.setState({
            facility_prefectures_id: event.target.value
        }, () => {
            this.editItemHandler("facility_prefectures_id", this.state.facility_prefectures_id);
        });
    };

    /**
     * 電話番号の変更
     * @param {*} event
     */
    handlePicTelCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (isBlank(this.state.pic_tel_country_name)) {
                this.setState({
                    pic_tel_country_number: '',
                    pic_tel_country_code: ''
                }, () => {
                    this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
                    this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
                    this.editItemHandler("pic_tel_country_number", this.state.pic_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 担当者電話番号＿国・地域名
                if (country.country_name === value) {
                    this.setState({
                        pic_tel_country_code: country.country_code,
                        pic_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("pic_tel_country_code", this.state.pic_tel_country_code);
                        this.editItemHandler("pic_tel_country_name", this.state.pic_tel_country_name);
                        this.editItemHandler("pic_tel_country_number", this.state.pic_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * 施設選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        const { value } = event.target;
        this.setState({
            err_msg: [],
            selectedFacilityId: this.state.facilityInfoList[value].facility_id,
            selectedFacilityData: this.state.facilityInfoList[value]
        });
    }

    /**
     * 新規登録
     */
    handlingSignUp = () => {
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        this.setState({
            facilityInfo: this.initialData(),
            areaShow: true,
            processDivision: '1',
            err_msg: []
        }, () => {
            if (isBlank(this.state.facilityInfo.pic_tel_country_name)) {
                this.setState({
                    pic_tel_country_number: '',
                    pic_tel_country_code: ''
                }, () => {
                    this.editItemHandler("pic_tel_country_code", this.state.facilityInfo.pic_tel_country_code);
                    this.editItemHandler("pic_tel_country_name", this.state.facilityInfo.pic_tel_country_name);
                    this.editItemHandler("pic_tel_country_number", this.state.facilityInfo.pic_tel_country_number);
                });
            }
        });
    }

    /**
     * 施設修正の処理を実行する
     */
    handleModifyOKAction = () => {
        const { selectedFacilityData, countryOpt } = this.state;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 条件設定
        this.setState({
            facilityInfo: selectedFacilityData,
            processDivision: '2',
            areaShow: true
        }, () => {
            // 代表者電話番号と国名
            countryOpt.forEach(country => {
                if (country.country_code === this.state.facilityInfo.pic_tel_country_code) {
                    // 担当者電話番号
                    this.setState({
                        pic_tel_country_name: country.country_name,
                        pic_tel_country_code: country.country_code,
                        pic_tel_country_number: country.country_number
                    });
                }
            });
        });
    }

    /**
     * 施設削除のを実行する
     */
    handlingDeleteOKAction = () => {
        const { facilityInfoList, processDivision, selectedFacilityId } = this.state;

        // 施設リストを繰り返す
        facilityInfoList.map((item, index) => {
            // 削除しようとしている施設があった場合
            if (item.facility_id === selectedFacilityId) {
                // 画面の施設リストから削除
                facilityInfoList.splice(index, 1);

                let newData = Object.assign({}, item);
                newData['update_type'] = '3';
                newData['process_division'] = '3';
                newData['facility_version_no'] = item.version_no;
                this.setState({
                    facilityInfo: newData,
                    selectedFacilityId: '',
                    isFacilityDelete: true
                }, () => {
                    this.props.createFacilityInfo(this.state.facilityInfo);
                });
            }
        });
    }

    /**
     * 施設のキャンセル処理
     */
    handlingCancelAction = () => {
        this.setState({
            facilityInfo: this.initialData()
        });
    }

    /**
     * 施設修正
     */
    handlingModify = () => {
        window.scrollTo(0, 0);
        this.initEditArea();
        if (isBlank(this.state.selectedFacilityId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            // 編集画面へ移動する
            this.handleModifyOKAction();
        }
    }

    /**
     * 施設削除処理
     */
    handlingDelete = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        this.initEditArea();

        if (isBlank(this.state.selectedFacilityId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            let message = getMessage('RS-0040');
            // 確認ダイアログメッセージボックスを表示する
            this.showModalDialog(1, message, this.handlingDeleteOKAction, this.handlingCancelAction);
        }
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.companyAndFacilityInfoReset();
    }

    /**
    * 登録
    */
    saveFacilityInfo = () => {
        const { facilityInfo, processDivision } = this.state;
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let newData = Object.assign({}, facilityInfo);
        newData['update_type'] = '2';
        newData['process_division'] = processDivision;
        newData['facility_version_no'] = facilityInfo.version_no;
        this.setState({
            facilityInfo: newData
        }, () => {
            this.props.createFacilityInfo(this.state.facilityInfo);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            facilityInfo: {
                ...prevState.facilityInfo,
                [name]: value
            }
        }));
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    redirectToCompany = () => {
        window.scrollTo(0, 0);

        // 企業画面に移動
        this.props.redirectToCompany("GCMOMCRU01");
    }

    /**
     * 画面初期化表示
     */
    initEditArea = () => {
        this.props.companyAndFacilityInfoReset();
        this.setState({
            facilityInfo: this.initialData(),
            areaShow: false
        });

    }

    render() {
        const { facilityInfo, facilityInfoList, areaShow, processDivision } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.facility_version_no != null) {
                this.initEditArea();
                if (this.state.isFacilityDelete == true) {
                    // 削除の場合完了メッセージ不要
                    this.setState({ isFacilityDelete: false }, () => { this.props.getCompanyAndFacilityInfo(); });
                } else {
                    // 削除以外の場合完了メッセージ
                    this.showModalDialog(0, getMessage('RS-0036'), () => { this.props.getCompanyAndFacilityInfo(); });
                }

            }
        }

        let count = 4;
        facilityInfoList && facilityInfoList.forEach((v, i) => {
            count = 4 + i;
        });

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織情報(プロフィール)</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ul className="bl_tabs_list" role="tablist">
                                    <li><a className="bl_tabs_link" href="#tab1" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.redirectToCompany(); }, () => { })} tabIndex="19">組織情報</a></li>
                                    <li><a className="bl_tabs_link is_current" href="#" tabIndex="20">施設情報</a></li>
                                </ul>
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ?
                                            <div id='errorarea'>
                                                <ul className='errorArea'>
                                                    {
                                                        Object.entries(this.state.err_msg).map(
                                                            ([key]) => (
                                                                <li key={key}>{this.state.err_msg[key]}</li>
                                                            )
                                                        )
                                                    }
                                                </ul>
                                            </div> : null
                                    );
                                }
                                )()}
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">施設一覧</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                            <button type="reset" name="signUp" className="el_blueBtn el_shadowBtn hp_mr15" onClick={() => this.handlingSignUp()} tabIndex="1">新規登録</button>
                                            <button type="button" name="modify" className="el_blueBtn el_shadowBtn hp_mr15" onClick={() => this.handlingModify()} tabIndex="2">修正​</button>
                                            <button type="button" name="delete" className="el_btn el_shadowBtn" onClick={() => this.handlingDelete()} tabIndex="3">削除​</button>
                                        </div>
                                        <div className='sticky_table_wrapper sticky_table_wrapperNarrow hp_mb7Rem'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr>
                                                        <th>選択</th>
                                                        <th>都道府県</th>
                                                        <th>施設名</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(facilityInfoList)) {
                                                            return (
                                                                facilityInfoList.map((item, index) => (
                                                                    <tr key={item.facility_id}>
                                                                        <td className="hp_tac">
                                                                            <input type="radio" name="row_select" value={index} onChange={this.handleRowSelection} tabIndex="4" />
                                                                        </td>
                                                                        <td>{item.prefectures_name}</td>
                                                                        <td>{item.facility_name}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                                {areaShow ?
                                    <>
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">施設情報登録/修正</h2>
                                        </div>
                                        <div className="bl_sect_inner">
                                            <section className="bl_sect_frame">
                                                <table className="bl_horizTable">
                                                    <tbody>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />施設名
                                                            </th>
                                                            <td>
                                                                <TextInput type="text" name="facility_name" className="el_input el_input__max" toZenKana="true" maxlength="60" required="required" setValue={facilityInfo.facility_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />施設名（フリガナ）
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="facility_name_kana" className="el_input el_input__max" toZenKana="true" maxlength="60" required="required" setValue={facilityInfo.facility_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />施設所在地
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <select className="el_select el_select_pref" name="facility_prefectures_name" value={facilityInfo.facility_prefectures_id} onChange={this.handleFacilityPrefecturesChange} tabIndex="7">
                                                                        <option value="" selected></option>
                                                                        {
                                                                            this.state.company_prefectures_name && this.state.company_prefectures_name.map(item => (
                                                                                <option key={item.prefectures_id} value={item.prefectures_id} selected={facilityInfo.facility_prefectures_id == item.prefectures_id}>
                                                                                    {item.prefectures_name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    <TextInput type="text" name="facility_address_2" className="el_input el_input__max" maxlength="60" required="required" setValue={facilityInfo.facility_address_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />ホームページ
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="home_page" className="el_input el_input__max" maxlength="60" required="required" setValue={facilityInfo.home_page} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />役職
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="position" className="el_input el_input__max" maxlength="40" required="required" setValue={facilityInfo.position} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="10" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />担当者氏名
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="pic_name" className="el_input el_input__max" maxlength="40" required="required" setValue={facilityInfo.pic_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="11" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />担当者氏名（フリガナ）
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="pic_name_kana" className="el_input el_input__max" toZenKana="true" maxlength="40" required="required" setValue={facilityInfo.pic_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="12" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />電話番号
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <select className="el_select el_select_country" name="pic_tel_country_name" value={facilityInfo.pic_tel_country_name} onChange={this.handlePicTelCountryNameChange} tabIndex="13">
                                                                        <option value="" selected></option>
                                                                        {
                                                                            this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                                <option key={item.country_code} value={item.country_name} selected={facilityInfo.pic_tel_country_name == item.country_name}>
                                                                                    {item.country_name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <TextInput type="text" name="pic_tel_country_number" className="el_input el_input__countryCode" setValue={this.state.pic_tel_country_number} maxlength="6" readOnly={true} />
                                                                <TextInput type="text" name="pic_tel_1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={facilityInfo.pic_tel_1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="14" />&nbsp;-&nbsp;
                                                                <TextInput type="text" name="pic_tel_2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={facilityInfo.pic_tel_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="15" />&nbsp;-&nbsp;
                                                                <TextInput type="text" name="pic_tel_3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={facilityInfo.pic_tel_3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="16" />
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                <span className="el_badge el_badge__required">必須</span><br />メールアドレス
                                                            </th>
                                                            <td>
                                                                <div className="bl_formUnit">
                                                                    <TextInput type="text" name="mail_address" className="el_input el_input__max" maxlength="81" required="required" setValue={facilityInfo.mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="17" />
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                            <div className="bl_horizBtnUnit">
                                                <button type="button" className="el_btn" onClick={() => this.saveFacilityInfo()} tabIndex="18">{processDivision === '1' ? '登録する' : '修正する'}</button>
                                            </div>
                                        </div>
                                    </> : null}
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.updateCompanyAndFacilityInfo.isFetching,
        data: state.updateCompanyAndFacilityInfo.data,
        isError: state.updateCompanyAndFacilityInfo.isError,
        err_type: state.updateCompanyAndFacilityInfo.err_type,
        err_detail: state.updateCompanyAndFacilityInfo.err_detail,
        // 組織情報(プロフィール)企業情報・企業施設情報
        facilityData: state.getCompanyAndFacilityInfo.data,
        isfacilityError: state.getCompanyAndFacilityInfo.isError,
        facilityErrtype: state.getCompanyAndFacilityInfo.err_type,
        facilityErrDetail: state.getCompanyAndFacilityInfo.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getCompanyAndFacilityInfo() {
            // 組織情報(プロフィール)企業情報取得
            dispatch(getCompanyAndFacilityInfo({
                'get_type': '2'
            }));
        },
        createFacilityInfo(arg) {
            // 組織情報(プロフィール)送り出し機関取得
            dispatch(updateCompanyAndFacilityInfo(arg));
        },
        companyAndFacilityInfoReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(updateCompanyAndFacilityInfoReset());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMCRU02);

