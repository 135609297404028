import actionTypes from './GRSMMIMR01ActionTypes';

/**
 * 企業情報
 */
const companyInitialState = {
    isFetching: false,
    data: [],
    isError: false,
    errorMessage: ""
};

/**
 * 案件詳細
 */
const caseInitialState = {
    isFetching: false,
    caseData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * インターン案件募集を登録するReducer
 * @param {*} state
 * @param {*} action
 */
export const createCase = (state = caseInitialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_CASE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                caseData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREATE_CASE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                caseData: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.CREATE_CASE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                caseData: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.CREATE_CASE_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CREATE_CASE_RESET_ALL_DATA:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                caseData: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 企業情報リストを取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getCompanyList = (state = companyInitialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false,
                errorMessage: ""
            });
        case actionTypes.GET_COMPANY_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                errorMessage: action.data.err_type
            });
        case actionTypes.GET_COMPANY_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false,
                errorMessage: ""
            });
        case actionTypes.GET_COMPANY_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                data: [],
                isError: false,
                errorMessage: ""
            });
        default:
            return state;
    }
};
