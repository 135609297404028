import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMOIR01ActionType';

// 組織管理一覧取得API
const GET_ORGANIZATION_MANAGEMENT_POST = BASE_URL + "/api/cm_om_get_system_org_tbl_info";
// 組織管理更新API
const GET_ORGANIZATION_MANAGEMENT_DETAIL_POST = BASE_URL + "/api/cm_om_get_system_org_tbl_detail_info";
// 組織管理削除API
const DELETE_ORGANIZATION_MANAGEMENT_POST = BASE_URL + "/api/cm_om_delete_system_org_tbl_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetOrganizationManagementListRequest = () => {
    return {
        type: actionTypes.GET_ORGANIZATION_MANAGEMENT_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetOrganizationManagementListSuccess = (data) => {
    return {
        type: actionTypes.GET_ORGANIZATION_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetOrganizationManagementListFailure = (err) => {
    return {
        type: actionTypes.GET_ORGANIZATION_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetOrganizationManagementListReset = () => {
    return {
        type: actionTypes.GET_ORGANIZATION_MANAGEMENT_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateOrganizationManagementRequest = () => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateOrganizationManagementSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateOrganizationManagementFailure = (err) => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateOrganizationManagementReset = () => {
    return {
        type: actionTypes.UPDATE_ORGANIZATION_MANAGEMENT_RESET
    };
};

/**
 * 削除リクエスト
 */
export const DeleteOrganizationManagementRequest = () => {
    return {
        type: actionTypes.DELETE_ORGANIZATION_MANAGEMENT_REQUEST
    };
};

/**
 * 削除成功
 * @param {*} data
 */
export const DeleteOrganizationManagementSuccess = (data) => {
    return {
        type: actionTypes.DELETE_ORGANIZATION_MANAGEMENT_SUCCESS,
        data
    };
};

/**
 * 削除失敗
 * @param {*} err
 */
export const DeleteOrganizationManagementFailure = (err) => {
    return {
        type: actionTypes.DELETE_ORGANIZATION_MANAGEMENT_FAILURE,
        err
    };
};

/**
 * 削除リセット
 */
export const DeleteOrganizationManagementReset = () => {
    return {
        type: actionTypes.DELETE_ORGANIZATION_MANAGEMENT_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getOrganizationManagementList = (data) => {
    return async (dispatch) => {
        dispatch(GetOrganizationManagementListRequest());
        ecoPostWithAuthHeader(GET_ORGANIZATION_MANAGEMENT_POST, JSON.stringify(data)).then(
            res => dispatch(GetOrganizationManagementListSuccess(res.data))
        ).catch(
            err => dispatch(GetOrganizationManagementListFailure(err))
        );
    };
};

/**
 * 修正処理
 * @param {*} data
 */
export const updateOrganizationManagement = (data) => {
    return async (dispatch) => {
        dispatch(UpdateOrganizationManagementRequest());
        ecoPostWithAuthHeader(GET_ORGANIZATION_MANAGEMENT_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateOrganizationManagementSuccess(res.data))
        ).catch(
            err => dispatch(UpdateOrganizationManagementFailure(err))
        );
    };
};

/**
 * 削除処理
 * @param {*} data
 */
export const deleteOrganizationManagement = (data) => {
    return async (dispatch) => {
        dispatch(DeleteOrganizationManagementRequest());
        ecoPostWithAuthHeader(DELETE_ORGANIZATION_MANAGEMENT_POST, JSON.stringify(data)).then(
            res => dispatch(DeleteOrganizationManagementSuccess(res.data))
        ).catch(
            err => dispatch(DeleteOrganizationManagementFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getOrganizationManagementListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetOrganizationManagementListReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateOrganizationManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateOrganizationManagementReset());
    };
};

/**
 * 画面リセットAction
 */
export const deleteOrganizationManagementReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(DeleteOrganizationManagementReset());
    };
};