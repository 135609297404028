import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { MessageMap } from "./GISAMIAR02Message";
import { getTrainingResults, getTrainingResultsReset, updateTrainingResults, updateTrainingResultsReset } from "../AmGISAMIAR02/GISAMIAR02Action";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import "../../Css/Common.css";

/**
 * 実習結果登録
 */
class GISAMIAR02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
            init_flg: 0,
            case_id: this.props.detailData.case_id,
            company_id: this.props.detailData.company_id,
            candidate_id: this.props.detailData.candidate_id,
            training_results_regist_status: this.props.detailData.training_results_regist_status,
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.pageInitial();
    }

    /**
     * 初期化処理
     */
    pageInitial = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.getTrainingResultsReset());
        this.setState({
            err_msg: []
        }, () => {
            // 初期化処理を行う
            this.props.getTrainingResults(this.state);
        });
    }

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * 回答結果
     * @param {*} event
     */
    setAnswerResult = (event) => {
        const { name } = event.target;
        let chk = event.target.checked ? '1' : '0';
        this.setState({
            [name]: chk
        }, () => {
            this.editItemHandler(name, chk);
        });
    }

    /**
     * 登録する
     */
    register = () => {
        window.scrollTo(0, 0);
        this.props.updateTrainingResults(this.state);
    }


    /**
     * 一覧画面切り替え
     */
    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { init_flg, version_no } = this.state;
        if (this.props.trainingResultsData && this.props.trainingResultsData.version_no != null) {
            if (init_flg === 0) {
                let newState = Object.assign({}, this.props.trainingResultsData);
                this.setState(newState);

                if (this.state.training_results_regist_status == '0') {
                    this.setState({
                        answer_result_01: '0',
                        answer_result_02: '0',
                        answer_result_03: '0',
                        answer_result_04: '0',
                        answer_result_05: '0',
                        answer_result_06: '0',
                        answer_result_other: '0',
                        other_answer: '',
                        training_report: '',
                        question_item_01: '働くことの厳しさを知った',
                        question_item_02: '現場の技術を体験できた',
                        question_item_03: '学校で学んだことを体験できた',
                        question_item_04: '今後の勉強の方向のヒントを得た',
                        question_item_05: '職業選択の参考になった',
                        question_item_06: '会社で働く先輩との交流ができた',
                        question_item_other: 'その他',
                    });
                }
                this.editItemHandler('init_flg', 1);
            } else if (version_no !== this.props.trainingResultsData.version_no) {
                // 最新バージョン設定する
                this.editItemHandler('version_no', this.props.trainingResultsData.version_no);
                this.editItemHandler('training_results_regist_status', this.props.trainingResultsData.training_results_regist_status);
                // 成功メッセージを表示
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.props.updateTrainingResultsReset();
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                実習結果登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISAMIRS01'); }, () => { })}>実習結果一覧</a>
                                </li>
                                <li>実習結果登録</li>
                            </ol>
                        </nav>

                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.training_results_err_type} err_detail={this.props.training_results_err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <Fragment>
                                    {this.state.init_flg != 0 ?
                                        <Fragment>
                                            <div className="bl_sect_inner">
                                                <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                                    <table className="bl_horizTable02 hp_mb20">
                                                        <tbody>
                                                            <tr>
                                                                <th>実習学生</th>
                                                                <td>学籍番号：{this.state.employment_student_no}<br />
                                                    氏名：{this.state.employment_name}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>性別</th>
                                                                <td>{this.state.employment_sex_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>学部・学科</th>
                                                                <td>{this.state.employment_department}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>コース・学年</th>
                                                                <td>{this.state.study_period}年</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <table className="bl_horizTable02">
                                                        <tbody>
                                                            <tr>
                                                                <th>企業名</th>
                                                                <td>{this.state.company_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>配属部署</th>
                                                                <td>{this.state.facility_name}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    実習期間
                                                </th>
                                                                <td>
                                                                    {this.state.accept_start}〜{this.state.accept_end}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>実施内容</th>
                                                                <td>{this.state.occupation}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </section>
                                            </div>
                                            <div className="bl_sect_inner">
                                                <div className="bl_sect_header">
                                                    <h2 className="el_lv2Heading">実習結果</h2>
                                                </div>
                                                <section className="bl_sect_frame">
                                                    <table className="bl_horizTable02">
                                                        <tbody>
                                                            <tr>
                                                                <th>
                                                                    <span className="el_badge el_badge__required hp_mr10">必須</span>
                                                                    インターンシップに参加して自分の役に立ったことはなんですか。（複数回答可）
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="bl_formUnit bl_formUnit__flex">
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_01" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_01 === '1'} onChange={this.setAnswerResult} tabIndex={1} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_01}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_02" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_02 === '1'} onChange={this.setAnswerResult} tabIndex={2} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_02}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_03" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_03 === '1'} onChange={this.setAnswerResult} tabIndex={3} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_03}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_04" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_04 === '1'} onChange={this.setAnswerResult} tabIndex={4} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_04}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_05" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_05 === '1'} onChange={this.setAnswerResult} tabIndex={5} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_05}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_06" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_06 === '1'} onChange={this.setAnswerResult} tabIndex={6} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_06}</span>
                                                                        </label>
                                                                        <label className="el_label">
                                                                            <input className="el_checkbox" type="checkbox" name="answer_result_other" disabled={this.state.training_results_regist_status === '2'}
                                                                                checked={this.state.answer_result_other === '1'} onChange={this.setAnswerResult} tabIndex={7} />
                                                                            <span className="el_checkbox_txt">{this.state.question_item_other}</span>
                                                                        </label>
                                                                        <TextInput type="text" name="other_answer" className="el_input el_input__max" placeholder="その他の場合に記入してください"
                                                                            disabled={this.state.training_results_regist_status === '2'}
                                                                            maxlength="200" setValue={this.state.other_answer} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    <span className="el_badge el_badge__required hp_mr10">必須</span>実習報告
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="bl_formUnit hp_mb10">
                                                                        <Textarea name="training_report" className="el_textarea" rows="6" maxlength="1000" required="required"
                                                                            disabled={this.state.training_results_regist_status === '2'}
                                                                            setValue={this.state.training_report} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </section>
                                                {this.state.training_results_regist_status == '2'
                                                    ? null
                                                    :
                                                    <div className="bl_horizBtnUnit">
                                                        <button type="button" className="el_btn" onClick={() => this.register()} tabIndex="10" >登録する</button>
                                                    </div>
                                                }
                                            </div>
                                        </Fragment> : null}
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISAMIRS01'); }, () => { })} tabIndex="11">実習結果一覧へ</button>
                                    </div>
                                </Fragment>
                            </div>
                        </section>
                    </div>
                </div >
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        // 実習結果登録
        trainingResultsIsFetching: state.trainingResults.isFetching,
        trainingResultsData: state.trainingResults.data,
        trainingResultsIsError: state.trainingResults.isError,
        training_results_err_type: state.trainingResults.err_type,
        training_results_err_detail: state.trainingResults.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getTrainingResults: (args) => dispatch(getTrainingResults(args)),
        getTrainingResultsReset: () => dispatch(getTrainingResultsReset()),
        updateTrainingResults: (args) => dispatch(updateTrainingResults(args)),
        updateTrainingResultsReset: () => dispatch(updateTrainingResultsReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISAMIAR02);

