import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMRMR31ActionType';

// 求人案件登録・更新API
const CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_POST = BASE_URL + "/api/rs_mm_update_specific_case_info";

// ********************Actionの作成 Start ********************

/**
 * 登録リクエスト
 */
export const CreateRecruitmentCaseSpecialSkillRequest = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const CreateRecruitmentCaseSpecialSkillSuccess = (data) => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const CreateRecruitmentCaseSpecialSkillFailure = (err) => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const CreateRecruitmentCaseSpecialSkillReset = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET
    };
};

/**
 * 登録リセット(データも合わせリセットする)
 */
export const CreateRecruitmentCaseSpecialSkillResetAllData = () => {
    return {
        type: actionTypes.CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_RESET_ALL_DATA
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const createRecruitmentCaseSpecialSkill = (data) => {

    return async (dispatch) => {
        dispatch(CreateRecruitmentCaseSpecialSkillRequest());
        ecoPostWithAuthHeader(CREATE_RECRUITMENT_CASE_SPECIAL_SKILL_POST, JSON.stringify(data)).then(
            res => dispatch(CreateRecruitmentCaseSpecialSkillSuccess(res.data))
        ).catch(
            err => dispatch(CreateRecruitmentCaseSpecialSkillFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const createRecruitmentCaseSpecialSkillReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(CreateRecruitmentCaseSpecialSkillReset());
    };
};