import React from 'react';
import EfTemplate from "./template";

function EfContainer() {
    return (
        <section className="App">
            <EfTemplate />
        </section>
    );
}

export default EfContainer;
