// メッセージMap
export const MessageMap = {
    "case_title": "求人案件名",
    "applicant": "募集人数",
    "employment_status": "雇用形態",
    "accept_period_start": "雇用開始",
    "accept_period_end": "雇用終了",
    "employment_renewal": "契約更新有無",
    "pub_period_start": "募集希望開始",
    "pub_period_end": "募集希望終了",
    "company_id": "企業",
    "company_name": "企業名",
    "company_name_kana": "企業名（フリガナ）",
    "company_prefectures_id": "本社所在地＿都道府県",
    "company_address": "本社所在地",
    "start_year": "設立日（年）",
    "president_name": "代表者名",
    "president_name_kana": "代表者名（フリガナ）",
    "president_tel_country_name": "代表者電話番号",
    "president_tel_country_code": "代表電話番号＿国・地域名",
    "president_tel_1": "代表電話番号＿市外局番",
    "president_tel_2": "代表電話番号＿市内局番",
    "president_tel_3": "代表電話番号加入者番号",
    "fax_country_country_name": "FAX番号",
    "fax_country_code": "FAX番号＿国・地域名",
    "fax_1": "FAX番号＿市外局番",
    "fax_2": "FAX番号＿市内局番",
    "fax_3": "FAX番号＿加入者番号",
    "employee_regular": "正社員",
    "employee_part_time": "パート/アルバイト",
    "facility_name": "施設名",
    "facility_name_kana": "施設名（フリガナ）",
    "facility_prefectures_id": "施設所在地＿都道府県",
    "facility_address_2": "施設所在地２",
    "home_page": "ホームページ",
    "position": "役職",
    "pic_name": "担当者氏名",
    "pic_name_kana": "担当者氏名（フリガナ）",
    "pic_tel_country_name": "電話番号",
    "pic_tel_country_code": "担当者電話番号＿国・地域名",
    "pic_tel_1": "担当者電話番号＿市外局番",
    "pic_tel_2": "担当者電話番号＿市内局番",
    "pic_tel_3": "担当者電話番号＿加入者番号",
    "mail_address": "メールアドレス",
    "candidate_sex": "性別",
    "country_select_flg": "国籍指定",
    "country": "国籍",
    "occupation": "募集職種",
    "content": "業務内容",
    "work_start": "勤務時間始業",
    "work_end": "勤務時間終業",
    "work_break": "勤務時間休憩",
    "work_hours": "勤務時間実働",
    "holiday": "休日",
    "holiday_number": "休日数",
    "payment_amount": "支給額",
    "payment_hourly": "時給",
    "deduction_dormitory": "寮費",
    "deduction_food": "食費",
    "fee": "名目＿金額",
    "paid_holiday": "有給休暇",
    "others_holiday": "その他休暇",
    "living_place": "寮の有無",
    "transportation": "交通手段",
    "insurance_flg": "保険加入",
    "immigration_service_flg": "入出国手続きサービス",
    "move_service_flg": "渡航移動パック販売サービス",
    "education_service_before_flg": "教育支援サービス（入国前）",
    "education_service_after_flg": "教育支援サービス（入国後研修）",
    "version_no": "バージョン番号",
    "facility_video_url": "動画URL",
    "environment_video_url": "動画URL"
};
