import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GSMBIBIO01ActionType';

//  請求情報一覧照会取得API
const GET_REQUEST_INFO_LIST_POST = BASE_URL + "/api/sm_bi_get_request_info_list";
//  請求情報ファイル作成API
const CREAT_REQUEST_INFO_FILE_POST = BASE_URL + "/api/sm_bi_creat_request_info_file";
//  請求情報ファイルダウンロードAPI
const DOWNLOAD_REQUEST_INFO_FILE_POST = BASE_URL + "/api/sm_bi_download_request_info_file";

// ********************Actionの作成 Start ********************
/**
 *  請求情報一覧照会取得
 */
export const GetRequestInfoListRequest = () => {
    return {
        type: actionTypes.GET_REQUEST_INFO_LIST_REQUEST
    };
};

/**
 *  請求情報一覧照会取得成功
 */
export const GetRequestInfoListSuccess = (data) => {
    return {
        type: actionTypes.GET_REQUEST_INFO_LIST_SUCCESS,
        data
    };
};

/**
 *  請求情報一覧照会取得失敗
 */
export const GetRequestInfoListFailure = (err) => {
    return {
        type: actionTypes.GET_REQUEST_INFO_LIST_FAILURE,
        err
    };
};

/**
 *  請求情報一覧照会取得リセット
 */
export const GetRequestInfoListReset = () => {
    return {
        type: actionTypes.GET_REQUEST_INFO_LIST_RESET
    };
};

/**
 * 請求情報ファイル作成
 */
export const CreatRequestInfoFileRequest = () => {
    return {
        type: actionTypes.CREAT_REQUEST_INFO_FILE_REQUEST
    };
};

/**
 * 請求情報ファイル作成成功
 */
export const CreatRequestInfoFileSuccess = (data) => {
    return {
        type: actionTypes.CREAT_REQUEST_INFO_FILE_SUCCESS,
        data
    };
};

/**
 * 請求情報ファイル作成失敗
 */
export const CreatRequestInfoFileFailure = (err) => {
    return {
        type: actionTypes.CREAT_REQUEST_INFO_FILE_FAILURE,
        err
    };
};

/**
 * 請求情報ファイル作成リセット
 */
export const CreatRequestInfoFileReset = () => {
    return {
        type: actionTypes.CREAT_REQUEST_INFO_FILE_RESET
    };
};

/**
 * 請求情報ファイルダウンロード
 */
export const DownloadRequestInfoFileRequest = () => {
    return {
        type: actionTypes.DOWNLOAD_REQUEST_INFO_FILE_REQUEST
    };
};

/**
 * 請求情報ファイルダウンロード成功
 */
export const DownloadRequestInfoFileSuccess = (data) => {
    return {
        type: actionTypes.DOWNLOAD_REQUEST_INFO_FILE_SUCCESS,
        data
    };
};

/**
 * 請求情報ファイルダウンロード失敗
 */
export const DownloadRequestInfoFileFailure = (err) => {
    return {
        type: actionTypes.DOWNLOAD_REQUEST_INFO_FILE_FAILURE,
        err
    };
};

/**
 * 請求情報ファイルダウンロードリセット
 */
export const DownloadRequestInfoFileReset = () => {
    return {
        type: actionTypes.DOWNLOAD_REQUEST_INFO_FILE_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 請求情報一覧照会取得Actionの発行
 */
export const getRequestInfoList = (data) => {
    return async (dispatch) => {
        // Storeへ請求情報一覧照会取得リクエストActionをdispatchする
        dispatch(GetRequestInfoListRequest());
        ecoPostWithAuthHeader(GET_REQUEST_INFO_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ請求情報一覧照会取得成功Actionをdispatchする
                dispatch(GetRequestInfoListSuccess(res.data))
            ).catch(err =>
                // Storeへ請求情報一覧照会取得失敗Actionをdispatchする
                dispatch(GetRequestInfoListFailure(err))
            );
    };
};

/**
 * 請求情報ファイル作成Actionの発行
 */
export const creatRequestInfoFile = (data) => {
    return async (dispatch) => {
        // Storeへ請求情報ファイル作成リクエストActionをdispatchする
        dispatch(CreatRequestInfoFileRequest());
        ecoPostWithAuthHeader(CREAT_REQUEST_INFO_FILE_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ請求情報ファイル作成成功Actionをdispatchする
                dispatch(CreatRequestInfoFileSuccess(res.data))
            ).catch(err =>
                // Storeへ請求情報ファイル作成失敗Actionをdispatchする
                dispatch(CreatRequestInfoFileFailure(err))
            );
    };
};

/**
 * 請求情報ファイルダウンロードActionの発行
 */
export const downloadRequestInfoFile = (data) => {
    return async (dispatch) => {
        // Storeへ請求情報ファイルダウンロードリクエストActionをdispatchする
        dispatch(DownloadRequestInfoFileRequest());
        ecoPostDownloadExcelFile(DOWNLOAD_REQUEST_INFO_FILE_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ請求情報ファイルダウンロード成功Actionをdispatchする
                dispatch(DownloadRequestInfoFileSuccess(res.data))
            ).catch(err =>
                // Storeへ請求情報ファイルダウンロード失敗Actionをdispatchする
                dispatch(DownloadRequestInfoFileFailure(err))
            );
    };
};