/**
 * Action Typesを定義
 */
const GIPPMPLI01ActionType = {
    GET_TRAINING_ENQUETE_ANSWER_INFO_REQUEST: 'GET_TRAINING_ENQUETE_ANSWER_INFO_REQUEST',  // アンケート初期取得登録（実習生用）照会定義
    GET_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS: 'GET_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS',  // アンケート初期取得登録（実習生用）照会成功定義
    GET_TRAINING_ENQUETE_ANSWER_INFO_FAILURE: 'GET_TRAINING_ENQUETE_ANSWER_INFO_FAILURE',  // アンケート初期取得登録（実習生用）照会失敗定義
    GET_TRAINING_ENQUETE_ANSWER_INFO_RESET: 'GET_TRAINING_ENQUETE_ANSWER_INFO_RESET',      // アンケート初期取得登録（実習生用）照会リセット定義
    UPDATE_TRAINING_ENQUETE_ANSWER_INFO_REQUEST: 'UPDATE_TRAINING_ENQUETE_ANSWER_INFO_REQUEST',  //  アンケート登録（実習生用）定義
    UPDATE_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS: 'UPDATE_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS',  //  アンケート登録（実習生用）成功定義
    UPDATE_TRAINING_ENQUETE_ANSWER_INFO_FAILURE: 'UPDATE_TRAINING_ENQUETE_ANSWER_INFO_FAILURE',  //  アンケート登録（実習生用）失敗定義
    UPDATE_TRAINING_ENQUETE_ANSWER_INFO_RESET: 'UPDATE_TRAINING_ENQUETE_ANSWER_INFO_RESET',      //  アンケート登録（実習生用）リセット定義
};

export default GIPPMPLI01ActionType;
