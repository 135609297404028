import React, { Fragment } from "react";

import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { GetSendingOrganizationInfoReset } from "../OmGCMOMCRU03/GCMOMCRU03Action";
import { isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 応募者詳細画面
 */
class GCMOMCRU06 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseDetail: this.props.caseDetail,
            candidateList: this.props.candidateList
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        const { sendingOrganizationData } = this.props;
        this.setState({
            sending_organization_name: sendingOrganizationData.sending_organization_name,       // 送り出し機関名
            sending_organization_location: sendingOrganizationData.sending_organization_location,       // 所在地
            country_name: sendingOrganizationData.country_name,       // 国
            pic_name: sendingOrganizationData.pic_name,       // 担当者名
            pic_mail_address: sendingOrganizationData.pic_mail_address,       // 担当者メールアドレス
            pic_tel_country_code: sendingOrganizationData.pic_tel_country_number,       // 担当者電話(国コード)
            pic_tel1: sendingOrganizationData.pic_tel1,       // 担当者電話番号１
            pic_tel2: sendingOrganizationData.pic_tel2,       // 担当者電話番号２
            pic_tel3: sendingOrganizationData.pic_tel3       // 担当者電話番号３
        });
    }

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            if (changeTo === 'GRSMMMLI01') {
                this.props.getCaseDetailReset();
                this.props.getEntryListReset();
            }
            this.props.resetSendingOrganizationState();
        }
        return this.props.switchDisplaySelectedOrg(changeTo, this.state.caseDetail, this.state.candidateList);
    }

    render() {
        return (
            <article>
                <div class="ly_cont">
                    <div class="bl_mainHeader">
                        <div class="bl_mainHeader_inner">
                            <h1 class="el_lv1Heading">
                                組織情報(プロフィール)
                            </h1>
                        </div>
                    </div>
                    <div class="ly_mainCont">
                        <nav class="bl_breadcrumb">
                            <ol class="bl_breadcrumb_list">
                                <li>
                                    <a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>案件詳細</a></li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section class="bl_sect">
                            <div class="ly_mainCont_inner">
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">登録プロフィール情報
                                    </h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>送り出し機関名</th>
                                                    <td>{this.state.sending_organization_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>所在地</th>
                                                    <td>{this.state.sending_organization_location}</td>
                                                </tr>
                                                <tr>
                                                    <th>国</th>
                                                    <td>{this.state.country_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者名</th>
                                                    <td>{this.state.pic_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者メールアドレス</th>
                                                    <td>{this.state.pic_mail_address}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者電話番号</th>
                                                    <td>{(isBlank(this.state.pic_tel_country_code) && isBlank(this.state.pic_tel1) && isBlank(this.state.pic_tel2) && isBlank(this.state.pic_tel3)) ? '' : this.state.pic_tel_country_code + ' ' + this.state.pic_tel1 + '-' + this.state.pic_tel2 + '-' + this.state.pic_tel3}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div class="bl_horizBtnUnit__move">
                                        <button type="button" class="el_blueBtn" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>エントリー情報へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article >
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        resetSendingOrganizationState: () => dispatch(GetSendingOrganizationInfoReset()),
    };
};

export default connect(null, mapDispatchToProps)(GCMOMCRU06);

