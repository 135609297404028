import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GTMWTWTA01ActionType';

// 作業時間承認/差戻API
const WORKINGTIME_APPROVAL_POST = BASE_URL + "/api/tm_wt_update_working_time_admission_info";

// ********************Actionの作成 Start ********************

/**
 * 承認/差戻リクエスト
 */
export const WorkingtimeApprovalRequest = () => {
    return {
        type: actionTypes.WORKINGTIME_APPROVAL_REQUEST
    };
};

/**
 * 承認/差戻成功
 * @param {*} data
 */
export const WorkingtimeApprovalSuccess = (data) => {
    return {
        type: actionTypes.WORKINGTIME_APPROVAL_SUCCESS,
        data
    };
};

/**
 * 承認/差戻失敗
 * @param {*} err
 */
export const WorkingtimeApprovalFailure = (err) => {
    return {
        type: actionTypes.WORKINGTIME_APPROVAL_FAILURE,
        err
    };
};

/**
 * 承認/差戻リセット
 */
export const WorkingtimeApprovalReset = () => {
    return {
        type: actionTypes.WORKINGTIME_APPROVAL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 承認/差戻処理
 * @param {*} data
 */
export const workingtimeApproval = (data) => {
    return async (dispatch) => {
        dispatch(WorkingtimeApprovalRequest());
        ecoPostWithAuthHeader(WORKINGTIME_APPROVAL_POST, JSON.stringify(data)).then(
            res => dispatch(WorkingtimeApprovalSuccess(res.data))
        ).catch(
            err => dispatch(WorkingtimeApprovalFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const workingtimeApprovalReset = () => {
    return async (dispatch) => {
        // Storeへ承認/差戻リセットActionをdispatchする
        dispatch(WorkingtimeApprovalReset());
    };
};