import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { saveAs } from "file-saver";
import moment from "moment";
import { connect } from "react-redux";
import { getCognitoUserInfo } from "../../Constants/CommonConstants";
import { getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { MessageMap } from "./GISAMIRS01Message";
import { getTrainingResultsList, trainingResultsListReset, trainingResultsDownloadFile, trainingResultsDownloadFileReset } from "./GISAMIRS01Action";
import { checkArray } from "../../Components/Tools/StringTools";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 実習結果一覧
 */
class GISAMIRS01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            employment_name_kana: '',
            case_title: '',
            company_name: '',
            accept_start: '',
            accept_end: '',
            search_case_id: this.props.caseId,
            search_company_id: this.props.companyId,
            search_candidate_id: this.props.candidateId,
            training_results_regist_status: '',
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
        // 初期検索
        this.handlingSearch();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.trainingResultsListReset();
    }

    /**
     * 検索処理
     */
    handlingSearch = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset());

        let from_todo_flg = null
        if (this.state.search_case_id != null) {
            from_todo_flg = '1'
        }
        // パラメータの設定
        let searchParameter = {
            case_id: this.state.search_case_id,
            company_id: this.state.search_company_id,
            candidate_id: this.state.search_candidate_id,
            employment_name_kana: this.state.employment_name_kana,
            case_title: this.state.case_title,
            company_name: this.state.company_name,
            accept_start: this.state.accept_start,
            accept_end: this.state.accept_end,
            training_results_regist_status: this.state.training_results_regist_status,
            from_todo_flg: from_todo_flg
        };

        this.setState({
            condition: searchParameter,
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getTrainingResultsList(this.state.condition);
        });
    }

    /**
     * ダウンロードファイル
     */
    HandleDownloadFile = (item) => event => {
        event.preventDefault();
        event.stopPropagation();

        this.setState({ downloadFileName: item.training_result_report_filename }, () => {
            this.props.downloadFile(item);
        })

    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
    * 画面切替処理
    * @param {*} item
    */
    handleToDisplay = (item) => {
        window.scrollTo(0, 0);
        // パラメータの設定
        this.setState({
            err_msg: []
        }, () => {
            // ログインユーザの組織タイプ = "INT"の場合
            if (getCognitoUserInfo()["org_type"] === 'INT') {
                // 実習結果登録画面へ遷移する
                this.props.redirectToView('GISAMIAR02', item);
            } else if (getCognitoUserInfo()["org_type"] === 'COP') {
                // 実習結果評価画面へ遷移する
                this.props.redirectToView('GISAMIAR01', item);
            }
        });
    }

    render() {

        // 実習結果登録
        const trainingResultsRegistOpt = getCodeValueLabelList('RSC0034');

        // 件数が100件を超える場合
        if (this.props.data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        // ファイルダウンロード処理
        if (this.props.downloadData.data && this.props.downloadData.err_type === '200') {
            const blob = new Blob([this.props.downloadData.data], {
                type: this.props.downloadData.data.type
            });
            // ファイルの場合
            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, this.state.downloadFileName);
            } else {
                saveAs(blob, this.state.downloadFileName);
            }
            this.props.trainingResultsDownloadFileReset();
        }

        if (this.state.search_case_id != null && this.props.err_type == '200') {
            if (checkArray(this.props.data.list)) {
                this.setState({
                    employment_name_kana: this.props.data.list[0].employment_name_kana,
                    case_title: this.props.data.list[0].case_title,
                    company_name: this.props.data.list[0].company_name,
                    search_case_id: null
                });
            } else {
                this.setState({
                    search_case_id: null
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">実習結果一覧</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>実習結果一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.downloadData.err_type} err_detail={this.props.downloadData.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_inner">
                                        <div className="bl_searchBtn">
                                            <button className={this.state.searchbtnclass} onClick={this.toggleClassAction} tabIndex="1">検索条件</button>
                                        </div>
                                        <div className={this.state.toggleclass}>
                                            <table className="bl_horizTable">
                                                <tbody>
                                                    <tr>
                                                        <th>氏名</th>
                                                        <td>
                                                            <TextInput type="text" name="employment_name_kana" className="el_input el_input__max" maxlength="60" setValue={this.state.employment_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>案件名</th>
                                                        <td>
                                                            <TextInput type="text" name="case_title" className="el_input el_input__max" maxlength="40" setValue={this.state.case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>企業名</th>
                                                        <td>
                                                            <TextInput type="text" name="company_name" className="el_input el_input__max" maxlength="60" setValue={this.state.company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>実習期間</th>
                                                        <td>
                                                            <TextInput type="date" name="accept_start" className="el_input el_input__datepicker" setValue={this.state.accept_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex="5" />
                                                            <span className="hp_ml10 hp_mr10">〜</span>
                                                            <TextInput type="date" name="accept_end" className="el_input el_input__datepicker" setValue={this.state.accept_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex="6" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>実習結果登録</th>
                                                        <td>
                                                            <select className="el_select el_select__w80per" name="training_results_regist_status" onChange={this.handleChange} tabIndex="7">
                                                                <option value="" selected ></option>
                                                                {
                                                                    trainingResultsRegistOpt && trainingResultsRegistOpt.map(item => (
                                                                        <option key={item.value} value={item.value} selected={this.state.training_results_regist_status == item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="bl_horizBtnUnit">
                                                <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSearch()} tabIndex="8">検索</button>
                                            </div>
                                        </div>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>実習結果報告書</th>
                                                        <th>氏名</th>
                                                        <th>案件名</th>
                                                        <th>企業名</th>
                                                        <th>実習期間</th>
                                                        <th>実習結果登録</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data.list)) {
                                                            return (
                                                                this.props.data.list.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                <button tabIndex={8 + index * 2 + 2} type="button" className={item.training_results_regist_status === '2' ? "el_smBtn el_shadowBtn" : "el_smBtn is_disabled"} onClick={this.HandleDownloadFile(item)}>ダウンロード</button>
                                                                            </div>
                                                                        </td>
                                                                        {(() => {
                                                                            if (getCognitoUserInfo()["org_type"] === 'INT') {
                                                                                return <td><a tabIndex={8 + index * 2 + 1} href="#" className="el_link" onClick={() => this.handleToDisplay(item)}>{item.employment_name_kana}</a></td>;
                                                                            } else if (getCognitoUserInfo()["org_type"] === 'COP') {
                                                                                if (item.training_results_regist_status === '0') {
                                                                                    return <td>{item.employment_name_kana}</td>;
                                                                                } else {
                                                                                    return <td><a tabIndex={8 + index * 2 + 1} href="#" className="el_link" onClick={() => this.handleToDisplay(item)}>{item.employment_name_kana}</a></td>;
                                                                                }
                                                                            } else {
                                                                                return <td>{item.employment_name_kana}</td>;
                                                                            }
                                                                        })()}
                                                                        <td>{item.case_title}</td>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{moment(item.accept_start).format('YYYY年MM月DD日')}〜{moment(item.accept_end).format('YYYY年MM月DD日')}</td>
                                                                        <td>{item.training_results_regist_status === '0' ? '未登録' : (item.training_results_regist_status === '1' ? '実習結果登録済' : '評価登録済')}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getTrainingResultsList.isFetching,
        data: state.getTrainingResultsList.data,
        isError: state.getTrainingResultsList.isError,
        err_type: state.getTrainingResultsList.err_type,
        err_detail: state.getTrainingResultsList.err_detail,
        downloadData: state.trainingResultsDownloadFile
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getTrainingResultsList: (arg) => dispatch(getTrainingResultsList(arg)),
        trainingResultsListReset: () => dispatch(trainingResultsListReset()),
        downloadFile: (arg) => dispatch(trainingResultsDownloadFile(arg)),
        trainingResultsDownloadFileReset: () => dispatch(trainingResultsDownloadFileReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISAMIRS01);

