import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCognitoUserInfo, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import { saveUserManagement, saveUserManagementReset } from "./GCMOMORU02Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { MessageMap } from "./GCMOMORU02Message";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * ユーザ情報登録・訂正
 */
class GCMOMORU02 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            orgType: getCognitoUserInfo()["org_type"],
            organizationNameOpt: [],
            user_id: isBlank(this.props.detailData.user_id) ? '' : this.props.detailData.user_id,
            user_version_no: isBlank(this.props.detailData.user_id) ? '' : this.props.detailData.user_version_no
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { detailData } = this.props;
        const { orgType } = this.state;

        // 在留資格の設定
        let noIntern = getCodeValueLabelList('RSC0004').filter(el => el.label !== 'インターン');
        let intern = getCodeValueLabelList('RSC0004').filter(el => el.label === 'インターン');

        if (orgType === 'UNI') {
            this.setState({
                statusOpt: intern
            });
        } else if (orgType === 'OKR') {
            this.setState({
                statusOpt: noIntern
            });
        } else if (orgType === 'COD') {
            this.setState({
                statusOpt: getCodeValueLabelList('RSC0004')
            });
        }

        // 訂正の場合
        if (!isBlank(detailData.user_id)) {
            let interObj = getCodeValueLabelList('RSC0004').find(data => data.label === 'インターン');
            let tokObj = getCodeValueLabelList('RSC0004').find(data => data.label === '特定技能');
            let gnoObj = getCodeValueLabelList('RSC0004').find(data => data.label === '技能実習');

            // 選択した在留資格
            if (detailData.organization_type === 'INT') {
                this.setState({
                    status_of_residence: interObj.value
                });
            } else if (detailData.organization_type === 'GNO') {
                this.setState({
                    status_of_residence: gnoObj.value
                });
            } else if (detailData.organization_type === 'TOK') {
                this.setState({
                    status_of_residence: tokObj.value
                });
            } else {
                this.setState({
                    status_of_residence: ''
                });
            }

            // 組織名
            this.setState({
                organization_id: detailData.organization_id,
                organization_name: detailData.organization_name,
                organization_type: detailData.organization_type
            });
        } else {
            // 在留資格
            this.setState({
                status_of_residence: detailData.status_of_residence
            });
        }

        let userKind = '';
        if (['UNI', 'OKR', 'COD'].includes(orgType)) {
            userKind = isBlank(detailData.user_id) ? (['INT', 'GNO', 'TOK'].includes(detailData.organization_type) ? '0' : '') : (['INT', 'GNO', 'TOK'].includes(detailData.organization_type) ? '1' : '0');
        } else {
            userKind = '0';
        }

        this.setState({
            user_name: detailData.user_name,
            user_kind: userKind,
            user_mail_address: detailData.user_mail_address,
        }, () => {
            if (isBlank(detailData.user_id)) {
                this.setState({
                    organizationNameOpt: detailData.staff
                });
                if (detailData.staff.length == 1) {
                    this.setState({
                        organization_id: detailData.staff[0].organization_id,
                        organization_name: detailData.staff[0].organization_name,
                        organization_type: detailData.staff[0].organization_type
                    });
                }
            }
        });
        window.scrollTo(0, 0);
    }

    /**
     * 登録者種別の変更処理 (0: 所員, 1: 応募者)
     */
    handleResidenceStatusChange = (event) => {
        const { name, value } = event.target;
        const { detailData } = this.props;

        this.setState({
            [name]: value
        }, () => {
            // 所員
            if (value === '0') {
                if (checkArray(detailData.staff) && detailData.staff.length > 1) {
                    this.setState({
                        organizationNameOpt: detailData.staff
                    });
                } else {
                    this.setState({
                        organizationNameOpt: detailData.staff,
                        organization_id: detailData.staff[0].organization_id,
                        organization_name: detailData.staff[0].organization_name,
                        organization_type: detailData.staff[0].organization_type
                    });
                }
            } else {
                if (checkArray(detailData.candidate) && detailData.candidate.length > 1) {
                    this.setState({
                        organizationNameOpt: detailData.candidate
                    });
                } else {
                    this.setState({
                        organizationNameOpt: detailData.candidate,
                        organization_id: detailData.candidate[0].organization_id,
                        organization_name: detailData.candidate[0].organization_name,
                        organization_type: detailData.candidate[0].organization_type
                    });
                }
            }
            this.editItemHandler(name, value);
        });
    };

    /**
     * ドロップダウンリスト変更処理
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 組織名変更処理
     */
    handleOrganizationNameChange = (event) => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        }, () => {
            this.state.organizationNameOpt.map(data => {
                if (data.organization_id === this.state.organization_id) {
                    // 組織名
                    this.setState({
                        organization_id: data.organization_id,
                        organization_name: data.organization_name,
                        organization_type: data.organization_type
                    });
                }
            });
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.saveUserManagementReset();
    }

    /**
     * 登録
     */
    saveUserManagementInfo = () => {
        window.scrollTo(0, 0);

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        this.setState({
            user_id: this.state.user_id,
            status_of_residence: this.state.status_of_residence,
            user_version_no: this.state.user_version_no
        }, () => {
            this.props.saveUserManagement(this.state);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.saveUserManagementReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    render() {
        const { orgType, statusOpt, organizationNameOpt, user_kind } = this.state;
        const { detailData } = this.props;

        // 表示、非表示項目
        const isShow = ['UNI', 'OKR', 'COD'].includes(orgType) ? 'table-row' : 'none';

        // 活性、非活性項目
        const isDisabled = isBlank(detailData.user_id) ? false : true;
        const cssDisabled = ((user_kind === '0' || isBlank(user_kind)) && isBlank(detailData.user_id)) ? ' is_disabled' : '';
        const dropDownDisabled = isBlank(detailData.user_id) ? '' : ' is_disabled';
        const selectIsDisabled = ((user_kind === '0' || isBlank(user_kind)) && isBlank(detailData.user_id)) ? true : false;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.user_version_no) && this.state.user_version_no !== this.props.data.user_version_no) {
                this.editItemHandler('user_version_no', this.props.data.user_version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.setState({
                        user_id: this.props.data.user_id
                    }, () => {
                        this.editItemHandler('user_id', this.state.user_id);
                        this.props.switchDisplayUser("GCMOMORU02", this.state);
                    });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">ユーザ情報登録・訂正​</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GCMOMORU01", true); }, () => { })}>ユーザ管理</a></li>
                                <li>ユーザ情報登録・訂正​​</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">ユーザ登録</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        ユーザ名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="user_name" className="el_input el_input__max" maxlength="60" setValue={this.state.user_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        登録者種別
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb10">
                                                            <input type="radio" name="user_kind" value="0" checked={user_kind === '0'} onChange={this.handleResidenceStatusChange} disabled={isDisabled} tabIndex="2" />
                                                            <span className="hp_mr20">所員</span>
                                                        </div>
                                                        <div className="bl_formUnit">
                                                            <input type="radio" name="user_kind" value="1" checked={user_kind === '1'} onChange={this.handleResidenceStatusChange} disabled={isDisabled} tabIndex="3" />
                                                            <span className="hp_mr20">応募者</span>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        在留資格
                                                    </th>
                                                    <td>
                                                        {(() => {
                                                            if (isBlank(detailData.user_id)) {
                                                                return (
                                                                    <select className={"el_select".concat(cssDisabled)} name="status_of_residence" value={this.state.status_of_residence} onChange={this.handleChange} disabled={selectIsDisabled} tabIndex="4">
                                                                        <option value="" selected></option>
                                                                        {
                                                                            statusOpt && statusOpt.map(item => (
                                                                                <option key={item.value} value={item.value} selected={this.state.status_of_residence === item.value}>
                                                                                    {item.label}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                );
                                                            } else {
                                                                return (
                                                                    <select className={"el_select".concat(dropDownDisabled)} name="status_of_residence" value={this.state.status_of_residence} disabled={isDisabled} tabIndex="4">
                                                                        <option value="" selected></option>
                                                                        {
                                                                            statusOpt && statusOpt.map(item => (
                                                                                <option key={item.value} value={item.value} selected={this.state.status_of_residence === item.value}>
                                                                                    {item.label}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        組織名
                                                    </th>
                                                    <td>
                                                        {(() => {
                                                            if (isBlank(detailData.user_id)) {
                                                                if (checkArray(organizationNameOpt) && organizationNameOpt.length > 1) {
                                                                    return (
                                                                        <select className="el_select" name="organization_id" value={this.state.organization_id} onChange={this.handleOrganizationNameChange} tabIndex="5">
                                                                            <option value="" selected></option>
                                                                            {
                                                                                organizationNameOpt.map((item) => (
                                                                                    <option key={item.organization_id} value={item.organization_id} selected={this.state.organization_id === item.organization_id}>
                                                                                        {item.organization_name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <select className="el_select is_disabled" name="organization_id" value={this.state.organization_id} disabled={true} tabIndex="5">
                                                                            {
                                                                                organizationNameOpt.length > 0 && organizationNameOpt.map((item) => (
                                                                                    <option key={item.organization_id} value={item.organization_id} selected={this.state.organization_id === item.organization_id}>
                                                                                        {item.organization_name}
                                                                                    </option>
                                                                                ))
                                                                            }
                                                                        </select>
                                                                    );
                                                                }
                                                            } else {
                                                                return (
                                                                    <select className={"el_select".concat(dropDownDisabled)} name="organization_id" disabled={isDisabled} tabIndex="5">
                                                                        <option value={this.state.organization_id} selected>{this.state.organization_name}</option>
                                                                    </select>
                                                                );
                                                            }
                                                        })()}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        メールアドレス
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="user_mail_address" className="el_input el_input__max" maxlength="81"
                                                                setValue={this.state.user_mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_backBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GCMOMORU01", true); }, () => { })} tabIndex="7">ユーザ管理へ​</button>
                                        <button type="button" className="el_btn" onClick={() => this.saveUserManagementInfo()} tabIndex="8">登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.saveUserManagement.isFetching,
        data: state.saveUserManagement.data,
        isError: state.saveUserManagement.isError,
        err_type: state.saveUserManagement.err_type,
        err_detail: state.saveUserManagement.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveUserManagement(args) {
            // ユーザ情報​更新
            dispatch(saveUserManagement(args));
        },
        saveUserManagementReset() {
            dispatch(saveUserManagementReset());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMORU02);

