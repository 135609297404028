import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getMessage, getCodeValueLabelList } from "../../Constants/CommonConstants";
import { checkArray } from "../../Components/Tools/StringTools";
import { MessageMap } from "./GRSMSMSA01Message";
import { getCandidateList } from "../MmGRSMMAMF01/GRSMMAMF01Action";
import { getEntryList, GetCaseCandidateListReset, getCaseCandidateList } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import { CaseEntryListActionReset } from "../MmGRSMMIMD02/GRSMMIMD02Action";
import { createInterviewHistory, createInterviewHistoryReset, getInterviewHistory, getInterviewHistoryReset } from "../MsGRSMSMSA01/GRSMSMSA01Action";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import "../../Css/Common.css";

/**
 * web面接日登録
 */
class GRSMSMSA01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            interview_title: "",
            interview_dest: [],
            interview_others_dest: "",
            interview_date: "",
            interview_start_time: "",
            interview_time_minutes: "",
            web_interview_history_log: this.props.interviewData.web_interview_history_log
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.props.saveWebInterviewDateReset();
    }

    /**
     * 宛先の処理
     * @param {*} event
     */
    handleChange = (event) => {
        event.persist();
        const { value, checked } = event.target;
        const { interviewData } = this.props;

        if (checked) {
            const addressObj = interviewData.list.find(el => el.user_id === value);
            this.setState(prevState => ({
                interview_dest: prevState.interview_dest.concat({
                    "user_id": addressObj.user_id,
                    "user_mail_address": addressObj.user_mail_address,
                    "organization_name": addressObj.organization_name,
                    "user_name": addressObj.user_name
                })
            }), () => {
                this.editItemHandler('interview_dest', this.state.interview_dest);
            });
        } else {
            this.setState(prevState => ({
                interview_dest: prevState.interview_dest.filter(i => i.user_id !== value)
            }), () => {
                this.editItemHandler('interview_dest', this.state.interview_dest);
            });
        }
    };

    static getDerivedStateFromProps(nextProps, prevState) {

        if (!nextProps.isError && nextProps.err_type === '200') {
            return {
                interview_title: "",
                interview_dest: [],
                interview_others_dest: "",
                interview_date: "",
                interview_start_time: "",
                interview_time_minutes: ""
            };
        }
        // Return null if the state hasn't changed
        return null;
    }

    /**
     * web面接時間変更時
     * @param {*} event
     */
    handleWebInterviewChange = (event) => {
        event.persist();
        const { name, value } = event.target;
        this.setState({ [name]: value });

    };

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.saveWebInterviewDateReset();
        this.props.getInterviewHistoryReset();
        this.props.getEntryListReset();
    }

    /**
     * 登録
     */
    saveWebInterviewDateInfo = () => {
        window.scrollTo(0, 0);
        const { case_id, organization_id, organization_type } = this.props.interviewData;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        this.setState({
            case_id: case_id,
            organization_id: organization_id,
            organization_type: organization_type
        }, () => {
            this.props.saveWebInterviewDate(this.state);
        });
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseCandidateListReset();
            this.saveReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * エントリー画面へ切替
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToEntry = async () => {
        window.scrollTo(0, 0);
        this.saveReset();
        this.setErrorCallback(this.getReset);
        this.props.CaseEntryListActionReset();
        let candidatecondition = {};
        candidatecondition.case_id = this.props.interviewData.case_id;
        candidatecondition.call_type = "2";
        await this.props.getCaseCandidateList(candidatecondition);

        await this.props.getEntryList({
            "case_id": this.props.interviewData.case_id,
            "call_type": "1"
        });

        this.props.switchDisplayEntry("GRSMMIMD02", this.props.caseDetail, this.props.candidate_data, this.props.entryList);

    }

    render() {
        const { interviewData } = this.props;
        const tabindex = interviewData.list.length + 1;

        // web面接時間
        const interviewEndTime = getCodeValueLabelList('RSC0032');

        if (!this.props.isFetching) {
            // web面接登録成功
            if (!this.props.isError && this.props.err_type === '200') {
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.props.saveWebInterviewDateReset();
                    this.props.getInterviewHistory(this.state.case_id, this.state.organization_id, this.state.organization_type);
                    this.props.getEntryList({
                        "case_id": this.props.interviewData.case_id,
                        "call_type": "1"
                    });
                });
            }
        }
        if (!this.props.interview_isFetching) {
            // web面接履歴取得成功
            if (!this.props.interview_isError && this.props.interview_err_type === '200') {
                this.setState({ web_interview_history_log: this.props.interview_data.web_interview_history_log }, () => {
                    this.props.getInterviewHistoryReset();
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">Web面接日登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToEntry(); }, () => { })}>案件詳細</a></li>
                                <li>Web面接日登録​​​​</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">Web面接日登録</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />Web面接タイトル
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="interview_title" className="el_input el_input__max" maxlength="60" setValue={this.state.interview_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />宛先
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit bl_formUnit__flex">
                                                            {
                                                                checkArray(interviewData.list) && interviewData.list.map((item, index) => {
                                                                    return (
                                                                        <label className="el_label" key={item.user_id}>
                                                                            <input type="checkbox" className="el_checkbox" name={item.organization_name} value={item.user_id}
                                                                                checked={this.state.interview_dest.find((el) => el.user_id === item.user_id)} onChange={this.handleChange} tabIndex={2 + index} />
                                                                            <span className="el_checkbox_txt">{item.organization_name + '　' + item.user_name}</span>
                                                                        </label>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>宛先追加</th>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb10">
                                                            <Textarea name="interview_others_dest" rows="4" maxlength="810" className="el_textarea" required="required"
                                                                setValue={this.state.interview_others_dest} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex={tabindex + 1} />
                                                        </div>
                                                        <p className="el_note_sm">※宛先を複数追加する場合は半角カンマで区切ってください。</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />日付</th>
                                                    <td>
                                                        <TextInput type="date" name="interview_date" className="el_input el_input__datepicker" maxlength="10" required="required"
                                                            setValue={this.state.interview_date} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex={tabindex + 2} />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />時間(日本時間)</th>
                                                    <td>
                                                        <TextInput type="time" name="interview_start_time" className="el_input el_input__sm" maxlength="5" required="required"
                                                            setValue={this.state.interview_start_time} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="--:--" tabIndex={tabindex + 3} />&nbsp;〜&nbsp;
                                                        <select className="el_select el_select__auto" name="interview_time_minutes" onChange={this.handleWebInterviewChange} tabIndex={tabindex + 4}>
                                                            <option value="" selected></option>
                                                            {
                                                                checkArray(interviewEndTime) && interviewEndTime.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.interview_time_minutes === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_btn" onClick={() => this.saveWebInterviewDateInfo()} tabIndex={tabindex + 5} >登録する</button>
                                        </div>
                                    </section>
                                </div>
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">WebExログイン情報
                                    </h2>
                                </div>

                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame">
                                        <table class="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        ログインID</th>
                                                    <td>
                                                        {interviewData.webex_id}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        パスワード</th>
                                                    <td>
                                                        {interviewData.webex_password}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">Web面接日登録ログ</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <table className="bl_horizTable02 hp_mb10">
                                            <tbody>
                                                {
                                                    checkArray(this.state.web_interview_history_log) && this.state.web_interview_history_log.map((item) => {
                                                        return (
                                                            <tr key={item.interview_id}>
                                                                <td>
                                                                    Web面接日時：{item.interview_date} {item.interview_start_time}〜{item.interview_time_minutes}<br />
                                                                    Web面接タイトル：{item.interview_title}<br />
                                                                    Web面接登録日：{item.interview_entry_date}<br />
                                                                    宛先 : {item.user_name}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit__move">
                                    <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToEntry(); }, () => { })} tabIndex={tabindex + 6} >エントリー情報へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createInterviewHistory.isFetching,
        data: state.createInterviewHistory.data,
        isError: state.createInterviewHistory.isError,
        err_type: state.createInterviewHistory.err_type,
        err_detail: state.createInterviewHistory.err_detail,
        interview_isFetching: state.getInterviewHistory.isFetching,
        interview_data: state.getInterviewHistory.data,
        interview_isError: state.getInterviewHistory.isError,
        interview_err_type: state.getInterviewHistory.err_type,
        interview_err_detail: state.getInterviewHistory.err_detail,
        isEntryFetching: state.getEntryList.isFetching,
        entryList: state.getEntryList.overViewData,
        isEntryError: state.getEntryList.isError,
        err_entry_type: state.getEntryList.err_type,
        err_entry_detail: state.getEntryList.err_detail,
        isCandidateFetching: state.getCandidateList.isFetching,
        candidate_data: state.getCandidateList.overViewData,
        isCandidate_Error: state.getCandidateList.isError,
        err_candidate_type: state.getCandidateList.err_type,
        err_candidate_detail: state.getCandidateList.err_detail,
        isCaseCandidateFetching: state.getCandidateList.isFetching,
        casecandidate_data: state.getCaseCandidateList.overViewData,
        isCaseCandidate_Error: state.getCaseCandidateList.isError,
        err_casecandidate_type: state.getCaseCandidateList.err_type,
        err_casecandidate_detail: state.getCaseCandidateList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveWebInterviewDate(args) {
            // web面接日登録
            dispatch(createInterviewHistory(args));
        },
        saveWebInterviewDateReset() {
            dispatch(createInterviewHistoryReset());
        },
        getInterviewHistory(caseId, organizationId, organizationType) {
            // web面接日詳細取得
            dispatch(getInterviewHistory({
                'case_id': caseId,
                'organization_id': organizationId,
                'organization_type': organizationType,
            }));
        },
        getInterviewHistoryReset() {
            dispatch(getInterviewHistoryReset());
        },
        getCandidateList(condition) {
            // 応募者一覧情報取得
            dispatch(getCandidateList(condition));
        },
        getEntryList(condition) {
            // エントリリスト取得
            dispatch(getEntryList(condition));
        }, getCaseCandidateListReset() {
            // 応募者一覧情報stateリセット
            dispatch(GetCaseCandidateListReset());
        }, getCaseCandidateList(condition) {
            // 応募者一覧情報取得
            dispatch(getCaseCandidateList(condition));
        }, CaseEntryListActionReset() {
            // エントリリストstateリセット
            dispatch(CaseEntryListActionReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMSMSA01);

