/**
 * Action Typesを定義
 */
const GAFLFCPF01ActionType = {
    UPDATE_PASSWORD_REQUEST: "UPDATE_PASSWORD_REQUEST", // パスワード更新定義
    UPDATE_PASSWORD_SUCCESS: "UPDATE_PASSWORD_SUCCESS", // パスワード更新成功定義
    UPDATE_PASSWORD_FAILURE: "UPDATE_PASSWORD_FAILURE", // パスワード更新失敗定義
    UPDATE_PASSWORD_RESET: "UPDATE_PASSWORD_RESET",   // パスワード更新リセット定義
};

export default GAFLFCPF01ActionType;
