/**
 * Action Typesを定義
 */
const GAFMCSNF01ActionType = {
    GET_NOTICE_LIST_REQUEST: "GET_NOTICE_LIST_REQUEST",// Home画面一覧データ取得定義
    GET_NOTICE_LIST_SUCCESS: "GET_NOTICE_LIST_SUCCESS",// Home画面一覧データ取得成功定義
    GET_NOTICE_LIST_FAILURE: "GET_NOTICE_LIST_FAILURE",// Home画面一覧データ取得失敗定義
    GET_NOTICE_LIST_RESET: "GET_NOTICE_LIST_RESET",// Home画面一覧データ取得RESET定義
    GET_NOTICE_DETAIL_REQUEST: "GET_NOTICE_DETAIL_REQUEST",// Home画面お知らせ詳細データ取得定義
    GET_NOTICE_DETAIL_SUCCESS: "GET_NOTICE_DETAIL_SUCCESS",// Home画面お知らせ詳細データ取得成功定義
    GET_NOTICE_DETAIL_FAILURE: "GET_NOTICE_DETAIL_FAILURE",// Home画面お知らせ詳細データ取得失敗定義
    GET_NOTICE_DETAIL_RESET: "CLEAR_NOTICE_DETAIL_RESET",// Home画面お知らせ詳細データREST定義
    UPDATE_TODO_DETAIL_REQUEST: "UPDATE_TODO_DETAIL_REQUEST",// Home画面TODOデータ更新定義
    UPDATE_TODO_DETAIL_SUCCESS: "UPDATE_TODO_DETAIL_SUCCESS",// Home画面TODOデータ更新成功定義
    UPDATE_TODO_DETAIL_FAILURE: "UPDATE_TODO_DETAIL_FAILURE",// Home画面TODOデータ更新失敗定義
    UPDATE_TODO_DETAIL_RESET: "UPDATE_TODO_DETAIL_RESET",// Home画面TODOデータRESET定義
};

export default GAFMCSNF01ActionType;
