import actionTypes from './GESSFAUS01ActionType';

/**
 * 送り出し機関・大学利用実績一覧
 */
const initialState = {
    isFetching: false,
    data: {},
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 送り出し機関・大学利用実績一覧を取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getOkrUniUsageResultsList = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
