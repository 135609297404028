/**
 * Action Typesを定義
 */
const GISAMIRS01ActionType = {
    GET_TRAINING_RESULTS_REQUEST: 'GET_TRAINING_RESULTS_REQUEST',        // 実習結果一覧取得定義
    GET_TRAINING_RESULTS_SUCCESS: 'GET_TRAINING_RESULTS_SUCCESS',        // 実習結果一覧取得成功定義
    GET_TRAINING_RESULTS_FAILURE: 'GET_TRAINING_RESULTS_FAILURE',        // 実習結果一覧取得失敗定義
    GET_TRAINING_RESULTS_RESET:   'GET_TRAINING_RESULTS_RESET',          // 実習結果一覧取得リセット定義
    TRAINING_RESULTS_DOWNLOAD_FILE_REQUEST: 'TRAINING_RESULTS_DOWNLOAD_FILE_REQUEST',      // 実習結果報告書ダウンロードファイル定義
    TRAINING_RESULTS_DOWNLOAD_FILE_SUCCESS: 'TRAINING_RESULTS_DOWNLOAD_FILE_SUCCESS',      // 実習結果報告書ダウンロードファイル成功定義
    TRAINING_RESULTS_DOWNLOAD_FILE_FAILURE: 'TRAINING_RESULTS_DOWNLOAD_FILE_FAILURE',      // 実習結果報告書ダウンロードファイル失敗定義
    TRAINING_RESULTS_DOWNLOAD_FILE_RESET: 'TRAINING_RESULTS_DOWNLOAD_FILE_RESET',          // 実習結果報告書ダウンロードファイルリセット定義
};

export default GISAMIRS01ActionType;