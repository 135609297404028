/**
 * Action Typesを定義
 */
const GIPPMPLI01ActionType = {
    GET_PROGRESS_LIST_REQUEST: 'GET_PROGRESS_LIST_REQUEST',  // 進捗一覧照会定義
    GET_PROGRESS_LIST_SUCCESS: 'GET_PROGRESS_LIST_SUCCESS',  // 進捗一覧照会成功定義
    GET_PROGRESS_LIST_FAILURE: 'GET_PROGRESS_LIST_FAILURE',  // 進捗一覧照会失敗定義
    GET_PROGRESS_LIST_RESET: 'GET_PROGRESS_LIST_RESET',      // 進捗一覧照会リセット定義
    PROGRESS_DOWNLOAD_FILE_REQUEST: 'PROGRESS_DOWNLOAD_FILE_REQUEST',  // 進捗一覧ダウンロードファイル定義
    PROGRESS_DOWNLOAD_FILE_SUCCESS: 'PROGRESS_DOWNLOAD_FILE_SUCCESS',  // 進捗一覧ダウンロードファイル成功定義
    PROGRESS_DOWNLOAD_FILE_FAILURE: 'PROGRESS_DOWNLOAD_FILE_FAILURE',  // 進捗一覧ダウンロードファイル失敗定義
    PROGRESS_DOWNLOAD_FILE_RESET: 'PROGRESS_DOWNLOAD_FILE_RESET',      // 進捗一覧ダウンロードファイルリセット定義
};

export default GIPPMPLI01ActionType;
