import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import { connect } from "react-redux";
import { MessageMap } from "./GISTTPTL01Message";
import { getMessage, getCognitoUserInfo } from "../../Constants/CommonConstants";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { getTrainingPlanTemplateList, getTrainingPlanTemplateDetail, deleteTrainingPlanTemplate, trainingPlanTemplateListReset, trainingPlanTemplateDetailReset } from "./GISTTPTL01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 実習計画テンプレート一覧
 */
class GISTTPTL01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.props.trainingPlanTemplateDetailReset();
        this.state = {
            company_name: "",
            plan_template_name: "",
            create_datetime_start: "",
            create_datetime_end: "",
            selectedTemplateId: "",
            coordinatorId: "",
            copyFlg: false,
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.handlingSearch();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    }

    /**
     * 検索処理
     */
    handlingSearch = () => {
        window.scrollTo(0, 0);

        // パラメータの設定
        let searchParams = {};
        // 企業名
        searchParams['company_name'] = this.state.company_name;
        // テンプレート名
        searchParams['plan_template_name'] = this.state.plan_template_name;
        // 作成日時開始日
        searchParams['create_datetime_start'] = this.state.create_datetime_start;
        // 作成日時終了日
        searchParams['create_datetime_end'] = this.state.create_datetime_end;

        this.setState({
            selectedTemplateId: "",
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getTrainingPlanTemplateList(searchParams);
        });
    }

    /**
     * テンプレート選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        const { value } = event.target;
        this.setState({
            selectedTemplateId: this.props.data[value].plan_template_id,
            coordinatorId: this.props.data[value].coordinator_id
        });
    }

    /**
     * 実習計画テンプレート削除処理
     */
    handlingDelete = () => {
        window.scrollTo(0, 0);
        if (isBlank(this.state.selectedTemplateId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            if (getCognitoUserInfo()["org_type"] !== 'COD' && !isBlank(this.state.coordinatorId)) {
                this.setState({
                    err_msg: [getMessage('RS-0057')]
                });
            } else {
                // 確認ダイアログメッセージボックスを表示する
                this.showModalDialog(1, getMessage('RS-0040'), this.handlingDeleteOKAction, this.handlingCancelAction);
            }
        }
    }

    /**
     * 削除のを実行する
     */
    handlingDeleteOKAction = () => {
        this.props.deleteTrainingPlanTemplate({ 'plan_template_id': this.state.selectedTemplateId });
    }

    /**
     * キャンセル処理
     */
    handlingCancelAction = () => { }

    /**
     * 実習計画テンプレート修正・コピー
     * @param {*} copy コピー
     * @param {*} value 0: 修正・コピー 1: 詳細表示
     */
    handlingModify = (copy, value) => {
        window.scrollTo(0, 0);
        if (this.state.selectedTemplateId == "") {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            // システムエラーのコールバック設定
            this.setErrorCallback(this.props.trainingPlanTemplateDetailReset());
            this.setState({ copyFlg: copy, modeFlg: value }, () => {
                if (copy) {
                    // コピーの場合
                    this.props.getTrainingPlanTemplateDetail({ 'plan_template_id': this.state.selectedTemplateId });
                } else {
                    if (value === 0) {
                        if (getCognitoUserInfo()["org_type"] !== 'COD' && !isBlank(this.state.coordinatorId)) {
                            this.setState({
                                err_msg: [getMessage('RS-0058')]
                            });
                        } else {
                            // 確認ダイアログメッセージボックスを表示する
                            this.props.getTrainingPlanTemplateDetail({ 'plan_template_id': this.state.selectedTemplateId });
                        }
                    } else {
                        this.props.getTrainingPlanTemplateDetail({ 'plan_template_id': this.state.selectedTemplateId });
                    }
                }
            });
        }
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDispWithData(changeTo);
    }

    render() {

        if (this.props.isDelete) {
            // 削除成功の場合、再検索する
            this.handlingSearch();
        }

        if (this.props.detailData && this.props.detail_err_type === '200') {
            // 実習計画テンプレート登録画面に遷移する。
            this.props.changeDispWithData("GISTTPTR01", this.props.detailData, { copyFlg: this.state.copyFlg, modeFlg: this.state.modeFlg });
        }

        // 件数が100件を超える場合
        if (this.props.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                実習計画テンプレート一覧
                                </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>実習計画テンプレート一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()} tabIndex="1">検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>
                                                        <TextInput type="text" className="el_input el_input__max" name="company_name" setValue={this.state.company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>テンプレート名</th>
                                                    <td>
                                                        <TextInput type="text" className="el_input el_input__max" name="plan_template_name" setValue={this.state.plan_template_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="3" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>作成日時</th>
                                                    <td>
                                                        <TextInput type="date" className="el_input el_input__datepicker" name="create_datetime_start" setValue={this.state.create_datetime_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex="4" />&nbsp;〜&nbsp;
                                                            <TextInput type="date" className="el_input el_input__datepicker" name="create_datetime_end" setValue={this.state.create_datetime_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} placeholder="年/月/日" tabIndex="5" /> まで
                                                        </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSearch()} tabIndex="6">検索</button>
                                        </div>
                                    </div>
                                    <section className="bl_sect">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv3Heading"><span>実習計画テンプレート一覧</span></h2>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__multi">
                                            <button type="button" className="el_btn hp_mr15" onClick={() => this.handlingDelete()} tabIndex="7">削除</button>
                                            <button type="button" className="el_blueBtn hp_mr15" onClick={() => this.handleToDisplay("GISTTPTR01")} tabIndex="8">新規登録</button>
                                            <button type="button" className="el_blueBtn hp_mr15" onClick={() => this.handlingModify(true, 0)} tabIndex="9">コピー作成</button>
                                            <button type="button" className="el_blueBtn" onClick={() => this.handlingModify(false, 0)} tabIndex="10">修正</button>
                                            <button type="button" className="el_blueBtn" onClick={() => this.handlingModify(false, 1)} tabIndex="11">詳細表示</button>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>選択</th>
                                                        <th>企業名</th>
                                                        <th>テンプレート名</th>
                                                        <th>作成日時</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data)) {
                                                            return (
                                                                this.props.data.map((item, index) => (
                                                                    <Fragment key={item.plan_detail_number}>
                                                                        <tr>
                                                                            <td className="hp_tac">
                                                                                <input type="radio" name="row_select" value={index} onChange={this.handleRowSelection} tabIndex={12 + index} />
                                                                            </td>
                                                                            <td>{item.company_name}</td>
                                                                            <td>{item.plan_template_name}</td>
                                                                            <td className="hp_tar">{item.create_datetime}</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getTrainingPlanTemplateList.isFetching,
        data: state.getTrainingPlanTemplateList.data,
        overflowflg: state.getTrainingPlanTemplateList.overflowflg,
        isDelete: state.getTrainingPlanTemplateList.isDelete,
        isError: state.getTrainingPlanTemplateList.isError,
        err_type: state.getTrainingPlanTemplateList.err_type,
        err_detail: state.getTrainingPlanTemplateList.err_detail,
        detailData: state.getTrainingPlanTemplateDetail.data,
        detail_err_type: state.getTrainingPlanTemplateDetail.err_type,
        detail_err_detail: state.getTrainingPlanTemplateDetail.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getTrainingPlanTemplateList: (arg) => dispatch(getTrainingPlanTemplateList(arg)),
        getTrainingPlanTemplateDetail: (arg) => dispatch(getTrainingPlanTemplateDetail(arg)),
        deleteTrainingPlanTemplate: (arg) => dispatch(deleteTrainingPlanTemplate(arg)),
        trainingPlanTemplateListReset: () => dispatch(trainingPlanTemplateListReset),
        trainingPlanTemplateDetailReset: () => dispatch(trainingPlanTemplateDetailReset)
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISTTPTL01);

