import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCognitoUserInfo, getMessage, getCodeValueLabelList } from "../../Constants/CommonConstants";
import { checkArray, isBlank, getInt } from "../../Components/Tools/StringTools";
import { saveTrainingPlanInfo, saveTrainingPlanReset } from "./GISIPWPR01Action";
import { getTrainingPlanTemplateDetail, trainingPlanTemplateDetailReset } from "../TtGISTTPTL01/GISTTPTL01Action";
import { GetWorkingPlanInfoListReset } from "../IpGISIPWPL01/GISIPWPL01Action";
import { MessageMap } from "./GISIPWPR01Message";
import { TRAINING_PLAN_TEMPLATE_DATA } from "../../Constants/IsConstants";
import moment from "moment";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Textarea from "../../Components/Atoms/Textarea";
import Plus_Icon from "../../Images/btn_plus.svg";
import "../../Css/Common.css";

/**
 * 実習計画登録
 */
class GISIPWPR01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            plan_template_id: "",
            plan_template_name: "",
            templateList: [],
            apprenticeList: [],
            trainingPlanList: [],
            err_msg: [],
            createorupdate: this.props.createorupdate,
            isDisabled: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { trainingPlanData, detailData } = this.props;

        if (this.props.trainingPlanDataResult.err_type == '200') {
            if (trainingPlanData.sys_now < detailData.accept_period_start && ['KAN', 'COP', 'TOR'].includes(getCognitoUserInfo()["org_type"])) {
                // 新規登録場合
                if (this.state.createorupdate === 'create') {
                    let registration = new Array(5).fill().map((item, index) => ({ ...TRAINING_PLAN_TEMPLATE_DATA, plan_detail_number: index + 1 }));
                    this.setState({
                        plan_name: "",
                        plan_template_id: "",
                        plan_template_name: "",
                        trainingPlanList: registration,
                        isDisabled: false
                    });
                } else if (this.state.createorupdate === 'update') { // 修正の場合
                    let result = trainingPlanData.work_plan_detail_dict.map(item => ({
                        plan_detail_number: item.plan_detail_number,
                        work_type: item.work_type,
                        work_name: item.work_name,
                        work_contents: item.work_contents,
                        hours_1: item.hours_1,
                        hours_2: item.hours_2,
                        hours_3: item.hours_3,
                        hours_4: item.hours_4,
                        hours_5: item.hours_5,
                        hours_6: item.hours_6,
                        hours_7: item.hours_7,
                        hours_8: item.hours_8,
                        hours_9: item.hours_9,
                        hours_10: item.hours_10,
                        hours_11: item.hours_11,
                        hours_12: item.hours_12
                    }));
                    this.setState({
                        plan_name: trainingPlanData.work_plan_case.plan_name,
                        plan_template_id: trainingPlanData.work_plan_case.plan_template_id,
                        plan_template_name: trainingPlanData.work_plan_case.plan_template_name,
                        trainingPlanList: result,
                        apprenticeList: trainingPlanData.work_plan_user_list,
                        isDisabled: false
                    });
                }
            } else {
                if (this.state.createorupdate === 'create') {
                    let registration = new Array(5).fill().map((item, index) => ({ ...TRAINING_PLAN_TEMPLATE_DATA, plan_detail_number: index + 1 }));
                    this.setState({
                        plan_name: "",
                        plan_template_id: "",
                        plan_template_name: "",
                        trainingPlanList: registration,
                        isDisabled: true
                    });
                } else if (this.state.createorupdate === 'update') { // 修正の場合
                    let result = trainingPlanData.work_plan_detail_dict.map(item => ({
                        plan_detail_number: item.plan_detail_number,
                        work_type: item.work_type,
                        work_name: item.work_name,
                        work_contents: item.work_contents,
                        hours_1: item.hours_1,
                        hours_2: item.hours_2,
                        hours_3: item.hours_3,
                        hours_4: item.hours_4,
                        hours_5: item.hours_5,
                        hours_6: item.hours_6,
                        hours_7: item.hours_7,
                        hours_8: item.hours_8,
                        hours_9: item.hours_9,
                        hours_10: item.hours_10,
                        hours_11: item.hours_11,
                        hours_12: item.hours_12
                    }));
                    this.setState({
                        plan_name: trainingPlanData.work_plan_case.plan_name,
                        plan_template_id: trainingPlanData.work_plan_case.plan_template_id,
                        plan_template_name: trainingPlanData.work_plan_case.plan_template_name,
                        trainingPlanList: result,
                        apprenticeList: trainingPlanData.work_plan_user_list,
                        isDisabled: true
                    });
                }
            }

            this.setState({
                case_title: detailData.case_title,
                case_id: detailData.case_id,
                company_name: detailData.company_name,
                accept_period_start: detailData.accept_period_start,
                accept_period_end: detailData.accept_period_end,
                templateList: trainingPlanData.work_plan_template
            });
        }
    }

    /**
     * テンプレート選択
     */
    handleTemplateChange = (event) => {
        const { name, value } = event.target;
        const { work_plan_template } = this.props.trainingPlanData;
        this.setState({
            [name]: value
        }, () => {
            if (isBlank(value)) {
                let registration = new Array(5).fill().map((item, index) => ({ ...TRAINING_PLAN_TEMPLATE_DATA, plan_detail_number: index + 1 }));
                this.setState({
                    plan_template_id: "",
                    plan_template_name: "",
                    trainingPlanList: registration,
                }, () => {
                    this.editItemHandler('plan_template_id', this.state.plan_template_id);
                    this.editItemHandler('plan_template_name', this.state.plan_template_name);
                });
            } else {
                work_plan_template.forEach(item => {
                    if (item.plan_template_id == value) {
                        this.setState({
                            plan_template_id: item.plan_template_id,
                            plan_template_name: item.plan_template_name,
                        }, () => {
                            this.editItemHandler('plan_template_id', this.state.plan_template_id);
                            this.editItemHandler('plan_template_name', this.state.plan_template_name);
                            this.props.getTrainingPlanTemplateDetail(this.state.plan_template_id);
                        });
                    }
                });
            }
        });
    };

    /**
     * 業務種別変更
     * @param {*} event
     */
    handleChange = index => event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandlerByObject(index, name, value);
        });
    };

    /**
     * 実習者の処理
     * @param {*} event
     */
    handleApprenticeChange = (event) => {
        event.persist();
        const { value, checked } = event.target;
        const { user_list } = this.props.trainingPlanData;

        if (checked) {
            const userObj = user_list.find(el => el.user_id === value);
            this.setState(prevState => ({
                apprenticeList: prevState.apprenticeList.concat({ 'user_id': userObj.user_id })
            }), () => {
                this.editItemHandler('apprenticeList', this.state.apprenticeList);
            });
        } else {
            this.setState(prevState => ({
                apprenticeList: prevState.apprenticeList.filter(i => i.user_id !== value)
            }), () => {
                this.editItemHandler('apprenticeList', this.state.apprenticeList);
            });
        }
    };

    /**
     * 画面リストにデータを追加する。
     */
    addTrainingPlan = () => {
        const { trainingPlanList } = this.state;
        if (trainingPlanList.length > 9) {
            // 表示中のエラーメッセージをリセットする
            this.props.saveTrainingPlanReset();
            this.setState({
                err_msg: [getMessage('RS-0056').replace(/%1/g, '10')]
            });
            window.scrollTo(0, 0);
        } else {
            const clone = { ...TRAINING_PLAN_TEMPLATE_DATA };
            clone.plan_detail_number = trainingPlanList.length + 1;
            this.setState(prevState => ({
                trainingPlanList: [...prevState.trainingPlanList, clone]
            }));
        }

    }

    /**
     * 登録
     */
    saveTrainingPlanInfo = () => {
        window.scrollTo(0, 0);
        const { trainingPlanList, apprenticeList } = this.state;
        const { work_plan_case } = this.props.trainingPlanData;

        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.saveTrainingPlanReset());

        let trainingPlanTemplateData = trainingPlanList.map((item, index) => ({
            plan_detail_number: index + 1,
            work_type: item.work_type,
            work_name: item.work_name,
            work_contents: item.work_contents,
            month_time: {
                hours_1: item.hours_1,
                hours_2: item.hours_2,
                hours_3: item.hours_3,
                hours_4: item.hours_4,
                hours_5: item.hours_5,
                hours_6: item.hours_6,
                hours_7: item.hours_7,
                hours_8: item.hours_8,
                hours_9: item.hours_9,
                hours_10: item.hours_10,
                hours_11: item.hours_11,
                hours_12: item.hours_12
            }
        }));

        if (checkArray(trainingPlanTemplateData)) {
            // 各月の列の合計時間
            for (let i = 1; i < 13; i++) {
                let sum = 0;
                trainingPlanTemplateData.forEach((v => {
                    sum = sum + getInt(v['month_time']['hours_' + i]);
                }));
                if (sum > 160) {
                    // 合計時間 ＞ 160時間
                    this.showModalDialog(1, getMessage('RS-0054').replace(/%1/g, MessageMap['hours_' + i]), () => { this.saveTrainingPlanInfoOK(work_plan_case, apprenticeList, trainingPlanTemplateData); }, () => { })
                    return;
                }
            }

        }

        this.saveTrainingPlanInfoOK(work_plan_case, apprenticeList, trainingPlanTemplateData);

    }


    saveTrainingPlanInfoOK = (work_plan_case, userIdArray, trainingPlanTemplateData) => {
        this.setState({
            err_msg: []
        }, () => {
            let params = {};
            params['plan_template_id'] = this.state.plan_template_id;
            params['plan_template_name'] = this.state.plan_template_name;
            params['case_id'] = this.state.case_id;
            params['plan_id'] = this.props.createorupdate === 'create' ? (isBlank(this.state.create_plan_id) ? '' : this.state.create_plan_id) : work_plan_case.plan_id;
            params['plan_name'] = this.state.plan_name;
            params['user_id_list'] = userIdArray;
            params['work_plan_detail_data'] = trainingPlanTemplateData;

            this.props.saveTrainingPlanInfo(params);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            trainingPlanList: this.state.trainingPlanList.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        if (changeTo == 'GISIPWPM01') {
            this.props.getWorkingPlanInfoListReset();
        }
        this.props.saveTrainingPlanReset();
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { templateList, trainingPlanList, isDisabled } = this.state;
        const { user_list } = this.props.trainingPlanData;

        if (this.props.trainingPlanDataResult.err_type == '300') {
            // 業務エラーがある場合にエラーメッセージ表示する
            return (<ErrorMessage err_type={this.props.trainingPlanDataResult.err_type} err_detail={this.props.trainingPlanDataResult.err_detail} messageMap={{}} />);
        }

        // 業務種別
        const workTypeOpt = getCodeValueLabelList('RSC0033');

        if (!this.props.data.isFetching) {
            // 最新バージョン設定する
            if (this.props.data.data && this.props.data.err_type === '200') {
                if (this.props.createorupdate === 'create') {
                    trainingPlanList.forEach((item) => item.plan_id = this.props.data.data.list[0].plan_id);
                    this.setState({
                        trainingPlanList: trainingPlanList,
                        create_plan_id: this.props.data.data.list[0].plan_id,
                        apprenticeList: this.props.data.data.list
                    }, () => {
                        this.props.saveTrainingPlanReset();
                        this.showModalDialog(0, getMessage('RS-0036'), () => { });
                    });
                } else {
                    this.setState({
                        apprenticeList: this.props.data.data.list
                    }, () => {
                        this.props.saveTrainingPlanReset();
                        this.showModalDialog(0, getMessage('RS-0036'), () => { });
                    });
                }
            }
        }

        if (this.props.planTemplateDetail && this.props.planTemplateDetail.err_type == '200') {
            this.setState({
                trainingPlanList: this.props.planTemplateDetail.data
            }, () => {
                this.props.trainingPlanTemplateDetailReset();
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">実習計画登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISIPWPM01'); }, () => { })}>実習計画案件一覧</a></li>
                                <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISIPWPL01'); }, () => { })}>実習計画一覧</a></li>
                                <li>実習計画登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.data.err_type} err_detail={this.props.data.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.trainingPlanDataResult.err_type} err_detail={this.props.trainingPlanDataResult.err_detail} messageMap={{}} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(([key]) => (
                                                        <li key={key}>{this.state.err_msg[key]}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>{this.state.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>{this.state.case_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{this.state.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    <td>{this.state.accept_period_start}〜{this.state.accept_period_end}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">実習実施予定表</h2>
                                    </div>
                                    <div className="bl_sortBoxUnit">
                                        <dl className="bl_sortBox bl_sortBox__l">
                                            <dt className="bl_sortBox_ttl">テンプレート選択</dt>
                                            <dd>
                                                <select disabled={isDisabled} className={isDisabled ? "el_select is_disabled" : "el_select el_select__auto"} name="plan_template_id" value={this.state.plan_template_id} onChange={this.handleTemplateChange} tabIndex="1" >
                                                    <option value="" selected></option>
                                                    {
                                                        checkArray(templateList) && templateList.map(item => (
                                                            <option key={item.plan_template_id} value={item.plan_template_id} selected={this.state.plan_template_id === item.plan_template_id}>
                                                                {item.plan_template_name}
                                                            </option>
                                                        ))
                                                    }
                                                </select>
                                            </dd>
                                        </dl>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable02 hp_mb20">
                                            <tbody>
                                                <tr>
                                                    <th>実習計画名 </th>
                                                    <td>
                                                        <TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_input__max"} name="plan_name" maxlength="40" required="required" setValue={this.state.plan_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="sticky_table_wrapper sticky_table_wrapperNoScroll">
                                            <table className="sticky_table mcTable_align el_fixedTable">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th colSpan="3">業務種別</th>
                                                        <th colSpan="9">業務名／業務内容</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(trainingPlanList)) {
                                                            return (
                                                                trainingPlanList.map((item, index) => (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="hp_tac hp_bg__gl" rowSpan="5">No.{index + 1}</td>
                                                                            <td colSpan="3" rowSpan="2" className="hp_tac">
                                                                                <select disabled={isDisabled} className={isDisabled ? "el_select is_disabled" : "el_select el_select__w80per"} name="work_type" value={item.work_type} onChange={this.handleChange(index)} tabIndex="3" >
                                                                                    <option value="" selected></option>
                                                                                    {
                                                                                        checkArray(workTypeOpt) && workTypeOpt.map(item => (
                                                                                            <option key={item.value} value={item.value} selected={item.work_type === item.value}>
                                                                                                {item.label}
                                                                                            </option>
                                                                                        ))
                                                                                    }
                                                                                </select>
                                                                            </td>
                                                                            <td colSpan="9" className="hp_tac">
                                                                                <div className="bl_formUnit">
                                                                                    <TextInput type="text" disabled={isDisabled} className={isDisabled ? "el_input is_disabled" : "el_input el_input__max"} name="work_name" maxlength="40" required="required" setValue={item.work_name} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="業務名をここに記入してください" tabIndex="4" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td colSpan="9" className="hp_tac">
                                                                                <div className="bl_formUnit">
                                                                                    <Textarea disabled={isDisabled} className={isDisabled ? "el_textarea is_disabled" : "el_textarea"} name="work_contents" rows="2" cols="15" maxlength="200" setValue={item.work_contents} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} placeholder="業務内容をここに記入してください" tabIndex="5" />
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <th colSpan="12">月・時間</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <th>４月</th>
                                                                            <th>５月</th>
                                                                            <th>６月</th>
                                                                            <th>７月</th>
                                                                            <th>８月</th>
                                                                            <th>９月</th>
                                                                            <th>１０月</th>
                                                                            <th>１１月</th>
                                                                            <th>１２月</th>
                                                                            <th>１月</th>
                                                                            <th>２月</th>
                                                                            <th>３月</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_4" maxlength="3" setValue={item.hours_4} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="6" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_5" maxlength="3" setValue={item.hours_5} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="7" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_6" maxlength="3" setValue={item.hours_6} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="8" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_7" maxlength="3" setValue={item.hours_7} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="9" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_8" maxlength="3" setValue={item.hours_8} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="10" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_9" maxlength="3" setValue={item.hours_9} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="11" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_10" maxlength="3" setValue={item.hours_10} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="12" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_11" maxlength="3" setValue={item.hours_11} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="13" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_12" maxlength="3" setValue={item.hours_12} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="14" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_1" maxlength="3" setValue={item.hours_1} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="15" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_2" maxlength="3" setValue={item.hours_2} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="16" />&nbsp;h</td>
                                                                            <td><TextInput disabled={isDisabled} type="text" className={isDisabled ? "el_input is_disabled" : "el_input el_fontSize__sm"} name="hours_3" maxlength="3" setValue={item.hours_3} onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} tabIndex="17" />&nbsp;h</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r">
                                            <button type="button" value="+" className="el_funcBtn" onClick={() => this.addTrainingPlan()} disabled={isDisabled} tabIndex="19" ><img style={{ opacity: isDisabled ? 0.5 : 1.0 }} src={Plus_Icon} alt="プラス" />追加</button>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">実習者</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>氏名</th>
                                                    <td>
                                                        <div className="bl_formUnit bl_formUnit__flex">
                                                            {
                                                                checkArray(user_list) && user_list.map((item, index) => {
                                                                    return (
                                                                        <label className="el_label" key={item.user_id}>
                                                                            <input type="checkbox" className="el_checkbox" name={item.user_id} value={item.user_id} disabled={isDisabled}
                                                                                checked={this.state.apprenticeList.find((el) => el.user_id === item.user_id)} onChange={this.handleApprenticeChange} tabIndex="20" />
                                                                            <span className="el_checkbox_txt">{item.employment_name_kana}</span>
                                                                        </label>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit">
                                    <button type="button" className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveTrainingPlanInfo()} tabIndex="21">登録する</button>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISIPWPL01'); }, () => { })} tabIndex="22">実習計画一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        data: state.saveTrainingPlanInfo,
        planTemplateDetail: state.getTrainingPlanTemplateDetail,
        // 実習計画登録
        trainingPlanDataResult: state.getTrainingPlanInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveTrainingPlanInfo: (arg) => dispatch(saveTrainingPlanInfo(arg)),
        saveTrainingPlanReset: () => dispatch(saveTrainingPlanReset()),
        getTrainingPlanTemplateDetail: (plan_template_id) => dispatch(getTrainingPlanTemplateDetail({ plan_template_id: plan_template_id })),
        trainingPlanTemplateDetailReset: () => dispatch(trainingPlanTemplateDetailReset()),
        getWorkingPlanInfoListReset: () => dispatch(GetWorkingPlanInfoListReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISIPWPR01);

