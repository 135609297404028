/**
 * Action Typesを定義
 */
const GTMWTWTR02ActionType = {
    GET_WORKING_HOURS_REQUEST: "GET_WORKING_HOURS_REQUEST", // 作業時間管理取得定義
    GET_WORKING_HOURS_SUCCESS: "GET_WORKING_HOURS_SUCCESS", // 作業時間管理取得成功定義
    GET_WORKING_HOURS_FAILURE: "GET_WORKING_HOURS_FAILURE", // 作業時間管理取得失敗定義
    GET_WORKING_HOURS_RESET: "GET_WORKING_HOURS_RESET",   // 作業時間管理取得リセット定義

    UPDATE_WORKING_HOURS_REQUEST: "UPDATE_WORKING_HOURS_REQUEST", // 作業時間管理更新定義
    UPDATE_WORKING_HOURS_SUCCESS: "UPDATE_WORKING_HOURS_SUCCESS", // 作業時間管理更新成功定義
    UPDATE_WORKING_HOURS_FAILURE: "UPDATE_WORKING_HOURS_FAILURE", // 作業時間管理更新失敗定義
    UPDATE_WORKING_HOURS_RESET: "UPDATE_WORKING_HOURS_RESET",   // 作業時間管理更新リセット定義
};

export default GTMWTWTR02ActionType;
