
import { getCompanyAndFacilityInfo, updateCompanyAndFacilityInfo } from "./OmGCMOMCRU01/GCMOMCRU01Reducer";
import { saveOrganizationManagement } from "./OmGCMOMOIR02/GCMOMOIR02Reducer";
import { saveUserManagement } from "./OmGCMOMORU02/GCMOMORU02Reducer";
import { getOrganizationManagementList, updateOrganizationManagement, deleteOrganizationManagement } from "./OmGCMOMOIR01/GCMOMOIR01Reducer";
import { getUserManagementList, updateUserManagement, deleteUserManagement } from "./OmGCMOMORU01/GCMOMORU01Reducer";
import { getSendingOrganizationInfo, updateSendingOrganizationInfo } from "./OmGCMOMCRU03/GCMOMCRU03Reducer";
import { getUniversityInfo, updateUniversityInfo } from "./OmGCMOMCRU04/GCMOMCRU04Reducer";
import { getSupervisingOrgInfo, updateSupervisingOrgInfo } from "./OmGCMOMCRU05/GCMOMCRU05Reducer";
import { getEmployFacilityInfo } from "./OmGCMOMCRU08/GCMOMCRU08Reducer";
import { getTrainningWorkerList } from "./OmGCMOMCUM01/GCMOMCUM01Reducer";
import { getTrainningWorkerDetail } from "./OmGCMOMCUM02/GCMOMCUM02Reducer";
import { getSupportOrgInfo, updateSupportOrgInfo } from "./OmGCMOMCRU10/GCMOMCRU10Reducer";
import { getChangeOrgInfo, updateChangeOrgInfo } from "./OmGCMOMORU03/GCMOMORU03Reducer";

export const cmReducer = {
    getOrganizationManagementList,
    updateOrganizationManagement,
    deleteOrganizationManagement,
    getUserManagementList,
    updateUserManagement,
    deleteUserManagement,
    saveOrganizationManagement,
    saveUserManagement,
    getCompanyAndFacilityInfo,
    updateCompanyAndFacilityInfo,
    getUniversityInfo,
    updateUniversityInfo,
    getSupervisingOrgInfo,
    updateSupervisingOrgInfo,
    getSendingOrganizationInfo,
    updateSendingOrganizationInfo,
    getEmployFacilityInfo,
    getTrainningWorkerList,
    getTrainningWorkerDetail,
    getSupportOrgInfo,
    updateSupportOrgInfo,
    getChangeOrgInfo,
    updateChangeOrgInfo
};