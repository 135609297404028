/**
 * Action Typesを定義
 */
const GISAMIAR02ActionType = {
    GET_TRAINING_RESULTS_REQUEST: "GET_TRAINING_RESULTS_REQUEST", // 実習結果取得定義
    GET_TRAINING_RESULTS_SUCCESS: "GET_TRAINING_RESULTS_SUCCESS", // 実習結果取得成功定義
    GET_TRAINING_RESULTS_FAILURE: "GET_TRAINING_RESULTS_FAILURE", // 実習結果取得失敗定義
    GET_TRAINING_RESULTS_RESET: "GET_TRAINING_RESULTS_RESET",   // 実習結果取得リセット定義

    UPDATE_TRAINING_RESULTS_REQUEST: "UPDATE_TRAINING_RESULTS_REQUEST", // 実習結果更新定義
    UPDATE_TRAINING_RESULTS_SUCCESS: "UPDATE_TRAINING_RESULTS_SUCCESS", // 実習結果更新成功定義
    UPDATE_TRAINING_RESULTS_FAILURE: "UPDATE_TRAINING_RESULTS_FAILURE", // 実習結果更新失敗定義
    UPDATE_TRAINING_RESULTS_RESET: "UPDATE_TRAINING_RESULTS_RESET",   // 実習結果更新リセット定義
};

export default GISAMIAR02ActionType;
