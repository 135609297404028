import axios from "axios";
import { getIdToken, getCognitoUser, getCognitoUserInfo, setCognitoUserInfo, sessionTimeoutCheck, getCognitoInfo, setRefreshCognitoSessionStorage } from "../../Constants/CommonConstants";
import jwt from "jsonwebtoken";

/**
 * Httpリクエスト共通部品
 * @param {*} url       リクエストURL
 * @param {*} data      リクエストデータ
 * @param {*} config    リクエストパラメータ
 */
export const ecoPost = (url, data, config) => {
    return new Promise(function (resolve, reject) {
        axios.post(url, data, config).then(
            response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
};

/**
 * Httpリクエスト共通部品
 * @param {*} url       リクエストURL
 * @param {*} data      リクエストデータ
 */
export const ecoPostWithAuthHeader = (url, data) => {

    // セッションタイムアウトチェック
    sessionTimeoutCheck();

    const idToken = getIdToken();
    // ヘッダー認証情報を設定する
    const headerParam = {
        Authorization: idToken
    };

    refreshCognitoUser();

    return new Promise(function (resolve, reject) {
        ecoPost(url, data, { headers: headerParam }).then(
            response => {
                resolve(response);
            }).catch(error => {
                reject(error);
            });
    });
};


export const doPostAws = (body) => {
    const url = 'https://cognito-idp.ap-northeast-1.amazonaws.com/';
    const headers = new Headers({
        "Content-type": "application/x-amz-json-1.1",
        'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth'
    });
    return fetch(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: headers
    }).then((response) => {
        return response.json();
    });
};

export const refreshCognitoUser = (url, data) => {
    if (getCognitoUser() != null) {
        getCognitoUser().getSession(function (err, session) {
            if (session != null && session.getRefreshToken() != null) {
                let refreshTokenstr = session.getRefreshToken().getToken();
                var params = {
                    AuthFlow: 'REFRESH_TOKEN_AUTH',
                    ClientId: getCognitoInfo('ClientId'),
                    AuthParameters: {
                        'REFRESH_TOKEN': refreshTokenstr,
                        'DEVICE_KEY': session.accessToken.payload.device_key
                    }
                };
                doPostAws(params).then(result => {
                    let userInfo = getCognitoUserInfo();
                    setRefreshCognitoSessionStorage(userInfo['user_name'], result.AuthenticationResult);
                    let idToken = result.AuthenticationResult.IdToken;
                    let decoded = jwt.decode(idToken, { complete: true });
                    userInfo['exp'] = decoded.payload.exp;
                    setCognitoUserInfo(userInfo);
                });
            }
        });
    }
};

/**
 * Httpリクエスト共通部品
 * @param {*} url       リクエストURL
 * @param {*} data      リクエストデータ
 */
export const ecoPostDownloadExcelFile = (url, data) => {

    // セッションタイムアウトチェック
    sessionTimeoutCheck();

    const idToken = getIdToken();
    // ヘッダー認証情報を設定する
    const headerParam = {
        Authorization: idToken,
        ContentType: 'application/vnd.ms-excel;charset=UTF-8',
        Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    };

    refreshCognitoUser();

    return new Promise(function (resolve, reject) {
        ecoPost(url, data, {
            headers: headerParam,
            responseType: "blob",
            dataType: "binary"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

/**
 * Httpリクエスト共通部品
 * @param {*} url       リクエストURL
 * @param {*} data      リクエストデータ
 */
export const ecoPostDownloadPdfFile = (url, data) => {

    // セッションタイムアウトチェック
    sessionTimeoutCheck();

    const idToken = getIdToken();
    // ヘッダー認証情報を設定する
    const headerParam = {
        Authorization: idToken,
        ContentType: 'application/pdf;charset=UTF-8',
        Accept: "application/pdf"
    };

    refreshCognitoUser();

    return new Promise(function (resolve, reject) {
        ecoPost(url, data, {
            headers: headerParam,
            responseType: "blob",
            dataType: "binary"
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
};

/**
 * Httpリクエスト共通部品(Async)
 * @param {*} url       リクエストURL
 * @param {*} data      リクエストデータ
 */
export const ecoPostWithAuthHeaderAsync = (url, data) => {

    // セッションタイムアウトチェック
    sessionTimeoutCheck();

    const idToken = getIdToken();
    // ヘッダー認証情報を設定する
    const headerParam = {
        Authorization: idToken
    };

    refreshCognitoUser();

    return axios.post(url, data, { headers: headerParam });

};
