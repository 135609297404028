/**
 * Action Typesを定義
 */
const GUIULUIP01ActionType = {
    UPDATE_USEFUL_INFO_DETAIL_REQUEST: 'UPDATE_USEFUL_INFO_DETAIL_REQUEST',  // お役立ち情報更新定義
    UPDATE_USEFUL_INFO_DETAIL_SUCCESS: 'UPDATE_USEFUL_INFO_DETAIL_SUCCESS',  // お役立ち情報更新成功定義
    UPDATE_USEFUL_INFO_DETAIL_FAILURE: 'UPDATE_USEFUL_INFO_DETAIL_FAILURE',  // お役立ち情報更新失敗定義
    UPDATE_USEFUL_INFO_DETAIL_RESET: 'UPDATE_USEFUL_INFO_DETAIL_RESET'       // お役立ち情報更新リセット定義
};

export default GUIULUIP01ActionType;