import actionTypes from './GCMOMCRU10ActionType';

/**
 * 組織情報(プロフィール)支援機関
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 組織情報(プロフィール)支援機関取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getSupportOrgInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SUPPORT_ORG_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.GET_SUPPORT_ORG_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.GET_SUPPORT_ORG_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.GET_SUPPORT_ORG_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 組織情報(プロフィール)支援機関を更新するReducer
 * @param {*} state
 * @param {*} action
 */
export const updateSupportOrgInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_SUPPORT_ORG_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.UPDATE_SUPPORT_ORG_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.UPDATE_SUPPORT_ORG_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.UPDATE_SUPPORT_ORG_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
