import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { imageResize } from "../../Components/Tools/ImageTools";
import TextInput from "../../Components/Atoms/TextInput";
import { createRecruitmentCase, createRecruitmentCaseReset } from "../MmGRSMMRMR01/GRSMMRMR01Action";
import { MessageMap } from "../MmGRSMMRMR01/GRSMMRMR01Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import Close_Icon from "../../Images/icon_close.svg";
import { getMessage } from "../../Constants/CommonConstants";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 求人案件登録（施設紹介）
 */
class GRSMMRMR05 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            imageCase: [],
            isDisabled: false,
            recruitmentCaseInfo: this.props.recruitmentCaseInfo,
            recruitmentCaseDetail: this.props.recruitmentCaseDetail,
            showImageArea: false,
            facility_video_url_list: []
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { recruitmentCaseDetail } = this.state;

        let facility_video_url_arr = [];
        if (recruitmentCaseDetail.facility_video_url_list && recruitmentCaseDetail.facility_video_url_list.length > 0) {
            facility_video_url_arr = recruitmentCaseDetail.facility_video_url_list
        }
        this.setState({
            facility_video_url_list: facility_video_url_arr
        });

        let imageArr = [];
        if (recruitmentCaseDetail.case_image && recruitmentCaseDetail.case_image.length > 0) {
            imageArr = recruitmentCaseDetail.case_image.filter(item => {
                return item.image_type === '0';
            });
        }
        // 画面操作エリアの表示を制御するため
        for (var i = 0; i < imageArr.length; i++) {
            if (imageArr[i].image_type == '0') {
                this.setState({ showImageArea: true });
                break;
            } else {
                this.setState({ showImageArea: false });
            }
        }
        this.setState({
            imageCase: imageArr
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && recruitmentCaseDetail.case_status === '2' && recruitmentCaseDetail.sys_date < recruitmentCaseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });
        window.scrollTo(0, 0);
    }

    changeImgId = (index) => {
        return "changeImg".concat(index);
    }

    /**
     * 画像を追加
     */
    addingImageUpload = (e) => {
        imageResize(e.target, (data) => {
            this.setState({
                imageCase: [...this.state.imageCase, {
                    image_type: '0',      // 画像タイプ
                    image_content: data,  // 画像内容
                    image_comment: ""     // 画像コメント
                }]
            }, () => {
                this.updateImageState();
            });
        });
    };

    /**
     * 画像を変更
     */
    handleImageUpload = (e, index) => {
        imageResize(e.target, (data) => {
            const imageUrls = this.state.imageCase;
            imageUrls[index].image_content = data;
            this.setState({ imageCase: imageUrls }, () => {
                this.updateImageState();
            });
        });
    }

    /**
     * 画像を削除
     */
    handleImageDelete = (index) => {
        let imageUrls = [...this.state.imageCase];
        imageUrls.splice(index, 1);
        this.setState({ imageCase: imageUrls }, () => {
            this.updateImageState();
        });
    };

    /**
     * 写真の説明
     * @param {*} e
     * @param {*} index
     */
    handleImageComment = (e, index) => {
        const imageUrls = this.state.imageCase;
        imageUrls[index].image_comment = e.target.value;
        this.setState({
            imageCase: imageUrls
        }, () => {
            this.updateImageState();
        });
    }

    /**
     * 動画URLの説明
     * @param {*} e
     * @param {*} index
     */
    handleUrlComment = (e, index) => {
        const videoUrls = this.state.facility_video_url_list;
        videoUrls[index].video_comment = e.target.value;
        this.setState({
            facility_video_url_list: videoUrls
        }, () => {
            this.updatevideoState();
        });
    }

    /**
     * 動画URLを追加する
     */
    addVideoUrl = () => {
        let plan = { "facility_video_url": "" };
        this.state.facility_video_url_list.push(plan);
        this.setState({
            facility_video_url_list: this.state.facility_video_url_list
        });
    }

    /**
     * 動画を削除
     */
    handleVideoDelete = (index) => {
        let videoUrls = [...this.state.facility_video_url_list];
        videoUrls.splice(index, 1);
        this.setState({ facility_video_url_list: videoUrls }, () => {
            this.updatevideoState();
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} index
     * @param {*} name
     * @param {*} value
     */
    editItemHandlerByObject = (index, name, value) => {
        this.setState({
            facility_video_url_list: this.state.facility_video_url_list.map((item, _index) =>
                _index == index ? { ...item, [name]: value } : item
            )
        }, () => {
            this.editItemHandler('facility_video_url_list', this.state.facility_video_url_list);
            this.updatevideoState();
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            ...this.state,
            [name]: value
        });
    }

    /**
     *画像サイズを変更する
     */
    updateImageState = () => {
        let imageArr = [];
        if (this.state.recruitmentCaseDetail.case_image && this.state.recruitmentCaseDetail.case_image.length > 0) {
            // 施設紹介保留
            imageArr = this.state.recruitmentCaseDetail.case_image.filter(item => {
                return item.image_type !== '0';
            });
        }
        imageArr = [...imageArr, ...this.state.imageCase];
        // 画面操作エリアの表示を制御するため
        for (var i = 0; i < imageArr.length; i++) {
            if (imageArr[i].image_type == '0') {
                this.setState({ showImageArea: true });
                break;
            } else {
                this.setState({ showImageArea: false });
            }
        }
        let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
        recruitmentCaseDetailData.case_image = imageArr;
        this.setState({ recruitmentCaseDetail: recruitmentCaseDetailData });
    }

    updatevideoState = () => {
        let videoArr = [];
        videoArr = [...videoArr, ...this.state.facility_video_url_list];

        let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
        recruitmentCaseDetailData.facility_video_url_list = videoArr;
        this.setState({ recruitmentCaseDetail: recruitmentCaseDetailData });
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createRecruitmentCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
        if (recruitmentCaseDetailData.case_id == '' || recruitmentCaseDetailData.case_id == null) {
            if (this.props.data && this.props.data.case_id != null) {
                recruitmentCaseDetailData.case_id = this.props.data.case_id;
            }
        }
        recruitmentCaseDetailData.temp_save = '1';
        this.setState({
            recruitmentCaseDetail: recruitmentCaseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.recruitmentCaseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createRecruitmentCase(reqData);
            }
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createRecruitmentCaseReset();
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    render() {
        const { isDisabled, facility_video_url_list } = this.state;
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.recruitmentCaseDetail.version_no != this.props.data.version_no) {
                let recruitmentCaseDetailData = this.state.recruitmentCaseDetail;
                recruitmentCaseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    recruitmentCaseDetail: recruitmentCaseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">求人案件管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>求人案件登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">求人案件登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_type_upload} err_detail={this.props.err_detail_upload} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR01", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR02", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)}>会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR03", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)}>条件・勤務形態</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMRMR04", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)}>待遇</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMRMR04", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)} tabIndex="8">戻る</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMRMR06", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)} tabIndex="7">次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" />
                                        </span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。
                                        </p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>施設紹介（写真）</span></h3>
                                        </div>
                                        <p className="hp_mb20">職場の魅力、仕事をイメージしやすく</p>
                                        {this.state.showImageArea ?
                                            <div className="bl_sect_box">
                                                {
                                                    this.state.imageCase.map((data, index) => (
                                                        <dl className="bl_upload" key={index}>
                                                            <dt className="bl_upload_ttl">
                                                                <span>{index + 1}枚目</span>
                                                                <span className="bl_upload_del">削除
                                                                    <img src={Close_Icon} alt="" onClick={() => this.handleImageDelete(index)} tabIndex="1" />
                                                                </span>
                                                            </dt>
                                                            <dd>
                                                                <div className="bl_upload_box">
                                                                    <figure className="bl_upload_img">
                                                                        <img src={data.image_content == null ? 'data:,' : data.image_content} alt="" />
                                                                    </figure>
                                                                    <label htmlFor={this.changeImgId(index)} className="el_smBlueBtn" tabIndex="2">画像を変更
                                                                        <input type="file" id={this.changeImgId(index)} className="el_sendFile" name="workplace_image_change" accept="image/*"
                                                                            onChange={(e) => this.handleImageUpload(e, index)} />
                                                                    </label>
                                                                </div>
                                                                <div className="bl_upload_desc">写真の説明</div>
                                                                <textarea className="el_textarea" rows="4" cols="80" name="workplace_image_comment" maxLength="370"
                                                                    value={data.image_comment} onChange={(e) => this.handleImageComment(e, index)} tabIndex="3" />
                                                            </dd>
                                                        </dl>
                                                    ))
                                                }
                                            </div>
                                            : null}
                                        <div style={{ display: this.state.imageCase.length >= 5 ? 'none' : 'block' }}>
                                            <div className="bl_horizBtnUnit">
                                                <label for="addImg" onClick={event => event.target.value = null} className="el_submitBtn el_submitBtn__plus" tabIndex="4">
                                                    写真を追加
                                                    <input type="file" id="addImg" className="el_sendFile" name="around_image_content" accept="image/*"
                                                        onChange={this.addingImageUpload} />
                                                </label>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>施設紹介（動画）</span></h3>
                                        </div>
                                        <p className="hp_mb20">職場の魅力、仕事をイメージしやすく</p>
                                        {facility_video_url_list.length > 0 ?
                                            <div className="bl_sect_box">
                                                {
                                                    checkArray(facility_video_url_list) ? (facility_video_url_list.map((data, index) => {
                                                        return (
                                                            <dl className="bl_upload_url" key={index}>
                                                                <dt className="bl_upload_ttl">
                                                                    <span>{index + 1}件目</span>
                                                                    <span className="bl_upload_del">削除
                                                                        <img src={Close_Icon} alt="" onClick={() => this.handleVideoDelete(index)} tabIndex="4" />
                                                                    </span>
                                                                </dt>
                                                                <dd>
                                                                    <div className="bl_upload_desc">動画URL</div>
                                                                    <TextInput type="text" maxlength="2000" onHandleChange={(k, v) => this.editItemHandlerByObject(index, k, v)} name="facility_video_url" className="el_input el_input__max" setValue={data.facility_video_url} tabIndex="5" />
                                                                    <div className="bl_upload_desc">動画の説明</div>
                                                                    <textarea className="el_textarea el_textarea_urlcomment" rows="4" cols="80" name="facility_video_url_comment" maxLength="370"
                                                                        value={data.video_comment} onChange={(e) => this.handleUrlComment(e, index)} tabIndex="6" />
                                                                </dd>
                                                            </dl>
                                                        );
                                                    })) : null
                                                }
                                            </div>
                                            : null}
                                        <div style={{ display: this.state.facility_video_url_list.length >= 5 ? 'none' : 'block' }}>
                                            <div className="bl_horizBtnUnit">
                                                <label htmlFor="addUrl" onClick={() => this.addVideoUrl()} className="el_submitBtn el_submitBtn__plus" tabIndex="7">動画を追加
                                                </label>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex="8">途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMRMR06", this.state.recruitmentCaseInfo, this.state.recruitmentCaseDetail)} tabIndex="9">次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createRecruitmentCase.isFetching,
        data: state.createRecruitmentCase.recruitmentCaseData,
        isError: state.createRecruitmentCase.isError,
        err_type: state.createRecruitmentCase.err_type,
        err_detail: state.createRecruitmentCase.err_detail,
        err_type_upload: state.uploadInfo.err_type,
        err_detail_upload: state.uploadInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createRecruitmentCase(arg) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCase(arg));
        },
        createRecruitmentCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createRecruitmentCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMRMR05);

