import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCognitoUserInfo, getCountryMasterList, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { isBlank, checkArray } from "../../Components/Tools/StringTools";
import {
    getUserManagementList,
    getUserManagementListReset,
    updateUserManagement,
    updateUserManagementReset,
    deleteUserManagement,
    deleteUserManagementReset
} from "./GCMOMORU01Action";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * ユーザ管理
 */
class GCMOMORU01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            country_code: "",
            organization_type: getCognitoUserInfo()["org_type"],
            organization_name: "",
            user_name: "",
            mail_address: "",
            selectedUserId: "",
            createOrUpdate: "",
            countryOpt: [],
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
        }), () => {
            this.handlingSearch();
        });
        window.scrollTo(0, 0);
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 検索処理
     */
    handlingSearch = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset());
        this.saveReset();
        this.setState({
            country_code: this.state.country_code,
            organization_type: this.state.organization_type,
            organization_name: this.state.organization_name,
            user_name: this.state.user_name,
            mail_address: this.state.mail_address,
            selectedUserId: "",
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getUserManagementList(this.state);
        });
    }

    /**
     * 組織選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        const { value } = event.target;

        this.setState({
            err_msg: [],
            selectedUserId: this.props.data.list[value].user_id,
            selectedUserData: this.props.data.list[value]
        });
    }

    /**
    * 新規登録
    */
    handlingSignUp = () => {
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 条件設定
        let parameterData = {
            user_id: '',
            organization_type: ''
        };

        this.props.updateUserManagement(parameterData);
    }

    /**
     * 組織修正
     */
    handlingModify = () => {
        window.scrollTo(0, 0);

        if (isBlank(this.state.selectedUserId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            // 編集画面へ移動する
            this.handleModifyOKAction();
        }
    }

    /**
     * 施設修正の処理を実行する
     */
    handleModifyOKAction = () => {
        const { selectedUserId, selectedUserData } = this.state;
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        // 条件設定
        let parameterData = {
            user_id: selectedUserId,
            organization_type: selectedUserData.organization_type
        };

        this.props.updateUserManagement(parameterData);
    }

    /**
     * 組織削除処理
     */
    handlingDelete = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        if (isBlank(this.state.selectedUserId)) {
            // 詳細データが選択されない場合、エラーになる
            this.setState({
                err_msg: [getMessage('RS-0026')]
            });
        } else {
            let message = getMessage('RS-0040');
            // 確認ダイアログメッセージボックスを表示する
            this.showModalDialog(1, message, this.handlingDeleteOKAction, this.handlingCancelAction);
        }
    }

    /**
     * 施設削除のを実行する
     */
    handlingDeleteOKAction = () => {
        const { selectedUserId, selectedUserData } = this.state;

        // 条件設定
        let parameterData = {
            user_id: selectedUserId,
            organization_type: selectedUserData.organization_type
        };

        this.props.deleteUserManagement(parameterData);

    }

    /**
     * キャンセル処理
     */
    handlingCancelAction = () => { }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.userManagementListReset();
        this.props.updateUserManagementReset();
        this.props.deleteUserManagementReset();
    }

    render() {

        // 組織タイプ
        const organizationType = getCodeValueLabelList('RSC0030').filter(v => v.value == getCognitoUserInfo()["org_type"]
            || getCognitoUserInfo()["org_type"] == 'COD'
            || (getCognitoUserInfo()["org_type"] == 'KAN' && v.value == 'COP')
            || (getCognitoUserInfo()["org_type"] == 'TOR' && v.value == 'COP')
            || (getCognitoUserInfo()["org_type"] == 'OKR' && v.value == 'UNI'));

        if (this.props.deleteErr_type === '200') {
            this.props.deleteUserManagementReset();
            if (this.props.deleteData['have_candidate_info'] === '1') {
                this.setState({
                    err_msg: [getMessage('RS-0072')]
                });
            } else if (this.props.deleteData['have_candidate_info'] != '1') {
                // 削除成功の場合、再検索する
                this.handlingSearch();
            }
        }

        // ユーザ情報登録・訂正画面に移動
        if (this.props.updateData && this.props.updateErr_type === '200') {
            this.props.changeDispWithData("GCMOMORU02", this.props.updateData);
        }

        // 件数が100件を超える場合
        if (this.props.data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">ユーザ管理</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>ユーザ管理</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.updateErr_type} err_detail={this.props.updateErr_detail} messageMap={{}} />
                                <ErrorMessage err_type={this.props.deleteErr_type} err_detail={this.props.deleteErr_detail} messageMap={{}} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={this.toggleClassAction} tabIndex="1">検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>国</th>
                                                    <td>
                                                        <select className="el_select" name="country_code" value={this.state.country_code} onChange={this.handleChange} tabIndex="2">
                                                            <option value="" selected></option>
                                                            {
                                                                this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                    <option key={item.country_number} value={item.country_code} selected={this.state.country_code === item.country_code}>
                                                                        {item.country_name}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>組織タイプ</th>
                                                    <td>
                                                        <select className="el_select" name="organization_type" value={this.state.organization_type} onChange={this.handleChange} disabled={!isBlank(this.state.organization_id)} tabIndex="3">
                                                            {
                                                                organizationType && organizationType.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.organization_type === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>組織名</th>
                                                    <td>
                                                        <TextInput type="text" name="organization_name" className="el_input el_input__max" maxlength="60" setValue={this.state.organization_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ユーザ名</th>
                                                    <td>
                                                        <TextInput type="text" name="user_name" className="el_input el_input__max" maxlength="60" setValue={this.state.user_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>メールアドレス</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="mail_address" className="el_input el_input__max" setValue={this.state.mail_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="search" className="el_blueBtn el_shadowBtn" onClick={() => this.handlingSearch()} tabIndex="7">検索</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="bl_sect_header">
                                    <h2 className="el_lv3Heading"><span>ユーザ一覧</span></h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect">
                                        <div className="bl_horizBtnUnit__move hp_mb20">
                                            <button type="button" name="signUp" className="el_blueBtn el_shadowBtn hp_mr15" onClick={() => this.handlingSignUp()} tabIndex="8">新規登録</button>
                                            <button type="button" name="modify" className="el_blueBtn el_shadowBtn hp_mr15" onClick={() => this.handlingModify()} tabIndex="9">修正​</button>
                                            <button type="button" name="delete" className="el_btn el_shadowBtn" onClick={() => this.handlingDelete()} tabIndex="10">削除</button>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>選択</th>
                                                        <th>国</th>
                                                        <th>組織タイプ</th>
                                                        <th>組織名</th>
                                                        <th>ユーザ名</th>
                                                        <th>メールアドレス</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data.list)) {
                                                            return (
                                                                this.props.data.list.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="hp_tac">
                                                                            <input type="radio" name="row_select" value={index} onChange={this.handleRowSelection} tabIndex={11 + index} />
                                                                        </td>
                                                                        <td>{item.country_name}</td>
                                                                        <td>{item.organization_type_name}</td>
                                                                        <td>{item.organization_id_name}</td>
                                                                        <td>{item.user_name}</td>
                                                                        <td>{item.user_mail_address}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getUserManagementList.isFetching,
        data: state.getUserManagementList.data,
        isError: state.getUserManagementList.isError,
        err_type: state.getUserManagementList.err_type,
        err_detail: state.getUserManagementList.err_detail,
        isUpdateFetching: state.updateUserManagement.isFetching,
        updateData: state.updateUserManagement.data,
        isUpdateError: state.updateUserManagement.isError,
        updateErr_type: state.updateUserManagement.err_type,
        updateErr_detail: state.updateUserManagement.err_detail,
        isDeleteFetching: state.deleteUserManagement.isFetching,
        deleteData: state.deleteUserManagement.data,
        isDeleteError: state.deleteUserManagement.isError,
        deleteErr_type: state.deleteUserManagement.err_type,
        deleteErr_detail: state.deleteUserManagement.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getUserManagementList(args) {
            // ユーザ管理情報取得
            dispatch(getUserManagementList(args));
        },
        userManagementListReset() {
            // ユーザ管理情報取得
            dispatch(getUserManagementListReset());
        },
        updateUserManagement(args) {
            // ユーザ管理情報取得
            dispatch(updateUserManagement(args));
        },
        updateUserManagementReset() {
            // ユーザ管理情報取得
            dispatch(updateUserManagementReset());
        },
        deleteUserManagement(args) {
            // ユーザ管理情報取得
            dispatch(deleteUserManagement(args));
        },
        deleteUserManagementReset() {
            // ユーザ管理情報取得
            dispatch(deleteUserManagementReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMORU01);

