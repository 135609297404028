import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCRU08ActionType';

// 組織情報(プロフィール)インターン向けAPI
const GET_EMPLOY_FACILITY_INFO_POST = BASE_URL + "/api/cm_om_get_employ_facility_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetEmployFacilityInfoRequest = () => {
    return {
        type: actionTypes.GET_EMPLOY_FACILITY_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetEmployFacilityInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_EMPLOY_FACILITY_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetEmployFacilityInfoFailure = (err) => {
    return {
        type: actionTypes.GET_EMPLOY_FACILITY_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetEmployFacilityInfoReset = () => {
    return {
        type: actionTypes.GET_EMPLOY_FACILITY_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getEmployFacilityInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetEmployFacilityInfoRequest());
        ecoPostWithAuthHeader(GET_EMPLOY_FACILITY_INFO_POST).then(
            res => dispatch(GetEmployFacilityInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetEmployFacilityInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getEmployFacilityInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetEmployFacilityInfoReset());
    };
};