import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GESSFAUS01ActionType';

// 送り出し機関・大学利用実績一覧取得API
const GET_OKR_UNI_USAGE_RESULTS_LIST_POST = BASE_URL + "/api/es_sf_get_okr_uni_usage_results_list";

// ********************Actionの作成 Start ********************
/**
 * 送り出し機関・大学利用実績一覧取得
 */
export const GetOkrUniUsageResultsListRequest = () => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_REQUEST
    };
};

/**
 * 送り出し機関・大学利用実績一覧取得成功
 */
export const GetOkrUniUsageResultsListSuccess = (data) => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_SUCCESS,
        data
    };
};

/**
 * 送り出し機関・大学利用実績一覧取得失敗
 */
export const GetOkrUniUsageResultsListFailure = (err) => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_FAILURE,
        err
    };
};

/**
 * 送り出し機関・大学利用実績一覧取得リセット
 */
export const GetOkrUniUsageResultsListReset = () => {
    return {
        type: actionTypes.GET_OKR_UNI_USAGE_RESULTS_LIST_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 送り出し機関・大学利用実績一覧取得Actionの発行
 */
export const getOkrUniUsageResultsList = (data) => {
    return async (dispatch) => {
        // Storeへ送り出し機関・大学利用実績一覧取得リクエストActionをdispatchする
        dispatch(GetOkrUniUsageResultsListRequest());
        ecoPostWithAuthHeader(GET_OKR_UNI_USAGE_RESULTS_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ送り出し機関・大学利用実績一覧取得成功Actionをdispatchする
                dispatch(GetOkrUniUsageResultsListSuccess(res.data))
            ).catch(err =>
                // Storeへ送り出し機関・大学利用実績一覧取得失敗Actionをdispatchする
                dispatch(GetOkrUniUsageResultsListFailure(err))
            );
    };
};