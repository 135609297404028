import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { getCandidateList, GetCandidateListReset, getCandidateDetail, getCandidateDetailReset, checkSelectedCandidateList, CheckSelectedCandidateListReset } from "../MmGRSMMAMF01/GRSMMAMF01Action";
import { CandidateReset } from "../MmGRSMMERF01/GRSMMERF01Action";
import { connect } from "react-redux";
import { getCountryMasterList } from "../../Constants/CommonConstants";
import { MessageMap } from "./GRSMMAMF01Message";
import { getEntryList } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 応募者検索画面
 */
class GRSMMAMF01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            candidateName: "",
            candidate_sex: "",
            gender: [],
            caseType: [],
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
            country: {},
            selectedCandidate: [],
            caseDetail: this.props.caseDetail ? this.props.caseDetail : {},
            entryList: this.props.entryList ? this.props.entryList : [],
            candidateList: this.props.candidateList ? this.props.candidateList : [],
            beforeDispId: this.props.beforeDispId,
            statusOfResidence: this.props.statusOfResidence ? this.props.statusOfResidence : "",
            selectedCandidateList: this.props.selectedCandidateList ? this.props.selectedCandidateList.splice() : [],
            returnSelectedCandidate: this.props.selectedCandidateList ? this.props.selectedCandidateList : [],

        };
        this.setCaseType = this.setCaseType.bind(this);
    }

    componentDidMount = () => {
        this.props.saveCandidateReset();
        if (this.props.beforeDispId && (this.props.beforeDispId == "GRSMMIMD02" || this.props.beforeDispId == "GRSMMAMF02")) {
            // インターン案件詳細（案件情報）画面から遷移した場合
            // 非活性
            this.setState({
                disabled: "disabled"
            }, () => {
                // データを再検索
                this.handleToQuery();
            });
        } else if (this.props.beforeDispId && this.props.beforeDispId === "GRSMMAMF02") {
            // 応募者詳細画面から遷移した場合
            this.setState({
                disabled: "disabled"
            }, () => {
                // データを再検索
                this.handleToQuery();
            });
        } else if (this.props.beforeDispId && this.props.beforeDispId === "CmTemplate") {
            // メニューから遷移した場合
            // 活性
            this.setState({
                disabled: ""
            }, () => {
                // 応募者リスト取得状態リセット
                this.props.resetCandidateListState();
            });
        }

        // 性別
        const genderRet = getCodeValueLabelList('RSC0007');
        this.setState(prevState => ({
            gender: [...prevState.gender, ...genderRet]
        }));

        let country_temp = {};
        Object.entries(getCountryMasterList()).map(([k, v]) => {
            country_temp[v.country_code] = v.country_name;
        });

        // 画面項目初期化
        this.setState({
            country: country_temp,
            caseDetail: this.props.caseDetail ? this.props.caseDetail : {},
            entryList: this.props.entryList ? this.props.entryList : [],
            candidateList: this.props.candidateList ? this.props.candidateList : [],
            beforeDispId: this.props.beforeDispId
        });

        if (this.props.caseDetail && this.props.caseDetail.status_of_residence === "0") {
            // 資格_技能実習
            this.state.caseType[0] = true;
        } else if (this.props.caseDetail && this.props.caseDetail.status_of_residence === "1") {
            // 資格_特定技能
            this.state.caseType[1] = true;
        } else if (this.props.caseDetail && this.props.caseDetail.status_of_residence === "2") {
            // 資格_インターン
            this.state.caseType[2] = true;
        }
        this.handleToQuery();
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        this.state.caseType[index] = event.target.checked;
        this.setState({
            caseType: this.state.caseType
        });
    };

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 検索処理
     */
    handleToQuery = () => {
        window.scrollTo(0, 0);
        // パラメータの設定
        let searchPara = {};
        // 氏名（ローマ字）
        searchPara['candidate_name'] = this.state.candidateName;
        // 性別
        searchPara['candidate_sex'] = this.state.candidate_sex;
        // 在留資格
        let casetype = "";
        this.state.caseType.map((key, value) => {
            if (key) {
                casetype = casetype + value + ",";
            }
        });
        if (casetype.length > 0) {
            casetype = casetype.substr(0, casetype.length - 1);
        }
        searchPara['status_of_residence'] = casetype;
        this.setState({
            condition: searchPara,
            selectedCandidate: [],
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getCandidateList(this.state.condition);
        });
    }

    handleToDisplayWithData = (candidateId) => {
        window.scrollTo(0, 0);
        // パラメータの設定
        this.setState({ err_msg: [] });
        if (this.state.beforeDispId == "CmTemplate") {
            // 応募者編集画面へ遷移する
            this.props.toEditProfile(candidateId);
        } else {
            // 応募者詳細取得
            this.props.getCandidateDetail(candidateId);
        }
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    switchDisplaySelectedCandidate = (changeTo, caseDetail, candidateList, selectedCandidate, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
            // this.props.getEntryListReset();
        }
        return this.props.switchDisplaySelectedCandidate(changeTo, caseDetail, candidateList, selectedCandidate);
    }

    /**
     * 性別を設定する
     * @param {*} event 性別イベント対象
     */
    handleGenderChange = (event) => {
        this.setState({
            candidate_sex: event.target.value
        });
    };

    /**
     * 応募者選択
     */
    handleSelection = () => {
        window.scrollTo(0, 0);
        this.props.getCandidateDetailReset();
        if (this.state.selectedCandidate.length == 0) {
            // 詳細データが選択されない場合、「インターン案件詳細（エントリー情報）」画面に遷移する。
            this.switchDisplaySelectedCandidate("GRSMMIMD02", this.state.caseDetail, this.state.candidateList, [], false);
        } else {
            let selectedCandidateIdList = [];
            // 新しく選択されたデータのチェック
            const value = Object.entries(this.state.returnSelectedCandidate).map(([k, v]) => {
                return v.candidate_id;
            });

            // 応募中データのチェック
            const valuecheck = Object.entries(this.props.candidateList).map(([k, v]) => {
                return v.candidate_id;
            });

            // 重複チェックを行う
            this.props.data.map(item => {
                // 選択データを繰り返す
                if (this.state.selectedCandidate.indexOf(item.candidate_id) !== -1) {
                    // 新しく選択されたデータが重複しているかを確認
                    const includeinselected = value.includes(item.candidate_id);
                    // 重複しない場合
                    if (includeinselected === false) {
                        // 応募中データと重複するかを確認
                        const includeincheck = valuecheck.includes(item.candidate_id);
                        // 重複している場合
                        if (includeincheck === false) {
                            // 重複しない場合、データを先頭に追加
                            this.state.returnSelectedCandidate.unshift(item);
                            // ステータスチェック対象追加
                            selectedCandidateIdList.push(item.candidate_id);
                            // stateの更新
                            this.setState({
                                returnSelectedCandidate: this.state.returnSelectedCandidate
                            });
                        } else {
                            // エラーになる
                            // console.log("Error", item.candidate_id);
                        }
                    }
                }
            });
            // 応募者ステータスチェック
            this.props.checkSelectedCandidateList({
                case_id: this.state.caseDetail.case_id,
                candidate_id_list: selectedCandidateIdList
            });

        }
    }

    /**
     * 応募者選択
     * @param {*} event 選択された対象
     */
    handleRowSelection = (event) => {
        const { selectedCandidate } = this.state;
        if (event.target.checked) {
            selectedCandidate.push(event.target.value);
        } else {
            const presentIndex = [...selectedCandidate].indexOf(event.target.value);
            selectedCandidate.splice(presentIndex, 1);
        }
        this.setState({
            selectedCandidate: selectedCandidate
        });
    }

    /**
     * 応募者ステータスチェック処理
     */
    handleCheckCandidateStatus = () => {
        let errList = this.props.candidateStatus.err_list;
        let warList = this.props.candidateStatus.war_list;
        // 応募者ステータスチェック結果リセット
        this.props.resetCheckSelectedCandidateList();
        if (errList && errList.length > 0) {
            // 応募者ステータスのエラーを表示する
            this.showWarningDialog(3, errList[0].warn_code, errList);
        } else if (warList && warList.length > 0) {
            // 応募者ステータスの警告を表示する
            this.showWarningDialog(1, warList[0].warn_code, warList);
        } else {
            // エントリ画面に遷移
            this.switchDisplaySelectedCandidateToEntry();
        }
    }

    /**
     * 応募者ステータスの警告・エラーを表示する
     */
    showWarningDialog = (errType, messageId, candidateList) => {
        let name = '';
        let nameArry = [];
        let idArry = [];
        candidateList.forEach(candidate => {
            this.state.returnSelectedCandidate.forEach(item => {
                if (item.candidate_id == candidate.candidate_id && messageId == candidate.warn_code) {
                    if (idArry.indexOf(candidate.candidate_id) == -1) {
                        // 応募者名設定
                        nameArry.push(item.candidate_name);
                        idArry.push(candidate.candidate_id);
                    }
                }
            });
        });
        name = nameArry.join(',');
        const content = (<div>{getMessage(messageId)}<br></br><br></br>該当者：「{name}」</div>);
        if (errType == 3) {
            this.clearReturnSelectedCandidate();
            // エラー表示、遷移できません
            this.showModalDialog(errType, content, function () { });
        } else {
            // 確認メッセージ表示
            this.showModalDialog(errType, content, this.switchDisplaySelectedCandidateToEntry, this.clearReturnSelectedCandidate);
        }

    }
    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            if (changeTo === 'GRSMMMLI01') {
                this.props.getCaseDetailReset();
                this.props.getEntryListReset();
            }
            this.props.resetCandidateListState();
        }
        return this.props.switchDisplaySelectedCandidate(changeTo, this.state.caseDetail, this.state.candidateList);
    }

    /**
     * エントリ画面に遷移
     */
    switchDisplaySelectedCandidateToEntry = () => {
        // エントリ画面に遷移
        this.switchDisplaySelectedCandidate("GRSMMIMD02", this.state.caseDetail, this.state.candidateList, this.state.returnSelectedCandidate, false);
    }

    /**
     * 選択した応募者リストをクリアする
     */
    clearReturnSelectedCandidate = () => {
        this.setState({ returnSelectedCandidate: this.state.selectedCandidateList.splice() });
    }

    render() {
        // エントリ画面に移動
        if (this.props.detailData && this.props.err_typeDetail === '200' & this.state.beforeDispId != "CmTemplate") {
            this.props.skipToNext("GRSMMAMF02", this.props.detailData, "GRSMMAMF01");
        }
        // ステータスチェック
        if (this.props.err_candidate_type === '200') {
            this.handleCheckCandidateStatus();
        }
        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                応募者情報
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                {
                                    (this.state.beforeDispId == "GRSMMIMD02" || this.state.beforeDispId == "GRSMMAMF02") ?
                                        <Fragment>
                                            <li>
                                                <a href="#!" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a>
                                            </li>
                                            <li>
                                                <a href="#!" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>案件詳細</a>
                                            </li>
                                        </Fragment>
                                        :
                                        null
                                }
                                <li>
                                    応募者一覧
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_typeDetail} err_detail={this.props.err_detailDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.err_entry_type} err_detail={this.props.err_entry_detail} messageMap={MessageMap} />
                                {
                                    (
                                        () => {
                                            return (
                                                this.state.err_msg.length > 0 ? <div id='errorarea'>
                                                    <ul className='errorArea'>
                                                        {
                                                            Object.entries(this.state.err_msg).map(
                                                                ([key]) => (
                                                                    <li key={key}>{this.state.err_msg[key]}</li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </div> : null
                                            );
                                        }
                                    )()
                                }
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>氏名（ローマ字）</th>
                                                    <td>
                                                        <TextInput type="text" className="el_input el_input__max" maxlength="40" name="candidateName" setValue={this.state.candidateName} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>性別</th>
                                                    <td>
                                                        <select className="el_select" name="candidate_sex" onChange={this.handleGenderChange} tabIndex="2">
                                                            <option value="" selected></option>
                                                            {
                                                                this.state.gender.map(item => {
                                                                    if (item.value != "N") {
                                                                        return <option key={item.value} value={item.value} selected={this.state.candidate_sex == item.value}>
                                                                            {item.label}
                                                                        </option>;
                                                                    }
                                                                })
                                                            };
                                                            </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} disabled={this.state.disabled} />
                                                                <span className="el_checkbox_txt">技能実習</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} disabled={this.state.disabled} />
                                                                <span className="el_checkbox_txt">特定技能</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input className="el_checkbox" type="checkbox" name="casetype" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} disabled={this.state.disabled} />
                                                                <span className="el_checkbox_txt">インターン</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_blueBtn el_shadowBtn" buttonEnable={true} onClick={() => this.handleToQuery()}>検索</button>
                                        </div>
                                    </div>
                                    <section className="bl_sect bl_sect_applicantList">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv3Heading"><span>応募者一覧</span></h2>
                                        </div>
                                        {
                                            (this.state.beforeDispId == "GRSMMIMD02" || this.state.beforeDispId == "GRSMMAMF02") ?
                                                <div className="bl_horizBtnUnit__move">
                                                    <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.handleSelection()}>選択</button>
                                                </div>
                                                :
                                                <div className="bl_horizBtnUnit__move">
                                                    <button type="button" className="el_blueBtn el_shadowBtn is_disabled">選択</button>
                                                </div>
                                        }
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr>
                                                        {/* {
                                                            this.state.beforeDispId == "GRSMMIMD02" ? <th>選択</th> : null
                                                        } */}
                                                        <th>選択</th><th>氏名（ローマ字）</th><th>国籍</th><th>生年月日</th><th>性別</th><th>日本語レベル</th><th>在留資格</th><th>受入企業</th><th>受入・終了年月</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (this.props.data !== undefined && this.props.data.length > 0) {
                                                            return (
                                                                this.props.data.map((item) => (
                                                                    <tr key={item.candidate_id}>
                                                                        {
                                                                            (this.state.beforeDispId == "GRSMMIMD02" || this.state.beforeDispId == "GRSMMAMF02") ? <td>
                                                                                <label className="el_label el_label__center">
                                                                                    <input className="el_checkbox" type="checkbox" name="row_select" value={item.candidate_id} onChange={this.handleRowSelection} /><span className="el_checkbox_txt el_checkbox_txt__solo"></span>
                                                                                </label>
                                                                            </td> :
                                                                                <td>
                                                                                    <label className="el_label el_label__center">
                                                                                        <input className="el_checkbox" disabled type="checkbox" name="row_select" /><span className="el_checkbox_txt el_checkbox_txt__solo"></span>
                                                                                    </label>
                                                                                </td>
                                                                        }
                                                                        <td>
                                                                            <a href="#" className="el_link" onClick={() => this.handleToDisplayWithData(item.candidate_id)}>
                                                                                {item.candidate_name}
                                                                            </a>
                                                                        </td>
                                                                        <td>{this.state.country[item.born_country_code]}</td>
                                                                        <td>{item.candidate_birthday}</td>
                                                                        <td>{item.candidate_sex_name}</td>
                                                                        <td>{item.candidate_japanese_level_name}</td>
                                                                        <td>{item.status_of_residence_name}</td>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{item.accept_period_end}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </main >
            </article >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.getCandidateList.isFetching,
        data: state.getCandidateList.overViewData,
        isError: state.getCandidateList.isError,
        err_type: state.getCandidateList.err_type,
        err_detail: state.getCandidateList.err_detail,
        isDetailFetching: state.getCandidateDetail.isFetching,
        detailData: state.getCandidateDetail.data,
        isDetailError: state.getCandidateDetail.isError,
        err_typeDetail: state.getCandidateDetail.err_type,
        err_detailDetail: state.getCandidateDetail.err_detail,
        isEntryFetching: state.getEntryList.isFetching,
        entryList: state.getEntryList.overViewData,
        isEntryError: state.getEntryList.isError,
        err_entry_type: state.getEntryList.err_type,
        err_entry_detail: state.getEntryList.err_detail,
        candidateStatus: state.checkSelectedCandidateStatus.data,
        err_candidate_type: state.checkSelectedCandidateStatus.err_type
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getCandidateList(condition) {
            // 応募者一覧情報取得
            dispatch(getCandidateList(condition));
        },
        getCandidateDetail(condition) {
            // 応募者詳細取得
            dispatch(getCandidateDetail(condition));
        },
        getEntryList(condition) {
            // エントリリスト取得
            dispatch(getEntryList(condition));
        },
        getCandidateDetailReset() {
            dispatch(getCandidateDetailReset);
        },
        resetCandidateListState() {
            dispatch(GetCandidateListReset());
        },
        checkSelectedCandidateList(condition) {
            dispatch(checkSelectedCandidateList(condition));
        },
        resetCheckSelectedCandidateList() {
            dispatch(CheckSelectedCandidateListReset());
        },
        saveCandidateReset() {
            dispatch(CandidateReset());
        }

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(GRSMMAMF01);

