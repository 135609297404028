/**
 * Action Typesを定義
 */
const GCMOMCRU04ActionType = {
    GET_UNIVERSITY_INFO_REQUEST: 'GET_UNIVERSITY_INFO_REQUEST',  // 組織情報(プロフィール)大学取得定義
    GET_UNIVERSITY_INFO_SUCCESS: 'GET_UNIVERSITY_INFO_SUCCESS',  // 組織情報(プロフィール)大学取得成功定義
    GET_UNIVERSITY_INFO_FAILURE: 'GET_UNIVERSITY_INFO_FAILURE',  // 組織情報(プロフィール)大学取得失敗定義
    GET_UNIVERSITY_INFO_RESET: 'GET_UNIVERSITY_INFO_RESET',       // 組織情報(プロフィール)大学取得リセット定義
    UPDATE_UNIVERSITY_INFO_REQUEST: 'UPDATE_UNIVERSITY_INFO_REQUEST',  // 組織情報(プロフィール)大学更新定義
    UPDATE_UNIVERSITY_INFO_SUCCESS: 'UPDATE_UNIVERSITY_INFO_SUCCESS',  // 組織情報(プロフィール)大学更新成功定義
    UPDATE_UNIVERSITY_INFO_FAILURE: 'UPDATE_UNIVERSITY_INFO_FAILURE',  // 組織情報(プロフィール)大学更新失敗定義
    UPDATE_UNIVERSITY_INFO_RESET: 'UPDATE_UNIVERSITY_INFO_RESET'       // 組織情報(プロフィール)大学更新リセット定義
};

export default GCMOMCRU04ActionType;
