/**
 * Action Typesを定義
 */
const GRSMMRMR01ActionType = {
    CREATE_RECRUITMENT_CASE_REQUEST: 'CREATE_RECRUITMENT_CASE_REQUEST',
    CREATE_RECRUITMENT_CASE_SUCCESS: 'CREATE_RECRUITMENT_CASE_SUCCESS',
    CREATE_RECRUITMENT_CASE_FAILURE: 'CREATE_RECRUITMENT_CASE_FAILURE',
    CREATE_RECRUITMENT_CASE_RESET: 'CREATE_RECRUITMENT_CASE_RESET',
    CREATE_RECRUITMENT_CASE_RESET_ALL_DATA: 'CREATE_RECRUITMENT_CASE_RESET_ALL_DATA'
};

export default GRSMMRMR01ActionType;