// メッセージMap
export const MessageMap = {
    "work_start_time": "開始時間",
    "work_end_time": "終了時間",
    "rest_minute": "休憩時間",
    "work_minute": "勤務時間",
    "late_early_flg": "遅刻／早退",
    'approval_chk': "承認",
    "chao_chk": "差戻",
    "rest_start_time": "休憩開始時間",
    "rest_end_time": "休憩終了時間"

};
