import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { checkArray } from "../../Components/Tools/StringTools";
import { getMessage } from "../../Constants/CommonConstants";
import {
    searchInsuranceApplicationList,
    searchInsuranceApplicationListReset,
    updateInsuranceApplicationList,
    updateInsuranceApplicationListReset,
    searchInsuranceApplicationListUpdate
} from "./GICIAIAS01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 保険申込一覧画面
 */
class GICIAIAS01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            insurance_kind: "",
            insurance_course_cd: "",
            applyDateArr: [],
            insuranceType: null,
            toggleclass: "bl_sect_frame bl_sect_frame_narrow",
            searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        // 申込年月ドロップダウン
        let ymOption = [
            moment().subtract(2, 'M').format('YYYY/MM'),
            moment().subtract(1, 'M').format('YYYY/MM'),
            moment().format('YYYY/MM'),
            moment().add(1, 'M').format('YYYY/MM')
        ];

        this.setState({
            applyDateArr: ymOption,
            apply_date: moment().subtract(1, 'M').format('YYYY/MM')
        });
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * 画面部品編集イベント
     * @param {*} event
     */
    inputChangeHandler = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
    }

    /**
     * 申込種別変更
     */
    insuranceKindChangeHandler = index => async (event) => {
        let data = this.props.data;
        const { value } = event.target;
        data.list[index].insurance_kind = value;
        await this.props.updateInsuranceApplicationData(data);
        this.forceUpdate();
    }

    /**
    * 申込プラン変更
    * @param {*} index
    */
    insuranceCourseChangeHandler = index => async (event) => {
        const { value } = event.target;
        let data = this.props.data;

        // 申込プラン
        data.list[index].insurance_course_cd = value;

        // 保険の種類
        const insuranceKind = data.list[index].insurance_kind;

        // 保険コースコードマスタより契約期間月取得
        const monthMasterObj = data.course_month_map[insuranceKind];
        const periodMonth = monthMasterObj[value];

        // 保険期間の開始日
        data.list[index].insurance_period_start.forEach(item => {

            // 保険期間の開始日の月 + 保険コードマスタ.契約期間月
            let newDate = moment(item).add(periodMonth, 'months').format('YYYY/MM/DD');

            // 保険期間の開始日の年
            let year = moment(newDate, 'YYYY/MM/DD').year();
            // 保険期間の開始日の月
            let month = 1 + moment(newDate, 'YYYY/MM/DD').month();
            // 保険期間の開始日の日
            let day = moment(newDate, 'YYYY/MM/DD').date();

            if (month > 12) {
                year = year + 1;
                month = month - 12;
            }

            let shortDate = year + '/' + month + '/' + day;

            // [保険期間の開始日の月 + 保険コードマスタ.契約期間月] - 1日
            let duration = moment(shortDate).subtract(1, 'days').format('YYYY/MM/DD');

            if ([...data.list[index].insurance_period_end].length > 1) {
                const exceptLast = [...data.list[index].insurance_period_end].slice(0, data.list[index].insurance_period_end.length - 1);
                exceptLast.push(duration);
                data.list[index].insurance_period_end = [...exceptLast];
            } else {
                data.list[index].insurance_period_end[0] = duration;
            }
        });
        await this.props.updateInsuranceApplicationData(data);
        this.forceUpdate();
    }

    /**
     * 「申込」処理
     */
    applicationHandler = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.insuranceApplicationListReset);

        let data = this.props.data;
        data.list.forEach(item => {
            item.responsibility_start = [...item.insurance_period_start].pop();
            item.responsibility_end = [...item.insurance_period_end].pop();
        });

        let param = {};
        param['apply_date'] = this.state.apply_date;
        param['insurance_list'] = data.list;

        this.props.updateInsuranceApplicationList(param);
    }

    /**
     * 保険申込一覧検索
     */
    searchHandle = () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.props.insuranceApplicationListReset);
        this.props.insuranceApplicationListInfo(this.state);
        this.setState({
            search_dapply_date: this.state.apply_date
        });
    }

    /**
     *
     * @param {*} nextProps
     */
    componentWillReceiveProps(nextProps) {
        const { course_master_map, type_master_map } = nextProps.data;

        if (course_master_map !== undefined) {

            // 保険種類マスタより取得
            const insuranceTypeRet = Object.keys(type_master_map).map(key => {
                return {
                    value: key,
                    label: type_master_map[key]
                };
            });

            if (!this.state.insuranceType) {
                this.setState({
                    insuranceType: insuranceTypeRet
                });
            }
        }
    }

    /**
     *
     * @param {*} insuranceKind
     */
    getInsuranceCourse = (insuranceKind) => {
        const { course_master_map } = this.props.data;
        if (this.props.data && course_master_map !== undefined && insuranceKind !== "") {
            // 保険コースコードマスタより取得
            const courseMasterObj = course_master_map[insuranceKind];
            const insuranceCourseRet = Object.keys(courseMasterObj).map(key => {
                return {
                    value: key,
                    label: courseMasterObj[key]
                };
            });
            return insuranceCourseRet;
        }
    }

    /**
     * 保険期間レンダリング処理
     * @param {*} item
     */
    renderApplicationPlanChange = (item) => {
        if (item.insurance_period_start.length > 1 && item.insurance_period_end.length > 1) {
            const exceptLastPeriodStart = [...item.insurance_period_start].slice(0, item.insurance_period_start.length - 1);
            const exceptLastPeriodEnd = [...item.insurance_period_end].slice(0, item.insurance_period_end.length - 1);
            const insurancePperiod = exceptLastPeriodStart.map((start, index) => {
                const end = exceptLastPeriodEnd[index];
                return (
                    <p>{start}〜{end}</p>
                );
            });
            return (
                <>
                    {insurancePperiod}
                    <p className="el_txtRed">{[...item.insurance_period_start].pop()}〜{[...item.insurance_period_end].pop()}</p>
                </>
            );
        } else {
            return <p className="el_txtRed">{item.insurance_period_start[0]}〜{item.insurance_period_end[0]}</p>;
        }
    }

    render() {
        const { havehistoryflg, list, now_before_month } = this.props.data;

        if (this.props.update_err_type === '200') {
            this.showModalDialog(0, getMessage('RS-0036'), () => {
                this.props.updateInsuranceApplicationListReset();
                this.searchHandle();
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">保険申込一覧</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li>保険申込一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} />
                                <ErrorMessage err_type={this.props.update_err_type} err_detail={this.props.update_err_detail} />
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button className={this.state.searchbtnclass} onClick={this.toggleClassAction}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>申込年月</th>
                                                    <td>
                                                        <select className="el_select" name="apply_date" value={this.state.apply_date} onChange={this.inputChangeHandler}>
                                                            {
                                                                this.state.applyDateArr.map((item, index) => (
                                                                    <option key={index} value={item}>
                                                                        {item}
                                                                    </option>
                                                                ))
                                                            };
                                                        </select>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={this.searchHandle}>検索</button>
                                        </div>
                                    </div>
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv3Heading"><span>保険申込対象者一覧</span></h2>
                                    </div>
                                    <div className="bl_sect_inner">
                                        <section className="bl_sect bl_sect_applicantList">
                                            <div className="bl_horizBtnUnit__move">
                                                <button type="button" className={(this.state.search_dapply_date === now_before_month && havehistoryflg === '0' && list.length !== 0) ? "el_btn el_shadowBtn hp_mb20" : "el_blueBtn is_disabled"} onClick={this.applicationHandler}>
                                                    {havehistoryflg === '1' ? '申込済' : '申込'}
                                                </button>
                                            </div>
                                            <div className="sticky_table_wrapper">
                                                <table className="sticky_table mcTable_align">
                                                    <thead>
                                                        <tr>
                                                            <th rowSpan="2">申込種別</th>
                                                            <th rowSpan="2">ステータス</th>
                                                            <th rowSpan="2">在留資格</th>
                                                            <th>保険の種類</th>
                                                            <th>保険期間</th>
                                                            <th>氏名</th>
                                                            <th>企業名</th>
                                                        </tr>
                                                        <tr>
                                                            <th rowSpan="2">申込プラン</th>
                                                            <th rowSpan="2">滞在期間</th>
                                                            <th>受入・就業期間</th>
                                                            <th>施設名</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            if (checkArray(list)) {
                                                                return (
                                                                    list.map((item, index) => (
                                                                        <Fragment key={index}>
                                                                            <tr>
                                                                                <td rowSpan="2">
                                                                                    {havehistoryflg === '1'
                                                                                        ? (item.keizoku_flg === '0' ? '新規加入' : '継続加入')
                                                                                        : (item.data_from === 'adoptiony_table' ? '新規加入' : '継続加入')}
                                                                                </td>
                                                                                <td rowSpan="2">{havehistoryflg === '1' ? '申込済み' : '無し'}</td>
                                                                                <td rowSpan="2">{item.status_of_residence_name}</td>
                                                                                <td className="hp_tac">
                                                                                    <select className="el_select" name="insurance_kind" onChange={this.insuranceKindChangeHandler(index)} disabled={havehistoryflg === '1' ? true : false}>
                                                                                        {
                                                                                            this.state.insuranceType && this.state.insuranceType.map(data => (
                                                                                                <option key={data.value} value={data.value} selected={item.insurance_kind === data.value}>
                                                                                                    {data.label}
                                                                                                </option>
                                                                                            ))
                                                                                        };
                                                                                    </select>
                                                                                </td>
                                                                                <td>
                                                                                    {this.renderApplicationPlanChange(item)}
                                                                                </td>
                                                                                <td>{item.employment_name_kana}</td>
                                                                                <td>{item.company_name}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="hp_tac">
                                                                                    <select className="el_select" name="insurance_course_cd" onChange={this.insuranceCourseChangeHandler(index)} disabled={havehistoryflg === '1' ? true : false}>
                                                                                        {
                                                                                            this.getInsuranceCourse(item.insurance_kind) && this.getInsuranceCourse(item.insurance_kind).map(data => (
                                                                                                <option key={data.value} value={data.value} selected={item.insurance_course_cd === data.value}>
                                                                                                    {data.label}
                                                                                                </option>
                                                                                            ))
                                                                                        };
                                                                                        </select>
                                                                                </td>
                                                                                <td>{item.stay_start}〜{item.stay_end}</td>
                                                                                <td>{item.accept_start}〜{item.accept_end}</td>
                                                                                <td>{item.facility_name}</td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))
                                                                );
                                                            }
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.searchInsuranceApplicationList.data,
        err_type: state.searchInsuranceApplicationList.err_type,
        err_detail: state.searchInsuranceApplicationList.err_detail,
        update_data: state.updateInsuranceApplicationList.data,
        update_err_type: state.updateInsuranceApplicationList.err_type,
        update_err_detail: state.updateInsuranceApplicationList.err_detail,
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        insuranceApplicationListInfo: (arg) => dispatch(searchInsuranceApplicationList(arg)),
        insuranceApplicationListReset: () => dispatch(searchInsuranceApplicationListReset()),
        updateInsuranceApplicationList: (arg) => dispatch(updateInsuranceApplicationList(arg)),
        updateInsuranceApplicationListReset: () => dispatch(updateInsuranceApplicationListReset()),
        updateInsuranceApplicationData: (data) => dispatch(searchInsuranceApplicationListUpdate(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GICIAIAS01);
