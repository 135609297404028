import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GTMWTWTR01ActionType';

// 作業時間登録API
const SAVE_WORKINGTIME_POST = BASE_URL + "/api/tm_wt_update_working_time_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const SaveWorkingtimeRequest = () => {
    return {
        type: actionTypes.SAVE_WORKINGTIME_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const SaveWorkingtimeSuccess = (data) => {
    return {
        type: actionTypes.SAVE_WORKINGTIME_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const SaveWorkingtimeFailure = (err) => {
    return {
        type: actionTypes.SAVE_WORKINGTIME_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const SaveWorkingtimeReset = () => {
    return {
        type: actionTypes.SAVE_WORKINGTIME_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const saveWorkingtime = (data) => {
    return async (dispatch) => {
        dispatch(SaveWorkingtimeRequest());
        ecoPostWithAuthHeader(SAVE_WORKINGTIME_POST, JSON.stringify(data)).then(
            res => dispatch(SaveWorkingtimeSuccess(res.data))
        ).catch(
            err => dispatch(SaveWorkingtimeFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const saveWorkingTimeReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveWorkingtimeReset());
    };
};