import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GCMOMCRU04ActionType';

// 組織情報(プロフィール)大学取得API
const GET_UNIVERSITY_INFO_POST = BASE_URL + "/api/cm_om_get_university_info";
// 組織情報(プロフィール)大学更新API
const UPDATE_UNIVERSITY_INFO_POST = BASE_URL + "/api/cm_om_update_university_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetUniversityInfoRequest = () => {
    return {
        type: actionTypes.GET_UNIVERSITY_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetUniversityInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_UNIVERSITY_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetUniversityInfoFailure = (err) => {
    return {
        type: actionTypes.GET_UNIVERSITY_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetUniversityInfoReset = () => {
    return {
        type: actionTypes.GET_UNIVERSITY_INFO_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateUniversityInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_UNIVERSITY_INFO_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateUniversityInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_UNIVERSITY_INFO_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateUniversityInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_UNIVERSITY_INFO_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateUniversityInfoReset = () => {
    return {
        type: actionTypes.UPDATE_UNIVERSITY_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getUniversityInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetUniversityInfoRequest());
        ecoPostWithAuthHeader(GET_UNIVERSITY_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetUniversityInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetUniversityInfoFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateUniversityInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateUniversityInfoRequest());
        ecoPostWithAuthHeader(UPDATE_UNIVERSITY_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateUniversityInfoSuccess(res.data))
        ).catch(
            err => dispatch(UpdateUniversityInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getUniversityInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetUniversityInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateUniversityInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateUniversityInfoReset());
    };
};