import React, { Fragment } from "react";

import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { GetUniversityInfoReset } from "../OmGCMOMCRU04/GCMOMCRU04Action";
import { isBlank, getNumberText } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 応募者詳細画面
 */
class GCMOMCRU07 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseDetail: this.props.caseDetail,
            candidateList: this.props.candidateList
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        const { universityData } = this.props;
        this.setState({
            university_name: universityData.university_name,       // 大学名
            university_location: universityData.university_location,       // 所在地
            country_name: universityData.country_name,       // 国
            university_start_year: universityData.university_start_year,       // 設立年
            teacher_name: universityData.teacher_name,       // 担当先生名
            teacher_mail: universityData.teacher_mail,       // 担当先生メールアドレス
            teacher_tel_country_number: universityData.teacher_tel_country_number,       // 担当先生電話(国コード)
            teacher_tel1: universityData.teacher_tel1,       // 担当先生電話番号１
            teacher_tel2: universityData.teacher_tel2,       // 担当先生電話番号２
            teacher_tel3: universityData.teacher_tel3,      // 担当先生電話番号３
            english_level_name: universityData.english_level_name,       // 英語レベル
            japanese_level_name: universityData.japanese_level_name,       // 日本語レベル
            jpteacher_name: universityData.jpteacher_name,       // 日本語教育専門の先生の有無
            people: universityData.people,       // 学生の人数
            study_period_name: universityData.study_period_name,       // 修業期間
            section_name: universityData.section_name        // 設置区分
        });
    }

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            if (changeTo === 'GRSMMMLI01') {
                this.props.getCaseDetailReset();
                this.props.getEntryListReset();
            }
            this.props.resetUniversityState();
        }
        return this.props.switchDisplaySelectedUni(changeTo, this.state.caseDetail, this.state.candidateList);
    }

    render() {
        return (
            <article>
                <div class="ly_cont">
                    <div class="bl_mainHeader">
                        <div class="bl_mainHeader_inner">
                            <h1 class="el_lv1Heading">
                                組織情報(プロフィール)
                            </h1>
                        </div>
                    </div>
                    <div class="ly_mainCont">
                        <nav class="bl_breadcrumb">
                            <ol class="bl_breadcrumb_list">
                                <li>
                                    <a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>案件詳細</a></li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section class="bl_sect">

                            <div class="ly_mainCont_inner">

                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">登録プロフィール情報
                                    </h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>大学名</th>
                                                    <td>{this.state.university_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>所在地</th>
                                                    <td>{this.state.university_location}</td>
                                                </tr>
                                                <tr>
                                                    <th>国</th>
                                                    <td>{this.state.country_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>設立日(年)</th>
                                                    <td>{isBlank(this.state.university_start_year) ? '' : this.state.university_start_year + ' 年'}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当先生名</th>
                                                    <td>{this.state.teacher_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当先生メールアドレス</th>
                                                    <td>{this.state.teacher_mail}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者電話番号</th>
                                                    <td>{(isBlank(this.state.teacher_tel_country_number) && isBlank(this.state.teacher_tel1) && isBlank(this.state.teacher_tel2) && isBlank(this.state.teacher_tel3)) ? '' : this.state.teacher_tel_country_number + ' ' + this.state.teacher_tel1 + '-' + this.state.teacher_tel2 + '-' + this.state.teacher_tel3}</td>
                                                </tr>
                                                <tr>
                                                    <th>英語レベル</th>
                                                    <td>{this.state.english_level_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>日本語レベル</th>
                                                    <td>{this.state.japanese_level_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>日本語教育専門の先生の有無</th>
                                                    <td>{this.state.jpteacher_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>学生の人数</th>
                                                    <td>{isBlank(this.state.people) ? '' : getNumberText(this.state.people) + ' 名'}</td>
                                                </tr>
                                                <tr>
                                                    <th>修業期間</th>
                                                    <td>{this.state.study_period_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>設置区分</th>
                                                    <td>{this.state.section_name}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div class="bl_horizBtnUnit__move">
                                        <button type="button" class="el_blueBtn" onClick={() => this.handleToDisplay("GRSMMIMD02", true)}>エントリー情報へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article >
        );
    }
}
const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        resetUniversityState: () => dispatch(GetUniversityInfoReset()),
    };
};

export default connect(null, mapDispatchToProps)(GCMOMCRU07);

