import React from "react";
import { withRouter } from "react-router";
import GAFLFLAF01 from "./LfGAFLFLAF01/GAFLFLAF01";
import GAFLFPRF01 from "./LfGAFLFPRF01/GAFLFPRF01";
import GAFLFCPF01 from "./LfGAFLFCPF01/GAFLFCPF01";
import GAFLFLAF02 from "./LfGAFLFLAF02/GAFLFLAF02";

/**
 * ログイン機能テンプレート
 */
class LoginTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: null,
            data: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId
        });
    }

    /**
     * 画面切替処理
     * @param {*} dispId
     */
    redirectToLoginPage = (dispId) => {
        this.setState({
            display: dispId
        });
    }

    /**
     * 画面切替処理
     * @param {*} dispId
     * @param {*} data
     */
    redirectToNextPage = (dispId, data) => {
        this.setState({
            display: dispId,
            data: data
        });
    }

    render() {
        let displayParts;

        if (this.state.display === "GAFLFPRF01") {
            // パスワードリマインド画面
            displayParts = <GAFLFPRF01 redirectToNextPage={this.redirectToNextPage} />;
        } else if (this.state.display === "GAFLFCPF01") {
            // パスワード変更画面
            displayParts = <GAFLFCPF01 redirectToNextPage={this.redirectToNextPage} beforeTemplate='LoginTemplate' userId={this.state.data} />;
        } else if (this.state.display === "GAFLFLAF02") {
            // 個人情報取り扱い同意取得
            displayParts = <GAFLFLAF02 redirectToNextPage={this.redirectToNextPage} />;
        } else {
            displayParts = <GAFLFLAF01 redirectToNextPage={this.redirectToNextPage} />;
        }

        return (
            displayParts
        );
    }
}


export default withRouter(LoginTemplate);

