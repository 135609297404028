/**
 * Action Typesを定義
 */
const GCMOMORU02ActionType = {
    UPDATE_USER_MANAGEMENT_REQUEST: "UPDATE_USER_MANAGEMENT_REQUEST", // ユーザ管理更新定義
    UPDATE_USER_MANAGEMENT_SUCCESS: "UPDATE_USER_MANAGEMENT_SUCCESS", // ユーザ管理更新成功定義
    UPDATE_USER_MANAGEMENT_FAILURE: "UPDATE_USER_MANAGEMENT_FAILURE", // ユーザ管理更新失敗定義
    UPDATE_USER_MANAGEMENT_RESET:   "UPDATE_USER_MANAGEMENT_RESET",   // ユーザ管理更新リセット定義
};

export default GCMOMORU02ActionType;
