import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { getChangeDateText } from "../../Components/Tools/StringTools";

/**
 * お知らせ詳細機能
 */
class GAFMCSNF02 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            noticeDetail: this.props.noticeDetail
        };

    }

    /**
     * 一覧画面切り替え
     */
    handleToDisplay = (changeTo) => {
        return this.props.changeDisp(changeTo);
    }

    render() {
        let bodyMessage = "";
        let link = null;
        const httpIdx = this.state.noticeDetail.info_detail.indexOf("http://") != -1 ? this.state.noticeDetail.info_detail.indexOf("http://") : this.state.noticeDetail.info_detail.indexOf("https://");
        if (this.state.noticeDetail.info_detail && httpIdx != -1) {
            bodyMessage = this.state.noticeDetail.info_detail.substring(0, httpIdx);
            link = this.state.noticeDetail.info_detail.substring(httpIdx, this.state.noticeDetail.info_detail.length);
        } else {
            bodyMessage = this.state.noticeDetail.info_detail;
        }
        return (
            <article>
                <div className="bl_mainHeader">
                    <div className="bl_mainHeader_linkUnit">
                    </div>
                    <div className="bl_mainHeader_inner">
                        <h1 className="el_lv1Heading">
                            お知らせ
                        </h1>
                    </div>
                </div>
                <div className="ly_mainCont">
                    <nav className="bl_breadcrumb">
                        <ol className="bl_breadcrumb_list">
                            <li>
                                <a href="#!" onClick={() => this.handleToDisplay("show_top")}>ホーム</a>
                            </li>
                            <li>
                                お知らせ
              </li>
                        </ol>
                    </nav>
                    <section className="bl_sect">
                        <div className="ly_mainCont_inner">
                            <section className="bl_sect_frame">
                                <div className="bl_sect_inner bl_sect_inner_internOverView">
                                    <div className="bl_sect_header bl_sect_header__info">
                                        <h2 className="el_lv3Heading">
                                            <span>{this.state.noticeDetail.info_title}</span>
                                        </h2>
                                    </div>
                                    <p className="el_date hp_tar">{getChangeDateText(this.state.noticeDetail.info_date)}</p>
                                    <div className="bl_infoBody">
                                        {bodyMessage ? bodyMessage.split('\n').map((str, index) => (
                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                        )) : null}
                                        {link ? <React.Fragment><a href={link} target="_blank">{link}</a></React.Fragment> : null}
                                    </div>
                                </div>
                            </section>
                            <button type="button" className="el_backBtn" onClick={() => this.handleToDisplay("show_top")}>ホーム画面へ</button>
                        </div>
                    </section>
                </div>
            </article>
        );
    }
}

export default GAFMCSNF02;
