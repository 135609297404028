import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GRSMMMLI01ActionType';

// 案件一覧検索APIURL定義
const GET_CASE_LIST_POST = BASE_URL + "/api/rs_mm_get_case_list";
// 案件詳細取得APIURL定義
const CASE_DETAIL_POST = BASE_URL + "/api/rs_mm_get_case_detail";
// 案件削除APIURL定義
const CASE_DELETE_POST = BASE_URL + "/api/rs_mm_delete_case";
// 求人案件詳細取得APIURL定義
const RECRUITMENT_DETAIL_POST = BASE_URL + "/api/rs_mm_get_recruitment_case_detail";
// 特定技能求人案件詳細取得APIURL定義
const SPECIFIC_DETAIL_POST = BASE_URL + "/api/rs_mm_get_specific_case_detail";

// ********************Actionの作成 Start ********************
/**
 * 案件一覧検索
 */
export const GetCaseListRequest = () => {
    return {
        type: actionTypes.GET_CASE_LIST_REQUEST
    };
};

/**
 * 案件一覧検索成功
 */
export const GetCaseListSuccess = (data) => {
    return {
        type: actionTypes.GET_CASE_LIST_SUCCESS,
        data
    };
};

/**
 * 案件一覧検索失敗
 */
export const GetCaseListFailure = (err) => {
    return {
        type: actionTypes.GET_CASE_LIST_FAILURE,
        err
    };
};

/**
 * 案件一覧検索リセット
 */
export const GetCaseListReset = () => {
    return {
        type: actionTypes.GET_CASE_LIST_RESET
    };
};

/**
 * 案件詳細取得
 */
export const CaseDetailRequest = () => {
    return {
        type: actionTypes.CASE_DETAIL_REQUEST
    };
};

/**
 * 案件詳細取得成功
 */
export const CaseDetailSuccess = (data) => {
    return {
        type: actionTypes.CASE_DETAIL_SUCCESS,
        data
    };
};

/**
 * 案件詳細取得失敗
 */
export const CaseDetailFailure = (err) => {
    return {
        type: actionTypes.CASE_DETAIL_FAILURE,
        err
    };
};

/**
 * 案件詳細取得リセット
 */
export const CaseDetailReset = () => {
    return {
        type: actionTypes.CASE_DETAIL_RESET
    };
};

/**
 * 案件コピー作成
 */
export const CaseDetailCopyRequest = () => {
    return {
        type: actionTypes.CASE_DETAIL_COPY_REQUEST
    };
};

/**
 * 案件コピー作成成功
 */
export const CaseDetailCopySuccess = (data) => {
    return {
        type: actionTypes.CASE_DETAIL_COPY_SUCCESS,
        data
    };
};

/**
 * 案件コピー作成失敗
 */
export const CaseDetailCopyFailure = (err) => {
    return {
        type: actionTypes.CASE_DETAIL_COPY_FAILURE,
        err
    };
};

/**
 * 案件コピー作成リセット
 */
export const CaseDetailCopyReset = () => {
    return {
        type: actionTypes.CASE_DETAIL_COPY_RESET
    };
};

/**
 * 案件削除
 */
export const CaseDeleteRequest = () => {
    return {
        type: actionTypes.CASE_DELETE_REQUEST
    };
};

/**
 * 案件削除成功
 */
export const CaseDeleteSuccess = (data) => {
    return {
        type: actionTypes.CASE_DELETE_SUCCESS,
        data
    };
};

/**
 * 案件削除失敗
 */
export const CaseDeleteFailure = (err) => {
    return {
        type: actionTypes.CASE_DELETE_FAILURE,
        err
    };
};

/**
 * 求人案件詳細取得
 */
export const RecruitmentDetailRequest = () => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_REQUEST
    };
};


/**
 * 求人案件詳細取得成功
 */
export const RecruitmentDetailSuccess = (data) => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_SUCCESS,
        data
    };
};

/**
 * 求人案件詳細取得失敗
 */
export const RecruitmentDetailFailure = (err) => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_FAILURE,
        err
    };
};

/**
 * 求人案件詳細取得リセット
 */
export const RecruitmentDetailReset = () => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_RESET
    };
};

/**
 * 求人案件コピー作成
 */
export const RecruitmentDetailCopyRequest = () => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_COPY_REQUEST
    };
};

/**
 * 求人案件コピー作成成功
 */
export const RecruitmentDetailCopySuccess = (data) => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_COPY_SUCCESS,
        data
    };
};

/**
 * 求人案件コピー作成失敗
 */
export const RecruitmentDetailCopyFailure = (err) => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_COPY_FAILURE,
        err
    };
};

/**
 * 求人案件コピー作成リセット
 */
export const RecruitmentDetailCopyReset = () => {
    return {
        type: actionTypes.RECRUITMENT_DETAIL_COPY_RESET
    };
};

/**
 * 特定技能求人案件詳細取得
 */
export const SpecificDetailRequest = () => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_REQUEST
    };
};


/**
 * 特定技能求人案件詳細取得成功
 */
export const SpecificDetailSuccess = (data) => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_SUCCESS,
        data
    };
};

/**
 * 特定技能求人案件詳細取得失敗
 */
export const SpecificDetailFailure = (err) => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_FAILURE,
        err
    };
};

/**
 * 特定技能求人案件詳細取得リセット
 */
export const SpecificDetailReset = () => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_RESET
    };
};

/**
 * 特定技能求人案件コピー作成
 */
export const SpecificDetailCopyRequest = () => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_COPY_REQUEST
    };
};

/**
 * 特定技能求人案件コピー作成成功
 */
export const SpecificDetailCopySuccess = (data) => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_COPY_SUCCESS,
        data
    };
};

/**
 * 特定技能求人案件コピー作成失敗
 */
export const SpecificDetailCopyFailure = (err) => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_COPY_FAILURE,
        err
    };
};

/**
 * 特定技能求人案件コピー作成リセット
 */
export const SpecificDetailCopyReset = () => {
    return {
        type: actionTypes.SPECIFIC_DETAIL_COPY_RESET
    };
};
// ********************Actionの作成 End ********************

/**
 * 案件一覧検索Actionの発行
 */
export const getCaseList = (query) => {
    return async (dispatch) => {
        // Storeへ案件一覧検索リクエストActionをdispatchする
        dispatch(GetCaseListRequest());
        ecoPostWithAuthHeader(GET_CASE_LIST_POST, JSON.stringify(query))
            .then(res =>
                // Storeへ案件一覧検索成功Actionをdispatchする
                dispatch(GetCaseListSuccess(res.data))
            ).catch(err =>
                // Storeへ案件一覧検索失敗Actionをdispatchする
                dispatch(GetCaseListFailure(err))
            );
    };
};

/**
 * 案件詳細取得Actionの発行
 */
export const getCaseDetail = (data) => {
    return async (dispatch) => {
        // Storeへ案件詳細情報取リクエストActionをdispatchする
        dispatch(CaseDetailRequest());
        ecoPostWithAuthHeader(CASE_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件詳細情報取得成功Actionをdispatchする
                dispatch(CaseDetailSuccess(res.data))
            ).catch(err =>
                // Storeへ案件詳細情報取得失敗Actionをdispatchする
                dispatch(CaseDetailFailure(err))
            );
    };
};

/**
 * 案件コピー作成Actionの発行
 */
export const getCaseDetailCopy = (data) => {
    return async (dispatch) => {
        // Storeへ案件コピーリクエストActionをdispatchする
        dispatch(CaseDetailCopyRequest());
        ecoPostWithAuthHeader(CASE_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件コピー成功Actionをdispatchする
                dispatch(CaseDetailCopySuccess(res.data))
            ).catch(err =>
                // Storeへ案件コピー失敗Actionをdispatchする
                dispatch(CaseDetailCopyFailure(err))
            );
    };
};

/**
 * 案件削除Actionの発行
 */
export const deleteCase = (data) => {
    return async (dispatch) => {
        // Storeへ案件詳細削除リクエストActionをdispatchする
        dispatch(CaseDeleteRequest());
        ecoPostWithAuthHeader(CASE_DELETE_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件詳細情報削除成功Actionをdispatchする
                dispatch(CaseDeleteSuccess(res.data))
            ).catch(err =>
                // Storeへ案件詳細情報削除失敗Actionをdispatchする
                dispatch(CaseDeleteFailure(err))
            );
    };
};

/**
 * 求人案件詳細取得Actionの発行
 */
export const getRecruitmentDetail = (data) => {
    return async (dispatch) => {
        // Storeへ案件詳細情報取リクエストActionをdispatchする
        dispatch(RecruitmentDetailRequest());
        ecoPostWithAuthHeader(RECRUITMENT_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件詳細情報取得成功Actionをdispatchする
                dispatch(RecruitmentDetailSuccess(res.data))
            ).catch(err =>
                // Storeへ案件詳細情報取得失敗Actionをdispatchする
                dispatch(RecruitmentDetailFailure(err))
            );
    };
};

/**
 * 求人案件コピー作成Actionの発行
 */
export const getRecruitmentDetailCopy = (data) => {
    return async (dispatch) => {
        // Storeへ案件コピーリクエストActionをdispatchする
        dispatch(RecruitmentDetailCopyRequest());
        ecoPostWithAuthHeader(RECRUITMENT_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件コピー成功Actionをdispatchする
                dispatch(RecruitmentDetailCopySuccess(res.data))
            ).catch(err =>
                // Storeへ案件コピー失敗Actionをdispatchする
                dispatch(RecruitmentDetailCopyFailure(err))
            );
    };
};

/**
 * 特定技能求人案件詳細取得Actionの発行
 */
export const getSpecificDetail = (data) => {
    return async (dispatch) => {
        // Storeへ案件詳細情報取リクエストActionをdispatchする
        dispatch(SpecificDetailRequest());
        ecoPostWithAuthHeader(SPECIFIC_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件詳細情報取得成功Actionをdispatchする
                dispatch(SpecificDetailSuccess(res.data))
            ).catch(err =>
                // Storeへ案件詳細情報取得失敗Actionをdispatchする
                dispatch(SpecificDetailFailure(err))
            );
    };
};

/**
 * 特定技能求人案件コピー作成Actionの発行
 */
export const getSpecificDetailCopy = (data) => {
    return async (dispatch) => {
        // Storeへ案件コピーリクエストActionをdispatchする
        dispatch(SpecificDetailCopyRequest());
        ecoPostWithAuthHeader(SPECIFIC_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件コピー成功Actionをdispatchする
                dispatch(SpecificDetailCopySuccess(res.data))
            ).catch(err =>
                // Storeへ案件コピー失敗Actionをdispatchする
                dispatch(SpecificDetailCopyFailure(err))
            );
    };
};