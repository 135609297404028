import { setMessageMaster, setBaseUrl, setCodeMaster, setAccessControlMaster, setCognitoInfo, setCountryMaster, setIndustryMaster, setPrefecturesMaster } from "../Constants/CommonConstants"


export const TEST_INDUSTRY_MASTER = [
    {
        "industry_id": "1001",
        "industry_name_j": "介護業",
        "industry_name_e": "NS(Nursing)"
    },
    {
        "industry_id": "1002",
        "industry_name_j": "ビルクリーニング業",
        "industry_name_e": "BC(Building cleaning )"
    },
    {
        "industry_id": "1003",
        "industry_name_j": "素形材産業",
        "industry_name_e": "CM(Casting material)"
    },
    {
        "industry_id": "1004",
        "industry_name_j": "産業機械製造業",
        "industry_name_e": "IM(Industrial machinery)"
    },
    {
        "industry_id": "1005",
        "industry_name_j": "電気・電子情報関連産業",
        "industry_name_e": "EE(Electricaland electronic information)"
    },
    {
        "industry_id": "1006",
        "industry_name_j": "建設業",
        "industry_name_e": "CI（Construction industry）"
    },
    {
        "industry_id": "1007",
        "industry_name_j": "造船・舶用業",
        "industry_name_e": "SM(Shipbuilding and marine)"
    },
    {
        "industry_id": "1008",
        "industry_name_j": "自動車整備業",
        "industry_name_e": "AM(Car maintenance)"
    },
    {
        "industry_id": "1009",
        "industry_name_j": "航空業",
        "industry_name_e": "AA(Aviation)"
    },
    {
        "industry_id": "1010",
        "industry_name_j": "宿泊業",
        "industry_name_e": "AB(Accommodation business)"
    },
    {
        "industry_id": "1011",
        "industry_name_j": "農業",
        "industry_name_e": "AC(Agriculture)"
    },
    {
        "industry_id": "1012",
        "industry_name_j": "漁業",
        "industry_name_e": "FS(Fishery)"
    },
    {
        "industry_id": "1013",
        "industry_name_j": "飲食料品製造業",
        "industry_name_e": "FB(Food and beverage)"
    },
    {
        "industry_id": "1014",
        "industry_name_j": "外食業",
        "industry_name_e": "EO(Eating out)"
    }
];

export const TEST_COUNTRY_MASTER = [
    {
        "country_number": "+81",
        "country_code": "JPN",
        "country_name": "日本",
        "numbering_country_code": "Z"
    },
    {
        "country_number": "+84",
        "country_code": "VNM",
        "country_name": "ベトナム",
        "numbering_country_code": "C"
    },
    {
        "country_number": "+886",
        "country_code": "TWN",
        "country_name": "台湾",
        "numbering_country_code": "X"
    },
    {
        "country_number": "+62",
        "country_code": "IDN",
        "country_name": "インドネシア",
        "numbering_country_code": "4"
    },
    {
        "country_number": "+95",
        "country_code": "MMR",
        "country_name": "ミャンマー",
        "numbering_country_code": "F"
    },
    {
        "country_number": "+65",
        "country_code": "SGP",
        "country_name": "シンガポール",
        "numbering_country_code": "G"
    },
    {
        "country_number": "+60",
        "country_code": "MYS",
        "country_name": "マレーシア",
        "numbering_country_code": "2"
    },
    {
        "country_number": "+86",
        "country_code": "CHN",
        "country_name": "中国",
        "numbering_country_code": "S"
    },
    {
        "country_number": "+82",
        "country_code": "KOR",
        "country_name": "韓国",
        "numbering_country_code": "J"
    }
];

export const TEST_PREFECTURES_MASTER = [
    {
        "prefectures_id": "01",
        "prefectures_name": "北海道",
        "map": "hokkaido.png",
        "minimum_wage": 1000.0
    },
    {
        "prefectures_id": "02",
        "prefectures_name": "青森県",
        "map": "tohoku.png",
        "minimum_wage": 1002.0
    },
    {
        "prefectures_id": "03",
        "prefectures_name": "岩手県",
        "map": "tohoku.png",
        "minimum_wage": 1002.0
    },
    {
        "prefectures_id": "04",
        "prefectures_name": "宮城県",
        "map": "tohoku.png",
        "minimum_wage": 1003.0
    },
    {
        "prefectures_id": "05",
        "prefectures_name": "秋田県",
        "map": "tohoku.png",
        "minimum_wage": 1004.0
    },
    {
        "prefectures_id": "06",
        "prefectures_name": "山形県",
        "map": "tohoku.png",
        "minimum_wage": 1005.0
    },
    {
        "prefectures_id": "07",
        "prefectures_name": "福島県",
        "map": "tohoku.png",
        "minimum_wage": 1006.0
    },
    {
        "prefectures_id": "08",
        "prefectures_name": "茨城県",
        "map": "kanto.png",
        "minimum_wage": 1007.0
    },
    {
        "prefectures_id": "09",
        "prefectures_name": "栃木県",
        "map": "kanto.png",
        "minimum_wage": 1008.0
    },
    {
        "prefectures_id": "10",
        "prefectures_name": "群馬県",
        "map": "kanto.png",
        "minimum_wage": 1009.0
    },
    {
        "prefectures_id": "11",
        "prefectures_name": "埼玉県",
        "map": "kanto.png",
        "minimum_wage": 1010.0
    },
    {
        "prefectures_id": "12",
        "prefectures_name": "千葉県",
        "map": "kanto.png",
        "minimum_wage": 1011.0
    },
    {
        "prefectures_id": "13",
        "prefectures_name": "東京都",
        "map": "kanto.png",
        "minimum_wage": 1012.0
    },
    {
        "prefectures_id": "14",
        "prefectures_name": "神奈川県",
        "map": "kanto.png",
        "minimum_wage": 1013.0
    },
    {
        "prefectures_id": "15",
        "prefectures_name": "新潟県",
        "map": "chubu.png",
        "minimum_wage": 1014.0
    },
    {
        "prefectures_id": "16",
        "prefectures_name": "富山県",
        "map": "chubu.png",
        "minimum_wage": 1015.0
    },
    {
        "prefectures_id": "17",
        "prefectures_name": "石川県",
        "map": "chubu.png",
        "minimum_wage": 1016.0
    },
    {
        "prefectures_id": "18",
        "prefectures_name": "福井県",
        "map": "chubu.png",
        "minimum_wage": 1017.0
    },
    {
        "prefectures_id": "19",
        "prefectures_name": "山梨県",
        "map": "chubu.png",
        "minimum_wage": 1018.0
    },
    {
        "prefectures_id": "20",
        "prefectures_name": "長野県",
        "map": "chubu.png",
        "minimum_wage": 1019.0
    },
    {
        "prefectures_id": "21",
        "prefectures_name": "岐阜県",
        "map": "chubu.png",
        "minimum_wage": 1020.0
    },
    {
        "prefectures_id": "22",
        "prefectures_name": "静岡県",
        "map": "chubu.png",
        "minimum_wage": 1021.0
    },
    {
        "prefectures_id": "23",
        "prefectures_name": "愛知県",
        "map": "chubu.png",
        "minimum_wage": 1022.0
    },
    {
        "prefectures_id": "24",
        "prefectures_name": "三重県",
        "map": "kinki.png",
        "minimum_wage": 1023.0
    },
    {
        "prefectures_id": "25",
        "prefectures_name": "滋賀県",
        "map": "kinki.png",
        "minimum_wage": 1024.0
    },
    {
        "prefectures_id": "26",
        "prefectures_name": "京都府",
        "map": "kinki.png",
        "minimum_wage": 1025.0
    },
    {
        "prefectures_id": "27",
        "prefectures_name": "大阪府",
        "map": "kinki.png",
        "minimum_wage": 1026.0
    },
    {
        "prefectures_id": "28",
        "prefectures_name": "兵庫県",
        "map": "kinki.png",
        "minimum_wage": 1027.0
    },
    {
        "prefectures_id": "29",
        "prefectures_name": "奈良県",
        "map": "kinki.png",
        "minimum_wage": 1028.0
    },
    {
        "prefectures_id": "30",
        "prefectures_name": "和歌山県",
        "map": "kinki.png",
        "minimum_wage": 1029.0
    },
    {
        "prefectures_id": "31",
        "prefectures_name": "鳥取県",
        "map": "chugoku.png",
        "minimum_wage": 1030.0
    },
    {
        "prefectures_id": "32",
        "prefectures_name": "島根県",
        "map": "chugoku.png",
        "minimum_wage": 1031.0
    },
    {
        "prefectures_id": "33",
        "prefectures_name": "岡山県",
        "map": "chugoku.png",
        "minimum_wage": 1032.0
    },
    {
        "prefectures_id": "34",
        "prefectures_name": "広島県",
        "map": "chugoku.png",
        "minimum_wage": 1033.0
    },
    {
        "prefectures_id": "35",
        "prefectures_name": "山口県",
        "map": "chugoku.png",
        "minimum_wage": 1034.0
    },
    {
        "prefectures_id": "36",
        "prefectures_name": "徳島県",
        "map": "shikoku.png",
        "minimum_wage": 1035.0
    },
    {
        "prefectures_id": "37",
        "prefectures_name": "香川県",
        "map": "shikoku.png",
        "minimum_wage": 1036.0
    },
    {
        "prefectures_id": "38",
        "prefectures_name": "愛媛県",
        "map": "shikoku.png",
        "minimum_wage": 1037.0
    },
    {
        "prefectures_id": "39",
        "prefectures_name": "高知県",
        "map": "shikoku.png",
        "minimum_wage": 1038.0
    },
    {
        "prefectures_id": "40",
        "prefectures_name": "福岡県",
        "map": "kyushu.png",
        "minimum_wage": 1039.0
    },
    {
        "prefectures_id": "41",
        "prefectures_name": "佐賀県",
        "map": "kyushu.png",
        "minimum_wage": 1040.0
    },
    {
        "prefectures_id": "42",
        "prefectures_name": "長崎県",
        "map": "kyushu.png",
        "minimum_wage": 1041.0
    },
    {
        "prefectures_id": "43",
        "prefectures_name": "熊本県",
        "map": "kyushu.png",
        "minimum_wage": 1042.0
    },
    {
        "prefectures_id": "44",
        "prefectures_name": "大分県",
        "map": "kyushu.png",
        "minimum_wage": 1043.0
    },
    {
        "prefectures_id": "45",
        "prefectures_name": "宮崎県",
        "map": "kyushu.png",
        "minimum_wage": 1044.0
    },
    {
        "prefectures_id": "46",
        "prefectures_name": "鹿児島県",
        "map": "kyushu.png",
        "minimum_wage": 1045.0
    },
    {
        "prefectures_id": "47",
        "prefectures_name": "沖縄県",
        "map": "kyushu.png",
        "minimum_wage": 1046.0
    }
];

export const TEST_CODE = [
    {
        "code_type": "RSC0001",
        "code": "1",
        "code_name": "依頼中"
    },
    {
        "code_type": "RSC0001",
        "code": "0",
        "code_name": "取下げ"
    },
    {
        "code_type": "RSC0001",
        "code": "3",
        "code_name": "受付済"
    },
    {
        "code_type": "RSC0001",
        "code": "9",
        "code_name": "辞退"
    },
    {
        "code_type": "RSC0003",
        "code": "9",
        "code_name": "作成中"
    },
    {
        "code_type": "RSC0003",
        "code": "2",
        "code_name": "作成済"
    },
    {
        "code_type": "RSC0003",
        "code": "0",
        "code_name": "募集中"
    },
    {
        "code_type": "RSC0003",
        "code": "1",
        "code_name": "募集終了"
    },
    {
        "code_type": "RSC0004",
        "code": "0",
        "code_name": "技能実習"
    },
    {
        "code_type": "RSC0004",
        "code": "1",
        "code_name": "特定技能"
    },
    {
        "code_type": "RSC0004",
        "code": "2",
        "code_name": "インターン"
    },
    {
        "code_type": "RSC0005",
        "code": "1",
        "code_name": "応募中"
    },
    {
        "code_type": "RSC0005",
        "code": "0",
        "code_name": "不採用"
    },
    {
        "code_type": "RSC0005",
        "code": "2",
        "code_name": "面接調整"
    },
    {
        "code_type": "RSC0005",
        "code": "3",
        "code_name": "面接待ち"
    },
    {
        "code_type": "RSC0005",
        "code": "4",
        "code_name": "内定・確定待"
    },
    {
        "code_type": "RSC0005",
        "code": "5",
        "code_name": "本人辞退"
    },
    {
        "code_type": "RSC0005",
        "code": "9",
        "code_name": "採用決定"
    },
    {
        "code_type": "RSC0006",
        "code": "N1",
        "code_name": "N1"
    },
    {
        "code_type": "RSC0006",
        "code": "N2",
        "code_name": "N2"
    },
    {
        "code_type": "RSC0006",
        "code": "N3",
        "code_name": "N3"
    },
    {
        "code_type": "RSC0006",
        "code": "N4",
        "code_name": "N4"
    },
    {
        "code_type": "RSC0006",
        "code": "N5",
        "code_name": "N5"
    },
    {
        "code_type": "RSC0007",
        "code": "M",
        "code_name": "男性"
    },
    {
        "code_type": "RSC0007",
        "code": "F",
        "code_name": "女性"
    },
    {
        "code_type": "RSC0007",
        "code": "N",
        "code_name": "限定無し"
    },
    {
        "code_type": "RSC0009",
        "code": "0",
        "code_name": "指定しない"
    },
    {
        "code_type": "RSC0009",
        "code": "1",
        "code_name": "指定する"
    },
    {
        "code_type": "RSC0011",
        "code": "0",
        "code_name": "無し"
    },
    {
        "code_type": "RSC0011",
        "code": "1",
        "code_name": "有り"
    },
    {
        "code_type": "RSC0012",
        "code": "0",
        "code_name": "水道光熱費別"
    },
    {
        "code_type": "RSC0012",
        "code": "1",
        "code_name": "水道光熱費込"
    },
    {
        "code_type": "RSC0013",
        "code": "0",
        "code_name": "募集開始期間(降順)"
    },
    {
        "code_type": "RSC0013",
        "code": "1",
        "code_name": "募集開始期間(昇順)"
    },
    {
        "code_type": "RSC0014",
        "code": "0",
        "code_name": "寮有り　Wi-Fi有り"
    },
    {
        "code_type": "RSC0014",
        "code": "1",
        "code_name": "寮有り　Wi-Fi無し"
    },
    {
        "code_type": "RSC0014",
        "code": "2",
        "code_name": "寮無し"
    },
    {
        "code_type": "RSC0015",
        "code": "0",
        "code_name": "申し込む"
    },
    {
        "code_type": "RSC0015",
        "code": "1",
        "code_name": "申し込まない"
    },
    {
        "code_type": "RSC0016",
        "code": "0",
        "code_name": "サービスする"
    },
    {
        "code_type": "RSC0016",
        "code": "1",
        "code_name": "サービスしない"
    },
    {
        "code_type": "RSC0017",
        "code": "0",
        "code_name": "未読"
    },
    {
        "code_type": "RSC0017",
        "code": "1",
        "code_name": "既読"
    },
    {
        "code_type": "RSC0018",
        "code": "0",
        "code_name": "契約社員"
    },
    {
        "code_type": "RSC0018",
        "code": "1",
        "code_name": "アルバイト"
    },
    {
        "code_type": "RSC0018",
        "code": "2",
        "code_name": "パート"
    },
    {
        "code_type": "RSC0019",
        "code": "0",
        "code_name": "原則として更新する"
    },
    {
        "code_type": "RSC0019",
        "code": "1",
        "code_name": "契約更新しない"
    },
    {
        "code_type": "RSC0020",
        "code": "C1",
        "code_name": "上級"
    },
    {
        "code_type": "RSC0020",
        "code": "C2",
        "code_name": "中級"
    },
    {
        "code_type": "RSC0020",
        "code": "C3",
        "code_name": "初級"
    },
    {
        "code_type": "RSC0020",
        "code": "C4",
        "code_name": "不可"
    },
    {
        "code_type": "RSC0021",
        "code": "001",
        "code_name": "国立"
    },
    {
        "code_type": "RSC0021",
        "code": "002",
        "code_name": "公立"
    },
    {
        "code_type": "RSC0021",
        "code": "003",
        "code_name": "私立"
    },
    {
        "code_type": "RSC0022",
        "code": "1",
        "code_name": "1年"
    },
    {
        "code_type": "RSC0022",
        "code": "2",
        "code_name": "2年"
    },
    {
        "code_type": "RSC0022",
        "code": "4",
        "code_name": "4年"
    },
    {
        "code_type": "RSC0022",
        "code": "6",
        "code_name": "6年"
    },
    {
        "code_type": "RSC0023",
        "code": "0",
        "code_name": "出勤"
    },
    {
        "code_type": "RSC0023",
        "code": "1",
        "code_name": "休日"
    },
    {
        "code_type": "RSC0023",
        "code": "2",
        "code_name": "欠勤"
    },
    {
        "code_type": "RSC0024",
        "code": "0",
        "code_name": "－"
    },
    {
        "code_type": "RSC0024",
        "code": "1",
        "code_name": "遅刻"
    },
    {
        "code_type": "RSC0024",
        "code": "2",
        "code_name": "早退"
    },
    {
        "code_type": "RSC0025",
        "code": "1",
        "code_name": "株式会社"
    },
    {
        "code_type": "RSC0025",
        "code": "2",
        "code_name": "合同会社(LLC)"
    },
    {
        "code_type": "RSC0025",
        "code": "3",
        "code_name": "合名会社"
    },
    {
        "code_type": "RSC0025",
        "code": "4",
        "code_name": "合資会社"
    },
    {
        "code_type": "RSC0025",
        "code": "5",
        "code_name": "その他"
    },
    {
        "code_type": "RSC0026",
        "code": "0",
        "code_name": "無し"
    },
    {
        "code_type": "RSC0026",
        "code": "1",
        "code_name": "有り"
    },
    {
        "code_type": "RSC0027",
        "code": "0",
        "code_name": "サービス停止中"
    },
    {
        "code_type": "RSC0027",
        "code": "1",
        "code_name": "サービス利用中"
    },
    {
        "code_type": "RSC0028",
        "code": "0",
        "code_name": "未承認"
    },
    {
        "code_type": "RSC0028",
        "code": "1",
        "code_name": "承認済"
    },
    {
        "code_type": "RSC0028",
        "code": "2",
        "code_name": "差戻"
    },
    {
        "code_type": "RSC0029",
        "code": "0",
        "code_name": "当日"
    },
    {
        "code_type": "RSC0029",
        "code": "1",
        "code_name": "翌日"
    },
    {
        "code_type": "RSC0030",
        "code": "COP",
        "code_name": "企業"
    },
    {
        "code_type": "RSC0030",
        "code": "KAN",
        "code_name": "監理団体"
    },
    {
        "code_type": "RSC0030",
        "code": "TOR",
        "code_name": "登録支援機関"
    },
    {
        "code_type": "RSC0030",
        "code": "UNI",
        "code_name": "大学"
    },
    {
        "code_type": "RSC0030",
        "code": "OKR",
        "code_name": "送り出し機関"
    },
    {
        "code_type": "RSC0030",
        "code": "AGT",
        "code_name": "保険代理店"
    },
    {
        "code_type": "RSC0030",
        "code": "COD",
        "code_name": "コーディネーター"
    },
    {
        "code_type": "RSC0031",
        "code": "COP",
        "code_name": "企業"
    },
    {
        "code_type": "RSC0031",
        "code": "KAN",
        "code_name": "監理団体"
    },
    {
        "code_type": "RSC0031",
        "code": "TOR",
        "code_name": "登録支援機関"
    },
    {
        "code_type": "RSC0031",
        "code": "UNI",
        "code_name": "大学"
    },
    {
        "code_type": "RSC0031",
        "code": "OKR",
        "code_name": "送り出し機関"
    },
    {
        "code_type": "RSC0031",
        "code": "AGT",
        "code_name": "保険代理店"
    },
    {
        "code_type": "RSC0031",
        "code": "COD",
        "code_name": "コーディネーター"
    },
    {
        "code_type": "RSC0031",
        "code": "INT",
        "code_name": "インターン"
    },
    {
        "code_type": "RSC0031",
        "code": "GNO",
        "code_name": "技能実習"
    },
    {
        "code_type": "RSC0031",
        "code": "TOK",
        "code_name": "特定技能"
    },
    {
        "code_type": "RSC0032",
        "code": "30",
        "code_name": "30分"
    },
    {
        "code_type": "RSC0032",
        "code": "60",
        "code_name": "1時間"
    },
    {
        "code_type": "RSC0032",
        "code": "90",
        "code_name": "1時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "120",
        "code_name": "2時間"
    },
    {
        "code_type": "RSC0032",
        "code": "150",
        "code_name": "2時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "180",
        "code_name": "3時間"
    },
    {
        "code_type": "RSC0032",
        "code": "210",
        "code_name": "3時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "240",
        "code_name": "4時間"
    },
    {
        "code_type": "RSC0032",
        "code": "270",
        "code_name": "4時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "300",
        "code_name": "5時間"
    },
    {
        "code_type": "RSC0032",
        "code": "330",
        "code_name": "5時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "360",
        "code_name": "6時間"
    },
    {
        "code_type": "RSC0032",
        "code": "390",
        "code_name": "6時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "420",
        "code_name": "7時間"
    },
    {
        "code_type": "RSC0032",
        "code": "450",
        "code_name": "7時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "480",
        "code_name": "8時間"
    },
    {
        "code_type": "RSC0032",
        "code": "510",
        "code_name": "8時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "540",
        "code_name": "9時間"
    },
    {
        "code_type": "RSC0032",
        "code": "570",
        "code_name": "9時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "600",
        "code_name": "10時間"
    },
    {
        "code_type": "RSC0032",
        "code": "630",
        "code_name": "10時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "660",
        "code_name": "11時間"
    },
    {
        "code_type": "RSC0032",
        "code": "690",
        "code_name": "11時間30分"
    },
    {
        "code_type": "RSC0032",
        "code": "720",
        "code_name": "12時間"
    },
    {
        "code_type": "RSC0033",
        "code": "0",
        "code_name": "必須業務"
    },
    {
        "code_type": "RSC0033",
        "code": "1",
        "code_name": "関連業務"
    },
    {
        "code_type": "RSC0033",
        "code": "2",
        "code_name": "周辺業務"
    },
    {
        "code_type": "RSC0034",
        "code": "0",
        "code_name": "未登録"
    },
    {
        "code_type": "RSC0034",
        "code": "1",
        "code_name": "実習結果登録済"
    },
    {
        "code_type": "RSC0034",
        "code": "2",
        "code_name": "評価登録済"
    },
    {
        "code_type": "RSC0035",
        "code": "1",
        "code_name": "完了予定"
    },
    {
        "code_type": "RSC0035",
        "code": "2",
        "code_name": "途中帰国"
    },
    {
        "code_type": "RSC0039",
        "code": "0",
        "code_name": "加入しない"
    },
    {
        "code_type": "RSC0039",
        "code": "1",
        "code_name": "加入する"
    },
    {
        "code_type": "RSC0042",
        "code": "0",
        "code_name": "未受講"
    },
    {
        "code_type": "RSC0042",
        "code": "1",
        "code_name": "受講済"
    },
    {
        "code_type": "RSC0043",
        "code": "0",
        "code_name": "未手続き"
    },
    {
        "code_type": "RSC0043",
        "code": "1",
        "code_name": "手続き済"
    },
    {
        "code_type": "RSC0044",
        "code": "0",
        "code_name": "未手配"
    },
    {
        "code_type": "RSC0044",
        "code": "1",
        "code_name": "手配済"
    },
    {
        "code_type": "RSC0045",
        "code": "1",
        "code_name": "受入・就業期間（昇順）"
    },
    {
        "code_type": "RSC0045",
        "code": "0",
        "code_name": "受入・就業期間（降順）"
    },
    {
        "code_type": "RSC0040",
        "code": "0",
        "code_name": "申込中"
    },
    {
        "code_type": "RSC0040",
        "code": "1",
        "code_name": "受講中"
    },
    {
        "code_type": "RSC0040",
        "code": "2",
        "code_name": "受講完了"
    },
    {
        "code_type": "RSC0041",
        "code": "1",
        "code_name": "合格"
    },
    {
        "code_type": "RSC0041",
        "code": "2",
        "code_name": "不合格"
    }
];

export const TEST_MESSAGE = {
    "RS-0063": "指定した面接日時を過ぎています。",
    "RS-0064": "会議参加者数が上限「%1」人を超えています。",
    "RS-0065": "面接日の登録に失敗しました。時間をおいて再度登録して下さい。",
    "AF-0001": "ログインIDかパスワードが異なります。",
    "AF-0002": "認証キーが異なります。",
    "AF-0003": "このページは表示できません。",
    "AF-0004": "予期せぬシステムエラー",
    "AF-0005": "他のユーザによりデータが変更・削除されています。",
    "RS-0001": "「%1」を入力してください。",
    "RS-0002": "「%1」の入力文字数が上限を超えています。",
    "RS-0003": "「%1」に入力できない文字が含まれています。",
    "RS-0004": "「%1」は入力できる範囲を超えています。",
    "RS-0005": "「%1」の日付が誤っています。",
    "RS-0006": "「%1」の時間が誤っています。",
    "RS-0007": "「%2」は「%1」以降の日付を入力してください。",
    "RS-0008": "「%1」が指定されていません。",
    "RS-0009": "「%1」が多すぎます。",
    "RS-0010": "「%1」は半角整数字を入力してください。",
    "RS-0011": "「%1」は全角ひらかなを入力してください。",
    "RS-0012": "「%1」の値が不正です。",
    "RS-0013": "「%1」のURLが不正です。",
    "RS-0014": "「%1」は必ず一つ選択してください。",
    "RS-0015": "「%1」への同意は必須です。",
    "RS-0016": "「%1」のマスタデータが存在しません。",
    "RS-0017": "表示上限を超えたため、「%1」件までを一覧に表示します。",
    "RS-0018": "「%1」のURL形式が誤っています。",
    "RS-0019": "「%1」は半角英数字を入力してください。",
    "RS-0020": "「%1」は全角文字を入力してください。",
    "RS-0021": "「%1」はカタカナを入力してください。",
    "RS-0022": "「%1」は正しいメールアドレスを入力してください。",
    "RS-0023": "「FAX番号」を正しく入力してください。",
    "RS-0024": "「%1」は半角を入力してください。",
    "RS-0025": "「%1」の国籍マスタデータが存在しません。",
    "RS-0026": "対象を選択してください。",
    "RS-0027": "選択した案件は、募集が開始されたため訂正できません。",
    "RS-0028": "訂正します。よろしいですか？",
    "RS-0029": "選択した案件は、募集が開始されたため取消できません。",
    "RS-0030": "取消します。よろしいですか？",
    "RS-0031": "選択した案件をコピーして新規に作成します。よろしいですか？",
    "RS-0032": "現在応募者は別案件に就業中です。このまま進めてよろしいですか？",
    "RS-0033": "現在応募者は別案件にて内定を受けている為、応募できません。",
    "RS-0034": "現在応募者は別案件に応募中です。このまま進めてよろしいですか？",
    "RS-0035": "「企業名」が選択されていません。会社概要の画面で、「企業名」を選択してください。",
    "RS-0036": "登録が完了しました。",
    "RS-0037": "「%1」は選択必須です。",
    "RS-0038": "選択した案件は、募集が終了されたため訂正できません。",
    "RS-0039": "選択した案件は、募集が終了されたため取消できません。",
    "RS-0040": "削除します。よろしいですか？",
    "RS-0041": "「%1」の形式が不正です。",
    "AF-0006": "半角アルファベットの大文字と小文字、および数字を組み合わせた8文字以上で設定してください。",
    "AF-0007": "新しいパスワードと新しいパスワード（再入力）が一致しません。",
    "AF-0008": "パスワード変更が成功しました。",
    "AF-0009": "パスワード変更が失敗しました。後ほどもう一度お試しください。",
    "AF-0010": "メールアドレスが間違っています。もう一度入力してください。",
    "AF-0011": "エラーが発生しました。後ほどもう一度お試しください。",
    "AF-0012": "新規パスワードをメールで送信しました。",
    "RS-0042": "「%1」できません。",
    "RS-0043": "「%1」休憩時間は勤務時間を超えました。",
    "RS-0044": "今日の作業時間情報が存在しますが、このまま進めてよろしいですか？",
    "RS-0045": "打刻しました。",
    "RS-0046": "「%1」が存在しません。",
    "RS-0047": "国は日本を選択してください。",
    "RS-0048": "所属団体を選択してください。",
    "RS-0049": "組織のユーザをすべて削除してから、実施してください。",
    "RS-0050": "承認済のため、打刻できません。",
    "RS-0051": "正常終了しました。",
    "RS-0052": "在留資格と組織名の組み合わせが不正です。",
    "RS-0053": "「%1」が重複しています。",
    "RS-0054": "「%1」は160Hを超えました。このまま進めてよろしいですか？",
    "RS-0055": "「%1」が別の計画を参加している為、選択できません。",
    "RS-0056": "上限の「%1」件に達しています。追加できません。",
    "RS-0057": "他のユーザのテンプレートは削除できません。",
    "RS-0058": "他のユーザのテンプレートは修正できません。",
    "RS-0059": "「その他」をチェックした場合、「その他記入欄」に入力してください。",
    "RS-0060": "「その他記入欄」を入力した場合、「その他」にチェックを入れて下さい。",
    "RS-0061": "「%1」の評価が選択されていません。",
    "RS-0062": "同一の送り出し機関が選択されています。",
    "RS-0063": "指定した面接日時を過ぎています。",
    "RS-0064": "会議参加者数が上限「%1」人を超えています。",
    "RS-0065": "面接日の登録に失敗しました。時間をおいて再度登録して下さい。",
    "RS-0066": "未登録の内容は破棄されます。よろしいですか？",
    "RS-0067": "このメールアドレスは使用できません。",
    "RS-0068": "メールアドレスが重複しています。",
    "RS-0070": "対象データが存在しません。",
    "RS-0069": "応募者を登録する場合、ユーザ名は半角20文字以内で入力してください。"

};
export const TEST_AC_1 = ['GAFMCMCFOM01', 'GAFMCMCFUM03', 'GAFMCMCFOM02', 'GAFMCMCFUM01', 'GAFMCMCFUM02', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFIA01', 'GAFMCMCFRM01', 'GAFMCMCFUM05', 'GAFMCMCFAM01', 'GAFMCMCFIP01', 'GAFMCMCFIP02', 'GAFMCMCFDM01', 'GAFMCMCFDI01', 'GAFMCMCFWT01', 'GAFMCMCFWT02', 'GAFMCMCFAA01', 'GAFMCMCFTT01', 'GAFMCMCFPP01', 'GAFMCMCFCR01'];
export const TEST_UNI = ['GAFMCMCFUM03', 'GAFMCMCFOM02', 'GAFMCMCFUM01', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFAM01'];
export const TEST_OKR = ['GAFMCMCFUM03', 'GAFMCMCFOM02', 'GAFMCMCFUM01', 'GAFMCMCFUM04', 'GAFMCMCFMM01'];
export const TEST_COD = ['GAFMCMCFOM01', 'GAFMCMCFUM03', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFIA01', 'GAFMCMCFRM01', 'GAFMCMCFIP01'];
export const TEST_KAN = ['GAFMCMCFUM03', 'GAFMCMCFOM02', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFAM01', 'GAFMCMCFIP01'];
export const TEST_TOR = ['GAFMCMCFUM03', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFIP01'];
export const TEST_COP = ['GAFMCMCFUM03', 'GAFMCMCFOM02', 'GAFMCMCFUM04', 'GAFMCMCFMM01', 'GAFMCMCFAA01', 'GAFMCMCFAM01', 'GAFMCMCFIP01'];
export const TEST_INT = ['GAFMCMCFOM02', 'GAFMCMCFUM02', 'GAFMCMCFUM04', 'GAFMCMCFUM05', 'GAFMCMCFMM01', 'GAFMCMCFWT01', 'GAFMCMCFWT02', 'GAFMCMCFAM01'];
export const TEST_TOK = ['GAFMCMCFOM02', 'GAFMCMCFUM02', 'GAFMCMCFUM04', 'GAFMCMCFUM05', 'GAFMCMCFMM01', 'GAFMCMCFWT01', 'GAFMCMCFWT02'];
export const TEST_GNO = ['GAFMCMCFOM02', 'GAFMCMCFUM02', 'GAFMCMCFUM04', 'GAFMCMCFUM05', 'GAFMCMCFMM01', 'GAFMCMCFWT01', 'GAFMCMCFWT02'];
//export const TEST_AC_1 = ['GAFMCMCFMS01'];
//export const TEST_AC_1 = ['123', '23'];
export const TEST_COGNITO = {
    'ClientId': "3ose8l656knp6t17eo4c1jfl3n",
    'UserPoolId': "ap-northeast-1_7YPnSdfLi"
};

export const testData = () => {

    setBaseUrl("http://localhost:8110");
    setMessageMaster(TEST_MESSAGE);
    setCodeMaster(TEST_CODE);
    setAccessControlMaster(TEST_AC_1);
    setCognitoInfo(TEST_COGNITO);
    setCountryMaster(TEST_COUNTRY_MASTER);
    setIndustryMaster(TEST_INDUSTRY_MASTER);
    setPrefecturesMaster(TEST_PREFECTURES_MASTER);
};

export const getMessage = () => {



};
