/**
 * Action Typesを定義
 */
const GTMWTWTA01ActionType = {
    WORKINGTIME_APPROVAL_REQUEST: 'WORKINGTIME_APPROVAL_REQUEST',
    WORKINGTIME_APPROVAL_SUCCESS: 'WORKINGTIME_APPROVAL_SUCCESS',
    WORKINGTIME_APPROVAL_FAILURE: 'WORKINGTIME_APPROVAL_FAILURE',
    WORKINGTIME_APPROVAL_RESET: 'WORKINGTIME_APPROVAL_RESET'
};

export default GTMWTWTA01ActionType;