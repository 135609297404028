/**
 * Action Typesを定義
 */
const GICIAIAS01ActionType = {
    INSURANCE_APPLICATION_LIST_REQUEST: 'INSURANCE_APPLICATION_LIST_REQUEST',  // 保険申込一覧検索定義
    INSURANCE_APPLICATION_LIST_SUCCESS: 'INSURANCE_APPLICATION_LIST_SUCCESS',  // 保険申込一覧検索成功定義
    INSURANCE_APPLICATION_LIST_FAILURE: 'INSURANCE_APPLICATION_LIST_FAILURE',  // 保険申込一覧検索失敗定義
    INSURANCE_APPLICATION_LIST_RESET: 'INSURANCE_APPLICATION_LIST_RESET',       // 保険申込一覧検索リセット定義
    INSURANCE_APPLICATION_LIST_UPDATE: 'INSURANCE_APPLICATION_LIST_UPDATE',  // 保険申込一覧データ更新
    INSURANCE_APPLICATION_UPDATE_REQUEST: 'INSURANCE_APPLICATION_UPDATE_REQUEST',  // 保険申込一覧更新定義
    INSURANCE_APPLICATION_UPDATE_SUCCESS: 'INSURANCE_APPLICATION_UPDATE_SUCCESS',  // 保険申込一覧更新成功定義
    INSURANCE_APPLICATION_UPDATE_FAILURE: 'INSURANCE_APPLICATION_UPDATE_FAILURE',  // 保険申込一覧更新失敗定義
    INSURANCE_APPLICATION_UPDATE_RESET: 'INSURANCE_APPLICATION_UPDATE_RESET'       // 保険申込一覧更新リセット定義
};

export default GICIAIAS01ActionType;
