import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import moment from "moment";
import { isBlank, checkArray, timeConvert } from "../../Components/Tools/StringTools";
import { getWorkingtimeInquiry, workingtimeInquiryReset } from "./GTMWTWTI01Action";
import { getCodeName } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import "../../Css/Common.css";

/**
 * 作業時間照会
 */
class GTMWTWTI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            date: this.props.detailData == null ? moment().format('YYYY/MM/DD') : this.props.detailData.work_report_date,
            employment_name_kana: this.props.detailData.employment_name_kana
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
    }

    /**
     * 作業時間取得
     * @param  {...any} args
     */
    getWorkingtimeInquiry = (...args) => {
        this.props.workingtimeInquiryReset();
        let params = {
            user_id: this.props.detailData.user_id,
            case_id: this.props.detailData.case_id,
            target_month: args[0],
            get_type: args[1]
        };
        this.props.getWorkingtimeInquiryList(params);
    }

    /**
     * 前月
     */
    lastMonth = () => {
        this.setState(prevState => ({
            date: moment(moment(prevState.date).subtract(1, 'months').format('YYYY/MM/DD')).format('YYYY/MM/DD'),
        }), () => {
            this.getWorkingtimeInquiry(this.state.date, '2');
        });
    }

    /**
     * 次月
     */
    nextMonth = () => {
        this.setState(prevState => ({
            date: moment(moment(prevState.date).add(1, 'months').format('YYYY/MM/DD')).format('YYYY/MM/DD'),
        }), () => {
            this.getWorkingtimeInquiry(this.state.date, '2');
        });
    }

    /**
     * 画面切替表示
     * @param {*} date
     */
    handleToDisplayWithData = (date) => {
        window.scrollTo(0, 0);
        this.props.workingtimeInquiryReset();
        // 作業時間登録へ遷移する
        date['employment_name_kana'] = this.state.employment_name_kana;
        return this.props.changeDispWithData("GTMWTWTR01", date);
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo) => {
        window.scrollTo(0, 0);
        return this.props.changeDisp(changeTo);
    }

    render() {
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">作業時間照会</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.handleToDisplay('GAFMCMCFWT01')}>ユーザ一覧</a></li>
                                <li>作業時間照会</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={{}} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">{moment(this.state.date).format('YYYY年MM月')}</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.lastMonth()} tabIndex="1">前月</button>
                                        <button type="button" className={moment(this.state.date).format('YYYY年MM月') === moment().format('YYYY年MM月') ? "el_btn is_disabled" : "el_nextBtn"} onClick={() => this.nextMonth()} tabIndex="2">次月</button>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>氏名：{this.state.employment_name_kana}</span></h3>
                                        </div>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr>
                                                        <th>日付</th>
                                                        <th>開始時間</th>
                                                        <th>終了時間</th>
                                                        <th>休憩時間</th>
                                                        <th>勤務時間</th>
                                                        <th>休暇区分</th>
                                                        <th>承認者</th>
                                                        <th>承認日</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(this.props.data)) {
                                                            return (
                                                                this.props.data.map(item => (
                                                                    <tr key={item.work_report_date}>
                                                                        <td className="hp_tar">
                                                                            <a href="#" className="el_link" onClick={() => this.handleToDisplayWithData(item)}>
                                                                                {item.year_month}
                                                                            </a>
                                                                        </td>
                                                                        <td className="hp_tar">{item.work_start_time}</td>
                                                                        <td className="hp_tar">{item.work_end_time}</td>
                                                                        <td className="hp_tar">{!isBlank(item.rest_minute) && (item.rest_minute != 0 || item.rest_flg == '0') ? (item.rest_minute + '分') : null}</td>
                                                                        <td className="hp_tar">{!isBlank(item.work_minute) && (item.work_minute != 0 || item.rest_flg == '0') ? timeConvert(item.work_minute) : null}</td>
                                                                        <td>{getCodeName('RSC0023', item.rest_flg)}</td>
                                                                        <td>{item.user_name}</td>
                                                                        <td className="hp_tar">{item.work_approval_datetime}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.handleToDisplay('GAFMCMCFWT01')} tabIndex="3">ユーザ一覧へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getWorkingtimeInquiry.isFetching,
        data: state.getWorkingtimeInquiry.data,
        isError: state.getWorkingtimeInquiry.isError,
        err_type: state.getWorkingtimeInquiry.err_type,
        err_detail: state.getWorkingtimeInquiry.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getWorkingtimeInquiryList: (args) => dispatch(getWorkingtimeInquiry(args)),
        workingtimeInquiryReset: () => dispatch(workingtimeInquiryReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GTMWTWTI01);

