// メッセージMap
export const MessageMap = {
    "university_name": "大学名",
    "university_location": "所在地",
    "university_start_year": "設立日(年)",
    "teacher_name": "担当先生名",
    "teacher_mail": "担当者先生メールアドレス",
    "teacher_tel_country_code": "担当者電話番号＿国・地域名",
    "teacher_tel1": "担当者電話番号＿市外局番",
    "teacher_tel2": "担当者電話番号＿市内局番",
    "teacher_tel3": "担当者電話番号加入者番号",
    "people": "学生の人数",
    "section": "設置区分"
};