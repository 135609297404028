import React from "react";
import { withRouter } from "react-router";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GICIAIAS01 from "../Ic/IaGICIAIAS01/GICIAIAS01";
import GICRMIAI01 from "../Ic/RmGICRMIAI01/GICRMIAI01";

/**
 * 保険契約管理機能テンプレート
 */
class IcTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId
        });
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        this.setState({
            display: dispId,
            detailData: detailData
        }, () => {
            this.props.getCandidateDetailReset();
        });
    }

    render() {
        let displayParts;
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
        if (this.state.display === "GICIAIAS01") {
            displayParts = <GICIAIAS01 changeDisp={this.switchDisplay} />;
        } else if (this.state.display === "GICRMIAI01") {
            displayParts = <GICRMIAI01 changeDisp={this.switchDisplay} />;
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

export default withRouter(IcTemplate);
