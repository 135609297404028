import React, { Fragment } from "react";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCognitoUserInfo, getMessage, getCodeName } from "../../Constants/CommonConstants";
import { MessageMap } from "./GIPPMPLI01Message";
import { getNumberText, checkArray, isBlank } from "../../Components/Tools/StringTools";
import { GetProgressList, progressDownloadFile, progressDownloadFileReset } from "./GIPPMPLI01Action";
import "../../Css/Common.css";
import "../../Css/GIPPMPLI01.css";

/**
 * 進捗一覧照会画面
 */
class GIPPMPLI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            search_case_title: "",
            search_case_id: "",
            search_company_name: "",
            search_accept_period_start: "",
            file_name: "",
            caseType: [],
            progressManagementStatus: [],
            downloadRes: {},
            err_msg: [],
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
        this.setCaseType = this.setCaseType.bind(this);
        this.setProgressManagementStatus = this.setProgressManagementStatus.bind(this);
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.handleToQuery();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.includes("hp_dn")) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        }
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        this.state.caseType[index] = event.target.checked;
        this.setState({
            caseType: this.state.caseType
        });
    };

    /**
     * ステータスを設定する
     * @param {*} index インデックス
     */
    setProgressManagementStatus = index => event => {
        this.state.progressManagementStatus[index] = event.target.checked;
        this.setState({
            progressManagementStatus: this.state.progressManagementStatus
        });
    };

    /**
     * 検索処理
     */
    handleToQuery = () => {
        window.scrollTo(0, 0);
        // 在留資格
        let casetype = "";
        this.state.caseType.map((key, value) => {
            if (key) {
                casetype = casetype + value + ",";
            }
        });
        if (casetype.length > 0) {
            casetype = casetype.substr(0, casetype.length - 1);
        }
        // ステータス
        let progressManagementStatus = "";
        this.state.progressManagementStatus.map((key, value) => {
            if (key) {
                progressManagementStatus = progressManagementStatus + value + ",";
            }
        });
        if (progressManagementStatus.length > 0) {
            progressManagementStatus = progressManagementStatus.substr(0, progressManagementStatus.length - 1);
        }
        this.setState({
            search_status_of_residence: casetype,
            search_progress_management_status: progressManagementStatus,
            downloadRes: {},
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getProgressList(this.state);
        });

    }

    /**
     * ダウンロードファイル
     */
    handleDownloadFile = event => {
        window.scrollTo(0, 0);
        event.preventDefault();
        event.stopPropagation();

        let downloadParam = {};
        // ファイル名
        const fileName = '進捗リスト_' + getCognitoUserInfo()['user_name'] + '.xlsx';
        this.setState({
            'file_name': fileName,
            'downloadRes': {}
        }, () => {
            // ボタンタイプ
            downloadParam['search_accept_period_start'] = this.state.apply_date_start;
            // ファイル名
            downloadParam['file_name'] = fileName;

            this.props.downloadFile(downloadParam);
        });
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} data
     */
    redirectToView = (data) => {
        window.scrollTo(0, 0);
        // 進捗管理へ遷移する
        return this.props.redirectToView("GIPPMPMF01", data);
    }

    render() {

        // ファイルダウンロード処理
        if (this.props.downloadData.data && this.props.downloadData.err_type === '200') {
            const blob = new Blob([this.props.downloadData.data], {
                type: this.props.downloadData.data.type
            });
            try {
                if (blob.size < 500) {
                    // メッセージの場合
                    const reader = new FileReader();
                    const _this = this;
                    reader.addEventListener('load', function (event) {
                        const arrayBuffer = event.target.result;
                        const myescape = (str) => {
                            return str.replace(/[^a-zA-Z0-9@*_+\-./]/g, function (m) {
                                const code = m.charCodeAt(0);
                                if (code <= 0xff) {
                                    return '%' + ('00' + code.toString(16)).slice(-2).toUpperCase();
                                } else {
                                    return '%u' + ('0000' + code.toString(16)).slice(-4).toUpperCase();
                                }
                            });
                        };
                        const uint8Array = new Uint8Array(arrayBuffer);
                        const binStr = String.fromCharCode(...uint8Array);
                        const res = JSON.parse(decodeURIComponent(myescape(binStr)));
                        window.scrollTo(0, 0);
                        _this.setState({ downloadRes: res });

                    });
                    reader.readAsArrayBuffer(blob);

                } else {
                    // ファイルの場合
                    if (window.navigator.msSaveBlob) {
                        window.navigator.msSaveBlob(blob, this.state.file_name);
                    } else {
                        saveAs(blob, this.state.file_name);
                    }
                }
            }
            catch (e) {
            }
            this.props.downloadFileReset();
        }

        // 件数が100件を超える場合
        if (this.props.progressList.data != null && this.props.progressList.data.overflowflg === '1' && this.state.err_msg.length == 0) {
            this.setState({
                err_msg: [getMessage('RS-0017').replace(/%1/g, '100')]
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                入国手続き進捗一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>
                                    入国手続き進捗一覧
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                {this.props.progressList != null ? <ErrorMessage err_type={this.props.progressList.err_type} err_detail={this.props.progressList.err_detail} messageMap={MessageMap} /> : null}
                                <ErrorMessage err_type={this.props.downloadData.err_type} err_detail={this.props.downloadData.err_detail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.state.downloadRes.err_type} err_detail={this.state.downloadRes.err_detail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                })()}
                                <div className="bl_sect_inner">
                                    <div className="bl_searchBtn">
                                        <button tabIndex="1" className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                    </div>
                                    <div className={this.state.toggleclass}>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>
                                                        <TextInput maxlength="40" tabIndex="2" type="text" setValue={this.state.search_case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__max" name="search_case_title" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>案件ID</th>
                                                    <td>
                                                        <TextInput maxlength="11" tabIndex="3" type="text" setValue={this.state.search_case_id} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__mid" name="search_case_id" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input tabIndex="4" className="el_checkbox" type="checkbox" name="search_gno" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} />
                                                                <span className="el_checkbox_txt">技能実習</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input tabIndex="5" className="el_checkbox" type="checkbox" name="search_tok" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} />
                                                                <span className="el_checkbox_txt">特定技能</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input tabIndex="6" className="el_checkbox" type="checkbox" name="search_int" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} />
                                                                <span className="el_checkbox_txt">インターン</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>
                                                        <TextInput maxlength="60" tabIndex="7" type="text" setValue={this.state.search_company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} className="el_input el_input__max" name="search_company_name" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        受入・就業開始年月
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput maxlength="10" tabIndex="8" type="month" name="search_accept_period_start" className="el_input el_input__datepicker" placeholder="年/月" setValue={this.state.search_accept_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>ステータス</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <label className="el_label">
                                                                <input tabIndex="9" type="checkbox" className="el_checkbox" name="" checked={this.state.progressManagementStatus[1]} onChange={this.setProgressManagementStatus(1)} />
                                                                <span className="el_checkbox_txt"> 完了</span>
                                                            </label>
                                                            <label className="el_label">
                                                                <input tabIndex="10" type="checkbox" className="el_checkbox" name="" checked={this.state.progressManagementStatus[0]} onChange={this.setProgressManagementStatus(0)} />
                                                                <span className="el_checkbox_txt"> 未完了</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>


                                        </table>
                                        <div className="bl_horizBtnUnit">
                                            <button tabIndex="11" type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleToQuery()}>検索</button>
                                        </div>
                                    </div>

                                    {getCognitoUserInfo()["org_type"] == 'COD' ? <Fragment>
                                        <div class="bl_sect_header">
                                            <h2 className="el_lv3Heading"><span>進捗リスト出力</span></h2>
                                        </div>

                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                    受入・就業開始年月
                                                </th>
                                                    <td width="18%" className="tdpadding">
                                                        <div className="divpadding">
                                                            <TextInput maxlength="10" tabIndex="12" type="month" placeholder="年/月" name="apply_date_start" className="el_input el_input__datepicker divpadding" setValue={this.state.apply_date_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} />
                                                        </div>

                                                    </td>
                                                    <td>
                                                        <div className="bl_horizBtnUnit__move">
                                                            <button tabIndex="13" type="button" className="el_btn el_shadowBtn hp_mb20" onClick={this.handleDownloadFile}>進捗リスト</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <br />
                                    </Fragment> : null}

                                    <div className="bl_sect_header">
                                        <h2 className="el_lv3Heading"><span>案件一覧</span></h2>
                                    </div>

                                    <section className="bl_sect">
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table mcTable_align">
                                                <thead>
                                                    <tr><th>案件名</th><th>案件ID</th><th>在留資格</th><th>企業名</th><th>受入・就業期間</th><th>ステータス</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (this.props.progressList != null && checkArray(this.props.progressList.data.list)) {
                                                            return (
                                                                this.props.progressList.data.list.map((item, index) => (
                                                                    <Fragment key={item.plan_id}>
                                                                        <tr>
                                                                            <td><a href="#" className="el_link" onClick={() => this.redirectToView(item)}>{item.case_title}</a></td>
                                                                            <td className="hp_tar">{item.case_id}</td>
                                                                            <td>{getCodeName('RSC0004', item.status_of_residence)}</td>
                                                                            <td>{item.company_name}</td>
                                                                            <td>{isBlank(item.accept_period_start) && isBlank(item.accept_period_end) ? '' : item.accept_period_start + '〜' + item.accept_period_end}</td>
                                                                            <td>{item.progress_management_status == '1' ? '完了' : '未完了'}</td>
                                                                        </tr>
                                                                    </Fragment>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        progressList: state.getProgressList,
        downloadData: state.progressDownloadFile
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getProgressList: (condition) => dispatch(GetProgressList(condition)), // 実習計画案件取得
        downloadFile: (arg) => dispatch(progressDownloadFile(arg)),
        downloadFileReset: () => dispatch(progressDownloadFileReset())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GIPPMPLI01);
