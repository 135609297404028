/**
 * Action Typesを定義
 */
const GAFMCMCF01ActionType = {
    MENU_USER_LOGOUT: "MENU_USER_LOGOUT",// ユーザログアウト
    MENU_CLEAR_DATA: "MENU_CLEAR_DATA",// クリアストアデータ
    MENU_LOADING_SHOW: "MENU_LOADING_SHOW",// ローディング画面表示
    MENU_LOADING_CLOSE: "MENU_LOADING_CLOSE",// ローディング画面非表示
    MENU_ERROR_SHOW: "MENU_ERROR_SHOW",// エラー画面表示
    MENU_ERROR_CLOSE: "MENU_ERROR_CLOSE",// エラー画面非表示
    MENU_ERROR_CLOSE_CALLBACK: "MENU_ERROR_CLOSE_CALLBACK"// エラー画面非表示時のコールバック
};

export default GAFMCMCF01ActionType;
