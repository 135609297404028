import actionTypes from "./GAFLFLAF02ActionType";

/**
 * 個人情報取り扱い同意取得
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: [],
};

/**
 * 個人情報取り扱い同意取得Reducer
 * @param {*} state
 * @param {*} action
 */
export const getPersonalInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_PERSONAL_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: [],
            });
        case actionTypes.GET_PERSONAL_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail,
            });
        case actionTypes.GET_PERSONAL_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: [],
            });
        case actionTypes.GET_PERSONAL_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: [],
            });
        default:
            return state;
    }
};

/**
 * 個人情報取り扱い同意Reducer
 * @param {*} state
 * @param {*} action
 */
export const updatePersonalInfo = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PERSONAL_INFO_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: [],
            });
        case actionTypes.UPDATE_PERSONAL_INFO_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail,
            });
        case actionTypes.UPDATE_PERSONAL_INFO_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: [],
            });
        case actionTypes.UPDATE_PERSONAL_INFO_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: [],
            });
        default:
            return state;
    }
};