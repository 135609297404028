import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import AfContainer from '../Af/container';
import AfHomeContainer from '../Af/home';
import CmHomeContainer from '../Cm/container';
import RsContainer from "../Rs/container";
import IcContainer from "../Ic/container";
import TmContainer from "../Tm/container";
import IsContainer from "../Is/container";
import IpContainer from "../Ip/container";
import EfContainer from "../Ef/container";
import EsContainer from "../Es/container";
import UiContainer from "../Ui/container";
import SmContainer from "../Sm/container";
import { getCognitoPool } from "../Constants/CommonConstants";

function App() {
    let userPool = getCognitoPool();
    let currentUser = null;
    if (userPool != null) {
        currentUser = userPool.getCurrentUser();
    }
    return (
        <BrowserRouter>
            <Switch>
                <Route exact path="/login" component={AfContainer}></Route>
            </Switch>
            <Route path="/af/home" component={AfHomeContainer} />
            <Route path="/rs/home" component={RsContainer} />
            <Route path="/cm/home" component={CmHomeContainer} />
            <Route path="/ic/home" component={IcContainer} />
            <Route path="/tm/home" component={TmContainer} />
            <Route path="/is/home" component={IsContainer} />
            <Route path="/ip/home" component={IpContainer} />
            <Route path="/ef/home" component={EfContainer} />
            <Route path="/es/home" component={EsContainer} />
            <Route path="/ui/home" component={UiContainer} />
            <Route path="/Sm/home" component={SmContainer} />
            {currentUser ? null : <Redirect to={'/login'} />}
        </BrowserRouter>
    );
}

export default App;
