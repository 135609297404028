import React from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GTMWTWTA01 from "../Tm/WtGTMWTWTA01/GTMWTWTA01";
import GTMWTWTI01 from "../Tm/WtGTMWTWTI01/GTMWTWTI01";
import GTMWTWTR01 from "../Tm/WtGTMWTWTR01/GTMWTWTR01";
import GTMWTWTR02 from "../Tm/WtGTMWTWTR02/GTMWTWTR02";
import GCMOMCUM01 from "../Cm/OmGCMOMCUM01/GCMOMCUM01";
import { isBlank } from "../Components/Tools/StringTools";
import { getTrainningWorkerList, getTrainningWorkerListReset } from "../Cm/OmGCMOMCUM01/GCMOMCUM01Action";
import { getWorkingtimeInquiry, workingtimeInquiryReset } from "../Tm/WtGTMWTWTI01/GTMWTWTI01Action";
import { } from "../Tm/WtGTMWTWTR02/GTMWTWTR02Action";

/**
 * 作業時間管理機能テンプレート
 */
class TmTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: null,
            beforeDispId: null,
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
        });
    }

    /**
     * 画面初期化処理を呼び出す
     */
    callInitialization = () => {
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        this.setState({
            display: dispId,
            detailData: detailData
        }, () => {
            this.props.getTrainningWorkerListReset();
            this.props.workingtimeInquiryReset();
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplayWithData = (dispId, detailData) => {
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            detailData: detailData
        }, () => {
            this.props.workingtimeInquiryReset();
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplayInquiry = (dispId, detailData) => {
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            detailData: detailData
        }, () => {
            this.props.getWorkingtimeInquiryList({
                user_id: detailData.user_id,
                case_id: detailData.case_id,
                target_month: detailData.work_report_date,
                get_type: '2'
            });
        });
    };

    /**
     * 状態リセット
     */
    workingtimeInquiryErrorDisable = () => {
        this.props.workingtimeInquiryReset();
    };

    render() {
        let displayParts;
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
        if (this.state.display === "GTMWTWTA01") {
            // 作業時間承認
            displayParts = <GTMWTWTA01 changeDisp={this.switchDisplay} detailData={this.state.detailData} />;
            this.callInitialization();
        } else if (this.state.display === "GTMWTWTI01") {
            // 作業時間照会
            displayParts = <GTMWTWTI01 changeDisp={this.switchDisplay} changeDispWithData={this.switchDisplayWithData} detailData={this.state.detailData} inquiryData={this.props.data} workingtimeInquiryReset={this.workingtimeInquiryErrorDisable} />;
            this.callInitialization();
        } else if (this.state.display === "GTMWTWTR01") {
            // 作業時間登録
            displayParts = <GTMWTWTR01 changeDisp={this.switchDisplay} beforeDispId={this.state.beforeDispId} detailData={this.state.detailData} workingtimeInquiryReset={this.workingtimeInquiryErrorDisable} switchDisplayInquiry={this.switchDisplayInquiry} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GTMWTWTR02") {
            // 簡易作業時間登録
            displayParts = <GTMWTWTR02 changeDisp={this.switchDisplay} />;
            this.callInitialization();
        } else if (this.state.display === "GAFMCMCFWT01") {
            // ユーザ一覧(作業時間管理)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFWT01" key="GAFMCMCFWT01" switchDisplayInquiry={this.switchDisplayInquiry} />;
            this.callInitialization();
        } else if (this.state.display === "GAFMCMCFAA01") {
            // ユーザ一覧(勤怠承認)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFAA01" key="GAFMCMCFAA01" switchDisplayInquiry={this.switchDisplayInquiry} />;
            this.callInitialization();
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

/**
 * データ取得のStateバインディングする
 * @param {*} state
 */
const mapStateToProps = (state) => {
    return {
        isFetching: state.getWorkingtimeInquiry.isFetching,
        data: state.getWorkingtimeInquiry.data,
        isError: state.getWorkingtimeInquiry.isError,
        err_type: state.getWorkingtimeInquiry.err_type,
        err_detail: state.getWorkingtimeInquiry.err_detail,
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        getWorkingtimeInquiryList: (args) => dispatch(getWorkingtimeInquiry(args)),
        getTrainningWorkerListReset: () => dispatch(getTrainningWorkerListReset()),
        workingtimeInquiryReset: () => dispatch(workingtimeInquiryReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TmTemplate));

