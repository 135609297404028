import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GISDMIRR01ActionType';

// レポート内容登録初期取得API
const GET_REPORT_CONTENT_INFO_POST = BASE_URL + "/api/is_dm_get_internship_report_info";

// レポート内容登録登録API
const SAVE_REPORT_CONTENT_INFO_POST = BASE_URL + "/api/is_dm_update_internship_report_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetReportContentRequest = () => {
    return {
        type: actionTypes.GET_REPORT_CONTENT_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetReportContentSuccess = (data) => {
    return {
        type: actionTypes.GET_REPORT_CONTENT_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetReportContentFailure = (err) => {
    return {
        type: actionTypes.GET_REPORT_CONTENT_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetReportContentReset = () => {
    return {
        type: actionTypes.GET_REPORT_CONTENT_RESET
    };
};

/**
 * 登録リクエスト
 */
export const SaveReportContentRequest = () => {
    return {
        type: actionTypes.SAVE_REPORT_CONTENT_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const SaveReportContentSuccess = (data) => {
    return {
        type: actionTypes.SAVE_REPORT_CONTENT_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const SaveReportContentFailure = (err) => {
    return {
        type: actionTypes.SAVE_REPORT_CONTENT_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const SaveReportContentReset = () => {
    return {
        type: actionTypes.SAVE_REPORT_CONTENT_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * レポート内容登録初期取得処理
 * @param {*} data
 */
export const getReportContentInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetReportContentRequest());
        ecoPostWithAuthHeader(GET_REPORT_CONTENT_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetReportContentSuccess(res.data))
        ).catch(
            err => dispatch(GetReportContentFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const saveReportContentInfo = (data) => {
    return async (dispatch) => {
        dispatch(SaveReportContentRequest());
        ecoPostWithAuthHeader(SAVE_REPORT_CONTENT_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(SaveReportContentSuccess(res.data))
        ).catch(
            err => dispatch(SaveReportContentFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getReportContentReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetReportContentReset());
    };
};

/**
 * 画面リセットAction
 */
export const saveReportContentReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveReportContentReset());
    };
};