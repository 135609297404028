import React, { Fragment } from "react";

import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getChangeDateText, getDateTextYM } from "../../Components/Tools/StringTools.js";
import { getTrainningWorkerDetailReset } from "../OmGCMOMCUM02/GCMOMCUM02Action";
import { checkArray } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";
import YouTube from "react-youtube";

/**
 * ユーザ詳細画面
 */
class GCMOMCUM02 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            trainningWorkerData: this.props.detailData
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        this.setState({
            status_of_residence_name: this.state.trainningWorkerData.status_of_residence_name,       // 在留資格名
            status_of_residence: this.state.trainningWorkerData.status_of_residence,       // 在留資格
            candidate_id: this.state.trainningWorkerData.candidate_id,       // 実習／労働者ID
            employment_photo: this.state.trainningWorkerData.employment_photo,       // 写真
            organization_name: this.state.trainningWorkerData.organization_name,       // 所属組織
            employment_student_no: this.state.trainningWorkerData.employment_student_no,       // 学籍番号
            employment_name: this.state.trainningWorkerData.employment_name,       // 氏名_ローマ字
            employment_name_kana: this.state.trainningWorkerData.employment_name_kana,       // 氏名_フリガナ
            employment_nickname: this.state.trainningWorkerData.employment_nickname,       // 氏名_ニックネーム
            employment_born_country_name: this.state.trainningWorkerData.employment_born_country_name,       // 国籍
            employment_bitrhday: this.state.trainningWorkerData.employment_bitrhday ? getChangeDateText(this.state.trainningWorkerData.employment_bitrhday) : "",       // 生年月日
            employment_sex_name: this.state.trainningWorkerData.employment_sex_name,       // 性別
            employment_japanese_level: this.state.trainningWorkerData.employment_japanese_level,       // 日本語レベル
            employment_language: this.state.trainningWorkerData.employment_language,       // 得意言語
            employment_department: this.state.trainningWorkerData.employment_department,       // 学部・専攻
            employment_acquired_credits: this.state.trainningWorkerData.employment_acquired_credits,       // 取得済単位数
            employment_intern_schedule_credits: this.state.trainningWorkerData.employment_intern_schedule_credits,       // インターンでの取得予定単位数
            employment_required_credits: this.state.trainningWorkerData.employment_required_credits,       // 卒業までに必要な単位数
            employment_appeal: this.state.trainningWorkerData.employment_appeal,       // 自己アピール
            license: this.state.trainningWorkerData.license,       // 保有資格
            video_url_display: this.state.trainningWorkerData.video_url ? this.youtubeParser(this.state.trainningWorkerData.video_url) : "",                   // 動画URL
            video_url_trans: this.state.trainningWorkerData.video_url,                   // 動画URL
            work_history: this.state.trainningWorkerData.work_history,       // 職歴
            work_history_list: this.state.trainningWorkerData.work_history_list,       // 職歴
            working_practice_history: this.state.trainningWorkerData.working_practice_history ? this.state.trainningWorkerData.working_practice_history : [],       // 就労/実習履歴
            education_history: this.state.trainningWorkerData.education_history ? this.state.trainningWorkerData.education_history : []                             // 教育履歴
        });
    }

    // youtubeのリンクから、IDを取得
    youtubeParser(url) {
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length == 11) ? match[1] : false;
    }

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.resetTrainningWorkerDetailState();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    openVideo = (event) => {
        event.target.stopVideo();
        document.getElementById(this.state.video_url_display).click();
    }

    render() {
        const { working_practice_history, education_history, status_of_residence, work_history_list } = this.state;

        // 表示、非表示項目
        const isShow = status_of_residence === '2' ? 'table-row' : 'none';

        return (
            <article>
                <div class="ly_cont">
                    <div class="bl_mainHeader">
                        <div class="bl_mainHeader_inner">
                            <h1 class="el_lv1Heading">ユーザ詳細</h1>
                        </div>
                    </div>
                    <div class="ly_mainCont">
                        <nav class="bl_breadcrumb">
                            <ol class="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.handleToDisplay("GCMOMCUM01", true)}>ユーザ一覧</a>
                                </li>
                                <li>ユーザ詳細</li>
                            </ol>
                        </nav>
                        <section class="bl_sect">
                            <div class="ly_mainCont_inner">
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">ユーザ詳細</h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>{this.state.status_of_residence_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>応募者ID</th>
                                                    <td>{this.state.candidate_id}</td>
                                                </tr>
                                                <tr>
                                                    <th>写真</th>
                                                    <td>
                                                        <figure class="un_applicantPhoto">
                                                            <img src={this.state.employment_photo} alt="" className="el_headerLogo_img" />
                                                        </figure>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>自己紹介動画</th>
                                                    {(() => {
                                                        if (this.state.video_url_trans) {
                                                            return (
                                                                <td>
                                                                    <div>
                                                                        <figure>
                                                                            <YouTube videoId={this.state.video_url_display} className={"un_applicantVideo"} onPlay={this.openVideo} tabIndex="1" />
                                                                            <u><a style={{ display: "none" }} id={this.state.video_url_display} href={this.state.video_url_trans} target="_blank"></a></u>
                                                                        </figure>
                                                                    </div>
                                                                </td>
                                                            );
                                                        }
                                                    })()}
                                                </tr>
                                                <tr>
                                                    <th>所属組織</th>
                                                    <td>{this.state.organization_name}</td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>学籍番号</th>
                                                    <td>{this.state.employment_student_no}</td>
                                                </tr>
                                                <tr>
                                                    <th>氏名<br />（ローマ字）</th>
                                                    <td>{this.state.employment_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>氏名<br />（フリガナ）</th>
                                                    <td>{this.state.employment_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>氏名<br />（ニックネーム）</th>
                                                    <td>{this.state.employment_nickname}</td>
                                                </tr>
                                                <tr>
                                                    <th>国籍</th>
                                                    <td>{this.state.employment_born_country_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>生年月日</th>
                                                    <td>{this.state.employment_bitrhday}</td>
                                                </tr>
                                                <tr>
                                                    <th>性別</th>
                                                    <td>{this.state.employment_sex_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>日本語レベル</th>
                                                    <td>{this.state.employment_japanese_level}</td>
                                                </tr>
                                                <tr>
                                                    <th>得意言語</th>
                                                    <td>{this.state.employment_language}</td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>学部・専攻</th>
                                                    <td>{this.state.employment_department}</td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>取得済単位数</th>
                                                    <td>{this.state.employment_acquired_credits}</td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>インターンでの取得予定単位数</th>
                                                    <td>{this.state.employment_intern_schedule_credits}</td>
                                                </tr>
                                                <tr style={{ display: isShow }}>
                                                    <th>卒業までに必要な単位数</th>
                                                    <td>{this.state.employment_required_credits}</td>
                                                </tr>
                                                <tr>
                                                    <th>自己アピール</th>
                                                    <td>
                                                        {this.state.employment_appeal ? this.state.employment_appeal.split('\n').map((str, index) => (
                                                            <Fragment key={index}>{str}<br /></Fragment>
                                                        )) : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>保有資格</th>
                                                    <td>
                                                        {this.state.license ? this.state.license.split('\n').map((str, index) => (
                                                            <Fragment key={index}>{str}<br /></Fragment>
                                                        )) : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>日本での職歴</th>
                                                    <td>
                                                        {
                                                            checkArray(work_history_list) && work_history_list.map((item, index) => {
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>
                                                                            {item.work_history_ym_from}
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history_ym_from ? '－' : null}
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history_ym_to}&nbsp;
                                                                        </td>
                                                                        <td>
                                                                            {item.work_history}
                                                                        </td>
                                                                    </tr>
                                                                );
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">就労/実習履歴</h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                {
                                                    checkArray(working_practice_history) && working_practice_history.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.status_of_residence_name}<br />
                                                                    期間：{getDateTextYM(item.accept_start)}〜{getDateTextYM(item.accept_end)}<br />
                                                                    業種：{item.industry_name_j}<br />
                                                                    職種：{item.occupation}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">教育履歴</h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                {
                                                    checkArray(education_history) && education_history.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    {item.education_name}<br />
                                                                    完了年月：{getDateTextYM(item.education_end)}<br />
                                                                    ステータス：{item.education_status}
                                                                </td>
                                                            </tr>
                                                        );
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </section>
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.handleToDisplay("GCMOMCUM01", true)} tabIndex="2">ユーザ一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getTrainningWorkerDetail.isFetching,
        data: state.getTrainningWorkerDetail.data,
        isError: state.getTrainningWorkerDetail.isError,
        err_type: state.getTrainningWorkerDetail.err_type,
        err_detail: state.getTrainningWorkerDetail.err_detail
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        resetTrainningWorkerDetailState: () => dispatch(getTrainningWorkerDetailReset()),
    };
};

export default connect(null, mapDispatchToProps)(GCMOMCUM02);

