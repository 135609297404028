import React from 'react';
import AfTemplate from "./template";

function Home() {
  return (
    <section className="App">
      <AfTemplate />
    </section>
  );
}

export default Home;
