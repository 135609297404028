import React, { Fragment } from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCognitoUserInfo, getMessage, getCodeName } from "../../Constants/CommonConstants";
import { MessageMap } from "./GEFCRRRI01Message";
import { getNumberText, checkArray, isBlank } from "../../Components/Tools/StringTools";
import { getEducationAction, getEducationActionReset } from "./GEFCRRRI01Action";
import "../../Css/Common.css";

/**
 * 履修履歴詳細画面
 */
class GEFCRRRI01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            orgType: getCognitoUserInfo()["org_type"]
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.props.getEducationActionReset();
    }

    /**
     * 学習するURLを取得する
     */
    getActionUrl = () => {
        window.scrollTo(0, 0);
        this.props.getEducationAction({
            candidate_id: this.props.educationHistory.data.candidate_id,
            case_id: this.props.educationHistory.data.case_id
        });
    }

    /**
     * eラーニングサイト表示
     */
    showELearning = (data, profileData) => {
        // シングルサインオン
        const form = document.getElementById('actionForm');
        form.action = data.action_url;
        form.login.value = profileData.user_id;
        form.time.value = data.sys_now;
        form.key.value = data.key;
        form.email.value = data.email;
        form.name.value = data.name;
        form.add_product.value = data.add_product;
        form.add_product_key.value = data.add_product_key;
        form.submit();
    }

    render() {

        const historyInfo = this.props.educationHistory;

        let educationList = [];
        let educationHistoryList = [];
        if (historyInfo.data && checkArray(historyInfo.data.list)) {
            educationHistoryList = historyInfo.data.list.filter(i => i.education_status == '2');
            educationList = historyInfo.data.list.filter(i => i.education_status != '2');
        }

        if (this.props.educationAction && this.props.educationAction.err_type == '200') {
            // eラーニングサイト表示
            this.showELearning(this.props.educationAction.data, this.props.educationHistory.data);
            this.props.getEducationActionReset();
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                履修履歴詳細
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.props.changeDisp("GAFMCMCFCR01")}>ユーザ一覧</a></li>
                                <li>
                                    履修履歴詳細
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <form id="actionForm" method="post" action="" target="_blank" rel="noopener">
                                    <input type="hidden" name="sco_id" value="0" />
                                    <input type="hidden" name="login" value="" />
                                    <input type="hidden" name="time" value="" />
                                    <input type="hidden" name="key" value="" />
                                    <input type="hidden" name="add_account" value="1" />
                                    <input type="hidden" name="email" value="" />
                                    <input type="hidden" name="name" value="" />
                                    <input type="hidden" name="add_product" value="" />
                                    {/* <input type="hidden" name="nickname" value="" /> */}
                                    {/* <input type="hidden" name="add_group" value="" /> */}
                                    <input type="hidden" name="add_product_key" value="" />
                                </form>
                                <div className="bl_sect_inner">
                                    <ErrorMessage err_type={this.props.educationAction.err_type} err_detail={this.props.educationAction.err_detail} messageMap={{}} />
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <p><img src={this.props.educationHistory.data.employment_photo} alt="" /></p>
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                <dt>氏名：</dt>
                                                <dd>{this.props.educationHistory.data.employment_name_kana}</dd>
                                                <dt>所属：</dt>
                                                <dd>{this.props.educationHistory.data.organization_name}</dd>
                                                <dt>在留資格：</dt>
                                                <dd>{getCodeName('RSC0004', this.props.educationHistory.data.status_of_residence)}</dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <section className="bl_sect">

                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">未完了コンテンツ一覧</h2>
                                        </div>
                                        <div className="bl_sect_frame bl_sect_frame__pd0">
                                            {['INT', 'GNO', 'TOK'].includes(this.state.orgType) ? <div className="bl_horizBtnUnit__move">
                                                <button type="button" className="el_blueBtn" onClick={() => { this.getActionUrl() }}>学習する</button>
                                            </div> : null}
                                            <p className="el_note_sm hp_tar hp_mb10">※前日時点の情報を表示しています</p>
                                            <div className='sticky_table_wrapper'>
                                                <table className='sticky_table mcTable_align'>
                                                    <thead>
                                                        <tr><th>コンテンツ名</th><th>受講結果</th><th>受講開始日</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            return (
                                                                educationList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.education_name}</td>
                                                                        <td>{getCodeName('RSC0040', item.education_status)}</td>
                                                                        <td className="hp_tar">{item.education_start}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section>

                                    <section className="bl_sect">
                                        <div className="bl_sect_header">
                                            <h2 className="el_lv2Heading">履修履歴</h2>
                                        </div>
                                        <div className="bl_sect_frame bl_sect_frame__pd0">
                                            <p className="el_note_sm hp_tar hp_mb10">※前日時点の情報を表示しています</p>
                                            <div className='sticky_table_wrapper'>
                                                <table className='sticky_table mcTable_align'>
                                                    <thead>
                                                        <tr><th>業種</th><th>コンテンツ名</th><th>受講結果</th><th>合否</th><th>受講完了日</th></tr>
                                                    </thead>
                                                    <tbody>
                                                        {(() => {
                                                            return (
                                                                educationHistoryList.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.industry_name_j}</td>
                                                                        <td>{item.education_name}</td>
                                                                        <td>{getCodeName('RSC0040', item.education_status)}</td>
                                                                        <td>{item.education_result_status == '0' || item.education_result_status == '1' ? getCodeName('RSC0041', item.education_result_status) : ''}</td>
                                                                        <td className="hp_tar">{item.education_end}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        })()}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </section>

                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.props.changeDisp("GAFMCMCFCR01")}>ユーザ一覧へ</button>
                                    </div>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        educationHistory: state.getEducationHistoryDetail,
        educationAction: state.getEducationAction
    };
};


const mapDispatchToProps = dispatch => {
    return {
        getEducationAction: (arg) => dispatch(getEducationAction(arg)),
        getEducationActionReset: () => dispatch(getEducationActionReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GEFCRRRI01);
