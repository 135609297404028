import React, { Fragment } from "react";

import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { GetUniversityInfoReset } from "../OmGCMOMCRU04/GCMOMCRU04Action";
import { getEmployFacilityInfo } from "../OmGCMOMCRU08/GCMOMCRU08Action";
import { isBlank } from "../../Components/Tools/StringTools";
import "../../Css/Common.css";

/**
 * 応募者詳細画面
 */
class GCMOMCRU08 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            universityData: this.props.uniDetailData,
            companyAndFacilityData: this.props.facDetailData
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        this.setState({
            university_name: this.state.universityData.university_name,       // 大学名
            university_location: this.state.universityData.university_location,       // 所在地
            teacher_name: this.state.universityData.teacher_name,       // 担当先生名
            teacher_tel_country_code: this.state.universityData.teacher_tel_country_number,       // 担当先生電話(国コード)
            teacher_tel1: this.state.universityData.teacher_tel1,       // 担当先生電話番号１
            teacher_tel2: this.state.universityData.teacher_tel2,       // 担当先生電話番号２
            teacher_tel3: this.state.universityData.teacher_tel3,       // 担当先生電話番号３
            teacher_mail: this.state.universityData.teacher_mail,       // 担当先生メールアドレス
            facility_name: this.state.companyAndFacilityData.facility_name,       // 施設名
            facility_name_kana: this.state.companyAndFacilityData.facility_name_kana,       // 施設名(フリガナ)
            facility_prefectures_id: this.state.companyAndFacilityData.prefectures_name,       // 施設所在地_都道府県
            facility_address_2: this.state.companyAndFacilityData.facility_address_2,       // 施設所在地_施設所在地２
            home_page: this.state.companyAndFacilityData.home_page,       // ホームページ
            pic_tel_country_code: this.state.companyAndFacilityData.pic_tel_country_number,       // 担当者電話(国コード)
            pic_tel_1: this.state.companyAndFacilityData.pic_tel_1,       // 担当者電話番号１
            pic_tel_2: this.state.companyAndFacilityData.pic_tel_2,       // 担当者電話番号２
            pic_tel_3: this.state.companyAndFacilityData.pic_tel_3,       // 担当者電話番号３
            fax_country_code: this.state.companyAndFacilityData.fax_country_number,       // FAX番号(国コード)
            fax_1: this.state.companyAndFacilityData.fax_1,       // FAX番号１
            fax_2: this.state.companyAndFacilityData.fax_2,       // FAX番号２
            fax_3: this.state.companyAndFacilityData.fax_3,       // FAX番号３
            mail_address: this.state.companyAndFacilityData.mail_address       // メールアドレス
        });
    }

    render() {
        return (
            <article>
                <div class="ly_cont">
                    <div class="bl_mainHeader">
                        <div class="bl_mainHeader_inner">
                            <h1 class="el_lv1Heading">
                                組織情報(プロフィール)
                            </h1>
                        </div>
                    </div>
                    <div class="ly_mainCont">
                        <nav class="bl_breadcrumb">
                            <ol class="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section class="bl_sect">
                            <div class="ly_mainCont_inner">
                                <div class="bl_sect_header">
                                    <h2 class="el_lv2Heading">所属組織情報
                                    </h2>
                                </div>
                                <div class="bl_sect_inner">
                                    <section class="bl_sect_frame bl_sect_frame__pd0">
                                        <table class="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>大学名</th>
                                                    <td>{this.state.university_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>所在地</th>
                                                    <td>{this.state.university_location}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当先生名</th>
                                                    <td>{this.state.teacher_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者電話番号</th>
                                                    <td>{(isBlank(this.state.teacher_tel_country_code) && isBlank(this.state.teacher_tel1) && isBlank(this.state.teacher_tel2) && isBlank(this.state.teacher_tel3)) ? '' : this.state.teacher_tel_country_code + ' ' + this.state.teacher_tel1 + '-' + this.state.teacher_tel2 + '-' + this.state.teacher_tel3}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当先生メールアドレス</th>
                                                    <td>{this.state.teacher_mail}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                {
                                    this.props.facDetailData.company_id == null ? null : <Fragment>
                                        <div class="bl_sect_header">
                                            <h2 class="el_lv2Heading">就業施設情報</h2>
                                        </div>
                                        <div class="bl_sect_inner">
                                            <section class="bl_sect_frame bl_sect_frame__pd0">
                                                <table class="bl_horizTable02">
                                                    <tbody>
                                                        <tr>
                                                            <th>施設名</th>
                                                            <td>{this.state.facility_name}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>施設名(フリガナ)</th>
                                                            <td>{this.state.facility_name_kana}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>施設所在地</th>
                                                            <td>{this.state.facility_prefectures_id + ' ' + this.state.facility_address_2}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>ホームページ</th>
                                                            <td>{this.state.home_page}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>電話番号</th>
                                                            <td>{(isBlank(this.state.pic_tel_country_code) && isBlank(this.state.pic_tel_1) && isBlank(this.state.pic_tel_2) && isBlank(this.state.pic_tel_3)) ? '' : this.state.pic_tel_country_code + ' ' + this.state.pic_tel_1 + '-' + this.state.pic_tel_2 + '-' + this.state.pic_tel_3}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>FAX番号</th>
                                                            <td>{(isBlank(this.state.fax_country_code) && isBlank(this.state.fax_1) && isBlank(this.state.fax_2) && isBlank(this.state.fax_3)) ? '' : this.state.fax_country_code + ' ' + this.state.fax_1 + '-' + this.state.fax_2 + '-' + this.state.fax_3}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>メールアドレス</th>
                                                            <td>{this.state.mail_address}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                        </div>
                                    </Fragment>
                                }
                            </div>
                        </section>
                    </div>
                </div>
            </article >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getEmployFacilityInfo.isFetching,
        data: state.getEmployFacilityInfo.data,
        isError: state.getEmployFacilityInfo.isError,
        err_type: state.getEmployFacilityInfo.err_type,
        err_detail: state.getEmployFacilityInfo.err_detail
    };
};


const mapDispatchToProps = dispatch => {
    return {
        // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
        // その後、createStoreで登録したreducerに処理が移る
        resetUniversityState: () => dispatch(GetUniversityInfoReset()),

        getEmployFacilityInfo() {
            // 組織情報(プロフィール)インターン向け
            dispatch(getEmployFacilityInfo());
        }
    };
};

export default connect(null, mapDispatchToProps)(GCMOMCRU08);

