import { ecoPostWithAuthHeader, ecoPostDownloadPdfFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GISAMIRS01ActionType";

// 実習結果一覧取得API
const GET_TRAINING_RESULTS_POST = BASE_URL + "/api/is_am_get_training_results_info";

// 実習結果報告書ファイルダウンロードAPI
const TRAINING_RESULTS_FILE_POST = BASE_URL + "/api/is_am_download_training_report";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetTrainingResultsRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetTrainingResultsSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_SUCCESS,
        data,
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetTrainingResultsFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_FAILURE,
        err,
    };
};

/**
 * 取得リセット
 */
export const GetTrainingResultsReset = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_RESET
    };
};

/**
 * ダウンロードリクエスト
 */
export const TrainingResultsDownloadFileRequest = () => {
    return {
        type: actionTypes.TRAINING_RESULTS_DOWNLOAD_FILE_REQUEST
    };
};

/**
 * ダウンロード成功
 * @param {*} data
 */
export const TrainingResultsDownloadFileSuccess = (data) => {
    return {
        type: actionTypes.TRAINING_RESULTS_DOWNLOAD_FILE_SUCCESS,
        data
    };
};

/**
 * ダウンロード失敗
 * @param {*} err
 */
export const TrainingResultsDownloadFileFailure = (err) => {
    return {
        type: actionTypes.TRAINING_RESULTS_DOWNLOAD_FILE_FAILURE,
        err
    };
};

/**
 * ダウンロードリセット
 * @param {*} err
 */
export const TrainingResultsDownloadFileReset = (err) => {
    return {
        type: actionTypes.TRAINING_RESULTS_DOWNLOAD_FILE_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getTrainingResultsList = (data) => {
    return async (dispatch) => {
        dispatch(GetTrainingResultsRequest());
        ecoPostWithAuthHeader(GET_TRAINING_RESULTS_POST, JSON.stringify(data))
            .then((res) => dispatch(GetTrainingResultsSuccess(res.data)))
            .catch((err) => dispatch(GetTrainingResultsFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const trainingResultsListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainingResultsReset());
    };
};

/**
 * ダウンロードActionの発行
 */
export const trainingResultsDownloadFile = (arg) => {
    return async (dispatch) => {
        // Storeへ実習結果報告書ダウンロードリクエストActionをdispatchする
        dispatch(TrainingResultsDownloadFileRequest());
        ecoPostDownloadPdfFile(TRAINING_RESULTS_FILE_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへ実習結果報告書ダウンロード成功Actionをdispatchする
                dispatch(TrainingResultsDownloadFileSuccess(res.data));
            }).catch(err =>
                // Storeへ実習結果報告書ダウンロード失敗Actionをdispatchする
                dispatch(TrainingResultsDownloadFileFailure(err))
            );
    };
};

/**
 * 画面リセットAction
 */
export const trainingResultsDownloadFileReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(TrainingResultsDownloadFileReset());
    };
};

