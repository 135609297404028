/**
 * Action Typesを定義
 */
const GESSFCES01ActionType = {
    GET_OKR_UNI_USAGE_RESULTS_LIST_REQUEST: 'GET_OKR_UNI_USAGE_RESULTS_LIST_REQUEST',  // 送り出し機関・大学利用実績一覧取得定義
    GET_OKR_UNI_USAGE_RESULTS_LIST_SUCCESS: 'GET_OKR_UNI_USAGE_RESULTS_LIST_SUCCESS',  // 送り出し機関・大学利用実績一覧取得成功定義
    GET_OKR_UNI_USAGE_RESULTS_LIST_FAILURE: 'GET_OKR_UNI_USAGE_RESULTS_LIST_FAILURE',  // 送り出し機関・大学利用実績一覧取得失敗定義
    GET_OKR_UNI_USAGE_RESULTS_LIST_RESET: 'GET_OKR_UNI_USAGE_RESULTS_LIST_RESET',       // 送り出し機関・大学利用実績一覧取得リセット定義
};

export default GESSFCES01ActionType;