// メッセージMap
export const MessageMap = {
    "findings": "所見",
    "evaluation_item_01": "評価項目１",
    "evaluation_item_02": "評価項目２",
    "evaluation_item_03": "評価項目３",
    "evaluation_item_04": "評価項目４",
    "evaluation_item_05": "評価項目５",
    "evaluation_item_06": "評価項目６",
    "evaluation_item_07": "評価項目７",
    "evaluation_item_08": "評価項目８",
    "evaluation_item_09": "評価項目９",
    "evaluation_item_10": "評価項目１０",
    "evaluation_item_11": "評価項目１１",
    "evaluation_item_12": "評価項目１２",
    "evaluation_item_13": "評価項目１３",
    "evaluation_item_14": "評価項目１４",
    "evaluation_item_15": "評価項目１５",
    "evaluation_item_16": "評価項目１６",
    "evaluation_item_17": "評価項目１７",
    "evaluation_item_18": "評価項目１８",
    "evaluation_item_19": "評価項目１９",
    "evaluation_item_20": "評価項目２０"
};