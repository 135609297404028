import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GISAMIAR01ActionType';

// 実習結果評価取得API
const GET_TRAINING_RESULTS_EVALUATION_POST = BASE_URL + "/api/is_am_get_training_results_evaluation_detail";

// 実習結果評価登録API
const TRAINING_RESULTS_EVALUATION_POST = BASE_URL + "/api/is_am_update_training_results_evaluation_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const getTrainingResultsEvaluationRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_EVALUATION_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const getTrainingResultsEvaluationSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_EVALUATION_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const getTrainingResultsEvaluationFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_EVALUATION_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const resetTrainingResultsEvaluation = () => {
    return {
        type: actionTypes.GET_TRAINING_RESULTS_EVALUATION_RESET
    };
};

/**
 * 登録リクエスト
 */
export const saveTrainingResultsEvaluationRequest = () => {
    return {
        type: actionTypes.TRAINING_RESULTS_EVALUATION_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const saveTrainingResultsEvaluationSuccess = (data) => {
    return {
        type: actionTypes.TRAINING_RESULTS_EVALUATION_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const saveTrainingResultsEvaluationFailure = (err) => {
    return {
        type: actionTypes.TRAINING_RESULTS_EVALUATION_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const resetSaveTrainingResultsEvaluation = () => {
    return {
        type: actionTypes.TRAINING_RESULTS_EVALUATION_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理Actionの発行
 * @param {*} data
 */
export const getTrainingResultsEvaluation = (data) => {
    return async (dispatch) => {
        // Storeへ実習結果評価取得リクエストActionをdispatchする
        dispatch(getTrainingResultsEvaluationRequest());
        ecoPostWithAuthHeader(GET_TRAINING_RESULTS_EVALUATION_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ実習結果評価取得成功Actionをdispatchする
                dispatch(getTrainingResultsEvaluationSuccess(res.data));
            }).catch(err =>
                // Storeへ実習結果評価取得失敗Actionをdispatchする
                dispatch(getTrainingResultsEvaluationFailure(err))
            );
    };
};

/**
 * 登録処理Actionの発行
 * @param {*} data
 */
export const saveTrainingResultsEvaluation = (data) => {
    return async (dispatch) => {
        // Storeへ実習結果評価登録リクエストActionをdispatchする
        dispatch(saveTrainingResultsEvaluationRequest());
        ecoPostWithAuthHeader(TRAINING_RESULTS_EVALUATION_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ実習結果評価登録成功Actionをdispatchする
                dispatch(saveTrainingResultsEvaluationSuccess(res.data));
            }).catch(err =>
                // Storeへ実習結果評価登録失敗Actionをdispatchする
                dispatch(saveTrainingResultsEvaluationFailure(err))
            );
    };
};

/**
 * 画面リセットActionの発行
 */
export const trainingResultsEvaluationReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(resetTrainingResultsEvaluation());
    };
};

/**
 * 画面リセットActionの発行
 */
export const saveTrainingResultsEvaluationReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(resetSaveTrainingResultsEvaluation());
    };
};


