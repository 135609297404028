import React, { Fragment } from "react";
import { getPrefecturesMaster, BASE_URL } from "../../Constants/CommonConstants";

/**
 * 都道府県のマップ表示部品
 * @param {*} props
 */
export default function PrefecturesMap(props) {
    return (
        <Fragment>
            {
                (
                    () => {
                        if (props.prefecturesId in getPrefecturesMaster()) {
                            // http://localhost:3000
                            return (<img src={BASE_URL + '/img/' + getPrefecturesMaster()[props.prefecturesId].map} className="el_mapImg" alt="" />);
                        }
                    }
                )()
            }
        </Fragment>
    );
}
