import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GAFLFCPF01ActionType";

// API
const UPDATE_PASSWORD_POST = BASE_URL + "/api/af_lf_update_password";

// ********************Actionの作成 Start ********************

/**
 * パスワード変更リクエスト
 */
export const ChangePasswordRequest = () => {
    return {
        type: actionTypes.UPDATE_PASSWORD_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const ChangePasswordSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const ChangePasswordFailure = (err) => {
    return {
        type: actionTypes.UPDATE_PASSWORD_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const ChangePasswordReset = () => {
    return {
        type: actionTypes.UPDATE_PASSWORD_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 修正処理
 * @param {*} data
 */
export const changePassword = (data) => {
    return async (dispatch) => {
        dispatch(ChangePasswordRequest());
        ecoPostWithAuthHeader(
            UPDATE_PASSWORD_POST,
            JSON.stringify(data)
        )
            .then((res) =>
                dispatch(ChangePasswordSuccess(res.data))
            )
            .catch((err) => dispatch(ChangePasswordFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const changePasswordReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(ChangePasswordReset());
    };
};
