/**
 * Action Typesを定義
 */
const GRSMMAMF01ActionTypes = {
    GET_CANDIDATE_LIST_REQUEST: 'GET_CANDIDATE_LIST_REQUEST',     // 応募者一覧検索定義
    GET_CANDIDATE_LIST_SUCCESS: 'GET_CANDIDATE_LIST_SUCCESS',     // 応募者一覧検索成功定義
    GET_CANDIDATE_LIST_FAILURE: 'GET_CANDIDATE_LIST_FAILURE',     // 応募者一覧検索失敗定義
    GET_CANDIDATE_LIST_RESET: 'GET_CANDIDATE_LIST_RESET',         // 応募者一覧検索リセット定義
    GET_CANDIDATE_DETAIL_REQUEST: 'GET_CANDIDATE_DETAIL_REQUEST', // 応募者詳細取得
    GET_CANDIDATE_DETAIL_SUCCESS: 'GET_CANDIDATE_DETAIL_SUCCESS', // 応募者詳細取得成功
    GET_CANDIDATE_DETAIL_FAILURE: 'GET_CANDIDATE_DETAIL_FAILURE', // 応募者詳細取得失敗
    GET_CANDIDATE_DETAIL_RESET: 'GET_CANDIDATE_DETAIL_RESET',      // 応募者詳細取得リセット
    GET_SELECTED_CASE_CANDIDATE_LIST_REQUEST: 'GET_SELECTED_CASE_CANDIDATE_LIST_REQUEST',
    GET_SELECTED_CASE_CANDIDATE_LIST_SUCCESS: 'GET_SELECTED_CASE_CANDIDATE_LIST_SUCCESS',
    GET_SELECTED_CASE_CANDIDATE_LIST_FAILURE: 'GET_SELECTED_CASE_CANDIDATE_LIST_FAILURE',
    GET_SELECTED_CASE_CANDIDATE_LIST_RESET: 'GET_SELECTED_CASE_CANDIDATE_LIST_RESET',
    CHECK_SELECTED_CANDIDATE_LIST_REQUEST: 'CHECK_SELECTED_CANDIDATE_LIST_REQUEST', // 応募者ステータスチェック
    CHECK_SELECTED_CANDIDATE_LIST_SUCCESS: 'CHECK_SELECTED_CANDIDATE_LIST_SUCCESS', // 応募者ステータスチェック成功
    CHECK_SELECTED_CANDIDATE_LIST_FAILURE: 'CHECK_SELECTED_CANDIDATE_LIST_FAILURE', // 応募者ステータスチェック失敗
    CHECK_SELECTED_CANDIDATE_LIST_RESET: 'CHECK_SELECTED_CANDIDATE_LIST_RESET', // 応募者ステータスチェックリセット
};

export default GRSMMAMF01ActionTypes;
