/**
 * Action Typesを定義
 */
const GICRMIAI01ActionType = {
    INSURANCE_APPLICATION_DETAILS_REQUEST: 'INSURANCE_APPLICATION_DETAILS_REQUEST',  // 保険申込履歴検索定義
    INSURANCE_APPLICATION_DETAILS_SUCCESS: 'INSURANCE_APPLICATION_DETAILS_SUCCESS',  // 保険申込履歴検索成功定義
    INSURANCE_APPLICATION_DETAILS_FAILURE: 'INSURANCE_APPLICATION_DETAILS_FAILURE',  // 保険申込履歴検索失敗定義
    INSURANCE_APPLICATION_DETAILS_RESET: 'INSURANCE_APPLICATION_DETAILS_RESET',      // 保険申込履歴検索リセット定義
    INSURANCE_DOWNLOAD_FILE_REQUEST: 'INSURANCE_DOWNLOAD_FILE_REQUEST',              // 保険申込履歴ダウンロードファイル定義
    INSURANCE_DOWNLOAD_FILE_SUCCESS: 'INSURANCE_DOWNLOAD_FILE_SUCCESS',              // 保険申込履歴ダウンロードファイル成功定義
    INSURANCE_DOWNLOAD_FILE_FAILURE: 'INSURANCE_DOWNLOAD_FILE_FAILURE',              // 保険申込履歴ダウンロードファイル失敗定義
    INSURANCE_DOWNLOAD_FILE_RESET: 'INSURANCE_DOWNLOAD_FILE_RESET',                  // 保険申込履歴ダウンロードファイルリセット定義
};

export default GICRMIAI01ActionType;
