import actionType from "./GRSMMMLI01ActionType";

// レスポンスState定義
const infoState = {
    isFetching: false,
    overViewData: [],
    overflowflg: "",
    isError: false,
    isDelete: false,
    err_type: "",
    err_detail: []
};

/**
 * 案件一覧データ取得
 * @param {*} state
 * @param {*} action
 */
export const getCaseList = (state = infoState, action) => {
    switch (action.type) {
        case actionType.GET_CASE_LIST_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                overflowflg: "",
                isDelete: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.GET_CASE_LIST_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data.list,
                overflowflg: action.data.data.overflowflg,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.GET_CASE_LIST_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                overflowflg: "",
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.GET_CASE_LIST_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                overflowflg: "",
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.CASE_DELETE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                isDelete: false,
                err_type: "",
                err_detail: []
            });
        case actionType.CASE_DELETE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                isDelete: true,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.CASE_DELETE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                isDelete: false,
                err_type: action.err,
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 案件詳細データ/求人案件詳細データを取得するReducer
 * @param {*} state
 * @param {*} action
 */
export const getCaseDetail = (state = infoState, action) => {
    switch (action.type) {
        case actionType.CASE_DETAIL_REQUEST:
        case actionType.RECRUITMENT_DETAIL_REQUEST:
        case actionType.SPECIFIC_DETAIL_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.CASE_DETAIL_SUCCESS:
        case actionType.RECRUITMENT_DETAIL_SUCCESS:
        case actionType.SPECIFIC_DETAIL_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.CASE_DETAIL_FAILURE:
        case actionType.RECRUITMENT_DETAIL_FAILURE:
        case actionType.SPECIFIC_DETAIL_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.CASE_DETAIL_RESET:
        case actionType.RECRUITMENT_DETAIL_RESET:
        case actionType.SPECIFIC_DETAIL_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });

        default:
            return state;
    }
};

/**
 * 案件コピーデータ取得Reducer
 * @param {*} state
 * @param {*} action
 */
export const getCaseDetailCopy = (state = infoState, action) => {
    switch (action.type) {
        case actionType.CASE_DETAIL_COPY_REQUEST:
        case actionType.RECRUITMENT_DETAIL_COPY_REQUEST:
        case actionType.SPECIFIC_DETAIL_COPY_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionType.CASE_DETAIL_COPY_SUCCESS:
        case actionType.RECRUITMENT_DETAIL_COPY_SUCCESS:
        case actionType.SPECIFIC_DETAIL_COPY_SUCCESS:
            // 下記の項目が引き込まない
            let responseData = action.data.data;
            responseData['accept_period_start'] = '';
            responseData['accept_period_end'] = '';
            responseData['pub_period_start'] = '';
            responseData['pub_period_end'] = '';
            responseData['case_status'] = '';
            responseData['case_id'] = '';
            responseData['version_no'] = '';

            return Object.assign({}, state, {
                isFetching: false,
                overViewData: responseData,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionType.CASE_DETAIL_COPY_FAILURE:
        case actionType.RECRUITMENT_DETAIL_COPY_FAILURE:
        case actionType.SPECIFIC_DETAIL_COPY_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionType.CASE_DETAIL_COPY_RESET:
        case actionType.RECRUITMENT_DETAIL_COPY_RESET:
        case actionType.SPECIFIC_DETAIL_COPY_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });

        default:
            return state;
    }
};
