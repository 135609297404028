const moment = require('moment');

/**
 * 空文字列かどうかチェック
 * @param {*} value
 */
export const isBlank = (value) => {
    return value == null || value == undefined || value === '';
};

/**
 * 数値表示
 * @param {*} value
 */
export const getNumberText = (value) => {
    return getNumber(value, false);
};

/**
 * 数値フォーマット定義
 * @param {*} value
 */
export const getNumber = (value) => {
    try {
        if (value == null || value == '') {
            return value;
        }
        let ret = Number.NaN;
        ret = Number((value + '').replace(/,/g, '')).toLocaleString('ja-JP', { maximumFractionDigits: 20 });
        if (Number.isNaN(ret) || ret == 'NaN') {
            return value;
        } else {
            return ret;
        }
    } catch (e) {
        return value;
    }
};

/**
 * 整数値取得
 * @param {*} value
 */
export const getInt = (value) => {
    try {
        let ret = parseInt(value);
        if (Number.isNaN(ret) || ret == 'NaN') {
            return 0;
        } else {
            return ret;
        }
    } catch (e) {
        return 0;
    }
};

/**
 * 画面表示日付文字列変換：YYYYMMDD → YYYY/MM/DD
 * @param {*} value
 */
export const getDateText = (value) => {
    try {
        let m = moment(value, "YYYYMMDD");
        return m.format("YYYY/MM/DD");
    } catch (e) {
        return value;
    }
};

/**
 * 画面表示日付文字列変換：YYYY-MM-DD → YYYY/MM/DD
 * @param {*} value
 */
export const getChangeDateText = (value) => {
    try {
        let m = moment(value, "YYYY-MM-DD");
        return m.format("YYYY/MM/DD");
    } catch (e) {
        return value;
    }
};

/**
 * 画面表示日付文字列変換：YYYY-MM-DD → YYYY/MM
 * @param {*} value
 */
export const getDateTextYM = (value) => {
    try {
        if (value) {
            let m = moment(value, "YYYY-MM-DD");
            return m.format("YYYY/MM");
        }
    } catch (e) {
        return value;
    }
};

/**
 * DB形式日付文字列変換：YYYY/MM/DD → YYYYMMDD
 * @param {*} value
 */
export const getDateDBText = (value) => {
    try {
        let m = moment(value, "YYYY/MM/DD");
        return m.format("YYYYMMDD");
    } catch (e) {
        return value;
    }
};

/**
 * 画面表示時分文字列変換：YYYYMMDDHHMMSS → YYYY/MM/DD/ HH:MM:SS
 * @param {*} value
 */
export const getDateTimeText = (value) => {
    try {
        let m = moment(value, "YYYYMMDDHHmmss");
        return m.format("YYYY/MM/DD HH:mm:ss");
    } catch (e) {
        return value;
    }
};

/**
 * DB形式時分文字列変換：YYYY/MM/DD/ HH:MM:SS → YYYYMMDDHHMMSS
 * @param {*} value
 */
export const getDateTimeDBText = (value) => {
    try {
        let m = moment(value, "YYYY/MM/DD HH:mm:ss");
        return m.format("YYYYMMDDHHmmss");
    } catch (e) {
        return value;
    }
};


/**
 * 画面表示時分文字列変換：HHMM → HH:MM
 * @param {*} value
 */
export const getTimeText = (value) => {
    try {
        let m = moment(value, "HHmm");
        return m.format("HH:mm");
    } catch (e) {
        return value;
    }
};

/**
 * DB形式時分文字列変換：HH:MM → HHMM
 * @param {*} value
 */
export const getTimeDBText = (value) => {
    try {
        let m = moment(value, "HH:mm");
        return m.format("HHmm");
    } catch (e) {
        return value;
    }
};

/**
 * 日付チェック
 * @param {*} value
 */
export const isRealDate = (value) => {
    if (value === undefined || value.trim() === '') return true;
    try {
        return (moment(value, "YYYY/MM/DD").isValid() || moment(value, "YYYY-MM-DD").isValid() || moment(value, "YYYYMMDD").isValid());
    } catch (e) {
        return false;
    }
};

/**
 * 文字列の文字種類チェック
 * @param {*} input
 * @param {*} charType
 */
export const checkCharType = (input, charType) => {
    switch (charType) {
        // 全角文字（ひらがな・カタカナ・漢字 etc.）のみ
        case "zenkaku":
            return (input.match(/^[^\x01-\x7E\xA1-\xDF]+$/)) ? true : false;
        // 全角ひらがなのみ
        case "hiragana":
            return (input.match(/^[\u3041-\u3096]+$/)) ? true : false;
        // 全角カタカナのみ
        case "katakana":
            return (input.match(/^[\u30a1-\u30f6]+$/)) ? true : false;
    }
    return false;
};

/**
 * 全角文字存在チェック
 * @param {*} value
 */
export const isContainsZenkaku = (value) => {
    if (value === undefined) return false;
    let str = value.trim();
    return str.length !== getByteLen(str);
};

/**
 * 入力バイト数チェック
 * @param {*} value
 */
export const getByteLen = (value) => {

    if (value === undefined) return 0;

    let str = value.trim();

    let result = 0;
    for (var i = 0; i < str.length; i++) {
        let chr = str.charCodeAt(i);
        if ((chr >= 0x00 && chr < 0x81) ||
            (chr === 0xf8f0) ||
            (chr >= 0xff61 && chr < 0xffa0) ||
            (chr >= 0xf8f1 && chr < 0xf8f4)) {
            //半角文字の場合は1を加算
            result += 1;
        } else {
            //それ以外の文字の場合は2を加算
            result += 2;
        }
    }
    //結果を返す
    return result;
};

/**
 * 入力文字数チェック
 * @param {*} value
 */
export const getStringLen = (value) => {

    if (value === undefined) return 0;

    let str = value.trim();

    return str.length;
};

/**
 * 配列が空かどうかを判定する
 * @param {*} data
 */
export const checkArray = (data) => {
    return (Array.isArray(data) && data.length) ? true : false;
};

/**
 * 分を時間/分に変換する
 * @param {*} n
 */
export const timeConvert = (mins) => {
    let hours = Math.trunc(mins / 60) | 0,
        minutes = mins % 60 | 0;
    return hours + "時間" + minutes + "分";
};
