/**
 * Action Typesを定義
 */
const GCMOMCUM01ActionType = {
    GET_TRAINNING_WORKER_REQUEST: 'GET_TRAINNING_WORKER_REQUEST',        // 実習／労働者一覧取得定義
    GET_TRAINNING_WORKER_SUCCESS: 'GET_TRAINNING_WORKER_SUCCESS',        // 実習／労働者一覧取得成功定義
    GET_TRAINNING_WORKER_FAILURE: 'GET_TRAINNING_WORKER_FAILURE',        // 実習／労働者一覧取得失敗定義
    GET_TRAINNING_WORKER_RESET: 'GET_TRAINNING_WORKER_RESET'             // 実習／労働者一覧取得リセット定義
};

export default GCMOMCUM01ActionType;