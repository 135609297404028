import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GAFLFLAF02ActionType";

// API
const GET_PERSONAL_INFO_POST = BASE_URL + "/api/af_lf_get_personal_info_word_info";
const UPDATE_PERSONAL_INFO_POST = BASE_URL + "/api/af_lf_update_personal_info_word_info";

// ********************Actionの作成 Start ********************

/**
 * 個人情報取り扱い同意取得リクエスト
 */
export const GetPersonalInfoRequest = () => {
    return {
        type: actionTypes.GET_PERSONAL_INFO_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const GetPersonalInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_PERSONAL_INFO_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const GetPersonalInfoFailure = (err) => {
    return {
        type: actionTypes.GET_PERSONAL_INFO_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const GetPersonalInfoReset = () => {
    return {
        type: actionTypes.GET_PERSONAL_INFO_RESET,
    };
};

// ********************Actionの作成 End ********************

// ********************Actionの作成 Start ********************

/**
 * 個人情報取り扱い同意リクエスト
 */
export const UpdatePersonalInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_PERSONAL_INFO_REQUEST,
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdatePersonalInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_PERSONAL_INFO_SUCCESS,
        data,
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdatePersonalInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_PERSONAL_INFO_FAILURE,
        err,
    };
};

/**
 * 更新リセット
 */
export const UpdatePersonalInfoReset = () => {
    return {
        type: actionTypes.UPDATE_PERSONAL_INFO_RESET,
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getPersonalInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetPersonalInfoRequest());
        ecoPostWithAuthHeader(
            GET_PERSONAL_INFO_POST,
            JSON.stringify(data)
        ).then((res) =>
            dispatch(GetPersonalInfoSuccess(res.data))
        ).catch((err) => dispatch(GetPersonalInfoFailure(err)));
    };
};

/**
 * 画面リセットAction
 */
export const getPersonalInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetPersonalInfoReset());
    };
};

/**
 * 同意処理
 * @param {*} data
 */
export const updatePersonalInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdatePersonalInfoRequest());
        ecoPostWithAuthHeader(
            UPDATE_PERSONAL_INFO_POST,
            JSON.stringify(data)
        ).then((res) =>
            dispatch(UpdatePersonalInfoSuccess(res.data))
        ).catch((err) => dispatch(UpdatePersonalInfoFailure(err)));
    };
};