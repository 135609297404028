import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GUIULUIP02ActionType";

// お役立ち情報取得API
const GET_USEFUL_INFO_DETAIL_POST = BASE_URL + "/api/ui_ul_get_useful_info_detail";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetUsefulInfoDetailRequest = () => {
    return {
        type: actionTypes.GET_USEFUL_INFO_DETAIL_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetUsefulInfoDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_USEFUL_INFO_DETAIL_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetUsefulInfoDetailFailure = (err) => {
    return {
        type: actionTypes.GET_USEFUL_INFO_DETAIL_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetUsefulInfoDetailReset = () => {
    return {
        type: actionTypes.GET_USEFUL_INFO_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getUsefulInfoDetail = (data) => {
    return async (dispatch) => {
        dispatch(GetUsefulInfoDetailRequest());
        ecoPostWithAuthHeader(GET_USEFUL_INFO_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(GetUsefulInfoDetailSuccess(res.data))
        ).catch(
            err => dispatch(GetUsefulInfoDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getUsefulInfoDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetUsefulInfoDetailReset());
    };
};
