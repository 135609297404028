import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GUIULUIP01ActionType";

// お役立ち情報更新API
const UPDATE_USEFUL_INFO_DETAIL_POST = BASE_URL + "/api/ui_ul_update_useful_info_detail";

// ********************Actionの作成 Start ********************

/**
 * 更新リクエスト
 */
export const UpdateUsefulInfoDetailRequest = () => {
    return {
        type: actionTypes.UPDATE_USEFUL_INFO_DETAIL_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateUsefulInfoDetailSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_USEFUL_INFO_DETAIL_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateUsefulInfoDetailFailure = (err) => {
    return {
        type: actionTypes.UPDATE_USEFUL_INFO_DETAIL_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateUsefulInfoDetailReset = () => {
    return {
        type: actionTypes.UPDATE_USEFUL_INFO_DETAIL_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const updateUsefulInfoDetail = (data) => {
    return async (dispatch) => {
        dispatch(UpdateUsefulInfoDetailRequest());
        ecoPostWithAuthHeader(UPDATE_USEFUL_INFO_DETAIL_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateUsefulInfoDetailSuccess(res.data))
        ).catch(
            err => dispatch(UpdateUsefulInfoDetailFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const updateUsefulInfoDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateUsefulInfoDetailReset());
    };
};
