import React, { Fragment } from "react";
import { connect } from "react-redux";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getCognitoUserInfo, getMessage, getCodeValueLabelList } from "../../Constants/CommonConstants";
import { MessageMap } from "./GESEQQRE01Message";
import { getNumberText, checkArray, isBlank } from "../../Components/Tools/StringTools";
import { updateCompanyEnqueteAnswerInfo, UpdateCompanyEnqueteAnswerInfoReset } from "./GESEQQRE01Action";
import "../../Css/Common.css";

/**
 * アンケート登録（企業用）
 */
class GESEQQRE01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            answerList: [],
            profileInfo: {},
            messageMap: {},
            sysDateFlg: false,
            enquete_answer_status: '1',
            enquete_answer_contents: ''
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const profileInfo = this.props.data.data.list;
        let answerList = [];
        // アンケートリスト設定
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('1') : profileInfo.enquete_item_01,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_01,
            item_no: '1'
        });
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('2') : profileInfo.enquete_item_02,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_02,
            item_no: '2'
        });
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('3') : profileInfo.enquete_item_03,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_03,
            item_no: '3'
        });
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('4') : profileInfo.enquete_item_04,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_04,
            item_no: '4'
        });
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('5') : profileInfo.enquete_item_05,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_05,
            item_no: '5'
        });
        answerList.push({
            enquete_item: isBlank(profileInfo.version_no) ? this.getMasterName('6') : profileInfo.enquete_item_06,
            score: isBlank(profileInfo.version_no) ? '' : profileInfo.score_06,
            item_no: '6'
        });
        // メッセージマスタ名設定
        const messageMap = Object.assign({}, MessageMap);
        messageMap['enquete_item_01'] = this.getMasterName('1');
        messageMap['enquete_item_02'] = this.getMasterName('2');
        messageMap['enquete_item_03'] = this.getMasterName('3');
        messageMap['enquete_item_04'] = this.getMasterName('4');
        messageMap['enquete_item_05'] = this.getMasterName('5');
        messageMap['enquete_item_06'] = this.getMasterName('6');
        this.setState({
            profileInfo: profileInfo,
            answerList: answerList,
            version_no: profileInfo.version_no,
            messageMap: messageMap,
            sysDateFlg: this.props.data.data.sys_date_flg,
            enquete_answer_status: isBlank(profileInfo.enquete_answer_status) ? '1' : profileInfo.enquete_answer_status,
            enquete_answer_contents: isBlank(profileInfo.enquete_answer_contents) ? '' : profileInfo.enquete_answer_contents
        });

    }

    /**
     * ラジオボタン変更
     * @param {*} event 
     */
    handleChange = (event) => {
        const { name, value, title } = event.target;
        const { answerList } = this.state;
        const itemNo = name.split('_')[1];
        answerList.forEach(el => {
            if (el.item_no == itemNo) {
                el.score = value;
            }
        });
        this.setState({
            answerList: answerList
        });
    };

    /**
     * アンケートマスタデータ
     * @param {*} intemNo 
     */
    getMasterName = (intemNo) => {
        const enquetMaster = this.props.data.data.enquet_master_list;
        return enquetMaster.filter(v => v.enquete_item_id == intemNo)[0].enquete_item_name;
    }

    /**
     * 登録
     */
    saveInfo = () => {
        window.scrollTo(0, 0);
        this.props.updateCompanyEnqueteAnswerInfo({
            case_id: this.state.profileInfo.case_id,
            user_id: this.state.profileInfo.user_id,
            version_no: this.state.version_no,
            enquete_answer_data: this.state.answerList,
            enquete_answer_status: this.state.enquete_answer_status,
            enquete_answer_contents: this.state.enquete_answer_contents
        });
    }

    /**
     * ドロップダウンリスト変更
     */
    dropDownListChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    render() {

        const { answerList, profileInfo, sysDateFlg } = this.state;

        let isDisabled = true;
        // 現在システム日付　≧　受入・就業期間終了日　－　1ヶ月
        if (sysDateFlg) {
            if (isBlank(this.state.version_no)) {
                // 採用者情報．在留資格　が　INT:インターン生　の場合
                if (profileInfo.status_of_residence == '2') {
                    // 実習結果報告書が作成済み（採用者情報．実習結果登録ステータス　＝　2:評価登録済）
                    if (profileInfo.training_results_regist_status == '2') {
                        isDisabled = false;
                    }
                } else {
                    isDisabled = false;
                }
            }
        }

        // 評価情報．ステータス
        const statusCode = getCodeValueLabelList('RSC0035');

        if (!this.props.resultData.isFetching) {
            // 最新バージョン設定する
            if (this.props.resultData.data
                && this.props.resultData.err_type === '200'
                && this.state.version_no !== this.props.resultData.data.version_no) {
                this.setState({ version_no: this.props.resultData.data.version_no }, () => {
                    this.showModalDialog(0, getMessage('RS-0036'), () => {
                        this.props.updateCompanyEnqueteAnswerInfoReset();
                    });
                });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                アンケート登録</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GAFMCMCFEQ01_GESEQQRE01"); }, () => { })}>ユーザ一覧</a></li>
                                <li>実習生評価登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">

                                <ErrorMessage err_type={this.props.resultData.err_type} err_detail={this.props.resultData.err_detail} messageMap={this.state.messageMap} />

                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">実習生評価登録</h2>
                                </div>

                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>実習生情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>在留資格</th>
                                                    <td>{this.state.profileInfo.status_of_residence_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>案件名</th>
                                                    <td>{this.state.profileInfo.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入・就業期間</th>
                                                    <td>
                                                        {
                                                            isBlank(this.state.profileInfo.accept_start) && isBlank(this.state.profileInfo.accept_end) ? <td></td> : <td>{this.state.profileInfo.accept_start}〜{this.state.profileInfo.accept_end}</td>
                                                        }
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        氏名
                                                    </th>
                                                    <td>
                                                        {this.state.profileInfo.employment_name_kana}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>写真</th>
                                                    <td>
                                                        <figure className="un_applicantPhoto">
                                                            <img src={this.state.profileInfo.employment_photo} alt="" />
                                                        </figure>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        国籍
                                                    </th>
                                                    <td>
                                                        {this.state.profileInfo.country_name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        所属
                                                    </th>
                                                    <td>
                                                        {this.state.profileInfo.organization_name}
                                                    </td>
                                                </tr>
                                            </tbody>

                                        </table>
                                    </section>
                                </div>

                                <div className="bl_sect_inner">

                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>実習生評価</span></h3>
                                        </div>
                                        <table className="bl_horizTable hp_mb30">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        ステータス
                                                </th>
                                                    <td>
                                                        <select tabIndex="1" className={isDisabled ? "el_select is_disabled" : "el_select"} disabled={isDisabled} name="enquete_answer_status" onChange={this.dropDownListChange}>
                                                            {
                                                                statusCode && statusCode.map(item => (
                                                                    <option key={item.value} value={item.value} selected={this.state.enquete_answer_status === item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))
                                                            }
                                                        </select>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        評価コメント
                                                </th>
                                                    <td colspan="5" className="mixTableBody">
                                                        <div className="bl_formUnit">
                                                            <Textarea tabIndex="2" disabled={isDisabled} name="enquete_answer_contents" maxlength="200" rows="4" cols="80" setValue={this.state.enquete_answer_contents} onHandleChange={(k, v) => this.editItemHandler(k, v)} className={isDisabled ? "el_textarea is_disabled" : "el_textarea"}></Textarea>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <p className="el_note_sm hp_tar hp_mb20">５：満足　４：やや満足　３：どちらとも言えない　２：やや不満　１：不満</p>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <span className="el_badge el_badge__required hp_mr10">必須</span>
                                                        </th><th>５</th><th>４</th><th>３</th><th>２</th><th>１</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        return (
                                                            checkArray(answerList) && answerList.map((el, index) => {
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <tr>
                                                                            <td className="el_vTitle">
                                                                                {el.enquete_item}
                                                                            </td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={5} title={el.enquete_item} checked={el.score == '5'} onChange={this.handleChange} tabIndex={index + 3} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={4} title={el.enquete_item} checked={el.score == '4'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={3} title={el.enquete_item} checked={el.score == '3'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={2} title={el.enquete_item} checked={el.score == '2'} onChange={this.handleChange} /></td>
                                                                            <td><input disabled={isDisabled} type="radio" name={"score_" + (index + 1)} value={1} title={el.enquete_item} checked={el.score == '1'} onChange={this.handleChange} /></td>
                                                                        </tr>
                                                                    </Fragment>
                                                                );
                                                            })
                                                        );
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>


                                    </section>

                                    <div className="bl_horizBtnUnit">
                                        <button tabIndex="9" type="button" className={isDisabled ? "el_btn is_disabled" : "el_btn"} onClick={() => this.saveInfo()}>登録する</button>
                                    </div>

                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button tabIndex="10" type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GAFMCMCFEQ01_GESEQQRE01"); }, () => { })}>ユーザ一覧へ</button>

                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state.getCompanyEnqueteAnswerInfo,
        resultData: state.updateCompanyEnqueteAnswerInfo
    };
};

const mapDispatchToProps = dispatch => {
    return {
        updateCompanyEnqueteAnswerInfo: (arg) => dispatch(updateCompanyEnqueteAnswerInfo(arg)),
        updateCompanyEnqueteAnswerInfoReset: () => dispatch(UpdateCompanyEnqueteAnswerInfoReset()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GESEQQRE01);
