import { getCaseList, getCaseDetail, getCaseDetailCopy } from "./MmGRSMMMLI01/GRSMMMLI01Reducer";
import { createCase, getCompanyList } from "./MmGRSMMIMR01/GRSMMIMR01Reducer";
import { createRecruitmentCase } from "./MmGRSMMRMR01/GRSMMRMR01Reducer";
import { createRecruitmentCaseSpecialSkill } from "./MmGRSMMRMR31/GRSMMRMR31Reducer";
import { getCandidateList, getCandidateDetail, getSelectedCaseCandidateList, checkSelectedCandidateStatus } from "./MmGRSMMAMF01/GRSMMAMF01Reducer";
import { saveCandidate } from "./MmGRSMMERF01/GRSMMERF01Reducer";
import { getEntryList, getCaseCandidateList } from "./MmGRSMMIMD01/GRSMMIMD01Reducer";
import { updateCaseEntryList, updateSelectedCandidateList, updateCaseEntryListByCandidate } from "./MmGRSMMIMD02/GRSMMIMD02Reducer";
import { uploadInfo } from "../Components/Tools/UploadTools";
import { getInterviewHistory, createInterviewHistory } from "./MsGRSMSMSA01/GRSMSMSA01Reducer";

export const rsReducer = {
    getCaseList,
    createCase,
    createRecruitmentCase,
    createRecruitmentCaseSpecialSkill,
    getCaseDetail,
    getCaseDetailCopy,
    getCandidateList,
    getCandidateDetail,
    saveCandidate,
    getEntryList,
    getCaseCandidateList,
    updateCaseEntryList,
    getSelectedCaseCandidateList,
    updateSelectedCandidateList,
    updateCaseEntryListByCandidate,
    getCompanyList,
    checkSelectedCandidateStatus,
    uploadInfo,
    getInterviewHistory,
    createInterviewHistory
};
