import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GISIPWPM01ActionType';

// 作業計画案件一覧取得APIURL定義
const WORK_PLAN_CASE_LIST_POST = BASE_URL + "/api/is_ip_get_working_plan_case_list";

// ********************Actionの作成 Start ********************
/**
 * 作業計画案件一覧検索
 */
export const GetWorkingPlanCaseListRequest = () => {
    return {
        type: actionTypes.GET_WORK_PLAN_CASE_LIST_REQUEST
    };
};

/**
 * 作業計画案件一覧検索成功
 */
export const GetWorkingPlanCaseListSuccess = (data) => {
    return {
        type: actionTypes.GET_WORK_PLAN_CASE_LIST_SUCCESS,
        data
    };
};

/**
 * 作業計画案件一覧検索失敗
 */
export const GetWorkingPlanCaseListFailure = (err) => {
    return {
        type: actionTypes.GET_WORK_PLAN_CASE_LIST_FAILURE,
        err
    };
};

/**
 * 作業計画案件一覧検索リセット
 */
export const GetWorkingPlanCaseListReset = () => {
    return {
        type: actionTypes.GET_WORK_PLAN_CASE_LIST_RESET
    };
};
// ********************Actionの作成 End ********************

/**
 * 作業計画案件一覧検索Actionの発行
 */
export const GetWorkingPlanCaseList = (data) => {
    return async (dispatch) => {
        // Storeへ作業計画案件一覧検索リクエストActionをdispatchする
        dispatch(GetWorkingPlanCaseListRequest());
        ecoPostWithAuthHeader(WORK_PLAN_CASE_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ作業計画案件一覧検索成功Actionをdispatchする
                dispatch(GetWorkingPlanCaseListSuccess(res.data))
            ).catch(err =>
                // Storeへ作業計画案件一覧検索失敗Actionをdispatchする
                dispatch(GetWorkingPlanCaseListFailure(err))
            );
        // dispatch(GetWorkingPlanCaseListSuccess({
        //     'data': {
        //         'list': [{
        //             'case_id': '123',
        //             'case_title': 'ｓ１２３あああ',
        //             'status_of_residence': '2',
        //             'company_name': 'AAA',
        //             'facility_name': 'BBBBB',
        //             'accept_period_start': '2021-1-1',
        //             'accept_period_end': '2021-1-30',
        //         }],
        //         'overflowflg': '0',
        //         'err_type': '200'
        //     }
        // }));
    };
};

