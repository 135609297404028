import actionTypes from './GRSMMIMD02ActionTypes';

/**
 * ケースエントリリスト情報
 */
const initialEntryState = {
    isFetching: false,
    overViewData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * ケースエントリリスト情報
 */
const initialEntryUpdateState = {
    isFetching: false,
    overViewData: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * ケースエントリリスト情報
 */
const initialSelectedCandidateListState = {
    selectedCandidateProps: []
};

/**
 * ケースエントリリストを更新するReducer
 * @param {*} state 
 * @param {*} action 
 */
export const updateCaseEntryList = (state = initialEntryState, action) => {
    switch (action.type) {
        case actionTypes.CASE_ENTRY_LIST_ACTION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CASE_ENTRY_LIST_ACTION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data,
                isError: action.data.isError,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.CASE_ENTRY_LIST_ACTION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.CASE_ENTRY_LIST_ACTION_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * ケースエントリリストを更新するReducer
 * @param {*} state 
 * @param {*} action 
 */
export const updateSelectedCandidateList = (state = initialSelectedCandidateListState, action) => {
    switch (action.type) {
        case actionTypes.SELECTED_CANDIDATE_LIST_ACTION_REQUEST:
            return Object.assign({}, state, {
                selectedCandidateProps: action.data
            });
        default:
            return state;
    }
};

/**
 * ケースエントリリストを更新するReducer
 * @param {*} state 
 * @param {*} action 
 */
export const updateCaseEntryListByCandidate = (state = initialEntryUpdateState, action) => {
    switch (action.type) {
        case actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: action.data.data,
                isError: action.data.isError,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                overViewData: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};