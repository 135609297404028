/**
 * Action Typesを定義
 */
const GCMOMCRU08ActionType = {
    GET_EMPLOY_FACILITY_INFO_REQUEST: 'GET_EMPLOY_FACILITY_INFO_REQUEST',  // 組織情報(プロフィール)インターン向け取得定義
    GET_EMPLOY_FACILITY_INFO_SUCCESS: 'GET_EMPLOY_FACILITY_INFO_SUCCESS',  // 組織情報(プロフィール)インターン向け取得成功定義
    GET_EMPLOY_FACILITY_INFO_FAILURE: 'GET_EMPLOY_FACILITY_INFO_FAILURE',  // 組織情報(プロフィール)インターン向け取得失敗定義
    GET_EMPLOY_FACILITY_INFO_RESET: 'GET_EMPLOY_FACILITY_INFO_RESET',       // 組織情報(プロフィール)インターン向け取得リセット定義
};

export default GCMOMCRU08ActionType;
