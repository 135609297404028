/**
 * Action Typesを定義
 */
const GIPPMPLI01ActionType = {
    GET_EDUCATION_HISTORY_DETAIL_REQUEST: 'GET_EDUCATION_HISTORY_DETAIL_REQUEST',  // 履修履歴詳細照会定義
    GET_EDUCATION_HISTORY_DETAIL_SUCCESS: 'GET_EDUCATION_HISTORY_DETAIL_SUCCESS',  // 履修履歴詳細照会成功定義
    GET_EDUCATION_HISTORY_DETAIL_FAILURE: 'GET_EDUCATION_HISTORY_DETAIL_FAILURE',  // 履修履歴詳細照会失敗定義
    GET_EDUCATION_HISTORY_DETAIL_RESET: 'GET_EDUCATION_HISTORY_DETAIL_RESET',      // 履修履歴詳細照会リセット定義
    GET_EDUCATION_HISTORY_ACTION_REQUEST: 'GET_EDUCATION_HISTORY_ACTION_REQUEST',  // 学習する先の取得定義
    GET_EDUCATION_HISTORY_ACTION_SUCCESS: 'GET_EDUCATION_HISTORY_ACTION_SUCCESS',  // 学習する先の取得成功定義
    GET_EDUCATION_HISTORY_ACTION_FAILURE: 'GET_EDUCATION_HISTORY_ACTION_FAILURE',  // 学習する先の取得失敗定義
    GET_EDUCATION_HISTORY_ACTION_RESET: 'GET_EDUCATION_HISTORY_ACTION_RESET',      // 学習する先の取得リセット定義
};

export default GIPPMPLI01ActionType;
