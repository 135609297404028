import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GISAMIAR01 from "./AmGISAMIAR01/GISAMIAR01";
import GISAMIAR02 from "./AmGISAMIAR02/GISAMIAR02";
import GISAMIRS01 from "./AmGISAMIRS01/GISAMIRS01";
import GISTTPTL01 from "./TtGISTTPTL01/GISTTPTL01";
import GISTTPTR01 from "./TtGISTTPTR01/GISTTPTR01";
import GISIPWPM01 from "./IpGISIPWPM01/GISIPWPM01";
import GISIPWPL01 from "./IpGISIPWPL01/GISIPWPL01";
import GISIPWPR01 from "./IpGISIPWPR01/GISIPWPR01";
import GCMOMCUM01 from "../Cm/OmGCMOMCUM01/GCMOMCUM01";
import GISDMRLS01 from "./DmGISDMRLS01/GISDMRLS01";
import GISDMIRR01 from "./DmGISDMIRR01/GISDMIRR01";
import GISDMIRI01 from "./DmGISDMIRI01/GISDMIRI01";
import { getTrainingResultsEvaluation, trainingResultsEvaluationReset } from "../Is/AmGISAMIAR01/GISAMIAR01Action";
import { getTrainingResults, getTrainingResultsReset } from "../Is/AmGISAMIAR02/GISAMIAR02Action";
import { saveTrainingPlanTemplateReset } from "../Is/TtGISTTPTR01/GISTTPTR01Action";
import { trainingPlanTemplateListReset } from "../Is/TtGISTTPTL01/GISTTPTL01Action";
import { getTrainingPlanInfo } from "../Is/IpGISIPWPR01/GISIPWPR01Action";

/**
 * 実習支援機能テンプレート
 */
class IsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            detailData: null,
            createorupdate: ""
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId
        }, () => {
            this.props.trainingResultsEvaluationReset();
        });
    };

    /**
     * 画面切替処理
     * @param {*} dispId
     * @param {*} isReset
     */
    switchDisplay = (dispId, isReset) => {
        if (!isReset) {
        }
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
        });
    };

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToView = (dispId, detailData) => {
        const beforeDispId = this.state.display;
        // 実習計画登録
        if (dispId == 'GISIPWPR01') {
            this.props.getTrainingPlanInfo({
                plan_template_id: detailData.plan_template_id,
                case_id: detailData.case_id,
                plan_id: detailData.plan_id
            });
            this.setState({
                detailData: detailData,
                display: dispId,
                createorupdate: detailData.createorupdate
            });
        } else if (dispId == 'GISDMIRR01') {  // レポート内容登録
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                detailData: detailData
            });
        } else if (dispId == 'GISAMIAR01') { // 実習結果評価
            this.props.getTrainingResultsEvaluation({
                user_id: detailData.user_id,
                case_id: detailData.case_id,
                candidate_id: detailData.candidate_id,
                training_results_regist_status: detailData.training_results_regist_status,
            });
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                detailData: detailData
            });
        } else if (dispId == 'GISAMIAR02') {  // 実習結果登録
            this.setState({
                display: dispId,
                beforeDispId: beforeDispId,
                detailData: detailData
            });
        }
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detail
     */
    switchDisplayWithData = (dispId, detail, action) => {
        const beforeDispId = this.state.display;
        this.setState({
            display: dispId,
            beforeDispId: beforeDispId,
            detailData: detail,
            action: action,
            createorupdate: detail === undefined ? 'create' : 'update'
        });
    };

    /**
     * 画面初期化処理を呼び出す
     */
    callInitialization = () => {
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
    }

    render() {
        let displayParts;
        if (this.state.display === "GISAMIAR01") {
            // 実習結果評価画面
            if (this.props.data && this.props.err_type === '200') {
                displayParts = <GISAMIAR01 changeDisp={this.switchDisplay} detailData={this.props.data} />;
            }
        } else if (this.state.display === "GISAMIAR02") {
            // 実習結果登録画面
            displayParts = <GISAMIAR02 changeDisp={this.switchDisplay} detailData={this.state.detailData} />;
        } else if (this.state.display === "GISAMIRS01") {
            // 実習結果一覧画面
            displayParts = <GISAMIRS01 changeDisp={this.switchDisplay} caseId={this.props.location.caseId} companyId={this.props.location.companyId} candidateId={this.props.location.candidateId} redirectToView={this.redirectToView} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISTTPTL01") {
            // 実習計画テンプレート一覧画面
            displayParts = <GISTTPTL01 changeDisp={this.switchDisplay} changeDispWithData={this.switchDisplayWithData} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISTTPTR01") {
            // 実習計画テンプレート登録画面
            displayParts = <GISTTPTR01 changeDisp={this.switchDisplay} detailData={this.state.detailData} action={this.state.action} createorupdate={this.state.createorupdate} />;
            this.callInitialization();
        } else if (this.state.display === "GISIPWPM01") {
            // 実習計画案件一覧画面
            displayParts = <GISIPWPM01 changeDisp={this.switchDisplay} />;
            this.callInitialization();
        } else if (this.state.display === "GISIPWPL01") {
            // 実習計画一覧
            displayParts = <GISIPWPL01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} beforeDispId={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISIPWPR01") {
            if (this.props.trainingPlanData.data && (this.props.trainingPlanData.err_type === '200' || this.props.trainingPlanData.err_type === '300')) {
                // 実習計画登録
                displayParts = <GISIPWPR01 changeDisp={this.switchDisplay} detailData={this.state.detailData} trainingPlanData={this.props.trainingPlanData.data} createorupdate={this.state.createorupdate} />;
            }
            this.callInitialization();
        } else if (this.state.display === "GAFMCMCFDM01") {
            // ユーザ一覧(日報管理)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFDM01" key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GAFMCMCFDI01") {
            // ユーザ一覧(日報照会)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFDI01" key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISDMIRR01") {
            // レポート内容登録
            displayParts = <GISDMIRR01 changeDisp={this.switchDisplay} detailData={this.state.detailData} beforeDispId={this.state.beforeDispId} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISDMRLS01_GAFMCMCFDM01") {
            // 日報日次一覧(日報管理)
            displayParts = <GISDMRLS01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} beforeDispId='GAFMCMCFDM01' isInit={this.state.beforeDispId != 'GAFMCMCFDM01'} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISDMRLS01_GAFMCMCFDI01") {
            // 日報日次一覧(日報照会)
            displayParts = <GISDMRLS01 changeDisp={this.switchDisplay} beforeDispId='GAFMCMCFDI01' isInit={this.state.beforeDispId != 'GAFMCMCFDI01'} key={this.state.beforeDispId} />;
            this.callInitialization();
        } else if (this.state.display === "GISDMIRI01") {
            // 実習レポート照会
            displayParts = <GISDMIRI01 changeDisp={this.switchDisplay} />;
            this.callInitialization();
        }

        return (
            <article className="contentsArea">
                <GAFMCMCF01
                    changeDisp={this.switchDisplay}
                    displayParts={displayParts}
                    toggleClass={this.props.location.toggleClass}
                    menuIndex={this.props.location.menuIndex}
                />
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // 実習結果評価
        isFetching: state.getTrainingResultsEvaluation.isFetching,
        data: state.getTrainingResultsEvaluation.data,
        isError: state.getTrainingResultsEvaluation.isError,
        err_type: state.getTrainingResultsEvaluation.err_type,
        err_detail: state.getTrainingResultsEvaluation.err_detail,
        // 実習結果登録
        trainingResultsIsFetching: state.trainingResults.isFetching,
        trainingResultsData: state.trainingResults.data,
        trainingResultsIsError: state.trainingResults.isError,
        trainingResultsErrType: state.trainingResults.err_type,
        trainingResultsErrDetail: state.trainingResults.err_detail,
        // 実習計画登録
        trainingPlanData: state.getTrainingPlanInfo
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        getTrainingResultsEvaluation: (arg) => dispatch(getTrainingResultsEvaluation(arg)),
        trainingResultsEvaluationReset: () => dispatch(trainingResultsEvaluationReset()),
        getTrainingResults: (args) => dispatch(getTrainingResults(args)),
        getTrainingResultsReset: () => dispatch(getTrainingResultsReset()),
        saveTrainingPlanTemplateReset: () => dispatch(saveTrainingPlanTemplateReset()),
        trainingPlanTemplateListReset: () => dispatch(trainingPlanTemplateListReset()),
        getTrainingPlanInfo: (args) => dispatch(getTrainingPlanInfo(args)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(IsTemplate)
);
