import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { saveAs } from "file-saver";
import { getCognitoUserInfo, getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { getDateTextYM } from "../../Components/Tools/StringTools";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";
import { getRequestInfoList, GetRequestInfoListReset, creatRequestInfoFile, CreatRequestInfoFileReset, downloadRequestInfoFile, DownloadRequestInfoFileReset } from "./GSMBIBIO01Action";
import { MessageMap } from "./GSMBIBIO01Message";

/**
 * 請求情報出力
 */

class GSMBIBIO01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            isCreat: false,
            isDownload: false,
            requestTargetList: [],
            billingInfoHistoryList: [],
            searchIndustryName: '',
            creatIndustryName: '',
            file_name: '',
            searchInfo: {
                coordinator_id: '',
                apply_date_start: '',
                apply_date_end: '',
            },
            creatInfo: {
                industry_id: '',
                coordinator_id: '',
                billing_date_ym: '',
            },
            downloadInfo: {
                coordinator_id: '',
                billing_date_ym: '',
            },
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
            orgType: getCognitoUserInfo()["org_type"],
        }
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.handleSearch();
    }

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    };

    /**
     * 検索変更
     */
    handleSearchChange = (event) => {
        const { name, value } = event.target;
        const { searchInfo, requestTargetList, } = this.state
        let { searchIndustryName } = this.state;
        searchInfo[name] = value;
        let isMatch = false;
        requestTargetList && requestTargetList.forEach(item => {
            if (!isMatch && item.coordinator_id === searchInfo.coordinator_id) {
                searchIndustryName = item.industry_name_j;
                isMatch = true;
            }
        });
        this.setState({
            searchIndustryName: isMatch ? searchIndustryName : '',
            searchInfo: searchInfo
        });
    };

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    searchEditItemHandler = (name, value) => {
        const { searchInfo } = this.state
        searchInfo[name] = value;
        this.setState({
            searchInfo: searchInfo
        });
    }

    /**
     * 検索処理
     */
    handleSearch = () => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isSearch: true }, () => {
            this.setErrorCallback(this.reset);
            const { searchInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify(searchInfo));
            this.props.getRequestInfoList(reqData);
        });
    };

    /**
     * 作成変更
     */
    handleCreatChange = (event) => {
        const { name, value } = event.target;
        const { creatInfo, requestTargetList } = this.state;
        let { creatIndustryName } = this.state;
        creatInfo[name] = value;
        let isMatch = false;
        requestTargetList && requestTargetList.forEach(item => {
            if (!isMatch && item.coordinator_id === creatInfo.coordinator_id) {
                creatIndustryName = item.industry_name_j;
                creatInfo.industry_id = item.industry_id;
                isMatch = true;
            }
        });
        this.setState({
            creatIndustryName: isMatch ? creatIndustryName : '',
            creatInfo: creatInfo
        });
    };

    /**
     * 作成条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    creatEditItemHandler = (name, value) => {
        const { creatInfo } = this.state
        creatInfo[name] = value;
        this.setState({
            creatInfo: creatInfo
        });
    }

    /**
     * 作成処理
     */
    handleCreat = () => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isCreat: true }, () => {
            this.setErrorCallback(this.reset);
            const { creatInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify({ ...creatInfo, billing_date_ym: creatInfo.billing_date_ym.replace(/\-/g, "") }));
            this.props.creatRequestInfoFile(reqData);
        });
    }

    /**
     * ダウンロード処理
     */
    handleDownload = (item) => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isDownload: true, file_name: item.billing_info_file }, () => {
            this.setErrorCallback(this.reset);
            const { downloadInfo } = this.state;
            downloadInfo.coordinator_id = item.coordinator_id;
            downloadInfo.billing_date_ym = item.billing_date_ym;
            let reqData = JSON.parse(JSON.stringify(downloadInfo));
            this.props.downloadRequestInfoFile(reqData);
        });
    }

    /**
     * リセットする
     */
    reset = () => {
        this.props.getRequestInfoListReset();
        this.props.creatRequestInfoFileReset();
        this.props.downloadRequestInfoFileReset();
    };

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        let requestTargetList = [];
        let billingInfoHistoryList = [];

        const { request_target_list_dict, billing_info_history_list_dict } = detailData;

        if (request_target_list_dict && request_target_list_dict.length > 0) {
            requestTargetList = request_target_list_dict;
        }
        if (billing_info_history_list_dict && billing_info_history_list_dict.length > 0) {
            billingInfoHistoryList = billing_info_history_list_dict;
        }
        this.setState({
            requestTargetList: requestTargetList,
            billingInfoHistoryList: billingInfoHistoryList
        });
    };

    render() {
        if (this.state.isSearch && this.props.getErrType === '200') {
            this.setState({ isSearch: false }, () => {
                this.reset();
                this.setViewStatus(this.props.getData);
            });
        }

        if (this.state.isCreat && this.props.creatErrType == '200') {
            this.setState({
                isCreat: false,
                searchIndustryName: '',
                searchInfo: {
                    coordinator_id: '',
                    apply_date_start: '',
                    apply_date_end: '',
                }
            }, () => {
                this.reset();
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.handleSearch();
                });
            });
        }

        // ファイルダウンロード処理
        if (this.props.downloadData && this.props.downloadErrType === '200') {
            const blob = new Blob([this.props.downloadData], {
                type: this.props.downloadData.type
            });

            if (window.navigator.msSaveBlob) {
                window.navigator.msSaveBlob(blob, this.state.file_name);
            } else {
                saveAs(blob, this.state.file_name);
            }
            this.reset();
        }

        const { searchInfo, creatInfo, requestTargetList, billingInfoHistoryList, searchIndustryName, creatIndustryName, orgType } = this.state;

        return (
            <Fragment>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">請求情報出力</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => { this.getMenu().gotoHome() }}>ホーム</a></li>
                                <li>請求情報出力</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.getErrType} err_detail={this.props.getErrDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.creatErrType} err_detail={this.props.creatErrDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.downloadErrType} err_detail={this.props.downloadErrDetail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">請求情報出力</h2>
                                    </div>
                                    <div className="bl_sect_inner">
                                        <div className="bl_searchBtn">
                                            <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                        </div>
                                        <div className={this.state.toggleclass}>
                                            <table className="bl_horizTable">
                                                <tbody>
                                                    {
                                                        orgType === 'SYS' ?
                                                            <tr>
                                                                <th>請求先</th>
                                                                <td>
                                                                    <select className="el_select el_select__auto" name="coordinator_id" value={searchInfo.coordinator_id} onChange={this.handleSearchChange} tabIndex="1">
                                                                        <option value="" selected></option>
                                                                        {
                                                                            requestTargetList.map(item => (
                                                                                <option key={item.coordinator_id} value={item.coordinator_id} selected={searchInfo.coordinator_id === item.coordinator_id}>
                                                                                    {item.coordinator_name}
                                                                                </option>
                                                                            ))
                                                                        }
                                                                    </select>
                                                                    &nbsp; {searchIndustryName}
                                                                </td>
                                                            </tr>
                                                            : null
                                                    }
                                                    <tr>
                                                        <th>請求対象年月</th>
                                                        <td>
                                                            <div>
                                                                <TextInput type="month" placeholder="年/月" maxlength="10" className="el_input el_input__datepicker" name="apply_date_start" setValue={searchInfo.apply_date_start} onHandleChange={(k, v) => this.searchEditItemHandler(k, v)} /> ～
                                                                <TextInput type="month" placeholder="年/月" maxlength="10" className="el_input el_input__datepicker" name="apply_date_end" setValue={searchInfo.apply_date_end} onHandleChange={(k, v) => this.searchEditItemHandler(k, v)} /> まで
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="bl_horizBtnUnit">
                                                <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.handleSearch()} tabIndex="2">検索</button>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        orgType === 'SYS' ?
                                            <table className="bl_horizTable">
                                                <tbody>
                                                    <tr>
                                                        <th width="20%">
                                                            <span className="el_badge el_badge__required">必須</span><br />
                                                            請求先
                                                        </th>
                                                        <td>
                                                            <select className="el_select el_select__auto" name="coordinator_id" value={creatInfo.coordinator_id} onChange={this.handleCreatChange} tabIndex="3">
                                                                <option value="" selected></option>
                                                                {
                                                                    requestTargetList.map(item => (
                                                                        <option key={item.coordinator_id} value={item.coordinator_id} selected={creatInfo.coordinator_id === item.coordinator_id}>
                                                                            {item.coordinator_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                            &nbsp; {creatIndustryName}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th width="20%">
                                                            <span className="el_badge el_badge__required">必須</span><br />
                                                            請求対象年月
                                                        </th>
                                                        <td>
                                                            <div>
                                                                <TextInput type="month" placeholder="年/月" maxlength="10" className="el_input el_input__datepicker" name="billing_date_ym" setValue={creatInfo.billing_date_ym} onHandleChange={(k, v) => this.creatEditItemHandler(k, v)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="bl_horizBtnUnit__move">
                                                                <button type="button" className="el_btn el_shadowBtn hp_mb20" onClick={() => this.handleCreat()} tabIndex="4">請求情報作成</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            : null
                                    }
                                    <section className="bl_sect_frame">
                                        <div className='bl_horizTable'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr>
                                                        {
                                                            orgType === 'SYS' ?
                                                                <Fragment>
                                                                    <th colspan="1" width="30%">請求先</th>
                                                                    <th colspan="1" width="20%">業種</th>
                                                                </Fragment>
                                                                : null
                                                        }
                                                        <th colspan="1" width="15%">請求対象年月</th>
                                                        <th colspan="1" width="15%">ダウンロード</th>
                                                        <th colspan="1" width="20%">作成日時</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (billingInfoHistoryList !== undefined && billingInfoHistoryList.length > 0) {
                                                            return (
                                                                billingInfoHistoryList.map((item, index) => (
                                                                    <tr>
                                                                        {
                                                                            orgType === 'SYS' ?
                                                                                <Fragment>
                                                                                    <td className="hp_tac">{item.coordinator_name}</td>
                                                                                    <td className="hp_tac">{item.industry_name_j}</td>
                                                                                </Fragment>
                                                                                : null
                                                                        }
                                                                        <td className="hp_tac">{getDateTextYM(item.billing_date_ym)}</td>
                                                                        <td className="hp_tac"><button type="button" className="el_smBlueBtn el_shadowBtn" onClick={() => this.handleDownload(item)} tabIndex={index}>請求情報</button></td>
                                                                        <td className="hp_tac">{item.create_datetime}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        // 請求情報一覧照会
        isGetFetching: state.getRequestInfoList.isFetching,
        getData: state.getRequestInfoList.data,
        isGetError: state.getRequestInfoList.isError,
        getErrType: state.getRequestInfoList.err_type,
        getErrDetail: state.getRequestInfoList.err_detail,

        // 請求情報ファイル作成
        isCreatFetching: state.creatRequestInfoFile.isFetching,
        creatData: state.creatRequestInfoFile.data,
        isCreatError: state.creatRequestInfoFile.isError,
        creatErrType: state.creatRequestInfoFile.err_type,
        creatErrDetail: state.creatRequestInfoFile.err_detail,

        // 請求情報ファイルダウンロード
        isDownloadFetching: state.downloadRequestInfoFile.isFetching,
        downloadData: state.downloadRequestInfoFile.data,
        isDownloadError: state.downloadRequestInfoFile.isError,
        downloadErrType: state.downloadRequestInfoFile.err_type,
        downloadErrDetail: state.downloadRequestInfoFile.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getRequestInfoList(arg) {
            // 請求情報一覧照会
            dispatch(getRequestInfoList(arg));
        },
        getRequestInfoListReset() {
            dispatch(GetRequestInfoListReset());
        },
        creatRequestInfoFile(arg) {
            // 請求情報ファイル作成
            dispatch(creatRequestInfoFile(arg));
        },
        creatRequestInfoFileReset() {
            dispatch(CreatRequestInfoFileReset());
        },
        downloadRequestInfoFile(arg) {
            // 請求情報ファイルダウンロード
            dispatch(downloadRequestInfoFile(arg));
        },
        downloadRequestInfoFileReset() {
            dispatch(DownloadRequestInfoFileReset());
        },
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GSMBIBIO01);