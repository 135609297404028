// メッセージMap
export const MessageMap = {
    "search_organization_type": "組織種別",
    "search_organization_name": "組織名",
    "search_accept_period_start": "案件終了月（From）",
    "search_accept_period_end": "案件終了月（To）",
    "search_score_date_start": "直近評価実施日（From）",
    "search_score_date_end": "直近評価実施日（To）",
    "search_score": "未評価",
    "order_by": "並び替え",
};