import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage } from "../../Constants/CommonConstants";
import { MessageMap } from "../AmGISAMIAR01/GISAMIAR01Message";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { trainingResultsEvaluationReset, saveTrainingResultsEvaluation, resetSaveTrainingResultsEvaluation } from "./GISAMIAR01Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import Textarea from "../../Components/Atoms/Textarea";
import "../../Css/Common.css";

/**
 * 実習結果評価
 */
class GISAMIAR01 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            evaluation: [],
            inited: false
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { list, enquet_master_list } = this.props.detailData;
        this.props.resetSaveTrainingResultsEvaluation();
        let evaluationList = [];
        if (list.training_results_regist_status === '2') {
            // 評価登録済の場合
            evaluationList.push({ item_no: '1', evaluation_item: list.evaluation_item_01, evaluation_results: list.evaluation_results_01 });
            evaluationList.push({ item_no: '2', evaluation_item: list.evaluation_item_02, evaluation_results: list.evaluation_results_02 });
            evaluationList.push({ item_no: '3', evaluation_item: list.evaluation_item_03, evaluation_results: list.evaluation_results_03 });
            evaluationList.push({ item_no: '4', evaluation_item: list.evaluation_item_04, evaluation_results: list.evaluation_results_04 });
        } else {
            // 評価未登録の場合マスタ表示
            if (checkArray(enquet_master_list)) {
                evaluationList.push({ item_no: '1', evaluation_item: enquet_master_list[0].enquete_item_name, evaluation_results: '' });
                evaluationList.push({ item_no: '2', evaluation_item: enquet_master_list[1].enquete_item_name, evaluation_results: '' });
                evaluationList.push({ item_no: '3', evaluation_item: enquet_master_list[2].enquete_item_name, evaluation_results: '' });
                evaluationList.push({ item_no: '4', evaluation_item: enquet_master_list[3].enquete_item_name, evaluation_results: '' });
            }

        }
        this.setState({
            findings: list.findings,
            evaluation_results_01: list.evaluation_results_01,
            evaluation_results_02: list.evaluation_results_02,
            evaluation_results_03: list.evaluation_results_03,
            evaluation_results_04: list.evaluation_results_04,
            version_no: list.version_no,
            evaluation: evaluationList,
            inited: true
        });
    }

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value, title } = event.target;
        const evaluationList = this.state.evaluation;
        evaluationList.forEach(el => {
            if (el.item_no == parseInt(name.split('_')[2]).toString()) {
                el.evaluation_results = value;
            }
        });
        this.setState({
            [name]: value,
            evaluation: evaluationList
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * 実習結果
     * @param {*} question_item 設問項目
     * @param {*} answer_results 回答結果
     * @returns
     */
    results = (question_item, answer_results) => {
        return (
            <>
                <label className="el_label is_disabled">
                    <input className="el_checkbox" type="checkbox" name="question_item" checked={answer_results === '1'} disabled />
                    <span className="el_checkbox_txt">{question_item}</span>
                </label>
            </>
        );
    }

    /**
     * 実習生の取り組み姿勢評価
     * @param {*} index 
     * @param {*} evaluation_item 評価項目
     * @param {*} evaluation_results 評価結果
     * @returns
     */
    apprenticesAttitude = (index, evaluation_item, evaluation_results) => {
        return (
            <tr>
                <td className="el_vTitle">
                    <span className="el_badge el_badge__required hp_mr10">必須</span><br />{evaluation_item}
                </td>
                <td><input type="radio" name={"evaluation_results_0" + index} value={5} title={evaluation_item} checked={evaluation_results == '5'} onChange={this.handleChange} tabIndex={index} /></td>
                <td><input type="radio" name={"evaluation_results_0" + index} value={4} title={evaluation_item} checked={evaluation_results == '4'} onChange={this.handleChange} /></td>
                <td><input type="radio" name={"evaluation_results_0" + index} value={3} title={evaluation_item} checked={evaluation_results == '3'} onChange={this.handleChange} /></td>
                <td><input type="radio" name={"evaluation_results_0" + index} value={2} title={evaluation_item} checked={evaluation_results == '2'} onChange={this.handleChange} /></td>
                <td><input type="radio" name={"evaluation_results_0" + index} value={1} title={evaluation_item} checked={evaluation_results == '1'} onChange={this.handleChange} /></td>
            </tr>
        );
    }

    /**
     * 登録
     */
    saveTrainingResultsEvaluation = () => {
        const { list } = this.props.detailData;

        window.scrollTo(0, 0);
        let params = {};
        params['user_id'] = list.user_id;
        params['case_id'] = list.case_id;
        params['candidate_id'] = list.candidate_id;
        params['company_id'] = list.company_id;
        params['case_title'] = list.case_title;
        params['employment_name_kana'] = list.employment_name_kana;
        params['status_of_residence'] = list.status_of_residence;
        params['evaluation'] = this.state.evaluation;
        params['findings'] = this.state.findings;
        params['version_no'] = this.state.version_no;

        this.props.saveTrainingResultsEvaluation(params);
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    /**
    * 画面切替処理
    * @param {*} changeTo 遷移先
    * @param {*} isReset リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.trainingResultsEvaluationReset();
        }
        return this.props.changeDisp(changeTo);
    }

    render() {
        const { list, training_data, enquet_master_list } = this.props.detailData;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && !isBlank(this.props.data.version_no) && this.state.version_no !== this.props.data.version_no && this.state.inited) {
                this.editItemHandler('version_no', this.props.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => { });
            }
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">実習結果評価</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISAMIRS01', true); }, () => { })}>実習結果一覧</a></li>
                                <li>実習結果評価</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{list.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>実習学生</th>
                                                    <td>
                                                        {list.university_name}<br />
                                                        学籍番号：{list.employment_student_no}<br />
                                                        氏名：{list.employment_name_kana}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>実習期間</th>
                                                    <td>{list.accept_start}〜{list.accept_end}</td>
                                                </tr>
                                                <tr>
                                                    <th>実施日数</th>
                                                    <td>{training_data}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">実習結果（本人登録）</h2>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0 hp_mb50">
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>インターンシップに参加して自分の役に立ったことはなんですか。（複数回答可）</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="bl_formUnit bl_formUnit__flex">
                                                            {!isBlank(list.question_item_01) ? this.results(list.question_item_01, list.answer_result_01) : null}
                                                            {!isBlank(list.question_item_02) ? this.results(list.question_item_02, list.answer_result_02) : null}
                                                            {!isBlank(list.question_item_03) ? this.results(list.question_item_03, list.answer_result_03) : null}
                                                            {!isBlank(list.question_item_04) ? this.results(list.question_item_04, list.answer_result_04) : null}
                                                            {(() => {
                                                                if (!isBlank(list.question_item_other) && !isBlank(list.answer_result_other)) {
                                                                    return (
                                                                        <>
                                                                            <label className="el_label is_disabled">
                                                                                <input className="el_checkbox" type="checkbox" name="question_item_other" checked={list.answer_result_other === '1'} disabled />
                                                                                <span className="el_checkbox_txt">{list.question_item_other}</span>
                                                                            </label>
                                                                            <input type="text" className="el_input el_input__max is_disabled" value={list.other_answer} disabled />
                                                                        </>
                                                                    );
                                                                }
                                                            })()}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>実習報告</th>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <div className="bl_formUnit hp_mb10">
                                                            <Textarea className="el_textarea is_disabled" name="training_report" rows="6" maxlength="1000" setValue={list.training_report} disabled="disabled" tabIndex="9" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv2Heading">実習生の取り組み姿勢評価</h2>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <p className="el_note_sm hp_tar hp_mb20">５：非常にできている　４：ほぼできている　３：どちらとも言えない　２：あまりできていない　１：全くできていない</p>
                                        <div className="sticky_table_wrapper">
                                            <table className="sticky_table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>５</th>
                                                        <th>４</th>
                                                        <th>３</th>
                                                        <th>２</th>
                                                        <th>１</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (list.training_results_regist_status === '1') {
                                                            return (
                                                                checkArray(enquet_master_list) && enquet_master_list.map((el, index) => {
                                                                    return (
                                                                        <Fragment key={el.enquete_item_id}>
                                                                            <tr>
                                                                                <td className="el_vTitle">
                                                                                    <span className="el_badge el_badge__required hp_mr10">必須</span><br />{el.enquete_item_name}
                                                                                </td>
                                                                                <td><input type="radio" name={"evaluation_results_0" + el.enquete_item_id} title={el.enquete_item_name} value="5" onChange={this.handleChange} tabIndex={el.enquete_item_id} /></td>
                                                                                <td><input type="radio" name={"evaluation_results_0" + el.enquete_item_id} title={el.enquete_item_name} value="4" onChange={this.handleChange} /></td>
                                                                                <td><input type="radio" name={"evaluation_results_0" + el.enquete_item_id} title={el.enquete_item_name} value="3" onChange={this.handleChange} /></td>
                                                                                <td><input type="radio" name={"evaluation_results_0" + el.enquete_item_id} title={el.enquete_item_name} value="2" onChange={this.handleChange} /></td>
                                                                                <td><input type="radio" name={"evaluation_results_0" + el.enquete_item_id} title={el.enquete_item_name} value="1" onChange={this.handleChange} /></td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    );
                                                                })
                                                            );
                                                        } else if (list.training_results_regist_status === '2') {
                                                            return (
                                                                <>
                                                                    {!isBlank(list.evaluation_item_01) ? this.apprenticesAttitude(1, list.evaluation_item_01, this.state.evaluation_results_01) : null}
                                                                    {!isBlank(list.evaluation_item_02) ? this.apprenticesAttitude(2, list.evaluation_item_02, this.state.evaluation_results_02) : null}
                                                                    {!isBlank(list.evaluation_item_03) ? this.apprenticesAttitude(3, list.evaluation_item_03, this.state.evaluation_results_03) : null}
                                                                    {!isBlank(list.evaluation_item_04) ? this.apprenticesAttitude(4, list.evaluation_item_04, this.state.evaluation_results_04) : null}
                                                                </>
                                                            );
                                                        }
                                                    })()}
                                                    <tr>
                                                        <th className="el_vTitle">
                                                            <span className="el_badge el_badge__required hp_mr10">必須</span><br />所見
                                                        </th>
                                                        <td colSpan="5" className="mixTableBody">
                                                            <div className="bl_formUnit">
                                                                <Textarea className="el_textarea" name="findings" rows="4" required="required" maxlength="1000" setValue={this.state.findings} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_btn" onClick={() => this.saveTrainingResultsEvaluation()} tabIndex="6">登録する</button>
                                    </div>
                                </div>
                                <div className="bl_horizBtnUnit__move">
                                    <button type="button" className="el_blueBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay('GISAMIRS01', true); }, () => { })} tabIndex="7">実習結果一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.saveTrainingResultsEvaluation.isFetching,
        data: state.saveTrainingResultsEvaluation.data,
        isError: state.saveTrainingResultsEvaluation.isError,
        err_type: state.saveTrainingResultsEvaluation.err_type,
        err_detail: state.saveTrainingResultsEvaluation.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        saveTrainingResultsEvaluation: (arg) => dispatch(saveTrainingResultsEvaluation(arg)),
        trainingResultsEvaluationReset: () => dispatch(trainingResultsEvaluationReset()),
        resetSaveTrainingResultsEvaluation: () => dispatch(resetSaveTrainingResultsEvaluation()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISAMIAR01);

