/**
 * Action Typesを定義
 */
const GCMOMCRU03ActionType = {
    GET_SENDING_ORGANIZATION_INFO_REQUEST:
        "GET_SENDING_ORGANIZATION_INFO_REQUEST", // 組織情報(プロフィール)送り出し機関取得定義
    GET_SENDING_ORGANIZATION_INFO_SUCCESS:
        "GET_SENDING_ORGANIZATION_INFO_SUCCESS", // 組織情報(プロフィール)送り出し機関取得成功定義
    GET_SENDING_ORGANIZATION_INFO_FAILURE:
        "GET_SENDING_ORGANIZATION_INFO_FAILURE", // 組織情報(プロフィール)送り出し機関取得失敗定義
    GET_SENDING_ORGANIZATION_INFO_RESET: "GET_SENDING_ORGANIZATION_INFO_RESET", // 組織情報(プロフィール)送り出し機関取得リセット定義
    UPDATE_SENDING_ORGANIZATION_INFO_REQUEST:
        "UPDATE_SENDING_ORGANIZATION_INFO_REQUEST", // 組織情報(プロフィール)送り出し機関更新定義
    UPDATE_SENDING_ORGANIZATION_INFO_SUCCESS:
        "UPDATE_SENDING_ORGANIZATION_INFO_SUCCESS", // 組織情報(プロフィール)送り出し機関更新成功定義
    UPDATE_SENDING_ORGANIZATION_INFO_FAILURE:
        "UPDATE_SENDING_ORGANIZATION_INFO_FAILURE", // 組織情報(プロフィール)送り出し機関更新失敗定義
    UPDATE_SENDING_ORGANIZATION_INFO_RESET:
        "UPDATE_SENDING_ORGANIZATION_INFO_RESET", // 組織情報(プロフィール)送り出し機関更新リセット定義
};

export default GCMOMCRU03ActionType;
