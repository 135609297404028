import React from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import PrefecturesMap from "../../Components/Atoms/PrefecturesMap";
import "../../Css/Common.css";
import { connect } from "react-redux";
import Btn_Up from "../../Images/btn_up.svg";
import "../../Css/GRSMMIMD01.css";
import { getCountryMasterList, getPrefecturesMasterList, getCodeValueLabelList, getCodeName, getCognitoUserInfo } from "../../Constants/CommonConstants";
import { getNumberText, isBlank } from "../../Components/Tools/StringTools";
import { calculatePayment, calculateSalary, getStatus } from "../../Constants/RsConstants";
import { getCaseCandidateList } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import { getEntryList, EntryListReset } from "../MmGRSMMIMD01/GRSMMIMD01Action";
import { updateCaseEntryList, CaseEntryListActionReset, updateSelectedCandidateList } from "../MmGRSMMIMD02/GRSMMIMD02Action";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import YouTube from "react-youtube";

/**
 * インターン案件情報画面
 */
class InternDetail extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            caseOutline: this.props.caseOutline,
            caseDetail: this.props.caseDetail,
            toggleclass1: "el_lv3Heading bl_accord_header is_active",
            bodyclass1: "bl_accord_body js_accordBody is_active",
            toggleclass2: "el_lv3Heading bl_accord_header is_active",
            bodyclass2: "bl_accord_body js_accordBody is_active",
            orgId: getCognitoUserInfo()["org_id"],
            orgType: getCognitoUserInfo()["org_type"],
            // orgId: "001",
            // orgType: "UNI"
        };
    }

    /**
    * 画面初期化処理
    */
    componentDidMount() {
        // 案件詳細情報
        const { caseDetail } = this.state;
        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });
        // 都道府県
        const prefecturesRet = Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            return v;
        });
        // 性別
        const genderRet = getCodeValueLabelList('RSC0007');

        // 電話番号と国名
        countryRet.forEach(country => {
            if (country.country_code === caseDetail.president_tel_country_code) {
                // 代表電話番号
                this.setState({
                    president_tel_country_name: country.country_name,
                    president_tel_country_code: country.country_number
                });
            }
            if (country.country_code === caseDetail.fax_country_code) {
                // FAX番号
                this.setState({
                    fax_country_name: country.country_name,
                    fax_country_code: country.country_number
                });
            }
            if (country.country_code === caseDetail.pic_tel_country_code) {
                // 電話番号
                this.setState({
                    pic_tel_country_name: country.country_name,
                    pic_tel_country_code: country.country_number
                });
            }
        });

        // 施設所在地と最低賃金
        prefecturesRet.forEach(prefectures => {
            if (prefectures.prefectures_id === caseDetail.facility_prefectures_id) {
                this.setState({
                    // 施設所在地
                    prefectures_name: prefectures.prefectures_name,
                    // 最低賃金
                    minimum_wage: prefectures.minimum_wage
                });
            }
            if (prefectures.prefectures_id === caseDetail.company_prefectures_id) {
                this.setState({
                    // 本社所在地
                    company_prefectures_name: prefectures.prefectures_name
                });
            }
        });

        // 性別
        genderRet.forEach(gender => {
            if (gender.value === caseDetail.candidate_sex) {
                this.setState({
                    sex_name: gender.label
                });
            }
        });

        // 国籍
        let country_item = "";
        let countryItems = caseDetail.country ? caseDetail.country.split(',') : []
        countryItems.forEach(item => {
            countryRet.forEach(country => {
                if (country.country_code === item) {
                    country_item = country_item + country.country_name + ","
                }
            })
        });

        if (country_item.length > 0) {
            country_item = country_item.substring(0, country_item.length - 1);
            this.setState({
                country_name: country_item
            });
        }

        // 寮の有無
        if (caseDetail.living_place === "1" && caseDetail.wifi === "1") {
            // 寮有無フラグ＝"1"　かつ　WiFi有無フラグ＝"1"　の場合
            country_item = country_item.substring(0, country_item.length - 1);
            this.setState({
                wifi_content: getCodeName('RSC0014', "0")
            });
        } else if (caseDetail.living_place === "1" && caseDetail.wifi !== "1") {
            // 寮有無フラグ＝"1"　かつ　WiFi有無フラグ＝"0"　の場合
            this.setState({
                wifi_content: getCodeName('RSC0014', "1")
            });
        } else {
            // 上記以外　の場合
            this.setState({
                wifi_content: getCodeName('RSC0014', "2")
            });
        }

        // 差引額
        let deduction = "";
        if (!isBlank(caseDetail.living_amount)) {
            deduction = "寮費 : " + getNumberText(caseDetail.living_amount) + " 円/月 " + getCodeName('RSC0012', caseDetail.water_light) + "\n"
        }
        if (!isBlank(caseDetail.meal_amount)) {
            deduction = deduction + "食費 : " + getNumberText(caseDetail.meal_amount) + " 円/月" + "\n"
        }
        caseDetail.deduction.forEach(item => {
            deduction = deduction + item.item_name + " : " + getNumberText(item.fee) + " 円/月" + "\n";
        });

        this.setState({
            deduction_content: deduction
        });


        const salary = caseDetail.salary;
        const amountList = caseDetail.deduction.map((item) => {
            return item.fee;
        });

        if (caseDetail.living_amount) {
            amountList.push(caseDetail.living_amount);
        }
        if (caseDetail.meal_amount) {
            amountList.push(caseDetail.meal_amount);
        }

        // 手取り支援額
        const proceeds = calculatePayment(salary, amountList);

        //1日の働く時間
        const minutes = caseDetail.working_minute == null ? 0 : caseDetail.working_minute / 60;
        const workingTime = parseInt(isBlank(caseDetail.working_hour) ? 0 : caseDetail.working_hour) + minutes;

        // 時給換算
        const wage = calculateSalary(proceeds, caseDetail.working_day, workingTime);
        this.setState({
            support_amount: proceeds,
            hourly_wage_conversion: wage
        });

        // エントリー画面にて選択した未応募する応募者リストをクリアする
        this.props.clearSelectedCandidateList();
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
            // this.props.EntryListReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
    * 画面切替処理
    * @param {*} changeTo　遷移先
    * @param {*} isReset　リセットフラグ
    */
    switchDisplayEntry = (changeTo, caseDetail, candidateList, entryList, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.switchDisplayEntry(changeTo, caseDetail, candidateList, entryList);
    }

    /**
     * エントリ画面に移動
     */
    handleToEntry = async () => {
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.getReset);
        this.props.CaseEntryListActionReset();
        let candidatecondition = {};
        candidatecondition.case_id = this.state.caseDetail.case_id;
        candidatecondition.call_type = "2";
        await this.props.getCaseCandidateList(candidatecondition);

        let entrycondition = {};
        entrycondition.case_id = this.state.caseDetail.case_id;
        entrycondition.call_type = "1";
        await this.props.getEntryList(entrycondition);
    }

    /**
    * 職場環境と周辺情報のtoggle制御
    * @param {*} item　1: 職場環境　2: 周辺情報
    */
    toggleClassAction = (item) => {
        // 職場環境の場合
        if (item === "1") {
            if (this.state.toggleclass1.indexOf("is_active") == -1) {
                this.setState({
                    toggleclass1: "el_lv3Heading bl_accord_header is_active",
                    bodyclass1: "bl_accord_body js_accordBody is_active"
                });
            } else {
                this.setState({
                    toggleclass1: "el_lv3Heading bl_accord_header",
                    bodyclass1: "bl_accord_body js_accordBody"
                });
            }
        } else {
            // 周辺情報の場合
            if (this.state.toggleclass2.indexOf("is_active") == -1) {
                this.setState({
                    toggleclass2: "el_lv3Heading bl_accord_header is_active",
                    bodyclass2: "bl_accord_body js_accordBody is_active"
                });
            } else {
                this.setState({
                    toggleclass2: "el_lv3Heading bl_accord_header",
                    bodyclass2: "bl_accord_body js_accordBody"
                });
            }
        }
    }

    /**
     * リセットする
     */
    getReset = () => {
        this.props.getCaseCandidateListReset();
        this.props.EntryListReset();
        this.props.CaseEntryListActionReset();
    }

    /**
    * エントリ更新処理
    * @param {*} item　更新対象
    */
    entryUpdate = (actionFlg) => {
        window.scrollTo(0, 0);
        // 更新条件
        let condition = {};
        // 案件ＩＤ
        condition.case_id = this.state.caseDetail.case_id;
        // 更新区分
        condition.action_flg = actionFlg;
        // 組織ＩＤ
        condition.organization_id = getCognitoUserInfo()["org_id"];
        // 画面区分フラグ
        condition.gamen_flg = '1';
        // VersionNo
        if (this.props.entryList != null && this.props.entryList.length > 0) {
            condition.entry_version_no = this.props.entryList[0].entry_version_no;
        }
        // システムエラーのコールバック設定
        this.setErrorCallback(this.getReset);
        // ＤＢ更新
        this.props.updateCaseEntryList(condition);
    }

    // youtubeのリンクから、IDを取得
    youtubeParser(url) {
        var regExp = /^https?\:\/\/(?:www\.youtube(?:\-nocookie)?\.com\/|m\.youtube\.com\/|youtube\.com\/)?(?:ytscreeningroom\?vi?=|youtu\.be\/|vi?\/|user\/.+\/u\/\w{1,2}\/|embed\/|watch\?(?:.*\&)?vi?=|\&vi?=|\?(?:.*\&)?vi?=)([^#\&\?\n\/<>"']*)/i;
        var match = url.match(regExp);
        return (match && match[1].length == 11) ? match[1] : false;
    }

    openVideo = (event) => {
        var openurl = event.target.getVideoUrl();
        event.target.stopVideo();
        let videoid = this.youtubeParser(openurl);
        document.getElementById(videoid).click();
    }

    render() {
        // 案件詳細情報
        const { caseDetail } = this.state;
        // 組織ＩＤ、組織タイプ
        const { orgId, orgType } = this.state;

        // エントリ画面に移動
        if (this.props.data && this.props.err_type === '200' && (this.props.entryList && this.props.err_entry_type === '200' || orgType === 'TOK')) {
            this.props.switchDisplayEntry("GRSMMIMD02", this.state.caseDetail, this.props.data, this.props.entryList);
        }

        // エントリ更新が成功になる場合
        if (this.props.entryUpdatedEntryList && this.props.err_entry_update_type === '200') {
            this.props.CaseEntryListActionReset();
            // 「エントリー」または「エントリー取り下げ」ボタン押下
            // データ取得条件
            let entrycondition = {};
            // 案件ＩＤ
            entrycondition.case_id = this.state.caseDetail.case_id;
            // データ取得区分（エントリ取得）
            entrycondition.call_type = "1";
            // エントリリスト取得
            this.props.getEntryList(entrycondition);
        }

        // エントリーボタン表示制御
        let entryButtonShow = false;
        if (['UNI', 'OKR', 'COD', 'COP', 'KAN', 'TOR', 'TOK'].includes(orgType)) {
            // ログインユーザの組織タイプ＝"UNI"　または　
            // ログインユーザの組織タイプ＝"OKR"　または　
            // ログインユーザの組織タイプ＝"COD"　の場合
            // ログインユーザの組織タイプ＝"COP"　の場合
            // ログインユーザの組織タイプ＝"KAN"　の場合
            // ログインユーザの組織タイプ＝"TOR"　の場合
            // 　ボタンを表示する。
            // 上記以外のログインユーザの組織タイプの場合
            // 　ボタンを非表示にする。
            entryButtonShow = true;
        }

        return (
            <article>
                <main className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                案件詳細
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.getMenu().gotoHome()}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧</a>
                                </li>
                                <li>
                                    案件詳細
                                </li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_entry_update_type} err_detail={this.props.err_entry_update_detail} messageMap={{}} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">案件情報</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <ul className="bl_tabs_list" role="tablist">
                                        <li><a className="bl_tabs_link is_current" href="#tab1">案件情報</a></li>
                                        {entryButtonShow ? <li><a className="bl_tabs_link" href="#tab2" onClick={() => this.handleToEntry()}>エントリー情報</a></li> : null}
                                    </ul>
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <img src={caseDetail.logo} alt="" />
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                <dt>案件ステータス：</dt>
                                                <dd>{getStatus(caseDetail)}</dd>
                                                <dt>案件ID：</dt>
                                                <dd>{caseDetail.case_id}</dd>
                                                <dt>案件名：</dt>
                                                <dd>{caseDetail.case_title}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>企業情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>企業名</th>
                                                    <td>{caseDetail.company_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>企業名<br />（フリガナ）</th>
                                                    <td>{caseDetail.company_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>本社所在地</th>
                                                    <td>{this.state.company_prefectures_name}{caseDetail.company_address}</td>
                                                </tr>
                                                <tr>
                                                    <th>設立日（年）</th>
                                                    <td>{isBlank(caseDetail.start_year) ? '' : caseDetail.start_year + ' 年'}</td>
                                                </tr>
                                                <tr>
                                                    <th>代表者名</th>
                                                    <td>{caseDetail.president_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>代表者名<br />（フリガナ）</th>
                                                    <td>{caseDetail.president_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>代表電話番号</th>
                                                    <td>{(isBlank(this.state.president_tel_country_code) && isBlank(caseDetail.president_tel_1) && isBlank(caseDetail.president_tel_2) && isBlank(caseDetail.president_tel_3)) ? '' : this.state.president_tel_country_code + ' ' + caseDetail.president_tel_1 + '-' + caseDetail.president_tel_2 + '-' + caseDetail.president_tel_3}</td>
                                                </tr>
                                                <tr>
                                                    <th>FAX番号</th>
                                                    <td>{(isBlank(this.state.fax_country_code) && isBlank(caseDetail.fax_1) && isBlank(caseDetail.fax_2) && isBlank(caseDetail.fax_3)) ? '' : this.state.fax_country_code + ' ' + caseDetail.fax_1 + '-' + caseDetail.fax_2 + '-' + caseDetail.fax_3}</td>
                                                </tr>
                                                <tr>
                                                    <th>従業員数<br />正社員</th>
                                                    <td>{isBlank(caseDetail.employee_regular) ? '' : getNumberText(caseDetail.employee_regular) + ' 名'}</td>
                                                </tr>
                                                <tr>
                                                    <th>従業員数<br />パート/アルバイト</th>
                                                    <td>{isBlank(caseDetail.employee_part_time) ? '' : getNumberText(caseDetail.employee_part_time) + ' 名'}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>

                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>施設情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>施設名</th>
                                                    <td>{caseDetail.facility_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>施設名<br />（フリガナ）</th>
                                                    <td>{caseDetail.facility_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>施設所在地</th>
                                                    <td>{this.state.prefectures_name}{caseDetail.facility_address_2}</td>
                                                </tr>
                                                <tr>
                                                    <th>ホームページ</th>
                                                    <td><a className="el_link" href={caseDetail.home_page} target="_blank">{caseDetail.home_page}</a></td>
                                                </tr>
                                                <tr>
                                                    <th>役職</th>
                                                    <td>{caseDetail.position}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者氏名</th>
                                                    <td>{caseDetail.pic_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当者氏名<br />（フリガナ）</th>
                                                    <td>{caseDetail.pic_name_kana}</td>
                                                </tr>
                                                <tr>
                                                    <th>電話番号</th>
                                                    <td>{(isBlank(this.state.pic_tel_country_code) && isBlank(caseDetail.pic_tel_1) && isBlank(caseDetail.pic_tel_2) && isBlank(caseDetail.pic_tel_3)) ? '' : this.state.pic_tel_country_code + ' ' + caseDetail.pic_tel_1 + '-' + caseDetail.pic_tel_2 + '-' + caseDetail.pic_tel_3}</td>
                                                </tr>
                                                <tr>
                                                    <th>メールアドレス</th>
                                                    <td>{isBlank(caseDetail.mail_address) ? '' : caseDetail.mail_address}</td>
                                                </tr>
                                                <tr>
                                                    <th>施設所在地地図</th>
                                                    <td><PrefecturesMap prefecturesId={caseDetail.facility_prefectures_id} /> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>募集要項情報</span></h3>
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>インターン名</th>
                                                    <td>{caseDetail.case_title}</td>
                                                </tr>
                                                <tr>
                                                    <th>募集人数</th>
                                                    <td>{isBlank(caseDetail.applicant) ? '' : getNumberText(caseDetail.applicant) + ' 名'}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入希望開始</th>
                                                    <td>{caseDetail.accept_period_start ? caseDetail.accept_period_start.replace(/\-/g, "/") : ""}</td>
                                                </tr>
                                                <tr>
                                                    <th>受入希望終了</th>
                                                    <td>{caseDetail.accept_period_end ? caseDetail.accept_period_end.replace(/\-/g, "/") : ""}</td>
                                                </tr>
                                                <tr>
                                                    <th>掲載開始</th>
                                                    <td>{caseDetail.pub_period_start ? caseDetail.pub_period_start.replace(/\-/g, "/") : ""}</td>
                                                </tr>
                                                <tr>
                                                    <th>掲載終了</th>
                                                    <td>{caseDetail.pub_period_end ? caseDetail.pub_period_end.replace(/\-/g, "/") : ""}</td>
                                                </tr>
                                                <tr>
                                                    <th>性別</th>
                                                    <td>{this.state.sex_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>国籍</th>
                                                    <td>{isBlank(this.state.country_name) ? '指定しない' : this.state.country_name}</td>
                                                </tr>
                                                <tr>
                                                    <th>学習職種</th>
                                                    <td>{caseDetail.occupation}</td>
                                                </tr>
                                                <tr>
                                                    <th>学習内容</th>
                                                    <td>{caseDetail.content ? caseDetail.content.split('\n').map((str, index) => (
                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                    )) : null}</td>
                                                </tr>
                                                <tr>
                                                    <th>自由記入</th>
                                                    <td>{caseDetail.skt_comment ? caseDetail.skt_comment.split('\n').map((str, index) => (
                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                    )) : null}</td>
                                                </tr>
                                                <tr>
                                                    <th>就業日数</th>
                                                    <td>{isBlank(caseDetail.working_day) ? '' : getNumberText(caseDetail.working_day) + ' 日/月'}</td>
                                                </tr>
                                                <tr>
                                                    <th>実労働時間</th>
                                                    <td>{isBlank(caseDetail.working_hour) ? '' : caseDetail.working_hour + ' 時間'} {isBlank(caseDetail.working_minute) ? '' : caseDetail.working_minute + ' 分/日'}</td>
                                                </tr>
                                                <tr>
                                                    <th>寮の有無</th>
                                                    <td>{this.state.wifi_content}</td>
                                                </tr>
                                                <tr>
                                                    <th>支援額</th>
                                                    <td>{isBlank(caseDetail.salary) ? '' : getNumberText(caseDetail.salary) + ' 円/月'}</td>
                                                </tr>
                                                <tr>
                                                    <th>差引額</th>
                                                    <td className="tdreturn">{this.state.deduction_content}</td>
                                                </tr>
                                                <tr>
                                                    <th>手取り支援額(目安)</th>
                                                    <td>{isBlank(this.state.support_amount) ? '' : getNumberText(this.state.support_amount) + ' 円/月'}</td>
                                                </tr>
                                                <tr>
                                                    <th>時給換算</th>
                                                    <td>{isBlank(this.state.hourly_wage_conversion) ? '' : getNumberText(this.state.hourly_wage_conversion) + ' 円/時間'}</td>
                                                </tr>
                                                {(() => {
                                                    if (orgType !== "UNI" && orgType !== "OKR" && orgType !== "INT" && orgType !== "GNO") {
                                                        return (
                                                            <tr>
                                                                <th>最低賃金</th>
                                                                <td>{this.state.prefectures_name} {isBlank(this.state.minimum_wage) ? '' : getNumberText(this.state.minimum_wage) + ' 円/時間'}</td>
                                                            </tr>
                                                        );
                                                    }
                                                })()}
                                                <tr>
                                                    <th>交通手段</th>
                                                    <td>{caseDetail.transportation}</td>
                                                </tr>
                                                <tr>
                                                    <th>担当教員の宿泊費</th>
                                                    <td>{caseDetail.hotels_service === "1" ? getCodeName('RSC0016', "0") : getCodeName('RSC0016', "1")}</td>
                                                </tr>
                                                <tr>
                                                    <th>食費</th>
                                                    <td>{caseDetail.meal_service === "1" ? getCodeName('RSC0016', "0") : getCodeName('RSC0016', "1")}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                            </div>

                            <div className="ly_mainCont_grayInner">
                                <section className="bl_sect_inner bl_accord">
                                    <div className="bl_sect_header js_accordBtn">
                                        <h3 className={this.state.toggleclass1}>
                                            <span>職場環境</span>
                                            <img src={Btn_Up} alt="閉じる" onClick={() => this.toggleClassAction("1")} />
                                        </h3>
                                    </div>
                                    <div className={this.state.bodyclass1}>
                                        {(() => {
                                            if (caseDetail.case_image !== undefined && caseDetail.case_image.length > 0) {
                                                return (
                                                    caseDetail.case_image.map((item) => (
                                                        (item.image_type === "0") ? (
                                                            <>
                                                                <div className="bl_media bl_media__bd">
                                                                    <figure className="bl_media_img">
                                                                        <img src={item.image_content} alt="" />
                                                                    </figure>
                                                                    <div className="bl_media_desc">
                                                                        <p>{item.image_comment ? item.image_comment.split('\n').map((str, index) => (
                                                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                        )) : null}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : null
                                                    ))
                                                )
                                            }
                                        })()}
                                        {(() => {
                                            if (caseDetail.facility_video_url_list !== undefined && caseDetail.facility_video_url_list.length > 0) {
                                                return (
                                                    caseDetail.facility_video_url_list.map((item) => (
                                                        <>
                                                            <div className="bl_media bl_media__bd">
                                                                <div className="bl_media_video">
                                                                    <figure>
                                                                        <YouTube videoId={this.youtubeParser(item.facility_video_url)} className={"bl_media_video_inner"} getVideoUrl={item.facility_video_url} onPlay={this.openVideo} />
                                                                        <u><a style={{ display: "none" }} id={this.youtubeParser(item.facility_video_url)} href={item.facility_video_url} target="_blank"></a></u>
                                                                    </figure>
                                                                </div>
                                                                <div className="bl_media_desc">
                                                                    <p>{item.video_comment ? item.video_comment.split('\n').map((str, index) => (
                                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                    )) : null}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                )
                                            }
                                        })()}
                                        <div className="bl_horizBtnUnit hp_mb70">
                                            <button className="el_closeBtn js_accordCloseBtn" onClick={() => this.toggleClassAction("1")}>閉じる</button>
                                        </div>
                                    </div>
                                </section>

                                <section className="bl_sect_inner bl_accord">
                                    <div className="bl_sect_header js_accordBtn">
                                        <h3 className={this.state.toggleclass2}>
                                            <span>周辺情報</span>
                                            <img src={Btn_Up} alt="閉じる" onClick={() => this.toggleClassAction("2")} />
                                        </h3>
                                    </div>
                                    <div className={this.state.bodyclass2}>
                                        {(() => {
                                            if (caseDetail.case_image !== undefined && caseDetail.case_image.length > 0) {
                                                return (
                                                    caseDetail.case_image.map((item) => (
                                                        (item.image_type === "1") ? (
                                                            <>
                                                                <div className="bl_media bl_media__bd">
                                                                    <figure className="bl_media_img">
                                                                        <img src={item.image_content} alt="" />
                                                                    </figure>
                                                                    <div className="bl_media_desc">
                                                                        <p>{item.image_comment ? item.image_comment.split('\n').map((str, index) => (
                                                                            <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                        )) : null}</p>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ) : null
                                                    ))
                                                );
                                            }
                                        })()}
                                        {(() => {
                                            if (caseDetail.environment_video_url_list !== undefined && caseDetail.environment_video_url_list.length > 0) {
                                                return (
                                                    caseDetail.environment_video_url_list.map((item) => (
                                                        <>
                                                            <div className="bl_media bl_media__bd">
                                                                <div className="bl_media_video">
                                                                    <figure>
                                                                        <YouTube videoId={this.youtubeParser(item.environment_video_url)} className={"bl_media_video_inner"} getVideoUrl={item.environment_video_url} onPlay={this.openVideo} />
                                                                        <u><a style={{ display: "none" }} id={this.youtubeParser(item.environment_video_url)} href={item.environment_video_url} target="_blank"></a></u>
                                                                    </figure>
                                                                </div>
                                                                <div className="bl_media_desc">
                                                                    <p>{item.video_comment ? item.video_comment.split('\n').map((str, index) => (
                                                                        <React.Fragment key={index}>{str}<br /></React.Fragment>
                                                                    )) : null}</p>
                                                                </div>
                                                            </div>
                                                        </>
                                                    ))
                                                )
                                            }
                                        })()}
                                        <div className="bl_horizBtnUnit hp_mb70">
                                            <button className="el_closeBtn js_accordCloseBtn" onClick={() => this.toggleClassAction("2")}>閉じる</button>
                                        </div>
                                    </div>
                                </section>
                            </div>

                            <div className="ly_mainCont_inner hp_mt20">
                                <div className="bl_sect_inner">
                                    {(() => {
                                        if (orgType === "COD" || orgType === "COP" || orgType === "KAN") {
                                            return (
                                                <section className="bl_sect_frame bl_sect_frame__pd0">
                                                    <table className="bl_horizTable02">
                                                        <tbody>
                                                            <tr>
                                                                <th>保険加入サービス</th>
                                                                {(() => {
                                                                    return (
                                                                        <td>{caseDetail.insurance_flg === "1" ? getCodeName('RSC0039', "1") : getCodeName('RSC0039', "0")}</td>
                                                                    );
                                                                })()}
                                                            </tr>
                                                            <tr>
                                                                <th>入出国手続きサービス</th>
                                                                {(() => {
                                                                    return (
                                                                        <td>{isBlank(caseDetail.immigration_service_flg) ? null : caseDetail.immigration_service_flg === "1" ? getCodeName('RSC0015', "0") : getCodeName('RSC0015', "1")}</td>
                                                                    );
                                                                })()}
                                                            </tr>
                                                            <tr>
                                                                <th>渡航移動パック販売サービス</th>
                                                                {(() => {
                                                                    return (
                                                                        <td>{isBlank(caseDetail.move_service_flg) ? null : caseDetail.move_service_flg === "1" ? getCodeName('RSC0015', "0") : getCodeName('RSC0015', "1")}</td>
                                                                    );
                                                                })()}
                                                            </tr>
                                                            <tr>
                                                                <th>教育支援サービス<br />（入国前）</th>
                                                                {(() => {
                                                                    return (
                                                                        <td>{isBlank(caseDetail.education_service_before_flg) ? null : caseDetail.education_service_before_flg === "1" ? getCodeName('RSC0015', "0") : getCodeName('RSC0015', "1")}</td>
                                                                    );
                                                                })()}
                                                            </tr>
                                                            <tr>
                                                                <th>教育支援サービス<br />（入国後研修）</th>
                                                                {(() => {
                                                                    return (
                                                                        <td>{isBlank(caseDetail.education_service_after_flg) ? null : caseDetail.education_service_after_flg === "1" ? getCodeName('RSC0015', "0") : getCodeName('RSC0015', "1")}</td>
                                                                    );
                                                                })()}
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </section>
                                            );
                                        }
                                    })()}

                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_backBtn" onClick={() => this.handleToDisplay("GRSMMMLI01", true)}>案件一覧へ</button>
                                        {(() => {
                                            if (orgType === "OKR" || orgType === "UNI") {
                                                // ログインユーザの組織タイプ＝'OKR'送出し機関　または
                                                // ログインユーザの組織タイプ＝'UNI'大学　の場合
                                                // 現在時刻
                                                let currentDate = caseDetail.sys_date ? caseDetail.sys_date.replace(/\-/g, "") : "";
                                                // 募集終了日のフォマード
                                                let pub_end = caseDetail.pub_period_end.replace(/\-/g, "");
                                                if (pub_end >= currentDate) {
                                                    if (this.props.entryList && this.props.entryList.length) {
                                                        if (this.props.entryList[0].entry_status === "0") {
                                                            //　案件エントリ情報.エントリステータス=0
                                                            return (
                                                                <button type="button" className="el_btn" onClick={() => this.entryUpdate("EntryButton")}>エントリーする</button>
                                                            );
                                                        } else if (this.props.entryList[0].entry_status === "1") {
                                                            // 案件エントリー情報.エントリステータス=1 の場合
                                                            return (
                                                                <button type="button" className="el_btn" onClick={() => this.entryUpdate("EntryQuitButton")}>取り下げする</button>
                                                            );
                                                        }
                                                    } else {
                                                        //　案件エントリ情報.エントリ情報が存在していない場合
                                                        return (

                                                            <button type="button" className="el_btn" onClick={() => this.entryUpdate("EntryButton")}>エントリーする</button>
                                                        );
                                                    }
                                                }
                                            }
                                        })()}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </main>
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isFetching: state.getCaseCandidateList.isFetching,
        data: state.getCaseCandidateList.overViewData,
        isError: state.getCaseCandidateList.isError,
        err_type: state.getCaseCandidateList.err_type,
        err_detail: state.getCaseCandidateList.err_detail,
        isEntryFetching: state.getEntryList.isFetching,
        entryList: state.getEntryList.overViewData,
        isEntryError: state.getEntryList.isError,
        err_entry_type: state.getEntryList.err_type,
        err_entry_detail: state.getEntryList.err_detail,
        isEntryUpdateFetching: state.updateCaseEntryList.isFetching,
        entryUpdatedEntryList: state.updateCaseEntryList.overViewData,
        isEntryUpdateError: state.updateCaseEntryList.isError,
        err_entry_update_type: state.updateCaseEntryList.err_type,
        err_entry_update_detail: state.updateCaseEntryList.err_detail,
    };
};
function mapDispatchToProps(dispatch) {
    return {
        getCaseCandidateList(condition) {
            // 応募者一覧情報取得
            dispatch(getCaseCandidateList(condition));
        }, getEntryList(condition) {
            // エントリリスト取得
            dispatch(getEntryList(condition));
        }, EntryListReset() {
            dispatch(EntryListReset());
        }, updateCaseEntryList(condition) {
            // エントリリスト更新
            dispatch(updateCaseEntryList(condition));
        }, CaseEntryListActionReset() {
            // エントリリストstateリセット
            dispatch(CaseEntryListActionReset());
        }, clearSelectedCandidateList() {
            // 選択した応募者一覧情報stateクリア
            dispatch(updateSelectedCandidateList([]));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(InternDetail);

