import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GESEQQRI01ActionType';

// アンケート初期取得登録（実習生用）API
const GET_TRAINING_ENQUETE_ANSWER_INFO_POST = BASE_URL + "/api/es_eq_get_training_enquete_answer_info";

// アンケート登録（実習生用）API
const UPDATE_TRAINING_ENQUETE_ANSWER_INFO_POST = BASE_URL + "/api/es_eq_update_training_enquete_answer_info";

// ********************Actionの作成 Start ********************
/**
 * アンケート初期取得登録（実習生用）照会
 */
export const GetTrainingEnqueteAnswerInfoRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_REQUEST
    };
};

/**
 * アンケート初期取得登録（実習生用）照会成功
 */
export const GetTrainingEnqueteAnswerInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS,
        data
    };
};

/**
 * アンケート初期取得登録（実習生用）照会失敗
 */
export const GetTrainingEnqueteAnswerInfoFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_FAILURE,
        err
    };
};

/**
 * アンケート初期取得登録（実習生用）照会リセット
 */
export const GetTrainingEnqueteAnswerInfoReset = () => {
    return {
        type: actionTypes.GET_TRAINING_ENQUETE_ANSWER_INFO_RESET
    };
};

/**
 *  アンケート登録（実習生用）リクエスト
 */
export const UpdateTrainingEnqueteAnswerInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_REQUEST
    };
};

/**
 *  アンケート登録（実習生用）成功
 * @param {*} data
 */
export const UpdateTrainingEnqueteAnswerInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_SUCCESS,
        data
    };
};

/**
 *  アンケート登録（実習生用）失敗
 * @param {*} err
 */
export const UpdateTrainingEnqueteAnswerInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_FAILURE,
        err
    };
};

/**
 *  アンケート登録（実習生用）リセット
 * @param {*} err
 */
export const UpdateTrainingEnqueteAnswerInfoReset = (err) => {
    return {
        type: actionTypes.UPDATE_TRAINING_ENQUETE_ANSWER_INFO_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * アンケート初期取得登録（実習生用）照会Actionの発行
 */
export const getTrainingEnqueteAnswerInfo = (data) => {
    return async (dispatch) => {
        // Storeへアンケート初期取得登録（実習生用）照会リクエストActionをdispatchする
        dispatch(GetTrainingEnqueteAnswerInfoRequest());
        ecoPostWithAuthHeader(GET_TRAINING_ENQUETE_ANSWER_INFO_POST, JSON.stringify(data))
            .then(res =>
                // Storeへアンケート初期取得登録（実習生用）照会成功Actionをdispatchする
                dispatch(GetTrainingEnqueteAnswerInfoSuccess(res.data))
            ).catch(err =>
                // Storeへアンケート初期取得登録（実習生用）照会失敗Actionをdispatchする
                dispatch(GetTrainingEnqueteAnswerInfoFailure(err))
            );
    };
};

/**
 *  アンケート登録（実習生用）Actionの発行
 */
export const updateTrainingEnqueteAnswerInfo = (arg) => {
    return async (dispatch) => {
        // Storeへアンケート初期取得登録（実習生用） アンケート登録（実習生用）リクエストActionをdispatchする
        dispatch(UpdateTrainingEnqueteAnswerInfoRequest());
        ecoPostWithAuthHeader(UPDATE_TRAINING_ENQUETE_ANSWER_INFO_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへアンケート初期取得登録（実習生用） アンケート登録（実習生用）成功Actionをdispatchする
                dispatch(UpdateTrainingEnqueteAnswerInfoSuccess(res.data));
            }).catch(err =>
                // Storeへアンケート初期取得登録（実習生用） アンケート登録（実習生用）失敗Actionをdispatchする
                dispatch(UpdateTrainingEnqueteAnswerInfoFailure(err))
            );
    };
};
