import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getPrefecturesMasterList, getCountryMasterList, getMessage } from "../../Constants/CommonConstants";
import { imageResize } from "../../Components/Tools/ImageTools";
import { updateSupervisingOrgInfo, updateSupervisingOrgInfoReset } from "./GCMOMCRU05Action";
import { MessageMap } from "./GCMOMCRU05Message";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import "../../Css/Common.css";

/**
 * 組織情報(プロフィール)監理団体
 */
class GCMOMCRU05 extends BaseUIComponent {

    constructor(props) {
        super(props);
        this.state = {
            countryOpt: [],
            supervising_prefectures_name: [],
            supervisingOrgInfo: this.props.detailData
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { supervisingOrgInfo } = this.state;

        // 国・地域名
        const countryRet = Object.entries(getCountryMasterList()).map(([k, v]) => {
            return v;
        });

        // 都道府県
        const prefecturesRet = Object.entries(getPrefecturesMasterList()).map(([k, v]) => {
            return v;
        });

        this.setState(prevState => ({
            countryOpt: [...prevState.countryOpt, ...countryRet],
            supervising_prefectures_name: [...prevState.supervising_prefectures_name, ...prefecturesRet],
            supervising_prefectures_id: supervisingOrgInfo.supervising_prefectures_id,
            supervising_org_name: supervisingOrgInfo.supervising_org_name,
            supervising_name_kana: supervisingOrgInfo.supervising_name_kana,
            supervising_logo: supervisingOrgInfo.logo,
            supervising_address: supervisingOrgInfo.supervising_address,
            start_year: supervisingOrgInfo.start_year,
            president_name: supervisingOrgInfo.president_name,
            president_name_kana: supervisingOrgInfo.president_name_kana,
            president_tel_country_name: supervisingOrgInfo.president_tel_country_name,
            president_tel_country_code: supervisingOrgInfo.president_tel_country_code,
            president_tel_1: supervisingOrgInfo.president_tel_1,
            president_tel_2: supervisingOrgInfo.president_tel_2,
            president_tel_3: supervisingOrgInfo.president_tel_3,
            fax_country_name: supervisingOrgInfo.fax_country_name,
            fax_country_code: supervisingOrgInfo.fax_country_code,
            fax_1: supervisingOrgInfo.fax_1,
            fax_2: supervisingOrgInfo.fax_2,
            fax_3: supervisingOrgInfo.fax_3
        }), () => {
            countryRet.forEach(country => {
                // 代表者電話番号
                if (country.country_code === this.state.president_tel_country_code) {
                    this.setState({
                        president_tel_country_name: country.country_name,
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    });
                }
                // 代表者FAX番号
                if (country.country_code === this.state.fax_country_code) {
                    this.setState({
                        fax_country_name: country.country_name,
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    });
                }
            });
        });
        window.scrollTo(0, 0);
    }

    /**
     * 画像を変更
     * @param {*} event
     */
    handleImageUpload = (event) => {
        imageResize(event.target, (data) => {
            this.setState({
                supervising_logo: data
            }, () => {
                this.editItemHandler('logo', this.state.supervising_logo);
            });
        });
    }

    /**
     * 画像を削除
     */
    handleImageDelete = () => {
        this.setState({
            supervising_logo: null
        }, () => {
            this.editItemHandler('logo', this.state.supervising_logo);
        });
    };

    /**
     * 代表者電話番号の変更
     * @param {*} event
     */
    handleTelCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.president_tel_country_name === '') {
                this.setState({
                    president_tel_country_code: '',
                    president_tel_country_number: ''
                }, () => {
                    this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                    this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                    this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 代表者電話番号＿国・地域名
                if (country.country_name === this.state.president_tel_country_name) {
                    this.setState({
                        president_tel_country_code: country.country_code,
                        president_tel_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("president_tel_country_name", this.state.president_tel_country_name);
                        this.editItemHandler("president_tel_country_code", this.state.president_tel_country_code);
                        this.editItemHandler("president_tel_country_number", this.state.president_tel_country_number);
                    });
                }
            });
        });
    };

    /**
     * 代表者FAX番号の変更
     * @param {*} event
     */
    handleFaxCountryNameChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.fax_country_name === '') {
                this.setState({
                    fax_country_code: '',
                    fax_country_number: ''
                }, () => {
                    this.editItemHandler("fax_country_name", this.state.fax_country_name);
                    this.editItemHandler("fax_country_code", this.state.fax_country_code);
                    this.editItemHandler("fax_country_number", this.state.fax_country_number);
                });
            }
            this.state.countryOpt && this.state.countryOpt.forEach(country => {
                // 代表者FAX番号＿国・地域名
                if (country.country_name === this.state.fax_country_name) {
                    this.setState({
                        fax_country_code: country.country_code,
                        fax_country_number: country.country_number
                    }, () => {
                        this.editItemHandler("fax_country_name", this.state.fax_country_name);
                        this.editItemHandler("fax_country_code", this.state.fax_country_code);
                        this.editItemHandler("fax_country_number", this.state.fax_country_number);
                    });
                }
            });
        });
    };

    /**
     * 本社所在地の変更
     * @param {*} event
     */
    handleSupervisingfecturesChange = (event) => {
        this.setState({
            supervising_prefectures_id: event.target.value
        }, () => {
            this.editItemHandler("supervising_prefectures_id", this.state.supervising_prefectures_id);
        });
    };

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler(name, value);
        });
    };

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.supervisingOrgInfoReset();
    }

    /**
    * 登録
    */
    saveSupervisingOrgInfo = () => {
        window.scrollTo(0, 0);
        const { supervisingOrgInfo } = this.state;
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);

        let reqData = JSON.parse(JSON.stringify(this.state.supervisingOrgInfo));
        reqData['version_no'] = supervisingOrgInfo.version_no;
        this.props.createSupervisingOrgInfo(reqData);
    }

    /**
    * 画面部品編集イベント
    * @param {*} name
    * @param {*} value
    */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            supervisingOrgInfo: {
                ...prevState.supervisingOrgInfo,
                [name]: value
            }
        }));
    }

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    render() {
        const { supervisingOrgInfo } = this.state;
        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && supervisingOrgInfo.version_no != this.props.data.version_no) {
                this.editItemHandler('version_no', this.props.data.version_no);
                this.showModalDialog(0, getMessage('RS-0036'), () => { });
            }
        }
        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">組織情報(プロフィール)</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>組織情報(プロフィール)</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">登録プロフィール情報</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <section className="bl_sect_frame">
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        監理団体名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="supervising_org_name" className="el_input el_input__max" maxlength="60" required="required" setValue={supervisingOrgInfo.supervising_org_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="1" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        監理団体名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="supervising_name_kana" className="el_input el_input__max" maxlength="60" required="required" setValue={supervisingOrgInfo.supervising_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="2" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>監理団体ロゴ</th>
                                                    <td>
                                                        <div className="bl_upload_box__applicant">
                                                            <figure>
                                                                <img src={this.state.supervising_logo == null ? 'data:,' : this.state.supervising_logo} alt="" />
                                                            </figure>
                                                            <div className="bl_btns">
                                                                <label htmlFor="changeImg" onClick={event => event.target.value = null} className="el_smBlueBtn" tabIndex="3">
                                                                    画像を選択<br />Select image
                                                                    <input type="file" id="changeImg" className="el_sendFile" name="log" accept="image/*"
                                                                        onChange={this.handleImageUpload} />
                                                                </label>
                                                                <label htmlFor="deleteImg" className="el_smBlueBtn" tabIndex="4">
                                                                    画像を削除<br />Delete image
                                                                    <input type="button" id="deleteImg" className="el_sendFile" onClick={this.handleImageDelete} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        本社所在地
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_pref" name="supervising_prefectures_name" value={this.state.supervising_prefectures_id} onChange={this.handleSupervisingfecturesChange} tabIndex="6">
                                                                <option value="" selected></option>
                                                                {
                                                                    this.state.supervising_prefectures_name && this.state.supervising_prefectures_name.map(item => (
                                                                        <option key={item.prefectures_id} value={item.prefectures_id} selected={this.state.supervising_prefectures_id == item.prefectures_id}>
                                                                            {item.prefectures_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                            <TextInput type="text" name="supervising_address" className="el_input el_input__max" maxlength="60" required="required"
                                                                setValue={supervisingOrgInfo.supervising_address} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        設立日(年)
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="start_year" className="el_input el_input__sm" maxlength="4" required="required" setValue={supervisingOrgInfo.start_year} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="8" />
                                                        <span className="el_form_txt">年</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者名
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="president_name" className="el_input el_input__max" maxlength="40" required="required" setValue={supervisingOrgInfo.president_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="9" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者名（フリガナ）
                                                    </th>
                                                    <td>
                                                        <TextInput type="text" name="president_name_kana" className="el_input el_input__max" maxlength="40" required="required" setValue={supervisingOrgInfo.president_name_kana} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="10" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />
                                                        代表者電話番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="president_tel_country_name" onChange={this.handleTelCountryNameChange} tabIndex="11">
                                                                <option value="" selected ></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.president_tel_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="president_tel_country_number" className="el_input el_input__countryCode" setValue={this.state.president_tel_country_number} maxlength="6" readOnly={true} />
                                                        <TextInput type="text" name="president_tel_1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.president_tel_1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="12" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="president_tel_2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.president_tel_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="13" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="president_tel_3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.president_tel_3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="14" />
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        FAX番号
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select el_select_country" name="fax_country_name" onChange={this.handleFaxCountryNameChange} tabIndex="15">
                                                                <option value="" selected ></option>
                                                                {
                                                                    this.state.countryOpt && this.state.countryOpt.map(item => (
                                                                        <option key={item.country_code} value={item.country_name} selected={this.state.fax_country_name == item.country_name}>
                                                                            {item.country_name}
                                                                        </option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </div>
                                                        <TextInput type="text" name="fax_country_number" className="el_input el_input__countryCode" setValue={this.state.fax_country_number} maxlength="6" readOnly={true} />
                                                        <TextInput type="text" name="fax_1" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.fax_1} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="16" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="fax_2" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.fax_2} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="17" />&nbsp;-&nbsp;
                                                        <TextInput type="text" name="fax_3" className="el_input el_input__phoneNum" maxlength="4" required="required" setValue={supervisingOrgInfo.fax_3} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="18" />
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_btn" onClick={() => this.saveSupervisingOrgInfo()} tabIndex="19">登録する</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.updateSupervisingOrgInfo.isFetching,
        data: state.updateSupervisingOrgInfo.data,
        isError: state.updateSupervisingOrgInfo.isError,
        err_type: state.updateSupervisingOrgInfo.err_type,
        err_detail: state.updateSupervisingOrgInfo.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createSupervisingOrgInfo(arg) {
            // 組織情報(プロフィール)監理団体更新
            dispatch(updateSupervisingOrgInfo(arg));
        },
        supervisingOrgInfoReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(updateSupervisingOrgInfoReset());
        }
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GCMOMCRU05);

