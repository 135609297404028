// メッセージMap
export const MessageMap = {
    "supervising_org_name": "登録支援機関名",
    "supervising_name_kana": "登録支援機関名(フリガナ)",
    "supervising_prefectures_name": "本社所在地＿都道府県",
    "supervising_prefectures_id": "本社所在地＿都道府県",
    "supervising_address": "本社所在地",
    "start_year": "設立日(年)",
    "president_name": "代表者名",
    "president_name_kana": "代表者名(フリガナ)",
    "president_tel_country_name": "代表者電話番号＿国・地域名",
    "president_tel_country_code": "代表者電話番号＿国・地域名",
    "president_tel_1": "代表者電話番号＿市外局番",
    "president_tel_2": "代表者電話番号＿市内局番",
    "president_tel_3": "代表者電話番号加入者番号",
    "fax_country_name": "代表者FAX番号＿国・地域名",
    "fax_country_code": "代表者FAX番号＿国・地域名",
    "fax_1": "代表者FAX番号＿市外局番",
    "fax_2": "代表者FAX番号＿市内局番",
    "fax_3": "代表者FAX番号加入者番号"
};
