import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GISIPWPL01ActionType';

// 作業計画案件取得APIURL定義
const WORK_PLAN_INFO_LIST_POST = BASE_URL + "/api/is_ip_get_working_plan_list";

// ********************Actionの作成 Start ********************
/**
 * 実習計画案件検索
 */
export const GetWorkingPlanInfoListRequest = () => {
    return {
        type: actionTypes.GET_WORK_PLAN_INFO_LIST_REQUEST
    };
};

/**
 * 実習計画案件検索成功
 */
export const GetWorkingPlanInfoListSuccess = (data) => {
    return {
        type: actionTypes.GET_WORK_PLAN_INFO_LIST_SUCCESS,
        data
    };
};

/**
 * 実習計画案件検索失敗
 */
export const GetWorkingPlanInfoListFailure = (err) => {
    return {
        type: actionTypes.GET_WORK_PLAN_INFO_LIST_FAILURE,
        err
    };
};

/**
 * 実習計画案件検索リセット
 */
export const GetWorkingPlanInfoListReset = () => {
    return {
        type: actionTypes.GET_WORK_PLAN_INFO_LIST_RESET
    };
};
// ********************Actionの作成 End ********************

/**
 * 実習計画案件検索Actionの発行
 */
export const GetWorkingPlanInfoList = (data) => {
    return async (dispatch) => {
        // Storeへ実習計画案件検索リクエストActionをdispatchする
        dispatch(GetWorkingPlanInfoListRequest());
        ecoPostWithAuthHeader(WORK_PLAN_INFO_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ実習計画案件検索成功Actionをdispatchする
                dispatch(GetWorkingPlanInfoListSuccess(res.data))
            ).catch(err =>
                // Storeへ実習計画案件検索失敗Actionをdispatchする
                dispatch(GetWorkingPlanInfoListFailure(err))
            );
    };
};

