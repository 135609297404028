/**
 * Action Typesを定義
 */
const GRSMSMSA01ActionType = {
    GET_INTERVIEW_HISTORY_REQUEST: 'GET_INTERVIEW_HISTORY_REQUEST',  // web面接日情報取得定義
    GET_INTERVIEW_HISTORY_SUCCESS: 'GET_INTERVIEW_HISTORY_SUCCESS',  // web面接日情報取得成功定義
    GET_INTERVIEW_HISTORY_FAILURE: 'GET_INTERVIEW_HISTORY_FAILURE',  // web面接日情報取得失敗定義
    GET_INTERVIEW_HISTORY_RESET:   'GET_INTERVIEW_HISTORY_RESET',       // web面接日情報取得リセット定義
    CREAT_INTERVIEW_HISTORY_REQUEST: 'CREAT_INTERVIEW_HISTORY_REQUEST',  // web面接日情報登録定義
    CREAT_INTERVIEW_HISTORY_SUCCESS: 'CREAT_INTERVIEW_HISTORY_SUCCESS',  // web面接日情報登録成功定義
    CREAT_INTERVIEW_HISTORY_FAILURE: 'CREAT_INTERVIEW_HISTORY_FAILURE',  // web面接日情報登録失敗定義
    CREAT_INTERVIEW_HISTORY_RESET:   'CREAT_INTERVIEW_HISTORY_RESET',      // web面接日情報登録リセット定義
    CREATE_INTERVIEW_HISTORY_RESET_ALL_DATA: 'CREATE_INTERVIEW_HISTORY_RESET_ALL_DATA'
};

export default GRSMSMSA01ActionType;