import { combineReducers } from "redux";
import { rsReducer } from "./Rs/reducers";
import { afReducer } from "./Af/reducers";
import { icReducer } from "./Ic/reducers";
import { cmReducer } from "./Cm/reducers";
import { tmReducer } from "./Tm/reducers";
import { isReducer } from "./Is/reducers";
import { ipReducer } from "./Ip/reducers";
import { efReducer } from "./Ef/reducers";
import { esReducer } from "./Es/reducers";
import { uiReducer } from "./Ui/reducers";
import { smReducer } from "./Sm/reducers";
import actionTypes from "./Af/McGAFMCMCF01/GAFMCMCF01ActionType";

const appReducer = combineReducers({
    ...rsReducer,
    ...afReducer,
    ...icReducer,
    ...cmReducer,
    ...tmReducer,
    ...isReducer,
    ...ipReducer,
    ...efReducer,
    ...esReducer,
    ...uiReducer,
    ...smReducer,
});

const rootReducer = (state, action) => {
    // フィルター開始処理
    if (action.type == actionTypes.MENU_USER_LOGOUT || action.type == actionTypes.MENU_CLEAR_DATA) {
        state = undefined;
    }
    if (state && action.type == actionTypes.MENU_ERROR_CLOSE) {
        for (let data in state) {
            if (state[data].isError) {
                state[data].isError = false;
            }
        }
    }
    let result = appReducer(state, action);
    // フィルター終了処理
    if (result) {
        // ローディング処理
        let showLoading = false;
        for (let data in result) {
            if (result[data].isFetching) {
                showLoading = true;
            }
        }
        if (showLoading) {
            result['menu'].showLoading = true;
        } else {
            result['menu'].showLoading = false;
        }

        // エラー処理
        let showError = false;
        for (let data in result) {
            if (result[data].isError) {
                showError = true;
            }
        }

        if (showError) {
            result['menu'].showError = true;
        } else {
            result['menu'].showError = false;
        }
    }
    return result;
};

export default rootReducer;
