import { ecoPostWithAuthHeaderAsync } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";

// ファイルアップロードAPI
const FILE_UPLOAD_POST = BASE_URL + "/api/rs_mm_general_upload_file";

// ファイル削除API
const FILE_DELETE_POST = BASE_URL + "/api/rs_mm_delete_upload_file";

/**
 * Action Typesを定義
 */
const UploadToolsActionType = {
    UPLOAD_REQUEST: 'UPLOAD_REQUEST',         // アップロード定義
    UPLOAD_SUCCESS: 'UPLOAD_SUCCESS',         // アップロード成功定義
    UPLOAD_FAILURE: 'UPLOAD_FAILURE',         // アップロード失敗定義
    UPLOAD_RESET: 'UPLOAD_RESET',             // アップロードリセット定義
};

// ********************Actionの作成 Start ********************
/**
 * 案件一覧検索
 */
export const UploadRequest = () => {
    return {
        type: UploadToolsActionType.UPLOAD_REQUEST
    };
};

/**
 * 案件一覧検索成功
 */
export const UploadSuccess = (data) => {
    return {
        type: UploadToolsActionType.UPLOAD_SUCCESS,
        data
    };
};

/**
 * 案件一覧検索失敗
 */
export const UploadFailure = (err) => {
    return {
        type: UploadToolsActionType.UPLOAD_FAILURE,
        err
    };
};

/**
 * 案件一覧検索リセット
 */
export const UploadReset = () => {
    return {
        type: UploadToolsActionType.UPLOAD_RESET
    };
};
// ********************Actionの作成 End ********************

/**
 * ファイルアップロード
 */
export const fileUpload = async (file, success, failure) => {
    try {
        const res = await ecoPostWithAuthHeaderAsync(FILE_UPLOAD_POST, { file_data: file });
        const item = res.data;
        if (item.err_type == '200') {
            return item.data.uuid_file_name;
        } else {
            success(item);
        }
    } catch (error) {
        failure(error);
    }
    return 'error';
};

/**
 * ファイル削除
 */
export const fileDelete = async (fileList, success, failure) => {
    try {
        const res = await ecoPostWithAuthHeaderAsync(FILE_DELETE_POST, { file_name_list: fileList });
        const item = res.data;
        if (item.err_type == '200') {
            return true;
        } else {
            success(item);
        }
    } catch (error) {
        failure(error);
    }
    return false;
};

// レスポンスState定義
const infoState = {
    isFetching: false,
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * アップロード
 * @param {*} state
 * @param {*} action
 */
export const uploadInfo = (state = infoState, action) => {
    switch (action.type) {
        case UploadToolsActionType.UPLOAD_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isError: false,
                err_type: "",
                err_detail: []
            });
        case UploadToolsActionType.UPLOAD_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case UploadToolsActionType.UPLOAD_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case UploadToolsActionType.UPLOAD_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
