import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GISDMRLS01ActionType';

// 日報日次一覧取得APIURL定義
const DAILY_REPORT_LIST_POST = BASE_URL + "/api/is_dm_get_daily_report_list";

// ********************Actionの作成 Start ********************
/**
 * 日報日次一覧検索
 */
export const GetDailyReportListRequest = () => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_REQUEST
    };
};

/**
 * 日報日次一覧検索成功
 */
export const GetDailyReportListSuccess = (data, beforeDispId) => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_SUCCESS,
        data,
        beforeDispId
    };
};

/**
 * 日報日次一覧検索失敗
 */
export const GetDailyReportListFailure = (err) => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_FAILURE,
        err
    };
};

/**
 * 日報日次一覧検索リセット
 */
export const GetDailyReportListReset = () => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_RESET
    };
};

/**
 * 日報日次一覧最新対象月
 */
export const GetDailyReportListLastDate = (data) => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_LASTDATE,
        data
    };
};

/**
 * 日報日次一覧最新対象月リセット
 */
export const GetDailyReportListLastDateReset = () => {
    return {
        type: actionTypes.DAILY_REPORT_LIST_LASTDATE_RESET
    };
};
// ********************Actionの作成 End ********************

/**
 * 日報日次一覧検索Actionの発行
 */
export const GetDailyReportList = (data, beforeDispId) => {
    return async (dispatch) => {
        // Storeへ日報日次一覧検索リクエストActionをdispatchする
        dispatch(GetDailyReportListRequest());
        ecoPostWithAuthHeader(DAILY_REPORT_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ日報日次一覧検索成功Actionをdispatchする
                dispatch(GetDailyReportListSuccess(res.data))
            ).catch(err =>
                // Storeへ日報日次一覧検索失敗Actionをdispatchする
                dispatch(GetDailyReportListFailure(err))
            );
    };
};

