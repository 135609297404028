import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMSMSA01ActionType';

// web面接日履歴取得
const GET_INTERVIEW_HISTORY_POST = BASE_URL + "/api/rs_ms_get_interview_history_info";

// web面接日登録
const CREATE_INTERVIEW_HISTORY_POST = BASE_URL + "/api/rs_ms_update_interview_history_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetInterviewHistoryRequest = () => {
    return {
        type: actionTypes.GET_INTERVIEW_HISTORY_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetInterviewHistorySuccess = (data) => {
    return {
        type: actionTypes.GET_INTERVIEW_HISTORY_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetInterviewHistoryFailure = (err) => {
    return {
        type: actionTypes.GET_INTERVIEW_HISTORY_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetInterviewHistoryReset = () => {
    return {
        type: actionTypes.GET_INTERVIEW_HISTORY_RESET
    };
};


/**
 * 登録リクエスト
 */
export const CreateInterviewHistoryRequest = () => {
    return {
        type: actionTypes.CREAT_INTERVIEW_HISTORY_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const CreateInterviewHistorySuccess = (data) => {
    return {
        type: actionTypes.CREAT_INTERVIEW_HISTORY_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const CreateInterviewHistoryFailure = (err) => {
    return {
        type: actionTypes.CREAT_INTERVIEW_HISTORY_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const CreateInterviewHistoryReset = () => {
    return {
        type: actionTypes.CREAT_INTERVIEW_HISTORY_RESET
    };
};

/**
 * 登録リセット(データも合わせリセットする)
 */
export const CreateInterviewHistoryResetAllData = () => {
    return {
        type: actionTypes.CREATE_INTERVIEW_HISTORY_RESET_ALL_DATA
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getInterviewHistory = (data) => {
    return async (dispatch) => {
        dispatch(GetInterviewHistoryRequest());
        ecoPostWithAuthHeader(GET_INTERVIEW_HISTORY_POST, JSON.stringify(data)).then(
            res => dispatch(GetInterviewHistorySuccess(res.data))
        ).catch(
            err => dispatch(GetInterviewHistoryFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getInterviewHistoryReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetInterviewHistoryReset());
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const createInterviewHistory = (data) => {

    return async (dispatch) => {
        dispatch(CreateInterviewHistoryRequest());
        ecoPostWithAuthHeader(CREATE_INTERVIEW_HISTORY_POST, JSON.stringify(data)).then(
            res => dispatch(CreateInterviewHistorySuccess(res.data))
        ).catch(
            err => dispatch(CreateInterviewHistoryFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const createInterviewHistoryReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(CreateInterviewHistoryReset());
    };
};