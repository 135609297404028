import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from './GISTTPTL01ActionType';

// 実習計画テンプレート一覧検索APIURL定義
const TRAINING_PLAN_TEMPLATE_LIST_POST = BASE_URL + "/api/is_tt_get_working_plan_template_list";

// 実習計画テンプレート詳細取得APIURL定義
const TRAINING_PLAN_TEMPLATE_DETAIL_POST = BASE_URL + "/api/is_tt_get_working_plan_template_info";

// 実習計画テンプレート削除APIURL定義
const TRAINING_PLAN_TEMPLATE_DELETE_POST = BASE_URL + "/api/is_tt_delete_working_plan_template_info";

// ********************Actionの作成 Start ********************
/**
 * 実習計画テンプレート一覧検索
 */
export const GetTrainingPlanTemplateListRequest = () => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_TEMPLATE_LIST_REQUEST
    };
};

/**
 * 実習計画テンプレート一覧検索成功
 */
export const GetTrainingPlanTemplateListSuccess = (data) => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_TEMPLATE_LIST_SUCCESS,
        data
    };
};

/**
 * 実習計画テンプレート一覧検索失敗
 */
export const GetTrainingPlanTemplateListFailure = (err) => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_TEMPLATE_LIST_FAILURE,
        err
    };
};

/**
 * 実習計画テンプレート一覧検索リセット
 */
export const GetTrainingPlanTemplateListReset = () => {
    return {
        type: actionTypes.GET_TRAINING_PLAN_TEMPLATE_LIST_RESET
    };
};

/**
 * 実習計画テンプレート削除
 */
export const TrainingPlanTemplateDeleteRequest = () => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DELETE_REQUEST
    };
};

/**
 * 実習計画テンプレート削除成功
 */
export const TrainingPlanTemplateDeleteSuccess = (data) => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DELETE_SUCCESS,
        data
    };
};

/**
 * 実習計画テンプレート削除失敗
 */
export const TrainingPlanTemplateDeleteFailure = (err) => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DELETE_FAILURE,
        err
    };
};

/**
 * 実習計画テンプレート詳細取得
 */
export const TrainingPlanTemplateDetailRequest = () => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DETAIL_REQUEST
    };
};

/**
 * 実習計画テンプレート詳細取得成功
 */
export const TrainingPlanTemplateDetailSuccess = (data) => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DETAIL_SUCCESS,
        data
    };
};

/**
 * 実習計画テンプレート詳細取得失敗
 */
export const TrainingPlanTemplateDetailFailure = (err) => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DETAIL_FAILURE,
        err
    };
};

/**
 * 実習計画テンプレート詳細取得リセット
 */
export const TrainingPlanTemplateDetailReset = () => {
    return {
        type: actionTypes.TRAINING_PLAN_TEMPLATE_DETAIL_RESET
    };
};


// ********************Actionの作成 End ********************

/**
 * 実習計画テンプレート一覧検索Actionの発行
 */
export const getTrainingPlanTemplateList = (data) => {
    return async (dispatch) => {
        // Storeへ実習計画テンプレート一覧検索リクエストActionをdispatchする
        dispatch(GetTrainingPlanTemplateListRequest());
        ecoPostWithAuthHeader(TRAINING_PLAN_TEMPLATE_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ実習計画テンプレート一覧検索成功Actionをdispatchする
                dispatch(GetTrainingPlanTemplateListSuccess(res.data))
            ).catch(err =>
                // Storeへ実習計画テンプレート一覧検索失敗Actionをdispatchする
                dispatch(GetTrainingPlanTemplateListFailure(err))
            );
    };
};

/**
 * 実習計画テンプレート詳細取得Actionの発行
 */
export const getTrainingPlanTemplateDetail = (data) => {
    return async (dispatch) => {
        // Storeへ実習計画テンプレート詳細情報取リクエストActionをdispatchする
        dispatch(TrainingPlanTemplateDetailRequest());
        ecoPostWithAuthHeader(TRAINING_PLAN_TEMPLATE_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ実習計画テンプレート詳細情報取得成功Actionをdispatchする
                dispatch(TrainingPlanTemplateDetailSuccess(res.data))
            ).catch(err =>
                // Storeへ実習計画テンプレート詳細情報取得失敗Actionをdispatchする
                dispatch(TrainingPlanTemplateDetailFailure(err))
            );
    };
};

/**
 * 実習計画テンプレート削除Actionの発行
 */
export const deleteTrainingPlanTemplate = (data) => {
    return async (dispatch) => {
        // Storeへ実習計画テンプレート詳細削除リクエストActionをdispatchする
        dispatch(TrainingPlanTemplateDeleteRequest());
        ecoPostWithAuthHeader(TRAINING_PLAN_TEMPLATE_DELETE_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ実習計画テンプレート詳細情報削除成功Actionをdispatchする
                dispatch(TrainingPlanTemplateDeleteSuccess(res.data))
            ).catch(err =>
                // Storeへ実習計画テンプレート詳細情報削除失敗Actionをdispatchする
                dispatch(TrainingPlanTemplateDeleteFailure(err))
            );
    };
};

/**
 * 画面リセットAction
 */
export const trainingPlanTemplateListReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetTrainingPlanTemplateListReset());
    };
};

/**
 * 画面リセットAction
 */
export const trainingPlanTemplateDetailReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(TrainingPlanTemplateDetailReset());
    };
};
