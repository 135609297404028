import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getCountryMasterList, getCodeValueLabelList, getMessage } from "../../Constants/CommonConstants";
import { createCase, createCaseReset } from "../MmGRSMMIMR01/GRSMMIMR01Action";
import { isBlank } from "../../Components/Tools/StringTools";
import { MessageMap } from "../MmGRSMMIMR01/GRSMMIMR01Message";
import TextInput from "../../Components/Atoms/TextInput";
import Textarea from "../../Components/Atoms/Textarea";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import NextButton_Icon from "../../Images/img_nextbtn.svg";
import Intern_Icon from "../../Images/icon_intern.svg";
import "../../Css/Common.css";

/**
 * インターン案件募集要項登録（条件・業務）
 */
class GRSMMIMR03 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            candidate_sex: "",
            gender: [],
            country_select_flg: "",
            countryItems: [],
            occupation: "",
            content: "",
            skt_comment: "",
            isDisabled: false,
            caseOutline: this.props.caseOutline,
            caseDetail: this.props.caseDetail
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        const { caseDetail } = this.state;
        this.setState({
            candidate_sex: caseDetail.candidate_sex,
            country_select_flg: caseDetail.country_select_flg,
            countryItems: !isBlank(caseDetail.country) ? caseDetail.country.split(',') : "",
            occupation: caseDetail.occupation,
            content: caseDetail.content,
            skt_comment: caseDetail.skt_comment
        }, () => {
            // 処理区分＝新規の場合の場合
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成中)
            // 処理区分＝訂正 かつ 案件一覧情報．案件ステータス(作成済) かつ 当日＜案件一覧情報．募集開始日の場合
            if ((this.props.createorupdate === 'create') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '9') ||
                (this.props.createorupdate === 'update' && caseDetail.case_status === '2' && caseDetail.sys_date < caseDetail.pub_period_start)) {
                //【途中保存】ボタン表示
            } else {
                //【途中保存】ボタン非表示
                this.setState({ isDisabled: true });
            }
        });

        // 性別
        const genderRet = getCodeValueLabelList('RSC0007');
        this.setState(prevState => ({
            gender: [...prevState.gender, ...genderRet]
        }));
        window.scrollTo(0, 0);
    }

    /**
     * 性別変更
     * @param {*} event
     */
    handleGenderChange = (event) => {
        this.setState({ candidate_sex: event.target.value }, () => {
            this.editItemHandler('candidate_sex', this.state.candidate_sex);
        });
    };

    /**
     * 国籍指定
     * @param {*} event
     */
    handleDesignationChanged = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.editItemHandler('country_select_flg', this.state.country_select_flg);
            if (this.state.country_select_flg === '0') {
                this.setState({ countryItems: [] }, () => {
                    this.editItemHandler('country', '');
                });
            }
        });
    };

    /**
     * 国・地域名の処理
     * @param {*} event
     */
    handleCountryChange = (event) => {
        const { value } = event.target;
        const { countryItems } = this.state;
        const presentIndex = [...countryItems].indexOf(value);

        // 存在しない場合
        if (presentIndex === -1) {
            this.setState({ countryItems: [...countryItems, value] }, () => {
                let items = "";
                this.state.countryItems.map((item, index) => {
                    items = items.concat(item, ',');
                });
                this.editItemHandler('country', items.substr(0, items.length - 1));
            });
        } else {
            countryItems.splice(presentIndex, 1);
            this.setState({ countryItems: countryItems }, () => {
                let items = "";
                this.state.countryItems.map((item, index) => {
                    items = items.concat(item, ',');
                });
                this.editItemHandler('country', items.substr(0, items.length - 1));
            });
        }
    };

    /**
     * 画面切替処理
     * @param {*} changeTo
     * @param {*} isReset
     */
    handleToDisplay = (changeTo, isReset) => {
        if (isReset) {
            this.props.getCaseDetailReset();
        }
        return this.props.changeDisp(changeTo, isReset);
    }

    /**
     * 画面部品編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        this.setState(prevState => ({
            caseDetail: {
                ...prevState.caseDetail,
                [name]: value
            }
        }));
    }

    /**
     * ローディング、エラー画面リセット
     */
    saveReset = () => {
        this.props.createCaseReset();
    }

    /**
     * 途中保存
     */
    temporalySave = () => {
        this.props.createCaseReset();
        window.scrollTo(0, 0);
        // システムエラーのコールバック設定
        this.setErrorCallback(this.saveReset);
        let caseDetailData = this.state.caseDetail;
        if (caseDetailData.case_id == '' || caseDetailData.case_id == null) {
            if (this.props.data && this.props.data.case_id != null) {
                caseDetailData.case_id = this.props.data.case_id;
            }
        }
        caseDetailData.temp_save = '1';
        this.setState({
            caseDetail: caseDetailData
        }, async () => {
            let reqData = JSON.parse(JSON.stringify(this.state.caseDetail));
            const ret = await this.props.uploadImageFiles(reqData.case_image);
            if (ret) {
                if (reqData.case_image && reqData.case_image.length > 0) {
                    // 一時ファイル削除記録
                    let fileNameList = [];
                    reqData.case_image.map(image => {
                        if (!isBlank(image.image_content)) {
                            fileNameList.push(image.image_content);
                        }
                    });
                    if (fileNameList.length > 0) {
                        this.setState({ imageFileNameList: fileNameList });
                    }
                }
                this.props.createCase(reqData);
            }
        });
    }

    /**
     * 次へ
     * @param {*} changeTo
     * @param {*} outline
     * @param {*} detail
     */
    skipToNext = (changeTo, outline, detail) => {
        return this.props.skipToNext(changeTo, outline, detail);
    }

    render() {
        const { caseDetail, countryItems, isDisabled } = this.state;

        if (!this.props.isFetching) {
            // 最新バージョン設定する
            if (this.props.data && this.props.data.version_no != null && this.state.caseDetail.version_no != this.props.data.version_no) {
                let caseDetailData = this.state.caseDetail;
                caseDetailData.version_no = this.props.data.version_no;
                this.setState({
                    caseDetail: caseDetailData
                });
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                        this.showModalDialog(0, getMessage('RS-0074'), () => { });
                    });
                } else {
                    this.showModalDialog(0, getMessage('RS-0074'), () => { });
                }
            }
            if (!isBlank(this.props.err_type) && this.props.err_type != '200') {
                // 入力チェックなどエラーが発生時に一時ファイル削除
                if (this.state.imageFileNameList && this.state.imageFileNameList.length > 0) {
                    const deleteList = this.state.imageFileNameList;
                    this.setState({
                        imageFileNameList: null
                    }, () => {
                        this.props.deleteImageFiles(deleteList);
                    });
                }
            }
        }

        const count = Object.keys(getCountryMasterList()).length;

        // 国籍指定
        const designation = getCodeValueLabelList('RSC0009');

        // 国・地域名
        const countryCheckBoxs = Object.entries(getCountryMasterList()).map(([k, v]) => {
            if (v.country_code !== "JPN") {
                return (
                    <label className="el_label" key={v.country_number}>
                        <input type="checkbox" className="el_checkbox"
                            name={v.country_name}
                            value={v.country_code}
                            checked={countryItems.includes(v.country_code)}
                            onChange={this.handleCountryChange} disabled={this.state.country_select_flg === '0'}
                            tabIndex={4 + count - (count - 1)} />
                        <span className="el_checkbox_txt">{v.country_name}</span>
                    </label>
                );
            }
        });

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                インターン管理
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li>
                                    <a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a>
                                </li>
                                <li>
                                    <a href="#" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.handleToDisplay("GRSMMMLI01", true); }, () => { })}>案件一覧</a>
                                </li>
                                <li>インターン登録</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">インターン登録</h2>
                                </div>
                                <ErrorMessage err_type={this.props.err_type} err_detail={this.props.err_detail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_stepbarUnit">
                                        <ol className="bl_stepbar">
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR01", this.state.caseOutline, this.state.caseDetail)}>募集手続</a>
                                            </li>
                                            <li className="bl_stepbar_item">
                                                <a href="#" className="bl_stepbar_link" onClick={() => this.skipToNext("GRSMMIMR02", this.state.caseOutline, this.state.caseDetail)}>会社情報</a>
                                            </li>
                                            <li className="bl_stepbar_item is_current">
                                                <span>条件・業務</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>待遇</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>施設紹介</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>周辺情報</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>サポート<br />サービス</span>
                                            </li>
                                            <li className="bl_stepbar_item is_disabled">
                                                <span>登録完了</span>
                                            </li>
                                        </ol>
                                    </div>
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.skipToNext("GRSMMIMR02", this.state.caseOutline, caseDetail)} tabIndex="18">戻る</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR04", this.state.caseOutline, caseDetail)} tabIndex="17">次へ</button>
                                    </div>
                                    <div className="bl_sect_desc">
                                        <p>以下の項目を入力し、<span className="un_nextBtnImg"><img src={NextButton_Icon} alt="次へ" /></span>ボタンを押してください。<span className="el_badge el_badge__required hp_mr5">必須</span>のついた項目は必須入力です。</p>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>条件・業務</span></h3>
                                        </div>
                                        <table className="bl_horizTable">
                                            <tbody>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />性別</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <select className="el_select" name="candidate_sex" onChange={this.handleGenderChange} tabIndex="1">
                                                                {
                                                                    this.state.gender.map(item => (
                                                                        <option key={item.value} value={item.value} selected={caseDetail.candidate_sex == item.value}>
                                                                            {item.label}
                                                                        </option>
                                                                    ))
                                                                };
                                                            </select>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />国籍</th>
                                                    <td>
                                                        {
                                                            designation.map((item, index) => {
                                                                return (
                                                                    <>
                                                                        <div className="bl_formUnit hp_mb15" key={index}>
                                                                            <input type="radio" name="country_select_flg" value={item.value} checked={this.state.country_select_flg === item.value}
                                                                                onChange={this.handleDesignationChanged} tabIndex={1 + index + 1} />
                                                                            <span className="hp_mr20">{item.label}</span>
                                                                        </div>
                                                                    </>
                                                                );
                                                            })
                                                        }
                                                        <div className="bl_formUnit">
                                                            {countryCheckBoxs}
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>
                                                        <span className="el_badge el_badge__required">必須</span><br />学習職種
                                                    </th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <TextInput type="text" name="occupation" className="el_input el_input__max" maxlength="60" required="required"
                                                                setValue={caseDetail.occupation} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="12" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th><span className="el_badge el_badge__required">必須</span><br />学習内容</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <Textarea name="content" rows="10" required="required" maxlength="618" className="el_textarea"
                                                                setValue={caseDetail.content} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="13" />
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>自由記入</th>
                                                    <td>
                                                        <div className="bl_formUnit">
                                                            <Textarea name="skt_comment" rows="10" required="required" maxlength="618" className="el_textarea"
                                                                setValue={caseDetail.skt_comment} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="14" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                    <div className="bl_horizBtnUnit">
                                        <button type="button" className="el_saveBtn" style={{ display: isDisabled ? 'none' : 'block' }} onClick={() => this.temporalySave()} tabIndex="15">途中保存</button>
                                        <button type="button" className="el_nextBtn" onClick={() => this.skipToNext("GRSMMIMR04", this.state.caseOutline, this.state.caseDetail)} tabIndex="16">次へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.createCase.isFetching,
        data: state.createCase.caseData,
        isError: state.createCase.isError,
        err_type: state.createCase.err_type,
        err_detail: state.createCase.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        createCase(condition) {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCase(condition));
        },
        createCaseReset() {
            // actionCreatorの関数を使用してactionをdispatchに返してそれを実行している。
            // その後、createStoreで登録したreducerに処理が移る
            dispatch(createCaseReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GRSMMIMR03);

