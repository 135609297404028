import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from "./GCMOMORU03ActionType";

// 組織変更取得API
const GET_CHANGE_ORG_INFO_POST = BASE_URL + "/api/cm_om_get_change_org_info";
// 組織変更更新API
const UPDATE_CHANGE_ORG_INFO_POST = BASE_URL + "/api/cm_om_update_change_org_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetChangeOrgInfoRequest = () => {
    return {
        type: actionTypes.GET_CHANGE_ORG_INFO_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetChangeOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.GET_CHANGE_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetChangeOrgInfoFailure = (err) => {
    return {
        type: actionTypes.GET_CHANGE_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetChangeOrgInfoReset = () => {
    return {
        type: actionTypes.GET_CHANGE_ORG_INFO_RESET
    };
};

/**
 * 更新リクエスト
 */
export const UpdateChangeOrgInfoRequest = () => {
    return {
        type: actionTypes.UPDATE_CHANGE_ORG_INFO_REQUEST
    };
};

/**
 * 更新成功
 * @param {*} data
 */
export const UpdateChangeOrgInfoSuccess = (data) => {
    return {
        type: actionTypes.UPDATE_CHANGE_ORG_INFO_SUCCESS,
        data
    };
};

/**
 * 更新失敗
 * @param {*} err
 */
export const UpdateChangeOrgInfoFailure = (err) => {
    return {
        type: actionTypes.UPDATE_CHANGE_ORG_INFO_FAILURE,
        err
    };
};

/**
 * 更新リセット
 */
export const UpdateChangeOrgInfoReset = () => {
    return {
        type: actionTypes.UPDATE_CHANGE_ORG_INFO_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 取得処理
 * @param {*} data
 */
export const getChangeOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(GetChangeOrgInfoRequest());
        ecoPostWithAuthHeader(GET_CHANGE_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(GetChangeOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(GetChangeOrgInfoFailure(err))
        );
    };
};

/**
 * 登録処理
 * @param {*} data
 */
export const updateChangeOrgInfo = (data) => {
    return async (dispatch) => {
        dispatch(UpdateChangeOrgInfoRequest());
        ecoPostWithAuthHeader(UPDATE_CHANGE_ORG_INFO_POST, JSON.stringify(data)).then(
            res => dispatch(UpdateChangeOrgInfoSuccess(res.data))
        ).catch(
            err => dispatch(UpdateChangeOrgInfoFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const getChangeOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetChangeOrgInfoReset());
    };
};

/**
 * 画面リセットAction
 */
export const updateChangeOrgInfoReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(UpdateChangeOrgInfoReset());
    };
};
