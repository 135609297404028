import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import GAFMCMCF01 from "../Af/McGAFMCMCF01/GAFMCMCF01";
import GCMOMCUM01 from "../Cm/OmGCMOMCUM01/GCMOMCUM01";
import GESEQQRI01 from "./EqGESEQQRI01/GESEQQRI01";
import GESEQQRE01 from "./EqGESEQQRE01/GESEQQRE01";
import GESSFELI01 from "./SfGESSFELI01/GESSFELI01";
import GESSFCES01 from "./SfGESSFCES01/GESSFCES01";
import GESSFAUS01 from "./SfGESSFAUS01/GESSFAUS01";
import GESSFQUS01 from "./SfGESSFQUS01/GESSFQUS01";
import GESSFCMA01 from "./SfGESSFCMA01/GESSFCMA01";
import GESSFCQA01 from "./SfGESSFCQA01/GESSFCQA01";
import { getOkrUniUsageResultsDetail, GetOkrUniUsageResultsDetailReset } from "./SfGESSFQUS01/GESSFQUS01Action";
import { getCopUsageResultsDetail, GetCopUsageResultsDetailReset } from "./SfGESSFCQA01/GESSFCQA01Action";

/**
 * 評価テンプレート
 */
class EsTemplate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId,
            detailData: null
        };
    }

    /**
     * 画面初期化イベント
     */
    componentDidMount = () => {
        this.setState({
            display: this.props.location.dispId,
            beforeDispId: this.props.location.dispId
        });
    }

    /**
     * 画面切替処理（データ連携あり）
     * @param {*} dispId
     * @param {*} detailData
     */
    redirectToView = (dispId, detailData) => {
        const beforeDispId = this.state.display;
    }

    /**
     * 画面切り替え
     * @param {*} dispId
     * @param {*} detailData
     */
    switchDisplay = (dispId, detailData) => {
        this.setState({
            display: dispId,
            detailData: detailData
        }, () => {
            if (this.state.display === "GESSFQUS01") {
                const { case_id, organization_id } = this.state.detailData;
                this.props.getOkrUniUsageResultsDetail({ case_id: case_id, organization_id: organization_id });
            } else if (this.state.display === "GESSFCQA01") {
                const { case_id, organization_id } = this.state.detailData;
                this.props.getCopUsageResultsDetail({ case_id: case_id, organization_id: organization_id });
            }
        });
    }

    render() {
        let displayParts;
        if (this.state.display != this.props.location.dispId && this.props.location.dispId != null) {
            this.componentDidMount();
        }
        if (this.state.display === "GAFMCMCFEQ01_GESEQQRI01") {
            // ユーザ一覧(アンケート登録（実習生用）)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFEQ01_GESEQQRI01" key={this.state.beforeDispId} />;
        } else if (this.state.display === "GAFMCMCFEQ01_GESEQQRE01") {
            // ユーザ一覧(アンケート登録（企業用）)
            displayParts = <GCMOMCUM01 changeDisp={this.switchDisplay} beforeDispId="GAFMCMCFEQ01_GESEQQRE01" key={this.state.beforeDispId} />;
        } else if (this.state.display === "GESEQQRI01") {
            displayParts = <GESEQQRI01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} />;
        } else if (this.state.display === "GESEQQRE01") {
            displayParts = <GESEQQRE01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} />;
        } else if (this.state.display === "GESSFELI01") {
            displayParts = <GESSFELI01 changeDisp={this.switchDisplay} redirectToView={this.redirectToView} />;
        } else if (this.state.display === "GESSFCES01") {
            const { organization_id, org_type, organization_type } = this.state.detailData;
            displayParts = <GESSFCES01 changeDisp={this.switchDisplay} detailData={{ organization_id: organization_id, organization_type: org_type || organization_type }} />;
        } else if (this.state.display === "GESSFAUS01") {
            const { organization_id, organization_type } = this.state.detailData;
            displayParts = <GESSFAUS01 changeDisp={this.switchDisplay} detailData={{ organization_id: organization_id, organization_type: organization_type }} />;
        } else if (this.state.display === "GESSFQUS01") {
            if (this.props.okrUniUsageResultsDetailData) {
                const { organization_id, organization_type } = this.state.detailData;
                displayParts = <GESSFQUS01 changeDisp={this.switchDisplay} detailData={{ ...this.props.okrUniUsageResultsDetailData, organization_id: organization_id, organization_type: organization_type }} />;
            }
        } else if (this.state.display === "GESSFCMA01") {
            const { organization_id, organization_type } = this.state.detailData;
            displayParts = <GESSFCMA01 changeDisp={this.switchDisplay} detailData={{ organization_id: organization_id, organization_type: organization_type }} />;
        } else if (this.state.display === "GESSFCQA01") {
            if (this.props.copUsageResultsDetailData) {
                const { organization_id, organization_type } = this.state.detailData;
                displayParts = <GESSFCQA01 changeDisp={this.switchDisplay} detailData={{ ...this.props.copUsageResultsDetailData, organization_id: organization_id, organization_type: organization_type }} />;
            }
        }

        return (
            <article className="contentsArea" >
                <GAFMCMCF01 changeDisp={this.switchDisplay} displayParts={displayParts} toggleClass={this.props.location.toggleClass} menuIndex={this.props.location.menuIndex} />
            </article>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // 送り出し機関・大学アンケート照会
        okrUniUsageResultsDetailData: state.getOkrUniUsageResultsDetail.data,
        isOkrUniUsageResultsDetailError: state.getOkrUniUsageResultsDetail.isError,
        okrUniUsageResultsDetailErrType: state.getOkrUniUsageResultsDetail.err_type,
        okrUniUsageResultsDetailErrDetail: state.getOkrUniUsageResultsDetail.err_detail,
        // 企業アンケート照会
        copUsageResultsDetailData: state.getCopUsageResultsDetail.data,
        isCopUsageResultsDetailError: state.getCopUsageResultsDetail.isError,
        copUsageResultsDetailErrType: state.getCopUsageResultsDetail.err_type,
        copUsageResultsDetailErrDetail: state.getCopUsageResultsDetail.err_detail,
    };
};

/**
 * データ取得のActionバインディングする
 * @param {*} dispatch
 */
const mapDispatchToProps = dispatch => {
    return {
        getOkrUniUsageResultsDetail(arg) {
            // 送り出し機関・大学アンケート照会取得
            dispatch(getOkrUniUsageResultsDetail(arg));
        },
        getOkrUniUsageResultsDetailReset() {
            dispatch(GetOkrUniUsageResultsDetailReset());
        },
        getCopUsageResultsDetail(arg) {
            // 企業アンケート照会取得
            dispatch(getCopUsageResultsDetail(arg));
        },
        getCopUsageResultsDetailReset() {
            dispatch(GetCopUsageResultsDetailReset());
        },
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EsTemplate)
);

