import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GTMWTWTI01ActionType';

// 作業時間管理を取得API
const GET_WORKINGTIME_INQUIRY_POST = BASE_URL + "/api/tm_wt_get_working_time_info";

// ********************Actionの作成 Start ********************

/**
 * 取得リクエスト
 */
export const GetWorkingtimeInquiryRequest = () => {
    return {
        type: actionTypes.GET_WORKINGTIME_INQUIRY_REQUEST
    };
};

/**
 * 取得成功
 * @param {*} data
 */
export const GetWorkingtimeInquirySuccess = (data) => {
    return {
        type: actionTypes.GET_WORKINGTIME_INQUIRY_SUCCESS,
        data
    };
};

/**
 * 取得失敗
 * @param {*} err
 */
export const GetWorkingtimeInquiryFailure = (err) => {
    return {
        type: actionTypes.GET_WORKINGTIME_INQUIRY_FAILURE,
        err
    };
};

/**
 * 取得リセット
 */
export const GetWorkingtimeInquiryReset = () => {
    return {
        type: actionTypes.GET_WORKINGTIME_INQUIRY_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理
 * @param {*} data
 */
export const getWorkingtimeInquiry = (data) => {
    return async (dispatch) => {
        dispatch(GetWorkingtimeInquiryRequest());
        ecoPostWithAuthHeader(GET_WORKINGTIME_INQUIRY_POST, JSON.stringify(data)).then(
            res => dispatch(GetWorkingtimeInquirySuccess(res.data))
        ).catch(
            err => dispatch(GetWorkingtimeInquiryFailure(err))
        );
    };
};

/**
 * 画面リセットAction
 */
export const workingtimeInquiryReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(GetWorkingtimeInquiryReset());
    };
};