import actionTypes from './GICRMIAI01ActionType';

/**
 * 保険申込履歴
 */
const initialState = {
    isFetching: false,
    data: [],
    isError: false,
    err_type: "",
    err_detail: []
};

/**
 * 保険申込履歴を検索するReducer
 * @param {*} state
 * @param {*} action
 */
export const searchInsuranceApplicationDetail = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSURANCE_APPLICATION_DETAILS_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: {},
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data.data,
                isError: false,
                err_type: action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.INSURANCE_APPLICATION_DETAILS_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.INSURANCE_APPLICATION_DETAILS_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                data: {},
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};

/**
 * 保険申込履歴のダウンロードReducer
 * @param {*} state
 * @param {*} action
 */
export const insuranceDownloadFile = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSURANCE_DOWNLOAD_FILE_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                data: [],
                isError: false,
                err_type: "",
                err_detail: []
            });
        case actionTypes.INSURANCE_DOWNLOAD_FILE_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                data: action.data,
                isError: false,
                err_type: action.data.err_type == null ? "200" : action.data.err_type,
                err_detail: action.data.err_detail
            });
        case actionTypes.INSURANCE_DOWNLOAD_FILE_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                data: {},
                isError: true,
                err_type: action.err,
                err_detail: []
            });
        case actionTypes.INSURANCE_DOWNLOAD_FILE_RESET:
            return Object.assign({}, state, {
                isFetching: false,
                isError: false,
                err_type: "",
                err_detail: []
            });
        default:
            return state;
    }
};
