// メッセージMap
export const MessageMap = {
    'search_company_name': '企業名',
    'search_facility_name': '施設名',
    'search_industry': '業種',
    'search_status_of_residence': '在留資格',
    'search_case_id': '案件ID',
    'search_case_title': '案件名',
    'search_accept_period_start': '受入・就業期間（From）',
    'search_accept_period_end': '受入・就業期間（To）',
    'sort_case_view': '並び替え'
};
