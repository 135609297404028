/**
 * Action Typesを定義
 */
const GCMOMCUM02ActionType = {
    GET_TRAINNING_WORKER_DETAIL_REQUEST: 'GET_TRAINNING_WORKER_DETAIL_REQUEST',  // 実習／労働者詳細取得定義
    GET_TRAINNING_WORKER_DETAIL_SUCCESS: 'GET_TRAINNING_WORKER_DETAIL_SUCCESS',  // 実習／労働者詳細取得成功定義
    GET_TRAINNING_WORKER_DETAIL_FAILURE: 'GET_TRAINNING_WORKER_DETAIL_FAILURE',  // 実習／労働者詳細取得失敗定義
    GET_TRAINNING_WORKER_DETAIL_RESET: 'GET_TRAINNING_WORKER_DETAIL_RESET',       // 実習／労働者詳細取得リセット定義
};

export default GCMOMCUM02ActionType;
