/**
 * Action Typesを定義
 */
const GISTTPTR01ActionType = {
    SAVE_TRAINING_PLAN_TEMPLATE_REQUEST: 'SAVE_TRAINING_PLAN_TEMPLATE_REQUEST',  // 実習計画テンプレートテーブル登録定義
    SAVE_TRAINING_PLAN_TEMPLATE_SUCCESS: 'SAVE_TRAINING_PLAN_TEMPLATE_SUCCESS',  // 実習計画テンプレートテーブル登録成功定義
    SAVE_TRAINING_PLAN_TEMPLATE_FAILURE: 'SAVE_TRAINING_PLAN_TEMPLATE_FAILURE',  // 実習計画テンプレートテーブル登録失敗定義
    SAVE_TRAINING_PLAN_TEMPLATE_RESET: 'SAVE_TRAINING_PLAN_TEMPLATE_RESET'       // 実習計画テンプレートテーブル登録リセット定義
};

export default GISTTPTR01ActionType;