import React, { useEffect, useState } from "react";

/**
 * テキストエリアコンポーネント
 * @param {*} props
 */
export default function Textarea(props) {
    // 現在の値と更新用関数を入手、初期値 setValue をセット
    const [txt, setTxt] = useState(props.setValue);

    /**
     * 値が変更された処理
     * @param {*} e
     */
    const handleChange = (e) => {
        const { name, value } = e.target;
        setTxt(value);
        props.onHandleChange(name, value);
    };

    // レンダリング後に実行されるので、確実に取得できる
    useEffect(() => {
        setTxt(props.setValue);
    });

    return (
        <textarea
            type="text"
            id={props.id}
            name={props.name}
            className={props.className}
            rows={props.rows ? props.rows : 3}
            cols={props.cols ? props.cols : 80}
            value={txt}
            onChange={handleChange}
            placeholder={props.placeholder}
            required={props.required}
            maxLength={props.maxlength}
            tabIndex={props.tabIndex}
            disabled={props.disabled}
        />
    );
}
