import React from "react";
import { isBlank } from "../Tools/StringTools";
import "../../Css/ScoreStar.css";

/**
 * @param {*} props
 */
export default function ScoreStar(props) {
    let starScore = [];
    if (!isBlank(props.score)) {
        const unscore = 5 - props.score;
        const scorehalf = props.score * 10 % 10 / 10;
        for (let i = 1; i <= props.score; i++) {
            let leftScoreCssClass = 'star_score';
            let rightScoreCssClass = 'star_score';
            starScore.push({ left: leftScoreCssClass, right: rightScoreCssClass });
        }
        for (let i = 0; i < unscore; i++) {
            let leftScoreCssClass = 'star_unscore';
            let rightScoreCssClass = 'star_unscore';
            if (i == 0) {
                if (scorehalf >= 0.3) {
                    leftScoreCssClass = 'star_score';
                }
                if (scorehalf > 0.7) {
                    rightScoreCssClass = 'star_score';
                }
            }
            starScore.push({ left: leftScoreCssClass, right: rightScoreCssClass });
        }
    }
    return (
        (() => {
            if (starScore !== undefined && starScore.length > 0) {
                return (
                    starScore.map((star, index) => (
                        <span className="star_nowrap">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1" height="20" width="10">
                                <polygon points="10,0 3,20 20,8 0,8 17,20" class={star.left} />
                            </svg>
                            <svg class="rotate_y_180" xmlns="http://www.w3.org/2000/svg" version="1" height="20" width="10">
                                <polygon points="10,0 3,20 20,8 0,8 17,20" class={star.right} />
                            </svg>
                        </span>
                    ))
                );
            } else {
                return null
            }
        })()
    );
}
