/**
 * Action Typesを定義
 */
const GTMWTWTI01ActionType = {
    GET_WORKINGTIME_INQUIRY_REQUEST: 'GET_WORKINGTIME_INQUIRY_REQUEST',
    GET_WORKINGTIME_INQUIRY_SUCCESS: 'GET_WORKINGTIME_INQUIRY_SUCCESS',
    GET_WORKINGTIME_INQUIRY_FAILURE: 'GET_WORKINGTIME_INQUIRY_FAILURE',
    GET_WORKINGTIME_INQUIRY_RESET: 'GET_WORKINGTIME_INQUIRY_RESET'
};

export default GTMWTWTI01ActionType;