import { getNoticeList, getNoticeDetail, updateTodoTable } from "./McGAFMCSNF01/GAFMCSNF01Reducer";
import { changePassword } from "./LfGAFLFCPF01/GAFLFCPF01Reducer";
import { getPersonalInfo, updatePersonalInfo } from "./LfGAFLFLAF02/GAFLFLAF02Reducer";
import { menu } from "./McGAFMCMCF01/GAFMCMCF01Reducer";
export const afReducer = {
    menu,
    getNoticeList,
    getNoticeDetail,
    updateTodoTable,
    changePassword,
    getPersonalInfo,
    updatePersonalInfo
};
