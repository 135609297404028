import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMIMD02ActionTypes';

// ケースエントリリスト更新API
const CASE_ENTRY_LIST_ACTION_POST = BASE_URL + "/api/rs_mm_case_entry_list_action";

// ********************Actionの作成 Start ********************

/**
 * ケースエントリリスト
 */
export const CaseEntryListActionRequest = () => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_ACTION_REQUEST
    };
};

/**
 * ケースエントリリスト更新成功
 * @param {*} data
 */
export const CaseEntryListActionSuccess = (data) => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_ACTION_SUCCESS,
        data
    };
};

/**
 * ケースエントリリスト更新失敗
 * @param {*} err
 */
export const CaseEntryListActionFailure = (err) => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_ACTION_FAILURE,
        err
    };
};

/**
 * ケースエントリリスト更新リセット
 */
export const CaseEntryListActionReset = () => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_ACTION_RESET
    };
};

/**
 * ケースエントリリスト
 */
export const SelectedCandidateListActionRequest = (data) => {
    return {
        type: actionTypes.SELECTED_CANDIDATE_LIST_ACTION_REQUEST,
        data
    };
};

/**
 * ケースエントリリスト
 */
export const CaseEntryListUpdateActionRequest = () => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_REQUEST
    };
};

/**
 * ケースエントリリスト更新成功
 * @param {*} data 
 */
export const CaseEntryListUpdateActionSuccess = (data) => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_SUCCESS,
        data
    };
};

/**
 * ケースエントリリスト更新失敗
 * @param {*} err 
 */
export const CaseEntryListUpdateActionFailure = (err) => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_FAILURE,
        err
    };
};

/**
 * ケースエントリリスト更新リセット
 */
export const CaseEntryListUpdateActionReset = () => {
    return {
        type: actionTypes.CASE_ENTRY_LIST_UPDATE_ACTION_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * ケースエントリ詳細情報更新Actionの発行
 * @param {*} data
 */
export const updateCaseEntryList = (data) => {

    return async (dispatch) => {
        // Storeへエントリ詳細情報更新リクエストActionをdispatchする
        dispatch(CaseEntryListActionRequest());
        ecoPostWithAuthHeader(CASE_ENTRY_LIST_ACTION_POST, JSON.stringify(data))
            .then(res =>
                // Storeへエントリ詳細情報更新成功Actionをdispatchする
                dispatch(CaseEntryListActionSuccess(res.data))
            ).catch(err =>
                // Storeへエントリ詳細情報更新失敗Actionをdispatchする
                dispatch(CaseEntryListActionFailure(err))
            );
    };
};

/**
 * ケースエントリ詳細情報更新Actionの発行
 * @param {*} data 
 */
export const updateCaseEntryListByCandidate = (data) => {

    return async (dispatch) => {
        // Storeへエントリ詳細情報更新リクエストActionをdispatchする
        dispatch(CaseEntryListUpdateActionRequest());
        ecoPostWithAuthHeader(CASE_ENTRY_LIST_ACTION_POST, JSON.stringify(data))
            .then(res =>
                // Storeへエントリ詳細情報更新成功Actionをdispatchする
                dispatch(CaseEntryListUpdateActionSuccess(res.data))
            ).catch(err =>
                // Storeへエントリ詳細情報更新失敗Actionをdispatchする
                dispatch(CaseEntryListUpdateActionFailure(err))
            );
    };
};

/**
 * ケースエントリ詳細情報更新Actionの発行
 * @param {*} data 
 */
export const updateSelectedCandidateList = (data) => {

    return async (dispatch) => {
        // Storeへエントリ詳細情報更新リクエストActionをdispatchする
        dispatch(SelectedCandidateListActionRequest(data));

    };
};
