/**
 * Action Typesを定義
 */
const GISIPWPL01ActionType = {
    GET_WORK_PLAN_INFO_LIST_REQUEST: 'GET_WORK_PLAN_INFO_LIST_REQUEST',         // 作業計画案件取得定義
    GET_WORK_PLAN_INFO_LIST_SUCCESS: 'GET_WORK_PLAN_INFO_LIST_SUCCESS',         // 作業計画案件取得成功定義
    GET_WORK_PLAN_INFO_LIST_FAILURE: 'GET_WORK_PLAN_INFO_LIST_FAILURE',         // 作業計画案件取得失敗定義
    GET_WORK_PLAN_INFO_LIST_RESET: 'GET_WORK_PLAN_INFO_LIST_RESET',             // 作業計画案件取得リセット定義
};

export default GISIPWPL01ActionType;
