import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GRSMMERF01ActionType';

// 応募者情報登録・更新API
const SAVE_CANDIDATE_POST = BASE_URL + "/api/rs_mm_update_candidate_info";

// ********************Actionの作成 Start ********************

/**
 * 登録リクエスト
 */
export const SaveCandidateRequest = () => {
    return {
        type: actionTypes.SAVE_CANDIDATE_REQUEST
    };
};

/**
 * 登録成功
 * @param {*} data
 */
export const SaveCandidateSuccess = (data) => {
    return {
        type: actionTypes.SAVE_CANDIDATE_SUCCESS,
        data
    };
};

/**
 * 登録失敗
 * @param {*} err
 */
export const SaveCandidateFailure = (err) => {
    return {
        type: actionTypes.SAVE_CANDIDATE_FAILURE,
        err
    };
};

/**
 * 登録リセット
 */
export const SaveCandidateReset = () => {
    return {
        type: actionTypes.SAVE_CANDIDATE_RESET
    };
};

// ********************Actionの作成 End ********************

/**
 * 登録処理Actionの発行
 * @param {*} data
 */
export const SaveCandidate = (data) => {
    return async (dispatch) => {
        // Storeへ応募者情報リクエストActionをdispatchする
        dispatch(SaveCandidateRequest());
        ecoPostWithAuthHeader(SAVE_CANDIDATE_POST, JSON.stringify(data))
            .then(res => {
                // Storeへ応募者情報登録成功Actionをdispatchする
                dispatch(SaveCandidateSuccess(res.data));
            }).catch(err =>
                // Storeへ応募者情報登録失敗Actionをdispatchする
                dispatch(SaveCandidateFailure(err))
            );
    };
};

/**
 * 画面リセットActionの発行
 */
export const CandidateReset = () => {
    return async (dispatch) => {
        // Storeへ登録リセットActionをdispatchする
        dispatch(SaveCandidateReset());
    };
};
