import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GEFCRRRI01ActionType';

// 履修履歴詳細API
const EDUCATION_HISTORY_DETAIL_POST = BASE_URL + "/api/ef_cr_education_history_detail";

// 学習する先の取得API
const GET_EDUCATION_HISTORY_ACTION_POST = BASE_URL + "/api/ef_cr_education_action";

// ********************Actionの作成 Start ********************
/**
 * 履修履歴詳細照会
 */
export const GetEducationHistoryDetailRequest = () => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_DETAIL_REQUEST
    };
};

/**
 * 履修履歴詳細照会成功
 */
export const GetEducationHistoryDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_DETAIL_SUCCESS,
        data
    };
};

/**
 * 履修履歴詳細照会失敗
 */
export const GetEducationHistoryDetailFailure = (err) => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_DETAIL_FAILURE,
        err
    };
};

/**
 * 履修履歴詳細照会リセット
 */
export const GetEducationHistoryDetailReset = () => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_DETAIL_RESET
    };
};

/**
 * 学習する先の取得リクエスト
 */
export const GetEducationActionRequest = () => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_ACTION_REQUEST
    };
};

/**
 * 学習する先の取得成功
 * @param {*} data
 */
export const GetEducationActionSuccess = (data) => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_ACTION_SUCCESS,
        data
    };
};

/**
 * 学習する先の取得失敗
 * @param {*} err
 */
export const GetEducationActionFailure = (err) => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_ACTION_FAILURE,
        err
    };
};

/**
 * 学習する先の取得リセット
 * @param {*} err
 */
export const GetEducationActionReset = (err) => {
    return {
        type: actionTypes.GET_EDUCATION_HISTORY_ACTION_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * 履修履歴詳細照会Actionの発行
 */
export const getEducationHistoryDetail = (data) => {
    return async (dispatch) => {
        // Storeへ履修履歴詳細照会リクエストActionをdispatchする
        dispatch(GetEducationHistoryDetailRequest());
        ecoPostWithAuthHeader(EDUCATION_HISTORY_DETAIL_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ履修履歴詳細照会成功Actionをdispatchする
                dispatch(GetEducationHistoryDetailSuccess(res.data))
            ).catch(err =>
                // Storeへ履修履歴詳細照会失敗Actionをdispatchする
                dispatch(GetEducationHistoryDetailFailure(err))
            );
    };
};

/**
 * 学習する先の取得Actionの発行
 */
export const getEducationAction = (arg) => {
    return async (dispatch) => {
        // Storeへ履修履歴詳細学習する先の取得リクエストActionをdispatchする
        dispatch(GetEducationActionRequest());
        ecoPostWithAuthHeader(GET_EDUCATION_HISTORY_ACTION_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへ履修履歴詳細学習する先の取得成功Actionをdispatchする
                dispatch(GetEducationActionSuccess(res.data));
            }).catch(err =>
                // Storeへ履修履歴詳細学習する先の取得失敗Actionをdispatchする
                dispatch(GetEducationActionFailure(err))
            );
    };
};


/**
 * 画面リセットActionの発行
 */
export const getEducationActionReset = () => {
    return async (dispatch) => {
        // Storeへ学習する先の取得リセットActionをdispatchする
        dispatch(GetEducationActionReset());
    };
};

