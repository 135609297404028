/**
 * Action Typesを定義
 */
const GIPPMPLI01ActionType = {
    GET_COMPANY_ENQUETE_ANSWER_INFO_REQUEST: 'GET_COMPANY_ENQUETE_ANSWER_INFO_REQUEST',  // アンケート初期取得登録（企業用）照会定義
    GET_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS: 'GET_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS',  // アンケート初期取得登録（企業用）照会成功定義
    GET_COMPANY_ENQUETE_ANSWER_INFO_FAILURE: 'GET_COMPANY_ENQUETE_ANSWER_INFO_FAILURE',  // アンケート初期取得登録（企業用）照会失敗定義
    GET_COMPANY_ENQUETE_ANSWER_INFO_RESET: 'GET_COMPANY_ENQUETE_ANSWER_INFO_RESET',      // アンケート初期取得登録（企業用）照会リセット定義
    UPDATE_COMPANY_ENQUETE_ANSWER_INFO_REQUEST: 'UPDATE_COMPANY_ENQUETE_ANSWER_INFO_REQUEST',  // アンケート登録（企業用）定義
    UPDATE_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS: 'UPDATE_COMPANY_ENQUETE_ANSWER_INFO_SUCCESS',  // アンケート登録（企業用）成功定義
    UPDATE_COMPANY_ENQUETE_ANSWER_INFO_FAILURE: 'UPDATE_COMPANY_ENQUETE_ANSWER_INFO_FAILURE',  // アンケート登録（企業用）失敗定義
    UPDATE_COMPANY_ENQUETE_ANSWER_INFO_RESET: 'UPDATE_COMPANY_ENQUETE_ANSWER_INFO_RESET',      // アンケート登録（企業用）リセット定義
};

export default GIPPMPLI01ActionType;
