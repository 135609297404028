import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import { isBlank, getChangeDateText } from "../../Components/Tools/StringTools";
import Textarea from "../../Components/Atoms/Textarea";
import ScoreStar from "../../Components/Atoms/ScoreStar";
import DoubleLineChart from "../../Components/Atoms/DoubleLineChart";
import "../../Css/Common.css";
import { getEvaluationResultsDetail, getEvaluationResultsDetailReset, updateEvaluationResultsDetail, updateEvaluationResultsDetailReset } from "./GESSFCES01Action";
import { MessageMap } from "./GESSFCES01Message";

/**
 * 評価詳細
 */
class GESSFCES01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isGet: false,
            isUpdate: false,
            getInfo: {
                organization_id: '',
                organization_type: '',
            },
            updateInfo: {
                organization_id: '',
                organization_type: '',
                version_no: '',
                evaluation_score: '',
                evaluation_comment: '',
            },
            average_score: null,
            setting: null,
            settings: null,
            evaluations: []
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);

        this.setOrganization();
        this.handlingGet();
    }

    /**
     * 組織設定 
     */
    setOrganization = () => {
        const { getInfo, updateInfo } = this.state;
        const { organization_id, organization_type } = this.props.detailData;

        getInfo.organization_id = organization_id;
        getInfo.organization_type = organization_type;
        updateInfo.organization_id = organization_id;
        updateInfo.organization_type = organization_type;
        this.setState({
            getInfo: getInfo,
            updateInfo: updateInfo
        });
    };

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        const { updateInfo } = this.state;
        const { average_score, get_setting_data_dict, get_score_data_dict, get_auto_evaluation_data_dict } = detailData;

        const settingData = get_setting_data_dict && get_setting_data_dict.length > 0 ? get_setting_data_dict : [];
        const setting = settingData.length > 0 ? settingData[0] : null;
        let evaluations = [];
        if (get_score_data_dict && get_score_data_dict.length > 0) {
            evaluations = get_score_data_dict.map(item => {
                const eva = { name: item.score_year_manual + '年度' };
                if (!isBlank(item.average_score_manual)) {
                    eva['評価設定'] = item.average_score_manual;
                }
                return eva;
            });
        }
        if (get_auto_evaluation_data_dict && get_auto_evaluation_data_dict.length > 0) {
            evaluations.map(eva => {
                get_auto_evaluation_data_dict.map(item => {
                    if (item.year + '年度' == eva.name) {
                        if (!isBlank(item.score)) {
                            eva['自動評価'] = item.score;
                        }
                    }
                });
                return eva;
            });
        }
        if (setting !== null) {
            updateInfo.version_no = setting.version_no;
        }
        this.setState({
            average_score: average_score,
            settings: settingData,
            setting: setting,
            evaluations: evaluations,
            updateInfo: updateInfo
        });
    };

    /**
     * 画面編集変更
     * @param {*} event 
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        const { updateInfo } = this.state;
        updateInfo[name] = value;
        this.setState({
            updateInfo: updateInfo
        });
    };

    /**
     * 画面編集イベント
     * @param {*} name
     * @param {*} value
     */
    editItemHandler = (name, value) => {
        const { updateInfo } = this.state;
        updateInfo[name] = value;
        this.setState({
            updateInfo: updateInfo
        });
    };

    /**
    * 取得
    */
    handlingGet = () => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isGet: true }, () => {
            this.setErrorCallback(this.reset);
            const { getInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify(getInfo));
            this.props.getEvaluationResultsDetail(reqData);
        });
    }

    /**
    * 登録
    */
    handlingUpdate = () => {
        window.scrollTo(0, 0);
        this.reset();

        this.setState({ isUpdate: true }, () => {
            this.setErrorCallback(this.reset);
            const { updateInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify(updateInfo));
            this.props.updateEvaluationResultsDetail(reqData);
        });
    }

    /**
     * ローディング、エラー画面リセット
     */
    reset = () => {
        this.props.getEvaluationResultsDetailReset();
        this.props.updateEvaluationResultsDetailReset();
    }

    render() {
        const { average_score, getInfo, updateInfo, settings, evaluations, setting } = this.state;

        if (this.state.isGet && this.props.getErrType == '200') {
            this.setState({ isGet: false }, () => {
                this.setViewStatus(this.props.getData);
            });
        }

        if (this.state.isUpdate && this.props.updateErrType == '200') {
            this.setState({ isUpdate: false }, () => {
                this.showModalDialog(0, getMessage('RS-0036'), () => {
                    this.handlingGet();
                });
            });
        }

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">評価詳細</h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.getMenu().gotoHome(); }, () => { })}>ホーム</a></li>
                                <li><a href="#!" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GESSFELI01"); }, () => { })}>評価対象一覧</a></li>
                                <li>評価詳細</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.getErrType} err_detail={this.props.getErrDetail} messageMap={MessageMap} />
                                <ErrorMessage err_type={this.props.updateErrType} err_detail={this.props.updateErrDetail} messageMap={MessageMap} />
                                <div className="bl_sect_inner">
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <p><img src={setting && setting.logo} alt="" /></p>
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                {(() => {
                                                    if (getInfo.organization_type === 'COP') {
                                                        return (
                                                            <dt>企業名：</dt>
                                                        );
                                                    } else if (getInfo.organization_type === 'UNI' || getInfo.organization_type === 'OKR') {
                                                        return (
                                                            <dt>機関・大学名：</dt>
                                                        );
                                                    }
                                                })()}
                                                <dd>{setting && setting.organization_name}</dd>
                                            </dl>
                                        </div>
                                    </div>

                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_horizBtnUnit bl_horizBtnUnit__r hp_mb20">
                                            <button type="button" name="" className="el_blueBtn el_shadowBtn" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp(getInfo.organization_type === 'COP' ? "GESSFCMA01" : "GESSFAUS01", getInfo); }, () => { })}>利用実績一覧へ</button>
                                        </div>
                                        <div className="bl_graphArea hp_mb30">
                                            <DoubleLineChart data={evaluations} title={"直近５年間評価設定"} xkey={"name"} ylabel={"評価点数"} lineonekey={"評価設定"} linetwokey={"自動評価"} />
                                        </div>
                                        <table className="bl_horizTable02">
                                            <tbody>
                                                <tr>
                                                    <th>自動評価結果</th>
                                                    <td>
                                                        {average_score !== null ?
                                                            <div>
                                                                {average_score}点<span style={{ display: "inline-block", width: "15px" }}></span>
                                                                <ScoreStar score={average_score} />
                                                            </div>
                                                            : "未到来"
                                                        }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </section>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_sect_header">
                                        <h2 className="el_lv3Heading"><span>評価設定</span></h2>
                                    </div>
                                    <section className="bl_sect_frame">
                                        <p className="el_note_sm hp_tar hp_mb20">５：満足　４：やや満足　３：どちらとも言えない　２：やや不満　１：不満</p>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table'>
                                                <thead>
                                                    <tr><th></th><th>５</th><th>４</th><th>３</th><th>２</th><th>１</th></tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td className="el_vTitle">
                                                            今回評価設定
                                                        </td>
                                                        <td><input type="radio" name="evaluation_score" value="5" checked={updateInfo.evaluation_score == '5'} onChange={this.handleChange} /></td>
                                                        <td><input type="radio" name="evaluation_score" value="4" checked={updateInfo.evaluation_score == '4'} onChange={this.handleChange} /></td>
                                                        <td><input type="radio" name="evaluation_score" value="3" checked={updateInfo.evaluation_score == '3'} onChange={this.handleChange} /></td>
                                                        <td><input type="radio" name="evaluation_score" value="2" checked={updateInfo.evaluation_score == '2'} onChange={this.handleChange} /></td>
                                                        <td><input type="radio" name="evaluation_score" value="1" checked={updateInfo.evaluation_score == '1'} onChange={this.handleChange} /></td>
                                                    </tr>
                                                    <tr>
                                                        <td className="el_vTitle">
                                                            評価コメント
                                                        </td>
                                                        <td colspan="5" className="mixTableBody">
                                                            <div className="bl_formUnit">
                                                                <Textarea className={"el_textarea"} maxlength="200" rows="4" cols="80" name="evaluation_comment" setValue={updateInfo.evaluation_comment} onHandleChange={(k, v) => this.editItemHandler(k, v)} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="bl_horizBtnUnit">
                                            <button type="button" className="el_btn" onClick={() => this.handlingUpdate()} tabIndex="9">評価登録</button>
                                        </div>
                                    </section>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>評価日</th><th>点数</th><th>評価コメント</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (settings !== null && settings !== undefined && settings.length > 0) {
                                                            return (
                                                                settings.map((item, index) => (
                                                                    <tr>
                                                                        <td className="hp_tar">{!isBlank(item.score_date) ? getChangeDateText(item.score_date) : ''}</td>
                                                                        <td className="hp_tar">{item.score}</td>
                                                                        <td>{item.comment}</td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => this.showModalDialog(1, getMessage('RS-0066'), () => { this.props.changeDisp("GESSFELI01"); }, () => { })}>評価対象一覧へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        //  評価詳細取得
        isGetFetching: state.getEvaluationResultsDetail.isFetching,
        getData: state.getEvaluationResultsDetail.data,
        isGetError: state.getEvaluationResultsDetail.isError,
        getErrType: state.getEvaluationResultsDetail.err_type,
        getErrDetail: state.getEvaluationResultsDetail.err_detail,
        //  評価詳細更新
        isUpdateFetching: state.updateEvaluationResultsDetail.isFetching,
        updateData: state.updateEvaluationResultsDetail.data,
        isUpdateError: state.updateEvaluationResultsDetail.isError,
        updateErrType: state.updateEvaluationResultsDetail.err_type,
        updateErrDetail: state.updateEvaluationResultsDetail.err_detail
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getEvaluationResultsDetail(arg) {
            // 評価詳細取得
            dispatch(getEvaluationResultsDetail(arg));
        },
        getEvaluationResultsDetailReset() {
            dispatch(getEvaluationResultsDetailReset());
        },
        updateEvaluationResultsDetail(arg) {
            // 評価詳細更新
            dispatch(updateEvaluationResultsDetail(arg));
        },
        updateEvaluationResultsDetailReset() {
            dispatch(updateEvaluationResultsDetailReset());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GESSFCES01);