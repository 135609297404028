/**
 * Action Typesを定義
 */
const GESSFELI01ActionType = {
    GET_EVALUATION_TARGETS_LIST_REQUEST: 'GET_EVALUATION_TARGETS_LIST_REQUEST',  // 評価対象一覧取得定義
    GET_EVALUATION_TARGETS_LIST_SUCCESS: 'GET_EVALUATION_TARGETS_LIST_SUCCESS',  // 評価対象一覧取得成功定義
    GET_EVALUATION_TARGETS_LIST_FAILURE: 'GET_EVALUATION_TARGETS_LIST_FAILURE',  // 評価対象一覧取得失敗定義
    GET_EVALUATION_TARGETS_LIST_RESET: 'GET_EVALUATION_TARGETS_LIST_RESET',       // 評価対象一覧取得リセット定義
};

export default GESSFELI01ActionType;