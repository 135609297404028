import { getTrainingEnqueteAnswerInfo, updateTrainingEnqueteAnswerInfo } from "./EqGESEQQRI01/GESEQQRI01Reducer";
import { getCompanyEnqueteAnswerInfo, updateCompanyEnqueteAnswerInfo } from "./EqGESEQQRE01/GESEQQRE01Reducer";
import { getEvaluationTargetsList } from "./SfGESSFELI01/GESSFELI01Reducer";
import { getEvaluationResultsDetail, updateEvaluationResultsDetail } from "./SfGESSFCES01/GESSFCES01Reducer";
import { getOkrUniUsageResultsList } from "./SfGESSFAUS01/GESSFAUS01Reducer";
import { getOkrUniUsageResultsDetail } from "./SfGESSFQUS01/GESSFQUS01Reducer";
import { getCopUsageResultsList } from "./SfGESSFCMA01/GESSFCMA01Reducer";
import { getCopUsageResultsDetail } from "./SfGESSFCQA01/GESSFCQA01Reducer";

export const esReducer = {
    getTrainingEnqueteAnswerInfo,
    updateTrainingEnqueteAnswerInfo,
    getCompanyEnqueteAnswerInfo,
    updateCompanyEnqueteAnswerInfo,
    getEvaluationTargetsList,
    getEvaluationResultsDetail,
    updateEvaluationResultsDetail,
    getOkrUniUsageResultsList,
    getOkrUniUsageResultsDetail,
    getCopUsageResultsList,
    getCopUsageResultsDetail
};
