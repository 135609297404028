import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import TextInput from "../../Components/Atoms/TextInput";
import { connect } from "react-redux";
import { getMessage, getIndustryMasterList, getCodeList, getCodeName } from "../../Constants/CommonConstants";
import { checkArray, isBlank } from "../../Components/Tools/StringTools";
import { GetDailyReportList, GetDailyReportListReset } from "./GISDMRLS01Action";
import { getTrainingReportList, getTrainingReportListReset } from "../DmGISDMIRI01/GISDMIRI01Action";
import { getReportContentInfo, getReportContentReset } from "../DmGISDMIRR01/GISDMIRR01Action";
import "../../Css/Common.css";
import moment from "moment";

/**
 * 日報日次一覧
 */
class GISDMRLS01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            err_msg: [],
            date: this.props.dailyReportList.data.target_date,
            user_id: this.props.dailyReportList.data.user_id,
            case_id: this.props.dailyReportList.data.case_id,
            last_target_date: this.props.dailyReportList.lastDate,
            inited: false
        };
        moment.locale("ja", {
            weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
            weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
        });
    }

    /**
     * 初期化イベント
     */
    componentDidMount = async () => {
        if (this.props.isInit) {
            // ユーザ一覧以外から再取得する
            window.scrollTo(0, 0);
            this.getChangeMonthInquiry(this.state.date);
        }
        await this.props.getReportContentReset();
        await this.props.getTrainingReportListReset();
        this.setState({ inited: true });
    }

    /**
     * 案件選択
     * @param {*} event 選択ID
     */
    handleRowSelection = (event) => {
        // this.setState({
        //     selectedId: this.props.data[event.target.value].plan_template_id
        // });
    }

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        this.setState({
            [name]: value
        });
    }

    /**
     * 画面切替表示
     * @param {*} changeTo 遷移先
     */
    handleToDisplay = (changeTo, isReset) => {
        window.scrollTo(0, 0);
        if (isReset) {
            this.props.getDailyReportListReset();
        }
        return this.props.changeDisp(changeTo);
    }

    /**
     * 前月
     */
    lastMonth = () => {
        this.setState(prevState => ({
            date: moment(moment(prevState.date, 'YYYY/MM').subtract(1, 'months').format('YYYY/MM'), 'YYYY/MM').format('YYYY/MM'),
            err_msg: []
        }), () => {
            this.getChangeMonthInquiry(this.state.date);
        });
    }

    /**
     * 次月
     */
    nextMonth = () => {
        this.setState(prevState => ({
            date: moment(moment(prevState.date, 'YYYY/MM').add(1, 'months').format('YYYY/MM'), 'YYYY/MM').format('YYYY/MM'),
            err_msg: []
        }), () => {
            this.getChangeMonthInquiry(this.state.date);
        });
    }

    /**
        * 検索処理
    */
    getChangeMonthInquiry = (...args) => {
        // パラメータの設定
        let searchPara = {};
        // ユーザID
        searchPara['user_id'] = this.state.user_id;
        // 案件ID
        searchPara['case_id'] = this.state.case_id;
        // 日付
        searchPara['internship_report_date'] = args[0];

        this.setState({
            condition: searchPara,
            err_msg: []
        }, () => {
            // 検索処理を行う
            this.props.getDailyReportList(this.state.condition);
        });
    }

    /**
     * 実習レポート照会
     * @param {*} event 選択された対象
     */
    toTrainingReport = (user_id, case_id, internship_report_date) => {
        window.scrollTo(0, 0);

        if (this.props.beforeDispId == "GAFMCMCFDI01") {
            // 実習レポート照会取得
            this.props.getTrainingReportInfo(user_id, case_id, internship_report_date);
        } else if (this.props.beforeDispId == "GAFMCMCFDM01") {
            // 日報管理レポート登録取得
            this.props.getReportContentInfo(user_id, case_id, internship_report_date);
        }


    };

    /**
     * 氏名などの情報を表示する
     * @param {*} day 対象日付
     * @param {*} daysMap 取得結果マップ
     */
    getDayRow = (day, daysMap) => {
        const ymd = day.format('YYYY/MM/DD');
        const item = daysMap[ymd];
        if (item) {
            return <Fragment>
                <td>{item.employment_name_kana}</td>
                <td className="hp_tac">{item.registration_flg === '1' ? '〇' : ''}</td>
                <td className="hp_tar">{item.regist_datetime}</td>
                <td className="hp_tac">{item.approval_flg === '1' ? '〇' : ''}</td>
                <td className="hp_tar">{item.approval_datetime}</td>
            </Fragment>;
        } else {
            return <Fragment>
                <td></td>
                <td className="hp_tac"></td>
                <td className="hp_tar"></td>
                <td className="hp_tac"></td>
                <td className="hp_tar"></td>
            </Fragment>;
        }
    }

    render() {

        if (!isBlank(this.props.trainingData) && this.props.trainingData.err_type == '200' && this.state.inited) {
            // 実習レポート照会へ遷移する
            this.props.changeDisp('GISDMIRI01');
        }

        if (!isBlank(this.props.reportContentInfo) && this.props.reportContentInfo.err_type == '200' && this.state.inited) {
            // レポート登録へ遷移する
            this.props.redirectToView('GISDMIRR01', this.props.reportContentInfo.data);
        }

        // 日本語カレンダー設定
        moment.locale("ja");

        let daysMap = {};
        if (checkArray(this.props.dailyReportList.data.internship_report_list)) {
            this.props.dailyReportList.data.internship_report_list.map((item, index) => (
                daysMap[item.internship_report_date] = item
            ));
        }

        // 当月の日付を取得する
        const currentMonthDates = new Array(moment(this.state.date, 'YYYY/MM').daysInMonth()).fill(null).map((x, i) => moment(this.state.date, 'YYYY/MM').startOf('month').add(i, 'days'));

        // 該当月の実習計画時間と実習時間
        const work_plan_detail_list = this.props.dailyReportList.data.work_plan_detail_list;

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                日報日次一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#" onClick={() => this.getMenu().gotoHome()}>ホーム</a></li>
                                <li>
                                    <a href="#" onClick={() => this.handleToDisplay(this.props.beforeDispId, true)}>ユーザ一覧</a>
                                </li>
                                <li>日報日次一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <div className="bl_sect_header">
                                    <h2 className="el_lv2Heading">{moment(this.state.date, 'YYYY/MM').format('YYYY年MM月')}</h2>
                                </div>
                                <div className="bl_sect_inner">
                                    <div className="bl_horizBtnUnit bl_horizBtnUnit__blue">
                                        <button type="button" className="el_backBtn" onClick={() => this.lastMonth()} tabIndex="1">前月</button>
                                        {this.state.date == this.state.last_target_date ? <button type="button" className="el_btn is_disabled" tabIndex="2">次月</button> : <button type="button" className="el_nextBtn" onClick={() => this.nextMonth()} tabIndex="2">次月</button>}
                                    </div>
                                    <div class="bl_sect_header">
                                        <h3 class="el_lv3Heading"><span>実習計画時間と実績時間（月合計）</span></h3>
                                    </div>
                                    <div>
                                        <font color="red">計画時間と実績時間の乖離が大きい場合は、指導の対象になりますのでご注意ください。</font>
                                    </div>
                                    <table class='sticky_table mcTable_align'>
                                        <thead>
                                            <tr><th>実習カテゴリ</th><th>計画時間</th><th>実績時間</th></tr>
                                        </thead>
                                        <tbody>
                                            {(() => {
                                                if (checkArray(work_plan_detail_list)) {
                                                    return (
                                                        work_plan_detail_list.map((item, index) => (
                                                            <tr key={index}>
                                                                <td>{item.work_name}</td>
                                                                <td class="hp_tar">
                                                                    {isBlank(item.plan_time) || item.plan_time == '0' ? null : item.plan_time + '時間'}
                                                                </td>
                                                                <td class="hp_tar">
                                                                    {isBlank(item.work_time) || item.work_time == '0' ? null : item.work_time + '時間'}
                                                                </td>
                                                            </tr>
                                                        ))
                                                    );
                                                }
                                            })()}
                                        </tbody>
                                    </table>
                                    <br></br>
                                    <br></br>
                                    <div class="bl_sect_header">
                                        <h3 class="el_lv3Heading"><span>日次実績一覧</span></h3>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">

                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>日付</th><th>氏名</th><th>日報登録有無</th><th>登録日</th><th>承認登録有無</th><th>承認日</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (checkArray(currentMonthDates)) {
                                                            return (
                                                                currentMonthDates.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className="hp_tar"><a className="el_link" href="#" onClick={() => this.toTrainingReport(this.state.user_id, this.state.case_id, item.format('YYYY/MM/DD'))}> {item.format('MM月DD日（ddd）')}</a></td>
                                                                        {this.getDayRow(item, daysMap)}
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                    <div className="bl_horizBtnUnit__move">
                                        <button type="button" className="el_blueBtn" onClick={() => this.handleToDisplay(this.props.beforeDispId, true)} tabIndex="3">ユーザ一覧へ</button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment >
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        dailyReportList: state.getDailyReportList,
        trainingData: state.getTrainingReportList,
        reportContentInfo: state.getReportContentInfo
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getDailyReportListReset() {
            // 日報日次一覧Reset
            dispatch(GetDailyReportListReset());
        },
        getDailyReportList(args) {
            // 日報日次一覧
            dispatch(GetDailyReportList(args));
        },
        getTrainingReportInfo(user_id, case_id, internship_report_date) {
            // レポート紹介取得
            dispatch(getTrainingReportList({
                case_id: case_id, user_id: user_id, internship_report_date: internship_report_date
            }));
        },
        getReportContentInfo(user_id, case_id, internship_report_date) {
            // レポート内容登録初期取得
            dispatch(getReportContentInfo({
                case_id: case_id, user_id: user_id, internship_report_date: internship_report_date
            }));
        },
        getReportContentReset() {
            // レポート内容登録初期取得リセット
            dispatch(getReportContentReset());
        },
        getTrainingReportListReset() {
            // レポート紹介取得リセット
            dispatch(getTrainingReportListReset);
        }

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GISDMRLS01);

