import React, { Fragment } from "react";
import BaseUIComponent from "../../Components/BaseUIComponent";
import { connect } from "react-redux";
import { getMessage, getCodeName, getCodeValueLabelList } from "../../Constants/CommonConstants";
import ErrorMessage from "../../Components/Atoms/ErrorMessage";
import TextInput from "../../Components/Atoms/TextInput";
import ScoreStar from "../../Components/Atoms/ScoreStar";
import "../../Css/Common.css";
import { getOkrUniUsageResultsList, GetOkrUniUsageResultsListReset } from "./GESSFAUS01Action";
import { MessageMap } from "./GESSFAUS01Message";

class GESSFAUS01 extends BaseUIComponent {
    constructor(props) {
        super(props);
        this.state = {
            isSearch: false,
            err_msg: [],
            caseType: [],
            okrUniUsageResultsList: [],
            okrUniUsageDict: {},
            searchInfo: {
                organization_id: '',
                organization_type: '',
                search_status_of_residence: '',
                search_case_title: '',
                search_company_name: '',
                search_accept_period_start: '',
                search_accept_period_end: '',
                search_enquete_answer_status: '',
                search_score: '',
            },
            toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
            searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn",
        };
    }

    /**
     * 初期化イベント
     */
    componentDidMount = () => {
        window.scrollTo(0, 0);
        this.setOrganization();
        this.handleSearch();
    }

    /**
     * 組織設定 
     */
    setOrganization = () => {
        const { searchInfo } = this.state;
        const { organization_id, organization_type } = this.props.detailData;

        searchInfo.organization_id = organization_id;
        searchInfo.organization_type = organization_type;
        this.setState({
            searchInfo: searchInfo,
        });
    };

    /**
     * 検索条件toggle制御
     */
    toggleClassAction = () => {
        if (this.state.toggleclass.indexOf("hp_dn") == -1) {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow hp_dn",
                searchbtnclass: "el_searchBtn el_searchBtn__open js_searchBtn"
            });
        } else {
            this.setState({
                toggleclass: "bl_sect_frame bl_sect_frame_narrow",
                searchbtnclass: "el_searchBtn el_searchBtn__close js_searchBtn"
            });
        }
    };

    /**
     * ドロップダウンリスト変更
     */
    handleChange = (event) => {
        const { name, value } = event.target;
        const { searchInfo } = this.state
        searchInfo[name] = value;
        this.setState({
            searchInfo: searchInfo
        });
    };

    /**
     * 検索条件編集
     * @param {*} name 検索条件名
     * @param {*} value 検索条件内容
     */
    editItemHandler = (name, value) => {
        const { searchInfo } = this.state
        searchInfo[name] = value;
        this.setState({
            searchInfo: searchInfo
        });
    }

    /**
     * 在留資格条件を設定する
     * @param {*} index インデックス
     */
    setCaseType = index => event => {
        const { caseType, searchInfo } = this.state;
        caseType[index] = event.target.checked;
        // 在留資格
        let searchStatusOfResidence = "";
        caseType.map((key, value) => {
            if (key) {
                searchStatusOfResidence = searchStatusOfResidence + value + ",";
            }
        });
        if (searchStatusOfResidence.length > 0) {
            searchStatusOfResidence = searchStatusOfResidence.substr(0, searchStatusOfResidence.length - 1);
        }
        searchInfo.search_status_of_residence = searchStatusOfResidence;
        this.setState({
            caseType: caseType,
            searchInfo: searchInfo
        });
    };

    /**
     * 検索処理
     */
    handleSearch = () => {
        window.scrollTo(0, 0);
        this.searchReset();

        this.setState({ isSearch: true, err_msg: [] }, () => {
            this.setErrorCallback(this.searchReset);
            const { searchInfo } = this.state;
            let reqData = JSON.parse(JSON.stringify(searchInfo));
            this.props.getOkrUniUsageResultsList(reqData);
        });
    };

    /**
     * 検索一覧リセットする
     */
    searchReset = () => {
        this.props.getOkrUniUsageResultsListReset();
    };

    /**
     * 画面処理ステータス設定 
     * @param {*} detailData 
     */
    setViewStatus = (detailData) => {
        let err_msg = [];
        let okrUniUsageResultsList = [];
        let okrUniUsageDict = {};

        const { overflowflg, okr_uni_usage_results_data_dict, case_list_data_dict } = detailData;
        if (overflowflg === '1' && this.state.err_msg.length == 0) {
            err_msg = [getMessage('RS-0017').replace(/%1/g, '100')];
        }
        if (okr_uni_usage_results_data_dict && okr_uni_usage_results_data_dict.length > 0) {
            okrUniUsageDict = okr_uni_usage_results_data_dict[0];
        }
        if (case_list_data_dict && case_list_data_dict.length > 0) {
            okrUniUsageResultsList = case_list_data_dict;
        }
        this.setState({
            err_msg: err_msg,
            okrUniUsageDict: okrUniUsageDict,
            okrUniUsageResultsList: okrUniUsageResultsList
        });
    };


    render() {
        if (this.state.isSearch && this.props.errType === '200') {
            this.setState({ isSearch: false }, () => {
                this.setViewStatus(this.props.data);
            });
        }

        const { okrUniUsageDict, okrUniUsageResultsList, searchInfo } = this.state;
        const { organization_id, organization_type } = this.props.detailData;
        const searchScore = getCodeValueLabelList('RSC0037');
        const searchEnqueteAnswerStatus = getCodeValueLabelList('RSC0011');

        return (
            <Fragment>
                <div className="ly_cont">
                    <div className="bl_mainHeader">
                        <div className="bl_mainHeader_inner">
                            <h1 className="el_lv1Heading">
                                利用実績一覧
                            </h1>
                        </div>
                    </div>
                    <div className="ly_mainCont">
                        <nav className="bl_breadcrumb">
                            <ol className="bl_breadcrumb_list">
                                <li><a href="#!" onClick={() => { this.getMenu().gotoHome() }}>ホーム</a></li>
                                <li><a href="#!" onClick={() => { this.props.changeDisp("GESSFELI01") }}>評価対象一覧</a></li>
                                <li><a href="#!" onClick={() => { this.props.changeDisp("GESSFCES01", searchInfo) }}>評価詳細</a></li>
                                <li>利用実績一覧</li>
                            </ol>
                        </nav>
                        <section className="bl_sect">
                            <div className="ly_mainCont_inner">
                                <ErrorMessage err_type={this.props.errType} err_detail={this.props.errDetail} messageMap={MessageMap} />
                                {(() => {
                                    return (
                                        this.state.err_msg.length > 0 ? <div id='errorarea'>
                                            <ul className='errorArea'>
                                                {
                                                    Object.entries(this.state.err_msg).map(
                                                        ([key]) => (
                                                            <li key={key}>{this.state.err_msg[key]}</li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div> : null
                                    );
                                }
                                )()}
                                <div className="bl_sect_inner">
                                    <div className="bl_caseInfo">
                                        <div className="bl_coLogo">
                                            <p><img src={okrUniUsageDict && okrUniUsageDict.logo} alt="" /></p>
                                        </div>
                                        <div className="bl_coTxt">
                                            <dl>
                                                <dt>送り出し機関名・大学名：</dt>
                                                <dd>{okrUniUsageDict && okrUniUsageDict.organization_name}</dd>
                                                <dt>前回評価：</dt>
                                                <dd>
                                                    {okrUniUsageDict && okrUniUsageDict.info_score
                                                        ? <ScoreStar score={okrUniUsageDict.info_score} />
                                                        : "未評価"
                                                    }
                                                </dd>
                                                <dt>前回評価日：</dt>
                                                <dd>{okrUniUsageDict && okrUniUsageDict.score_date ? okrUniUsageDict.score_date.replace(/\-/g, "/") : "　"}</dd>
                                                <dt>自動評価結果：</dt>
                                                <dd>
                                                    {okrUniUsageDict && okrUniUsageDict.summary_score
                                                        ? <ScoreStar score={okrUniUsageDict.summary_score} />
                                                        : "未到来"
                                                    }
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                    <div className="bl_sect_inner">
                                        <div className="bl_searchBtn">
                                            <button className={this.state.searchbtnclass} onClick={() => this.toggleClassAction()}>検索条件</button>
                                        </div>
                                        <div className={this.state.toggleclass}>
                                            <table className="bl_horizTable">
                                                <tbody>
                                                    <tr>
                                                        <th>在留資格</th>
                                                        <td>
                                                            <div className="bl_formUnit">
                                                                <label className="el_label">
                                                                    <input type="checkbox" className="el_checkbox" name="search_status_of_residence" value="0" checked={this.state.caseType[0]} onChange={this.setCaseType(0)} tabIndex="1" />
                                                                    <span className="el_checkbox_txt"> 技能実習</span>
                                                                </label>
                                                                <label className="el_label">
                                                                    <input type="checkbox" className="el_checkbox" name="search_status_of_residence" value="1" checked={this.state.caseType[1]} onChange={this.setCaseType(1)} tabIndex="2" />
                                                                    <span className="el_checkbox_txt"> 特定技能</span>
                                                                </label>
                                                                <label className="el_label">
                                                                    <input type="checkbox" className="el_checkbox" name="search_status_of_residence" value="2" checked={this.state.caseType[2]} onChange={this.setCaseType(2)} tabIndex="3" />
                                                                    <span className="el_checkbox_txt"> インターン</span>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>案件名</th>
                                                        <td>
                                                            <TextInput type="text" className="el_input el_input__max" maxlength="40" name="search_case_title" setValue={searchInfo.search_case_title} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="4" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>企業名</th>
                                                        <td>
                                                            <TextInput type="text" className="el_input el_input__max" maxlength="60" name="search_company_name" setValue={searchInfo.search_company_name} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="5" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>受入・就業期間</th>
                                                        <td>
                                                            <TextInput type="date" className="el_input el_input__datepicker" placeholder="年/月/日" name="search_accept_period_start" setValue={searchInfo.search_accept_period_start} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="6" />
                                                            <span className="hp_ml10 hp_mr10">〜</span>
                                                            <TextInput type="date" className="el_input el_input__datepicker" placeholder="年/月/日" name="search_accept_period_end" setValue={searchInfo.search_accept_period_end} onHandleChange={(k, v) => this.editItemHandler(k, v)} tabIndex="7" />
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>アクシデント</th>
                                                        <td>
                                                            <div className="bl_formUnit">
                                                                <select className="el_select el_select__auto" name="search_enquete_answer_status" value={searchInfo.search_enquete_answer_status} onChange={this.handleChange} tabIndex="10">
                                                                    <option value="" selected></option>
                                                                    {
                                                                        searchEnqueteAnswerStatus.map(item => (
                                                                            <option key={item.value} value={item.value} selected={searchInfo.search_enquete_answer_status === item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>自動評価結果</th>
                                                        <td>
                                                            <div className="bl_formUnit">
                                                                <select className="el_select" name="search_score" value={searchInfo.search_score} onChange={this.handleChange} tabIndex="10">
                                                                    <option value="" selected></option>
                                                                    {
                                                                        searchScore.map(item => (
                                                                            <option key={item.value} value={item.value} selected={searchInfo.search_score === item.value}>
                                                                                {item.label}
                                                                            </option>
                                                                        ))
                                                                    }
                                                                </select>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            <div className="bl_horizBtnUnit">
                                                <button type="button" className="el_blueBtn el_shadowBtn" onClick={() => this.handleSearch()} tabIndex="11">検索</button>
                                            </div>
                                        </div>
                                    </div>
                                    <section className="bl_sect_frame bl_sect_frame__pd0">
                                        <div className="bl_sect_header">
                                            <h3 className="el_lv3Heading"><span>送り出し機関・大学利用実績案件一覧</span></h3>
                                        </div>
                                        <p className="el_note_sm hp_tar hp_mb20">※自動評価は案件完了月末に反映</p>
                                        <div className='sticky_table_wrapper'>
                                            <table className='sticky_table mcTable_align'>
                                                <thead>
                                                    <tr><th>在留資格</th><th>案件名</th><th>企業名</th><th>受入・就業期間</th><th>採用人数</th><th>アクシデント</th><th>アンケート</th><th>自動評価</th></tr>
                                                </thead>
                                                <tbody>
                                                    {(() => {
                                                        if (okrUniUsageResultsList !== undefined && okrUniUsageResultsList.length > 0) {
                                                            return (
                                                                okrUniUsageResultsList.map((item, index) => (
                                                                    <tr>
                                                                        <td>{getCodeName('RSC0004', item.status_of_residence)}</td>
                                                                        <td>{item.case_title}</td>
                                                                        <td>{item.company_name}</td>
                                                                        <td>{item.accept_period_start ? item.accept_period_start.replace(/\-/g, "/") : ""}〜{item.accept_period_end ? item.accept_period_end.replace(/\-/g, "/") : ""}</td>
                                                                        <td className="hp_tar">{item.candidate_num}名</td>
                                                                        <td>{item.have_accident_flg === '1' ? '有り' : '無し'}</td>
                                                                        <td className="hp_tac">
                                                                            <div className="bl_horizBtnUnit bl_horizBtnUnit__inTable">
                                                                                <button type="button" className={item.have_enquete_flg === '1' ? "el_smBlueBtn el_shadowBtn" : "el_smBlueBtn el_shadowBtn is_disabled"} disabled={item.have_enquete_flg === '0'} onClick={() => { this.props.changeDisp("GESSFQUS01", { case_id: item.case_id, organization_id: organization_id, organization_type: organization_type }) }}>詳細</button>
                                                                            </div>
                                                                        </td>
                                                                        <td className="hp_noWrap hp_tac">
                                                                            {item.score
                                                                                ? <ScoreStar score={item.score} />
                                                                                : "未到来"
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                ))
                                                            );
                                                        }
                                                    })()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </section>
                                </div>
                                <div className="bl_horizBtnUnit__move hp_mt50">
                                    <button type="button" className="el_blueBtn el_blueBtn__wide" onClick={() => { this.props.changeDisp("GESSFCES01", searchInfo) }}>評価詳細へ</button>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </Fragment>
        );
    }
}

// この関数は第一引数はstore.getState()の結果を第一引数に、このContainer componentへ渡されたpropsを第二引数にして呼び出され、
// これらのstateとpropsを使って子のcomponentにpropsとして渡す値を生成する
const mapStateToProps = (state) => {
    return {
        isFetching: state.getOkrUniUsageResultsList.isFetching,
        data: state.getOkrUniUsageResultsList.data,
        error: state.getOkrUniUsageResultsList.isError,
        errType: state.getOkrUniUsageResultsList.err_type,
        errDetail: state.getOkrUniUsageResultsList.err_detail,
    };
};

// この関数は，store.dispatchを第一引数にして呼び出される関数で、
// dispatchを使って子のcomponentにpropsとして渡す関数を生成する
const mapDispatchToProps = dispatch => {
    return {
        getOkrUniUsageResultsList(arg) {
            // 送り出し機関・大学利用実績一覧
            dispatch(getOkrUniUsageResultsList(arg));
        },
        getOkrUniUsageResultsListReset() {
            dispatch(GetOkrUniUsageResultsListReset());
        },
    };

};

export default connect(mapStateToProps, mapDispatchToProps)(GESSFAUS01);