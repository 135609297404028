import { BASE_URL } from "../../Constants/CommonConstants";
import { ecoPostWithAuthHeader } from "../../Components/Tools/HttpTools";
import actionTypes from "./GRSMMAMF01ActionType";

// 応募者一覧検索APIURL定義
const GET_CANDIDATE_LIST_POST = BASE_URL + "/api/rs_mm_get_candidate_list";
// 応募者詳細取得APIURL定義
const GET_CANDIDATE_DETAIL_POST = BASE_URL + "/api/rs_mm_get_candidate_detail";
// 案件応募者詳細取得APIURL定義
const GET_CASE_CANDIDATE_DETAIL_POST = BASE_URL + "/api/rs_mm_get_case_candidate_detail";
// エントリリスト取得APIURL定義
const ENTRY_LIST_POST = BASE_URL + "/api/rs_mm_get_case_entry_list";
// 応募者ステータスチェックAPIURL定義
const CHECK_SELECTED_CANDIDATE_LIST_POST = BASE_URL + "/api/rs_mm_get_candidate_id_check";

/**
 * 応募者一覧検索
 */
export const GetCandidateListRequest = () => {
    return {
        type: actionTypes.GET_CANDIDATE_LIST_REQUEST
    };
};

/**
 * 応募者一覧検索成功
 */
export const GetCandidateListSuccess = (data) => {
    return {
        type: actionTypes.GET_CANDIDATE_LIST_SUCCESS,
        data
    };
};

/**
 * 応募者一覧検索失敗
 */
export const GetCandidateListFailure = (err) => {
    return {
        type: actionTypes.GET_CANDIDATE_LIST_FAILURE,
        err
    };
};

/**
 * 応募者一覧検索リセット
 */
export const GetCandidateListReset = () => {
    return {
        type: actionTypes.GET_CANDIDATE_LIST_RESET
    };
};

/**
 * 応募者詳細取得
 */
export const GetCandidateDetailRequest = () => {
    return {
        type: actionTypes.GET_CANDIDATE_DETAIL_REQUEST
    };
};

/**
 * 応募者詳細取得成功
 */
export const GetCandidateDetailSuccess = (data) => {
    return {
        type: actionTypes.GET_CANDIDATE_DETAIL_SUCCESS,
        data
    };
};

/**
 * 応募者詳細取得失敗
 */
export const GetCandidateDetailFailure = (err) => {
    return {
        type: actionTypes.GET_CANDIDATE_DETAIL_FAILURE,
        err
    };
};

/**
 * 応募者詳細取得リセット
 */
export const GetCandidateDetailReset = () => {
    return {
        type: actionTypes.GET_CANDIDATE_DETAIL_RESET
    };
};

/**
 * 案件応募者リスト
 */
export const GetSelectedCaseCandidateListRequest = () => {
    return {
        type: actionTypes.GET_SELECTED_CASE_CANDIDATE_LIST_REQUEST
    };
};

/**
 * 案件応募者リスト取得成功
 * @param {*} data
 */
export const GetSelectedCaseCandidateListSuccess = (data) => {
    return {
        type: actionTypes.GET_SELECTED_CASE_CANDIDATE_LIST_SUCCESS,
        data
    };
};

/**
 * 案件応募者リスト取得失敗
 * @param {*} err
 */
export const GetSelectedCaseCandidateListFailure = (err) => {
    return {
        type: actionTypes.GET_SELECTED_CASE_CANDIDATE_LIST_FAILURE,
        err
    };
};

/**
 * 案件応募者リスト取得リセット
 */
export const GetSelectedCaseCandidateListReset = () => {
    return {
        type: actionTypes.GET_SELECTED_CASE_CANDIDATE_LIST_RESET
    };
};

/**
 * 応募者リストステータスチェック
 */
export const CheckSelectedCandidateListRequest = () => {
    return {
        type: actionTypes.CHECK_SELECTED_CANDIDATE_LIST_REQUEST
    };
};

/**
 * 応募者リストステータスチェック成功
 */
export const CheckSelectedCandidateListSuccess = (data) => {
    return {
        type: actionTypes.CHECK_SELECTED_CANDIDATE_LIST_SUCCESS,
        data
    };
};

/**
 * 応募者リストステータスチェックエラー
 */
export const CheckSelectedCandidateListFailure = (err) => {
    return {
        type: actionTypes.CHECK_SELECTED_CANDIDATE_LIST_FAILURE,
        err
    };
};

/**
 * 応募者リストステータスチェックリセット
 */
export const CheckSelectedCandidateListReset = () => {
    return {
        type: actionTypes.CHECK_SELECTED_CANDIDATE_LIST_RESET
    };
};

/**
 * 応募者一覧検索Action
 */
export const getCandidateList = (query) => {
    return async (dispatch) => {
        dispatch(GetCandidateListRequest());
        dispatch(GetCandidateDetailReset());
        ecoPostWithAuthHeader(GET_CANDIDATE_LIST_POST, JSON.stringify(query))
            .then(res =>
                dispatch(GetCandidateListSuccess(res.data))
            ).catch(err =>
                dispatch(GetCandidateListFailure(err))
            );
    };
};

/**
 * 応募者詳細取得Actionの発行
 */
export const getCandidateDetail = (candidateId) => {
    return async (dispatch) => {
        dispatch(GetCandidateDetailRequest());
        ecoPostWithAuthHeader(GET_CANDIDATE_DETAIL_POST, { 'candidate_id': candidateId })
            .then(res => {
                dispatch(GetCandidateDetailSuccess(res.data));
            }
            ).catch(err => {
                dispatch(GetCandidateDetailFailure(err));
            }
            );
    };
};

/**
 * 案件応募者詳細取得Actionの発行
 */
export const getCaseCandidateDetail = (candidateId, caseId) => {
    return async (dispatch) => {
        dispatch(GetCandidateDetailRequest());
        ecoPostWithAuthHeader(GET_CASE_CANDIDATE_DETAIL_POST, { 'candidate_id': candidateId, 'case_id': caseId })
            .then(res => {
                dispatch(GetCandidateDetailSuccess(res.data));
            }
            ).catch(err => {
                dispatch(GetCandidateDetailFailure(err));
            }
            );
    };
};

/**
 * 応募者詳細取得Actionの発行
 */
export const getCandidateDetailReset = () => {
    return async (dispatch) => {
        dispatch(GetCandidateDetailReset());
    };
};

/**
 * 案件応募者リスト取得Actionの発行
 * @param {*} data
 */
export const getSelectedCaseCandidateList = (data) => {

    return async (dispatch) => {
        // Storeへ案件応募者リスト取得リクエストActionをdispatchする
        dispatch(GetSelectedCaseCandidateListRequest());
        ecoPostWithAuthHeader(ENTRY_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ案件応募者リスト取得成功Actionをdispatchする
                dispatch(GetSelectedCaseCandidateListSuccess(res.data))
            ).catch(err =>
                // Storeへ案件応募者リスト取得失敗Actionをdispatchする
                dispatch(GetSelectedCaseCandidateListFailure(err))
            );
    };
};


/**
 * 応募者ステータスチェック
 * @param {*} data
 */
export const checkSelectedCandidateList = (data) => {

    return async (dispatch) => {
        // Storeへ応募者ステータスチェックリクエストActionをdispatchする
        dispatch(CheckSelectedCandidateListRequest());
        ecoPostWithAuthHeader(CHECK_SELECTED_CANDIDATE_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ応募者ステータスチェック成功Actionをdispatchする
                dispatch(CheckSelectedCandidateListSuccess(res.data))
            ).catch(err =>
                // Storeへ応募者ステータスチェック失敗Actionをdispatchする
                dispatch(CheckSelectedCandidateListFailure(err))
            );
    };
};
