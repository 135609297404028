// メッセージMap
export const MessageMap = {
    "case_id": "案件ID",
    "company_name": "企業名",
    "facility_name": "施設名",
    "industry_id": "業種ID",
    "status_of_residence": "在留資格",
    "case_title": "案件名",
    "pub_start": "募集期間(開始)",
    "pub_end": "募集期間(終了)",
    "accept_start": "受入・就業希望期間(開始)",
    "accept_end": "受入・就業希望期間(終了)",
    "case_status": "ステータス"
};
