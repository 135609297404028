import React from "react";
import "../../Css/Loading.css";

/**
 * ローディングコンポーネント
 * @param {*} props
 */
export default function Load(props) {

    return (
        <div className="loadWrapper">
            <div className="wrapper">
                <div className="el_loader">
                    <div className="el_loader_body"></div>
                    <div className="el_loader_txt">Loading</div>
                </div>
            </div>
        </div>
    );
}
