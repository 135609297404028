/**
 * Action Typesを定義
 */
const GRSMMERF01ActionTypes = {
    SAVE_CANDIDATE_REQUEST: 'SAVE_CANDIDATE_REQUEST',
    SAVE_CANDIDATE_SUCCESS: 'SAVE_CANDIDATE_SUCCESS',
    SAVE_CANDIDATE_FAILURE: 'SAVE_CANDIDATE_FAILURE',
    SAVE_CANDIDATE_RESET: 'SAVE_CANDIDATE_RESET',
};

export default GRSMMERF01ActionTypes;