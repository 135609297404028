import { ecoPostWithAuthHeader, ecoPostDownloadExcelFile } from "../../Components/Tools/HttpTools";
import { BASE_URL } from "../../Constants/CommonConstants";
import actionTypes from './GIPPMPLI01ActionType';

// 進捗一覧照会API
const PROGRESS_LIST_POST = BASE_URL + "/api/ip_pm_get_progress_management_list";

// 進捗一覧ファイルダウンロードAPI
const PROGRESS_DOWNLOAD_FILE_POST = BASE_URL + "/api/ip_pm_download_progress_management_list";

// ********************Actionの作成 Start ********************
/**
 * 進捗一覧照会
 */
export const GetProgressListRequest = () => {
    return {
        type: actionTypes.GET_PROGRESS_LIST_REQUEST
    };
};

/**
 * 進捗一覧照会成功
 */
export const GetProgressListSuccess = (data) => {
    return {
        type: actionTypes.GET_PROGRESS_LIST_SUCCESS,
        data
    };
};

/**
 * 進捗一覧照会失敗
 */
export const GetProgressListFailure = (err) => {
    return {
        type: actionTypes.GET_PROGRESS_LIST_FAILURE,
        err
    };
};

/**
 * 進捗一覧照会リセット
 */
export const GetProgressListReset = () => {
    return {
        type: actionTypes.GET_PROGRESS_LIST_RESET
    };
};

/**
 * ダウンロードリクエスト
 */
export const ProgressDownloadFileRequest = () => {
    return {
        type: actionTypes.PROGRESS_DOWNLOAD_FILE_REQUEST
    };
};

/**
 * ダウンロード成功
 * @param {*} data
 */
export const ProgressDownloadFileSuccess = (data) => {
    return {
        type: actionTypes.PROGRESS_DOWNLOAD_FILE_SUCCESS,
        data
    };
};

/**
 * ダウンロード失敗
 * @param {*} err
 */
export const ProgressDownloadFileFailure = (err) => {
    return {
        type: actionTypes.PROGRESS_DOWNLOAD_FILE_FAILURE,
        err
    };
};

/**
 * ダウンロードリセット
 * @param {*} err
 */
export const ProgressDownloadFileReset = (err) => {
    return {
        type: actionTypes.PROGRESS_DOWNLOAD_FILE_RESET,
        err
    };
};

// ********************Actionの作成 End ********************

/**
 * 進捗一覧照会Actionの発行
 */
export const GetProgressList = (data) => {
    return async (dispatch) => {
        // Storeへ進捗一覧照会リクエストActionをdispatchする
        dispatch(GetProgressListRequest());
        ecoPostWithAuthHeader(PROGRESS_LIST_POST, JSON.stringify(data))
            .then(res =>
                // Storeへ進捗一覧照会成功Actionをdispatchする
                dispatch(GetProgressListSuccess(res.data))
            ).catch(err =>
                // Storeへ進捗一覧照会失敗Actionをdispatchする
                dispatch(GetProgressListFailure(err))
            );
        // dispatch(GetProgressListSuccess({
        //     'data': {
        //         'list': {
        //             'case_id': '0123456789',
        //             'case_title': 'ｓ１２３あああ',
        //             'status_of_residence': 'インターン',
        //             'company_name': '日立システムズ',
        //             'facility_name': 'AAAホテル',
        //             'accept_period': '2020年04月01日〜2020年10月31日',
        //             'list': [{
        //                 'name': '実習計画A',
        //                 'human_name': '吉田　義男、田中　一郎、鈴木　次郎'
        //             }]
        //         },
        //         'overflowflg': '0',

        //     },
        //     'err_type': '200'
        // }));
    };
};

/**
 * ダウンロードActionの発行
 */
export const progressDownloadFile = (arg) => {
    return async (dispatch) => {
        // Storeへ進捗一覧ダウンロードリクエストActionをdispatchする
        dispatch(ProgressDownloadFileRequest());
        ecoPostDownloadExcelFile(PROGRESS_DOWNLOAD_FILE_POST, JSON.stringify(arg))
            .then(res => {
                // Storeへ進捗一覧ダウンロード成功Actionをdispatchする
                dispatch(ProgressDownloadFileSuccess(res.data));
            }).catch(err =>
                // Storeへ進捗一覧ダウンロード失敗Actionをdispatchする
                dispatch(ProgressDownloadFileFailure(err))
            );
    };
};


/**
 * 画面リセットActionの発行
 */
export const progressDownloadFileReset = () => {
    return async (dispatch) => {
        // StoreへダウンロードリセットActionをdispatchする
        dispatch(ProgressDownloadFileReset());
    };
};

